import {
  CellComponentDisplayPage,
  EnumMetaProps,
  ObjectValues,
  RecordProps,
  TableMetaProps,
  TableMode
} from "@props/RecordProps";
import { ActionColumnKey } from '@config/base';
import {
  DisplayComponentRenderFunction, FieldConfigures, getDestTypeForComponentLookup
} from "./ComponentsConfig";
import { FormInstance } from "antd";
import { textCellComponentRender } from "./DisplayComponents";

export interface GetDisplayRenderProps {
  column: TableMetaProps;
  enumValues: { [id: string]: EnumMetaProps[] };
  objectValues: ObjectValues;
  domainName: string;
  page: CellComponentDisplayPage;
  zIndex: number;
  form: FormInstance;
  switchTabCallback?: (key: string) => void;
  isHighlightField?: boolean;
  tableMode?: TableMode;
}

export const hasDisplayRenderFunction = (column: TableMetaProps): boolean => {
  const { type } = column;
  const destType = getDestTypeForComponentLookup(type);
  return !!FieldConfigures.find(f => f.type === destType)?.displayComponent;
};

export const getDisplayRenderFunction = (props: GetDisplayRenderProps): DisplayComponentRenderFunction => {
  const {
    column, enumValues, objectValues, domainName, page,
    zIndex, form, switchTabCallback, isHighlightField,
    tableMode
  } = props;
  const { type, multiple } = column;
  const destType = getDestTypeForComponentLookup(type);
  const displayComponent = (
    FieldConfigures.find(f => f.type === destType)?.displayComponent) ?? textCellComponentRender;
  return displayComponent({
    column, enumValues, objectValues, domainName,
    page, zIndex, switchTabCallback, isHighlightField,
    tableMode, multiple: multiple ?? false, form,
  });
};

/**
 * 根据表格的元数据、表格中用到的枚举的值和标签的映射表，将从后台拿到的数据结构转化成显示的格式，
 * 动态生成各列的 render 函数
 * @param tableMetaProps 表格的元数据
 * @param enumValues 枚举的值和标签的映射表
 * @param objectValues 对象的值和标签的映射表
 * @param domainName pe name of the domain object
 * @param tableMode Mode of the table, can be "table-list" | "detail" | "finder" | "detail-drawer" | "card-list"
 * @param zIndex zIndex of the component
 */
export function transferColumnMeta(
  tableMetaProps: Array<TableMetaProps>,
  enumValues: { [id: string]: EnumMetaProps[] },
  objectValues: ObjectValues,
  domainName: string,
  tableMode: TableMode,
  zIndex: number,
): Array<TableMetaProps> {
  const result: Array<TableMetaProps> = Object.assign([], tableMetaProps);
  result
    .filter(c => c != null && c.key !== ActionColumnKey)
    .map(column => {
      const { type, multiple } = column;
      const destType = getDestTypeForComponentLookup(type);
      column.render = FieldConfigures.find(f => f.type === destType)?.displayComponent({
        column, enumValues, objectValues, domainName,
        page: "LIST",
        zIndex,
        switchTabCallback: undefined,
        isHighlightField: undefined,
        multiple: multiple ?? false,
        tableMode
      });
      return column;
    });
  return result;
}

/**
 * 使用配置中配置的转换函数，对后台返回的数据中，字段的值进行转换
 */
export function transformRecord(
  domainMeta: Array<TableMetaProps>, record: RecordProps
): RecordProps {
  const convertedRecord = { ...record };
  for (let i = 0; i < domainMeta.length; ++i) {
    const columnMeta = domainMeta[i];
    const columnKey = columnMeta.key;
    const columnValue = record[columnKey];
    const columnType = columnMeta.type;
    if (columnType != null) {
      const destType = getDestTypeForComponentLookup(columnType);
      const transferFunction = FieldConfigures.find(fc => fc.type === destType)?.backendValueConverter;
      if (transferFunction) {
        convertedRecord[columnKey] = transferFunction(record, columnValue);
      }
    }
  }
  return convertedRecord;
}

/**
 * 判断某个列定义是不是某个从属对象所属的主对象列
 * 如对于合同行，判断其某一列是不是到合同的应用
 * @param ownerClass 主对象的类型
 * @param ownerId 主对象的 id
 * @param ownerField 主对象的字段
 * @param column column 的定义
 */
export function isOwnerColumn(
  ownerClass: string | undefined, ownerField: string | undefined, ownerId: number | undefined, column: TableMetaProps
): boolean {
  return !!ownerClass && !!ownerId && !!ownerField && column.backReferenceField === ownerField;
}
