import React, { ReactElement } from "react";
import { useTranslation } from 'react-i18next';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Space } from "antd";

const HasDetailFieldPlaceholderCell = (props: {
  switchTabCallback?: (key: string) => void;
  columnKey: string;
  isHighlightField?: boolean;
}): ReactElement => {
  const { switchTabCallback, columnKey } = props;
  const { t } = useTranslation();

  return (
    <span
      className="link-span"
      onClick={
        () => (switchTabCallback != null) ?
          switchTabCallback(columnKey) : {}
      }
    >
      <Space>
        {t('点击在下方显示详情')}
        <ArrowDownOutlined />
      </Space>
    </span>
  );
};

export default HasDetailFieldPlaceholderCell;
