import React, { ReactElement } from "react";
import { Popover, Tag } from "antd";
import { CellComponentDisplayPage, TableMetaProps } from "@props/RecordProps";

const MultiSelectCell = (props: {
  columnMeta: TableMetaProps;
  value: Array<number> | Array<string> | string;
  page?: CellComponentDisplayPage
}): ReactElement => {
  const { columnMeta, value, page } = props;
  const isArray = Array.isArray(value);
  const isValidArrayValue = (value !== '' && value != null &&
    (isArray) && (typeof value.map === 'function'));
  const elems: Array<ReactElement> = isValidArrayValue ?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value.map(
      (val: number | string): ReactElement => (
        <Tag
          closable={false}
          style={{ marginBottom: "0.5em" }}
          className="mul-opt-tag"
          key={columnMeta.key + "_" + val}>
          {val}
        </Tag>
      )) : [];
  if (isArray && value.length > 1 && page !== 'DISPLAY') {
    // Follow ts-ignore is to by pass
    // https://github.com/microsoft/TypeScript/issues/36390
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const content = (<div
      className="multi-select-popover"
      style={{ width: "200px" }}>
      {elems}
    </div>);
    const display = `${value[0]}`;
    return (
      <>
        <div
          // 在只读页面显示的多选字段的选项显示的 Popover 的 Container
          id="multi-select-cell-popover-container"
          //z-index 为 2000, 需要在所有其他的控件之前显示
          style={{ zIndex: 2000, position: "relative" }}
        > </div>
        <Popover
          placement="topLeft"
          content={content}
          title={undefined}
          className="multi-select-cell"
          ref={columnMeta.ref}
          trigger={columnMeta.trigger == null ? "hover" : columnMeta.trigger}
          arrowPointAtCenter
          getPopupContainer={
            () => (document.getElementById("multi-select-cell-popover-container")
              || document.createElement("div"))
          }
        >
          <Tag closable={false}
            className="mul-opt-tag"
          >
            {display}{(value.length > 1) && (<span> &raquo;</span>)}
          </Tag>
        </Popover>
      </>
    );
  } else if (isArray) {
    return (
      <span className="multi-select-cell">
        {elems}
      </span>
    );
  } else {
    return (
      <span className="multi-select-cell">
        {value == null ? "" : JSON.stringify(value)}
      </span>
    );
  }
};

export default MultiSelectCell;
