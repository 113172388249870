import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { fetchSelectOptions } from "@utils/FetchUtils";
import { FormInstance } from "antd/es/form";
import { getReadOnlyClass } from "@utils/ComponentUtils";
import { SuffixIcon } from '../../components';

const { Option } = Select;

interface DynamicSelectProps {
  style?: CSSProperties;
  mode?: "multiple" | "tags";
  dfKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
  currentValue: string | Array<string> | number | Array<number>;
  form: FormInstance;
  updatable?: boolean;
}

const DynamicSelect = (props: DynamicSelectProps): ReactElement => {
  const {
    mode, style, updatable, dfKey, onChange,
    form, currentValue
  } = props;
  const [options, setOptions] = useState<Array<string | number>>([] as Array<string | number>);
  const [loading, setLoading] = useState<boolean>(false);
  const disabled = (updatable == null) ? false : !updatable;

  useEffect(() => {
    setLoading(true);
    fetchSelectOptions(dfKey)
      .then((options: Array<string | number>) => {
        return options;
      })
      .then((options: Array<string | number>) => {
        setOptions(options);
      })
      .then(() => setLoading(false));
  }, [dfKey]);

  useEffect(() => {
    if (options?.length > 0) {
      form.setFieldsValue({ [dfKey]: currentValue });
    }
  }, [dfKey, currentValue, options, form]);

  const optElem = options?.map(opt => <Option key={opt} value={opt}>{opt}</Option>);
  const className = `df_${mode == null ? "single" : mode}`;

  // **** Attention ****
  // zIndex of the select dropdown should higher than the zIndex of modal(by
  // default 1051) because the dropdown might be displayed on a modal
  // https://stackoverflow.com/questions/53926911/antd-select-not-working-inside-a-full-screen-dialog
  // Set in app.less by .ant-select-dropdown
  return loading ? <Spin size="small" /> : (
    <Select
      className={`${className} ${getReadOnlyClass(updatable)}`}
      mode={mode}
      style={style}
      onChange={onChange}
      defaultValue={currentValue}
      disabled={disabled}
      showSearch={true}
      suffixIcon={<SuffixIcon updatable={updatable} />}
      allowClear={true}
      showArrow={true}
      dropdownStyle={{ zIndex: 2000 }}
    >
      {optElem}
    </Select>
  );
};

export default DynamicSelect;
