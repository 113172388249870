import React, { ReactElement, useEffect, useState } from "react";
import { EnumCellProps, EnumMetaProps } from "@props/RecordProps";
import { Tag } from "antd";
import { getEnumLabelFromCache } from "@utils/EnumUtils";

const DefaultDisplayVal = '';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colors = [
  "#E6F1FF", //(light blue)
  "#F5F5F5", // (light grey)
  "#F4F4F4", // (light grey with a hint of beige)
  "#FFF3E0", // (light orange)
  "#F1F8E9", // (light green)
  "#F2F2F2", // (light off-white)
  "#FCF3CF", // (light yellow)
  "#EEEFF2", // (light grey-blue)
  "#F9EBEA", // (light pink)
  "#F2F2F2", //(light grey with a hint of blue)
];

const EnumCell = (props: EnumCellProps): ReactElement => {
  const { value, field, mappings, type } = props;
  const [displayVal, setDisplayVal] = useState<string>(DefaultDisplayVal);

  useEffect(() => {
    const setLabel = (mapping: Array<EnumMetaProps>): void => {
      const enumName = (typeof value === 'string') ? value : value?.name;
      const enumMetaProps = mapping?.filter(e => e.value === enumName);
      const label = (enumMetaProps != null && enumMetaProps.length === 1) ? enumMetaProps[0].label : "";
      setDisplayVal(label);
    };
    const mapping = mappings[field];
    if (mapping == null) {
      if (type == null) {
        console.warn(`Mapping for ${field}/${value} not found in mappings: `, mappings);
        setDisplayVal("");
      } else {
        getEnumLabelFromCache(type).then(setLabel);
      }
    } else {
      setLabel(mapping);
    }
  }, [value, type, field, mappings, displayVal]);

  //const color = (displayVal != null && displayVal.length > 0) ? colors[displayVal.length % colors.length] : undefined;

  return (displayVal === "") ? <></> : (<div className="enum-value">
    <Tag className="enum-tag">{displayVal}</Tag>
  </div>);
};

export default EnumCell;
