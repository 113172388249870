import React, { ReactElement, useEffect, useState } from 'react';
import {
  CommentOutlined
} from '@ant-design/icons';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { Drawer, Space } from "antd";
import { CloseIcon } from "../icons";
import { useTranslation } from "react-i18next";
import { EditableControllerProps } from "@kernel/ComponentsConfig";
import { CommentsPanel } from "./index";
import './comments.less';
import { DomainComment } from './CommentProps';
import { fetchListOfDomainData } from '@utils/FetchUtils';

export interface CommentIconProps extends EditableControllerProps {
  zIndex: number;
}

const CommentsIcon = (props: CommentIconProps): ReactElement => {
  const { zIndex, domainName, record, column } = props;
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [objectTypeId, setObjectTypeId] = useState<number>();

  useEffect(() => {
    drawerVisible && (objectTypeId == null) && fetchListOfDomainData({
      domainName: 'DomainClass',
      current: 1,
      max: 9999,
      useCache: true
    }).then((data) => {
      if (!drawerVisible || (objectTypeId != null)) {
        return;
      }
      if (objectTypeId != null) {
        return;
      }
      const domainClass = data?.data?.find((item) => {
        const isFullName: boolean = domainName.includes("_");
        const result = (isFullName) ?
          (item.fullName == domainName.replaceAll("_", ".")) : (item.shortName === domainName);
        return result;
      });
      if (domainClass != null) {
        setObjectTypeId(domainClass.id);
      } else {
        console.error("Not able to get domain class for " + domainName);
      }
    });
  }, [drawerVisible, domainName, objectTypeId]);

  return record ? (
    <>
      <CommentOutlined
        onClick={() => setDrawerVisible(true)}
      />
      <div onClick={stopPropagationAndPreventDefault}>
        <Drawer
          rootClassName="comments-display-container"
          width="600px"
          rootStyle={{ margin: "auto" }}
          destroyOnClose={true}
          zIndex={zIndex + 3}
          title={
            <Space><CommentOutlined />{t('Comments')}</Space>
          }
          open={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          closeIcon={<CloseIcon onClick={setDrawerVisible} />}
        >
          <CommentsPanel
            zIndex={zIndex + 3}
            domainName={domainName}
            record={record as DomainComment}
            column={column}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            objectTypeId={objectTypeId!}
          />
        </Drawer>
      </div>
    </>
  ) : <CommentOutlined />;
};

export default CommentsIcon;
