import React, { ReactElement, useEffect, useState } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CsvOperateProps } from '@props/RecordProps';
import CsvUploadComponent from './CsvUploadComponent';
import { fetchCanCreate } from "@utils/FetchUtils";

const CsvOperateComponent = (props: CsvOperateProps): ReactElement => {

  const {
    domainName, columns, domainTitle, showUpload, fetchDataCallback,
    ownerId, ownerClass, columnNameInOwnerClass, ownerColumn,
    readonly, zIndex, visiblePopover, setVisiblePopoverCallback
  } = props;
  const { t } = useTranslation();
  const myTitle = domainTitle ?? t(domainName);
  const [canCreate, setCanCreate] = useState<boolean>(false);

  // Hide the popup when switch to different domain list page
  useEffect(() => {
    if (showUpload == null) {
      fetchCanCreate(domainName).then(json => {
        if (json.error === 1) {
          setCanCreate(false);
        } else {
          setCanCreate(json.create);
        }
      });
    } else {
      setCanCreate(showUpload);
    }
  }, [domainName, showUpload]);

  return (
    <div>
      <Space direction="horizontal" size="small">
        {/*
        {(allData.length > 0) &&
          <CsvDownloadComponent
            visiblePopover={visiblePopover}
            setVisiblePopoverCallback={setVisiblePopoverCallback}
            columns={columns}
            domainName={domainName}
            objectValues={objectValues}
            enumValues={enumValues}
            allData={allData}
            selectedData={selectedData}
            zIndex={zIndex + 1}
          />}
          */}
        {canCreate && !readonly &&
          <CsvUploadComponent
            visiblePopover={visiblePopover}
            setVisiblePopoverCallback={setVisiblePopoverCallback}
            columns={columns}
            fetchDataCallback={() => {
              setVisiblePopoverCallback('csvUpload');
              fetchDataCallback();
            }}
            domainName={domainName}
            domainTitle={myTitle ?? ""}
            ownerId={ownerId}
            ownerClass={ownerClass}
            columnNameInOwnerClass={columnNameInOwnerClass}
            ownerColumn={ownerColumn}
            zIndex={zIndex + 1}
          />
        }
      </Space >
    </div >
  );
};

export default CsvOperateComponent;
