// This file is for use client as a separate frontend on it's own
// ************************* ATTENTION ****************************

import { initializeI18n } from '@config/i18n';
import { ModalLockProvider } from '@utils/context';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './css/App.less';
import './css/scrollbar.css';

initializeI18n()
  .then(() => {
    ReactDOM.render(
      <BrowserRouter>
        <ModalLockProvider>
          <App />
        </ModalLockProvider>
      </BrowserRouter>
      ,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    console.error('Error initializing i18n:', error);
    // You may want to display an error message to the user here
  });

export { App };
