import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Pagination, Popover, Space, Tag, Empty, List, Button } from 'antd';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import {
  FormProps,
  RecordProps, SearchConditions,
  SearchValue,
  SortDirection, TableMetaProps
} from '@props/RecordProps';
import { ReloadOutlined, SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getColumnTransKey, isObjectType } from '@utils/ColumnsUtils';
import { fetchFormIdAndExtInfoByType, fetchSearchResult } from '@utils/FetchUtils';
import { getPagination } from '../../components';
import { ObjectIdsDisplayComponent } from '../../form/fields';
import { FullTextSearchDefaultPageSize } from '@config/base';
import { isBlank, isNotBlank } from '@utils/StringUtils';
import CardSearchResultCell from './CardSearchResultCell';
import { CreateAction, PredefinedFilters } from '../../form';
import ActionButtons from '../action/ActionButtons';
import {
  getSearchConditionsForDomain,
  removeSearchConditions, SearchComponent
} from "@kernel/ServerSideSearcher";
import { MatcherDisplay } from "@kernel/ServerSideSearcherConfig";
import i18n from "@config/i18n";
import { emptyMethod } from "@utils/Constants";
import SaveDynamicFilterPopover from "../../components/filters/SaveDynamicFilterPopover";
import { isEqual } from 'lodash';

const { Search } = Input;

interface FullTextSearchPanelProps {
  zIndex: number;
  domainName: string;
  columns: Array<TableMetaProps>;
  fullTextSearchColumns: Array<TableMetaProps>;
  additionalSearchColumns: Array<TableMetaProps>;
  sortColumns: Array<TableMetaProps>;
  panelDisplayColumns: Array<TableMetaProps>;
  fetchDataCallback: (data: Array<RecordProps>) => void;
  refreshData: boolean;
  ownerClass?: string;
  ownerIds?: Array<number>;
  ownerClassColumnName?: string;
  contentDisplayColumns: Array<TableMetaProps>;
  changeCallback: (record: RecordProps, selected: boolean) => void;
  initShowCreateModal?: boolean;
  formExtInfo?: FormProps;
  setOwnerIds?: (ownerIds: Array<number>) => void;
}

const FullTextSearchPanel = (props: FullTextSearchPanelProps): ReactElement => {
  const {
    domainName, fetchDataCallback, fullTextSearchColumns,
    additionalSearchColumns, sortColumns, refreshData,
    ownerClass, ownerIds, ownerClassColumnName,
    contentDisplayColumns, changeCallback, columns, zIndex,
    initShowCreateModal, formExtInfo, panelDisplayColumns, setOwnerIds
  } = props;
  const { t } = useTranslation();
  const [data, setData] = useState<Array<RecordProps>>();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(FullTextSearchDefaultPageSize);
  const [sortField, setSortField] = useState<TableMetaProps>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();
  const [updateFormId, setUpdateFormId] = useState<number>(-1);
  const [selectedData, setSelectedData] = useState<Array<RecordProps>>([]);
  const [additionalConditions, setAdditionalConditions] = useState<SearchConditions>({});
  const [ownerClassColumnNameState, setOwnerClassColumnNameState] = useState<string>(ownerClassColumnName ?? "");
  const [ownerIdsState, setOwnerIdsState] = useState<Array<number> | undefined>(ownerIds);
  const [refreshPredefinedFilters, setRefreshPredefinedFilters] = useState(0);

  const refresh = (): void => {
    setRefreshPredefinedFilters(prevRefresh => prevRefresh + 1);
  };

  useEffect(() => {
    if (!isEqual(ownerIds, ownerIdsState)) {
      setOwnerIdsState(ownerIds);
    }
  }, [ownerIds, ownerIdsState]);

  useEffect(() => {
    if (ownerClassColumnName !== ownerClassColumnNameState && ownerClassColumnName != null) {
      setOwnerClassColumnNameState(ownerClassColumnName);
    }
  }, [ownerClassColumnName, ownerClassColumnNameState]);

  const ownerBlank = isBlank(ownerClass) || !((ownerIdsState?.length ?? 0) > 0);

  const getSortLabel = (): string => {
    if (sortField == null || sortDirection == null) {
      return t("Click to select sort field");
    }
    return t(`Sort ${sortDirection}`, {
      column: sortField.title ?? t(getColumnTransKey(domainName, sortField.key))
    });
  };

  useEffect(() => {
    fetchFormIdAndExtInfoByType(domainName, 'Update')
      .then((res: FormProps) => {
        setUpdateFormId(res.id);
      })
      .catch(e => console.error(`Failed to fetch update form Id of ${domainName}: ${e}`));
  }, [domainName]);

  const emptyElem = (description: string): ReactElement => {
    return (<Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t(description)}
      style={{ paddingTop: "1rem" }}
    />);
  };
  //ATTENTION: 这里是实际控制结果显示面板的高度的
  // 连带控制分页组件的下边界的对齐
  //FIXME #833 根据是否有 owner 信息来自动设定 height
  const height = `${window.innerHeight - 162}px`;

  /**
   * Prepares the search conditions for full text search based on the search keyword and full text search columns.
   * @returns {SearchConditions} The search conditions for full text search.
   */
  const prepareFullTextSearchCondition = useCallback((): SearchConditions => {
    const searchConditions = {} as SearchConditions;
    fullTextSearchColumns
      .forEach(c => {
        searchConditions[c.key] = {
          value: searchKeyword,
          matchMode: "contains",
          columnKey: c.key,
          status: "outOfSync"
        };
      });
    return searchConditions;
  }, [fullTextSearchColumns, searchKeyword]);

  /**
   * Handles the search action for the full text search panel.
   * @param {string} value - The search keyword.
   * @param {Object} props - The optional properties for the search action.
   * @param {number} props.newCurrent - The new current page number.
   * @param {number} props.newPageSize - The new page size.
   * @param {SortDirection} props.newSortDirection - The new sort direction.
   * @param {TableMetaProps} props.newSortField - The new sort field.
   * @param {SearchConditions} newAdditionalConditions - The additional search conditions.
   */
  const onSearch = useCallback((
    value: string, props: {
      newCurrent?: number;
      newPageSize?: number;
      newSortDirection?: SortDirection;
      newSortField?: TableMetaProps
    },
    newAdditionalConditions: SearchConditions
  ) => {
    // eslint-disable-next-line react/prop-types
    const { newCurrent, newPageSize, newSortDirection, newSortField } = props;
    const sortConditionBlank = (isBlank(newSortDirection as string) || newSortField == null);
    const moreConditions = newAdditionalConditions ?? additionalConditions;
    const moreConditionBlank = (moreConditions == null || JSON.stringify(moreConditions) === "{}");
    const conditionBlank = isBlank(value) && isBlank(searchKeyword) && sortConditionBlank && moreConditionBlank;
    let fullTextConditions = {} as SearchConditions;
    //如果之前保存的搜索条件和新的搜索条件都为空, 且 owner 信息也为空, 则从后台获取所有的数据
    if (conditionBlank && ownerBlank) {
      // Do nothing if all conditions is blank
    } else if (!conditionBlank) {
      fullTextConditions = prepareFullTextSearchCondition();
    }
    setLoading(true);
    fetchSearchResult({
      domainName,
      searchConditions: { ...moreConditions, ...formExtInfo?.extInfo?.conditions },
      fullTextConditions,
      max: newPageSize ?? pageSize,
      current: newCurrent ?? 0, isFullTextSearch: true,
      sortDirection: newSortDirection ?? sortDirection,
      // eslint-disable-next-line react/prop-types
      sortField: newSortField?.key ?? sortField?.key,
      ownerClass,
      ownerIds: ownerIdsState,
      ownerClassColumnName: ownerClassColumnNameState,
    }).then((result) => {
      const { data, total } = result;
      fetchDataCallback(data);
      setData(data);
      setTotal(total);
    }).finally(() => setLoading(false))
      .catch(e => console.error(`Failed to search ${domainName} with condition ${fullTextConditions}: ${e}`));

  }, [
    domainName, fetchDataCallback, pageSize,
    searchKeyword, sortDirection, sortField, ownerClass,
    ownerIdsState, ownerBlank, additionalConditions, formExtInfo,
    prepareFullTextSearchCondition, ownerClassColumnNameState
  ]);

  useEffect(() => {
    setSelectedData([]);
    onSearch(searchKeyword, {}, additionalConditions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, ownerIdsState, ownerClassColumnNameState, ownerClass, additionalConditions, searchKeyword]);

  // const onSelectLabel = (selectedKeys: Array<number>): void => {
  //   if (selectedKeys.length > 0) {
  //   }
  // };

  const elements: Array<ReactElement> = additionalSearchColumns
    .map(column => {
      const { key, title, type } = column;
      const props = {
        setSearchInputs: emptyMethod,
        refreshSearchResult: () => {
          const cc = getSearchConditionsForDomain(domainName) ?? {};
          setAdditionalConditions(cc);
          onSearch(searchKeyword, {}, cc);
        },
        domainName,
        searchInputs: {},
        column,
        ownerClass,
        ownerIds: ownerIdsState,
        ownerClassColumnName: ownerClassColumnNameState,
        max: pageSize,
        current,
        zIndex,
        clearFilters: () => {
          removeSearchConditions(domainName, key);
        },
        confirm: emptyMethod,
        selectedKeys: [],
        setSelectedKeys: emptyMethod,
      };
      let currCondition = null;
      for (const conditionKey in additionalConditions) {
        if (!Object.prototype.hasOwnProperty.call(additionalConditions, conditionKey)) {
          continue;
        }
        if (conditionKey === key) {
          currCondition = additionalConditions[conditionKey];
          break;
        }
      }
      const matchMode = `matchMode:${MatcherDisplay[currCondition?.matchMode ?? "="]}`;
      const matchValue = Array.isArray(currCondition?.value) ?
        currCondition?.value.map((v: SearchValue) =>
          <Tag key={v?.toString()} className="search-condition-field">{v}</Tag>) : currCondition?.value;
      const value = (isObjectType(type)) ?
        <ObjectIdsDisplayComponent
          text={matchValue?.toString().split(",")}
          record={{
            id: -1,
            [key]: matchValue,
            objectType: type,
          } as RecordProps}
          columnKey={key}
          zIndex={zIndex}
          flatDisplay={true}
        /> : matchValue;
      const titleDisplay = (currCondition == null) ?
        title : <>{title} {i18n.t(matchMode)} {value}</>;
      return (
        <Popover
          trigger={["click"]}
          key={key}
          content={
            <div>
              <SearchComponent {...props} />
            </div>
          }
        >
          <Tag
            key={key}
            title={title}
            className="search-condition-tag"
          >
            {titleDisplay}
          </Tag>
        </Popover>
      );
    });

  const sortCallback = (column?: TableMetaProps, direction?: SortDirection): void => {
    setSortField(column);
    setSortDirection(direction);
    onSearch(searchKeyword, {
      newSortField: column,
      newSortDirection: direction
    }, additionalConditions);
  };

  const sortContent = (<Space
    direction="vertical"
    size={4}
  >
    <span
      style={{ cursor: "pointer", width: "100%", display: "block" }}
      onClick={() => sortCallback(undefined, undefined)}
    >
      <CloseCircleOutlined /> {t('Clear sort')}
    </span>
    {sortColumns
      .map(c => {
        return (
          <Space
            direction="vertical"
            key={c.key}
            size={4}
            style={{ width: "100%" }}
          >
            <span
              style={{ cursor: 'n-resize', width: "100%", display: "block" }}
              onClick={() => sortCallback(c, 'asc')}
            >
              <SortDescendingOutlined /> {c.title}</span>
            <span
              style={{ cursor: 's-resize', width: "100%", display: "block" }}
              onClick={() => sortCallback(c, 'desc')}
            ><SortAscendingOutlined /> {c.title}</span>
          </Space>
        );
      })}
  </Space>);

  const result = (
    (data == null) ?
      emptyElem('Please search above') : ((data?.length === 0) ?
        emptyElem('No record found') : (
          <List
            style={{ height }}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 1,
              xxl: 1,
            }}
            dataSource={data}
            renderItem={item => (
              <List.Item key={item.id}>
                <CardSearchResultCell
                  zIndex={zIndex}
                  searchKeyword={searchKeyword}
                  changeCallback={(record, selected) => {
                    if (selected) {
                      selectedData.push(record);
                    } else {
                      selectedData.splice(selectedData.indexOf(record, 1));
                    }
                    setSelectedData(selectedData);
                    changeCallback(record, selected);
                  }}
                  updateFormId={updateFormId}
                  record={item}
                  domainName={domainName}
                  columns={columns}
                  contentDisplayColumns={contentDisplayColumns}
                  panelDisplayColumns={panelDisplayColumns}
                  key={item.id}
                />
              </List.Item>
            )}
          />
        ))
  );

  const refreshTableIcon = (
    <ReloadOutlined
      title={t("Refresh data table")}
      className="link-icon refresh-icon"
      onClick={() => onSearch(searchKeyword, {}, additionalConditions)}
    />
  );

  const createIcon = (
    <CreateAction
      initShowCreateModal={initShowCreateModal}
      domainName={domainName}
      ownerId={ownerIdsState?.[ownerIdsState?.length - 1]}
      ownerClass={ownerClass}
      callback={(): void => onSearch(searchKeyword, {}, additionalConditions)}
      zIndex={zIndex + 1}
      showIconAndText={true}
    />
  );

  const [visiblePopover, setVisiblePopover] = useState<string>();
  const actionMode = (selectedData.length > 0) ? "multiple" : "class";
  const actionButtons = (<span className="action-icons">
    <ActionButtons
      visiblePopover={visiblePopover}
      setVisiblePopoverCallback={(key?: string) => setVisiblePopover(key)}
      domainName={domainName}
      fetchDataCallback={() => fetchDataCallback(data ?? [])}
      mode={actionMode}
      selectedData={selectedData}
      zIndex={zIndex}
    />
  </span>);

  const hasCondition = (additionalConditions != null) && (Object.keys(additionalConditions).length > 0)
    || (searchKeyword != null && searchKeyword != '')
    || (sortField != null && sortDirection != null)
    || (ownerIdsState != null && ownerIdsState.length > 0
      && ownerClassColumnNameState != null && ownerClassColumnNameState.length > 0
      && ownerClass != null);

  const saveFilterButton = (
    <span className="save-filter-icon">
      {hasCondition ? (<SaveDynamicFilterPopover
        fullTextSearchConditions={{
          domainName,
          searchConditions: { ...additionalConditions, ...formExtInfo?.extInfo?.conditions },
          fullTextConditions: prepareFullTextSearchCondition(),
          max: pageSize,
          current: 0,
          isFullTextSearch: true,
          sortDirection: sortDirection,
          // eslint-disable-next-line react/prop-types
          sortField: sortField?.key,
          ownerClass,
          ownerIds: ownerIdsState,
          ownerClassColumnName: ownerClassColumnNameState,
          keyword: searchKeyword,
        }}
        domainName={domainName}
        zIndex={zIndex + 1}
        updateCallback={() => refresh()}
        displayElement={<Button
          icon={<SaveOutlined title={t("Save filter")} style={{ cursor: "pointer", marginRight: "-4px" }} />}
          size="small"
          type="link"
        >{t('Create dynamic filter')}</Button>}
      />) : <></>}
    </span>
  );

  const searchPanel = (
    <div className='search-box-container'>
      <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
        <span>
          <Search
            autoFocus={true}
            loading={loading}
            placeholder={t("Input search condition")}
            allowClear
            enterButton={t("Search")}
            size="large"
            onSearch={(value: string) => {
              //如果之前的搜索条件不为空，输入框中的新的搜索条件为空
              //表示是清空搜索框，这种情况下，不发送搜索请求
              if (isNotBlank(searchKeyword) && isBlank(value)) {
                setSearchKeyword('');
                return;
              }
              onSearch(value, {}, additionalConditions);
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            value={searchKeyword}
          />
          {createIcon}
          {refreshTableIcon}
          {selectedData?.length > 0 && actionButtons}
        </span>
        <Space direction="horizontal" size="small">
          <div>
            <span>{t('Other filter conditions')}</span>
            <span>{elements}</span>
          </div>
          <div>
            <span>{t('Result sorting')}</span>
            <Popover
              content={sortContent}
              placement="bottom"
              trigger="click"
            >
              <Tag className='search-condition-tag'>
                {getSortLabel()}
              </Tag>
            </Popover>
          </div>
          <div style={{ paddingTop: "4px" }}>
            {saveFilterButton}
          </div>
        </Space>
        <PredefinedFilters
          key={refreshPredefinedFilters}
          domainName={domainName}
          fetchDataCallback={emptyMethod}
          fullTextFetchDataCallback={(conditions) => {
            setSearchKeyword(conditions?.keyword ?? "");
            setSortDirection(conditions?.sortDirection ?? undefined);
            setSortField(conditions?.sortField ?
              columns.find((column) => column.key === conditions.sortField) : undefined);
            setAdditionalConditions(conditions?.searchConditions ?? {});
            setOwnerIds?.(conditions?.ownerIds ?? []);
            setOwnerIdsState(conditions?.ownerIds ?? []);
            setOwnerClassColumnNameState(conditions?.ownerClassColumnName ?? "");
          }}
          columns={columns}
          displayStyle="inline"
          zIndex={zIndex}
          fullTextSearchConditions={{
            domainName,
            searchConditions: { ...additionalConditions, ...formExtInfo?.extInfo?.conditions },
            fullTextConditions: prepareFullTextSearchCondition(),
            max: pageSize,
            current: 0,
            isFullTextSearch: true,
            sortDirection: sortDirection,
            // eslint-disable-next-line react/prop-types
            sortField: sortField?.key,
            ownerClass,
            ownerIds: ownerIdsState,
            ownerClassColumnName: ownerClassColumnNameState,
            keyword: searchKeyword,
          }}
        />
      </Space>
    </div>
  );

  const pagination = (
    <Pagination
      {...getPagination({ current, total, pageSize })}
      hideOnSinglePage={false}
      onChange={(page: number, pageSize?: number) => {
        setCurrent(page);
        setPageSize(pageSize ?? FullTextSearchDefaultPageSize);
        onSearch(searchKeyword, { newCurrent: page, newPageSize: pageSize }, additionalConditions);
      }}
      onShowSizeChange={(current: number, pageSize: number) => {
        setCurrent(current);
        setPageSize(pageSize);
        onSearch(searchKeyword, {
          newCurrent: current,
          newPageSize: pageSize
        }, additionalConditions);
      }}
      responsive={true}
    />
  );

  const hasData = (data != null && data.length > 0);
  const addrClassName = hasData ? '' : 'full-text-search-panel-container-no-data';
  return (
    <div
      className={`full-text-search-panel-container ${addrClassName}`}
    >
      <Space
        direction="vertical"
        size={4}
      >
        {searchPanel}
        {result}
        {hasData && pagination}
      </Space>
    </div>
  );
};

export default FullTextSearchPanel;
