import { RecordValueType, TableMetaProps } from "@props/RecordProps";
import { SERVER_URL } from "@config/base";
import { getRawDomainName } from "@utils/ObjectUtils";
import { requestUrlAndGetPromise } from "@utils/FetchUtils";


export enum ArrayColumnOperationsTypeEnum {
  ADD = "ADD",
  REMOVE = "REMOVE",
  EDIT = "EDIT",
  NOT_CHANGE = "NOT_CHANGE",
}

export interface ArrayColumnOption {
  operations: Record<ArrayColumnOperationsTypeEnum, number[]>;
  ownerColumnName?: string;
}

export interface EntityAttributeValues {
  [propName: string]: RecordValueType;
}

export async function getEntityAttributeMeta(templateDomainName: string, id: number): Promise<TableMetaProps[]> {
  return requestUrlAndGetPromise(`${SERVER_URL}/template/attribute/metas/${getRawDomainName(templateDomainName)}/${id}`, {}, { useCache: false });
}

export async function getEntityAttributeValues(instanceDomainName: string, id: number): Promise<EntityAttributeValues> {
  return requestUrlAndGetPromise(`${SERVER_URL}/template/attribute/values/${getRawDomainName(instanceDomainName)}/${id}`, {}, { useCache: false });
}

export async function getEntityAttributeDefaultValues(templateDomainName: string, id: number): Promise<EntityAttributeValues> {
  return requestUrlAndGetPromise(`${SERVER_URL}/template/attribute/default/values/${getRawDomainName(templateDomainName)}/${id}`, {}, { useCache: false });
}

