import { Col, Row } from 'antd';
import React, { ReactElement } from 'react';

export interface MasterDetailVerticalLayoutProps {
  headerOnClick?: () => void;
  header: ReactElement;
  content: ReactElement;
  headerClassName?: string;
  contentClassName?: string;
}

const MasterDetailVerticalLayout = (props: MasterDetailVerticalLayoutProps): ReactElement => {
  const {
    header, headerOnClick, content,
    headerClassName, contentClassName,
  } = props;

  return (
    <div className='md-vertical-layout'>
      <Row>
        <Col
          onClick={headerOnClick}
          className={`md-layout-header ${headerClassName ?? ""}`}
          span={24}
        >
          {header}
        </Col>
      </Row>
      <Row>
        <Col
          className={`md-layout-content ${contentClassName ?? ""}`}
          span={24}
        >
          {content}
        </Col>
      </Row>
    </div>
  );
};

export default MasterDetailVerticalLayout;
