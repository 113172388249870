import React, { useEffect, useMemo } from "react";
import {
  GetColumnValueCallback,
  RecordProps,
  SaveOptionProps,
  SaveRecordProps,
  Store,
  TableMetaProps
} from "@props/RecordProps";

import './entityAttributes.less';

import { Empty, Form, FormInstance, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { EntityAttributeValues, getEntityAttributeMeta, } from "./EntityAttributesUtils";
import { EntityAttributesCell, EntityAttributesCellProps } from "./EntityAttributesCell";
import { getEditableController } from "@kernel/EditableComponentsMapping";
import { getDisplayRenderFunction, transformRecord } from "@kernel/DisplayComponentsMapping";
import { getCustomValueCallback } from "@utils/FormUtils";
import { buildPath, EVENT_BUS, resolvePath } from "@utils/eventBus/EventBus";

export interface EntityAttributesFormProps {
  column?: TableMetaProps;
  value?: EntityAttributeValues;
  ownerClass: string;
  // columnKey: string;
  owner?: SaveRecordProps;
  form?: FormInstance;
  editMode: boolean;
  zIndex: number;
  saveOptions?: SaveOptionProps;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  path?: string;
}

export interface DisplayRenderProps {
  record: EntityAttributeValues;
}

export interface EditableRenderProps {
  record: EntityAttributeValues;
}

const DEFAULT_GROUP_NAME = -1;

const extractObjectId = (value: unknown): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'object') {
    return (value as RecordProps).id;
  } else {
    return value as number;
  }
};

const EntityAttributesTable = (props: EntityAttributesFormProps): React.ReactElement<EntityAttributesFormProps> => {
  const {
    column, ownerClass, owner, zIndex, path,
    editMode, form, saveOptions, value,
  } = props;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [attributeMetas, setAttributeMetas] = React.useState<TableMetaProps[]>();
  const [attributeValues, setAttributeValues] = React.useState<EntityAttributeValues>({});
  const [templateDomainId, setTemplateDomainId] = React.useState<number | undefined>(undefined);

  const [innerForm] = Form.useForm();

  const templateFieldName = column?.templateFieldName;
  const templateDomainName = column?.templateDomainName;
  const ownerId = owner?.id;

  useEffect(() => {
    if (templateFieldName) {
      if (form) {
        setTemplateDomainId(extractObjectId(form.getFieldValue(templateFieldName)));
      } else if (owner) {
        setTemplateDomainId(extractObjectId(owner[templateFieldName]));
      }
    }
  }, [templateFieldName, form, owner]);

  useEffect(() => {
    if (path && templateFieldName) {
      return EVENT_BUS.on(resolvePath(path, `../${templateFieldName}`),
        (event) => {
          const { payload } = event;
          setTemplateDomainId(extractObjectId(payload));
        });
    }
  }, [path, templateFieldName]);

  useEffect(() => {
    if (column?.key && templateFieldName && saveOptions) {
      saveOptions.dynamicTemplateAttributesFieldOptions[column.key] = {
        templateFieldName: templateFieldName,
      };
    }
  }, [templateFieldName, column?.key, saveOptions]);

  useEffect(() => {
    if (!form || !column?.key) {
      return;
    }
    const getColumnValueRecord: Record<string, GetColumnValueCallback> = getCustomValueCallback(form);
    if (getColumnValueRecord) {
      getColumnValueRecord[column.key] = () => innerForm.getFieldsValue(true);
    }
  }, [form, column?.key, innerForm]);

  useEffect(() => {
    // console.log("getEntityAttributeMeta", templateDomainId, templateDomainName);
    if (templateDomainId && templateDomainName) {
      getEntityAttributeMeta(templateDomainName, templateDomainId).then(setAttributeMetas);
    } else {
      setAttributeMetas([]);
    }
  }, [templateDomainId, templateDomainName]);

  useEffect(() => {
    if (!attributeMetas) {
      return;
    }
    if (!column) {
      setLoading(false);
      return;
    }
    let res: RecordProps;
    if (value) {
      res = value as RecordProps;
    } else {
      res = {} as RecordProps;
      attributeMetas.forEach((meta) => {
        res[meta.key] = meta.defaultValue;
      });
    }
    const data = transformRecord(attributeMetas, res as RecordProps);
    innerForm.setFieldsValue(data);
    setAttributeValues(data);
    setLoading(false);
  }, [value, ownerId, column, ownerClass, innerForm, templateDomainId, templateDomainName, attributeMetas]);

  const tableColumns = useMemo(() => {
    if (!attributeMetas) {
      return undefined;
    }
    const filteredMetas = attributeMetas.filter((c) => c.display !== false);
    const columnGroupsRecord: Record<number, TableMetaProps[]> = {};
    const columnGroups: number[] = [];
    filteredMetas.forEach((meta) => {
      const columnGroup = meta.extInfo?.rowSequence ?? DEFAULT_GROUP_NAME;
      if (!columnGroupsRecord[columnGroup]) {
        columnGroupsRecord[columnGroup] = [];
        columnGroups.push(columnGroup);
      }
      columnGroupsRecord[columnGroup].push(meta);
    });
    return columnGroups
      .sort((a, b) => a - b)
      .map(groupIndex => {
        const columns = columnGroupsRecord[groupIndex];
        const result = columns
          .sort((a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0))
          .map((c) => {
            const { key, type, title, multiple, enumOptions } = c;
            const columnPath = buildPath(path ?? "", key);
            const editableController = getEditableController(type);
            let EditableRender: React.FC<EditableRenderProps> | undefined = undefined;
            if (editableController) {
              EditableRender = ({ record }) => {
                return editableController({
                  updatable: editMode && ((ownerId && ownerId > 0) ? c.updatable !== false : true),
                  fieldValue: innerForm.getFieldValue(key) ?? c.defaultValue,
                  type,
                  fieldTitle: title,
                  form: innerForm,
                  key,
                  domainName: "",
                  record,
                  zIndex,
                  multiple: !!multiple,
                  column: c,
                  style: {
                    flex: 1,
                  },
                  enumOptions,
                  path: columnPath,
                });
              };
            }
            const displayController = getDisplayRenderFunction({
              column: c,
              enumValues: {},
              objectValues: {},
              domainName: "",
              page: "LIST",
              zIndex,
              form: innerForm,
            });
            const DisplayRender: React.FC<DisplayRenderProps> = ({ record }) => {
              const value = record[c.key];
              return displayController(value, record as RecordProps);
            };
            return {
              key: c.key,
              dataIndex: c.key,
              title: c.title,
              ellipsis: true,
              onCell: (record: EntityAttributeValues): EntityAttributesCellProps => ({
                column: c,
                record,
                editMode,
                DisplayRender,
                EditableRender,
                path: columnPath,
              }),
            };
          });
        return <Table
          key={groupIndex}
          pagination={false}
          className="entity-attributes-table-container"
          columns={result as ColumnsType<EntityAttributeValues>}
          components={{
            body: {
              cell: EntityAttributesCell,
            },
          }}
          // bordered
          dataSource={[attributeValues]}
          size="small"
        />;
      });
  }, [attributeValues, innerForm, attributeMetas, zIndex, editMode, path, ownerId]);

  // console.log("EntityAttributesTable: ", tableColumns, templateDomainId);

  return (loading ? <Spin/> :
      <Form
        form={innerForm}
        initialValues={attributeValues}
        component={false}
      >
        <div className="entity-attributes-table-panel">
          <Space direction="vertical">
            {!tableColumns || tableColumns.length === 0 ?
              <Empty className="empty-content" image={Empty.PRESENTED_IMAGE_SIMPLE}/> : tableColumns}
          </Space>
        </div>
      </Form>
  );
};


export default EntityAttributesTable;
