import React, { Suspense, ReactElement } from "react";
import remarkGfm from 'remark-gfm';
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";

const ReactMarkdown = React.lazy(() => import('react-markdown'));

const ReactMarkdownWrap = (props: ReactMarkdownOptions): ReactElement => {
  return (<Suspense fallback={<div />}>
    <ReactMarkdown
      {...props}
      remarkPlugins={[remarkGfm]}
    />
  </Suspense>);
};

export default ReactMarkdownWrap;
