import React, { useState, ReactElement } from "react";
import { useTranslation } from 'react-i18next';
import { Space } from "antd";
import { SisternodeOutlined } from '@ant-design/icons';
import { ListOperations } from "../../form";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { ExtendDataNode, RecordProps } from "@props/RecordProps";
import DynamicIcon from "../../components/icons/DynamicIcon";
import { RedirectComponent } from "../../components/redirect";

export type TreeNodeOperation = "edit" | "addChild";

interface TreeTitleRenderProps<T extends ExtendDataNode> {
  node: T;
  domainName: string;
  zIndex: number;
  updateDataCallback: (node: T, operation: TreeNodeOperation) => void;
  deleteCallback: (node?: RecordProps) => void;
  selected: boolean;
  canCreate: boolean;
  setCurrentShowMenuOrModalNode: (node?: ExtendDataNode) => void;
  currentShowMenuOrModalNode?: ExtendDataNode;
  updateFormName?: string;
}

const TreeTitleRender = <T extends ExtendDataNode>(props: TreeTitleRenderProps<T>): ReactElement => {
  const {
    node, zIndex, domainName, updateDataCallback, selected, canCreate,
    currentShowMenuOrModalNode, setCurrentShowMenuOrModalNode, deleteCallback,
    updateFormName
  } = props;
  const [showAddChildModal, setShowAddChildModal] = useState<boolean>();
  const showMenuOrModal = (currentShowMenuOrModalNode?.id === node.id);
  const { t } = useTranslation();

  const content = (
    <Space className="tree-node-operation-container">
      <ListOperations
        id={node.key as number}
        domainName={domainName}
        deleteCallback={deleteCallback}
        updateCallback={() => updateDataCallback(node, "edit")}
        zIndex={zIndex + 2}
        page={'list'}
        showActions={true}
        updateFormName={updateFormName}
      />
      {canCreate && <SisternodeOutlined
        onClick={(e) => {
          stopPropagationAndPreventDefault(e);
          setShowAddChildModal(true);
          setCurrentShowMenuOrModalNode(node as ExtendDataNode);
        }}
        title={t('Add new child node')}
      />}
    </Space>
  );

  return (
    <Space
      direction="horizontal"
      onMouseEnter={() => {
        setCurrentShowMenuOrModalNode(node as ExtendDataNode);
      }}
      className="tree-node-title-container"
    >
      {node.icon && <DynamicIcon type={node.icon}/>}
      <span>{node.title}</span>
      {(showMenuOrModal || selected) && content}
      {(showMenuOrModal || selected) && showAddChildModal &&
        <RedirectComponent
          forMultiple={false}
          fetchDataCallback={() => {
            updateDataCallback(node, "addChild");
            setShowAddChildModal(false);
            setCurrentShowMenuOrModalNode(undefined);
          }}
          redirect={`/${domainName}/create`}
          zIndex={zIndex + 1}
          showText={false}
          ownerClass={domainName}
          ownerId={node.key as number}
          columnNameInOwnerClass="children"
          hasRelateObjectField={true}
        />
      }
    </Space>
  );
};

export default TreeTitleRender;
