import { SERVER_URL } from '@config/base';
import { SearchConditionProps, SearchConditions, SearchValue, TableMetaProps } from '@props/RecordProps';
import { getDefaultMatcher } from "@kernel/ServerSideSearcherMapping";

/**
 * Set finder condition user inputed to session storage User inputed conditions
 * contains only value, and this method will transfer them to valid search
 * condition object, and set to session storage
 * @param domainName domain name
 * @param conditions conditions
 * @param columns columns meta of the to search domain
 */
export function setFinderCondition(domainName: string,
  conditions: {
    [propName: string]: SearchValue
  }, columns: Array<TableMetaProps>): void {
  const searchConditions: SearchConditions = {} as SearchConditions;
  for (const property in conditions) {
    if (!Object.prototype.hasOwnProperty.call(conditions, property)) {
      continue;
    }
    const val = conditions[property];
    const isNonEmptyString = (typeof val === 'string' && val !== '');
    const isNonEmptyNumber = (typeof val === 'number' && !isNaN(val));
    const isBoolean = (typeof val === 'boolean');
    const notNull = (val != null);
    const isNonEmptyArray = (Array.isArray(val) && (val as Array<unknown>).length > 0);
    if (notNull && (isNonEmptyString || isNonEmptyArray || isNonEmptyNumber || isBoolean)) {
      const condition: SearchConditionProps = {} as SearchConditionProps;
      condition.columnKey = property;
      const columnMeta = columns.find(column => column.key === property);
      if (columnMeta != null) {
        condition.matchMode = getDefaultMatcher(columnMeta);
        condition.value = val;
        searchConditions[property] = condition;
      }
    }
  }
  saveFinderConditionsToCache(domainName, searchConditions);
}

/**
 * Save finder conditions to session storage cache
 * @param domainName domain to search
 * @param conditions Conditions
 */
export function saveFinderConditionsToCache(domainName: string, conditions: SearchConditions): void {
  if (conditions != null) {
    sessionStorage.setItem(
      getFinderConditionKey(domainName), JSON.stringify(conditions)
    );
  }
}

export function getFinderConditionKey(domainName: string): string {
  return `${domainName}_${SERVER_URL}_finderConditions`;
}

/**
 * clear finder conditions from sessionStorage
 * @param domainName name of the domain to clear
 */
export function clearFinderConditions(domainName: string): void {
  sessionStorage.removeItem(getFinderConditionKey(domainName));
}

export function getFinderConditions(domainName: string): SearchConditions {
  const cache = sessionStorage.getItem(getFinderConditionKey(domainName));
  return (cache == null)? cache: JSON.parse(cache);
}
