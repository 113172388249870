import { Dropdown, Menu, Space, Tag } from "antd";
import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import { ClockCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import {getRefreshIntervalDisplay} from "@utils/StringUtils";

const RefreshIntervalSelect = (props: {
  refreshInterval?: number;
  setRefreshIntervalCallback: (interval?: number) => void;
}): ReactElement => {
  const {refreshInterval, setRefreshIntervalCallback} = props;
  const {t} = useTranslation();

  const menu = (
    <Menu>
      {[undefined, 10, 60, 120, 300, 900].map((interval: number | undefined) =>
        <Menu.Item key={interval ?? "no_auto_refresh"}
                   onClick={() => setRefreshIntervalCallback(interval)}>
          <Space><ClockCircleOutlined />{getRefreshIntervalDisplay(interval)}</Space>
        </Menu.Item>
      )}
    </Menu>);

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Tag><ReloadOutlined/>{t('Refresh interval', {interval: getRefreshIntervalDisplay(refreshInterval)})}</Tag>
    </Dropdown>
  );
};

export default RefreshIntervalSelect;