import React, { ReactElement, useState } from "react";
import { Drawer, FormInstance } from "antd";
import { ContainerOutlined } from "@ant-design/icons";
import { DetailPanelProps, SaveRecordProps } from "@props/RecordProps";
import './app.css';
import DocumentDetail from "./DocumentDetail";
import { getLabelToDisplay, stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { CloseIcon } from "../icons";

const DocumentDisplay = (props: DetailPanelProps): ReactElement => {
  const { zIndex, column, record} = props;
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  return (<>
    <ContainerOutlined
      onClick={() => setDrawerVisible(true)}
    />
    <div onClick={stopPropagationAndPreventDefault}>
      <Drawer
        rootClassName="document-display-container"
        width="910px"
        rootStyle={{ margin: "auto" }}
        destroyOnClose={true}
        zIndex={zIndex + 2}
        title={getLabelToDisplay(record ?? {} as SaveRecordProps, undefined)}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closeIcon={<CloseIcon onClick={setDrawerVisible} />}
      >
        <DocumentDetail
          column={column}
          form={{} as FormInstance}
          isCurrentActiveTab={true}
          ownerClass={""}
          record={record}
          zIndex={zIndex + 1}
          readonly={true}
        />
      </Drawer>
    </div>
  </>);
};

export default DocumentDisplay;
