import { OperationProps, RecordProps, TableMetaProps } from "@props/RecordProps";
import React, { ReactNode } from "react";
import { Tooltip } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { ActionColumnKey } from '@config/base';
import i18n from "@config/i18n";
import Operations from "../list/Operations";

const operationColumn = (props: OperationProps): TableMetaProps => {
  const {
    ownerClass,
    ownerId,
    domainName,
    fetchDataCallback,
    deleteCallback,
    zIndex,
    updateFormName
  } = props;
  return {
    title: (<Tooltip title={i18n.t("ActionColumnTooltipTitle")}><EditOutlined /></Tooltip>),
    fieldType: "TRANSIENT_FIELD",
    key: ActionColumnKey,
    align: "center",
    updatable: false,
    width: 80,
    fixed: "left",
    className: "fix-column",
    type: ActionColumnKey,
    render: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      text: string | Array<string | number>, record: RecordProps, index: number
    ): ReactNode => {

      return (
        <Operations
          /* useModal={tableMode === "detail"} */
          id={record.id}
          ownerId={ownerId}
          ownerClass={ownerClass}
          domainName={domainName}
          deleteCallback={(node?: RecordProps): void => {
            if (node != null) {
              deleteCallback(node.id);
            }
          }}
          updateCallback={fetchDataCallback}
          zIndex={zIndex + 2}
          page={"list"}
          showActions={true}
          updateFormName={updateFormName}
        />
      );
    },
  };
};

export default operationColumn;
