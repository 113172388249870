// define react GroupedGrandChildComponent component
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CellComponentDisplayPage,
  RecordProps,
  SortedRecordProps,
  TableMetaProps,
} from "@props/RecordProps";
import { CloseIcon, ErrorMsg } from "../index";
import {
  fetchDomainMeta,
  fetchFormIdAndExtInfoByName,
  fetchListOfRelateDomainData,
  saveDomain
} from "@utils/FetchUtils";
import { Drawer, FormInstance, Spin } from 'antd';
import { NodeIndexOutlined } from "@ant-design/icons";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import './groupedGrandChild.less';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { shouldDisplayIcon, supportDnd } from '@utils/ComponentUtils';
import GroupedGrandChildDetailGroups from "./GroupedGrandChildDetailGroups";
import update from "immutability-helper";
import { SERVER_URL } from "@config/base";
import { updateDisplaySequence } from "./updateDisplaySequence";
import { emptyMethod } from "@utils/Constants";
import { openInfoNotification } from '@utils/NotificationUtils';
import { capitalizeFirstLetter, removePackagePart, typeWithPackageToSimpleType } from '@utils/StringUtils';

interface GroupedGrandChildComponentProps {
  record: RecordProps;
  column: TableMetaProps;
  page: CellComponentDisplayPage;
  zIndex: number;
  domainName: string;
  form?: FormInstance,
}

const GroupedGrandChildComponent = (props: GroupedGrandChildComponentProps): ReactElement => {
  const {
    record,
    column,
    page,
    zIndex,
    domainName,
    form,
  } = props;

  const { extInfo, elementType, type, key } = column;
  const { displayForm } = extInfo ?? {};
  const { id: ownerId } = record;
  const [formId, setFormId] = useState<number>();
  const [visible, setVisible] = useState<boolean>((page === 'DISPLAY'));
  const [canExchange, setCanExchange] = useState<boolean>(false);
  const [data, setData] = useState<Array<SortedRecordProps>>([] as SortedRecordProps[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
  const [summaryGroupTitleColumns, setSummaryGroupTitleColumnsColumns] = useState<Array<TableMetaProps>>([]);
  const [summaryGroupExtraColumns, setSummaryGroupExtraColumns] = useState<Array<TableMetaProps>>([]);
  const [summaryGroupChildrenColumn, setSummaryGroupChildrenColumn] = useState<TableMetaProps>();
  const [canDnd, setCanDnd] = useState<boolean>(false);
  const [fallbackToListForm, setFallbackToListForm] = useState<boolean>(false);
  const groupDomainName = (elementType ?? type);
  const { t } = useTranslation();
  const [sortedData, setSortedData] = useState<Array<Array<SortedRecordProps>>>([]);

  //2. Get form group definition and all fields
  useEffect(() => {
    if (displayForm != null && visible) {
      setLoading(true);
      fetchFormIdAndExtInfoByName(groupDomainName, displayForm).then(json => {
        setFormId(json.id);
        fetchDomainMeta(groupDomainName, json.id).then((meta: Array<TableMetaProps>) => {
          setColumns(meta);
          setSummaryGroupTitleColumnsColumns(meta.filter(c => c.groupName?.startsWith('summaryGroupTitle')));
          setSummaryGroupExtraColumns(meta.filter(c => c.groupName?.startsWith('summaryGroupExtra')));
          const childColumns = meta.filter(c => c.groupName?.startsWith('summaryGroupChildren'));
          if (childColumns.length > 1) {
            console.warn("More than one child column found on summaryGroupChildren, will use first one: ", childColumns);
          }
          if (childColumns.length >= 1) {
            setSummaryGroupChildrenColumn(childColumns[0]);
          }
          if (childColumns.length === 0) {
            console.error("No child column found on summaryGroupChildren: ", meta);
            setFallbackToListForm(true);
          }
          return meta;
        })
          .finally(() => {
            setLoading(false);
            setCanExchange(true);
          })
          .catch(e => console.error(`Failed to get form ${formId} for domain ${domainName}: ${e}`));
      });
    } else {
      console.error("No displayForm extInfo defined for grouped grand child component: ", column);
    }
  }, [displayForm, elementType, formId, type, visible, domainName, groupDomainName, column]);

  const reload = useCallback(() => {
    fetchListOfRelateDomainData({
      domainName,
      columnName: key,
      ownerId,
      current: 1,
      max: 9999,
      useCache: false
    }).then(json => {
      const canDnd = supportDnd(columns);
      setCanDnd(canDnd);
      const sortedData = canDnd ?
        json.data.sort((a, b) => a.displaySequence - b.displaySequence) : json.data;
      setData(sortedData as SortedRecordProps[]);
      const initColumnData = [];
      for (let i = 0; i < sortedData.length; ++i) {
        initColumnData.push([]);
      }
      setSortedData(initColumnData);
    });
  }, [columns, key, domainName, ownerId]);

  useEffect(() => {
    if (visible) {
      reload();
    }
  }, [columns, domainName, key, ownerId, visible, reload]);

  const onExchange = (index: number, isDown: boolean): void => {
    if (!canExchange) {
      return;
    }
    // do nothing if the element is in the edge
    if ((isDown && index === data.length - 1) || (!isDown && index === 0)) {
      return;
    }
    const target = isDown ? index + 1 : index - 1;
    const newData = update(data, {
      [index]: { $set: data[target] },
      [target]: { $set: data[index] },
    });
    const [updatedDisplaySequenceNewData, changedElements] = updateDisplaySequence(newData, index);
    if (changedElements.length === 0) {
      return;
    }
    setData(updatedDisplaySequenceNewData);
    setSortedData((prevState) =>
      update(prevState, {
        [index]: { $set: prevState[target] },
        [target]: { $set: prevState[index] },
      })
    );
    setCanExchange(false);
    let failed = false;
    Promise.all(changedElements.map((element) =>
      saveDomain({
        domainName: groupDomainName,
        successCallback: emptyMethod,
        failCallback: () => {
          failed = true;
        },
        method: "PUT",
        url: `${SERVER_URL}/${groupDomainName}/${element.id}`,
        values: {
          displaySequence: element.displaySequence,
        },
        skipInfoNotification: true,
      }))).finally(() => {
        if (failed) {
          reload();
        } else {
          const dn = t(`domainTitle:${capitalizeFirstLetter(typeWithPackageToSimpleType(groupDomainName))}`);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          openInfoNotification(t('Save success', { domainTitle: dn }));
        }
        setCanExchange(true);
      });
  };

  //1. Get form definition from displayForm
  if (displayForm == null) {
    return <ErrorMsg errorMsg={t("displayForm is not defined on extInfo")} />;
  }
  const innerElement = (summaryGroupChildrenColumn == null) ? (<></>) : (
    <GroupedGrandChildDetailGroups
      data={data}
      ownerId={ownerId}
      ownerClass={domainName}
      reload={reload}
      onExchange={onExchange}
      sortedData={sortedData}
      setSortedData={setSortedData}
      domainName={groupDomainName}
      columns={columns}
      page={page}
      zIndex={zIndex}
      canDnd={canDnd}
      summaryGroupTitleColumns={summaryGroupTitleColumns}
      summaryGroupExtraColumns={summaryGroupExtraColumns}
      summaryGroupChildrenColumn={summaryGroupChildrenColumn}
      form={form ?? {} as FormInstance}
      column={column}
    />);

  const innerElementWithDnd = canDnd ? (
    <DndProvider backend={HTML5Backend}>
      {innerElement}
    </DndProvider>
  ) : innerElement;
  if (loading && visible) {
    return (<Spin />);
  }

  const title = (<span>
    {t(`domainTitle:${removePackagePart(groupDomainName)}`)}
  </span>
  );

  const myElement = shouldDisplayIcon(page) ? (
    <>
      <NodeIndexOutlined
        onClick={() => setVisible(true)}
      />
      <div onClick={stopPropagationAndPreventDefault}>
        <Drawer
          rootClassName="grouped-grand-child-display-container"
          width="600px"
          rootStyle={{ margin: "auto" }}
          destroyOnClose={true}
          zIndex={zIndex + 3}
          title={title}
          open={visible}
          onClose={() => setVisible(false)}
          closeIcon={<CloseIcon onClick={setVisible} />}
        >
          {innerElementWithDnd}
        </Drawer>
      </div>
    </>
  ) : <div className="grouped-grand-child-display-container">{innerElementWithDnd}</div>;
  const elementWithFallback = (fallbackToListForm) ? (
    <>{t('formSummaryGroupChildrenConfigError', { formId })}</>
  ) : myElement;
  return elementWithFallback;
};

export default GroupedGrandChildComponent;
