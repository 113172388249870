import React, { ReactElement  } from "react";
import {
  EnumMetaProps, MatchMode, ObjectSelectOptionProp,
  ObjectSelectOptionProps,
  SearchValue,
  SelectMetaProps, TableMetaProps
} from "@props/RecordProps";
import { DatePicker, Input, InputNumber, Select, Switch } from "antd";
import { isDateTimeType } from "@utils/ColumnsUtils";
import { HttpMethods, DateFormat, DateTimeFormat } from '@config/base';
import { EnumOrDynamicSelectSearchInput, Percentage, Currency, ObjectSelect } from '../form/fields';
import { getRawDomainName } from "@utils/ObjectUtils";
import { isMultiple } from "@kernel/ServerSideSearcherConfig";

export interface SearchInputProps {
  column: TableMetaProps;
  onUpdateSearchKeyword: (value: SearchValue, valueLabels?: { [propName: string]: string }) => void;
  commonProps: {
    [propName: string]: unknown;
  };
  performSearchCallback: () => Promise<void>;
  type?: string;
  defaultValue?: boolean | string | number | number[] | undefined;
  enumValue?: Array<EnumMetaProps>,
  dynamicSelectValue?: Array<SelectMetaProps>;
  ownerClass?: string;
  fieldName?: string;
  zIndex?: number;
  matchMode?: MatchMode;
}

export const getNumberSearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword, performSearchCallback } = props;
  return (
    <InputNumber
      className="search-input"
      onChange={(value) => {
        onUpdateSearchKeyword(value);
      }}
      {...commonProps}
      onPressEnter={performSearchCallback}
    />
  );
};

export const getPercentageSearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword, performSearchCallback } = props;
  return (
    <Percentage
      {...commonProps}
      className="search-input"
      onChange={(value) => {
        onUpdateSearchKeyword(value);
      }}
      onPressEnter={performSearchCallback}
    />
  );
};

export const getCurrencySearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword, performSearchCallback } = props;
  return (
    <Currency
      {...commonProps}
      className="search-input"
      onChange={(value) => {
        onUpdateSearchKeyword(value);
      }}
      onPressEnter={performSearchCallback}
    />
  );
};

export const getBooleanSearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword, defaultValue } = props;
  return (
    <div>
      <Switch
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultChecked={defaultValue == null ? true : defaultValue}
        className="search-switch"
        {...commonProps}
        onChange={(value) => {
          onUpdateSearchKeyword(value);
        }} />
    </div>
  );
};

export const getDateSearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword, type } = props;
  return (
    <div>
      <DatePicker
        style={{ width: "100%" }}
        className="date"
        showTime={isDateTimeType(type ?? "")}
        {...commonProps}
        format={isDateTimeType(type ?? "") ? DateTimeFormat : DateFormat}
        onChange={(dateMoment, dateString) => {
          if (dateString != null) {
            onUpdateSearchKeyword(dateString);
          }
        }}
      />
    </div>
  );
};

export const getHttpMethodSelectSearchInput = (props: SearchInputProps): ReactElement => {
  const { commonProps, onUpdateSearchKeyword } = props;
  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      className="search-input httpMethod"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(value) => onUpdateSearchKeyword(value)}
      {...commonProps}
    >
      {HttpMethods.map(opt => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)}
    </Select>
  );
};

export const getEnumOrDynamicSelectSearchInput = (props: SearchInputProps): ReactElement => {
  return <EnumOrDynamicSelectSearchInput {...props} />;
};

export const getObjectSearchInput = (props: SearchInputProps): ReactElement => {
  const {
    onUpdateSearchKeyword, type, defaultValue, ownerClass,
    fieldName, zIndex, column, matchMode,
  } = props;
  const transferredType = getRawDomainName(type ?? "");
  const multiple = isMultiple(matchMode);
  return (
    <div>
      <ObjectSelect
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultValue={defaultValue}
        style={{ width: "100%" }}
        domainName={transferredType}
        mode={multiple ? "multiple" : undefined}
        placeholder="Type to search"
        notFoundContent="No data found"
        operation="query"
        onChange={(
          val: string | Array<string>,
          option?: ObjectSelectOptionProp | ObjectSelectOptionProps
        ) => {
          const valueLabels: { [propName: string]: string } = {};
          if (Array.isArray(option)) {
            option?.forEach(opt => {
              valueLabels[opt.value] = opt.children;
            });
          } else if (option) {
            valueLabels[option.value] = option.children;
          }
          let valNum;
          if (Array.isArray(val)) {
            valNum = val.map(Number);
          } else {
            valNum = Number(val);
          }
          onUpdateSearchKeyword(valNum, valueLabels);
        }}
        ownerClass={ownerClass ?? ""}
        fieldName={fieldName ?? ""}
        zIndex={zIndex ?? 1}
        column={column}
      />
    </div>
  );
};

export const getStringSearchInput = (props: SearchInputProps): ReactElement => {
  const { onUpdateSearchKeyword, commonProps, performSearchCallback } = props;
  return (
    <Input
      className="search-input"
      onChange={(e) => {
        const { value } = e.target;
        onUpdateSearchKeyword(value);
      }}
      {...commonProps}
      onPressEnter={performSearchCallback}
    />
  );
};
