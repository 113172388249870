import { ActionColumnKey, IdColumnKey, IdColumnKeyInCsv } from '@config/base';
import { isObjectType } from "@utils/ColumnsUtils";
import { TableMetaProps } from "../props/RecordProps";
import i18n from "@config/i18n";
import { capitalizeFirstLetter, typeWithPackageToSimpleType } from '@utils/StringUtils';

/** 下载 CSV 导入模板时候要隐藏的列 */
export const HideColumns = [
  "lastUpdated", "version", "lastUpdatedBy", "createdBy", "dateCreated", ActionColumnKey, IdColumnKey
];

export function getCsvHeader(columns: Array<TableMetaProps>): Array<string> {
  // ID 列单独处理放在第一列，所以后续循环中要排除 ID 列
  const headers = [IdColumnKeyInCsv].concat(columns
    .filter(c => !HideColumns.includes(c.key))
    .filter(c => c.type !== 'array')
    .map(c => isObjectType(c.type) ? `${c.key}.${c.labelField}` : `${c.key}`));
  return headers;
}

export function getCsvHeaderTranslated(domainName: string, columns: Array<TableMetaProps>): Array<string> {
  const simpleDomainName: string = capitalizeFirstLetter(typeWithPackageToSimpleType(domainName));
  // ID 列单独处理放在第一列，所以后续循环中要排除 ID 列
  const headers = ["; " + IdColumnKeyInCsv].concat(columns
    .filter(c => !HideColumns.includes(c.key))
    .filter(c => c.type !== 'array')
    .map(c => {
      const columnTitle = i18n.t(`field:${simpleDomainName}.${c.key}`);
      const labelTitle = i18n.t(`field:${simpleDomainName}.${c.labelField}`);
      const linkPhrase = i18n.t("objectFieldLinkPhrase");
      return isObjectType(c.type) ? `${columnTitle}${linkPhrase}${labelTitle}` : `${columnTitle}`;
    }));
  return headers;
}
