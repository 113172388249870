import React, { ReactElement } from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import { parsePercentage } from "@utils/DecimalUtils";

const Percentage = (props: InputNumberProps): ReactElement => {
  return (
    <
      InputNumber
      {...props}
      formatter={value => `${value}%`}
      parser={parsePercentage()}
    />);
};

export default Percentage;
