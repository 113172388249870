import React, { ReactElement } from 'react';
import { GanttComponentProps } from './GanttComponent';

const GanttComponent = React.lazy(() => import("./GanttComponent"));

const GanttComponentWrap = (props: GanttComponentProps): ReactElement => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <GanttComponent {...props} />
    </React.Suspense>
  );
};

export default GanttComponentWrap;
