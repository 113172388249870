import React, { ReactElement } from "react";
import { Tooltip } from "antd";
import { useTranslation } from 'react-i18next';

import { MasterCollapseWidth } from '@config/base';

export interface DetailCollapseProps {
  domainName: string;
  label?: string
}

const DetailCollapse = (props: DetailCollapseProps): ReactElement => {
  const { domainName, label } = props;
  const { t } = useTranslation();
  const domainTitle = t(`domainTitle:${domainName ?? ''}`);
  const basicInfo = t('Basic Information');

  return (<div
    className="detail-collapse-bar"
    style={{
      width: MasterCollapseWidth,
    }}>
    <Tooltip
      placement="right"
      arrowPointAtCenter={true}
      title={t("Click to expand detail")}
    >
      <span style={{ fontSize: "120%", color: "darkgray" }}>
        {label ?? `${domainTitle}${basicInfo}`} &raquo;
      </span>
    </Tooltip>

  </div>);

};

export default DetailCollapse;
