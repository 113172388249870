import React, { ReactElement } from "react";
import { useTranslation } from 'react-i18next';

import { EnumMetaProps } from "@props/RecordProps";
import { Tag } from "antd";

interface StatusCellProps {
  value: "SUCCESS" | "FAILED" | "RUNNING" | "PARTIALLY_FAIL" | "NOT_START" |
  "NO_DATA_IMPORTED" | "INFO" | "ERROR" | "WARNING" | "IMPORTANT" | "EMERGENCY" |
  "NORMAL" | "STOPPED" | "MAINTENANCE" | "FAULT" | "UNKNOWN" | "NEW" | "IN_PROGRESS" |
  "RESOLVED" | "AUTO" | "MANUAL" | "RERUN" | "IDLE" | "STARVED" | "BLOCKED" | "DISABLED" |
  "DOWN_PLANNED" | "DOWN_UNPLANNED";
  field: string;
  mappings: { [id: string]: Array<EnumMetaProps> };
  size?: "big";
  type?: string;
}

export const StatusTagRgbColorByStatus = {
  "SUCCESS": "68, 204, 119",
  "FAILED": "238, 44, 74",
  "RUNNING": "38, 115, 221",
  "PARTIALLY_FAIL": "238, 44, 74",
  "SUCCESS_WITH_WARNING": "8, 151, 156",
  "NOT_START": "255, 184, 0",
  "NO_DATA_IMPORTED": "255, 184, 0",
  "INFO": "68, 204, 119",
  "ERROR": "238, 44, 74",
  "WARNING": "255, 184, 0",
  "IMPORTANT": "83, 29, 171",
  "ACTION_REQUIRED": "255, 184, 0",
  "AUTO": "68, 204, 119",
  "MANUAL": "83, 29, 171",
  "EMERGENCY": "238, 44, 74",
  "RERUN": "255, 184, 0",
  "NORMAL": "68, 204, 119",
  "STOPPED": "128, 128, 128",
  "MAINTENANCE": "255, 184, 0",
  "FAULT": "238, 44, 74",
  "UNKNOWN": "0, 0, 0",
  "NEW": "255, 184, 0",
  "IN_PROGRESS": "38, 115, 221",
  "RESOLVED": "68, 204, 119",
  "IDLE": "68, 204, 119",
  "STARVED": "238, 44, 74",
  "BLOCKED": "238, 44, 74",
  "DISABLED": "83, 29, 171",
  "DOWN_PLANNED": "128, 128, 128",
  "DOWN_UNPLANNED": "238, 44, 74",
};

const StatusEnumCell = (props: StatusCellProps): ReactElement => {
  const { value, size } = props;

  const emptySpan = (<span className="status-enum-cell" />);
  const { t } = useTranslation();

  if (value == null) {
    return emptySpan;
  }
  const colorCode = StatusTagRgbColorByStatus[value];
  const label = t(`status:${value}`);
  const rgbColor = `rgb(${colorCode})`;
  return (value in StatusTagRgbColorByStatus) ? (
    <span className={`status-enum-cell ${size ?? ""}`}>
      <Tag style={{
        backgroundColor: `rgba(${colorCode}, 0.098)`,
        borderColor: rgbColor, color: rgbColor,
      }} title={t(`status:${label}`)}>{label}</Tag>
    </span>
  ) : emptySpan;

};

export default StatusEnumCell;
