import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const SuffixIcon = (props: { updatable?: boolean }): ReactElement => {
  const { updatable } = props;
  const { t } = useTranslation();

  return (updatable == null || updatable) ?
    (<></>) : (
      <Tooltip title={t("Locked and can not be updated")}>
        <LockOutlined />
      </Tooltip>
    );
};

export default SuffixIcon;
