import React, { ReactElement, useState } from "react";
import { Popover, Space, Tag, Timeline } from "antd";
import { useTranslation } from 'react-i18next';

import { fetchCronInformation } from "@utils/FetchUtils";
import { LargeSpin } from "../../components";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface CronExpressionCellProps {
  value: string;
}

const CronExpressionCell = (props: CronExpressionCellProps): ReactElement => {
  const { value } = props;
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>();
  const [schedules, setSchedules] = useState<Array<string>>([]);
  const [from, setFrom] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  if (value === '') {
    return <div>Invalid expression</div>;
  } else {
    return (
      <Popover
        title={undefined}
        trigger="click"
        placement="rightBottom"
        content={loading ? (<LargeSpin />) : (
          <Space
            size={"middle"}
            direction={"vertical"}
            style={{ padding: "0 0.3rem" }}
          >
            <div style={{ paddingTop: "0.5rem" }}>
              <Space direction="vertical">
                <Tag className="cron-exp-tag">
                  {t('SCHEDULES')}
                  <a
                    href="https://docs.spring.io/spring-framework/docs/current/reference/html/integration.html#scheduling-cron-expression"
                    rel="noopener noreferrer"
                    title={t("Spring cron expression explanation")}
                    target="_blank"
                    style={{ paddingLeft: "10px" }}
                  >
                    <QuestionCircleOutlined />
                  </a>
                  <br /> <span>{description?.toUpperCase()}</span>
                </Tag>
                <Tag className="cron-schedule-tag">
                  {t('NEXT 5 RUNS AFTER', { from })}<br />
                </Tag>
              </Space>
            </div>
            <Timeline className="cron-exp-timeline">
              {schedules?.map((schedule, idx) => {
                return (
                  <Timeline.Item key={idx}>{schedule}</Timeline.Item>
                );
              })}
            </Timeline>
          </Space>
        )}
      >
        <Tag
          className="cron-exp"
          title={t("Click to show cron expression and schedule details")}
          onClick={() => {
            setLoading(true);
            fetchCronInformation(encodeURIComponent(value))
              .then(json => {
                setFrom(json.from);
                setDescription(json.description);
                setSchedules(json.schedules);
              }).finally(() => {
                setLoading(false);
              });
          }}
        >{value}
        </Tag>
      </Popover>
    );
  }
};

export default CronExpressionCell;
