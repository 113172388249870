import React, { ReactElement, useState } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { RedirectComponent } from "../../components/redirect";
import { CustomIcon } from '@config/base';

const ClickableURL = (props: {
  url: string;
  zIndex: number;
}): ReactElement => {
  const { url, zIndex } = props;
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const isRelativeURL = url.startsWith('/');
  const [redirect, setRedirect] = useState<boolean>(false);

  if (isAbsoluteURL || isRelativeURL) {
    return (
      <div>
        <a
          href='/#'
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            if (isAbsoluteURL) {
              window.open(url, '_blank');
            } else {
              setRedirect(true);
            }
          }}
          title="Open link in a new tab"
        >
          <Tag
            className="action-url-tag"
            icon={<CustomIcon type='icon-link' />}
          >
            {url}
          </Tag>
        </a>
        {
          redirect && (
            <RedirectComponent
              redirect={url}
              zIndex={zIndex + 1}
              forMultiple={false}
              showText={false}
              fetchDataCallback={() => setRedirect(false)}
            />
          )
        }
      </div>
    );
  } else {
    return <span>{url ?? ''}</span>;
  }
};

ClickableURL.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ClickableURL;
