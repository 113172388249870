import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { Collapse, Empty, FormInstance, Space } from "antd";
import {
  CellComponentDisplayPage,
  FormProps,
  RecordProps,
  SortedRecordProps,
  TableMetaProps
} from "@props/RecordProps";
import { CaretRightOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import GroupedGrandChildGroupTitle from "./GroupedGrandChildGroupTitle";
import GroupedGrandChildDetailGroup from "./GroupedGrandChildDetailGroup";
import GroupedGrandChildGroupExtra from "./GroupedGrandChildGroupExtra";
import GroupedGrandChildDetailFooter from "./GroupedGrandChildDetailFooter";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { useTranslation } from "react-i18next";
import PageHeader from "../../form/PageHeader";
import { emptyMethod } from "@utils/Constants";
import update from "immutability-helper";
import { openInfoNotification } from "@utils/NotificationUtils";

const { Panel } = Collapse;

export interface GroupedGrandChildDetailGroupsProps {
  data: Array<RecordProps>;
  ownerId: number;
  ownerClass: string;
  reload: () => void;
  onExchange: (index: number, isDown: boolean) => void;
  sortedData: SortedRecordProps[][];
  setSortedData: Dispatch<SetStateAction<SortedRecordProps[][]>>;
  domainName: string;
  columns: Array<TableMetaProps>;
  page: CellComponentDisplayPage,
  zIndex: number;
  canDnd: boolean;
  summaryGroupTitleColumns: Array<TableMetaProps>;
  summaryGroupChildrenColumn: TableMetaProps;
  summaryGroupExtraColumns: Array<TableMetaProps>;
  form: FormInstance;
  column: TableMetaProps;
}

const GroupedGrandChildDetailGroups = (props: GroupedGrandChildDetailGroupsProps): ReactElement => {
  const {
    data,
    ownerId,
    ownerClass,
    reload,
    onExchange,
    sortedData,
    setSortedData,
    domainName,
    page,
    zIndex,
    columns,
    summaryGroupExtraColumns,
    summaryGroupTitleColumns,
    summaryGroupChildrenColumn,
    canDnd,
    form,
    column
  } = props;

  const { t } = useTranslation();
  const [updatedGroups, setUpdatedGroups] = useState<Array<number>>([]);

  const header = <PageHeader
    listForm={{ extInfo: { domainTitle: "" } } as FormProps}
    tableMode={"table-list"}
    domainName={domainName}
    data={[]}
    selectedData={[]}
    columns={columns}
    enumValues={{}}
    objectValues={{}}
    setData={emptyMethod}
    fetchDataCallback={() => {
      reload();
      openInfoNotification(t("Data refreshed"));
    }}
    zIndex={zIndex + 1}
    ownerClass={ownerClass}
    ownerId={ownerId}
    ownerColumn={column.backReferenceField}
    columnNameInOwnerClass={column.dataIndex}
    dataState={{}}
    dataDispatch={emptyMethod}
    disableSearch={true}
    disableCSV={true}
  />;

  if (data?.length === 0) {
    return (<>
      {header}
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </>);
  }

  return (<>
    {header}
    <Collapse
      bordered={false}
      defaultActiveKey={data.map((d, i) => i.toString())}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse">
      {data.map((d, idx) => {
        const header = <GroupedGrandChildGroupTitle
          record={d}
          columns={columns}
          displayColumns={summaryGroupTitleColumns}
          domainName={domainName}
          page={page} zIndex={zIndex} form={form}
        />;
        const extra = <GroupedGrandChildGroupExtra
          record={d}
          operationsProps={{
            ownerId,
            ownerClass,
          }}
          createChildProps={{
            columnNameInCurrentDomainClass: summaryGroupChildrenColumn.key,
            childrenDomainName: summaryGroupChildrenColumn.elementType ?? summaryGroupChildrenColumn.type,
          }}
          reload={() => {
            if (updatedGroups.indexOf(idx) === -1) {
              setUpdatedGroups(prevState => update(prevState, { $push: [idx] }));
            }
          }}
          columns={columns}
          displayColumns={summaryGroupExtraColumns}
          domainName={domainName}
          page={page} zIndex={zIndex}
          form={form}
          showOperations={true}
        />;
        const dndController = (canDnd) ? (
          <Space>
            <a
              href="/#"
              onClick={(event: React.MouseEvent<unknown>): void => {
                stopPropagationAndPreventDefault(event);
                onExchange(idx, false);
              }}
              style={idx === 0 ? { visibility: "hidden" } : {}}
              title={t("Move up")}
            ><ArrowUpOutlined /></a>
            <a href="/#"
              style={(idx === data.length - 1) ? { visibility: "hidden" } : {}}
              onClick={(event: React.MouseEvent<unknown>): void => {
                stopPropagationAndPreventDefault(event);
                onExchange(idx, true);
              }}
              title={t("Move down")}
            >
              <ArrowDownOutlined />
            </a>
          </Space>
        ) : (<></>);
        return (<Panel header={header}
          key={idx}
          className="site-collapse-custom-panel"
          extra={<Space>{extra}{dndController}</Space>}
        >
          {summaryGroupChildrenColumn != null && <GroupedGrandChildDetailGroup
            domainName={domainName}
            index={idx}
            sortedData={sortedData}
            setSortedData={setSortedData}
            record={d}
            displayColumn={summaryGroupChildrenColumn}
            page={page}
            zIndex={zIndex}
            updatedGroups={updatedGroups}
            setUpdatedGroups={setUpdatedGroups}
          />}
          {summaryGroupChildrenColumn &&
            <GroupedGrandChildDetailFooter
              domainName={domainName}
              record={d}
              displayColumn={summaryGroupChildrenColumn}
              zIndex={zIndex} />}
        </Panel>);
      })}
    </Collapse>
  </>);
};

export default GroupedGrandChildDetailGroups;
