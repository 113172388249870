import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Popover, List, message, Tooltip, Alert, Space } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { CSVDownload } from 'react-csv';
import {
  UploadOutlined, CloudUploadOutlined, FileSearchOutlined, WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  CsvOperateComponentPanelMaxWidth,
  CsvTemplateAdditionalComments,
  NumberOfCsvPreviewLine,
  CustomIcon
} from '@config/base';
import { uploadCsvs } from '@utils/FetchUtils';
import { ExpandableContentComponent } from '../../components';
import ImportRecordComponent, { ImportRecordProps } from '../cells/ImportRecord';
import { CsvUploadProps } from '@props/RecordProps';
import { getCsvHeader, getCsvHeaderTranslated } from '@utils/CsvUtils';
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { UploadFile } from 'antd/lib/upload/interface';
import { removePackagePart } from '@utils/StringUtils';

const CsvUploadComponent = (props: CsvUploadProps): ReactElement => {

  const {
    columns, fetchDataCallback, domainName, ownerId, ownerClass,
    columnNameInOwnerClass, ownerColumn, zIndex, visiblePopover,
    setVisiblePopoverCallback
  } = props;
  const { t } = useTranslation();
  const open = (visiblePopover === 'csvUpload');

  const MultipleFileMode = false;
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [importRecord, setImportRecord] = useState<ImportRecordProps>({} as ImportRecordProps);
  const [previewContent, setPreviewContent] = useState<string>("");
  const [hasPreview, setHasPreview] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const [downloadCsv, setDownloadCsv] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showTimeoutInfo, setShowTimeoutInfo] = useState<boolean>(true);

  const noFileSelected = (fileList.length === 0);

  useEffect(() => {
    window.addEventListener("click", (e: MouseEvent) => {
      if (visiblePopover === 'csvUpload') {
        setVisiblePopoverCallback(undefined, e);
      }
    });
    return () => window.removeEventListener("click", (e: MouseEvent) => {
      setVisiblePopoverCallback(undefined, e);
    });
  }, [setVisiblePopoverCallback, visiblePopover]);

  const prepareDownloadTemplate = (domainName: string): Array<Array<string>> => {
    const result = [] as Array<Array<string>>;
    result.push(getCsvHeader(columns));
    result.push(getCsvHeaderTranslated(domainName, columns));
    CsvTemplateAdditionalComments.forEach(line => result.push(line.map(c => t(c))));
    // 等待 200ms 后再将 downloadCsv 的值设置为 false
    setTimeout(() => setDownloadCsv(false), 200);
    return result;
  };

  const handleUpload = (): void => {
    const formData = new FormData();
    fileList.forEach((file: UploadFile): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append('files[]', file);
    });
    if (ownerClass != null) {
      formData.append("ownerClass", ownerClass);
    }
    if (ownerId != null) {
      formData.append("ownerId", ownerId.toString());
    }
    if (columnNameInOwnerClass != null) {
      formData.append("columnNameInOwnerClass", columnNameInOwnerClass);
    }
    if (ownerColumn != null) {
      formData.append("ownerColumn", ownerColumn);
    }
    uploadCsvs({
      data: formData,
      domainName: domainName
    }).then(json => {
      const hasErrorInJson = ('error' in json);
      setUploaded(!hasErrorInJson);
      if (hasErrorInJson) {
        setErrorMessage(JSON.stringify(json));
        setImportRecord({} as ImportRecordProps);
        setHasPreview(true);
      } else if ('data' in json) {
        setImportRecord(json?.data);
        setErrorMessage("");
        if (json?.status === "running") {
          setShowTimeoutInfo(true);
          setImportRecord({} as ImportRecordProps);
          setUploaded(false);
        } else if (json?.data?.status === "SUCCESS") {
          setFileList([]);
          setHasPreview(false);
          setPreviewContent("");
        }
        fetchDataCallback();
      }
    }).catch(error => {
      console.error('Error uploading csv file:', error);
      setUploaded(false);
      setErrorMessage(error);
      fetchDataCallback();
    }).finally(() => {
      setUploading(false);
    });
    setUploading(true);
  };

  // Hide the popup when switch to different domain list page
  useEffect(() => {
    setUploaded(false);
    setImportRecord({} as ImportRecordProps);
    setPreviewContent("");
    setHasPreview(false);
    setErrorMessage("");
    setShowTimeoutInfo(false);
  }, [domainName]);

  return (
    <Popover
      open={open}
      title={undefined}
      placement="bottom"
      trigger="click"
      overlayStyle={{ zIndex: zIndex + 1 }}
      overlayClassName="csv-upload-popover-container"
      content={(
        <div
          className="csv-upload-container"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            stopPropagationAndPreventDefault(e);
          }}
        >
          <List>
            <List.Item>
              {t('1. Download CSV template')}
              <Button
                type="primary"
                size="small"
                style={{ margin: "5px" }}
              >
                {<span onClick={() => setDownloadCsv(true)}>{t('HERE')}</span>}
                {
                  downloadCsv && <CSVDownload
                    data={prepareDownloadTemplate(domainName)}
                    filename={t("Import csv template", { domainName: t(`domainTitle:${removePackagePart(domainName)}`) })}
                    key={`${domainName}-csv-template`}
                    target="_blank"
                  />
                }
              </Button>
              {t('and prepare the data')}
            </List.Item>
            <List.Item>
              {t('2. Select or drag the file below and click')}
              <Tooltip
                title={
                  t(noFileSelected ? "Please select a CSV file first" : "Click to upload selected file")
                }
              >
                <Button
                  type="primary"
                  size="small"
                  style={{ margin: "5px" }}
                  loading={uploading}
                  disabled={noFileSelected}
                  onClick={handleUpload}
                >
                  {(uploading ? t("Uploading") : t("Upload"))}
                </Button>
              </Tooltip>
              {showTimeoutInfo && <Alert
                         type='warning'
                         message={<span>
                                    {t('Import still on going')}
                                    <a
                                      href="/#"
                                      onClick={() => window.open('/ImportRecord/list', "_blank")}
                                      style={{ paddingLeft: "0.2rem",paddingRight: "0.4rem" }}
                                    >{t('Click here')} <CustomIcon type='icon-link' /></a>
                                    {t('See import result or close this page')}
                                  </span>}
                         showIcon={true}
                         closable={true}
                       />
              }
              <div style={{ width: "97%", margin: "auto", paddingTop: "5px" }}>
                <Dragger
                  accept=".csv,.CSV"
                  multiple={false}
                  action=""
                  onChange={(info) => {
                    const { status } = info.file;
                    if (status === 'done') {
                      message.success(t('FileImportSuccessfully', { fileName: info.file.name }));
                    } else if (status === 'error') {
                      message.error(t('FileImportFailed', { fileName: info.file.name }));
                    }
                  }}
                  beforeUpload={(file) => {
                    if (MultipleFileMode) {
                      setFileList([...fileList, file]);
                    } else {
                      setFileList([file]);
                    }
                    const reader = new FileReader();
                    //用户选择了文件之后的处理
                    reader.onload = (): void => {
                      const content: string = (reader.result) as string;
                      const hasContent = (content != null && content.length > 0);
                      if (hasContent) {
                        let preview = content.split(/\r\n|\n/,
                          NumberOfCsvPreviewLine).join("\n");
                        const previewTmp: string[] = [];
                        // 截取最后十行在界面上显示预览
                        if (content.split(/\r\n|\n/).length >= 11) {
                          const contents = content.split(/\r\n|\n/);
                          const tmp = contents.filter((element, index) =>
                            index >= contents.length - 10 || index === 0);
                          tmp.forEach(element => previewTmp.push(element));
                          preview = previewTmp.join("\n");
                        }
                        setHasPreview(true);
                        setPreviewContent(preview);
                      } else {
                        setHasPreview(false);
                        setPreviewContent("");
                      }
                    };
                    reader.readAsText(file);
                    return false;
                  }}
                  onRemove={(file) => {
                    const newFileList = fileList.filter(f => f.uid !== file.uid);
                    setFileList(newFileList);
                  }}
                  fileList={fileList}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                  <p className="ant-upload-hint" />
                </Dragger>
                {hasPreview &&
                  <ExpandableContentComponent
                    content={previewContent}
                    icon={(<FileSearchOutlined />)}
                    showDownload={false}
                    title={t('CsvPreview', { num: NumberOfCsvPreviewLine })}
                    customStyle={{
                      width: "100%",
                      margin: "auto",
                      marginTop: "10px",
                      maxWidth: CsvOperateComponentPanelMaxWidth,
                    }}
                    initDisplay={!uploaded && errorMessage === ""}
                  />}
              </div>
              {uploaded && errorMessage == "" &&
                 <ImportRecordComponent
                   domainName={domainName}
                   importRecord={importRecord}
                   uploaded={uploaded}
                   zIndex={zIndex}
                 />
              }
              {errorMessage !== "" &&
               <ExpandableContentComponent
                 content={errorMessage}
                 icon={(<WarningOutlined />)}
                 showDownload={true}
                 title={t('Import error message')}
                 breakLine={true}
                 initDisplay={errorMessage !== ""}
                 customStyle={{
                   marginTop: "1rem",
                   color: "red"
                 }}
               />
              }
            </List.Item>
          </List>
        </div>
      )}
    >
      <Space
        className="link-icon link-icon-with-label"
        size={2}
        onClick={(e) => setVisiblePopoverCallback(open ? undefined : 'csvUpload', e)}
        title={t("Upload data using CSV file")}
      >
        <CloudUploadOutlined />
        {t('Import')}
      </Space>
    </Popover>
  );
};

export default CsvUploadComponent;
