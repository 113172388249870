import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Avatar, Button, Image, Popconfirm, Space, Spin, Upload, message
} from 'antd';
import { EditableControllerProps } from "@kernel/ComponentsConfig";
import { fetchCurrentValue } from "@utils/FetchUtils";
import { getBase64 } from "../fileOperator/FileOperatorUtils";
import { ExtendUploadFile } from "@props/RecordProps";
import ImgCrop from "antd-img-crop";
import { emptyMethod } from "@utils/Constants";
import { useTranslation } from "react-i18next";
import ReconnectableSocket, { SocketInterface } from "@utils/WebsocketUtils";
import { WebSocketDummyMessage, WEBSOCKET_SERVER_URL } from "@config/base";
import { RcFile } from 'antd/es/upload';
import { deleteAttachment } from '@utils/FetchUtils';
import { BaseResponseProps } from '@props/RecordProps';

export interface SingleImageDisplayProps extends EditableControllerProps {
  avatarMode?: boolean;
}

const SingleImage = (props: SingleImageDisplayProps): ReactElement => {
  const { column, form, avatarMode, domainName, record, updatable } = props;
  const { id } = record || {};
  const [previewBlob, setPreviewBlob] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const fileList = useRef<Array<ExtendUploadFile>>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<SocketInterface>();
  const { t } = useTranslation();
  const isAvatar = avatarMode || (column.key === 'avatar');

  useEffect(() => {
    if (websocket != null) {
      return;
    }
    const socketInterface = ReconnectableSocket(`${WEBSOCKET_SERVER_URL}/websocket/fileUpload`);
    setWebsocket(socketInterface);
    socketInterface.on((wsMessage: string) => {
      if (wsMessage === WebSocketDummyMessage) {
        return;
      }
      const messageProps: ExtendUploadFile = JSON.parse(wsMessage);
      const { data } = messageProps;
      // 上传
      //1. 将保存中标记设置为 false,
      //2. 将 fileList 中的元素的 id 设置为后台返回的 data.id
      //3. 将 form 中， 对应字段的值设置为后台返回的 id
      //4. 如果保存没有发生错误，则设置 isDirty 为 false
      setSaving(false);
      if (data != null && data.id != null) {
        setIsDirty(false);
        message.success(t('Operation success'));
        const dataId = data.id;
        form?.setFieldsValue({
          [column.key]: dataId,
        });
        const newFileList: Array<ExtendUploadFile> = [];
        newFileList[0] = fileList.current[0];
        newFileList[0].id = dataId;
        fileList.current = newFileList;
      } else {
        message.error(t('Operation failed'));
        console.error(`Backend returned ${wsMessage}, no data.id attribute, will not be able to save the image`);
        fileList.current = [];
      }
    });
    //组件 unload 的时候关闭 websocket 连接
    return;
  }, [t, websocket, column.key, form, fileList]);

  useEffect(() => {
    if (id == null) {
      return;
    }
    setLoading(true);
    fetchCurrentValue(domainName, id).then((json) => {
      if (column.key in json) {
        const value = json[column.key];
        if (value != null && ('id' in value) && value.id != null) {
          const storageFieldValueId = value.id;
          getBase64({
            id: storageFieldValueId,
            name: 'avatar.png'
          } as ExtendUploadFile).then(content => {
            setPreviewBlob(content as string);
            fileList.current = [
              {
                id: storageFieldValueId,
                name: 'avatar.png',
                status: 'done',
                uid: storageFieldValueId,
              }
            ];
          }).catch((err) => {
            console.error(`Failed to get StorageFieldValue(${storageFieldValueId}): ${JSON.stringify(err)}`);
          }).finally(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      } else {
        console.error(`${column.key} not found in ${domainName}`);
      }
    }).catch((err) => {
      console.error(`Failed to get ${domainName} current value ${id}(${JSON.stringify(record)}): ${JSON.stringify(err)}`);
    });
  }, [domainName, id, column.key, record]);

  const onBeforeUpload = (file: RcFile): boolean => {
    const newFile = file as ExtendUploadFile;
    if (fileList.current.length === 0) {
      newFile.status = undefined;
    } else {
      const first = fileList.current[0];
      if (first.id != null) {
        newFile.id = first.id;
      }
      newFile.status = undefined;
    }
    fileList.current = [newFile];
    setIsDirty(true);
    //TODO 代码重构，将这里的和 FileOperatorUtils 中的 Promise 相关代码去重
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file as RcFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).then(content => {
      setPreviewBlob(content as string);
    });
    return false;
  };

  //TODO 上传和删除附件的逻辑和 fileOperator 中的合并，删除重复代码
  const saveAvatar = (): void => {
    // 如果没有设定头像，或者删除头像操作，
    // 则点击保存的时候无需有动作
    if (fileList.current.length === 0) {
      return;
    }
    const file = fileList.current[0];
    // 如果是修改或者新增头像或图片，
    // 则需要请求后台，保存新的图片
    const attachmentId = file.id;
    setSaving(true);
    websocket?.sendFile({
      id: attachmentId ?? undefined,
      uid: file.uid,
      ownerId: id,
      ownerClass: domainName,
      columnNameInOwnerClass: column.key,
      multiple: false
    }, (file as unknown) as File);
  };

  const onConfirm = (): void => {
    const backendId = fileList.current[0].id;
    setDeleting(true);
    if (backendId != null) {
      deleteAttachment({
        id: backendId,
        uid: backendId.toString(),
        ownerId: id,
        ownerClass: domainName,
        columnNameInOwnerClass: column.key,
        multiple: false
      }).then((json: BaseResponseProps) => {
        const { success } = json;

        //删除,
        //1. 将删除中标记设置为 false,
        //2. 将 fileList 设置为空数组， previewBlob 设置为空
        //3. 将 form 中， 对应字段的值设置为 null
        //4. 如果删除没有发生错误，则设置 isDirty 为 false
        setDeleting(false);
        if (success === true) {
          message.success(t('Operation success'));
          fileList.current = [];
          setPreviewBlob(undefined);
          form?.setFieldsValue({
            [column.key]: null
          });
          setIsDirty(false);
        } else {
          console.error("Failed to update avator", json);
          message.error(t('Operation failed'));
        }
      }).catch(e => {
        message.error(t('Operation failed'), e);
      });
    } else {
      setIsDirty(false);
      setDeleting(false);
      fileList.current = [];
      setPreviewBlob(undefined);
    }
  };
  const avatarContent = (
    <Space
      direction="horizontal"
      size={6}
      style={{ textAlign: "center" }}
    >
      {previewBlob && <Avatar
        src={<Image src={previewBlob} preview={false} />}
        size={120}
      />}
      {updatable && fileList.current.length > 0 && <Popconfirm
        title={t('Are you sure to remove this avatar')}
        onConfirm={onConfirm}
        okText={t("Confirm")}
        cancelText={t("Cancel")}
      >
        <Button
          size="small"
          loading={deleting}
        >
          {t('Remove avatar')}
        </Button>
      </Popconfirm>
      }
      {updatable &&
        <span>
          <ImgCrop
            rotationSlider={true}
            cropShape={"round"}
          >
            <Upload
              beforeUpload={onBeforeUpload}
              fileList={fileList.current}
              onPreview={emptyMethod}
              multiple={false}
              showUploadList={false}
              accept=".jpg,.jpeg,.gif,.png,.bmp"
            >
              <Button size="small">{t('Change avatar')}</Button>
            </Upload>
          </ImgCrop>
        </span>
      }
      {updatable && isDirty &&
        <Button
          size="small"
          onClick={() => saveAvatar()}
          loading={saving}
        >
          {t('Save avatar')}
        </Button>
      }
    </Space>);
  const imageContent = <Image src={previewBlob} />;
  const content = isAvatar ? avatarContent : imageContent;
  return loading ? (<Spin />) : content;
};

export default SingleImage;
