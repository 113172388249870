import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Alert, Input, Popover, Space, Tag, Tooltip } from "antd";
import { PageHeader as AntdPageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { filterDataAsync } from "@kernel/ClientSideFilter";
import { FileAddOutlined, FilterOutlined } from '@ant-design/icons';
import { fetchCanCreate, fetchFormIdAndExtInfoByName, fetchFormIdAndExtInfoByType } from "@utils/FetchUtils";
import { fullDomainNameToHumanReadable } from "@utils/StringUtils";
import { FormProps, PageHeaderProps } from "@props/RecordProps";
import { CsvOperateComponent } from "./csv";
import { CreateAction, ActionButtons, UpdateAction } from "./";
import { WEBSOCKET_SERVER_URL } from "@config/base";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import ReconnectableSocket, { SocketInterface } from "@utils/WebsocketUtils";
import { realtimeReducer, RealtimeWebsocketListenFunction, useConfig } from "../utils/hooks";
import {
  getDomainAutoRefreshService,
  UnsubscribeCallback
} from "@utils/DomainAutoRefreshUtils";
import DisplayStyleDropdown from "./DisplayStyleDropdown";

const PageHeader = (props: PageHeaderProps): ReactElement => {
  const {
    domainName, data, selectedData, columns, tableMode, objectValues,
    enumValues, setData, fetchDataCallback, initialCanCreate, ownerClass, extra,
    ownerColumn, ownerId, columnNameInOwnerClass, readonly, setTableMode,
    initShowCreateModal, initDomainId, initDomainPage, zIndex, isCurrentActiveTab,
    displayLabel, hide, listForm, dataState, dataDispatch, disableSearch, disableCSV,
    createFormName,
  } = props;

  const { t } = useTranslation();
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [createFormId, setCreateFormId] = useState<number>();
  const [canCreateErrorMsg, setCanCreateErrorMsg] = useState<string | undefined>(undefined);
  const domainTitle = fullDomainNameToHumanReadable(listForm?.extInfo?.domainTitle ?? domainName);
  const [matchedNumber, setMatchedNumber] = useState<number>();
  const [filterKeyword, setFilterKeyword] = useState<string>();
  const [visiblePopover, setVisiblePopover] = useState<string>();
  const [websocket, setWebsocket] = useState<SocketInterface>();
  /** 查看详情的 Modal 的只读状态，在查看详情的 Modal 中，可以点击编辑按钮，将其状态修改为编辑状态 */
  const [detailModalReadonly, setDetailModalReadonly] = useState<boolean>(initDomainPage === 'detail');
  const { value: useNewWebsocketService } = useConfig("debugDynamicLogic.enableNewDomainChangePushService");
  const domainChangeUnsubscribeCallbackRef = useRef<UnsubscribeCallback>();

  /** 如果不是当前的激活页签，则隐藏所有的 Popover 控件 */
  useEffect(() => {
    if (isCurrentActiveTab === false) {
      setVisiblePopover(undefined);
    }
  }, [isCurrentActiveTab]);

  useEffect(() => {
    let promise: Promise<FormProps>;
    if (createFormName) {
      promise = fetchFormIdAndExtInfoByName(domainName, createFormName);
    } else {
      promise = fetchFormIdAndExtInfoByType(domainName, "Create");
    }
    promise.then(json => setCreateFormId(json.id))
      .catch(e => {
        console.warn(`Failed to get create form id of domain ${domainName}: ${e}`);
      });
  }, [createFormName, domainName]);

  useEffect(() => {
    if (initialCanCreate != null) {
      setCanCreate(initialCanCreate);
    } else {
      fetchCanCreate(domainName).then(json => {
        if (json.error === 1) {
          setCanCreate(false);
          setCanCreateErrorMsg(json.createErrorMsg);
        } else {
          setCanCreate(json.create);
        }
      }).catch(
        (e) => console.warn(`Failed to get canCreate for ${domainName}: ${JSON.stringify(e)}`)
      );
    }
  }, [domainName, initialCanCreate]);

  useEffect(() => {
    if (useNewWebsocketService !== 'true') {
      return;
    }
    const domainAutoRefreshService = getDomainAutoRefreshService();
    if (dataState.refreshMode === 'realtime') {
      domainChangeUnsubscribeCallbackRef.current = domainAutoRefreshService.subscribe(domainName, () => fetchDataCallback(false));
    } else if (dataState.refreshMode === 'manual' || dataState.refreshMode == null) {
      domainChangeUnsubscribeCallbackRef.current?.();
    }
    return () => {
      domainChangeUnsubscribeCallbackRef.current?.();
    };
  }, [domainName, dataState.refreshMode, useNewWebsocketService, fetchDataCallback]);

  // deprecated
  useEffect(() => {
    if (useNewWebsocketService === 'true') {
      return;
    }
    //console.log("websocket: ", websocket, " dataState.data: ", dataState.data, " refreshModeState: ", dataState.refreshMode);
    const { data } = dataState;
    if (websocket == null || data == null) {
      return;
    }
    let listenFunc: RealtimeWebsocketListenFunction | undefined;
    // 只在第一次 websocket 连接时候，注册监听函数，后续数据变化了，无需再注册或者刷新监听函数
    if (websocket.numberOfMessageListeners() === 0) {
      if (dataState.refreshMode === 'realtime') {
        listenFunc = realtimeReducer({
          type: 'initConnection', payload: {
            domainName, data, dataDispatch, websocket, fetchDataCallback
          }
        });
        websocket.onStateChange((isOpen: boolean) => {
          //on websocket reconnect, send current listen id to backend
          if (isOpen) {
            realtimeReducer({
              type: 'transferIds', payload: {
                domainName, data, dataDispatch, websocket, fetchDataCallback
              }
            });
          }
        });
      } else if (dataState.refreshMode === 'manual' || dataState.refreshMode == null) {
        realtimeReducer({
          type: 'close', payload: {
            domainName, data, dataDispatch, websocket, fetchDataCallback
          }
        });
      }
      if (listenFunc != null) {
        console.log("Replace websocket listen func with new data: ", dataState.data);
        websocket.replaceOn(listenFunc());
      }
    }
  }, [
    dataDispatch, dataState.data, domainName, dataState.refreshMode, websocket,
    fetchDataCallback, dataState, websocket?.isConnected, useNewWebsocketService,
  ]);

  // Reset the websocket connection when domainName changes
  useEffect(() => {
    websocket?.close();
    setWebsocket(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName]);

  const actionMode = (selectedData.length > 0) ? "multiple" : "class";
  const actionButtons = (<ActionButtons
    visiblePopover={visiblePopover}
    setVisiblePopoverCallback={(key?: string) => setVisiblePopover(key)}
    domainName={domainName}
    fetchDataCallback={fetchDataCallback}
    mode={actionMode}
    selectedData={selectedData}
    zIndex={zIndex}
    displayLabel={displayLabel}
    ownerClass={ownerClass}
    ownerId={ownerId}
    columnNameInOwnerClass={columnNameInOwnerClass}
    labelField={listForm?.labelField}
  />);

  const csvOperations = disableCSV ? (
    <></>
  ) : (
    <CsvOperateComponent
      visiblePopover={visiblePopover}
      setVisiblePopoverCallback={(key, e) => {
        if (e != null) {
          stopPropagationAndPreventDefault(e);
        }
        setVisiblePopover(key);
      }}
      domainName={domainName}
      columns={columns}
      selectedData={selectedData}
      enumValues={enumValues}
      objectValues={objectValues}
      allData={data ?? []}
      fetchDataCallback={fetchDataCallback}
      ownerClass={ownerClass}
      ownerId={ownerId}
      columnNameInOwnerClass={columnNameInOwnerClass}
      showUpload={canCreate}
      ownerColumn={ownerColumn}
      readonly={readonly}
      zIndex={zIndex}
    />
  );

  const createIcon = canCreate ? (
    <div>
      <CreateAction
        initShowCreateModal={initShowCreateModal}
        formId={createFormId ?? undefined}
        domainName={domainName}
        ownerId={ownerId}
        ownerClass={ownerClass}
        columnNameInOwnerClass={columnNameInOwnerClass}
        callback={() => fetchDataCallback(true)}
        initialCanCreate={canCreate}
        zIndex={zIndex + 1}
        showIconAndText={true}
        formName={createFormName}
      />
    </div>
  ) : (
    <></>
  );

  const createAndImportWrapper = (
    <Space>
      {createIcon}
      {csvOperations}
    </Space>
  );


  const changeDisplayStyleElem = (
    <DisplayStyleDropdown
      key="change-display-style"
      setTableMode={setTableMode}
      tableMode={tableMode}
      fetchDataCallback={fetchDataCallback}
      refreshMode={dataState.refreshMode}
      setRefreshMode={(mode: string) => {
        dataDispatch({ type: "set", payload: { refreshMode: mode } });
        if (mode === 'realtime') {
          const socket: SocketInterface = (websocket == null || !websocket.isConnected()) ?
            ReconnectableSocket(`${WEBSOCKET_SERVER_URL}/websocket/domainData`) : websocket;
          setWebsocket(socket);
        } else {
          if (websocket != null) {
            websocket.close();
            setWebsocket(undefined);
          }
        }
      }}
    />
  );

  const textFilterElement = (<Input
    key="search-form"
    placeholder={t("Type to filter table content")}
    allowClear
    prefix={<FilterOutlined style={{ paddingRight: "8px" }} />}
    suffix={(filterKeyword != null && filterKeyword !== '' && matchedNumber != null) &&
      <Tooltip
        title={t("FilterTableContentTooltip", {
          numOfRow: data.length, matchedNumber,
          isAre: matchedNumber > 1 ? 'are' : 'is'
        })}
      >
        <Tag className="filter-number-tag">{`${matchedNumber}/${data.length}`}</Tag>
      </Tooltip>}
    onChange={(e) => {
      const { value } = e.target;
      setFilterKeyword(value);
      filterDataAsync(data, columns, enumValues, objectValues, value)
        .then(result => {
          setData(result.data);
          setMatchedNumber(result.total);
        });
    }} />);

  const masterExtra = (<>
    {extra}
    {disableSearch ? (<></>) : [
      changeDisplayStyleElem,
      textFilterElement,
    ]}
  </>);

  const canCreateErrorElement = ((canCreateErrorMsg != null) &&
    <Popover
      title={<Alert message="Error" type="error" showIcon />}
      content={<pre>{canCreateErrorMsg}</pre>}
    >
      <Tooltip
        title={`Create new ${domainTitle} (Backend configuration error)`}
      >
        <FileAddOutlined
          className="link-icon"
          style={{ color: "red" }} />
      </Tooltip>
    </Popover>
  );

  const initDomainUpdateActionElement = (initDomainId != null) && (
    <div style={{ display: "none" }}>
      <UpdateAction
        id={initDomainId}
        domainName={domainName}
        ownerClass={ownerClass}
        ownerId={ownerId}
        initShowEditModal={initDomainId != null}
        callback={() => fetchDataCallback()}
        zIndex={zIndex + 1}
        readonly={detailModalReadonly}
        switchEditable={() => {
          setDetailModalReadonly(!detailModalReadonly);
        }}
      /></div>
  );

  // 如果父对象传递了 initDomainId 表示页面打开的时候就需要
  // 打开编辑页面
  const masterHeader = (
    <AntdPageHeader
      className="site-page-header"
      backIcon={false}
      title={domainTitle}
      subTitle={<Space
        direction="horizontal"
        size={"small"}
      >
        {!readonly && canCreate && canCreateErrorMsg == null && createAndImportWrapper}
        {!canCreate && canCreateErrorElement}
        {actionButtons}
        {initDomainUpdateActionElement}
      </Space>}
      extra={masterExtra}
    />
  );

  const detailHeader = (
    <Space
      className="detail-page-action-space"
      direction="horizontal"
      size="middle">
      {!readonly && createAndImportWrapper}
      {actionButtons}
    </Space>
  );
  const TableModeHeaderMapping = {
    "simple-list": masterHeader,
    "table-list": masterHeader,
    "card-list": masterHeader,
    "detail-drawer": detailHeader,
    "detail": detailHeader,
    "finder": (<div />),
  };
  return (hide === true) ? (<div style={{ height: "80px" }} />) : TableModeHeaderMapping[tableMode];
};

export default PageHeader;
