import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnType, RecordProps } from '@props/RecordProps';
import { fetchCurrentValue } from '@utils/FetchUtils';
import { isNumber } from 'lodash';
import { Rate, Spin } from 'antd';

export interface RatingsProps  {
    value: RecordProps;
    type: ColumnType;
}

const translates: {[propName: string]: string} = {
    "modifyRatio": "修改率",
    "usageRatio": "使用率",
    "overallScore": "综合评分",
};

// 备注: 后台要将 API 返回的数值转换为 0 - 5 的一个数值，用以显示为 rating
const Ratings = (props: RatingsProps): ReactElement => {
    const { value, type} = props;
    const { id } = value;
    const [currentValue, setCurrentValue] = useState<RecordProps>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchCurrentValue(type, id).then(value => {
            setCurrentValue(value);
        }).catch((e) => {
            console.error('fetch current value error', e);
        }).finally(() => {
            setLoading(false);
        });
    }, [type, id]);

    const ratings: Array<ReactElement> = [];
    for (const key in currentValue) {
        if(key === "id") {
            continue;
        }
        const value = currentValue[key];
        const trans = translates[key];
        // 暂时只支持 translates 列表中的字段显示
        if(value != null && isNumber(value) && trans != null) {
            // 接口返回的数值为 0 - 5 的一个数值，乘 20 以得到 0 - 100 的数值(百分比)
            const percent = value * 20;
            ratings.push(
                <span key={key}>
                  <span className="ant-rate-text">{trans}: {percent}%</span>
                  <Rate value={value} disabled={true} allowHalf={true} />
                </span>
            );
        }
    }
    return loading? <Spin /> : (<>{ratings}</>);
};

export default Ratings;
