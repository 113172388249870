const getClassNameAlias = (): Map<string, string[]> => {
  const classNames: string[] = [
    "AuditTrail",
    "AuthenticationToken",
    "ChatMessage",
    "Conversation",
    "DefaultDynamicEntityInstance",
    "DefaultDynamicEntityType",
    "DomainClass",
    "DomainClassField",
    "DomainColumnClientSideTypeConfig",
    "DomainComment",
    "DummyDomain",
    "DynamicAction",
    "DynamicActionDomainClass",
    "DynamicActionExecRecord",
    "DynamicActionGroup",
    "DynamicConfig",
    "DynamicConfigRevision",
    "DynamicConfigTrash",
    "DynamicDashboardWidget",
    "DynamicFieldDefinition",
    "DynamicFieldHook",
    "DynamicFieldHookExecRecord",
    "DynamicFieldInstance",
    "DynamicFieldValue",
    "DynamicFilter",
    "DynamicForm",
    "DynamicFormField",
    "DynamicFormGroup",
    "DynamicFormWizardStep",
    "DynamicIntegration",
    "DynamicIntegrationDomainClass",
    "DynamicIntegrationExecRecord",
    "DynamicLogic",
    "DynamicLogicEngine",
    "DynamicLogicRevision",
    "DynamicLogicTrash",
    "DynamicLogicType",
    "DynamicMenu",
    "DynamicObjectHook",
    "DynamicObjectHookExecRecord",
    "DynamicPrompt",
    "DynamicTask",
    "DynamicTaskExecRecord",
    "DynamicTheme",
    "EmailSendingRecord",
    "Equipment",
    "EquipmentClass",
    "EquipmentSpecification",
    "Feedback",
    "Group",
    "GroupRole",
    "I18n",
    "I18nType",
    "ImportRecord",
    "Message",
    "Organization",
    "ProductionLine",
    "RegisterInvitation",
    "RegisterInvitationCode",
    "RequestMap",
    "Role",
    "RoleHierarchyEntry",
    "SampleContract",
    "SampleContractLine",
    "SampleContractLineType",
    "SampleMilestone",
    "SampleProject",
    "SampleTask",
    "StorageFieldValue",
    "User",
    "UserGroup",
    "UserRole",
    "WizardStepExecRecord",
    "AppVersion",
    "DynamicReport",
    "DynamicServiceProvider",
    "DynamicServiceExecRecord",
  ];

  const classNameAliasMap: Map<string, string[]> = new Map();

  for (const className of classNames) {
    const alias = className
      .replace('Default', '')
      .replace('Dynamic', '');

    classNameAliasMap.set(className, [className, alias].map(str => str.toLowerCase()));
  }

  return classNameAliasMap;
};

export const getOriginalClassName = (alias: string, classNameAliasMap?: Map<string, string[]>): string | undefined => {
  const map = classNameAliasMap || getClassNameAlias();
  const lowerCaseAlias = alias.toLowerCase();
  for (const [key, value] of map.entries()) {
    if (value.map(val => val.toLowerCase()).includes(lowerCaseAlias)) {
      return key;
    }
  }
  return undefined;
};

// Example usage
// const classNameAliasMap = getClassNameAlias();
// const alias = "Action"; // Enter the alias here
// const originalClassName = getOriginalClassName(alias, classNameAliasMap);

// if (originalClassName) {
//   console.log(`The original class name for alias "${alias}" is "${originalClassName}"`);
// } else {
//   console.log(`No original class name found for alias "${alias}"`);
// }
