import { Store } from "@props/RecordProps";
import { useTranslation } from 'react-i18next';
import { camelCaseToSentence } from "@utils/StringUtils";
import { Alert, Table } from "antd";
import React, { ReactElement } from "react";
import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";
import { wrapAsHtml } from "@utils/ComponentUtils";

const DataTableWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs, widget } = props;
  const config = JSON.parse(widget.options)?.config ?? {};
  const mergedConfigs = { ...config, ...dataAndConfigs };
  const { data } = dataAndConfigs;
  const dataArray = [] as Array<Store>;
  const { t } = useTranslation();
  (data as Array<Store>).forEach(d => {
    const item = Object.assign({}, d);
    dataArray.push(item);
    for (const [key, value] of Object.entries(item)) {
      item[key] = wrapAsHtml(value);
    }
  });
  if (!Array.isArray(dataArray)) {
    return <Alert
      type="warning"
      message={
        t("Data from backend should be array for DataTableWidget",
          {
            data: JSON.stringify(data),
            widget: JSON.stringify(widget),
            dataAndConfigs: JSON.stringify(dataAndConfigs)
          }
        )
      }
    />;
  }
  if (dataArray.length === 0) {
    return <Alert
      type="info"
      message={t("No data returned from backend")}
      showIcon={true}
      className="light-bg-alert"
    />;
  }
  const columns = Object.keys(dataArray[0]);
  const tableColumns = columns.map(c => {
    return {
      key: c,
      dataIndex: c,
      title: camelCaseToSentence(c)
    };
  });
  return (
    <Table
      dataSource={dataArray}
      columns={tableColumns}
      {...mergedConfigs}
      rowKey={(record, index) => index}
    />
  );
};

export default DataTableWidget;
