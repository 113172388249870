import React, { useEffect, useState, ReactElement, CSSProperties } from 'react';
import { Card, Space } from 'antd';
import {
  CloudDownloadOutlined, MinusOutlined, PlusOutlined,
  CaretDownOutlined, CaretRightOutlined,
} from '@ant-design/icons';
import { CSVDownload } from 'react-csv';
import { useTranslation } from 'react-i18next';

const ExpandableContentComponent = (props: {
  content: string | ReactElement;
  title: string | ReactElement;
  showDownload: boolean;
  breakLine?: boolean
  customStyle?: CSSProperties;
  icon?: ReactElement;
  fixHeight?: number;
  initDisplay?: number | boolean;
  visible?: boolean;
  initNumOfLineBreaks?: number;
  className?: string;
  toggleDisplayCallback?: (display: boolean) => void;
  titleRightExtra?: ReactElement;
}): ReactElement => {
  const {
    content, title, showDownload, customStyle, icon, breakLine, fixHeight,
    initDisplay, visible, initNumOfLineBreaks, className, toggleDisplayCallback,
    titleRightExtra,
  } = props;
  const { t } = useTranslation();

  const numOfLineBreaks = (initNumOfLineBreaks != null && !isNaN(initNumOfLineBreaks)) ?
    initNumOfLineBreaks : (typeof content === "string") ? content.split("\n").length : 1;
  const maxHeight = 500;
  const calculatedHeight = 90 + (numOfLineBreaks * 25);
  const expandHeight = (calculatedHeight > maxHeight) ? maxHeight : calculatedHeight;
  const [height, setHeight] = useState<number>(expandHeight);
  const [display, setDisplay] = useState<boolean>(true);
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  useEffect(() => {
    setHeight(fixHeight ? fixHeight : expandHeight);
    setDisplay(!(initDisplay === -1 || initDisplay === false));
  }, [expandHeight, initDisplay, fixHeight]);

  const whiteSpace = (breakLine) ? "break-spaces" : "pre";

  const prepareDownloadData = (logContent: string | unknown):Array<{[propName: string]: string}> => {
    const result = [] as Array<{[propName: string]: string}>;
    result.push({ "": logContent as string} as {[propName: string]: string});
    setTimeout(() => {
      setTriggerDownload(false);
    } , 100);
    return result;
  };

  return visible === false ? (<div />) : (
    <Card size="small"
      style={(customStyle != null) ? customStyle : {}}
      className={`expandable-content ${className}`}
      title={(<div
        role="link"
        onClick={(e: React.MouseEvent<unknown>) => {
          e.preventDefault();
          const v = !display;
          setDisplay(v);
          if (toggleDisplayCallback != null) {
            toggleDisplayCallback(v);
          }
        }}
        title={t("Toggle display of this panel")}
      >
        <Space
          direction="horizontal"
          size="small">
          {display ? <CaretDownOutlined /> : <CaretRightOutlined />}
          <span>
            <span className="title">{title}</span> {icon ? icon : undefined}
          </span>
        </Space>
      </div>)}
      extra={
        <Space size="middle" direction="horizontal">
          <span
            title={t("Make the panel bigger")}
            onClick={
              () => {
                if (display === false) {
                  setDisplay(true);
                } else {
                  setHeight(height >= maxHeight ? maxHeight : height + 100);
                }
              }
            }>
            <PlusOutlined className="small-clickable-icon" />
          </span>
          <span
            title={t("Make the panel smaller")}
            onClick={
              () => {
                if (height <= expandHeight) {
                  setDisplay(false);
                }
                setHeight(height <= expandHeight ? expandHeight : height - 100);
              }
            }>
            <MinusOutlined className="small-clickable-icon" />
          </span>
          {showDownload &&
            <span
              title={t("Download the content as txt file")}
              onClick={() => setTriggerDownload(true)}
            >
              {triggerDownload &&
               <CSVDownload
                 data={prepareDownloadData(content)}
                 headers={undefined}
                 filename={`${new Date().toISOString().slice(0, 10)}.csv`}
                 enclosingCharacter={""}
                 key={Math.random()}
               />
              }
              <CloudDownloadOutlined className="small-clickable-icon" />
            </span>
          }
          {titleRightExtra}
        </Space>
      }
    >
      {
        display &&
        <pre
          className="import-info-pre"
          style={{ height, whiteSpace }}
        >
          {content}
        </pre>
      }
    </Card>
  );
};

export default ExpandableContentComponent;
