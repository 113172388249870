import React, { ReactElement } from 'react';
import { Button, ModalProps, Popconfirm, Space } from 'antd';
import {
  SaveOutlined, CaretLeftOutlined, CaretRightOutlined, FileDoneOutlined,
  LockOutlined, UnlockOutlined
} from '@ant-design/icons';
import {
  camelCaseToSentence,
  fullDomainNameToHumanReadable
} from '@utils/StringUtils';
import CloseIcon from '../components/icons/CloseIcon';
import { SpecialModalWidth } from '@config/base';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { emptyMethod } from '@utils/Constants';
import { Operations } from './list';
import { SaveOperation, SwitchEditableProps } from '@props/RecordProps';
import SaveButtonGroup from './SaveButtonGroup';

export type WizardLoadingButtonType = "previous" | "next" | "save" | undefined;
export type RedirectMode = SaveOperation | "external" | "diff" | "inbox";

export type SaveLoadingButtonType =
  "saveClose"
  | "saveEdit"
  | "saveContinueCreate"
  | "saveContinueEdit"
  | undefined;

export interface ModalPropsBuilderProps extends ModalProps, SwitchEditableProps {
  mode: RedirectMode;
  open: boolean | undefined;
  domainName: string;
  onSaveAndClose?: () => void;
  onSaveAndContinue?: () => void;
  onSaveAndContinueEdit?: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  hasRelateObjectField?: boolean;
  isValid: boolean;
  zIndex: number;
  ownerClass?: string;
  columnNameInOwnerClass?: string;
  ownerId?: number;
  id?: number;

  //后台控制的用户是否可以编辑
  updatable?: boolean;
  onWizardPreviousStep?: () => void;
  onWizardNextStep?: () => void;
  onWizardSave?: () => void;
  wizardTitle?: string;
  wizardHasNextStep?: boolean;
  wizardHasPreviousStep?: boolean;
  wizardDescription?: string;
  wizardCanSubmit?: boolean;

  // 当前哪个 wizard 的按钮处于 loading 状态,显示 loading 动画
  wizardLoadingButton?: WizardLoadingButtonType;

  // 当前是哪个 create 或者 update 的按钮处于 loading 状态
  saveLoadingButton?: SaveLoadingButtonType;

  t: (key: string, variables?: { [propName: string]: unknown }) => string;

  // 可选的直接指定的 modal 的 title
  modalTitle?: string;

  // Url where rendering the modal, use to set back the URL when closing the modal
  refererUrl?: string;

  cancelConfirmMessage?: string;
}

const modalPropsBuilder = (props: ModalPropsBuilderProps): ModalProps & { key: string } => {
  //FIXME 重构: 将 hasRelateObjectField 重命名为 hasDetailPanel
  const {
    open, mode, domainName, onSaveAndContinue, isValid, zIndex,
    onSaveAndClose, onCancel, hasRelateObjectField, onSaveAndContinueEdit,
    ownerId, ownerClass, id, onDelete, readonly, t,
    onWizardNextStep, onWizardPreviousStep, onWizardSave, wizardTitle,
    wizardHasNextStep, wizardHasPreviousStep, wizardDescription, columnNameInOwnerClass,
    wizardCanSubmit, wizardLoadingButton, saveLoadingButton, modalTitle,
    refererUrl, switchEditable, updatable, cancelConfirmMessage
  } = props;

  const pushUrlToHistory = (): void => {
    if (refererUrl != null) {
      window.history.pushState({}, '', refererUrl);
    }
  };

  const isCreateMainObj = (mode === 'create');
  const isCreateRelatedObj = (mode === 'create-related');
  const isCreate = (isCreateMainObj || isCreateRelatedObj);
  const isWizard = (mode === 'wizard');
  const isList = (mode === 'list');
  const isEditOrShow = (mode === 'edit' || mode === 'edit-related');

  const operationLabel = camelCaseToSentence(isCreate ?
    'Create' : (readonly ? 'Show' : 'edit'));
  const continueLabel = isEditOrShow ? 'Edit' : operationLabel;
  // const closeLabel = isCreateRelatedObj ? 'Use' : 'Close';
  const idStr = (id == null) ? "" : t('IdCommaString', { id: id });

  // 获取 config 中配置的特殊宽度
  const specialWidth = SpecialModalWidth[domainName];

  const isEditOrShowOrCreateAndHasDetailField = (isEditOrShow || isCreate) && hasRelateObjectField;
  // 如果特殊配置的宽度为空，则使用如下的判断逻辑
  const width = specialWidth ??
    ((isWizard || isEditOrShowOrCreateAndHasDetailField) ?
      "95%" : ((isCreate) ? "95%" : undefined));

  const fixDataIssueMsg = t("Please fix data issue");
  const saveAndReturnMsg = t("Click to save and return to list page");
  const saveAndEditMsg = t("Click to save and edit new record");
  const saveAndContinueMsg = t("Click to save and continue on this page");

  const isReadonly = (readonly === true);
  const calcModalTitle = modalTitle ?? ((isWizard) ?
    (<div>
      <span>{wizardTitle}</span>
      <span style={{
        fontSize: "80%",
        color: "rgba(0,0,0,0.45)",
        paddingLeft: "0.5rem"
      }}>{wizardDescription}</span>
    </div>)
    : (isList ? "" : t('ModalTitle', { domainTitle: fullDomainNameToHumanReadable(domainName), idStr })));
  const emptyElement = null;

  const saveAndCloseButton = (onSaveAndClose != null && !isReadonly) ? (<Button
    className="modal-title-button"
    type="default"
    htmlType="submit"
    size="small"
    onClick={() => {
      onSaveAndClose();
      pushUrlToHistory();
    }}
    disabled={!isValid}
    title={!isValid ? fixDataIssueMsg : saveAndReturnMsg}
    icon={<SaveOutlined />}
    loading={saveLoadingButton === 'saveClose'}
  >
    <span>{t(`Save`)}</span>
  </Button>) : emptyElement;

  const saveAndEditButton = ((isCreateMainObj && onSaveAndContinueEdit != null) && !isReadonly) ? (
    <Button
      className="modal-title-button"
      type="default"
      htmlType="submit"
      size="small"
      onClick={onSaveAndContinueEdit}
      disabled={!isValid}
      title={!isValid ? fixDataIssueMsg : saveAndEditMsg}
      icon={<SaveOutlined />}
      loading={saveLoadingButton === 'saveEdit'}
    >
      <span>{t('Save / Edit')}</span>
    </Button>
  ) : emptyElement;

  const saveAndContinueLoading = (isEditOrShow && saveLoadingButton === 'saveContinueEdit') ||
    (isCreate && saveLoadingButton === 'saveContinueCreate');

  const saveAndContinueButton = ((onSaveAndContinue != null) && !isReadonly) ? (
    <Button
      className="modal-title-button"
      type="default"
      htmlType="submit"
      size="small"
      onClick={onSaveAndContinue}
      disabled={!isValid}
      title={!isValid ? fixDataIssueMsg : saveAndContinueMsg}
      icon={<SaveOutlined />}
      loading={saveAndContinueLoading}
    >
      <span>{t(`Save / Continue ${continueLabel}`)}</span>
    </Button>
  ) : emptyElement;

  const operationsPopover = (id != null &&
    <Operations
      page={isEditOrShow ? (readonly ? "detail" : "edit") : "edit"}
      domainName={domainName}
      id={id}
      ownerClass={ownerClass}
      columnNameInOwnerClass={columnNameInOwnerClass}
      ownerId={ownerId}
      deleteCallback={(): void => {
        if (onDelete != null) {
          onDelete();
        }
        pushUrlToHistory();
      }}
      updateCallback={emptyMethod}
      zIndex={zIndex + 1}
      showActions={true}
      switchEditable={switchEditable}
    />
  );

  const submitTitle = (wizardCanSubmit ? t("Submit wizard information") :
    t("The wizard has been submitted, and can not be submitted again"));
  const submitLabel = (wizardHasNextStep) ? t("Next step") : t("Submit");
  const wizardNextStepButton = isWizard ? (
    <Button
      className="modal-title-button"
      type="primary"
      htmlType="submit"
      size="small"
      onClick={onWizardNextStep}
      disabled={!isValid || (!wizardCanSubmit)}
      title={wizardHasNextStep ? t("Next step") : submitTitle}
      loading={wizardLoadingButton === "next"}
      icon={wizardHasNextStep ? <CaretRightOutlined /> : <FileDoneOutlined />}
    >
      <span>{submitLabel}</span>
    </Button>
  ) : emptyElement;

  const wizardPreviousStepButton = isWizard ? (
    <Button
      className="modal-title-button"
      type="default"
      htmlType="submit"
      size="small"
      onClick={onWizardPreviousStep}
      disabled={!isValid || !wizardHasPreviousStep}
      title={t("Previous step")}
      loading={wizardLoadingButton === "previous"}
      icon={<CaretLeftOutlined />}
    ><span>{t('Previous step')}</span></Button>
  ) : emptyElement;

  const wizardSaveButton = (isWizard) ? (
    <Button
      className="modal-title-button"
      type="default"
      htmlType="submit"
      size="small"
      onClick={onWizardSave}
      disabled={!isValid}
      title={t("Save the wizard data, you can back to work on it in future")}
      loading={wizardLoadingButton === 'save'}
      icon={<SaveOutlined />}
    >{t('Save wizard data')}</Button>
  ) : emptyElement;

  const titleLabel = readonly ?
    "Switch to edit mode" : "Switch to show mode";
  const titleTitle = readonly ?
    "Switch to edit mode title" : "Switch to show mode title";
  const icon = readonly ? (<UnlockOutlined
    title={updatable ? t(titleLabel) : t("No permission")}
    style={updatable ? {} : {
      opacity: "0.5", /* reduce opacity to indicate disabled state */
      pointerEvents: "none", /* disable pointer events */
    }} />) : <LockOutlined />;

  const switchModeButton: ReactElement = (
    <Button
      title={t(titleTitle)}
      className="modal-title-button"
      onClick={() => switchEditable?.(!(readonly ?? false))}
      size={"small"}
      icon={icon}
    >
      {t(titleLabel)}
    </Button>
  );

  return {
    className: `${mode}-modal ${mode}-${domainName}-model`,
    wrapClassName: `${mode}-modal-wrap ${mode}-${domainName}-modal-wrap`,
    open,
    title: (
      <div onClick={e => stopPropagationAndPreventDefault(e)}>
        <div className="left">
          <span> {calcModalTitle} </span>
        </div>
        <div className="right">
          <Space size={"middle"}>
            {isEditOrShow && operationsPopover}
            {!saveAndCloseButton && (isEditOrShow && updatable && switchModeButton)}
            {<SaveButtonGroup
               saveAndCloseButton={saveAndCloseButton}
               saveAndEditButton={saveAndEditButton}
               saveAndContinueButton={saveAndContinueButton}
               switchModeButton={(isEditOrShow && updatable) ? switchModeButton : emptyElement}
            />}
            {wizardPreviousStepButton}
            {wizardNextStepButton}
            {wizardSaveButton}
          </Space>
        </div>
      </div>
    ),
    footer: false,
    maskClosable: false,
    centered: false,
    zIndex: (zIndex != null) ? zIndex : 1031,
    width,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    closeIcon: <Popconfirm
      title={t(cancelConfirmMessage ?? 'Discard changes?')}
      onConfirm={() => {
        onCancel();
        pushUrlToHistory();
      }}
      okText={t("Confirm")}
      cancelText={t("Cancel")}
      placement="bottomRight"
      zIndex={zIndex + 1}
      disabled={!cancelConfirmMessage}
    >
      <CloseIcon onClick={() => {
        if (!cancelConfirmMessage) {
          onCancel();
          pushUrlToHistory();
        }
      }}/>
    </Popconfirm>,
    destroyOnClose: true,
    key: `${domainName}_${mode}_modal_${readonly? 'readonly' : 'editable'}_${id}`
  };
};

export default modalPropsBuilder;
