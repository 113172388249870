import React, { ReactElement, useState } from 'react';
import { FileOperatorProps } from '@props/RecordProps';
import { Drawer } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import FileOperator from './FileOperator';
import { CloseIcon } from "../";
import './App.less';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { useDomainPermit } from '@utils/DomainPermitUtils';

const FileDisplay = (props: FileOperatorProps): ReactElement => {
  const {
    updatable, page, record, domainName, type, zIndex, column,
    multiple
  } = props;
  const { title, key, serverSideType } = column;
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const isMultipleMode = (type === 'array') || (serverSideType === 'FILE_LIST') || (multiple === true);
  const domainPermit = useDomainPermit(domainName, record?.id);

  const content = (
    <FileOperator
      fieldValue={record?.[key]}
      domainName={domainName}
      fieldTitle={title}
      // 因为是显示页面，不是编辑页面，因此 form 为空
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form={undefined}
      key={key}
      multiple={isMultipleMode}
      zIndex={zIndex + 2}
      type={type}
      column={column}
      record={record}
      updatable={(domainPermit.canUpdate != null && domainPermit.canUpdate) && updatable}
      columnKey={key}
    />
  );

  if (page === 'LIST') {
    return (<>
      <PaperClipOutlined
        onClick={() => setDrawerVisible(true)}
      />
      <div onClick={stopPropagationAndPreventDefault}>
        <Drawer
          rootClassName="file-display-container"
          width="400px"
          rootStyle={{ margin: "auto" }}
          destroyOnClose={true}
          zIndex={zIndex + 3}
          title={title}
          open={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          closeIcon={<CloseIcon onClick={setDrawerVisible} />}
        >
          <span>
            {content}
          </span>
        </Drawer>
      </div>
    </>);
  } else if (page === 'DISPLAY' || page === 'INLINE_DISPLAY') {
    return content;
  }
  return (<></>);
};

export default FileDisplay;
