const openWindowWithTarget = (url: string, target: "_blank" | "_self"): void => {
  const newWindow = window.open(url, target, 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

/**
 * Open a url in new browser link
 * https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
 */
export const openInNewTab = (url: string): void => {
  openWindowWithTarget(url, '_blank');
};

/**
 * Open a url in current browser tab
 */
export const openInCurrentTab = (url: string): void => {
  openWindowWithTarget(url, '_self');
};

export const getOperatingSystem = (): string => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  return operatingSystem;
};

/**
 * Sets focus on the first textarea element with the specified className,
 * if it is not currently focused.
 * @param {string} className - The class name of the textarea element(s) to target.
 * @returns {void}
 */
export const autoFocusTextarea = (className: string): void => {
  const elements = Array.from(document.getElementsByClassName(className));
  if (elements.length > 0) {
    const inputElem = (elements[0] as HTMLTextAreaElement);
    if (inputElem !== document.activeElement) {
      inputElem.focus();
    }
  }
};
