import React, { ReactElement } from 'react';

export interface CodeBriefProps {
  value: string;
}

const CodeBrief = (props: CodeBriefProps): ReactElement => {
  const { value } = props;
  return (
    (<span style={{ paddingLeft: "8px" }}>
      <code style={{ whiteSpace: "nowrap" }}>
        {!!value && value?.length > 20 ? value?.substring(0, 20) + "..." : value}
      </code>
    </span>)
  );
};

export default CodeBrief;
