import { getNetworkService, NetworkService } from "@utils/WebsocketUtils";

const DOMAIN_DATA_CHANGE_TOPIC = 'domain_data_change';

interface DomainDataChangeSubscriberRequestPayload {
  domainName: string;
  action: 'subscribe' | 'unsubscribe';
}

interface DomainDataChangeSubscriberResponsePayload {
  status: 'success' | 'failed';
}

export type UnsubscribeCallback = () => void;

export enum DomainChangeEventTypeEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface DomainDataChangeEvent {
  ids: number[];
  eventType: DomainChangeEventTypeEnum;
}

interface DomainDataChangeRawEvent extends DomainDataChangeEvent {
  domainName: string;
}

export type DomainDataChangeEventListener = (event: DomainDataChangeEvent) => void;

export class DomainAutoRefreshService {

  private readonly networkService: NetworkService;
  private readonly listenerRecord: Record<string, Array<DomainDataChangeEventListener>> = {};

  constructor(networkService: NetworkService) {
    this.networkService = networkService;
    networkService.onEvent((event) => {
      if (event.type === 'connected') {
        Object.keys(this.listenerRecord).forEach(domainName => this.register(domainName));
      }
    });
    this.networkService.subscribe(DOMAIN_DATA_CHANGE_TOPIC, (payload: DomainDataChangeRawEvent) => {
      this.listenerRecord[payload.domainName]?.forEach(l => {
        try {
          l(payload);
        } catch (e) {
          console.error(`Failed to handle domain data change event for domain ${payload.domainName}: ${e}`);
        }
      });
    });
    networkService.init();
  }

  private readonly register = (domainName: string): void => {
    this.networkService
      .send<DomainDataChangeSubscriberRequestPayload, DomainDataChangeSubscriberResponsePayload>(DOMAIN_DATA_CHANGE_TOPIC, {
        domainName: domainName,
        action: 'subscribe'
      })
      .then(payload => {
        if (payload.status === 'success') {
          console.log(`Successfully subscribed to domain ${domainName}`);
        } else {
          console.error(`Failed to subscribe to domain ${domainName}`);
        }
      })
      .catch(e => {
        console.error(`Failed to subscribe to domain ${domainName}: ${e}`);
      });
  };

  private readonly unregister = (domainName: string): void => {
    this.networkService
      .send<DomainDataChangeSubscriberRequestPayload, DomainDataChangeSubscriberResponsePayload>(DOMAIN_DATA_CHANGE_TOPIC, {
        domainName: domainName,
        action: 'unsubscribe'
      })
      .then(payload => {
        if (payload.status === 'success') {
          console.log(`Successfully unsubscribed to domain ${domainName}`);
        } else {
          console.error(`Failed to unsubscribe to domain ${domainName}`);
        }
      })
      .catch(e => {
        console.error(`Failed to unsubscribe to domain ${domainName}: ${e}`);
      });
  };

  public readonly subscribe = (domainName: string, listener: DomainDataChangeEventListener): UnsubscribeCallback => {
    if (this.listenerRecord[domainName]) {
      console.info(`Domain ${domainName} is already monitored`);
      this.listenerRecord[domainName].push(listener);
    } else {
      this.listenerRecord[domainName] = [listener];
      this.register(domainName);
    }
    return () => {
      const listeners = this.listenerRecord[domainName];
      if (!listeners) {
        return;
      }
      listeners.splice(listeners.findIndex(l => l === listener), 1);
      if (listeners.length === 0) {
        this.unregister(domainName);
        delete this.listenerRecord[domainName];
      }
    };
  };

}

let domainAutoRefreshService: DomainAutoRefreshService;

export const getDomainAutoRefreshService = (): DomainAutoRefreshService => {
  if (!domainAutoRefreshService) {
    domainAutoRefreshService = new DomainAutoRefreshService(getNetworkService());
  }
  return domainAutoRefreshService;
};