import { RecordProps } from "@props/RecordProps";

export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function removeDuplicate<T>(arr: Array<T>): Array<T> {
  return [...new Set(arr)];
}

export function removeDuplicateById(arr: Array<RecordProps>): Array<RecordProps> {
  return arr.filter((item: RecordProps, index: number, self: Array<RecordProps>) => {
    return self.findIndex((t: RecordProps) => t.id === item.id) === index;
  });
}
