import React, { ReactElement, ReactNode, Suspense } from "react";
import { FormInstance, Tag } from "antd";
import { EnumTypes, RecordProps } from "@props/RecordProps";
import {
  DisplayComponentRenderFunction,
  DisplayControllerProps,
  isStatusSeverityType
} from "./ComponentsConfig";
import { isObject } from "@utils/ObjectUtils";
import { hasDetailPanel, isObjectType } from "@utils/ColumnsUtils";
import { FileFieldType } from "@config/base";
import i18n from '@config/i18n';
import { CloseCircleOutlined } from '@ant-design/icons';

import {
  CommentsIcon,
  DocumentDisplay, FileDisplay, SingleImage,
} from '../components';

import { ObjectIdsDisplayComponent, ClickableURL } from "../form/fields";

import {
  HasDetailFieldPlaceholderCell, TextCell, RelatedObjectsCell,
  MultiSelectCell, ObjectCell, StatusEnumCell, EnumCell, BooleanCell,
  DateTimeCell, RolesCell, IdsCell, CronExpressionCell, MultipleObjectsCell,
  JsonCell, CodeCell, MarkdownCell, StacktraceCell
} from '../form/cells';

import { isJsonString } from "@utils/StringUtils";
import Ratings from "../form/fields/Ratings";

//FIXME change to import from module
import { GroupedGrandChildComponent } from "../components/groupedGrandChild";
import { IconSelect } from "../components/icons";
import { emptyMethod } from "@utils/Constants";
import { displayHasDetailPlaceholder, notDisplayHasDetailPlaceholder, shouldDisplayIcon } from "@utils/ComponentUtils";
import EntityAttributesComponentCell from "../form/entityAttributes/EntityAttributesComponentCell";
import SubTableFormDisplayComponentCell from "../form/subTable/SubTableFormDisplayComponentCell";
import { EntityAttributeValues } from "../form/entityAttributes/EntityAttributesUtils";
import LineChart from "../form/fields/LineChart";
import { TableChart } from "../form/fields/TableChart";


export const idCellComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page } = props;
  return (text: unknown): ReactElement => (Number.parseInt(text as string) < 0) ?
    (<span title={i18n.t("Id will be generated when you save the record")}> - <span style={{color: "transparent"}}>{text}</span></span>) :
    (<TextCell value={text as string} length={page === 'DISPLAY' ? 999 : undefined} />
  );
};

export const textCellComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page } = props;
  return (text: unknown): ReactElement => (
    <TextCell value={text as string} length={page === 'DISPLAY' ? 999 : undefined} />
  );
};

export const relateObjectCellComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const {
    page, column, domainName, zIndex, switchTabCallback, isHighlightField, tableMode
  } = props;
  const { key, type, elementType } = column;
  if (type === 'array' && elementType === FileFieldType) {
    return attachmentsDisplayComponentRender(props);
  }
  return (text: unknown, record: RecordProps): ReactElement => {
    return notDisplayHasDetailPlaceholder(page, tableMode) ? (
      <RelatedObjectsCell
        isCurrentActiveTab={true}
        record={record}
        column={column}
        ownerClass={domainName}
        zIndex={zIndex + 1}
        form={{} as FormInstance}
      />
    ) : (
        <HasDetailFieldPlaceholderCell
          switchTabCallback={switchTabCallback}
          isHighlightField={isHighlightField}
          columnKey={key}
        />
    );
  };
};

export const relateObjectInlineCellComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const {
    column, domainName, zIndex,
  } = props;
  return (text: unknown, record: RecordProps): ReactElement => {
    return <RelatedObjectsCell
      record={record}
      column={column}
      ownerClass={domainName}
      zIndex={zIndex + 1}
      form={{} as FormInstance}
      isCurrentActiveTab={true}
    />;
  };
};

export const codeDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column, page, tableMode, zIndex } = props;
  const { key, extInfo } = column;
  return (text: unknown, record: RecordProps): ReactElement => {
    if (extInfo?.showBrief) {
      return (<CodeCell
                value={record[key]}
                column={column}
                page="INLINE_DISPLAY"
                zIndex={zIndex}
                record={record}
              />);
    }
    if (hasDetailPanel(column) && displayHasDetailPlaceholder(page, tableMode)) {
      return (<HasDetailFieldPlaceholderCell
        {...props}
        columnKey={key}
      />);
    }
    return (<Suspense fallback={<div />}>
      <CodeCell
        column={column}
        value={record[key]}
        page={page}
        tableMode={tableMode}
        zIndex={zIndex}
        record={record}
      />
    </Suspense>);
  };
};

export const tagsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => {
    const options = record[key];
    const isNullOption = (options == null || options === "");
    const isValidJson = isJsonString(options);
    if (!isNullOption && !isValidJson) {
      return <Tag icon={<CloseCircleOutlined />} color="error">
        {i18n.t("is not a valid json string", { value: options })}
      </Tag>;
    }
    const value = isNullOption ? "" :
      (typeof options === 'string') ? JSON.parse(options) : options;
    return (
      <MultiSelectCell
        columnMeta={column}
        value={value} />
    );
  };
};

export const tagListDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => {
    const options = record[key];
    const isNullOption = (options == null || options === "");
    const isValidJson = isJsonString(options);
    if (!isNullOption && !isValidJson) {
      return <Tag icon={<CloseCircleOutlined />} color="error">
        {i18n.t("is not a valid json string", { value: options })}
      </Tag>;
    }
    if (isNullOption) {
      return <></>;
    } else {
      const value = (typeof options === 'string') ? JSON.parse(options) : options;
      return (
        <MultiSelectCell
          columnMeta={column}
          value={value} />
      );
    }
  };
};

export const objectDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column, tableMode, zIndex, multiple } = props;
  const { key, type, labelField } = column;

  if (column.isDynamicField || column.type === 'object' || isObjectType(column.type)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (multiple) ? (text: unknown, record: RecordProps): ReactElement => {
      return (<MultipleObjectsCell
        domainName={column.elementDomain ?? type}
        zIndex={zIndex}
        displayText={""}
        tableMode={tableMode}
        ids={(text as unknown) as Array<number>}
      />);
    } : (text: unknown, record: RecordProps): ReactElement => {
      const val = record[key];
      if (val == null) {
        return (<></>);
      }
      const displayText = labelField ? val[labelField] : undefined;
      const isObjectAndHasId = isObject(val) && ('id' in val);
      return (<ObjectCell
        domainName={column.elementDomain ?? type}
        id={isObjectAndHasId? val.id : val}
        labelField={labelField}
        displayText={displayText}
        tableMode={tableMode}
        zIndex={zIndex + 1}
      />);
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (text: unknown): ReactElement => {
      return (<MultipleObjectsCell
        domainName={
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          column.elementType!}
        zIndex={zIndex}
        displayText={""}
        tableMode={tableMode}
        ids={((text as unknown) as Array<{id: number}>)?.map(v => v.id)}
      />);
    };
  }
};

export const getEnumDisplayComponent = (type: EnumTypes):
  ((props: DisplayControllerProps) => DisplayComponentRenderFunction) => {
  const Controller = isStatusSeverityType(type) ? StatusEnumCell : EnumCell;
  return (props: DisplayControllerProps): DisplayComponentRenderFunction => {
    const { column, enumValues } = props;
    const { key, type, enumType } = column;
    //TODO Remove duplicate code for this and enum
    return (text: unknown, record: RecordProps): ReactElement => (
      <Controller
        value={record[key]}
        field={key}
        mappings={enumValues}
        type={enumType ?? type}
      />);
  };
};

export const booleanDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => (
    <BooleanCell value={record[key]} />
  );
};

export const multipleSelectDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column, page } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => (
    <MultiSelectCell
      columnMeta={column}
      value={record[key]}
      page={page}
    />);
};

export const dateDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { key, type } = column;
  return (text: unknown, record: RecordProps): ReactElement => (
    <DateTimeCell type={type} value={record[key]} />);
};

export const zonedDatetimeDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { key, type } = column;
  return (text: unknown, record: RecordProps): ReactElement => {
    let value = record[key];
    if (value && typeof value === 'string') {
      value = value.includes('[') ? value.split('[')[0] : value;
    }
    // console.log('zonedDatetimeDisplayComponentRender', key, value);
    return <DateTimeCell type={type} value={value} />;
  };
};

export const httpMethodDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (
    <Tag className="http-method-tag">
      {(text == null) ? "" :
        (typeof text === "string") ? text :
          (text as { name: string }).name}
    </Tag>
  );
};

export const rolesDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (<RolesCell value={text as string} />);
};

export const radioDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (<div>{text as string}</div>);
};

export const percentageDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (
    (text == null) ? <div /> : <div className="percentage-cell">{`${text as string}%`}</div>);
};

export const numericDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  if(column.key === 'id') {
    return idCellComponentRender(props);
  }
  return (text: unknown): ReactElement => (
    (text == null) ? <div /> : <div className={`numeric-cell col-${column.key}`}>{text as string}</div>);
};

export const fieldDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (<TextCell value={text as string} />);
};

export const updatedIdsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column, page, zIndex, switchTabCallback, isHighlightField, tableMode } = props;
  const { key } = column;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (text: IdsCellValueProps, record: RecordProps): ReactNode => {
    return (!displayHasDetailPlaceholder(page, tableMode)) ?
      (<IdsCell
        value={text}
        domainClassId={record?.domainClass?.id}
        zIndex={zIndex + 1}
      />
      ) : <HasDetailFieldPlaceholderCell
        columnKey={key}
        switchTabCallback={switchTabCallback}
        isHighlightField={isHighlightField}
      />;
  };
};

export const cronExpressionDisplayComponentRender = (): DisplayComponentRenderFunction => {
  return (text: unknown): ReactElement => (<CronExpressionCell value={text as string} />);
};

export const stacktraceDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page, zIndex } = props;
  return (text: unknown): ReactElement => {
    return (<StacktraceCell
      value={text as string}
      page={page}
      zIndex={zIndex + 1}
    />);
  };
};

export const ratingsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { type } = column;
  return (text: unknown): ReactElement => {
    return (<Ratings
         value={text as RecordProps}
         type={type}
      />);
  };
};

export const stringDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page } = props;
  return (text: unknown): ReactElement => (
    <TextCell
      value={text as string}
      length={page === 'DISPLAY' ? 999 : undefined}
    />
  );
};

export const jsonDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page, column, tableMode } = props;
  const { key } = column;

  return (text: unknown): ReactElement => {
    if (hasDetailPanel(column) && displayHasDetailPlaceholder(page, tableMode)) {
      return (<HasDetailFieldPlaceholderCell
        {...props}
        columnKey={key}
      />);
    }
    return (<Suspense fallback={<div />}>
      <JsonCell text={text as string} page={page} />
    </Suspense>);
  };

};

export const lineChartDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page, column} = props;

  if (page === 'LIST') {
    return (text: unknown): ReactElement => {
      return (<TextCell value={text as string} />);
    };
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return (value: any): ReactElement => {
    return <LineChart
      value={value}
      column={column}
    />;
  };
};

export const tableChartDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page, column} = props;

  if (page === 'LIST') {
    return (text: unknown): ReactElement => {
      return (<TextCell value={text as string} />);
    };
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return (value: any): ReactElement => {
    return <TableChart
      data={value}
      column={column}
    />;
  };
};

export const markdownDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { page, zIndex, column, tableMode } = props;
  const { key } = column;

  return (text: unknown): ReactElement => {
    if (hasDetailPanel(column) && displayHasDetailPlaceholder(page, tableMode)) {
      return (<HasDetailFieldPlaceholderCell
        {...props}
        columnKey={key}
      />);
    }
    return (<Suspense fallback={<div />}>
      <MarkdownCell
        text={text as string}
        page={page}
        zIndex={zIndex + 1}
        tableMode={tableMode}
      />
    </Suspense>);
  };
};

export const objectIdsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { tableMode, zIndex, column } = props;
  const { labelField, key } = column;

  // 假设 objectType(objectIds对应的对象的类型信息)
  // 保存在对象的 objectType 字段中
  return (text: unknown, record: RecordProps): ReactElement => {
    return <ObjectIdsDisplayComponent
      record={record}
      text={text as Array<string>}
      labelField={labelField}
      tableMode={tableMode}
      zIndex={zIndex + 1}
      columnKey={key}
    />;
  };
};

export const attachmentsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  return (text: unknown, record: RecordProps) => {
    return attachmentDisplayComponentRender(props)(text as string, record);
  };
};

export const attachmentDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { title, key, type } = column;
  return (text: unknown, record: RecordProps) => {
    return (<FileDisplay
      fieldTitle={title}
      type={type}
      key={key}
      fieldValue={record[key]}
      updatable={false}
      columnKey={key}
      {...props}
      form={{} as FormInstance}
      record={record}
    />);
  };
};

export const imageDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column } = props;
  const { title, key, type } = column;
  return (text: unknown, record: RecordProps) => {
    return (<SingleImage
      fieldTitle={title}
      type={type}
      key={key}
      fieldValue={record[key]}
      updatable={false}
      {...props}
      form={{} as FormInstance}
      record={record}
    />);
  };
};

export const documentDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { zIndex, column, page, switchTabCallback, isHighlightField, tableMode } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => {
    return (notDisplayHasDetailPlaceholder(page, tableMode) ?
        <DocumentDisplay
          record={record}
          column={column}
          ownerClass={""}
          zIndex={zIndex + 1}
          isCurrentActiveTab={true}
          form={{} as FormInstance}
        /> : <HasDetailFieldPlaceholderCell
          columnKey={key}
          switchTabCallback={switchTabCallback}
          isHighlightField={isHighlightField}
        />
    );
  };
};

export const groupedGrandChildDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const {
    column, page, zIndex, domainName, form, switchTabCallback, isHighlightField, tableMode
  } = props;
  const { key } = column;
  return (text: unknown, record: RecordProps): ReactElement => {

    return shouldDisplayIcon(page, tableMode) ? (<GroupedGrandChildComponent
      record={record}
      column={column}
      page={page}
      zIndex={zIndex}
      domainName={domainName}
      form={form}
    />) : (<HasDetailFieldPlaceholderCell
      columnKey={key}
      switchTabCallback={switchTabCallback}
      isHighlightField={isHighlightField}
    />);
  };
};

export const commentsDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { column, zIndex, domainName, form } = props;
  const { title, key, type } = column;
  return (text: unknown, record: RecordProps) => {
    return (<CommentsIcon
      column={column}
      domainName={domainName}
      fieldTitle={title}
      fieldValue={null}
      form={form ?? {} as FormInstance}
      key={key}
      multiple={true}
      record={record}
      type={type}
      updatable={true}
      zIndex={zIndex}
    />);
  };
};


export const urlDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const { zIndex } = props;

  return (text: unknown): ReactElement => {
    const url = String(text ?? "");
    return (<ClickableURL
      url={url}
      zIndex={zIndex}
    />);
  };
};

export const iconDisplayComponentRender = (props: DisplayControllerProps): DisplayComponentRenderFunction => {
  const { zIndex } = props;

  return (text: unknown): ReactElement => {
    return (
      <IconSelect
        value={text}
        readonly={true}
        zIndex={zIndex}
        onChange={emptyMethod}
      />
    );
  };
};


export const entityAttributesDisplayComponentRender = (props: DisplayControllerProps): DisplayComponentRenderFunction => {
  const {
    column, domainName, zIndex,
  } = props;
  return (value: unknown, record: RecordProps) => (
    <EntityAttributesComponentCell
      value={value as EntityAttributeValues}
      column={column}
      owner={record}
      ownerClass={domainName}
      zIndex={zIndex}
      editable={false}
    />
  );
};


export const subTableDisplayComponentRender = (
  props: DisplayControllerProps
): DisplayComponentRenderFunction => {
  const {
    column, domainName, zIndex,
  } = props;
  return (value: unknown, record: RecordProps) => (
    <SubTableFormDisplayComponentCell
      column={column}
      owner={record}
      ownerClass={domainName}
      zIndex={zIndex}
    />
  );
};
