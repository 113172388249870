import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Form, Input, Button, Space, Popover } from 'antd';
import {
  WarningOutlined, LoadingOutlined, LockOutlined, UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { login, logout, Credentials, loggedIn } from "@utils/LoginUtils";
import { getDefaultPage } from "@utils/TokenUtils";
import { isDevelopmentEnv } from "@config/base";
import { QuickLoginPanel, SwitchLanguage } from "../development";
import { Link } from "react-router-dom";
import { useTheme } from "@utils/hooks";
import { layout, tailLayout } from "./layoutConfig";
import { useConfig } from "@utils/hooks";

import './login.less';

const TenantToEmailSuffixMapping: { [propName: string]: string } = isDevelopmentEnv() ? {
  'samt': 'samtc.com',
  'aecc': 'aecc.cn',
  'clokm': 'boe.com',
  'muyan': 'muyan.cloud'
} : {};

export const getEmailSuffix = (): string => {
  const { hostname } = window.location;
  const tenant = hostname.split('.')[0];
  const suffix = TenantToEmailSuffixMapping[tenant] || 'muyan.cloud';
  return suffix;
};

const LoginPage = (props: { next: string, firstTime?: boolean }): ReactElement => {
  const { t } = useTranslation();
  const { next, firstTime } = props;
  const [loginSuccess, setLoginSuccess] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const themeInfo = useTheme();
  const { value: registerEnable } = useConfig('register.self_register');
  const { value: resetPasswordEnable } = useConfig('register.reset_password');

  const redirectToConfigPage = useCallback((): void => {
    const defaultPage = getDefaultPage();
    let assignUrl = '/';
    // 仅在 next 不为 / 时，优先级高于 defaultPage
    if (next != null && next !== '/') {
      assignUrl = next;
    } else if (defaultPage != null && defaultPage !== '') {
      assignUrl = defaultPage;
    } else if (next != null) {
      assignUrl = next;
    }
    window.location.replace(assignUrl);
  }, [next]);

  const onFinish = useCallback((values: Credentials): void => {
    setLoading(true);
    login(values, themeInfo.ssoEnable).then(() => {
      if (loggedIn()) {
        setLoading(false);
        redirectToConfigPage();
      }
    }).catch((e) => {
      setLoginSuccess(false);
      setLoading(false);
      setErrorMsg(e.message);
    });
  }, [redirectToConfigPage, themeInfo.ssoEnable]);

  useEffect(() => {
    if (themeInfo.ssoEnable) {
      onFinish({
        username: '',
        password: '',
        rememberMe: ''
      });
    }
  }, [themeInfo.ssoEnable, onFinish]);

  const setLoginInfo = useCallback((email: string, autoLogin?: boolean): void => {
    const cre: Credentials = {
      username: email,
      password: "password",
      rememberMe: "false",
    };
    form.setFieldsValue(cre);
    if (autoLogin) {
      onFinish(cre);
    }
  }, [form, onFinish]);

  useEffect(() => {
    if (firstTime === true) {
      setLoginInfo(`admin@${getEmailSuffix()}`, false);
    }
  }, [firstTime, setLoginInfo]);

  useEffect(() => {
    if (loggedIn()) {
      setLoading(false);
      redirectToConfigPage();
    }
  }, [redirectToConfigPage, loginSuccess, errorMsg, t]);

  const onFinishFailed = (errorInfo: unknown): void => {
    console.error(`Failed to login: ${JSON.stringify(errorInfo)}`);
  };

  if (next === "/logout") {
    logout();
  }

  const widthStyle = { width: "300px" };
  const userIcon = <UserOutlined className="site-form-item-icon" />;
  const loginPrefix = isDevelopmentEnv() ? (
    <Popover
      title={undefined}
      placement="left"
      content={<QuickLoginPanel
        setLoginInfo={setLoginInfo}
      />}
    >
      {userIcon}
    </Popover>
  ) : userIcon;

  return loggedIn() ? (<></>) : (
    <div>
      <div className="login-div">
        <Space direction="vertical">
          {themeInfo?.logo &&
            <div className="login-logo">
              <img
                src={themeInfo.logo}
                style={{ maxWidth: '240px' }}
              />
            </div>
          }
          <Form
            className="login-form"
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            colon={false}
            form={form}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: t('login:Please input your email') }]}
            >
              <Input
                prefix={loginPrefix}
                style={widthStyle}
                placeholder={t("login:Please input your email")}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: t('login:Please input your password') }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                style={widthStyle}
                placeholder={t("login:Please input your password")}
              />
            </Form.Item>

            <Form.Item {...tailLayout} valuePropName="checked">
              {/*<Checkbox>Remember me</Checkbox>*/}
              {!loading &&
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  {t("login:LoginButtonLabel")}
                </Button>
              }
              {loading &&
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={true}
                  icon={<LoadingOutlined />}>
                  {t('login:KnockingTheDoor')}
                </Button>}
              {!loginSuccess &&
                <span className="login-failed-msg">
                  <WarningOutlined className="login-failed-msg" />{errorMsg}
                </span>
              }
            </Form.Item>
            {(registerEnable || resetPasswordEnable) &&
              <div style={{
                display: 'flex',
                justifyContent: 'space-between', // This will evenly distribute the items
                alignItems: 'center', // Vertically center align items if needed
              }}>
                {registerEnable &&
                  <Link
                    to='/account?scene=register'
                  >
                    {t('account:Register new account')}
                  </Link>
                }
                <div style={{ textAlign: 'center', flex: '1' }}>
                  <SwitchLanguage />
                </div>
                {resetPasswordEnable &&
                  <Link
                    to='/account?scene=resetPassword'
                  >
                    {t('account:Forgot password')}
                  </Link>
                }
              </div>
            }
          </Form>
        </Space>
        <br />
      </div>
    </div>
  );
};

export default LoginPage;
