import React, { ReactElement } from "react";
import ReactMarkdownWrap from "../../../components/wrap/ReactMarkdownWrap";
import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";

const MarkdownWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs } = props;
  return (
    <ReactMarkdownWrap
      linkTarget="_blank"
    >
      {dataAndConfigs.data as string}
    </ReactMarkdownWrap>
  );
};

export default MarkdownWidget;
