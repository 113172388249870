import {
  DomainDataWebSocketOperation, DomainDataWebsocketUpdateResponseProps, RecordProps
} from "@props/RecordProps";
import { DataProps } from './useData';

import { Dispatch } from 'react';
import { WebSocketDummyMessage } from "@config/base";
import { SocketInterface } from "@utils/WebsocketUtils";

export interface RealTimeHookProps {
  websocket: SocketInterface;
}

export interface RealtimePayloadProps {
  domainName: string;
  data: Array<RecordProps>;
  dataDispatch: Dispatch<{
    type: "set";
    payload: DataProps;
    operation?: DomainDataWebSocketOperation;
  }>;
  websocket: SocketInterface;
  fetchDataCallback: (showLoading?: boolean) => void;
}

export type RealtimeAction = 'initConnection' | 'transferIds' | 'close';
export type RealtimeWebsocketListenFunction = () => ((message?: string) => void);

const realtimeReducer = (action: {
  type: RealtimeAction;
  payload: RealtimePayloadProps;
}): RealtimeWebsocketListenFunction | undefined => {
  const { type, payload } = action;
  const { domainName, data, websocket, fetchDataCallback } = payload;
  const refreshListenIds = (websocket: SocketInterface, domainName: string, newData: Array<RecordProps>): void => {
    const messageString = JSON.stringify(
      { domainName, ids: (newData ?? []).map((d: RecordProps) => d.id) });
    console.log("Sending message to refresh listen ids: ", messageString);
    websocket.send(messageString);
  };
  switch (type) {
    case 'initConnection':
      return (() => {
        return (message?: string) => {
          console.log("Message from server: ", message);
          if (message === WebSocketDummyMessage) {
            return;
          }
          const respFromServer: Array<DomainDataWebsocketUpdateResponseProps> = JSON.parse(message || '[]');
          console.log(respFromServer);
          fetchDataCallback(false);
        };
      }) as RealtimeWebsocketListenFunction;
    case 'transferIds':
      if (websocket?.isConnected()) {
        refreshListenIds(websocket, domainName, data);
      } else {
        console.log("Websocket is not connected, will wait 1 second and connect again");
        setTimeout(() => {
          const messageString = JSON.stringify(
            { domainName, ids: (data ?? []).map((d: RecordProps) => d.id) });
          console.log("Sending message: ", messageString);
          websocket?.send(messageString);
        }, 1000);
      }
      break;
    case 'close':
      websocket?.close();
      break;
    default:
      throw new Error("Not support action ", type);
  }
};

export default realtimeReducer;
