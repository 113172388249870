import React, { ReactElement, useCallback, useState } from "react";
import { Button, Form, Input, Space } from 'antd';
import { CheckCircleTwoTone, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PasswordValidator from "password-validator";
import { v4 as uuid } from 'uuid';
import {
  registerOrResetPassword,
  RegisterOrResetPasswordForm,
  sendVerificationCode,
  SendVerificationCodeForm
} from "@utils/AccountUtils";
import { Link } from "react-router-dom";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { loggedIn } from "@utils/LoginUtils";
import { layout, tailLayout } from "./layoutConfig";
import { useConfig, useTheme } from "@utils/hooks";
import { openInfoNotification } from "@utils/NotificationUtils";

import './login.less';
import { SwitchLanguage } from "../development";

const validator = new PasswordValidator()
  .is().min(8)              // Minimum length 8
  .is().max(100)            // Maximum length 100
  .has().uppercase()        // Must have uppercase letters
  .has().lowercase()        // Must have lowercase letters
  .has().digits()           // Must have digits
  .has();                   // Should not have spaces

const AccountPage = (): ReactElement => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const scene = params.get('scene') === 'resetPassword' ? 'resetPassword' : 'register';
  const invitationCode = scene === 'register' && params.get('invitationCode') || undefined;
  const [requestId] = useState<string>(uuid());
  const [validationCodeErrorMsg, setValidationCodeErrorMsg] = useState<string>("");
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [sendingVerificationCode, setSendingVerificationCode] = useState<boolean>(false);
  const [form] = Form.useForm<RegisterOrResetPasswordForm>();
  const [coolDown, setCoolDown] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const themeInfo = useTheme();

  const { value: registerEnable } = useConfig('register.self_register');
  const { value: resetPasswordEnable } = useConfig('register.reset_password');

  const onFinish = useCallback((values: RegisterOrResetPasswordForm): void => {
    setSubmitErrorMsg("");
    setSubmitting(true);
    registerOrResetPassword(scene, requestId, {
      ...values,
      invitationCode,
    }).then(() => {
      setSuccess(true);
    }).catch((e) => {
      setSubmitErrorMsg(e.message);
    }).finally(() => {
      setSubmitting(false);
    });
  }, [invitationCode, requestId, scene]);

  const onSendVerificationCode = useCallback(async (values: SendVerificationCodeForm) => {
    if (!values.email) {
      setValidationCodeErrorMsg(t('account:Please input your email first'));
      return;
    }
    setSendingVerificationCode(true);
    setValidationCodeErrorMsg("");
    try {
      await sendVerificationCode(scene, requestId, values);
      let counter = 60;
      setCoolDown(counter);
      const timer = setInterval(() => {
        --counter;
        setCoolDown(counter);
        if (counter <= 0) {
          clearInterval(timer);
        }
      }, 1000);
      openInfoNotification(t('account:Verification code sent successfully'));
    } catch (e: unknown) {
      setValidationCodeErrorMsg(t('account:Failed to send verification code', {
        error: t(`account:${(e as { message: string }).message}`)
      }));
    } finally {
      setSendingVerificationCode(false);
    }
  }, [requestId, scene, t]);

  const onFinishFailed = (errorInfo: unknown): void => {
    console.error(`Failed to ${scene}: ${JSON.stringify(errorInfo)}`);
  };

  const widthStyle = { width: "300px" };

  if (loggedIn()) {
    window.location.href = "/";
    return <></>;
  }

  const sceneTitle = {
    "register": t("account:register title"),
    "resetPassword": t("account:resetPassword title")
  };

  const sceneSuccessMsg = {
    "register": t("account:register successfully"),
    "resetPassword": t("account:resetPassword successfully"),
  };

  return (
    <div className="login-div">
      <Space direction="vertical">
        {themeInfo?.logo &&
          <div className="login-logo">
            <img
              src={themeInfo.logo}
              style={{ maxWidth: '240px' }}
            />
          </div>
        }
        {!success
          ? (<>
            <Form
              className="login-form"
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
              colon={false}
              form={form}
            >
              {scene === 'register' &&
                <Form.Item
                  name="name"
                  hasFeedback={true}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    style={widthStyle}
                    placeholder={t("account:What should we call you?")}
                  />
                </Form.Item>
              }
              <Form.Item
                name="email"
                hasFeedback={true}
                rules={[
                  { required: true, message: t('account:Please input your email') },
                  {
                    type: 'email',
                    message: t('account:The input is not valid E-mail'),
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  style={widthStyle}
                  placeholder={t("account:Please input your email")}
                />
              </Form.Item>

              <Form.Item
                name="verificationCode"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (!value) {
                        const errMsg = t('account:Verification code could not be empty');
                        setValidationCodeErrorMsg(errMsg);
                        return Promise.reject(errMsg);
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                style={{
                  width: '100%',
                }}
                validateStatus={validationCodeErrorMsg ? 'error' : 'success'}
                help={`${validationCodeErrorMsg}`}
              // help={!!validationCodeErrorMsg ? <div>{validationCodeErrorMsg}</div> : undefined}
              >
                <Space direction={'horizontal'}>
                  <Input
                    onChange={(e) => {
                      form.setFieldValue('verificationCode', e.target.value);
                    }}
                    placeholder={t("account:Please input verification code")}
                  />
                  <Button
                    type="primary"
                    disabled={sendingVerificationCode || coolDown > 0}
                    loading={sendingVerificationCode}
                    onClick={() => {
                      onSendVerificationCode({
                        email: form.getFieldValue('email'),
                      });
                    }}
                  >
                    {coolDown > 0 ? `${coolDown}s` : t("account:Get Verification Code")}
                  </Button>
                </Space>
              </Form.Item>

              <Form.Item
                name="password"
                hasFeedback={true}
                rules={[
                  { required: true, message: t('account:Please input your password') },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (!validator.validate(value)) {
                        return Promise.reject(new Error(t("account:Min length 8, use Aa & 0-9")));
                      }
                      return Promise.resolve();
                    }
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={widthStyle}
                  placeholder={t("account:Min length 8, use Aa & 0-9")}
                />
              </Form.Item>

              <Form.Item
                name="confirmedPassword"
                hasFeedback={true}
                dependencies={['password']}
                rules={[
                  { required: true, message: t('account:Please input your password again') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('account:The two passwords that you entered do not match')));
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={widthStyle}
                  placeholder={t("account:Please input your password again")}
                />
              </Form.Item>

              <Form.Item
                {...tailLayout}
                valuePropName="checked"
                validateStatus={submitErrorMsg ? 'error' : 'success'}
                help={`${submitErrorMsg}`}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  {sceneTitle[scene]}
                </Button>
              </Form.Item>
              {(registerEnable || resetPasswordEnable) &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between', // This will evenly distribute the items
                  alignItems: 'center', // Vertically center align items if needed
                }}>
                  <Link
                    to='/login'
                    style={{
                      float: 'left'
                    }}
                  >
                    {t('login:Login')}
                  </Link>
                  <div style={{ textAlign: 'center', flex: '1' }}>
                    <SwitchLanguage />
                  </div>
                  {scene === 'resetPassword' ? (registerEnable && <Link
                    to='/account?scene=register'
                    style={{
                      float: 'right'
                    }}
                    onClick={(e) => {
                      window.location.href = "/account?scene=register";
                      stopPropagationAndPreventDefault(e);
                    }}
                  >
                    {t('account:Register new account')}
                  </Link>
                  )
                    : (resetPasswordEnable && <Link
                      to='/account?scene=resetPassword'
                      style={{
                        float: 'right'
                      }}
                      onClick={(e) => {
                        window.location.href = "/account?scene=resetPassword";
                        stopPropagationAndPreventDefault(e);
                      }}
                    >{t('account:Forgot password')}</Link>)
                  }
                </div>
              }
            </Form>
          </>
          ) : (
            <Space className="login-form" direction={'vertical'} align={'center'} size={30}>
              <CheckCircleTwoTone
                style={{ fontSize: '1000%' }}
                twoToneColor={'#00EE00'}
              />
              <div style={{ fontSize: '24px' }}>
                {sceneSuccessMsg[scene]}
              </div>
              <Link to='/login'>
                {t('account:Back to login')}
              </Link>
            </Space>
          )
        }
      </Space>
    </div>
  );
};

export default AccountPage;
