import React, { ReactElement, Suspense } from "react";
import { emptyMethod } from "@utils/Constants";
import { TableMetaProps } from "@props/RecordProps";

const Line = React.lazy(() => import('../../components/wrap/LineWrap'));

if (typeof window.URL.createObjectURL == 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.URL.createObjectURL = emptyMethod;
}

interface LineChartProps {
  value: string;
  column: TableMetaProps;
}

const LineChart = (props: LineChartProps): ReactElement => {
  const { value, column } = props;
  const { chartInfo } = column.extInfo || {};
  const data = JSON.parse(value);

  return (
    <Suspense fallback={<div/>}>
      <Line
        data={data}
        height={400}
        xField={chartInfo?.xField || 'x'}
        yField={chartInfo?.yField || 'y'}
        axis={chartInfo?.axis}
        seriesField={chartInfo?.seriesField}
        point={chartInfo?.point}
        color={chartInfo?.color}
      />
    </Suspense>
  );
};

export default LineChart;
