import React, { CSSProperties, ReactElement } from 'react';
import { Card, Table, FormInstance, Checkbox, Space, Modal, Select, Pagination } from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  TableMetaProps, CellComponentDisplayPage, TableMode, RecordProps, FormProps,
  EnumMetaProps, ObjectValues, PaginationProps, fetchDataCallbackProps
} from '@props/RecordProps';
import { DataProps } from '@utils/hooks';
import { getColumnTransKey } from '@utils/ColumnsUtils';
import { getDisplayRenderFunction } from '@kernel/DisplayComponentsMapping';
import { emptyMethod } from '@utils/Constants';
import Operations from '../list/Operations';
import { useTranslation } from 'react-i18next';
import { getLabelToDisplay } from '@utils/ObjectUtils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ZoomInOutlined } from '@ant-design/icons';

import './CardList.less';
import { CloseIcon } from '../../components/icons';
import { capitalizeFirstLetter, typeWithPackageToSimpleType } from '@utils/StringUtils';
import { wrapAsHtml } from '@utils/ComponentUtils';
import { getPagination } from '../../components/Pagination';

const { Option } = Select;

interface CardListProps {
  dataState: DataProps;
  dataDispatch: (action: { type: 'set'; payload: DataProps }) => void;
  listForm: FormProps;
  domainName: string;
  page: CellComponentDisplayPage;
  zIndex: number;
  isHighlightField?: boolean;
  columns: Array<TableMetaProps>;
  deleteCallback: (id: number) => void;
  fetchDataCallback: (props: fetchDataCallbackProps) => void;
  paginationState: PaginationProps;
  paginationDispatch: (action: { type: 'init' | 'set'; payload: { tableMode?: TableMode; current?: number; total?: number; pageSize?: number } }) => void;
  ownerId?: number;
  ownerClass?: string;
  displayMode?: TableMode;
  switchTab?: (key: string) => void;
  enumValues?: { [id: string]: EnumMetaProps[] },
  objectValues?: ObjectValues,
  onSelectRow?: (data: Array<RecordProps> | undefined) => void;
  ownerColumn?: TableMetaProps;
}

interface TableColumnMeta {
  key: string;
  label: string;
  value: React.ReactElement;
}

const hideColumnInCardList = ['zz-client-action', 'id', '$HTML_CONTENT$'];
const CardList: React.FC<CardListProps> = (props) => {
  const {
    dataState, listForm, columns, domainName, page, zIndex,
    ownerId, ownerClass, deleteCallback, fetchDataCallback,
    enumValues, objectValues, onSelectRow, dataDispatch,
    paginationState, paginationDispatch, ownerColumn
  } = props;
  const { data } = dataState;
  const recordsPerRow = (listForm.extInfo?.defaultRecordsPerRow ?? 4);
  const { t } = useTranslation();
  const [isDetailModalVisible, setIsDetailModalVisible] = React.useState(false);
  const [showDetailCard, setShowDetailCard] = React.useState<RecordProps | null>(null);
  const [hoveredCardId, setHoveredCardId] = React.useState<number | null>(null);

  const recordsPerRowOptions = Array.from({ length: 6 }, (_, i) => i + 1);

  const cardListStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${recordsPerRow}, 1fr)`,
    gridGap: '16px',
  } as CSSProperties;

  const handleMaxIconClick = (record: RecordProps): void => {
    setShowDetailCard(record);
    setIsDetailModalVisible(true);
  };

  const handleModalClose = (): void => {
    setIsDetailModalVisible(false);
  };

  const handleCardMouseEnter = (recordId: number): void => {
    setHoveredCardId(recordId);
  };

  const handleCardMouseLeave = (): void => {
    setHoveredCardId(null);
  };

  const handleRecordsPerRowChange = (value: number): void => {
    listForm.extInfo = {
      ...listForm.extInfo,
      defaultRecordsPerRow: value,
    };
  };

  const handleCardSelect = (recordId: number, e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked;
    let newSelectedRowKeys = dataState.selectedRowKeys ?? [];

    if (isChecked) {
      newSelectedRowKeys = [...newSelectedRowKeys, recordId];
    } else {
      newSelectedRowKeys = newSelectedRowKeys.filter((id) => id !== recordId);
    }

    const selectedRecords = data?.filter((record) => newSelectedRowKeys.includes(record.id));

    onSelectRow?.(selectedRecords);

    dataDispatch({
      type: 'set',
      payload: {
        ...dataState,
        selectedRowKeys: newSelectedRowKeys,
        selectedData: selectedRecords,
      },
    });
  };

  const rowData = (record: RecordProps): Array<TableColumnMeta> =>
    columns
      .filter(c => hideColumnInCardList.includes(c.key) === false)
      .map((column) => {
        const { key } = column;
        const label = t(getColumnTransKey(domainName, key));
        const render = getDisplayRenderFunction({
          column,
          enumValues: enumValues ?? ({} as { [id: string]: EnumMetaProps[] }),
          objectValues: objectValues ?? ({} as ObjectValues),
          domainName,
          page,
          zIndex,
          form: {} as FormInstance,
          switchTabCallback: emptyMethod,
          isHighlightField: false,
          tableMode: "card-list",
        });

        return {
          key,
          label,
          value: render(record[key], record),
        };
      });

  const tableColumns: ColumnType<TableColumnMeta>[] = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: '30%',
      className: 'label-column'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      className: 'value-column'
    },
  ];

  const simpleDomainName: string = capitalizeFirstLetter(typeWithPackageToSimpleType(domainName));
  const domainTitle = t(`domainTitle:${simpleDomainName}`);

  const getData = (card: RecordProps | null, rowData: (record: RecordProps) => Array<TableColumnMeta>, tableColumns: ColumnType<TableColumnMeta>[]): ReactElement => {
    return (card != null) && card['@HTML_CONTENT@'] ? (
      <div className="modal-content-wrapper html-content-container">
        {wrapAsHtml(card['@HTML_CONTENT@'])}
      </div>
    ) : (
      <Table
        size="large"
        rowKey="key"
        dataSource={rowData(card ?? {} as RecordProps)}
        columns={tableColumns}
        pagination={false}
        bordered
        showHeader={false}
        style={{ width: '100%' }}
      />);
  };

  const selectRecordsPerRow = (
    <div className="records-per-row-selector">
      <Space>
        <span>{t('Cards per row')}:</span>
        <Select
          defaultValue={recordsPerRow}
          style={{ width: 90 }}
          onChange={handleRecordsPerRowChange}
        >
          {recordsPerRowOptions.map((number) => (
            <Option key={number} value={number}>
              {t(`Per row`, { num: number })}
            </Option>
          ))}
        </Select>
      </Space>
    </div>
  );

  const cardRender = (record: RecordProps): ReactElement => {
    const labelToDisplay = getLabelToDisplay(record);
    const isChecked = dataState.selectedRowKeys?.includes(record.id) ?? false;
    const displayStyle = {
      visibility: (hoveredCardId === record.id) ? 'unset' : 'hidden',
    } as CSSProperties;
    const title = (
      <div className="ant-card-head-wrapper">
        <div
          className="card-title"
          onClick={(e) => {
            e.stopPropagation();
            handleCardSelect(record.id, { target: { checked: !isChecked } } as CheckboxChangeEvent);
          }}
          style={{ cursor: 'pointer' }}
          title={`${labelToDisplay} ${t('Click to select this record')}`}
        >
          <Space>
            <Checkbox
              checked={isChecked}
              style={{
                visibility: (hoveredCardId === record.id || isChecked) ? 'unset' : 'hidden',
              }} />
            {labelToDisplay}
          </Space>
        </div>
        <Space
          className="ant-card-extra"
          style={displayStyle}
          direction="horizontal"
        >
          <ZoomInOutlined
            onClick={() => handleMaxIconClick(record)}
            title={t('View details')} />
          <Operations
            id={record.id}
            ownerId={ownerId}
            ownerClass={ownerClass}
            domainName={domainName}
            deleteCallback={() => deleteCallback(record.id)}
            updateCallback={() => fetchDataCallback({
              newCurrent: paginationState.current,
              pageSizeNum: paginationState.pageSize,
              refreshListFormId: listForm.id,
              isInit: false,
              ownerColumn: ownerColumn,
              useCache: false,
              listFormParam: listForm
            })}
            zIndex={zIndex + 2}
            page={"list"}
            showActions={true} />
        </Space>
      </div>
    );
    return (
      <Card
        key={record.id}
        title={title}
        extra={undefined}
        onMouseEnter={() => handleCardMouseEnter(record.id)}
        onMouseLeave={handleCardMouseLeave}
        className={`card-item${hoveredCardId === record.id ? ' card-hovered' : ''}`}
      >
        {getData(record, rowData, tableColumns)}
        <div
          className="card-footer"
          onClick={() => handleMaxIconClick(record)}
        >
          <Space>
            <ZoomInOutlined />
            {t('View details')}
          </Space>
        </div>
      </Card>
    );
  };

  const pagination = <Pagination {...getPagination({
    current: paginationState.current,
    total: paginationState.total,
    pageSize: paginationState.pageSize,
    showLessItems: true,
    showQuickJumper: true,
    showSizeChanger: true,
    onChange: (page, pageSize) => {
      paginationDispatch({
        type: 'set', payload: {
          'pageSize': pageSize,
          'current': page,
        }
      });
      fetchDataCallback({
        newCurrent: page,
        pageSizeNum: pageSize,
        refreshListFormId: listForm.id,
        isInit: false,
        ownerColumn,
        useCache: false,
        listFormParam: listForm
      });
    },
    instruction: t('cardListPopoverInstruction')
  })}
  />;

  return (
    (<div className="card-list-container">
      <div className="controls-container">
        {pagination}
        {selectRecordsPerRow}
      </div>
      <div className="card-list" style={cardListStyle}>
        {data?.map((record: RecordProps) => cardRender(record))}
        <Modal
          title={`${t('Details of', { title: domainTitle + "(" + showDetailCard?.id + ") " + getLabelToDisplay(showDetailCard ?? {} as RecordProps) })}`}
          open={isDetailModalVisible}
          onCancel={handleModalClose}
          style={{ top: '5%', maxWidth: "60%", minWidth: "600px" }}
          bodyStyle={{ height: 'calc(90% - 55px)', overflowY: 'auto' }}
          footer={null}
          closeIcon={<CloseIcon onClick={handleModalClose} />}
          zIndex={zIndex + 1}
          className="card-detail-modal"
        >
          {getData(showDetailCard, rowData, tableColumns)}
        </Modal>
      </div>
    </div>)
  );
};

export default CardList;
