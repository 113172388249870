import React, { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { fetchDomainMeta, fetchCanCreate, fetchFormExtInfo } from '@utils/FetchUtils';
import { FormProps, RecordProps, TableMetaProps } from '@props/RecordProps';
import { PageHeader } from '../../form';
import { LargeSpin } from '../../components';
import './App.less';
import FullTextSearchPanel from './FullTextSearchPanel';
import { dataReducer, initData } from "@utils/hooks";

export interface WithSearchListProps {
  domainName: string;
  formId: number;
  zIndex: number;
  setOwnerIds?: (ownerIds: Array<number>) => void;
  initShowCreateModal?: boolean;
  initDomainId?: number;
  hideHeaderTitle?: boolean;
  ownerClass?: string;
  ownerIds?: Array<number>;
  ownerClassColumnName?: string;
}

const FullTextSearchListComponent = (props: WithSearchListProps): ReactElement => {
  const {
    domainName, formId, zIndex, initDomainId, initShowCreateModal, hideHeaderTitle,
    ownerClass, ownerIds, ownerClassColumnName, setOwnerIds
  } = props;
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [contentDisplayColumns, setContentDisplayColumns] = useState<Array<TableMetaProps>>([]);
  const [fullTextSearchColumns, setFullTextSearchColumns] = useState<Array<TableMetaProps>>([]);
  const [additionalSearchColumns, setAdditionalSearchColumns] = useState<Array<TableMetaProps>>([]);
  const [panelDisplayColumns, setPanelDisplayColumns] = useState<Array<TableMetaProps>>([]);
  const [sortColumns, setSortColumns] = useState<Array<TableMetaProps>>([]);
  const [willRefreshData, setWillRefreshData] = useState<boolean>(false);
  const [formExtInfo, setFormExtInfo] = useState<FormProps>();
  const [dataState, dataDispatch] = useReducer(dataReducer, initData);
  useEffect(() => {
    setLoading(true);
    fetchCanCreate(domainName).then(json => {
      setCanCreate(json.create);
    }).catch(e => console.error(`Failed to get canCreate of ${domainName}: ${e}`));
    fetchFormExtInfo(formId).then((formProps: FormProps) => {
      setFormExtInfo(formProps);
    });
    fetchDomainMeta(domainName, formId).then((meta: Array<TableMetaProps>) => {
      setColumns(meta);
      setContentDisplayColumns(meta.filter(c => c.groupName?.startsWith('contentDisplayGroup')));
      setFullTextSearchColumns(meta.filter(c => c.groupName?.startsWith('fullTextSearchConditionGroup')));
      setAdditionalSearchColumns(meta.filter(c => c.groupName?.startsWith('additionalSearchConditionGroup')));
      setSortColumns(meta.filter(c => c.groupName?.startsWith('sortingConditionGroup')));
      setPanelDisplayColumns(meta.filter(c => c.groupName?.startsWith('panelDisplayGroup')));
      return meta;
    })
      .finally(() => setLoading(false))
      .catch(e => console.error(`Failed to get form ${formId} for domain ${domainName}: ${e}`));

  }, [domainName, formId]);

  const refreshDataCallback = useCallback((): void => {
    setWillRefreshData(true);
  }, []);

  return loading ? (<LargeSpin />) : (
    <div className='with-search-container'>
      {hideHeaderTitle !== true &&
        <PageHeader
          initDomainId={initDomainId}
          initShowCreateModal={initShowCreateModal}
          listForm={formExtInfo ?? {} as FormProps}
          tableMode={"table-list"}
          domainName={domainName}
          data={dataState.data ?? []}
          selectedData={dataState.selectedData ?? []}
          columns={columns}
          enumValues={{}}
          objectValues={{}}
          setData={(data: Array<RecordProps>) => dataDispatch({ type: 'set', payload: { data } })}
          fetchDataCallback={() => refreshDataCallback()}
          initialCanCreate={canCreate}
          zIndex={zIndex + 1}
          ownerClass={ownerClass}
          ownerId={ownerIds?.[0]}
          columnNameInOwnerClass={ownerClassColumnName}
          dataState={dataState}
          dataDispatch={dataDispatch}
          createFormName={formExtInfo?.extInfo?.createFormName}
        />
      }
      <FullTextSearchPanel
        formExtInfo={formExtInfo}
        zIndex={zIndex}
        refreshData={willRefreshData}
        domainName={domainName}
        columns={columns}
        fetchDataCallback={(data: Array<RecordProps>) => {
          dataDispatch({ type: 'set', payload: { data } });
          setWillRefreshData(false);
        }}
        additionalSearchColumns={additionalSearchColumns}
        fullTextSearchColumns={fullTextSearchColumns}
        sortColumns={sortColumns}
        panelDisplayColumns={panelDisplayColumns}
        ownerClass={ownerClass}
        ownerIds={ownerIds}
        ownerClassColumnName={ownerClassColumnName}
        contentDisplayColumns={contentDisplayColumns}
        changeCallback={(record: RecordProps, selected: boolean): void => {
          const sd = (dataState.selectedData ?? []);
          if (selected) {
            dataDispatch({ type: 'set', payload: { selectedData: [...sd, record] } });
          } else {
            const index = sd.indexOf(record);
            const newSelectedData = sd.slice();
            newSelectedData.splice(index, 1);
            dataDispatch({ type: 'set', payload: { selectedData: newSelectedData } });
          }
        }}
        setOwnerIds={setOwnerIds}
      />
    </div>
  );
};

export default FullTextSearchListComponent;
