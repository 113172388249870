import { ReactElement } from "react";
import { DashboardWidgetProps, WidgetDataResponseProps } from "@props/RecordProps";
import { DefaultWidget } from "./widgets";
import { WidgetConfigures } from "./WidgetConfigs";

export type DashboardWidgetComponent = ((props: WidgetComponentRenderProps) => ReactElement);
export type WidgetComponentRenderProps = {
  widget: DashboardWidgetProps;
  dataAndConfigs: WidgetDataResponseProps;
};

export type AntdChartWidgetComponentRenderProps = {
  Component: ((props: unknown) => ReactElement);
  extraProps: {
    [propName: string]: unknown;
  }
}

export const getWidgetComponent = (props: DashboardWidgetProps): DashboardWidgetComponent => {
  const { type } = props;
  const component = WidgetConfigures.find(w => w.type === type)?.component;
  if (component != null) {
    return component;
  } else {
    return DefaultWidget;
  }
};
