import React, { ReactElement } from "react";
import { FormInstance } from 'antd';
import { MapOfEnumMetaProps, Store, TableMetaProps, WizardMetaProps, WizardMetaStepProps } from "@props/RecordProps";
import { MasterExpandWidth } from '@config/base';
import { MasterDetailLayout, MasterDetailVerticalLayout } from "../../layout";
import WizardStepComponent from "./WizardStepComponent";
import StepsComponent from "./StepsComponent";
import { emptyMethod } from "@utils/Constants";
import "./css/wizard.less";

interface WizardComponentProps {
  stepId: number;
  wizardId: number;
  zIndex: number;
  visibleSeed?: number | false;
  current: number;
  infoMessage?: string;
  warningMessage?: string;
  errorMessage?: string;
  disabledSteps?: Array<string>;
  finalSubmitted: boolean;
  redirect?: string;
  allStepFormFieldValues?: Store;
  wizardMeta?: WizardMetaProps;
  form: FormInstance;
  onClose?: () => void;
  onWizardRerun?: () => void;
  options?: Array<MapOfEnumMetaProps>;
  dynamicMetas?: Array<TableMetaProps>;
}

// 每个步骤传递到后台处理的数据都是之前所有步骤的字段数据的全集
// 所有全量字段数据保存在 allStepFormFieldValues 中
// visibleSeed 用于调用本组件的父组件每次使用 Math.random() 来传递一个随机值
// 来确保每次初始调用本组件的时候，可以将 visible 状态设置为 false
// 不然每次点击 close 按钮关闭本组件后，无法再将 visible 设置为 true
export default function WizardComponent(props: WizardComponentProps): ReactElement {
  const {
    current, infoMessage, warningMessage,
    errorMessage, disabledSteps, finalSubmitted, redirect, allStepFormFieldValues,
    wizardMeta, form, zIndex, onClose, onWizardRerun,
    options, dynamicMetas
  } = props;

  const direction = wizardMeta?.extInfo?.direction ?? 'horizontal';

  const getStepContent = (stepProp?: WizardMetaStepProps): ReactElement => {
    const isCurrentStep = (stepProp?.id === wizardMeta?.steps?.[current]?.id);
    return (wizardMeta == null || stepProp == null || !isCurrentStep) ?
      (<></>) : (<WizardStepComponent
        wizardId={wizardMeta?.id}
        wizardStep={stepProp}
        form={form}
        errorMessage={errorMessage}
        warningMessage={warningMessage}
        infoMessage={infoMessage}
        finalSubmitted={finalSubmitted}
        redirect={redirect}
        initialValues={allStepFormFieldValues}
        zIndex={zIndex + 1}
        onClose={onClose}
        onWizardRerun={onWizardRerun}
        options={options}
        dynamicMetas={dynamicMetas}
        direction={direction}
      />);
  };
  const currentStepMeta = wizardMeta?.steps[current];
  const currentStepContent = getStepContent(currentStepMeta);

  const steps = (
    <StepsComponent
      wizardMeta={wizardMeta}
      current={current}
      disabledSteps={disabledSteps}
      wizardDirection={direction}
    />
  );

  return direction === 'horizontal'
    ? <MasterDetailLayout
      flex={`${MasterExpandWidth}px`}
      leftContent={steps}
      rightContent={currentStepContent}
      leftOnClick={emptyMethod}
      rightVisible={true}
    />
    : <MasterDetailVerticalLayout
      header={steps}
      content={currentStepContent}
      headerClassName='wizard-modal-vertical-header'
      contentClassName='wizard-modal-vertical-content'
    />;
}
