import { ThemeInfoProps } from "@props/RecordProps";
import { getFaviconEl } from "@utils/ComponentUtils";
import { fetchDynamicThemeInfo } from "@utils/FetchUtils";
import { useEffect, useState } from "react";

export const defaultTheme = {
  title: "",
  favicon: "",
  backgroundImage: "",
  ssoEnable: false,
  ssoEnableLogic: ""
};

export const useTheme = (): ThemeInfoProps => {
  const [themeInfo, setThemeInfo] = useState<ThemeInfoProps>(defaultTheme);

  useEffect(() => {
    fetchDynamicThemeInfo().then((themeInfo: ThemeInfoProps): void => {
      setThemeInfo(themeInfo);
      if (themeInfo.title) {
        document.title = themeInfo.title;
      }
      if (themeInfo.backgroundImage && themeInfo.backgroundImage.length > 0) {
        document.body.style.backgroundImage = "url('" + themeInfo.backgroundImage + "')";
      }
      if (themeInfo.favicon && themeInfo.favicon.length > 0) {
        const faviconEl = getFaviconEl();
        if (faviconEl) {
          faviconEl.href = themeInfo.favicon;
        }
      }
      if (themeInfo.ssoEnableLogic && themeInfo.ssoEnableLogic?.length > 0) {
        //console.log(themeInfo.ssoEnableLogic, ' <--- themeInfo.ssoEnableLogic value');
        const ssoEnableEvalResult = eval(themeInfo.ssoEnableLogic);
        //console.log(ssoEnableEvalResult, ' <--- eval(themeInfo.ssoEnableLogic) value');
        themeInfo.ssoEnable = ssoEnableEvalResult;
      }
    }).catch((e: Error): void => {
      console.error(`Error fetching theme info: ${e}`);
    });
  }, []);
  return themeInfo;
};
