import React, { useEffect, useMemo, useState } from "react";
import {
  FormExtInfoProps,
  FormProps, GetColumnValueCallback,
  RecordProps,
  SaveOptionProps,
  SaveRecordProps,
  Store,
  TableMetaProps
} from "@props/RecordProps";

import './subTable.less';

import { FormInstance, Modal, Space, Spin, Table } from "antd";
import {
  fetchCanCreate,
  fetchDomainMeta,
  fetchFormExtInfo,
  fetchFormIdAndExtInfoByName,
  fetchFormIdAndExtInfoByType,
  fetchListOfRelateDomainData,
  populateDomainData
} from "@utils/FetchUtils";
import { ColumnsType } from "antd/lib/table";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { GetComponentProps } from "rc-table/lib/interface";
import { calcColumnTitle, isArrayType } from "@utils/ColumnsUtils";
import { clearFinderConditions } from "@kernel/ServerSideFinder";
import { CloseIcon } from "../../components";
import { ListComponent } from "../list";
import { useTranslation } from "react-i18next";
import { fullDomainNameToHumanReadable } from "@utils/StringUtils";
import {
  ArrayColumnOperationsTypeEnum,
  ArrayColumnOption,
  convertRecordToStore,
  convertValueFromForm,
  mergeChangedValues
} from "./SubTableUtils";
import { SubTableCell, SubTableCellProps } from "./SubTableCell";
import { SubTableRow, SubTableRowProps } from "./SubTableRow";
import { emptyMethod } from "@utils/Constants";
import {
  useDomainPermits,
} from "@utils/DomainPermitUtils";
import { getEditableController } from "@kernel/EditableComponentsMapping";
import { getDisplayRenderFunction } from "@kernel/DisplayComponentsMapping";
import { getFieldTitle } from "@utils/ComponentUtils";
import { convertValueForSave } from "@utils/SaveDomainUtils";
import { getCustomValueCallback, getFormFieldsValue } from "@utils/FormUtils";
import { buildPath, EVENT_BUS } from "@utils/eventBus/EventBus";

export interface SubTableFormProps {
  column?: TableMetaProps;
  initData?: RecordProps[];
  ownerClass?: string;
  // columnKey: string;
  owner?: SaveRecordProps;
  form?: FormInstance;
  editMode: boolean;
  zIndex: number;
  saveOptions?: SaveOptionProps;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  isCurrentActiveTab?: boolean;
  path?: string;
}

export interface DisplayRenderProps {
  record: RecordProps;
}

export interface EditableRenderProps {
  form: FormInstance;
  record: RecordProps;
}

export const EditableContext = React.createContext<FormInstance | null>(null);


const SubTableForm = (props: SubTableFormProps): React.ReactElement<SubTableFormProps> => {
  const {
    column, ownerClass, owner, zIndex,
    editMode, form, saveOptions, initData,
    isCurrentActiveTab, onValuesChange, path,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [extInfo, setExtInfo] = React.useState<FormExtInfoProps>();
  // const [domainName, setDomainName] = React.useState<string>();
  const [columns, setColumns] = React.useState<TableMetaProps[]>([]);
  const [columnsRecord, setColumnsRecord] = React.useState<Record<string, TableMetaProps>>({});
  const [data, setData] = React.useState<RecordProps[]>([]);
  const [candidates, setCandidates] = React.useState<RecordProps[]>([]);
  const formName = column?.extInfo?.displayForm;
  const domainName = column?.elementDomain ?? column?.elementType;
  const [formId, setFormId] = useState<number>();
  const [canCreate, setCanCreate] = useState<boolean>();
  // const [current, setCurrent] = React.useState<number>(1);
  // const [total, setTotal] = React.useState<number>(0);
  const [clicked, setClicked] = React.useState<number | undefined>();
  const [sortKey, setSortKey] = React.useState<string | undefined>();
  const [keepOrder, setKeepOrder] = React.useState<boolean>(false);
  const [sortable, setSortable] = React.useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const createdRowIndexRef = React.useRef<number>(0);
  const setDataAndSortRef = React.useRef<(updateFunc: (prevState: RecordProps[]) => RecordProps[]) => void>(emptyMethod);
  const dataIds = React.useMemo(() => data.map(d => d.id), [data]);
  const domainPermitsState = useDomainPermits(domainName, dataIds);
  const needPopulate = React.useMemo<boolean>(() => !!columns.find((c) => c.fieldType === "TRANSIENT_FIELD"), [columns]);
  const formRecord = React.useMemo<Record<number, FormInstance>>(() => ({}), []);
  
  const populateDataRef = React.useRef<(d: RecordProps[]) => Promise<RecordProps[]>>((d) => Promise.resolve(d));
  const setClickRef = React.useRef<(clicking: number| undefined) => void>(setClicked);

  const ownerId = owner?.id;
  const currentPath = path ?? `/SubTable|${domainName}`;

  populateDataRef.current = (d: RecordProps[]): Promise<RecordProps[]> => {
    if (!needPopulate || !formId || !domainName) {
      return Promise.resolve(d);
    }
    return populateDomainData({
      formId,
      domainName,
      objects: d,
    });
  };

  useEffect(() => {
    if (clicked) {
      onValuesChange?.([], form?.getFieldsValue(true));
    }
  }, [clicked, onValuesChange, form]);

  useEffect(() => {
    if (domainName == null) {
      return;
    }
    fetchCanCreate(domainName).then(json => {
      setCanCreate(json.create);
    }).catch(e => console.error(`Failed to get canCreate of ${domainName}: ${e}`));
  }, [domainName]);

  const arrayColumnOption: ArrayColumnOption = React.useMemo<ArrayColumnOption>(() => {
    return {
      operations: {
        [ArrayColumnOperationsTypeEnum.ADD]: [],
        [ArrayColumnOperationsTypeEnum.REMOVE]: [],
        [ArrayColumnOperationsTypeEnum.EDIT]: [],
        [ArrayColumnOperationsTypeEnum.NOT_CHANGE]: [],
      }
    };
  }, []);

  setDataAndSortRef.current = (updateFunc) => {
    setData((prevState) => {
      const newState = updateFunc(prevState);
      if (keepOrder) {
        return newState;
      }
      if (sortable && sortKey != null) {
        return newState.sort((a, b) => (b[sortKey] ?? 0) - (a[sortKey] ?? 0));
      } else {
        return newState.sort((a, b) => Math.abs(b.id) - Math.abs(a.id));
      }
    });
  };

  setClickRef.current = (clicking: number | undefined) => {
    setClicked((prevState) => {
      if (prevState !== clicking) {
        const prevRowRecord = data.find((d) => d.id === prevState);
        if (prevRowRecord && prevState) {
          // write previous editing data to record
          const rowForm = formRecord[prevState];
          const val = rowForm.getFieldsValue(true);
          const getColumnValueRecord: Record<string, GetColumnValueCallback> = getCustomValueCallback(rowForm);
          if (getColumnValueRecord) {
            Object.keys(getColumnValueRecord).forEach((key) => {
              val[key] = getColumnValueRecord[key]();
            });
          }
          populateDataRef.current([mergeChangedValues(prevRowRecord, val)]).then(d => {
            setDataAndSortRef.current((prevState) => {
              const newData = [...prevState];
              const index = newData.findIndex((item) => prevRowRecord.id === item.id);
              newData[index] = d[0];
              return newData;
            });
          });
        }
      }
      return clicking;
    });
  };

  useEffect(() => {
    setClickRef.current(undefined);
  }, [isCurrentActiveTab]);

  useEffect(() => {
    if (!saveOptions || !column) {
      return;
    }
    saveOptions.arrayColumnOptions[column.key] = arrayColumnOption;
  }, [arrayColumnOption, column, saveOptions]);

  useEffect(() => {
    if (columns.length == 0) {
      return;
    }
    const backReferenceColumn = columns.find((c) => c.key === column?.backReferenceField);
    if (backReferenceColumn && !isArrayType(backReferenceColumn.type)) {
      arrayColumnOption.ownerColumnName = backReferenceColumn.key;
    }
    const record: Record<string, TableMetaProps> = {};
    columns.forEach((c: TableMetaProps) => {
      record[c.key] = c;
    });
    setColumnsRecord(record);
  }, [columns, column?.backReferenceField, arrayColumnOption]);

  useEffect(() => {
    if (!!formName && !!domainName) {
      fetchFormIdAndExtInfoByName(domainName, formName).then((res: FormProps) => {
        setExtInfo(res.extInfo);
        setFormId(res.id);
      });
    } else if (formId != -1 && !!formId) {
      fetchFormExtInfo(formId).then((res: FormProps) => {
        setExtInfo(res.extInfo);
        setFormId(res.id);
      });
    } else if ((formId == -1 || !formId) && !!domainName) {
      fetchFormIdAndExtInfoByType(domainName, "List").then((res: FormProps) => {
        setFormId(res.id);
      });
    }
  }, [formId, formName, domainName]);

  useEffect(() => {
    setKeepOrder(!!(extInfo?.subTable?.keepOrder ?? column?.extInfo?.subTable?.keepOrder));
    const newSortByKey = extInfo?.subTable?.sortBy ?? column?.extInfo?.subTable?.sortBy;
    if (newSortByKey) {
      setSortKey(newSortByKey);
    } else {
      if (columns.find((c) => c.key === "displaySequence")) {
        setSortKey("displaySequence");
      }
    }
    setSortable(extInfo?.subTable?.sortable ?? column?.extInfo?.subTable?.sortable ?? false);
  }, [columns, extInfo, column]);

  useEffect(() => {
    if (!domainName) {
      console.error("Domain name is not ready.");
      return;
    }
    if (formId == null) {
      return;
    }
    fetchDomainMeta(domainName, formId).then(setColumns);
  }, [domainName, formId]);

  useEffect(() => {
    // not ready
    if (!domainName || Object.keys(columnsRecord).length === 0) {
      return;
    }
    if (initData) {
      populateDataRef.current(initData).then((res: RecordProps[]) => {
        if (res.length > 0) {
          createdRowIndexRef.current = -Math.max.apply(null, res.map(d => d.id));
        }
        setDataAndSortRef.current(() => res);
        arrayColumnOption.operations = {
          [ArrayColumnOperationsTypeEnum.ADD]: [],
          [ArrayColumnOperationsTypeEnum.REMOVE]: [],
          [ArrayColumnOperationsTypeEnum.EDIT]: [],
          [ArrayColumnOperationsTypeEnum.NOT_CHANGE]: res.map((d) => d.id),
        };
      });
      setLoading(false);
      return;
    }
    if (!ownerId || !column) {
      setLoading(false);
      return;
    }
    if (ownerId && ownerClass) {
      // TODO notify dev sub table should not contains more than 500 records
      fetchListOfRelateDomainData({
        formId,
        domainName: ownerClass,
        ownerId,
        columnName: column.key,
        current: 1,
        max: 500,
      }).then(res => {
        const data = res.data.map(d => convertRecordToStore(d, columnsRecord));
        if (data.length > 0) {
          createdRowIndexRef.current = -Math.max.apply(null, data.map(d => d.id));
        }
        setDataAndSortRef.current(() => data);
        arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE] = data.map((d) => d.id);
        setLoading(false);
      });
    }
  }, [initData, ownerId, domainName, column, ownerClass, formId, arrayColumnOption, columnsRecord]);

  const tableColumns = useMemo(() => {
    // not ready
    if (!domainName) {
      return [];
    }
    columns.forEach(c => calcColumnTitle(domainName, c));

    const result = columns
      .filter((c) => c.display !== false)
      .map((c) => {
        // Owner column should be readonly, this is the reference to master
        // object from the sub table object
        if (column?.backReferenceField === c.key && !isArrayType(c.type)) {
          return {
            ...c,
            editable: false,
          };
        }
        return c;
      })
      .map((c) => {
        const {
          key, type, title, multiple, helpText,
          unique, email,
        } = c;
        const cellPath = buildPath(currentPath, key);
        const fieldTitle = getFieldTitle({ domainName, title, helpText, key, unique, email });
        const editableController = getEditableController(type);
        let EditableRender: React.FC<EditableRenderProps> | undefined = undefined;
        if (editableController) {
          EditableRender = ({ form, record, }) => {
            return editableController({
              updatable: editMode,
              fieldValue: form.getFieldValue(key),
              type,
              fieldTitle,
              form,
              key,
              domainName,
              record,
              zIndex,
              multiple: !!multiple,
              column: c,
              style: {
                flex: 1,
              },
              onValuesChange: (changedValues, allValues) => {
                const mergedRecord = mergeChangedValues(record, allValues);
                populateDataRef.current([mergedRecord]).then(d => {
                  setData((prevState) => [...prevState.map((item) => {
                    if (item.id === record.id) {
                      return d[0];
                    }
                    return item;
                  })]);
                });
                EVENT_BUS.emit(cellPath, mergedRecord[key]);
              },
              path: cellPath,
              page: "LIST",
            });
          };
        }
        const displayController = getDisplayRenderFunction({
          column: c,
          enumValues: {},
          objectValues: {},
          domainName,
          page: "LIST",
          zIndex,
          form: {} as FormInstance,
        });
        const DisplayRender: React.FC<DisplayRenderProps> = ({ record }) => {
          const value = record[c.key];
          return displayController(value, record);
        };
        return {
          key: c.key,
          dataIndex: c.key,
          title: <div>
            {!c.nullable && <span className="sub-table-header-required-mark">*</span>}
            {fieldTitle}
          </div>,
          ellipsis: true,
          width: (c.key === "id") ? 90 : undefined,
          onCell: (record: RecordProps): SubTableCellProps => ({
            column: c,
            record,
            editMode,
            clicked,
            setClickRef: setClickRef,
            setData,
            sortKey,
            arrayColumnOption,
            DisplayRender,
            EditableRender,
            sortable,
          }),
        };
      });
    if (editMode) {
      result.push({
        key: "@EDIT@",
        dataIndex: "@EDIT@",
        title: t("Edit"),
        ellipsis: false,
        width: 140,
        onCell: (record: RecordProps): SubTableCellProps => ({
          record,
          editMode,
          clicked,
          setClickRef,
          setData,
          sortKey,
          arrayColumnOption,
          updatable: (column?.extInfo?.subTable?.updatable ?? true),
          deletable: (record.id < 0 || (column?.extInfo?.subTable?.deletable ?? true)),
          domainPermit: domainPermitsState[record.id],
          DisplayRender: () => <></>,
          sortable,
        }),
      });
    }
    // console.log("SubTableForm tableColumns: ", result);
    return result;
  }, [domainName, columns, t, domainPermitsState, column, zIndex, editMode, clicked, sortKey,
    arrayColumnOption, currentPath, sortable]);

  useEffect(() => {
    if (!column || !form) {
      return;
    }
    const getColumnValueRecord: Record<string, GetColumnValueCallback> = getCustomValueCallback(form);
    if (getColumnValueRecord) {
      getColumnValueRecord[`@ARRAY_COLUMNS@${column.key}`] = () => {
        const convertedData = data.map((d) => {
          const innerForm = formRecord[d.id];
          const processedValues = convertValueFromForm(d, getFormFieldsValue(innerForm), columnsRecord);
          Object.keys(columnsRecord).forEach((key) => processedValues[key] = convertValueForSave(columnsRecord[key], processedValues));
          return processedValues;
        });
        if (!keepOrder) {
          convertedData.reverse();
        }
        return convertedData;
      };
    }
  }, [column, form, data, formRecord, columnsRecord, keepOrder]);

  const numberOfColumns = tableColumns.length;

  const onRow = (record: RecordProps, index: number): SubTableRowProps => ({
    record,
    index,
    clicked,
    'set-data-and-sort-ref': setDataAndSortRef,
    'populate-data-ref': populateDataRef,
    'number-of-columns': numberOfColumns,
    'form-record': formRecord,
    'array-column-option': arrayColumnOption,
  });

  const searchModal = (domainName &&
    <Modal
      className="search-modal"
      okText={t("Select")}
      open={showSearchModal}
      centered={false}
      title={t("SearchFormFormTitle", { domainTitle: fullDomainNameToHumanReadable(domainName) })}
      onCancel={() => {
        setShowSearchModal(false);
        clearFinderConditions(domainName);
      }}
      maskClosable={true}
      //zIndex should be higher than 1050, which is zIndex of dropdown menu
      zIndex={zIndex + 1}
      destroyOnClose={false}
      width="60%"
      key={`${domainName}_search_modal`}
      closeIcon={<CloseIcon onClick={(visible) => setShowSearchModal(visible)} />}
      style={{
        minWidth: "410px",
        marginTop: "1%",
      }}
      onOk={() => {
        if (candidates && candidates.length > 0) {
          populateDataRef.current(candidates).then((res: RecordProps[]) => {
            setDataAndSortRef.current((prevState) => {
              const newData = [...prevState];
              res.forEach((candidate) => {
                if (newData.findIndex((item) => item.id === candidate.id) === -1) {
                  const convertedData = convertRecordToStore(candidate, columnsRecord);
                  newData.push(convertedData);
                  if (!arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE].find(value => value === convertedData.id)) {
                    arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE].push(convertedData.id);
                  }
                }
              });
              return newData;
            });
          });
        }
        clearFinderConditions(domainName);
        setShowSearchModal(false);
      }}
    >
      <ListComponent
        tableMode="finder"
        inline={true}
        domainName={domainName}
        onSelectRow={(selectedRows) => {
          setCandidates(selectedRows ?? []);
        }}
        zIndex={zIndex + 1}
        ownerClass={ownerClass}
        ownerId={ownerId}
        columnNameInOwnerClass={column?.backReferenceField}
        multiple={true}
      />
    </Modal>);

  const domainTitle = fullDomainNameToHumanReadable(domainName);

  const effectiveData = (data.length > 0) ? data : [{ key: 'empty-row', id: Number.MIN_VALUE }];

  // console.log("SubTableForm: ", columns, needPopulate);

  return (loading || !column ? <Spin /> :
    <div>
      {editMode && <Space className="sub-table-header-icons-container" direction="horizontal">
        {canCreate && (column?.extInfo?.subTable?.creatable !== false) && <PlusOutlined
            title={t('Create new domain', { domainTitle })}
            className="link-icon"
            onClick={() => {
              const id = --createdRowIndexRef.current;
              const newRecord: RecordProps = { id };
              columns.forEach((c) => {
                if (c.defaultValue !== undefined) {
                  newRecord[c.key] = c.defaultValue;
                }
              });
              if (column?.backReferenceField && !isArrayType(column.type)) {
                newRecord[column?.backReferenceField] = owner;
              }
              if (sortable && sortKey != null) {
                newRecord[sortKey] = (data[data.length - 1]?.[sortKey] ?? 0) + 1;
              }
              if (extInfo?.subTable?.newLinePosition === 'bottom') {
                setData((prevState) => [...prevState, newRecord]);
              } else {
                setData((prevState) => [newRecord, ...prevState]);
              }
              arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.ADD].push(id);
              setClickRef.current(id);
            }}
        />}
        {column?.extInfo?.subTable?.searchModal &&
            <SearchOutlined
                className="link-icon"
                title={t("Link existing domain", { domainTitle })}
                onClick={() => {
                  setShowSearchModal(true);
                }}
            />}
      </Space>}
      <Table
        pagination={false}
        className="sub-table-container"
        columns={tableColumns as ColumnsType<RecordProps>}
        components={{
          body: {
            row: SubTableRow,
            cell: SubTableCell,
          },
        }}
        // bordered
        dataSource={effectiveData}
        size="small"
        onRow={onRow as GetComponentProps<RecordProps>}
        onChange={() => setClickRef.current(undefined)}
        scroll={{
          x: true,
        }}
      // rowKey='key'
      />
      {searchModal}
    </div>
  );
};


export default SubTableForm;
