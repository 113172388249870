import { DynamicFilterResponseProps } from "@props/RecordProps";
import { getDynamicFilters } from "@utils/FetchUtils";
import { useEffect, useReducer } from "react";

export type FiltersStateType = {
  dynamicFilters: Array<DynamicFilterResponseProps>;
  defaultFilter?: DynamicFilterResponseProps;
};

export const filtersInitialState: FiltersStateType = {
  dynamicFilters: [],
  defaultFilter: undefined,
};

export const filtersReducer = (state: unknown, action: {
  type: 'set';
  payload: FiltersStateType;
}): FiltersStateType => {
  switch (action.type) {
    case 'set':
      return {
        dynamicFilters: action.payload.dynamicFilters,
        defaultFilter: action.payload.defaultFilter
      };
    default:
      throw new Error("Not support action ", action.type);
  }
};

export const useFilter = (domainName: string, displayDefault?: boolean, refresh?: number): [Array<DynamicFilterResponseProps>, DynamicFilterResponseProps | undefined] => {
  const [state, dispatch] = useReducer(filtersReducer, filtersInitialState);
  useEffect(() => {
    getDynamicFilters(domainName, (displayDefault ?? true))
      .then((filters: Array<DynamicFilterResponseProps>) => {
        dispatch({
          type: 'set', payload: {
            dynamicFilters: filters,
            defaultFilter: filters?.find(f => f.isDefault === true)
          }
        });
      }).catch(e => console.error(`Failed to get filter of domain ${domainName}: ${e}`));
  }, [domainName, displayDefault, refresh]);
  return [state.dynamicFilters, state.defaultFilter];
};
