import { ServerUrlLocalStorageKey, SERVER_URL } from '@config/base';
import { LanguageLocalStorageKey } from '@config/i18n';
import { clearTokens, getAccessToken, saveTokens } from "./TokenUtils";

interface Credentials {
  username: string,
  password: string,
  rememberMe: string
}

interface FetchJsonResponse<ResponseBody> {
  body: ResponseBody,
  response: Response
}

/**
 * Login user, <b>remember me is not implemented</b>,
 * Every request to backend has token in header for authentication.
 * username: username
 * password: password
 * rememberMe: whether to remember me, currently not implemented
 * @param credentials
 * @param sso
 */
const login = async (credentials: Credentials, sso = false): Promise<void | FetchJsonResponse<unknown>> => {
  const credentialsWithSso: RequestCredentials = sso? 'include' : 'same-origin';
  const body: BodyInit = sso? '' : JSON.stringify(credentials);
  const response = await fetch(`${SERVER_URL}/api/login`, {
    method: 'POST',
    body,
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    credentials: credentialsWithSso
  });
  if (response.ok) {
    const loginResult = await response.json();
    saveTokens(loginResult);
    return loginResult;
  } else {
    let info;
    try {
      const payload = await response.json();
      info = payload.info as { info: string; };
    } catch {
      info = 'Login failed';
    }
    console.error("Login failed, response: ", info);
    throw new Error(info as string);
  }
};

const logout = async (): Promise<void> => {
  try {
    const accessToken = getAccessToken();
    const response = await fetch(`${SERVER_URL}/api/logout`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': "Bearer " + (accessToken == null ? "" : accessToken),
        'Content-Type': 'application/json'
      })
    });
    if (!response.ok) {
      console.error("Logout failed, response: ", response);
    }
  } catch (err) {
    console.error("Logout failed, response: ", err);
  }
  clearTokens();
  clearAllLocalStorage();
  setTimeout(() => {
    window.location.assign("/login");
  }, 500);
};

const loggedIn = (): boolean => {
  return getAccessToken() != null;
};

const preserveLocalStorageKeys = [ServerUrlLocalStorageKey, LanguageLocalStorageKey];

const clearAllLocalStorage = (): void => {
  const keys = Object.keys(localStorage);
  for (let i = 0; i < keys.length; i++) {
    if (!preserveLocalStorageKeys.includes(keys[i])) {
      localStorage.removeItem(keys[i]);
    }
  }
};

export {
  login,
  logout,
  loggedIn
};

export type {
  Credentials
};
