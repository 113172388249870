import React, { ReactElement } from 'react';
import { Button, Result, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccessDeniedComponent = (props: { showHomepageButton?: boolean }): ReactElement => {
  const { showHomepageButton } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (<Result
    status="403"
    title={t("403")}
    subTitle={t("Sorry, you are not authorized to access this page.")}
    extra={showHomepageButton && (
      <Space>
        <Button type="primary" onClick={() => navigate("/")}>{t('Homepage')}</Button>
      </Space>
    )
    }
  />);
};

export default AccessDeniedComponent;
