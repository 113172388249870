import { Statistic } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from 'react-i18next';

import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";
const { Countdown } = Statistic;

const CountdownWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs, widget } = props;
  const { t } = useTranslation();

  const config = JSON.parse(widget.options)?.config ?? {};
  const mergedConfigs = { ...config, ...dataAndConfigs };
  return (
    <Countdown
      title={t("Countdown")}
      format="D 天 H 时 m 分 s 秒"
      {...mergedConfigs}
    />
  );
};

export default CountdownWidget;
