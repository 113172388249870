import React, { ReactElement, useEffect } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import './app.css';
import { wrapAsHtml } from "@utils/ComponentUtils";
import { getAccessToken } from "@utils/TokenUtils";
import { SERVER_URL } from "@config/base";
import { makeid } from "@utils/StringUtils";

const DocumentDetail = (props: DetailPanelProps): ReactElement => {
  const { column, record, form, readonly } = props;
  const isReadonly = (readonly === true);
  const content = record?.[column.key] ?? "";
  const uniqueKey = makeid(6);
  const editorClassName = (isReadonly ? "document-editor__readonly_" : "document-editor__editable_") + uniqueKey;
  const toolbarClassName = (isReadonly ? "document-editor__readonly-toolbar_" : "document-editor__editable-toolbar_") + uniqueKey;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('@muyantech/ckeditor5').then((Editor) => {
        Editor.default.create(document.querySelector(`.${editorClassName}`), {
            language: 'zh-cn',
            fontFamily: {
                options: [
                    'default',
                    '宋体, SimSun, STSong',
                    '黑体, SimHei, STHeiti',
                    '新宋体, NSimSun',
                    '仿宋, FangSong_GB2312, 仿宋_GB2312, STFangsong, FangSong, fangsong',
                    '楷体, KaiTi, 楷体, KaiTi_GB2312',
                    '微软雅黑, Microsoft YaHei',
                    '隶书, LiSu',
                    '幼圆, YouYuan',
                    'Arial, Helvetica, sans-serif',
                    'Courier New, Courier, monospace',
                    'Georgia, serif',
                    'Lucida Sans Unicode, Lucida Grande, sans-serif',
                    'Tahoma, Geneva, sans-serif',
                    'Times New Roman, Times, serif',
                    'Trebuchet MS, Helvetica, sans-serif',
                    'Verdana, Geneva, sans-serif'
                ],
                supportAllValues: true
            },
            simpleUpload: {
                // The URL that the images are uploaded to.
                uploadUrl: `${SERVER_URL}/attachment/upload`,
                // Enable the XMLHttpRequest.withCredentials property.
                withCredentials: false,
                // Headers sent along with the XMLHttpRequest to the upload server.
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`
                }
            }
        })
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        .then((editor: any) => {
            const toolbarContainer = document.querySelector(`.${toolbarClassName}`);
            toolbarContainer?.appendChild(editor.ui.view.toolbar.element);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.editor = editor;
            editor.model.document.on('change:data', () => {
                form.setFieldsValue({
                    [column.key]: editor.getData()
                });
            });
            editor.isReadOnly = isReadonly;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (toolbarContainer != null && toolbarContainer.style != null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                toolbarContainer.style.display = (isReadonly) ? 'none' : 'block';
            }
        })
        .catch((err: Error) => {
            console.error(err);
        });
    });
  }, [column.key, form, readonly, record, editorClassName, toolbarClassName, isReadonly]);

  return (
    <div className="document-editor">
      <div className={toolbarClassName} />
      <div className="document-editor__editable-container">
        <div className={editorClassName}>
          {wrapAsHtml(content)}
        </div>
      </div>
    </div>
  );
};

export default DocumentDetail;
