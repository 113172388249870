import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { Checkbox, Col, Row, Spin, Radio } from "antd";
import { fetchSelectOptions } from "@utils/FetchUtils";
import { FormInstance } from "antd/es/form";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";

interface DynamicCheckboxProps {
  style?: CSSProperties;
  dfKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
  currentValue: string | Array<string> | number | Array<number>;
  form: FormInstance;
  updatable?: boolean;
  type: "checkbox" | "radio";
}

const DynamicCheckboxAndRadio = (props: DynamicCheckboxProps): ReactElement => {
  const { style, updatable, dfKey, onChange, form, currentValue, type } = props;
  const [options, setOptions] = useState<Array<CheckboxOptionType | string>>([] as Array<CheckboxOptionType | string>);
  const [loading, setLoading] = useState<boolean>(true);
  const disabled = (updatable == null) ? false : !updatable;

  useEffect(() => {
    fetchSelectOptions(dfKey)
      .then((options: Array<CheckboxOptionType | string>) => {
        setOptions(options);
      }).catch(e => {
        console.error(`Failed to get select options of dynamic field ${dfKey}: ${e}`);
      }).finally(() => setLoading(false));
  }, [dfKey]);

  const isValidCurrentValue = (
    currentValue == null ||
    typeof currentValue === 'string' ||
    typeof currentValue === 'number' ||
    Array.isArray(currentValue)
  );

  useEffect(() => {
    if (options?.length > 0) {
      if (isValidCurrentValue) {
        form.setFieldsValue({ [dfKey]: currentValue });
      } else {
        console.warn(`value ${currentValue} is not valid value for dynamic checkbox`);
        form.setFieldsValue({ [dfKey]: undefined });
      }
    }
  }, [dfKey, currentValue, options, form, isValidCurrentValue]);

  const className = `df_${type}`;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const content = (type === 'checkbox') ? (
    <Checkbox.Group
      className={className}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      defaultValue={isValidCurrentValue ? currentValue : undefined}
      style={style}
      onChange={onChange}
      disabled={disabled}
    >
      <Row>
        {options?.map(opt => (
          <Col key={opt.toString()} span={24}>
            <Checkbox value={opt}>{opt}</Checkbox>
          </Col>)
        )}
      </Row>
    </Checkbox.Group>
  ) : (
      <Radio.Group
        className={className}
        defaultValue={currentValue == null ? "" : currentValue.toString()}
        style={style}
        onChange={onChange}
        disabled={disabled}
      >
        {options?.map(opt => {
          return (
            <Radio key={opt.toString()} style={radioStyle} value={opt}>
              {opt}
            </Radio>
          );
        })}
      </Radio.Group>
    );

  return loading ? <Spin size="small" /> : content;
};

export default DynamicCheckboxAndRadio;
