import React, { ReactElement } from "react";
import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";
import { wrapAsHtml } from "@utils/ComponentUtils";

const HtmlWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs, widget } = props;
  const config = JSON.parse(widget.options)?.config ?? {};
  /* Set style on the html contents on widget options (extInfo)
    {
      "config": {
        "style": {
          "color": "red",
          "fontSize": "32px",
          "textAlign": "center"
        }
      }
    }
   */
  const mergedConfigs = { ...config, ...dataAndConfigs };
  return (
    <div
        className="html-widget"
        role="region"
        aria-label={config.className}
        {...mergedConfigs}
    >
      {wrapAsHtml(dataAndConfigs.data as string)}
    </div>
  );
};

export default HtmlWidget;
