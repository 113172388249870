import dayjs from 'dayjs';
import { DateTimeFormat, TimeFormat } from '@config/base';

/**
 * 格式化日期
 */
export const formatDate = (dateString: string): string => {
  return dayjs(dateString)?.format(DateTimeFormat);
};

/**
 * 格式化时间
 */
export const formatTime= (dateString: string): string => {
  return dayjs(dateString)?.format(TimeFormat);
};

/**
 * 获取当前日期的周一
 * @param date
 */
export const getMonday = (date: Date): Date => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.getFullYear(), date.getMonth(), diff);
};
