import React, { ReactElement, Suspense, useState } from "react";
import { CellComponentDisplayPage, SaveRecordProps, TableMetaProps, TableMode } from "@props/RecordProps";
import { Drawer } from "antd";
import { CodeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { emptyMethod } from "@utils/Constants";
import { CloseIcon } from "../../components/icons";
import CodeBrief from "./CodeBrief";

const CodeEditor = React.lazy(() => import('../fields/CodeEditor'));

const CodeContent = (props: {
  value: string | undefined,
  codeLanguage: string,
  zIndex: number,
  record?: SaveRecordProps | undefined;
}): ReactElement => {
  const { value, codeLanguage, zIndex, record } = props;
  return (
    <div
      onClick={(e) => stopPropagationAndPreventDefault(e)}
      className="code-cell-parent"
    >
      <pre className="line-numbers code-cell">
        <Suspense fallback={<div />}>
          <CodeEditor
            value={value ?? ""}
            onChange={emptyMethod}
            name={"code"}
            updatable={false}
            width="100%"
            mode={codeLanguage}
            zIndex={zIndex}
            record={record}
          />
        </Suspense>
      </pre>
    </div>
  );
};

const CodeCell = (props: {
  column: TableMetaProps;
  value?: string;
  page?: CellComponentDisplayPage;
  tableMode?: TableMode;
  zIndex: number;
  record?: SaveRecordProps | undefined;
}): ReactElement => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const { value, page, column, tableMode, zIndex, record } = props;
  const { extInfo, title } = column;
  const { t } = useTranslation();
  const content = <CodeContent
    value={value}
    codeLanguage={extInfo?.codeLanguage ?? "javascript"}
    zIndex={zIndex}
    record={record}
  />;
  return (page === 'DISPLAY' && tableMode != 'detail-drawer') ? content : (
    <>
      <span
        onClick={() => setDrawerVisible(true)}
        style={{
          cursor: 'pointer',
        }}
        title={t("Click to show code")}
      >
        <CodeOutlined className="code-cell-icon" />
        {extInfo?.showBrief && (<CodeBrief value={value ?? ""} />)}
      </span>
      <Drawer
        rootClassName="code-cell-drawer"
        placement="right"
        title={title}
        open={drawerVisible}
        //headerStyle={{ display: "none" }}
        onClose={() => setDrawerVisible(false)}
        closeIcon={<CloseIcon onClick={setDrawerVisible} />}
      >
        {content}
      </Drawer>
    </>
  );
};

export default CodeCell;
