import React, { ReactElement } from 'react';
import { RightSquareOutlined } from '@ant-design/icons';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const DynamicIcon = ({ type, ...rest }: { type: string; rest?: any }): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return (Component == null) ?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <RightSquareOutlined {...rest} /> : <Component {...rest} />;
};

export default DynamicIcon;
