import React, { ReactElement, useMemo } from "react";
import { FormInstance, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOptionProps, SaveRecordProps, Store, TableMetaProps } from "@props/RecordProps";
import { getDetailRenderFunction } from '@kernel/DetailComponentsMapping';
import { getColumnTransKey, shouldUseFrontendTrans } from '@utils/ColumnsUtils';
import { buildPath, EVENT_BUS } from "@utils/eventBus/EventBus";

const { TabPane } = Tabs;

export type DetailTabsProps = {
  currentDetailTab?: string;
  columns: Array<TableMetaProps>;
  switchTabCallback: (key: string) => void;
  record: SaveRecordProps | undefined;
  domainName: string;
  readonly?: boolean;
  setExpandColCallback: (expandCol: string) => void;
  expandCol: string;
  zIndex: number;
  tabBarExtraContent?: ReactElement;
  form: FormInstance;
  isCreate: boolean;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  saveOptions?: SaveOptionProps;
  parentPath?: string;
};

const DetailTabs = (props: DetailTabsProps): ReactElement => {
  const {
    domainName, record, currentDetailTab, columns,
    switchTabCallback, readonly, expandCol, parentPath,
    setExpandColCallback, zIndex, tabBarExtraContent,
    form, isCreate, onValuesChange, saveOptions,
  } = props;
  const { t } = useTranslation();
  const detailObjectsTab = useMemo(() => columns
    .filter(c => isCreate ? (c.type !== 'array') : true)
    .map((col, idx) => {
      const { key } = col;
      const Controller = getDetailRenderFunction(col);
      const isCurrentDisplayTab = (currentDetailTab === key) || (currentDetailTab == null && idx === 0);
      //const windowOperIcon = expandCol === "master" ? <CustomIcon type='icon-fullscreen1' /> : <CustomIcon type='icon-minimize1' />;
      const windowOperIcon = (<></>);
      const title = shouldUseFrontendTrans(col) ? t(getColumnTransKey(domainName, key)) : col.title;
      const colPath = parentPath ? buildPath(parentPath, key) : undefined;
      return <TabPane
        className="relate-object-tab"
        key={key}
        tab={
          <a
            href="/#"
            onClick={(event) => {
              event.preventDefault();
              switchTabCallback(key);
              if (currentDetailTab !== key) {
                setExpandColCallback('master');
              } else if (currentDetailTab === key) {
                setExpandColCallback(expandCol === 'master' ? 'master' : 'master');
              }
            }}
            title={t('ClickToChangePanelStatus', { operation: expandCol === "master" ? "expand" : "collapse" })}
          >
            {<span style={isCurrentDisplayTab ? { paddingLeft: "0.8rem" } : {}}>{title}</span>}
            {(isCurrentDisplayTab) && <span style={{ marginLeft: "10px" }}>{windowOperIcon}</span>}
          </a>
        }>
        {Controller && <Controller
          isCurrentActiveTab={isCurrentDisplayTab}
          record={record}
          column={col}
          ownerClass={domainName}
          readonly={readonly}
          zIndex={zIndex}
          form={form}
          domainName={domainName}
          onValuesChange={(changedValues, allValues) => {
            onValuesChange?.(changedValues, allValues);
            if (colPath) {
              EVENT_BUS.emit(colPath, allValues[key]);
            }
          }}
          path={colPath}
          saveOptions={saveOptions}
          fieldValue={form?.getFieldValue(key)}
        />
        }
      </TabPane>;
    }), [
    columns, currentDetailTab, domainName, expandCol, form, isCreate, record, parentPath,
    readonly, setExpandColCallback, switchTabCallback, t, zIndex, onValuesChange, saveOptions,
  ]);
  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={currentDetailTab}
        tabBarExtraContent={tabBarExtraContent}
      >
        {detailObjectsTab}
      </Tabs>
    </div>
  );
};

export default DetailTabs;
