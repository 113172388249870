import React, { Suspense } from "react";
import {
  AntdChartWidgetComponentRenderProps, DashboardWidgetComponent, WidgetComponentRenderProps
} from "../WidgetComponentsMapping";

const AntdChartWidget = (props: AntdChartWidgetComponentRenderProps): DashboardWidgetComponent => {
  const { Component, extraProps } = props;
  return (props: WidgetComponentRenderProps) => {
    const { dataAndConfigs, widget } = props;
    const config = JSON.parse(widget.options)?.config ?? {};
    //ATTENTION: the merge sequence should be extraProps, config, dataAndConfig
    //Since the settings on frontend has lowest priority
    //Then the settings on widget definition
    //Then the settings returned from widget render core logic
    const mergedConfigs = { ...extraProps, ...config, ...dataAndConfigs };
    return (
      <Suspense fallback={<div />}>
        <Component
          className="ds-simple-chart"
          {...mergedConfigs}
        />
      </Suspense>
    );
  };
};

export default AntdChartWidget;
