import React from "react";
import { Popover } from "antd";
import { FileOperator } from "./index";
import { PaperClipOutlined } from "@ant-design/icons";
import { FileOperatorProps } from "@props/RecordProps";

const FileOperatorCell: React.FC<FileOperatorProps> = (props: FileOperatorProps) => {
  return <Popover
    trigger="click"
    content={<div className='file-operator-popover'>
      <FileOperator
        {...props}
      />
    </div>}
    overlayStyle={{
      zIndex: props.zIndex + 2,
    }}
  >
    <PaperClipOutlined/>
  </Popover>;
};

export default FileOperatorCell;