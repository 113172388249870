import React, { ReactElement, useEffect, useState } from 'react';
import { FormProps, RecordProps, SaveRecordProps, TableMetaProps } from "@props/RecordProps";
import { fetchDomainMeta, fetchFormIdAndExtInfoByName, fetchFormIdAndExtInfoByType, fetchListOfRelateDomainData } from '@utils/FetchUtils';
//import { useTranslation } from 'react-i18next';
import { emptyMethod } from '@utils/Constants';
import CardSearchResultCell from '../fullTextSearchList/CardSearchResultCell';
import { LargeSpin } from '../../components';
import './objectsDetailList.less';
import { FetchRelateDomainProps } from '@utils/FetchUtilsProps';

interface ObjectsDetailListProps {
    domainName: string;
    zIndex: number;
    record: SaveRecordProps;
    column: TableMetaProps
    ownerClass: string;
}

// 以 card 形式显示的对象列表
const ObjectsDetailList = (props: ObjectsDetailListProps): ReactElement => {
    const { zIndex, record, column, ownerClass, domainName } = props;
    const { elementType, extInfo, key } = column;
    const { displayForm } = ((extInfo == null) ? { displayForm: "" } : extInfo);

    const { id } = record;
    const [data, setData] = useState<Array<RecordProps>>([]);
    const [formId, setFormId] = useState<number>();
    const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
    const [contentDisplayColumns, setContentDisplayColumns] = useState<Array<TableMetaProps>>([]);
    const [updateFormId, setUpdateFormId] = useState<number>(-1);
    //const { t } = useTranslation();

    useEffect(() => {
        if (elementType != null) {
            fetchFormIdAndExtInfoByType(elementType, 'Update')
                .then((res: FormProps) => {
                    setUpdateFormId(res.id);
                })
                .catch(e => console.error(`Failed to fetch update form Id of ${elementType}: ${e}`));
        }
    }, [elementType]);

    useEffect(() => {
        // formId == null 才请求是为了避免重复请求
        if (elementType != null && formId == null) {
            const listFormIsEmpty = (displayForm == null || displayForm === '');
            if(listFormIsEmpty) {
                fetchFormIdAndExtInfoByType(domainName, "RELATED_DETAIL_LIST").then(res => {
                    setFormId(res.id);
                });
            } else {
                fetchFormIdAndExtInfoByName(domainName, displayForm).then(res => {
                    setFormId(res.id);
                });
            }
        }
    }, [elementType, formId, displayForm, domainName]);

    useEffect(() => {
        if (formId != null && elementType != null) {
            fetchDomainMeta(domainName, formId).then((meta: Array<TableMetaProps>) => {
                setColumns(meta);
                setContentDisplayColumns(meta.filter(c => c.groupName?.startsWith('contentDisplayGroup')));
                return meta;
            });
        }
    }, [formId, elementType, domainName]);

    useEffect(() => {
        //1. Get list of related data
        const params = {
            domainName: ownerClass,
            columnName: key,
            ownerId: id,
            max: 9999,
            ownerClass,
            ownerClassColumnName: key,
            useCache: false,
            current: 1,
        };
        fetchListOfRelateDomainData(params as FetchRelateDomainProps).then((res) => {
            const { data } = res;
            if(data.length > 0) {
                const firstData = data[0];
                if('displaySequence' in firstData) {
                    data.sort((a, b) => a.displaySequence - b.displaySequence);
                }
            }
            setData(data);
        });
        //2. Get form groups
    }, [record, id, key, ownerClass]);

    const elems: Array<ReactElement> = data.map((d: RecordProps): ReactElement => {
        return (<CardSearchResultCell
            key={d.id.toString()}
            record={d}
            domainName={domainName}
            contentDisplayColumns={contentDisplayColumns}
            updateFormId={updateFormId}
            changeCallback={emptyMethod}
            columns={columns}
            zIndex={zIndex}
            layout="horizontal"
            onlyShowAbstract={false}
        />);
    });
    return (contentDisplayColumns.length === 0)? (<LargeSpin />) : (<>{elems}</>);
};

export default ObjectsDetailList;
