import React, { ReactElement } from 'react';
import { Form } from 'antd';

import { Store, TableMetaProps } from '@props/RecordProps';
import { callSaveDomainWithCallback } from '@utils/SaveDomainUtils';
import { getName, getUserId, getUsername } from '@utils/TokenUtils';
import { DataCollectForm } from '../form';
import { emptyMethod } from '@utils/Constants';
import { UserDomainName } from '@config/domain';

export interface UserProfileUpdateComponentProps {
  onFinish: (values: Store) => void;
  onFinishFailed: (values: Store) => void;
  zIndex: number;
}

const columns = [
  {
    "blank": false,
    "dataIndex": "id",
    "display": true,
    "displaySequence": -2147483648,
    "editable": false,
    "key": "id",
    "title": "id",
    "type": "integer",
  }, {
    "blank": false,
    "dataIndex": "username",
    "display": true,
    "editable": false,
    "email": true,
    "key": "username",
    "nullable": false,
    "title": "Email",
    "type": "string",
    "unique": ["tenant"],
    "updatable": false,
  }, {
    "blank": true,
    "dataIndex": "password",
    "display": true,
    "editable": true,
    "key": "password",
    "nullable": true,
    "title": "Password",
    "type": "password",
    "updatable": true,
  }, {
    "blank": true,
    "dataIndex": "name",
    "display": true,
    "editable": true,
    "key": "name",
    "nullable": true,
    "title": "Name",
    "type": "string",
    "updatable": true,
  }, {
    "backReferenceField": "",
    "dataIndex": "avatar",
    "display": true,
    "editable": true,
    "key": "avatar",
    "labelField": "description",
    "nullable": true,
    "title": "Avatar",
    "type": "image",
    "updatable": true,
  }
];

const UserProfileUpdateComponent = (props: UserProfileUpdateComponentProps): ReactElement => {
  const { zIndex, onFinish, onFinishFailed } = props;

  const [form] = Form.useForm();
  const userId = getUserId();
  if (userId == null) {
    return (<></>);
  }
  return (
    <div style={{ padding: "1rem" }}>
      <DataCollectForm
        page="user-profile-edit"
        labelAlign="left"
        onChange={emptyMethod}
        onFinishFailed={onFinishFailed}
        onFinish={() => {
          const values = form.getFieldsValue();
          callSaveDomainWithCallback({
            emptyMethod,
            values,
            continueOperate: false,
            domainName: UserDomainName,
          }).then(() => console.info(
            `Domain ${UserDomainName} has been saved with parameters ${JSON.stringify(values)}`
          ));
          onFinish(values);
        }}
        operation="update"
        columns={columns as Array<TableMetaProps>}
        domainName={UserDomainName}
        hideDetailPanel={true}
        groups={[]}
        form={form}
        readonly={false}
        hideFields={[]}
        record={{
          id: parseInt(userId),
          password: "",
          username: getUsername(),
          name: getName(),
        }}
        zIndex={zIndex}
        showBottomSaveButton={true}
      />
    </div>
  );
};

export default UserProfileUpdateComponent;
