import React, { useState } from 'react';
import { Select } from 'antd';
import * as Icons from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EditableComponentDefaultStyle } from '@config/base';
import ErrorBoundary from '../ErrorBoundary';

const { Option } = Select;

interface IconSelectProps {
  value?: unknown;
  onChange: (value: string) => void;
  readonly?: boolean;
  zIndex: number;
}

const IconSelect: React.FC<IconSelectProps> = ({ value, onChange, readonly }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const iconEntries = Object.entries(Icons);

  const filteredIcons = iconEntries
    .filter(([iconName]) => /^[A-Z]/.test(iconName))
    .filter(([iconName]) => iconName.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = (val: string): void => {
    setSearchTerm(val);
  };

  const renderOption = (iconName: string, IconComponent: React.ComponentType<unknown>): JSX.Element => (
    <Option key={iconName} value={iconName}>
      <ErrorBoundary>
        <IconComponent /> - {iconName}
      </ErrorBoundary>
    </Option>
  );

  const renderReadOnly = (): JSX.Element => {
    if (value == null) {
      return <></>;
    }
    const iconEntry = iconEntries.find(([iconName]) => iconName === value);
    if (!iconEntry) {
      return <>{value}</>;
    }

    const [, IconComponent] = iconEntry;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const elem = <IconComponent />;
    return (<>{elem} <span style={{ fontSize: "80%" }}>{value}</span></>);
  };

  if (readonly) {
    return renderReadOnly();
  }

  return (
    <Select
      showSearch
      value={value ? String(value) : undefined}
      onChange={onChange}
      onSearch={handleSearch}
      filterOption={false}
      placeholder={t('iconSelect.placeholder')}
      className="icon-select"
      style={EditableComponentDefaultStyle}
    >
      {filteredIcons.map(([iconName, IconComponent]) => renderOption(
        iconName, IconComponent as React.ComponentType<unknown>))}
    </Select>
  );
};

export default IconSelect;
