import React, { ReactElement, ReactPortal } from 'react';
import {
  Button, Input, Select, Space, Tooltip
} from 'antd';
import {
  FilterFilled, SearchOutlined, ExclamationCircleOutlined, DeleteOutlined
} from '@ant-design/icons';

import {
  ColumnSearchParameterProps,
  ColumnSearchReturnProps,
  ColumnType,
  DataApiResultProps,
  EnumMetaProps,
  EnumValues,
  MatchMode,
  RecordProps,
  SearchConditionProps,
  SearchConditions, DynamicSelectValues,
  TableMetaProps, SelectMetaProps, SearchValue
} from '@props/RecordProps';
import {
  FilterStatus, isMultiple,
  MatcherDisplay,
  NoValueModeMatcher
} from "./ServerSideSearcherConfig";
import { fetchSearchResult } from "@utils/FetchUtils";
import dayjs from "dayjs";
import { DateFormat, DateTimeFormat, SERVER_URL } from '@config/base';
import {
  isActionColumn, isDateOrDateTimeType, isDisableSearchColumn
} from '@utils/ColumnsUtils';
import {
  getAvailableMatchers, getDefaultMatcher, getSearchInputComponent
} from './ServerSideSearcherMapping';
import i18n from "@config/i18n";

type InputType = typeof Input;

export const getDefaultMatchParameters = (
  column: TableMetaProps, conditions: SearchConditions, key: string
): SearchConditionProps | undefined => {
  const { type } = column;
  const defaultMatcher = getDefaultMatcher(column);
  const defaultMatchValue = getDefaultMatchValue(type);

  if (defaultMatcher != null && defaultMatchValue != null) {
    return {
      matchMode: defaultMatcher,
      status: FilterStatus.outOfSync,
      value: defaultMatchValue,
      columnKey: key
    };
  } else if (defaultMatcher != null && NoValueModeMatcher.includes(defaultMatcher)) {
    return {
      matchMode: defaultMatcher,
      status: FilterStatus.outOfSync,
      columnKey: key
    };
  } else {
    return undefined;
  }
};

/**
 * 某个字段的搜索逻辑
 * @param props: 调用参数，具体的元素如下
 * <br> domainName 搜索的目标对象类型
 * <br> column 搜索的列
 * <br> confirm 确认回调，antd 的框架提供
 * <br> refreshSearchResult 获取到搜索结果后，刷新列表页面显示的回调函数
 */
export const handleSearch = async (
  props: {
    domainName: string;
    column: TableMetaProps;
    confirm: () => void;
    ownerClass?: string;
    ownerIds?: Array<number>;
    ownerClassColumnName?: string;
    max: number;
    current: number;
  }
): Promise<DataApiResultProps> => {
  const {
    domainName, column, confirm, ownerIds,
    ownerClass, ownerClassColumnName, max, current
  } = props;
  confirm();
  const { key, type } = column;
  const conditions = getSearchConditionsForDomain(domainName);

  if (conditions != null) {
    if (conditions[key] == null) {
      const defaultParams = getDefaultMatchParameters(column, conditions, key);
      if (null != defaultParams) {
        conditions[key] = defaultParams;
      }
    } else {
      const { value, matchMode } = conditions[key];
      const onlyMatchModeSet = (value == null && matchMode != null);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (onlyMatchModeSet && !NoValueModeMatcher.includes(matchMode)) {
        const defaultValue = getDefaultMatchValue(type);
        if (defaultValue != null) {
          conditions[key].value = defaultValue;
        }
      }
    }
    if (conditions[key] != null) {
      //Search and set the status to "applied"
      conditions[key].status = "applied";
    }
    setSearchConditions(domainName, conditions);
    return await fetchSearchResult({
      domainName, searchConditions: conditions,
      ownerClass, ownerIds, ownerClassColumnName, max, current
    });
  } else {
    return await fetchSearchResult({
      domainName, searchConditions: {},
      ownerClass, ownerIds, ownerClassColumnName, max, current
    });
  }
};

/**
 * 重置列表页面某个字段的搜索条件
 * @param props: 调用参数，具体的元素如下
 * domainName 搜索的目标对象类型
 * <br/> column 要重置搜索条件的列
 * <br/> clearFilters 重置搜索条件的 antd 回调
 * <br/> ownerClass 当前列表中显示的对象的所属对象类型
 * <br/> ownerId 当前列表中显示的对象的所属对象id
 * <br/> columnNameInOwnerClass 当前列表中显示的对象在其所属对象中的字段名称
 */
export const handleReset = async (
  props: {
    domainName: string;
    column: TableMetaProps;
    clearFilters: () => void;
    ownerClass?: string;
    ownerIds?: Array<number>;
    ownerClassColumnName?: string;
    max: number;
    current: number;
  }
): Promise<DataApiResultProps> => {
  const {
    domainName,
    column,
    clearFilters,
    ownerClass,
    ownerIds,
    ownerClassColumnName,
    max,
    current
  } = props;
  clearFilters();
  const conditions = getSearchConditionsForDomain(domainName);
  if (conditions != null) {
    delete conditions[column.key];
    setSearchConditions(domainName, conditions);
    return await fetchSearchResult({
      domainName, searchConditions: conditions, ownerClass,
      ownerIds, ownerClassColumnName, max, current
    });
  } else {
    return await fetchSearchResult({
      domainName, searchConditions: {}, ownerClass,
      ownerIds, ownerClassColumnName, max, current
    });
  }
};

const getDefaultMatchValue = (type: ColumnType): true | string | undefined => {
  if (type === "boolean") {
    return true;
  } else if (type === "datetime") {
    return dayjs().format(DateTimeFormat);
  } else if (type === "date") {
    return dayjs().format(DateFormat);
  }
  return undefined;
};

/**
 * 获取当前的匹配规则，如果当前用户设置的匹配规则为空，则获取该字段默认的匹配规则
 * @param domainName 对象的类型名称
 * @param column 要获取匹配规则的列的元数据
 */
const getCurrentMatcher = (domainName: string, column: TableMetaProps): MatchMode | undefined => {
  const { key } = column;
  const conditions = getSearchConditionsForDomain(domainName);
  if (conditions != null) {
    if (conditions[key] != null && conditions[key].matchMode != null) {
      return conditions[key].matchMode;
    }
    return getDefaultMatcher(column);
  }
  return getDefaultMatcher(column);
};

/**
 * 获取用户输入的，某列当前的搜索条件的值
 * @param domainName 对象的类型名称
 * @param columnKey 该对象的字段名
 * @param columnType 字段的列类型
 */
const getCurrentConditionValue = (
  domainName: string, columnKey: string, columnType: ColumnType
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
): SearchValue => {
  const conditions = getSearchConditionsForDomain(domainName);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const value = conditions?.[columnKey]?.value;

  return (isDateOrDateTimeType(columnType)) ?
    ((value == null || value === '') ? dayjs() : dayjs(value as string)) : value;
};

/**
 * 获取对象当前的搜索过滤条件信息
 * @param domainName 对象的类型名称
 */
export function getSearchConditionsForDomain(domainName: string): SearchConditions | undefined {
  const val = window.localStorage.getItem(getSearchConditionSaveKey(domainName));
  if (val != null && val !== "undefined" && val !== "null") {
    return JSON.parse(val);
  }
  return undefined;
}

/**
 * 删除搜索条件中, 匹配条件为空的搜索条件
 * @param conditions 待处理的搜索条件列表
 * @param columns 列的元数据
 */
export const removeObsoleteConditions = (
  conditions: SearchConditions, columns: Array<TableMetaProps>
): {
  conditions: SearchConditions; changed: boolean;
} => {
  let changed = false;
  for (const conditionKey in conditions) {
    if (!Object.prototype.hasOwnProperty.call(conditions, conditionKey)) {
      continue;
    }
    const { columnKey } = conditions[conditionKey];
    const conditionColumn = columns.find(c => c.key === columnKey);
    // ATTENTION: 要求作为搜索条件的字段定义, 必须在表单定义的字段列表中,
    //  不然该字段的搜索不会生效
    // 如果在搜索条件中存在某字段，但是在 columns 中不存在，
    // 表示后台配置错误，该用作过滤条件的字段未在对应 form 中定义
    // 此时打印警告信息
    if (columnKey != null && conditionColumn == null) {
      console.error(
        `Column ${columnKey} is in search condition but not in form fields: this column should be added to form for dynamic filter to work correctly
         Columns defined in form: ${JSON.stringify(columns, null, 2)}`
      );
    }

    if (conditionColumn == null) {
      delete conditions[conditionKey];
      changed = true;
    }
  }
  return { conditions, changed };
};

// 返回的结果分三种
//1. 如果有任何查询条件，则返回查询条件
//2. 如果当前保存的是一个空的查询条件，则返回 {}
//3. 如果当前 localStorage 中没有保存查询条件，则返回 undefined
export function getAppliedSearchConditionsForDomain(domainName: string): SearchConditions | undefined {
  const filterConditions = getSearchConditionsForDomain(domainName);
  let key: string, appliedConditions: SearchConditions | undefined = undefined;
  if (filterConditions != null) {
    appliedConditions = {};
    for (key in filterConditions) {
      if ((Object.prototype.hasOwnProperty.call(filterConditions, key))
        && filterConditions[key].status !== FilterStatus.outOfSync) {
        appliedConditions[key] = filterConditions[key];
      }
    }
  }
  return appliedConditions;
}

/**
 * Get search condition save key in local storage
 * @param domainName Domain Name
 */
export function getSearchConditionSaveKey(domainName: string): string {
  return `${domainName}_${SERVER_URL}_searchConditions`;
}

/** 获取保存当前过滤器的 id 的 key */
const getFilterIdStorageKey = (domainName: string): string => {
  return `${domainName}_${SERVER_URL}_filterId`;
};

/** 获取当前过滤器的 id */
export const getCurrentFilterId = (domainName: string): number | undefined => {
  const filterId = window.localStorage.getItem(getFilterIdStorageKey(domainName));
  if (filterId != null) {
    return parseInt(filterId);
  }
  return undefined;
};

/**
 * 将对象当前的搜索过滤信息保存到缓存中
 * @param domainName 对象的类型名称
 * @param newSearchConditions 要设置的目标搜索过滤信息
 * @param filterId 如果当前的过滤条件来自一个过滤器，则 filterId 为当前过滤器的 id
 */
export function setSearchConditions(
  domainName: string, newSearchConditions?: SearchConditions, filterId?: number
): void {
  if (newSearchConditions === undefined) {
    window.localStorage.removeItem(getSearchConditionSaveKey(domainName));
  } else {
    window.localStorage.setItem(
      getSearchConditionSaveKey(domainName), JSON.stringify(newSearchConditions)
    );
  }
  if (filterId != null) {
    window.localStorage.setItem(getFilterIdStorageKey(domainName), filterId.toString());
  } else {
    window.localStorage.removeItem(`${domainName}_${SERVER_URL}_filterId`);
  }
}

/**
 * 将对象当前的搜索过滤信息从缓存中删除
 * @param domainName 对象的类型名称
 * @param conditionKey 要删除的搜索条件的 key
 */
export function removeSearchConditions(domainName: string, conditionKey: string): void {
  const jsonObj = getSearchConditionsForDomain(domainName);
  delete jsonObj?.[conditionKey];
  setSearchConditions(domainName, jsonObj);
}

/**
 * 执行搜索操作
 * @param props 调用参数
 */
const performSearch = (
  props: {
    domainName: string;
    column: TableMetaProps;
    confirm: () => void;
    refreshSearchResult: (data: DataApiResultProps, newCurrent: number) => void;
    ownerClass?: string;
    ownerIds?: Array<number>;
    ownerClassColumnName?: string;
    max: number;
    current: number;
  }): () => Promise<void> => {
  const { refreshSearchResult } = props;
  return () => handleSearch(props).then(
    json => refreshSearchResult(json, 1)
  );
};

/**
 * 获取不同类型的字段的搜索控件
 * @param props 获取控件所需要的相关信息，包括控件渲染后控件本身需要调用的回调函数等信息
 */
const getSearchController = (props: {
  searchInputs: { [p: string]: InputType },
  column: TableMetaProps,
  setSearchInputs: (value: (((prevState: { [p: string]: InputType }) => { [p: string]: InputType }) | { [p: string]: InputType })) => void,
  domainName: string,
  confirm: () => void,
  refreshSearchResult: (data: DataApiResultProps, newCurrent: number) => void
  enumValue?: Array<EnumMetaProps>,
  dynamicSelectValue?: Array<SelectMetaProps>;
  ownerClass?: string;
  ownerIds?: Array<number>;
  ownerClassColumnName?: string;
  max: number;
  current: number;
  zIndex: number;
  matchMode?: MatchMode;
}): ReactElement => {
  const {
    searchInputs,
    column,
    setSearchInputs,
    domainName,
    confirm,
    refreshSearchResult,
    enumValue,
    dynamicSelectValue,
    ownerClass,
    ownerIds,
    ownerClassColumnName,
    max,
    current,
    zIndex,
    matchMode,
  } = props;
  const { title, key, type } = column;

  function getRef() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return node => {
      const newSearchInputs = searchInputs;
      if (node != null) {
        newSearchInputs[column.key] = node;
      }
      setSearchInputs(newSearchInputs);
    };
  }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onUpdateSearchKeyword = (value: SearchValue, valueLabels?: any): void => {
    const conditions = getSearchConditionsForDomain(domainName) ?? ({} as SearchConditions);
    //console.log("search condition when define filterDropdown: ", conditions);
    //console.log("3. searchCondition before set value: ", conditions);
    const newSearchConditions = Object.assign({}, conditions) as SearchConditions;
    if (!Object.keys(conditions).includes(key)) {
      newSearchConditions[key] = {
        value,
        columnKey: key,
        status: FilterStatus.outOfSync,
        matchMode: getDefaultMatcher(column),
        valueLabels,
      };
    } else {
      newSearchConditions[key] = {
        ...conditions[key],
        value,
        status: FilterStatus.outOfSync,
        valueLabels,
      };
    }
    setSearchConditions(domainName, newSearchConditions);
    //console.log("4. searchCondition after set value: ", newSearchConditions);
  };

  const defaultValue = getCurrentConditionValue(domainName, key, type) as string;
  //const currentMatchMode = getCurrentMatcher(domainName, column);
  //const isSingleMatch = (currentMatchMode == null)? false : SingleModeMatcher.includes(currentMatchMode);
  //const isMultipleMatch = (currentMatchMode == null)? false : MultipleModeMatcher.includes(currentMatchMode);
  //const isNoValueMatch = (currentMatchMode == null)? false : NoValueModeMatcher.includes(currentMatchMode);
  const placeholder = i18n.t("Input search condition", { title });
  const commonProps = {
    ref: getRef(),
    placeholder: placeholder,
    defaultValue: defaultValue,
    //disabled: isNoValueMatch,
  };
  const performSearchCallback = performSearch({
    domainName, column, confirm, refreshSearchResult,
    ownerClass, ownerIds, ownerClassColumnName, max, current
  });
  const renderFunc = getSearchInputComponent(column);
  return renderFunc({
    onUpdateSearchKeyword,
    commonProps,
    performSearchCallback,
    defaultValue,
    type,
    enumValue,
    dynamicSelectValue,
    ownerClass: domainName,
    fieldName: key,
    zIndex,
    column,
    matchMode,
  });
};

type SetSearchInputType =
  ((prevState: { [p: string]: InputType }) => { [p: string]: InputType })
  | { [p: string]: InputType };

export interface SetSearchPropsProps {
  columns: Array<TableMetaProps>;
  setSearchInputs: (value: SetSearchInputType) => void;
  domainName: string;
  searchInputs: {
    [p: string]: InputType;
  };
  refreshSearchResult: (data: DataApiResultProps, newCurrent: number) => void;
  enumValues: EnumValues;
  dynamicSelectValues: DynamicSelectValues;
  ownerClass?: string;
  ownerIds?: Array<number>;
  ownerClassColumnName?: string;
  max: number;
  current: number;
  zIndex: number;
}

export const setSearchProps = (searchProps: SetSearchPropsProps): void => {
  const {
    columns, searchInputs, domainName, setSearchInputs, refreshSearchResult,
    enumValues, ownerClass, ownerIds, ownerClassColumnName, max, current, zIndex,
    dynamicSelectValues
  } = searchProps;
  columns
    .filter(column => !isActionColumn(column.key))
    .filter(column => !isDisableSearchColumn(domainName, column))
    .forEach(column => {
      const searchProps = getColumnSearchProps({
        domainName, column, setSearchInputs, searchInputs,
        enumValue: enumValues[column.key],
        dynamicSelectValue: dynamicSelectValues[column.key],
        refreshSearchResult, ownerClass, ownerIds,
        ownerClassColumnName, max, current, zIndex
      });
      for (const p in searchProps) {
        if (Object.prototype.hasOwnProperty.call(searchProps, p)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          column[p] = searchProps[p];
        }
      }
    });
};

export const SearchComponent: React.FC<ColumnSearchParameterProps & FilterDropdownProps> = (props: ColumnSearchParameterProps & FilterDropdownProps) => {
  const {
    column, searchInputs, domainName, setSearchInputs, refreshSearchResult,
    enumValue, ownerClass, ownerIds, ownerClassColumnName,
    max, current, zIndex, dynamicSelectValue, confirm, clearFilters
  } = props;
  const { key } = column;

  const defaultMatchMode = getCurrentMatcher(domainName, column);
  const [matchMode, setMatchMode] = React.useState<MatchMode | undefined>(defaultMatchMode);

  const searchController = getSearchController({
    searchInputs,
    column,
    setSearchInputs,
    domainName,
    confirm,
    refreshSearchResult,
    enumValue,
    dynamicSelectValue,
    ownerIds,
    ownerClass,
    ownerClassColumnName,
    max,
    current,
    zIndex,
    matchMode,
  });
  const availableMatcher = getAvailableMatchers(column);
  const options = availableMatcher?.map(matcher => {
    return <Select.Option
      value={matcher}
      key={matcher}>
      {i18n.t(`matchMode:${MatcherDisplay[matcher]}`) as ReactPortal}
    </Select.Option>;
  });

  const searchMatcher = <Select
    className="search-input search-matcher"
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onSelect={(value: MatchMode) => {
      setMatchMode(value);
      const conditions = getSearchConditionsForDomain(domainName);
      if (conditions != null) {
        //console.log("1. matcher: before search conditions: ", conditions);
        const newSearchConditions = Object.assign({}, conditions) as SearchConditions;
        const condExistsForCurrentColumn = Object.keys(conditions).includes(key);
        if (!condExistsForCurrentColumn) {
          newSearchConditions[key] = {
            matchMode: value,
            columnKey: key,
            status: FilterStatus.outOfSync,
          };
        } else {
          const previousCondition = conditions[key];
          newSearchConditions[key] = {
            ...conditions[key],
            matchMode: value,
            status: FilterStatus.outOfSync
          };
          if (Array.isArray(previousCondition.value)
            && previousCondition.matchMode
            && isMultiple(previousCondition.matchMode)
            && !isMultiple(value)) {
            newSearchConditions[key].value = previousCondition.value[0];
          }
          if (NoValueModeMatcher.includes(value)) {
            newSearchConditions[key].value = undefined;
          }
        }
        //console.log("2. matcher: after search conditions to be set: ", newSearchConditions);
        setSearchConditions(domainName, newSearchConditions);
      } else {
        if (NoValueModeMatcher.includes(value)) {
          setSearchConditions(domainName, {
            [key]: {
              matchMode: value,
              columnKey: key,
              status: FilterStatus.outOfSync,
              value: undefined,
            }
          });
          // console.log("3. matcher: after search conditions to be set: ", {
          //   [key]: {
          //     matchMode: value,
          //     columnKey: key,
          //     status: FilterStatus.outOfSync,
          //     value: undefined,
          //   }
          // });
        }
      }
    }}
    defaultValue={defaultMatchMode}
  >
    {options}
  </Select>;
  return (<div style={{ padding: 18 }}>
    <Space className="search-space">
      <Button
        type="primary"
        onClick={performSearch({
          domainName,
          column,
          confirm,
          refreshSearchResult,
          ownerClass,
          ownerIds,
          ownerClassColumnName,
          max,
          current,
        })}
        icon={<SearchOutlined />}
        size="small"
        className="search-button-s"
      >
        {i18n.t('Search')}
      </Button>
      <Button
        onClick={() => handleReset({
          domainName,
          column,
          clearFilters,
          ownerIds,
          ownerClass,
          ownerClassColumnName,
          max,
          current
        }).then(
          json => refreshSearchResult(json, 1)
        )}
        size="small"
        className="search-button-c"
        icon={<DeleteOutlined />}
      >
        {i18n.t('Remove')}
      </Button>
    </Space>
    {searchMatcher}
    {matchMode && !NoValueModeMatcher.includes(matchMode) && searchController}
  </div>);
};

export interface FilterDropdownProps {
  setSelectedKeys: (value: Array<string>) => void;
  selectedKeys: Array<string>;
  confirm: () => void;
  clearFilters: () => void;
}

export function getColumnSearchProps(searchProps: ColumnSearchParameterProps): ColumnSearchReturnProps {
  const { column, domainName } = searchProps;
  return {
    filterDropdown: (filterProps: FilterDropdownProps): ReactElement => {
      const props = { ...filterProps, ...searchProps };
      return <SearchComponent {...props}/>;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filterIcon: (filtered: boolean): ReactElement => {
      const conditions = getSearchConditionsForDomain(domainName);
      const condition = conditions?.[column.key];
      if (condition != null && condition.status === "applied") {
        return (
          <Tooltip title={i18n.t("Data shown below has been filtered by this column")}>
            <FilterFilled
              className="search-icon"
            />
          </Tooltip>
        );
      }
      if (condition != null && condition.status === FilterStatus.outOfSync) {
        return (
          <Tooltip
            title={i18n.t("Filter condition has been changed but data shown below has not been filter by it.")}>
            <ExclamationCircleOutlined
              className="search-icon"
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            title={i18n.t("Click to filter data by this column")}
          >
            <SearchOutlined />
          </Tooltip>
        );
      }
    },
    onFilter: (value: string | number | boolean, record: RecordProps): boolean | undefined => {
      return record[column.key] ? record[column.key].toString().toLowerCase().includes(value.toString().toLowerCase()) : '';
    },
    onFilterDropdownVisibleChange: (visible: boolean): void => {
      const { searchInputs, column } = searchProps;
      if (visible) {
        setTimeout(() => {
          const searchInput = searchInputs[column.key];
          try {
            searchInput.select();
          } catch (ignore) {
            //Ignore the error
          }
        }, 100);
      }
    }
  };
}
