import { Select, Tag } from 'antd';
import React, { CSSProperties, ReactElement, } from 'react';
import { EnumMetaProps, SaveRecordProps } from '@props/RecordProps';
import { getReadOnlyClass } from '@utils/ComponentUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SuffixIcon } from '../../components';

export type StaticFieldProps = {
  style?: CSSProperties | undefined;
  defaultValue?: string;
  value: string;
  onChange: (val: string) => void;
  record: SaveRecordProps | undefined;
  options?: Array<EnumMetaProps>;
  updatable?: boolean;
}

/**
 * A static field chooser, used to choose fields associate to an domain class
 * Current usage: select target/source static field when creating dynamic logic
*/
const StaticFieldSelect = (props: StaticFieldProps): ReactElement => {
  const { updatable, onChange, style, value, options } = props;
  const disabled = (updatable == null) ? false : !updatable;
  const optionsElem = (options == null) ? undefined :
    options?.filter(opt => opt.value !== value)
      .map(opt => (
        <Select.Option
          key={opt.value}
          value={opt.value}
        >
          {opt.label}
        </Select.Option>
      ));
  const currentValueElem = (value == null) ? undefined :
    options?.filter(opt => opt.value === value)
      .map(opt => (
        <Select.Option
          key={opt.value}
          value={opt.value}
        >
          {opt.label}
        </Select.Option>
      ));
  return (
    <Select
      mode="tags"
      onChange={value => onChange(value[0])}
      style={style}
      value={value}
      showArrow={true}
      showSearch={true}
      disabled={disabled}
      notFoundContent={
        <div style={{ textAlign: 'center', lineHeight: '180%' }}>
          <InfoCircleOutlined style={{ fontSize: '32px' }} /><br />
          <span>
            For transient field, please input the field name directly<br />
            For static and dynamic field, Please set value for <Tag className="object-id-tag">Form</Tag>, <Tag className="object-id-tag">Object Type</Tag> or <Tag className="object-id-tag">Apply Class</Tag>
            and <Tag className="object-id-tag">Field Type</Tag> first, then list of fields will be shown here accordingly
          </span>
        </div>
      }
      className={`staticField ${getReadOnlyClass(updatable)}`}
      suffixIcon={<SuffixIcon updatable={updatable} />}
      dropdownStyle={{ zIndex: 2000 }}
    >
      {currentValueElem}
      {optionsElem}
    </Select>
  );
};

export default StaticFieldSelect;
