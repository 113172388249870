import React, { ReactElement, useState } from 'react';
import { Menu } from 'antd';
import { Link, } from 'react-router-dom';
import { FileAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DynamicIcon } from "../components";
import { openInCurrentTab, openInNewTab } from '@utils/BrowserUtils';
import { DynamicMenuProps, formRouterConfig, RouterConfig } from '@kernel/RoutersConfig';

const { SubMenu } = Menu;
const { Item: MenuItem } = Menu;

const AppNav = (props: {
  menus: Array<DynamicMenuProps>;
  mode: 'horizontal' | 'vertical' | 'inline' | undefined;
  inlineCollapsed: boolean;
}): ReactElement => {

  const { menus, mode, inlineCollapsed } = props;
  const { t } = useTranslation();
  const [current, setCurrent] = useState<string>("main");

  const getIcon = (menu: DynamicMenuProps): ReactElement => {
    return menu.icon ? <DynamicIcon type={menu.icon} /> : <FileAddOutlined />;
  };

  const getMenuItem = (menu: DynamicMenuProps, linkDestination: string, level: number): ReactElement => {
    return (
      <MenuItem
        className="hover-style"
        key={`${menu?.id}_${menu?.type.toLowerCase()}`}
        icon={level >= 2 ? undefined :
          <span className={`menu-icon-level-${level}`}>{getIcon(menu)}</span>}
        title={t(`menu:${menu.label}`)}
      >
        <Link to={linkDestination}>{t(`menu:${menu.label}`)}</Link>
      </MenuItem>
    );
  };

  const renderLink = (menu: DynamicMenuProps, target: string, level: number): ReactElement => {
    return (
      <MenuItem
        className="hover-style"
        key={`${menu.id}_${menu?.type.toLowerCase()}`}
        onClick={() => {
          if (menu.link != null) {
            if (target === '_blank') {
              openInNewTab(menu.link);
            } else {
              openInCurrentTab(menu.link);
            }
          }
        }}
        icon={level >= 2 ? undefined :
          <span className={`menu-icon-level-${level}`}>{getIcon(menu)}</span>}
      >
        {t(`menu:${menu.label}`)}
      </MenuItem>
    );
  };

  function renderMenuItems(menus: Array<DynamicMenuProps>, level: number): ReactElement[] {
    const result: ReactElement[] = [];
    menus.forEach(menu => {
      let subMenu: ReactElement | null;
      if (menu.type === "EXTERNAL_LINK") {
        subMenu = renderLink(menu, '_blank', level);
      } else if (menu.type === "INTERNAL_LINK") {
        subMenu = renderLink(menu, '_self', level);
      } else if (menu.type === "MENU_GROUP") {
        const { children, label } = menu;
        const childrenMenus = renderMenuItems(children ?? [], level + 1);
        subMenu = (
          <SubMenu
            key={`${menu.id}_menu_group`}
            title={t(`menu:${label}`)}
            icon={<span className={`menu-icon-level-${level}`}>{getIcon(menu)}</span>}
          >
            {childrenMenus}
          </SubMenu>);
      } else {
        const formType = menu.form?.type;
        const configs: RouterConfig[] = formRouterConfig.filter(config => config?.formType === formType);
        const dest = (configs?.length <= 0 || menu.form == null) ? "" :
          (configs[0])?.getMenuLink?.(menu.form) ?? "";
        subMenu = getMenuItem(menu, dest, level);
      }
      if (subMenu != null) {
        result.push(subMenu);
      }
    });

    return result;
  }

  return (
    <div className="app-nav-container">
      <Menu
        triggerSubMenuAction="hover"
        onClick={(e) => {
          setCurrent(e.key.toString());
        }}
        defaultSelectedKeys={['main']}
        selectedKeys={[current]}
        mode={mode}
        inlineCollapsed={inlineCollapsed}
      >
        {renderMenuItems(menus, 0)}
      </Menu>
    </div>
  );
};

export default AppNav;
