import React, { ReactElement, useState } from 'react';
import { CellComponentDisplayPage, RecordProps, TableMetaProps, TableMode } from "@props/RecordProps";
import { FormInstance, Space } from "antd";
import GroupedGrandChildGroupTitle from "./GroupedGrandChildGroupTitle";
import Operations from "../../form/list/Operations";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { CreateAction } from "../../form";
import { SisternodeOutlined } from "@ant-design/icons";

export interface GroupedGrandChildGroupExtraOperationsProps {
  ownerId?: number,
  ownerClass?: string,
}

export interface GroupedGrandChildGroupExtraCreateChildProps {
  columnNameInCurrentDomainClass?: string,
  childrenDomainName: string;
}

export interface GroupedGrandChildGroupExtraProps {

  domainName: string;
  zIndex: number;
  page: CellComponentDisplayPage,
  tableMode?: TableMode,
  record: RecordProps;
  reload: () => void;
  columns: Array<TableMetaProps>;
  displayColumns: Array<TableMetaProps>;
  form: FormInstance,
  switchTabCallback?: (key: string) => void,
  isHighlightField?: boolean,
  operationsProps?: GroupedGrandChildGroupExtraOperationsProps,
  createChildProps?: GroupedGrandChildGroupExtraCreateChildProps,
  showOperations?: boolean,
}

const GroupedGrandChildGroupExtra = (props: GroupedGrandChildGroupExtraProps): ReactElement => {
  const {
    record, operationsProps, domainName, reload, zIndex, createChildProps,
    showOperations
  } = props;
  const { ownerId, ownerClass } = operationsProps || {};
  const { columnNameInCurrentDomainClass, childrenDomainName } = createChildProps || {};
  const [showAddChildModal, setShowAddChildModal] = useState<boolean>(false);

  return (<Space onClick={stopPropagationAndPreventDefault}>
    <GroupedGrandChildGroupTitle {...props} />
    {showOperations && <Operations
      /* useModal={tableMode === "detail"} */
      id={record.id}
      ownerId={ownerId}
      ownerClass={ownerClass}
      domainName={domainName}
      deleteCallback={reload}
      updateCallback={reload}
      zIndex={zIndex + 2}
      page={"list"}
      showActions={false}
    />}
    {(createChildProps && showOperations)
      ? <a href="/#" onClick={stopPropagationAndPreventDefault}>
        <SisternodeOutlined
          onClick={(e) => {
            stopPropagationAndPreventDefault(e);
            setShowAddChildModal(true);
          }}
        />
        {showAddChildModal && childrenDomainName ? <CreateAction
          domainName={childrenDomainName}
          ownerId={record.id}
          ownerClass={domainName}
          columnNameInOwnerClass={columnNameInCurrentDomainClass}
          callback={() => {
            reload();
            setShowAddChildModal(false);
          }}
          initialCanCreate={true}
          initShowCreateModal={true}
          zIndex={zIndex + 2}
          showIconAndText={false}
          onCancelCallback={() => {
            setShowAddChildModal(false);
          }}
        /> : <></>}
      </a>
      : <></>}
  </Space>
  );
};

export default GroupedGrandChildGroupExtra;
