import { getAccessToken } from "./TokenUtils";
import { AttachmentOperation, AttachmentProps } from "@props/RecordProps";
import { fetchConfig } from "@utils/hooks/useConfig";
import { v4 as uuid } from "uuid";
import { WEBSOCKET_SERVER_URL } from "@config/base";

const DEFAULT_HEARTBEAT_INTERVAL_IN_SEC = 20;

let heartbeatIntervalInSec = DEFAULT_HEARTBEAT_INTERVAL_IN_SEC;

// 这里无法直接用 useConfig hook，因为 useConfig 依赖 react，需要在 react component 中调用
fetchConfig("websocket.heartbeatInterval", "int")
  .then((config) => {
    console.log(`websocket.heartbeatInterval: ${config.value}`);
    if (config.value) {
      heartbeatIntervalInSec = config.value as number;
    }
  })
  .catch((error) => {
    console.error(`Failed to fetch config for websocket.heartbeatInterval: ${error}`);
  });

export interface AttachmentOperationProps extends AttachmentProps {
  operation: AttachmentOperation; /* 操作类型 */
}

/* eslint-disable @typescript-eslint/ban-types */
export interface SocketInterface {
  on: (fn: Function) => void;
  replaceOn: (fn: Function) => void;
  off: (fn: Function) => void;
  onStateChange: (fn: Function) => void;
  close: () => void;
  getClient: () => void;
  isConnected: () => boolean;
  send: (content: string) => void;
  sendFile: (props: AttachmentProps, file: File) => void;
  numberOfMessageListeners: () => number;
}

export interface NetworkConnectedEvent {
  type: 'connected';
}

export interface NetworkReconnectedEvent {
  type: 'reconnected';
}

export interface NetworkClosedEvent {
  type: 'closed';
}

export interface NetworkUpstreamMessage<P> {
  msgId?: string;
  topic: string;
  payload: P;
}

export interface NetworkDownstreamMessage<P> {
  msgId?: string;
  topic: string;
  payload: P;
}

export type NetworkEvent = NetworkConnectedEvent | NetworkReconnectedEvent | NetworkClosedEvent;

export type NetworkEventListener = (event: NetworkEvent) => void;

export type NetworkMessageListener<P> = (payload: P) => void;

export type UnregisterNetworkEventListener = () => void;

export class NetworkService {

  private readonly url: string;
  private readonly eventListeners: Array<NetworkEventListener> = [];
  private readonly msgListeners: Record<string, Array<NetworkMessageListener<unknown>>> = {};
  private readonly pendingMessagesResolver: Record<string, (payload: unknown) => void> = {};
  private client?: WebSocket;
  private reconnectOnClose = true;

  constructor(url: string) {
    this.url = url;
  }

  public readonly init = (): Promise<WebSocket> => {
    if (this.client) {
      return Promise.resolve(this.client);
    }

    return new Promise((resolve) => {
      const client = new WebSocket(`${this.url}?access_token=${getAccessToken()}`);

      client.onopen = () => {
        this.eventListeners.forEach(l => l({ type: 'connected' }));
        this.client = client;
        // heartbeat
        const heartbeatTimeout = setInterval(async () => {
          if (client.readyState === client.CLOSED) {
            clearInterval(heartbeatTimeout);
            return;
          }
          await this.send('heartbeat', 'ping');
        }, heartbeatIntervalInSec * 1000);
        resolve(client);
      };

      client.onmessage = (event: MessageEvent<string>) => {
        const msg = JSON.parse(event.data) as NetworkDownstreamMessage<unknown>;
        if (msg.msgId) {
          this.pendingMessagesResolver[msg.msgId]?.(msg.payload);
        }
        this.msgListeners[msg.topic]?.forEach(l => l(msg.payload));
      };

      client.onerror = (e) => {
        console.error(e);
      };

      client.onclose = () => {
        this.client = undefined;
        this.eventListeners.forEach(l => l({ type: 'closed' }));
        if (this.reconnectOnClose) {
          setTimeout(() => {
            this.init().then(() => {
              this.eventListeners.forEach(l => l({ type: 'reconnected' }));
            });
          }, 1000);
        }
      };
    });
  };

  public readonly close = (): void => {
    this.reconnectOnClose = false;
    this.client?.close();
  };

  public readonly onEvent = (listener: NetworkEventListener): UnregisterNetworkEventListener => {
    this.eventListeners.push(listener);
    return () => {
      this.eventListeners.filter(l => l !== listener);
    };
  };

  public readonly subscribe = <T>(topic: string, listener: NetworkMessageListener<T>): UnregisterNetworkEventListener => {
    if (!this.msgListeners[topic]) {
      this.msgListeners[topic] = [];
    }
    this.msgListeners[topic].push(listener as NetworkMessageListener<unknown>);
    return () => {
      this.msgListeners[topic].filter(l => l !== listener);
    };
  };

  public readonly send = <P, R>(topic: string, payload: P, timeout?: number): Promise<R> => {
    const msgId = uuid();
    const upstreamMsg: NetworkUpstreamMessage<P> = {
      msgId,
      topic,
      payload
    };
    return new Promise<R>((resolve, reject) => {
      let timeoutId: NodeJS.Timeout | undefined = undefined;
      if (timeout) {
        timeoutId = setTimeout(() => {
          reject(new Error(`Timeout after ${timeout} ms`));
        }, timeout);
      }
      this.init().then((client) => {
        client.send(JSON.stringify(upstreamMsg));
        this.pendingMessagesResolver[msgId] = (payload: unknown) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          resolve(payload as R);
        };
      });
    });
  };

  public readonly sendOneWay = <P>(msgType: string, payload: P): void => {
    const upstreamMsg: NetworkUpstreamMessage<P> = {
      msgId: uuid(),
      topic: msgType,
      payload
    };
    this.init().then((client) => {
      client.send(JSON.stringify(upstreamMsg));
    });
  };

}

let networkService: NetworkService | undefined = undefined;

export const getNetworkService = (): NetworkService => {
  if (!networkService) {
    networkService = new NetworkService(`${WEBSOCKET_SERVER_URL}/websocket/route`);
  }
  return networkService;
};

const ReconnectableSocket = (url: string): SocketInterface => {
  let client: WebSocket;
  let isConnected = false;
  let reconnectOnClose = true;

  let messageListeners: Array<Function> = [];
  let stateChangeListeners: Array<Function> = [];

  const on = (fn: Function): void => {
    messageListeners.push(fn);
  };

  const replaceOn = (fn: Function): void => {
    messageListeners = [fn];
  };

  const send = (content: string): void => {
    client.send(content);
  };

  /**
   * 通过 Websocket 发送文件到后台
   * 先发送文件的元数据，包括文件名，文件大小等
   * 然后发送文件的内容
   */
  const sendFile = (props: AttachmentProps, file: File): void => {
    const {
      id, uid, ownerId, ownerClass, columnNameInOwnerClass, multiple
    } = props;
    const fileMetaData = {
      lastModified: file.lastModified,
      name: file.name,
      type: file.type,
      size: file.size,
      uid: uid,
      ownerId: ownerId ?? undefined,
      ownerClass: ownerClass,
      id: id ?? undefined,
      columnNameInOwnerClass,
      operation: "upload",
      multiple
    };
    client.send(JSON.stringify(fileMetaData));
    client.send(file);
  };

  const off = (fn: Function): void => {
    messageListeners = messageListeners.filter(l => l !== fn);
  };

  const onStateChange = (fn: Function): void => {
    stateChangeListeners = [fn];
  };

  const start = (): void => {
    client = new WebSocket(`${url}?access_token=${getAccessToken()}`);

    client.onopen = () => {
      isConnected = true;
      stateChangeListeners.forEach(fn => fn(true));
    };

    const { close } = client;

    // heartbeat
    const heartbeat = (): void => {
      setTimeout(() => {
        if (client.readyState === client.CLOSED) {
          return;
        }
        send("ping");
        heartbeat();
      }, heartbeatIntervalInSec * 1000);
    };

    heartbeat();

    // Close without reconnecting;
    client.close = () => {
      reconnectOnClose = false;
      messageListeners = [];
      stateChangeListeners = [];
      close.call(client);
    };

    client.onmessage = (event) => {
      if (event.data === "pong") {
        return;
      }
      messageListeners.forEach(fn => fn(event.data));
    };

    client.onerror = (e) => {
      console.error(e);
    };

    client.onclose = () => {
      isConnected = false;
      stateChangeListeners.forEach(fn => fn(false));

      if (!reconnectOnClose) {
        return;
      }

      setTimeout(start, 1000 * 10);
    };
  };

  start();

  return {
    on,
    replaceOn,
    off,
    send,
    sendFile,
    onStateChange,
    close: () => client.close(),
    getClient: () => client,
    isConnected: () => isConnected,
    numberOfMessageListeners: () => messageListeners.length,
  };
};

export default ReconnectableSocket;
