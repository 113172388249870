import { SERVER_URL } from "@config/base";

export type Scene = 'register' | 'resetPassword';

export interface SendVerificationCodeForm {
  email: string;
}

export interface RegisterOrResetPasswordForm {
  email?: string;
  name?: string;
  password?: string;
  confirmedPassword?: string;
  verificationCode?: string;
  invitationCode?: string;
}

export const sendVerificationCode = async (scene: Scene, requestId: string, form: SendVerificationCodeForm):
  Promise<void> => {
  const response = await fetch(`${SERVER_URL}/api/account/sendVerificationCode`, {
    method: 'POST',
    body: JSON.stringify({
      requestId,
      scene,
      ...form,
    }),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
  if (response.ok) {
    return;
  } else {
    let info;
    try {
      const payload = await response.json();
      info = payload.message as { message: string; };
    } catch {
      info = 'Send verification code failed';
    }
    console.error("Send verification code failed, response: ", info);
    throw new Error(info as string);
  }
};

export const registerOrResetPassword = async (scene: Scene, requestId: string, form: RegisterOrResetPasswordForm):
  Promise<void> => {
  const response = await fetch(`${SERVER_URL}/api/account/${scene}`, {
    method: 'POST',
    body: JSON.stringify({
      requestId,
      ...form,
    }),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
  if (response.ok) {
    return;
  } else {
    let info;
    try {
      const payload = await response.json();
      info = payload.message as { message: string; };
    } catch {
      info = `${scene} failed`;
    }
    console.error(`${scene} failed, response: ${info}`);
    throw new Error(info as string);
  }
};

