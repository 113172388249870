import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SERVER_URL } from '@config/base';
import { getAccessToken } from '@utils/TokenUtils';
import i18next from 'i18next';

const i18n = i18next.use(Backend).use(LanguageDetector).use(initReactI18next);

const buildAuthorizationHeader = (): {
  [propName: string]: string;
} => {
  const accessToken = getAccessToken();
  return {
    'Authorization': "Bearer " + (accessToken == null ? "" : accessToken),
    'Content-Type': 'application/json'
  };
};

export const LanguageLocalStorageKey = `${SERVER_URL}_language`;

const defaultLang = localStorage.getItem(LanguageLocalStorageKey) || 'en';

export const initializeI18n: () => Promise<void> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise<void>((resolve, reject) => {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        backend: {
          loadPath: (lng, ns) => `${SERVER_URL}/translations/${ns}/${lng}`,
          // You can also add a custom fetch function if your API requires special headers or parameters
          customHeaders: buildAuthorizationHeader(),
          requestOptions: { /* Your custom options here if required */ },
          parse: (data) => JSON.parse(data),
          crossDomain: true,
        },
        ns: [],
        defaultNS: 'common',
        lng: defaultLang,
        fallbackLng: false,
        detection: {
          // order and from where user language should be detected
          order: ['localStorage', 'navigator'],
          // keys or params to lookup language from
          lookupLocalStorage: `${SERVER_URL}_language`,
        },
        react: {
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ['br', 'span', 'div', 'strong', 'p', 'i', 'ul', 'li', 'ol'],
          useSuspense: false,
        },
        debug: false,

        interpolation: {
          escapeValue: false,
        }
      }).then(() => {
        fetch(`${SERVER_URL}/translations/namespaces`, {
          headers: buildAuthorizationHeader(),
        }).then(response => response.json())
          .then(namespaces => {
            i18n.options.ns = namespaces;
            i18n.reloadResources();
            console.info('i18n initialized');
            resolve();
          })
          .catch(error => {
            console.error('Error fetching namespaces:', error);
            // Resolve here to make sure UI always renders
            resolve();
          });
      });
  });
};

export default i18n;
