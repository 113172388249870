import { SortedRecordProps } from "@props/RecordProps";

const STEP = 10;

export const updateDisplaySequence = <T extends SortedRecordProps>(data: T[], newIndex: number): [T[], T[]] => {
  const changedElements: T[] = [];
  const newData = data.slice();
  if (data.length < 2) {
    return [newData, changedElements];
  }
  const current = newData[newIndex];
  // this function will re calculate all element's displaySequence to satisfy the sequence.
  const reCalculate = (): void => {
    let previousDisplaySequence = -1;
    for (let i = 0; i < newData.length; ++i) {
      if (previousDisplaySequence >= newData[i].displaySequence) {
        newData[i].displaySequence = previousDisplaySequence + STEP;
        changedElements.push(newData[i]);
      }
      previousDisplaySequence = newData[i].displaySequence;
    }
  };
  if (newIndex === newData.length - 1) {
    // if the target is the last element
    if (current.displaySequence > newData[newIndex - 1].displaySequence) {
      return [newData, changedElements];
    }
    current.displaySequence = newData[newIndex - 1].displaySequence + 10;
    changedElements.push(current);

  } else if (newIndex === 0) {
    // if the target is the first element
    if (current.displaySequence < newData[newIndex + 1].displaySequence) {
      return [newData, changedElements];
    }
    current.displaySequence = newData[newIndex + 1].displaySequence - 10;
    if (current.displaySequence < 0) {
      reCalculate();
    } else {
      changedElements.push(current);
    }
  } else {
    const prev = newData[newIndex - 1];
    const next = newData[newIndex + 1];
    if (prev.displaySequence < current.displaySequence && current.displaySequence < next.displaySequence) {
      return [newData, changedElements];
    }
    const mid = Math.floor((next.displaySequence + prev.displaySequence) / 2);
    if (mid > prev.displaySequence) {
      current.displaySequence = mid;
      changedElements.push(current);
    } else {
      reCalculate();
    }
  }
  return [newData, changedElements];
};
