import React, { createContext, ReactNode, ReactElement, useCallback, useRef } from 'react';

/**
 * Interface for the context value
 */
interface ModalLockContextType {
  acquireLock: () => number;
  releaseLock: (id: number) => void;
  hasLock: (id: number) => boolean;
}

/**
 * Create the context with default values
 */
const ModalLockContext = createContext<ModalLockContextType>({
  acquireLock: () => 0,
  releaseLock: () => {/* No action need */ },
  hasLock: () => false,
});

/**
 * Interface for the provider props
 */
interface ModalLockProviderProps {
  children: ReactNode; // React children elements
}

/**
 * The provider component for the modal lock context
 * @param {ModalLockProviderProps} props - The props for the provider component
 * @returns {ReactElement} The provider element
 */
export function ModalLockProvider({ children }: ModalLockProviderProps): ReactElement {
  const lockIdStackRef = useRef<number[]>([]);
  const nextLockIdRef = useRef(1);

  const acquireLock = useCallback(() => {
    const newLockId = nextLockIdRef.current;
    nextLockIdRef.current += 1;
    lockIdStackRef.current.push(newLockId);
    return newLockId;
  }, []);

  const releaseLock = useCallback((id: number) => {
    const lockIdStack = lockIdStackRef.current;
    if (lockIdStack.length > 0 && lockIdStack[lockIdStack.length - 1] === id) {
      lockIdStack.pop();
    } else {
      console.error(`Attempted to release a non-top lock: ${id}`);
    }
  }, []);

  const hasLock = useCallback((id: number) => {
    const lockIdStack = lockIdStackRef.current;
    return lockIdStack.length > 0 && lockIdStack[lockIdStack.length - 1] === id;
  }, []);

  return (
    <ModalLockContext.Provider
      value={{
        acquireLock,
        releaseLock,
        hasLock,
      }}
    >
      {children}
    </ModalLockContext.Provider>
  );
}

export default ModalLockContext;
