import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { DataApiResultProps, RecordProps, TableMetaProps } from "@props/RecordProps";
import CommentForm from "./CommentForm";
import { fetchDomainMeta, fetchListOfRelateDomainData, fetchSearchResult } from "@utils/FetchUtils";
import CommentsList from "./CommentsList";
import { DomainComment } from './CommentProps';
import { openErrorNotification } from '@utils/NotificationUtils';
import { LargeSpin } from '../../components';
import { PredefinedFilters } from '../../form';
import { getSearchConditionsForDomain } from '@kernel/ServerSideSearcher';
import { CommentDomainName, CommentsColumnNameInOwnerClass } from '.';

export interface CommentPanelProps {
  zIndex: number;
  domainName: string;
  record: RecordProps;
  column: TableMetaProps;
  fetchDataCallback?: () => void;
  objectTypeId: number;
}

const CommentsPanel = (props: CommentPanelProps): ReactElement => {
  const {
    zIndex, record, domainName, column, objectTypeId
  } = props;

  const [comments, setComments] = useState<DomainComment[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [columns, setColumns] = useState<TableMetaProps[]>([]);
  const highlightCommentId = ("answerComment" in record) ? record.answerComment?.id : undefined;

  const refreshData = useCallback((): void => {
    setLoading(true);
    const searchConditions = getSearchConditionsForDomain(CommentDomainName);
    const noSearchConditions = (searchConditions == null) || (Object.keys(searchConditions).length === 0);
    const result: Promise<DataApiResultProps> = noSearchConditions ? fetchListOfRelateDomainData({
      domainName,
      ownerId: record.id,
      columnName: column.key,
      current: 1,
      max: 9999,
      useCache: false
    }) : fetchSearchResult({
      domainName: CommentDomainName,
      searchConditions,
      current: 1,
      max: 9999,
      ownerIds: [record.id],
      ownerClass: domainName,
      columnNameInOwnerClass: CommentsColumnNameInOwnerClass,
    });
    result.then((result: DataApiResultProps) => {
      setComments(result.data as DomainComment[]);
    }).finally(() => {
      setLoading(false);
    }).catch((error) => {
      console.error("Failed to load comments for record: " + record.id, error);
      openErrorNotification("Failed to load comments for record: " + record.id);
    });
  }, [column.key, domainName, record.id]);

  const updateComment = useCallback((comment: DomainComment): void => {
    const newComments = comments.map((item: DomainComment) => {
      if (item.id === comment.id) {
        return comment;
      }
      return item;
    });
    setComments(newComments);
  }, [comments]);

  useEffect(() => {
    refreshData();
  }, [domainName, record.id, column.key, refreshData]);

  useEffect(() => {
    fetchDomainMeta(CommentDomainName, -1).then((result) => {
      setColumns(result);
    }).catch((error) => {
      console.error("Failed to load domain meta for domain: " + domainName, error);
      openErrorNotification("Failed to load domain meta for domain: " + domainName);
    });
  }, [domainName]);

  return (
    <>
      <CommentForm
        column={column}
        zIndex={zIndex}
        mainDomainName={domainName}
        owner={record}
        fetchDataCallback={refreshData}
        objectTypeId={objectTypeId}
      />
      <span className="simple-list-filters">
        <PredefinedFilters
          domainName={"DomainComment"}
          fetchDataCallback={refreshData}
          displayStyle='inline'
          columns={columns}
          zIndex={zIndex}
        />
      </span>
      <div style={{ clear: "both" }} />
      {!loading && <CommentsList
        comments={comments}
        updateCallback={updateComment}
        fetchDataCallback={refreshData}
        owner={record}
        column={column}
        mainDomainName={domainName}
        zIndex={zIndex}
        objectTypeId={objectTypeId}
        highlightCommentId={highlightCommentId}
      />}
      {loading && <LargeSpin />}
    </>
  );
};

export default CommentsPanel;
