import { Alert } from "antd";
import React, { ReactElement } from "react";
import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";

const MarkdownWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs, widget } = props;
  return (
    <Alert
      message="Failed to render widget"
      description={`No dashboard widget defined for widget(${JSON.stringify({ widget })}), data(${JSON.stringify(dataAndConfigs)})`}
      type="error"
      showIcon
    />
  );
};

export default MarkdownWidget;
