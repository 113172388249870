import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Collapse, Form } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { fullDomainNameToHumanReadable } from '@utils/StringUtils';
import { Rule } from 'antd/lib/form';
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';

import { LargeSpin, DetailCollapse } from "../components";
import { constraintsToRulesMap } from "@kernel/ConstraintMapping";
import { getEditableController, getValuePropName } from "@kernel/EditableComponentsMapping";
import { getDisplayRenderFunction, isOwnerColumn } from "@kernel/DisplayComponentsMapping";
import { refreshColumnMeta, refreshDynamicColumnMeta } from "@utils/FetchUtils";
import {
  ConstraintsToRulesMapParams,
  DataCollectFormRenderProps,
  EnumMetaProps,
  FieldValidationRuleName,
  GroupedFormFields,
  GroupMetaProps,
  MapOfEnumMetaProps,
  OperationPage,
  RecordProps,
  SaveRecordProps,
  Store,
  TableMetaProps
} from "@props/RecordProps";
import { getFieldTitle, isMultipleFileColumn } from '@utils/ComponentUtils';
import { isFieldNotReadonlyBasedOnGroupMeta, panels } from '@utils/FieldGroupUtils';
import { hasDetailPanel, readOnlyFieldValue, hideInDrawerMode } from '@utils/ColumnsUtils';
import { MasterDetailLayout } from '../layout';
import { emptyMethod } from '@utils/Constants';
import { ListOperations, DataCollectFormMasterPanel, DetailTabs } from './';
import { RefreshColumnMetaResultProps } from '@utils/FetchUtilsProps';
import { MasterCollapseWidth, MasterExpandWidth } from '@config/base';
import { REFRESH_ALL_DYNAMIC_FIELDS } from "../props/RecordPropsConstrants";
import { DataCollectFormHideFieldClassName } from "@config/ui";
import { buildPath, EVENT_BUS } from "@utils/eventBus/EventBus";

function refreshAllDynamicColumns(
  setColumns: ((value: (((prevState: TableMetaProps[]) => TableMetaProps[]) | TableMetaProps[])) => void) | undefined,
  domainName: string,
  record: SaveRecordProps,
  key: string,
  columns: Array<TableMetaProps>,
): void {
  if (!setColumns) {
    console.error("setColumns is not defined for dynamic column");
    return;
  }
  refreshDynamicColumnMeta({
    domainName,
    currentObjValue: record,
    sourceFieldName: key,
    sourceFieldValue: record[key],
  }).then(json => {
    if (json.dynamicFields == null) {
      return;
    }
    const dynamicFieldsRecord: Record<string, TableMetaProps> = {};
    json.dynamicFields.forEach((field: TableMetaProps) => {
      dynamicFieldsRecord[field.key] = field;
    });
    columns.forEach(col => delete dynamicFieldsRecord[col.key]);
    if (Object.keys(dynamicFieldsRecord).length == 0) {
      return;
    }
    setColumns((cols) =>
      [...cols.filter((col) => !col.isDynamicTemplateAttributeField), ...json.dynamicFields]
        .sort((a, b) => a.displaySequence ?? 0 - (b.displaySequence ?? 0)));
  });
}

const shouldDisplay = (
  display: boolean | undefined,
  isRefreshCol: boolean,
  refDisplay: "hide" | "show" | "readonly" | undefined,
  hideDetailPanel: boolean | undefined,
  col: TableMetaProps,
  displayMode: "detail-drawer" | undefined,
  operationPage: OperationPage,
  hasDetailColumns: TableMetaProps[],
): boolean => {
  // 默认显示 display 不为 false 的记录
  if (display === false) {
    return false;
  }
  // 如果后台传递了 display 属性到前台 (hide 或者 show),
  // 则需要对应的隐藏或者显示对应字段
  const shouldHideThis = ((isRefreshCol && refDisplay === "hide")
    // 动态属性在 drawer panel 中需要被渲染出来
    || (hideDetailPanel && hasDetailPanel(col) && !(col.type === 'entityAttributes'))) && hideInDrawerMode(col, displayMode)
    || hasDetailColumns.find((c) => c.key === col.key);
  const shouldDisplayThis = (isRefreshCol && refDisplay === "show");
  // 默认根据是否在 detail panel 中显示来判断是否隐藏
  // 当前只对 subTable 类型的字段启用，如果是 wizard 和 action 且字段类型为 subTable, 则显示
  if (["wizard", "action"].includes(operationPage) && col.type === "subTable") {
    return true;
  }
  if (col.type === "subTable") {
    return false;
  }
  /** 在编辑和创建页面，隐藏 code, json 和 array 的字段 */
  if ((operationPage === "create" || operationPage === "edit")
    && (col.type === "code" || col.type === "json" || col.type === "array")) {
    return false;
  }
  if (shouldHideThis) {
    col.hide = true;
  } else if (shouldDisplayThis) {
    col.hide = false;
  }
  // 如果某字段之前字段是隐藏的， 且本次刷新表单没有涉及该字段，那么继续隐藏
  const previousHideOnes = (col.hide != null && col.hide);
  return (!shouldHideThis && !previousHideOnes);
};

// 如果 ownerId 和 ownerClass 不为空, 表示创建或者编辑关联对象
const DataCollectForm = (props: DataCollectFormRenderProps): ReactElement => {
  const {
    columns, setColumns, domainName, onFinish, onFinishFailed, readonly,
    form, record, hideFields, operation, formType,
    ownerClass, ownerId, onChange, validationCallback, groups,
    hideDetailPanel, zIndex, labelAlign, hideHeaderTitle,
    deleteCallback, updateCallback, hideHeader, displayMode,
    showBottomSaveButton, defaultColumnOptions, page: operationPage, saveOptions,
    setCancelConfirmMessage, path, columnNameInOwnerClass,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isUpdate = (operation === "update");
  const isUpdateAndDataReady = (isUpdate && record != null);
  const isCreate = (operation === "create");
  const displayDomainName = fullDomainNameToHumanReadable(domainName);
  const pageOper = isCreate ? 'Creat new' : (readonly ? '' : 'Update');
  const idDisplay = (record == null || record?.id == null) ? "" : record.id;
  const subTitle = `${pageOper} ${displayDomainName} ${idDisplay}`;
  const [expandCol, setExpandCol] = useState<string>("master");
  const [currentDetailTab, setCurrentDetailTab] = useState<string>();
  const [formFields, setFormFields] = useState<GroupedFormFields | Array<ReactElement>>([]);
  const [formFieldsInitiated, setFormFieldsInitiated] = useState<boolean>(true);
  const hasGroup = (groups != null && groups.length > 0);
  const hideGroups = (groups.filter(g => g.displayMode === 'hide'));
  const hideHeaderState = hideHeader ?? true;

  /**
   * 按照字段组的可见性判断，是否某个字段应该隐藏
   * @param col Column Meta
   * @param hasGroup Whether current form has group
   * @returns Whether the column should be hide in UI
   */
  function hideBasedOnColumnGroup(col: TableMetaProps, hasGroup: boolean): boolean {
    if (!hasGroup) {
      return false;
    }
    const isInHiddenGroupList = hideGroups.find(g => g.id === col.group) != null;
    const isNotInGroupList = groups.find(g => g.id === col.group) == null;
    const hideGroup = (isInHiddenGroupList || isNotInGroupList);
    return hideGroup;
  }

  /** 已经根据 decides 从后台刷新过的字段 */
  const [initColumnMetaRefreshed, setInitColumnMetaRefreshed] = useState<Array<string>>([]);
  const [refreshedFieldOptions, setRefreshedFieldOptions] = useState<Array<MapOfEnumMetaProps>>(defaultColumnOptions ?? []);
  const toDisplayColumns = columns.filter(col => !(hideFields?.includes(col.key)));
  // 创建页面不显示 array 关联字段， 是否有右边的面板取决于是否有列 hasDetailPanel 且该列不应该被隐藏
  let hasDetailColumns: TableMetaProps[];
  if (displayMode === "detail-drawer") {
    hasDetailColumns = [];
  } else {
    hasDetailColumns = toDisplayColumns
      .filter(col => (isCreate && (col.type !== 'array')) || !isCreate)
      .filter(col => (hasDetailPanel(col) && !hideBasedOnColumnGroup(col, hasGroup)));
  }

  /** 当前展开显示的字段组 */
  const [activeGroups, setActiveGroups] = useState<Array<GroupMetaProps>>(groups);

  function pushValidateRule(
    ruleParameters: ConstraintsToRulesMapParams,
    rules: Rule[],
    ruleName: FieldValidationRuleName,
    value?: number
  ): void {
    ruleParameters.constraintKey = ruleName;
    ruleParameters.constraintValue = value;
    const items = constraintsToRulesMap(ruleParameters);
    if (items != null) {
      rules.push(items);
    }
  }

  // 将从后台刷新过来的字段选项保存在 refreshedFieldOptions 中,以字段的名称为 key
  // 这是为了再次刷新的时候, 如果后台没有传递 options 到前台, 可以从保存的 refreshedFieldOptions
  // 中恢复之前刷新的 Options
  const saveRefreshedOptionToState = useCallback((json: RefreshColumnMetaResultProps, columnKey: string) => {
    if (null != json.options) {
      const existingOption = refreshedFieldOptions.find(obj => obj.fieldName === columnKey);
      if (existingOption == null) {
        refreshedFieldOptions.push({
          fieldName: columnKey,
          options: json.options
        });
      } else {
        existingOption.options = json.options;
      }
      setRefreshedFieldOptions(refreshedFieldOptions);
    }
  }, [refreshedFieldOptions]);

  const switchTab = (key: string): void => {
    setCurrentDetailTab(key);
    setFormFields(prepareFormFields({}, key));
  };

  /**
   * 根据元数据准备表单，
   * 如果是表单渲染后，因为字段的修改而触发的更新，则需要传递 refreshInfo 参数，
   * 如果是表单渲染后，因为点击了包含在右边显示的字段，则需要传递 currentHighlightField 参数，
   * 该参数为 {字段: RefreshColumnMetaResultProps} 的格式，
   * 本方法根据该参数，决定需要刷新哪些字段，
   * 字段刷新的信息保存在以该字段的 key 为 key 的元素中，格式为 RefreshColumnMetaResultProps
   */
  const prepareFormFields = useCallback((
    refreshInfo: { [p: string]: RefreshColumnMetaResultProps },
    currentHighlightField?: string
  ): GroupedFormFields | Array<ReactElement> => {
    const toRefreshFieldName = Object.keys(refreshInfo)[0];
    const toRefreshFieldMeta = refreshInfo[toRefreshFieldName];
    // Meta refreshed from backend by decides logic
    // Should take higher priority than default metas
    const {
      options: refOptions = undefined,
      display: refDisplay = undefined,
      value: refValue = undefined,
      required: refRequired = undefined,
    } = (toRefreshFieldMeta == null) ? {} : toRefreshFieldMeta;
    const refreshedOptions = (toRefreshFieldMeta == null || refOptions == null) ?
      undefined : refOptions;
    const className = (hasDetailColumns.length > 0) ? "has-detail-form-start" : "no-detail-form-start";
    const newFormFields = hasGroup ? {} as GroupedFormFields :
      [(<div className={`non-group-form-start ${className}`} key="n-g-e-f-s" />)] as Array<ReactElement>;
    // Don't display array fields on create page
    const createColumns = (isCreate) ?
      toDisplayColumns.filter(c => (c.type !== 'array') || isMultipleFileColumn(c)) : toDisplayColumns;

    createColumns
      //.filter(col => !hasDetailColumns.includes(col))
      .map(col => {
        // 如果后台级联刷新的时候传递了 required 属性到前台
        // 则在本处刷新对应 column 的 nullable 数据, 用来控制字段是否必填
        const isRefreshCol = (col.key === toRefreshFieldName);
        const refreshToRequired = (isRefreshCol && refRequired === true);
        const refreshToNotRequired = (isRefreshCol && refRequired === false);
        if (refreshToRequired) {
          col.nullable = false;
        } else if (refreshToNotRequired) {
          col.nullable = true;
        }
        return col;
      }).forEach(col => {
        const {
          type, title, helpText, unique, key, nullable, editable,
          defaultValue, updatable = true, decides, group, min, max,
          validate, multiple, email
        } = col;
        if (type == null) {
          return (<div key={`${key}_domainTypeEmpty`}>{t('Field type is empty')}</div>);
        }
        const isRefreshCol = (key === toRefreshFieldName);
        const refreshToReadonly = (isRefreshCol && refDisplay === 'readonly');
        const valuePropName = getValuePropName(type);
        const columnPath = path ? buildPath(path, key) : undefined;
        const fieldTitle = getFieldTitle({ domainName, title, helpText, key, unique, email });
        const fv = (record == null) ? defaultValue : record[key];
        const value = (
          toRefreshFieldMeta != null
          && refValue !== undefined
          && toRefreshFieldName === key
          && type != 'entityAttributes'
        ) ? refValue : fv;

        const display = shouldDisplay(col.display, isRefreshCol, refDisplay, hideDetailPanel, col, displayMode,
          operationPage, hasDetailColumns);

        const isOwnerCol = isOwnerColumn(ownerClass, columnNameInOwnerClass, ownerId, col);
        const fieldValue = isOwnerCol ? ownerId : value;
        const fieldRenderFunction = getEditableController(type);
        const formGroupNotReadonly = isFieldNotReadonlyBasedOnGroupMeta(group, groups);
        // 字段可以更新的几种情况:
        // 1. 后台传递的 updatable 为 true
        // 2. updatable 为 false, 但是当前显示的是创建表单(isCreate)
        // 3. 非 ownerCol, ownerCol 在表单中为只读状态，
        //    因为是在创建与该列关联的关联对象
        // 4. 非在编辑时候通过字段客制化刷新为只读(refreshToReadonly),
        // 5. 且 formGroup 为非只读状态
        const canChangeValue = ((updatable && editable) || (!updatable && isCreate))
          && !isOwnerCol
          && !refreshToReadonly
          && formGroupNotReadonly
          && !col.transient;
        const readOnlyField = (<div> {readOnlyFieldValue(col, fieldValue)} </div>);
        // 选择字段:因为后台不一定会传递刷新的字段选项过来,因此如果后台没有传递 options 过来
        // 则从 refreshedFieldOptions 中使用字段的名称查询保存的选项列表并作为参数
        // 初始化字段显示
        const saveOption = refreshedFieldOptions.find(obj => obj.fieldName === col.key);
        const options = (toRefreshFieldName === col.key && refreshedOptions != null) ?
          refreshedOptions : saveOption?.options as Array<EnumMetaProps>;
        // 因为后台不一定会传递 required 属性过来,因此如果后台没有传递 required 属性过来, 则从
        // refreshedFieldRequired 中使用字段的名称查询保存的 required 并作为默认的 required 参数
        const hf = currentHighlightField ?? hasDetailColumns[0]?.key;
        const isHighlightField = (hf === key);
        const page = (formType == "INLINE_DISPLAY") ? "INLINE_DISPLAY" : "DISPLAY";
        let controller;
        if (hasDetailColumns.find((c) => c.key === col.key)) {
          controller = (<></>);
        } else if (readonly || !canChangeValue) {
          const readOnlyRenderFunc = getDisplayRenderFunction({
            column: col,
            enumValues: {},
            objectValues: {},
            domainName,
            page,
            zIndex,
            form,
            switchTabCallback: switchTab,
            isHighlightField,
            tableMode: displayMode,
          });
          controller = (readOnlyRenderFunc == null) ?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            readOnlyField : (<>{readOnlyRenderFunc?.(fieldValue, { ...record })}</>);
        } else {
          controller = (fieldRenderFunction == null) ? readOnlyField : fieldRenderFunction({
            updatable: canChangeValue,
            type, fieldTitle: title, form, key,
            fieldValue, options, domainName, record,
            onValuesChange: (changedValues, allValues) => {
              onValuesChange(changedValues, allValues);
              if (columnPath) {
                EVENT_BUS.emit(columnPath, allValues[key]);
              }
            }, switchTabCallback: switchTab,
            isHighlightField, zIndex, multiple: multiple ?? false,
            column: col, saveOptions, path: columnPath,
          });
        }
        const rules: Rule[] = [];
        const ruleParameters: ConstraintsToRulesMapParams = {
          fieldTitle, domainName, record, column: col, form,
          constraintKey: "",
          callback: (isValid: boolean) => {
            if (validationCallback != null) {
              validationCallback(isValid);
            }
          }
        };
        const notNullable = (!nullable && editable);
        if (display) {
          if (validate) {
            pushValidateRule(ruleParameters, rules, "validate");
            if (notNullable) {
              ruleParameters.nullableCheckBy = "validate";
            }
          }
          if (unique) {
            pushValidateRule(ruleParameters, rules, "unique");
            if (notNullable) {
              ruleParameters.nullableCheckBy = "unique";
            }
          }
          if (email) {
            pushValidateRule(ruleParameters, rules, "email");
            if (notNullable) {
              ruleParameters.nullableCheckBy = "email";
            }
          }
          if (notNullable) {
            pushValidateRule(ruleParameters, rules, "nullable");
          }
          if (max) {
            pushValidateRule(ruleParameters, rules, "max", max);
          }
          if (min) {
            pushValidateRule(ruleParameters, rules, "min", min);
          }
        }

        // Refresh the field list and update options, values, display options
        // etc based on decides meta data when first render the form
        function initFieldMetaBasedOnDecides(): void {
          // Only refresh the meta if is a update form(record != null)
          if (decides != null && record != null) {
            if (decides === REFRESH_ALL_DYNAMIC_FIELDS) {
              refreshAllDynamicColumns(setColumns, domainName, record, key, columns);
            } else if (decides.length > 0) {
              decides.forEach((toBeDecideCol: string) => {
                refreshColumnMeta({
                  domainName,
                  currentObjValue: record,
                  sourceFieldName: key,
                  sourceFieldValue: record[key],
                  destFieldName: toBeDecideCol,
                  destFieldValue: record[toBeDecideCol]
                }).then(json => {
                  saveRefreshedOptionToState(json, toBeDecideCol);
                  const refreshedFormFields = prepareFormFields({ [toBeDecideCol]: json });
                  setFormFields(refreshedFormFields);
                  setFormFieldsInitiated(true);
                  if ((json.value !== undefined
                    && (fieldValue === undefined || isCreate))) {
                    // ATTENTION:
                    // We only refresh the field value if one of follow two conditions meet
                    // 1. This is a create form
                    // 2. There's no value(value is undefined) on target field currently
                    // Will not set when it's null(intentional set to null by backend)
                    // There's no unit test right now
                    form.setFieldsValue({ [toBeDecideCol]: json.value });
                  }
                  // Save field meta refreshed, to avoid infinity loop
                  initColumnMetaRefreshed.push(key);
                  setInitColumnMetaRefreshed(initColumnMetaRefreshed);
                });
              });
            }
          }
        }

        const alreadyRefreshedFormForCurrentField = initColumnMetaRefreshed.includes(key);
        const isOperateRelatedObject = (ownerId != null && ownerClass != null);
        // 以下几种情况,页面刚开始 render 的时候, 初始刷新字段的元数据
        // 1. 如果是更新表单,且数据已经从后台获取到
        // 2. 如果是操作关联对象
        // 3. 如果是创建表单,且字段有默认值存在
        if ((isUpdateAndDataReady || isOperateRelatedObject ||
          (isCreate && defaultValue != null))
          && !alreadyRefreshedFormForCurrentField) {
          initFieldMetaBasedOnDecides();
        }

        const displayCssClassName = display ? "" : DataCollectFormHideFieldClassName;

        const elem = (
          <Form.Item
            label={fieldTitle}
            name={key}
            key={key}
            rules={rules}
            valuePropName={valuePropName}
            className={`form-field-type-${type.toLowerCase()} ${isHighlightField ? "highlight-field" : `form-item-${key}`} ${displayCssClassName}`}
          >
            {controller}
          </Form.Item>);
        if (hasGroup) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const currGroup = (newFormFields[group] == null) ?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ([] as Array<ReactElement>) : newFormFields[group];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          currGroup.push(elem);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          newFormFields[group] = currGroup;
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          newFormFields.push(elem);
        }
      });
    return newFormFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    domainName, form, isCreate, operation, ownerClass, ownerId, record,
    toDisplayColumns, groups, initColumnMetaRefreshed, isUpdateAndDataReady,
    // readonly 模式变化的时候，也要重新生成 formFields
    refreshedFieldOptions, saveRefreshedOptionToState, readonly, displayMode,
    formType, hasDetailColumns, hasGroup, hideDetailPanel, zIndex, path, columnNameInOwnerClass
  ]);

  // 如果数据已经更新，那么刷新表单中的显示
  useEffect(() => {
    setFormFields(prepareFormFields({}));
    // Attention: if prepareFormFields is also put to deps list, then infinitely loop happens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  // First init of the form, without data
  useEffect(() => {
    const initFormFields = prepareFormFields({});

    setFormFields(initFormFields);
    setFormFieldsInitiated(true);
    // Attention: if prepareFormFields is also put to deps list, then infinitely loop happens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, columns]);

  // Init form again after all data has been ready
  useEffect(() => {
    if (formFieldsInitiated && (isUpdateAndDataReady || isCreate)) {
      const initFormFields = prepareFormFields({});
      setFormFields(initFormFields);
    }
    // Attention: if prepareFormFields is also put to deps list, then infinitely loop happens
    // Attention: need to refresh the form list when readonly prop changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldsInitiated, isCreate, isUpdateAndDataReady, readonly]);

  if (!(isUpdateAndDataReady || isCreate)) {
    return (<LargeSpin />);
  }

  function onValuesChange(changedValues: Store, latestValues: Store): void {
    setCancelConfirmMessage?.("Discard changes?");
    /**
     * 如果后台传递过来的最新的 Option 中不包含字段的当前值，
     * 那么将字段的当前值设置为空，
     * 避免出现非法的值
     */
    const removeInvalidValueBasedOnNewOptions = (
      toBeDecideCol: string, json: RefreshColumnMetaResultProps
    ): void => {
      const value = form.getFieldValue(toBeDecideCol);
      if (json.options != null) {
        const stillInOptionsValue = json.options?.find(
          opt => opt.value === value);
        if (stillInOptionsValue == null) {
          latestValues[toBeDecideCol] = undefined;
          form.setFieldsValue(latestValues);
        }
      }
    };

    const changedFields = Object.keys(changedValues);
    if (changedFields.length === 0) {
      return;
    }
    onChange(latestValues as RecordProps);
    const changedField = changedFields[0];
    const sourceColumnNewValue = changedValues[changedField];
    const filteredColMetas = columns.filter(col => col.key === changedField);
    if (filteredColMetas.length > 0) {
      const changedColMeta = filteredColMetas[0];
      const toBeDecideCols = changedColMeta.decides;
      if (toBeDecideCols != null) {
        if (toBeDecideCols === REFRESH_ALL_DYNAMIC_FIELDS) {
          refreshAllDynamicColumns(setColumns, domainName, latestValues, changedField, columns);
          if (!setColumns) {
            console.error("setColumns is not defined for dynamic column");
            return;
          }
          refreshDynamicColumnMeta({
            domainName,
            currentObjValue: latestValues,
            sourceFieldName: changedField,
            sourceFieldValue: sourceColumnNewValue,
          }).then(json => setColumns((cols) =>
            [...cols.filter((col) => !col.isDynamicTemplateAttributeField), ...json.dynamicFields]
          ));
        } else if (toBeDecideCols.length > 0) {
          toBeDecideCols.forEach((toBeDecideCol: string) => {
            const destColumnNewValue = latestValues[toBeDecideCol];
            refreshColumnMeta({
              domainName,
              currentObjValue: latestValues,
              sourceFieldName: changedField,
              sourceFieldValue: sourceColumnNewValue,
              destFieldName: toBeDecideCol,
              destFieldValue: destColumnNewValue
            }).then(json => {
              saveRefreshedOptionToState(json, toBeDecideCol);
              const refreshedFormFields = prepareFormFields({ [toBeDecideCol]: json });
              setFormFields(refreshedFormFields);
              const needToSetFieldValue = (json.value !== undefined);
              if (needToSetFieldValue) {
                latestValues[toBeDecideCol] = json.value;
                form.setFieldsValue(latestValues);
              }
              removeInvalidValueBasedOnNewOptions(toBeDecideCol, json);
            });
          });
        }
      }
    }

  }

  const formFieldElems =
    (hasGroup && !Array.isArray(formFields)) ?
      (<div className={`form-group-collapse form-group-collapse-${domainName}`}>
        <Collapse
          bordered={false}
          defaultActiveKey={groups.map(g => g.id)}
          expandIcon={() => <></>}
          className="site-collapse-custom-collapse"
          activeKey={activeGroups.map(g => g.id)}
        >
          {panels(domainName, formFields, groups, activeGroups, setActiveGroups)}
        </Collapse>
      </div>
      ) : formFields;

  const rightVisible = (hasDetailColumns.length > 0) && !hideDetailPanel;

  const expandedMasterPanel = (
    <DataCollectFormMasterPanel
      domainName={domainName}
      form={form}
      labelAlign={labelAlign}
      initialValues={record}
      onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      formFieldElems={formFieldElems}
      readonly={readonly}
      //Disable expand detail panel as of now (2023-02-15)
      collapseMasterCallback={() => setExpandCol("detail")}
      collapseGroupsCallback={() => setActiveGroups([])}
      expandGroupsCallback={() => setActiveGroups(groups)}
      hasGroup={hasGroup}
      hasDetail={hasDetailColumns?.length > 0 && !hideDetailPanel}
      formType={formType}
      showBottomSaveButton={showBottomSaveButton}
      page={operationPage}
    />);

  const collapsedMasterPanel = (<DetailCollapse domainName={displayDomainName ?? ""} />);

  const detailTabs = hideDetailPanel ? (<></>) : (
    <DetailTabs
      isCreate={isCreate}
      columns={hasDetailColumns}
      currentDetailTab={currentDetailTab}
      switchTabCallback={switchTab}
      setExpandColCallback={setExpandCol}
      expandCol={expandCol}
      domainName={domainName}
      record={record}
      readonly={readonly}
      zIndex={zIndex}
      tabBarExtraContent={undefined}
      form={form}
      onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
      saveOptions={saveOptions}
      parentPath={path}
    />
  );

  const flex = (operationPage !== 'master-detail') ? "0 0 100%" :
    (((hasDetailColumns.length === 0) || hideDetailPanel) ? "100%" :
      `${(expandCol === 'master') ? MasterExpandWidth : MasterCollapseWidth + 10}px`);
  // const flex = "0 0 100%";

  const leftContent = (expandCol === 'master') ? expandedMasterPanel : collapsedMasterPanel;

  const detailOrEditPage = readonly ? "detail" : "edit";
  const className = readonly ? (hideDetailPanel ? `readonly-popover-form` : `readonly-form`) : '';
  return (
    <div
      className={className}
      // 禁止在表单显示区域点击鼠标调用父组件中的 onClick 事件
      onClick={(e) => e.stopPropagation()}
    >
      {!hideHeaderState && readonly && <PageHeader
        className="site-page-header"
        onBack={() => navigate(`/${domainName}/list`)}
        title={hideHeaderTitle ? undefined : displayDomainName}
        subTitle={hideHeaderTitle ? undefined : subTitle}
        backIcon={hideHeaderTitle ? false : undefined}
        extra={(record?.id != null &&
          <ListOperations
            showActions={true}
            page={detailOrEditPage}
            domainName={domainName}
            id={record?.id}
            ownerClass={undefined}
            ownerId={undefined}
            deleteCallback={deleteCallback ?? emptyMethod}
            updateCallback={updateCallback ?? emptyMethod}
            zIndex={zIndex + 1}
          />
        )}
      />
      }
      <MasterDetailLayout
        flex={flex}
        leftOnClick={() => setExpandCol("master")}
        leftContent={leftContent}
        leftClassName={expandCol}
        rightClassName={expandCol}
        rightVisible={rightVisible}
        rightContent={detailTabs}
      />
    </div>
  );
};

export default DataCollectForm;
