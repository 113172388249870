// DisplayStyleDropdown.tsx
import React from "react";
import { Button, Dropdown, Menu, Space, Switch } from "antd";
import {
  ReloadOutlined, TableOutlined, AppstoreOutlined, CaretDownOutlined
} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { FullScreenToggle } from "../components";
import { TableMode } from "@props/RecordProps";
import {stopPropagationAndPreventDefault} from "@utils/ObjectUtils";

type DisplayStyleDropdownProps = {
  setTableMode?: (mode: TableMode) => void;
  tableMode: TableMode;
  fetchDataCallback: (force?: boolean) => void;
  refreshMode: string;
  setRefreshMode: (mode: string) => void;
};

const DisplayStyleDropdown: React.FC<DisplayStyleDropdownProps> = ({
  setTableMode,
  tableMode,
  fetchDataCallback,
  refreshMode,
  setRefreshMode,
}) => {
  const { t } = useTranslation();
  const isMasterTableMode = (tableMode === "table-list");

  const handleMenuClick = (key: string): void => {
    if (key === "refreshData") {
      fetchDataCallback(true);
    } else if (key === "toggleTableMode") {
      setTableMode?.(isMasterTableMode ? "card-list" : "table-list");
    }
  };

  const refreshTableElement = (
    <Menu.Item key="refreshData" icon={<ReloadOutlined />}>
      {t("Refresh data")}
    </Menu.Item>
  );

  const toggleTableModeElement = (
    <Menu.Item key="toggleTableMode" icon={isMasterTableMode ? <AppstoreOutlined /> : <TableOutlined />}>
      {isMasterTableMode ? t("Switch to card list") : t("Switch to table list")}
    </Menu.Item>
  );

  const switchRefreshModeIconTitle: string = (refreshMode === 'realtime') ?
    '当前数据刷新模式: 自动刷新，点击切换到手动刷新数据' : '当前数据刷新模式: 手动刷新，点击切换到自动刷新数据';

  const realTimeRefreshToggle = (refreshMode != null && refreshMode !== 'disable') && (
    <Menu.Item key="realTimeRefreshToggle">
      <Space size="small">
        <Switch
          checkedChildren=""
          unCheckedChildren=""
          title={switchRefreshModeIconTitle}
          checked={refreshMode === 'realtime'}
          onChange={(checked: boolean) => {
            const destMode = checked ? 'realtime' : 'manual';
            setRefreshMode(destMode);
          }}
          onClick={(checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
          }}
          size={"small"}
        />
        <span onClick={(e: React.MouseEvent<unknown>) => {
          stopPropagationAndPreventDefault(e);
          if (refreshMode === 'realtime') {
            setRefreshMode("manual");
          } else {
            setRefreshMode("realtime");
          }
        }}>
          {refreshMode === "realtime" ? "实时刷" : "手动刷"}
        </span>
      </Space>
    </Menu.Item>
  );

  const menuItems = [
    realTimeRefreshToggle,
    refreshTableElement,
    <FullScreenToggle key="fullScreenToggle" />,
    toggleTableModeElement,
  ];

  const dropdownMenu = <Menu onClick={({ key }) => handleMenuClick(key)}>{menuItems}</Menu>;

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={["hover"]}
    >
      <Button>
        <Space size={"small"}>
          {isMasterTableMode ? <TableOutlined /> : <AppstoreOutlined />}
          {t("Display settings")}
          <CaretDownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DisplayStyleDropdown;
