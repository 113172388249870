import React, { ReactElement, useEffect, useState } from 'react';
import {
  PlusCircleOutlined, CheckCircleOutlined, MinusCircleOutlined,
  PauseCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import ObjectPopup from './ObjectPopup';
import { fetchCurrentValue, fetchFormIdAndExtInfoByType } from '@utils/FetchUtils';
import { emptyMethod } from '@utils/Constants';

export type IdsCellValueProps = {
  insertedIds: string;
  updatedIds: string;
  deletedIds: string;
  noUpdateIds: string;
  conflictIds: string;
  dbUpdatedCsvNoUpdateIds: string;
  failedIds: string;
};

export type IdsCellProps = {
  value: IdsCellValueProps;
  domainClassId: number;
  zIndex: number;
}

const IdsCell = (props: IdsCellProps): ReactElement => {

  const { value, domainClassId, zIndex } = props;
  const { t } = useTranslation();

  const [domainName, setDomainName] = useState<string>("");
  const [formId, setFormId] = useState<number>(-1);

  useEffect(() => {
    fetchCurrentValue("DomainClass", domainClassId).then(json => {
      setDomainName(json.shortName);
      fetchFormIdAndExtInfoByType(json.shortName, "Update").then(formInfo => {
        setFormId(formInfo?.id);
      });
    }).catch(e => {
      console.error(`Failed to get current value for DomainClass ${domainClassId}: ${e}`);
    });
  }, [domainClassId]);

  const {
    insertedIds, updatedIds, deletedIds, noUpdateIds, conflictIds,
    dbUpdatedCsvNoUpdateIds, failedIds
  } = (value == null) ? {
    insertedIds: "",
    updatedIds: "",
    deletedIds: "",
    noUpdateIds: "",
    conflictIds: "",
    dbUpdatedCsvNoUpdateIds: "",
    failedIds: ""
  } : value;

  const render = (
    title: string, content: string, Icon: typeof PlusCircleOutlined, color: string
  ): ReactElement => {
    const idsArray = content?.split(',');
    const tags = idsArray?.map(id =>
      <ObjectPopup
        domainName={domainName}
        id={id}
        formId={formId}
        style={{ marginBottom: "0.2rem", marginTop: "0.2rem" }}
        key={id}
        zIndex={zIndex + 1}
      />
    );
    return content?.length > 0 ? (
      <Popover
        overlayStyle={{ zIndex: zIndex + 1 }}
        onOpenChange={emptyMethod}
        title={`${title}(${t("click id to open object detail page")})`}
        content={
          <div className='ids-cell-display'>
            {tags}
          </div>
        }
        trigger="click"
      >
        <Tag style={{
          color,
          border: `1px solid ${color}`
        }}
          className="action-tag ids-tag"
          title={t("Click to show list of ids")}
        >
          <Icon /> {idsArray?.length}
        </Tag>
      </Popover>) : <></>;
  };

  return (<div className="update-ids-cell">
    {render(t("Conflict objects"), conflictIds, ExclamationCircleOutlined, "#FFA500")}
    {render(t("Inserted objects"), insertedIds, PlusCircleOutlined, "green")}
    {render(t("DB updated CSV no update objects"), dbUpdatedCsvNoUpdateIds, PauseCircleOutlined, "blue")}
    {render(t("Updated objects"), updatedIds, CheckCircleOutlined, "green")}
    {render(t("Deleted objects"), deletedIds, MinusCircleOutlined, "yellow")}
    {render(t("No update objects"), noUpdateIds, PauseCircleOutlined, "grey")}
    {render(t("Failed objects"), failedIds, ExclamationCircleOutlined, "red")}
  </div>
  );
};

export default IdsCell;
