import { MatchMode } from "@props/RecordProps";

/**
 * 匹配规则与后台通信时的 value 及前台显示给用户的显示值的对应关系
 */
export const MatcherDisplay: { [key in MatchMode]: string } = {
  "=": "Equal to",
  "!=": "Not equal to",
  ">": "Greater than",
  "<": "Smaller than",
  ">=": "Greater than or equal",
  "<=": "Smaller than or equal",
  "endsWith": "Ends with",
  "startsWith": "Starts with",
  "contains": "Contains",
  "notContains": "not Contains",
  "containsAll": "Contains all of",
  "containsAny": "Contains some of ",
  "notContainsAny": "Contains none of",
  "between": "between",
  "before": "Before",
  "after": "After",
  "beforeOrEqual": "Before or equal to",
  "afterOrEqual": "After or equal to",
  "isOneOf": "Is one of",
  "isNotAnyOf": "Is not any of",
  "isEmpty": "Is empty",
  "isNotEmpty": "Is not empty",
  "hasNoRelated": "Has no relate data",
  "hasRelated": "Has relate data",
};

export const isMultiple = (matcher?: MatchMode): boolean => {
  if (!matcher) {
    return false;
  }
  return ["containsAll", "containsAny", "notContainsAny", "contains", "notContains", "isOneOf", "isNotAnyOf"].includes(matcher);
};

export const TextMatchers: Array<MatchMode> = ["contains", "=", "notContains", "startsWith", "endsWith", "isEmpty", "isNotEmpty"];
export const NumberMatchers: Array<MatchMode> = ["=", "!=", ">", "<", ">=", "<=", "between", "isEmpty", "isNotEmpty"];
export const DateMatchers: Array<MatchMode> = ["before", "after", "beforeOrEqual", "afterOrEqual", "=", "!=", "between", "isEmpty", "isNotEmpty"];
export const BooleanMatchers: Array<MatchMode> = ["=", "!=", "isEmpty", "isNotEmpty"];
export const SingleSelectionMatchers: Array<MatchMode> = ["=", "!=", "isOneOf", "isNotAnyOf", "isEmpty", "isNotEmpty"];
export const ObjectMatchers: Array<MatchMode> = ["=", "!=", "isOneOf", "isNotAnyOf", "isEmpty", "isNotEmpty"];
export const MultipleSelectMatchers: Array<MatchMode> = ["containsAny", "containsAll", "notContainsAny", "isEmpty", "isNotEmpty"];
export const ConfigAttributeMatchers: Array<MatchMode> = ["contains", "notContains", "isEmpty", "isNotEmpty"];
export const ArrayMatchers: Array<MatchMode> = ["hasRelated", "hasNoRelated"];
export const SingleModeMatcher: Array<MatchMode> = ["=", "!="];
export const MultipleModeMatcher: Array<MatchMode> = ["isOneOf", "isNotAnyOf", "containsAll", "containsAny", "notContainsAny", "contains", "notContains"];
export const NoValueModeMatcher: Array<MatchMode> = ["isEmpty", "isNotEmpty", "hasRelated", "hasNoRelated"];

export const FilterStatus: { [propName: string]: string } = {
  outOfSync: "outOfSync",
  applied: "applied",
  init: "init",
};
