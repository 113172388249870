import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {Button, Dropdown, Menu, Result, Space, Tabs, Tag, Tooltip} from "antd";
import { useTranslation } from 'react-i18next';
import { fetchCanCreate, getDashboards } from "@utils/FetchUtils";
import { DashboardProps } from "@props/RecordProps";
import { Dashboard } from "./form/dashboard/";
import {
  PlusCircleOutlined, SettingOutlined, ReloadOutlined,
  EditOutlined, FullscreenOutlined
} from "@ant-design/icons";
import { LargeSpin } from "./components";
import { DeleteComponent } from "./form";
import { DynamicFormDomainName } from "@config/domain";
import { RefreshIntervalSelect } from "./form/dashboard";
import RedirectComponent from "./components/redirect/RedirectComponent";
import { useFullScreen } from "@utils/hooks";
import { useTheme } from "@utils/hooks";

const { TabPane } = Tabs;

const FirstPage = (): ReactElement => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<DashboardProps>();
  const [showEditDashboardModal, setShowEditDashboardModal] = useState<boolean>(false);
  const [showAddWidgetModal, setShowAddWidgetModal] = useState<boolean>(false);
  const [showAddDashboardModal, setShowAddDashboardModal] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<Array<DashboardProps>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canCreateDashboard, setCanCreateDashboard] = useState<boolean>(false);
  //DESIGN BACKGROUND: triggerDashboardRefresh is use to refresh list of dashboard
  // widgets when user add a new widget to current displaying dashboard
  const [triggerDashboardRefresh, setTriggerDashboardRefresh] = useState<false | number>(false);
  const { fullScreen, toggleFullScreen } = useFullScreen(); // <-- Use the custom hook
  const [refreshIntervals, setRefreshIntervals] = useState<Array<{ id: number; refreshInterval?: number }>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const themeInfo = useTheme();

  const refreshData = useCallback((): void => {
    getDashboards().then(ds => {
      if (ds == null || ds.length === 0) {
        return;
      }
      if (current == null) {
        setCurrent(ds?.[0]);
      }
      //If current display dashboard been deleted
      //Set current to first dashboard
      if (current != null && ds?.filter(d => d.id === current?.id).length === 0) {
        setCurrent(ds?.[0]);
      }
      setDashboards(ds);
      //Loop over dashboards and put the extInfo.refreshInterval to a list
      const refreshIntervals: Array<{ id: number; refreshInterval?: number }> = [];
      ds.forEach(d => {
        refreshIntervals.push({ id: d.id, refreshInterval: d.extInfo?.refreshInterval });
      });
      setRefreshIntervals(refreshIntervals);
      //Each time refresh list of data, give triggerDashboardRefresh a random number
      //To trigger dashboard widget list refresh
      setTriggerDashboardRefresh(Math.random());
    }).finally(() => setLoading(false));
  }, [current]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    fetchCanCreate("DynamicForm")
      .then(json => setCanCreateDashboard(json.create))
      .catch(e => {
        console.error(`Failed to get canCreate of domain DynamicForm: ${e}`);
      });
  }, []);

  const getMenu = (d: DashboardProps): ReactElement => (
    <Menu>
      <Menu.Item key="1">
        <span onClick={() => {
          toggleFullScreen();
        }}>
          <FullscreenOutlined /> {t("Fullscreen")}
        </span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={() => {
          setLoading(true);
          refreshData();
        }}><ReloadOutlined /> {t('Refresh data')}</span>
      </Menu.Item>
      {d.canUpdate &&
        <Menu.Item>
          <span onClick={() => setShowEditDashboardModal(true)}>
            <EditOutlined /> {t('Edit dashboard information')}
          </span>
        </Menu.Item>
      }
      {
        d.canCreateWidget &&
        <Menu.Item>
          <span onClick={() => setShowAddWidgetModal(true)}>
            <PlusCircleOutlined /> {t('Add new widget')}
          </span>
        </Menu.Item>
      }
      {
        d.canDelete &&
        <Menu.Item>
          <DeleteComponent
            domainName="DynamicForm"
            id={d.id}
            callback={() => {
              setLoading(true);
              refreshData();
            }}
            trigger="click"
            renderWithoutContainer={true}
            text={t("Delete dashboard")}
          />
        </Menu.Item>
      }
    </Menu >
  );

  const createDashboardIcon = (
    <Tooltip
      title={t("Create new dashboard")}
      placement="left"
    >
      <a href="/#" onClick={(e: React.MouseEvent<unknown>) => e.preventDefault()} title={t("Create new dashboard")}>
        <PlusCircleOutlined
          onClick={() => setShowAddDashboardModal(true)}
          className="link-icon"
        />
      </a>
    </Tooltip>
  );

  const createDashboardBigIcon = (
    <Result
      title={t("No dashboard defined(or you don't have access to any)")}
      extra={canCreateDashboard && (
        <Button
          type="primary"
          onClick={() => setShowAddDashboardModal(true)}
        >
          Create new dashboard
        </Button>
      )}
    />
  );

  const addWidgetModal = <>{showAddWidgetModal &&
    <RedirectComponent
      forMultiple={false}
      fetchDataCallback={() => {
        refreshData();
        setShowAddWidgetModal(false);
      }}
      redirect={`/DynamicDashboardWidget/create`}
      //Use 7 to make sure it appears on top of dashboard widgets
      zIndex={7}
      showText={false}
      ownerClass={DynamicFormDomainName}
      ownerId={current?.id}
      columnNameInOwnerClass="dashboardWidgets"
      hasRelateObjectField={true}
    />
  }</>;

  const editDashboardModal = <>{showEditDashboardModal &&
    <RedirectComponent
      forMultiple={false}
      fetchDataCallback={() => {
        refreshData();
        setShowEditDashboardModal(false);
      }}
      redirect={`/DynamicForm/${current?.id}/update`}
      //Use 7 to make sure it appears on top of dashboard widgets
      zIndex={7}
      showText={false}
    />
  }</>;

  const addDashboardModal = <>{
    showAddDashboardModal && <RedirectComponent
      forMultiple={false}
      fetchDataCallback={() => {
        refreshData();
        setShowAddDashboardModal(false);
      }}
      redirect={`/DynamicForm/create`}
      //Use 7 to make sure it appears on top of dashboard widgets
      zIndex={7}
      showText={false}
      hasRelateObjectField={false}
    />
  }</>;

  const getDashboard = useCallback((dashboardMeta: DashboardProps, isActive: boolean): ReactElement => {
    const myRefreshInterval = refreshIntervals.find(ri => ri.id === dashboardMeta.id)?.refreshInterval;
    return (<>
      {loading && <LargeSpin />}
      {!loading && <Dashboard
          meta={dashboardMeta}
          display={isActive}
          triggerRefresh={triggerDashboardRefresh}
          addWidgetCallback={() => setShowAddWidgetModal(true)}
          refreshInterval={myRefreshInterval}
      />}
    </>);
  },[refreshIntervals, triggerDashboardRefresh, loading]);

  const dashboardSkeletonClassName: string = fullScreen? "dashboard-skeleton-fullscreen" : "";

  return (
    <div className={`dashboard-skeleton ${dashboardSkeletonClassName}`}>
      {addWidgetModal}
      {editDashboardModal}
      {addDashboardModal}
      {!loading && dashboards.length === 0 && createDashboardBigIcon}
      {
        dashboards.length > 0
        && fullScreen
        && dashboards.filter(d => d.id === current?.id).length > 0
        && getDashboard(dashboards.filter(d => d.id === current?.id)[0], true)
      }
      {dashboards.length > 0 && !fullScreen &&
        <Tabs
          defaultActiveKey={current?.id.toString()}
          activeKey={current?.id.toString()}
          tabBarExtraContent={{
            right: (
              <Space>
                {(canCreateDashboard ? createDashboardIcon : <></>)}
              </Space>
            ),
          }}
        >
          {dashboards?.map(d => {
            const isActive = (current?.id === d.id);
            return (<TabPane
              tab={
                <Space>
                  <span
                    onClick={() => setCurrent(d)}
                  >{d.label ?? d.name}</span>
                  {isActive && (
                      <Space direction="horizontal" size={2}>
                        <Dropdown
                            overlay={getMenu(d)}
                            trigger={["click"]}
                        >
                          <a title={t("Change dashboard settings")}>
                            <Tag><SettingOutlined/>{t('设置')}</Tag>
                          </a>
                        </Dropdown>
                        <RefreshIntervalSelect
                            setRefreshIntervalCallback={(interval?: number) => {
                              //Find the value from refreshIntervals by dashboard id and update it
                              const newRefreshIntervals = [...refreshIntervals];
                              newRefreshIntervals.forEach((ri: { id: number; refreshInterval?: number }) => {
                                if (ri.id == d.id) {
                                  ri.refreshInterval = interval;
                                }
                              });
                              setRefreshIntervals(newRefreshIntervals);
                            }}
                            refreshInterval={refreshIntervals.find(ri => ri.id === d.id)?.refreshInterval}
                        />
                      </Space>
                    )}
                </Space>
              }
              key={d.id.toString()}
            >
              {getDashboard(d, isActive)}
            </TabPane>
            );
          })}
        </Tabs>
      }
    </div>
  );
};

export default FirstPage;

