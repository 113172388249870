import React, { ReactElement } from 'react';
import { RecordProps, TableMetaProps } from "@props/RecordProps";

export interface GroupedGrandChildDetailFooterProps {
  domainName: string;
  record: RecordProps;
  displayColumn: TableMetaProps;
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GroupedGrandChildDetailFooter = (props: GroupedGrandChildDetailFooterProps): ReactElement => {
  //const { domainName, record, displayColumn, zIndex } = props;
  //console.log(props, ' <--- props value');
  return (
    <div>
    </div>
  );
};

export default GroupedGrandChildDetailFooter;
