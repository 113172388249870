import React, { ReactElement } from "react";
import { Menu } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFullScreen } from "@utils/hooks";

const FullScreenToggle = (): ReactElement => {
  const { fullScreen, toggleFullScreen } = useFullScreen();
  const { t } = useTranslation();
  const icon = fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />;
  return (
    <Menu.Item key="toggleFullScreen" onClick={toggleFullScreen} icon={icon}>
      {fullScreen ? t("Exit fullscreen") : t("Fullscreen")}
    </Menu.Item>
  );
};

export default FullScreenToggle;
