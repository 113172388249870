//TODO Move to a separate file
import { Button, Form, FormInstance, Space, Tooltip } from "antd";
import {
  ContinueOperate,
  FormType,
  GroupedFormFields,
  OperationPage,
  SaveRecordProps,
  Store
} from "@props/RecordProps";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DefaultFormLayout, FormLayoutConfig, SpecialFormLayout } from "@config/base";
import {
  DoubleLeftOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { debounce } from "lodash";

const DataCollectFormMasterPanel = (props: {
  domainName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>,
  onValuesChange: (changedValues: Store, allValues: Store) => void,
  onFinish: (result: SaveRecordProps, continueOperate?: ContinueOperate) => void,
  onFinishFailed: (result: ValidateErrorEntity) => void,
  formFieldElems: JSX.Element | GroupedFormFields | Array<React.ReactElement>,
  collapseMasterCallback: () => void;
  expandGroupsCallback: () => void;
  collapseGroupsCallback: () => void;
  hasGroup?: boolean,
  labelAlign?: "left" | "right",
  initialValues?: SaveRecordProps,
  readonly?: boolean,
  hasDetail: boolean,
  formType?: FormType,
  showBottomSaveButton?: boolean,
  page: OperationPage,
}): ReactElement => {
  const { t } = useTranslation();
  const {
    labelAlign,
    formFieldElems,
    onFinish,
    onValuesChange,
    onFinishFailed,
    form,
    initialValues,
    domainName,
    collapseMasterCallback,
    collapseGroupsCallback,
    expandGroupsCallback,
    hasGroup,
    hasDetail,
    formType,
    showBottomSaveButton,
    page,
  } = props;
  const effLayout = FormLayoutConfig[formType as string] || SpecialFormLayout[domainName] || DefaultFormLayout;
  const title = t(`Save`);
  const pagesArray = ['master-detail', 'detail-drawer', 'wizard', 'user-profile-edit'];
  const pageConst = (page === 'action') ? "vertical" : (pagesArray.includes(page) ? "horizontal" : "inline");
  //FIXME 这里如果在 Modal 中弹出的，那么不应该显示 save 按钮
  return (<>
    {(hasDetail || hasGroup) &&
      <div className="master-operation-container">
        <Space direction="horizontal">
          {collapseMasterCallback && hasDetail &&
            <Tooltip
              title={t('Click to collapse panel')}
              placement='left'
            >
              <DoubleLeftOutlined
                onClick={(e) => {
                  stopPropagationAndPreventDefault(e);
                  collapseMasterCallback();
                }}
              />
            </Tooltip>
          }
          {hasGroup && <NodeExpandOutlined onClick={expandGroupsCallback} />}
          {hasGroup && <NodeCollapseOutlined onClick={collapseGroupsCallback} />}
        </Space>
      </div>
    }
    <Form
      {...effLayout}
      form={form}
      labelAlign={labelAlign ?? "right"}
      name="basic"
      colon={false}
      layout={pageConst}
      initialValues={initialValues}
      scrollToFirstError={true}
      onValuesChange={debounce(
        onValuesChange, 1000)
      }
      onFinish={(values) => {
        try {
          onFinish(values);
        } catch (error) {
          console.error(error);
        }
      }
      }
      onFinishFailed={(values) => {
        try {
          onFinishFailed(values);
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {formFieldElems}
      {(showBottomSaveButton === true) && (
        <div className="save-button-container">
          <Button
            className="modal-title-button"
            type="default"
            htmlType="submit"
            size="middle"
            title={title}
            icon={<SaveOutlined />}
          >
            <span>{title}</span>
          </Button>
        </div>
      )}
    </Form>
  </>
  );
};
export default DataCollectFormMasterPanel;
