import React, { ReactElement } from 'react';
import { ListProps } from '@props/RecordProps';
import { ListComponent } from '../list';

export interface CardListProps extends ListProps {
  tableMode: 'card-list';
}

const CardListComponent = (props: CardListProps): ReactElement => {
  return (
    <ListComponent {...props}/>
  );

};

export default CardListComponent;
