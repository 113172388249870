import { EnumMetaProps } from "@props/RecordProps";
import { fetchEnumOptions } from "@utils/FetchUtils";

const labelCache = new Map<string, Promise<Array<EnumMetaProps>>>();

export const getEnumLabelFromCache = async (enumName: string): Promise<Array<EnumMetaProps>> => {
  let promise = labelCache.get(enumName);
  if (promise) {
    return promise;
  }
  promise = fetchEnumOptions(enumName);
  labelCache.set(enumName, promise);
  return promise;
};