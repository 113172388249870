import { DetailPanelProps } from "@props/RecordProps";
import React, { ReactElement } from "react";
import EntityAttributesTable from "./EntityAttributesTable";


export const EntityAttributesDetailPanel = (props: DetailPanelProps): ReactElement => {
  const {
    column, readonly, form, domainName, record,
    zIndex, saveOptions, fieldValue, path,
  } = props;
  if (!domainName) {
    return <></>;
  }
  return <EntityAttributesTable
    value={fieldValue}
    column={column}
    owner={record}
    editMode={!readonly}
    ownerClass={domainName}
    zIndex={zIndex}
    form={form}
    saveOptions={saveOptions}
    path={path}
  />;
};