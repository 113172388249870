import React, { ReactElement, useState } from 'react';
import {
  getAppliedSearchConditionsForDomain, getCurrentFilterId, removeObsoleteConditions,
  setSearchConditions
} from '@kernel/ServerSideSearcher';
import { DynamicFilterResponseProps, FetchSearchResultProps, SearchConditions, TableMetaProps } from '@props/RecordProps';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { DynamicIcon, CloseIcon } from '../components';
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import { useFilter } from '@utils/hooks';
import { getUserId } from '@utils/TokenUtils';
import SaveDynamicFilterPopover from '../components/filters/SaveDynamicFilterPopover';

export interface PredefinedFiltersProps {
  domainName: string;
  fetchDataCallback: () => void;
  fullTextFetchDataCallback?: (fullTextSearchConditions?: FetchSearchResultProps) => void;
  columns: Array<TableMetaProps>;
  displayStyle: 'inline' | 'block';
  displayDefault?: boolean;
  zIndex: number;
  fullTextSearchConditions?: FetchSearchResultProps;
}

const PredefinedFilters = (props: PredefinedFiltersProps): ReactElement => {
  const {
    columns,
    fetchDataCallback,
    domainName,
    displayStyle,
    displayDefault,
    zIndex,
    fullTextSearchConditions,
    fullTextFetchDataCallback
  } = props;
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState<number>(0);
  const [dynamicFilters, defaultFilter] = useFilter(domainName, displayDefault, refresh);

  const refreshCallback = (): void => {
    setRefresh(refresh + 1);
  };

  // 如果该方法返回 undefined,
  // 则 apply 系统定义的 isDefault 为 true 的 DynamicFilter
  const conditionSaved = getAppliedSearchConditionsForDomain(domainName);

  const { changed, conditions } = (conditionSaved === undefined) ?
    { changed: false, conditions: undefined } : removeObsoleteConditions(conditionSaved, columns);

  if (changed && !fullTextFetchDataCallback) {
    setSearchConditions(domainName, conditions);
    fetchDataCallback();
  }

  // 如果当前保存的搜索条件为 null 则默认使用 defaultFilter 来进行过滤
  if (defaultFilter != null && conditions == null) {
    const cond = JSON.parse(defaultFilter.conditions);
    defaultFilter.parsedConditions = cond;
    if (fullTextFetchDataCallback != null) {
      setSearchConditions(domainName, cond.searchConditions ?? {}, defaultFilter.id);
      fullTextFetchDataCallback(cond);
    } else {
      setSearchConditions(domainName, cond, defaultFilter.id);
      fetchDataCallback();
    }
  }

  const applyPredefinedFilter = (filter: DynamicFilterResponseProps): void => {
    const parsedCondition = filter.parsedConditions ?? {};
    if (fullTextFetchDataCallback != null) {
      const c = (parsedCondition as unknown as FetchSearchResultProps);
      setSearchConditions(domainName, c.searchConditions, filter.id);
      fullTextFetchDataCallback(c);
    } else {
      const c = (parsedCondition as unknown as SearchConditions);
      setSearchConditions(domainName, c, filter.id);
      fetchDataCallback();
    }
  };

  const userId = getUserId();

  function blockRender(): ReactElement {
    const entries = [] as ReactElement[];
    const nonEmptyDefaultFilter = defaultFilter ?? {} as DynamicFilterResponseProps;
    dynamicFilters?.forEach((f: DynamicFilterResponseProps, idx: number) => {
      f.parsedConditions = JSON.parse(f.conditions);
      //const isCurrent = isEqual(f.parsedConditions, conditions) || isEqual(f.parsedConditions, fullTextSearchConditions);
      const isCurrent = isEqual(f.id, getCurrentFilterId(domainName));
      const isDefault = isEqual(f, defaultFilter);
      const onClick = (): void => applyPredefinedFilter(isCurrent ? nonEmptyDefaultFilter : f);
      const isMyFilter = (f.owner != null && userId != null && f.owner === parseInt(userId));
      const userDefinedClass = isMyFilter ? "user-defined-filter-tag" : "";
      const className = isCurrent ?
        `current-filter-tag filter-${f.name}-active ${userDefinedClass}` :
        `filter-tag filter-${f.name}-inactive ${userDefinedClass}`;
      entries.push(
        <Tooltip
          title={t(f.description ?? "")}
          key={f.name}
          placement={(idx === dynamicFilters?.length - 1) ? "bottom" : "top"}
          mouseEnterDelay={0.5}
        >
          <Tag
            onClick={onClick}
            className={className}
            icon={f.icon == null ? undefined : <DynamicIcon type={f.icon} />}
          >
            <span>{t(f.label)}</span>
            <span>
              {isMyFilter &&
                <SaveDynamicFilterPopover
                  domainName={domainName}
                  zIndex={zIndex + 1}
                  filter={f}
                  updateCallback={() => refreshCallback()}
                  fullTextSearchConditions={fullTextSearchConditions}
                />
              }
            </span>
            <span>
              {isCurrent &&
                <a href="/#"
                  onClick={(e) => stopPropagationAndPreventDefault(e)}
                  title={t("Remove this filter")}
                >
                  <CloseIcon
                    noLinkWrap={true}
                    onClick={() => applyPredefinedFilter(isDefault ?
                      {} as DynamicFilterResponseProps : nonEmptyDefaultFilter)}
                  />
                </a>
              }
            </span>
          </Tag>
        </Tooltip>
      );
    });
    return entries.length > 0 ? (<>{entries}</>) : (<></>);
  }

  const inlineRender = (): ReactElement => {
    return blockRender();
  };
  const renderByDisplayStyle = {
    inline: inlineRender,
    block: blockRender
  };

  return renderByDisplayStyle[displayStyle]();
};

export default PredefinedFilters;
