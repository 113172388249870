import React, { ReactElement, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider, Layout, Result, Space, Spin } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, Routes, useNavigate } from 'react-router-dom';
import { getOrganization } from "@utils/TokenUtils";

import { AppNav, RoutersNav } from './menu';
import { fetchTopMenus, fetchDynamicThemeInfo } from '@utils/FetchUtils';
import { loggedIn } from "@utils/LoginUtils";
import { UserProfile } from './userProfile';
import { LeftMenuWidth, SERVER_URL } from "@config/base";
import { ThemeInfoProps } from "@props/RecordProps";
import { getFaviconEl } from '@utils/ComponentUtils';
import RoutersConfig, { DynamicMenuProps } from '@kernel/RoutersConfig';
import { SupportWidget } from './components/supportWidget';
import { AiAssistantWidget } from "./components/assistant";
import { errorToObject } from '@utils/ObjectUtils';
import { Locale } from 'antd/lib/locale';

const { Sider } = Layout;

const theme = {
  token: {
    colorText: "rgba(0, 0, 0, 0.88)",
    colorPrimary: '#696AAD', // Example of setting a primary color
    colorBgContainer: '#f0f2f5', // Example of setting a background color for containers
  },
  components: {
    Layout: {
      siderBg: "#f0f2f5",
    },
    Menu: {
      colorBgContainer: '#f0f2f5', // Specific customization for Menu component
    },
  },
};

export interface ErrorProps {
  status: number;
}

const i18nToLocaleMapping: { [propName: string]: Locale } = {
  'zh': zhCN,
  'en': enUS
};

const App = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [sidebarHide, setSidebarHide] = useState<boolean>(false);
  const [appNav, setAppNav] = useState<ReactElement>();
  const [routerNav, setRouterNav] = useState<ReactElement>();
  const [publicRouterNav, setPublicRouterNav] = useState<ReactElement>();
  const [themeInfo, setThemeInfo] = useState<ThemeInfoProps>();
  const [error, setError] = useState<Error | null>(null);
  const locale = i18nToLocaleMapping[i18n.language] ?? zhCN;
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect((): (() => void) => {
    // Add event listener for resize to detect mobile
    const handleResize: () => void = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (themeInfo != null) {
      return;
    }
    fetchDynamicThemeInfo().then(themeInfo => {
      setThemeInfo(themeInfo);
      if (themeInfo.title) {
        document.title = themeInfo.title;
      }
      if (themeInfo.backgroundImage && themeInfo.backgroundImage.length > 0) {
        document.body.style.backgroundImage = "url('" + themeInfo.backgroundImage + "')";
      }
      if (themeInfo.favicon && themeInfo.favicon.length > 0) {
        const faviconEl = getFaviconEl();
        if (faviconEl) {
          faviconEl.href = themeInfo.favicon;
        }
      }
    }).catch((e) => {
      console.error(`Error fetching page title: ${e}`);
    });
  }, [themeInfo]);

  const addStyle = (url: string): void => {
    const style = document.createElement("link");
    style.href = url;
    style.rel = "stylesheet";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    style.async = true;
    document.head.appendChild(style);
  };

  const forwardToLoginPage = (): void => {
    if (window.location.pathname !== '/login') {
      window.location.replace("/login");
    }
  };

  useEffect(() => {
    const cssUrl = `${SERVER_URL}/theme/dynamicTheme.css`;
    addStyle(cssUrl);
    if (loggedIn()) {
      setAuthorized(true);
      setLoading(true);
      (appNav != null) && setLoading(false);
      (appNav == null) && fetchTopMenus().then((topMenus: Array<DynamicMenuProps> | ErrorProps) => {
        if (Array.isArray(topMenus) && topMenus.length >= 0) {
          setAppNav(<AppNav
            menus={topMenus}
            mode={isMobile ? "horizontal" : "inline"}
            inlineCollapsed={sidebarHide}
          />);
          setRouterNav(<RoutersNav />);
        } else if (topMenus != null && typeof topMenus == 'object') {
          if ('status' in topMenus && topMenus.status === 401) {
            window.location.replace("/login");
          }
          setAuthorized(false);
        }
        setLoading(false);
      }).catch((error: Error) => {
        setLoading(false);
        setAuthorized(false);
        console.error("Error get menu list", error);
        setError(error);
        forwardToLoginPage();
      });
    } else {
      setPublicRouterNav(<div><Routes>{RoutersConfig({ type: "public", navigate })}</Routes></div>);
    }
  }, [appNav, navigate, sidebarHide, isMobile]);

  const stackTrace =  error ?  errorToObject(error)?.stack : "";

  return (
    <ConfigProvider locale={locale} theme={theme}>
      {isMobile && (
        <div className="top-bar-menu">
          {appNav}
        </div>
      )}
      <div>{authorized ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            className="left-menu-container"
            width={LeftMenuWidth}
            collapsed={sidebarHide}
          >
            {!loading && <div className='left-top-menu-container'>
                <div className='menu-logo-container'>
                <Link to="/">
                  {!sidebarHide && <img src={themeInfo?.logo} alt="logo" className='menu-logo' />}
                  {sidebarHide && <img src={themeInfo?.squareLogo ?? themeInfo?.logo} alt="logo" className='menu-logo-square' />}
                </Link>
                </div>
                <div className={`user-org-info user-org-info-${sidebarHide? 'hidden' : 'display'}`}>
                    <UserProfile
                        collapsed={sidebarHide}
                    />
                  {!sidebarHide && <Link to="/" title={getOrganization().name} className="user-org-info-org-name">
                    {getOrganization().name}
                  </Link>}
                </div>
            </div>}
            {!loading && !isMobile && (<div
              className={sidebarHide ? "left-sidebar__hide left-sidebar" : "left-sidebar__show left-sidebar"}
              onClick={() => setSidebarHide(!sidebarHide)}
            >
              {!sidebarHide &&
                  <a href="/#" onClick={(e: React.MouseEvent<unknown>) => e.preventDefault()}>
                      <DoubleLeftOutlined
                          className="link-icon-small"
                          title={t("Click to collapse left menu")}
                      />
                  </a>
              }
              {sidebarHide &&
                  <a
                      title={t("Click to expand menu")}
                      href="/#"
                      style={{ marginLeft: "0.6rem" }}
                      onClick={(e: React.MouseEvent<unknown>) => e.preventDefault()}>
                      <DoubleRightOutlined
                          className="link-icon-small"
                          title={t("Click to expand left menu")}
                      />
                  </a>
              }
            </div>)}
            {!isMobile && (
              <div className="left-menu-container">
                {appNav}
              </div>
            )}
          </Sider>
          {loading && (
            <div style={{ margin: "auto" }}>
              <Spin style={{ margin: "10px" }} />
            </div>
          )}
          {(!loading) &&
            <Layout
              className="site-layout"
            >
              {routerNav}
              <AiAssistantWidget
                zIndex={9999}
              />
            </Layout>
          }
        </Layout>
      ) : (
        <Layout className="login-page-container">
          <Layout className="site-layout">
            {error &&
              <Result
                status="500"
                title={t('Error while fetching top menus')}
                subTitle={
                  <Space direction="vertical" align="start">
                    <pre style={{textAlign: 'left'}}>
                      {stackTrace}
                    </pre>
                  </Space>
                }
              />
            }
            {publicRouterNav}
          </Layout>

        </Layout>
      )}
        <SupportWidget
          authorized={authorized}
          zIndex={9999}
        />
      </div>
    </ConfigProvider>
  );
};

export default App;
