import React, { ReactElement, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { RecordProps, TableMetaProps } from "@props/RecordProps";
import { Avatar, Badge, Checkbox, List, Space, Spin } from "antd";
import { downloadAttachment, fetchCurrentValues } from "@utils/FetchUtils";
import { ExcludeArrayColumnsFetchType, FileFieldType } from "@config/base";
import { removeItem } from "@utils/ArrayUtils";
import { PredefinedFilters } from "../../form";
import { useTranslation } from "react-i18next";
import ReactMarkdownWrap from "../../components/wrap/ReactMarkdownWrap";

export interface SimpleListComponentProps {
  domainName: string;
  data: Array<RecordProps>;
  onClick: (record: RecordProps) => void;
  onSelect: (record: Array<RecordProps>) => void;
  initSelectedId?: number;
  columns: Array<TableMetaProps>;
  fetchDataCallback: () => void;
  zIndex: number;
}

interface AvatarContentProp {
  [propName: number]: string;
}

const avatarSize = { xs: 24, sm: 32, md: 40, lg: 48, xl: 48, xxl: 48 };
const SystemAvatarImageSrc = "/images/system-avatar.svg";

const SimpleListComponent = (props: SimpleListComponentProps): ReactElement => {
  const {
    data,
    onSelect,
    onClick,
    initSelectedId,
    domainName,
    columns,
    fetchDataCallback,
    zIndex
  } = props;
  const avatarBlobs = useRef<AvatarContentProp>([]);
  const [avatarsLoaded, setAvatarsLoaded] = useState<boolean>(false);
  const [mouseOverItem, setMouseOverItem] = useState<number>();
  const [selectedData, setSelectedData] = useState<Array<RecordProps>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (data == null || data.length === 0 || avatarsLoaded) {
      return;
    }
    const reducedFroms: Set<number> = new Set();
    data.forEach((record) => {
      if (record.from?.id) {
        reducedFroms.add(record.from?.id);
      }
    });
    let pendingAvatars = Array.from(reducedFroms);
    fetchCurrentValues("User", Array.from(reducedFroms), ExcludeArrayColumnsFetchType)
      .then(users => {
        users.forEach((user) => {
          const avatarId = user.avatar?.id;
          if (avatarId != null) {
            downloadAttachment(avatarId, false).then(content => {
              avatarBlobs.current[user.id] = URL.createObjectURL(content);
              pendingAvatars = removeItem(pendingAvatars, user.id);
            }).catch(e => console.error(`Failed to download attachment ${avatarId}: ${JSON.stringify(e)}`))
              .finally(() => {
                pendingAvatars = removeItem(pendingAvatars, user.id);
              });
          } else {
            pendingAvatars = removeItem(pendingAvatars, user.id);
          }
        });
      }).catch((e) => {
        console.log(`Failed to get ${FileFieldType} with id ${Array.from(reducedFroms)}: ${e}`);
        pendingAvatars = [];
      });
    const intervalFunc = (): void => {
      if (pendingAvatars.length === 0) {
        clearInterval(intervalId);
        setAvatarsLoaded(true);
      }
    };
    const intervalId = setInterval(intervalFunc, 100);
  }, [data, avatarBlobs, avatarsLoaded]);

  const dataIds = data?.map(d => d.id);
  const selectedDataIds = selectedData?.map(d => d.id);
  const anySelectedDataShown: boolean = (
    selectedData.length > 0 && selectedDataIds.filter(id => dataIds.includes(id)).length > 0
  );
  const renderItem = (item: RecordProps): ReactElement => {
    const content = (
      <ReactMarkdownWrap
        linkTarget="_blank"
        className="markdown-display"
      >
        {`${item.content.substring(0, 50)} ...`}
      </ReactMarkdownWrap>
    );
    const dateCreated = dayjs(item.dateCreated);
    const dateCreatedStr = dateCreated.format("yyyy/M/DD hh:mm");
    const isSelected = (initSelectedId === item.id);
    const selectedClass = isSelected ? "selected-list-item" : "";
    const markClass = (item?.isUnRead === true) ? "mark-list-item" : "";
    const usernameEmpty = (item?.from?.username === undefined);
    const title = item.from?.username ?? "System";
    const avatarBlob = avatarBlobs.current[item.from?.id];
    const avatar = <>{mouseOverItem !== item.id && !anySelectedDataShown && (
      <Avatar
        src={usernameEmpty ? SystemAvatarImageSrc : avatarBlob}
        size={avatarSize}
        style={{ padding: "8px"}}
      >
        {!avatarBlob && avatarsLoaded &&
          <span className="capitalize">
            {title?.substring(0, 3)}
          </span>
        }
        {!avatarBlob && !avatarsLoaded && <Spin size="small" />}
      </Avatar>
    )} {(mouseOverItem === item.id || anySelectedDataShown) && (
      <span title={t('Click to select')}>
        <Checkbox
          onClick={() => {
            const newSelectedData = selectedData.map(d => d.id).includes(item.id) ?
              selectedData.filter(d => d.id !== item.id) : [...selectedData, item];
            setSelectedData(newSelectedData);
            onSelect(newSelectedData);
          }}
          checked={selectedDataIds.includes(item.id)}
          className="simple-list-checkbox"
        />
      </span>)
      }</>;

    const avatarElems = (<Space direction="vertical" size="small">
      {markClass !== "" && <Badge color="red">
        {avatar}
      </Badge>
      }
      {markClass === '' && avatar}
    </Space>);

    return (
      <List.Item
        actions={[]}
        onClick={() => onClick(item)}
        onMouseOver={() => setMouseOverItem(item.id)}
        onMouseLeave={() => setMouseOverItem(undefined)}
      >
        <List.Item.Meta
          className={`${selectedClass} ${markClass}`}
          avatar={avatarElems}
          title={<>{title} @ {dateCreatedStr}</>}
          description={content}
        />
      </List.Item>
    );
  };

  return (
    <span style={{ zIndex }}>
      <span className='simple-list-filter-container'>
        <span
          className="select-all-container"
          title={t('Select all')}
        >
          <Checkbox
            onChange={(e) => {
              const newSelectedData = e.target.checked ? data : [];
              setSelectedData(newSelectedData);
              onSelect(newSelectedData);
            }}
            checked={selectedData.length === data.length && selectedData.length > 0}
          />
        </span>
        <span className="simple-list-filters">
          <PredefinedFilters
            domainName={domainName}
            fetchDataCallback={fetchDataCallback}
            columns={columns}
            displayStyle='inline'
            displayDefault={false}
            zIndex={zIndex}
          />
        </span>
      </span>
      <List
        className="master-detail-simple-list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={renderItem}
      />
    </span>
  );
};

export default SimpleListComponent;
