import React, { ReactElement, Suspense, useCallback, useState } from "react";
import { Button } from "antd";
import "./AiAssistant.less";
import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { AssistantWidgetProps } from ".";
import { useConfig } from "@utils/hooks";
import { useHotkeys } from "react-hotkeys-hook";
import { DisplayMode } from "@props/RecordProps";

const AiAssistantComponent = React.lazy(() => import("./AiAssistantComponent"));

const AiAssistantWidget = (props: AssistantWidgetProps): ReactElement => {

  const { zIndex } = props;
  const [style, setStyle] = useState<string>("assistant-panel-none");

  useHotkeys('shift+ctrl+k, shift+cmd+k', () => {
    const invisible = ['assistant-panel-invisible', 'assistant-panel-none'].includes(style);
    setDisplay(invisible ? 'normal' : 'minimal');
  }, {
    //Also trigger the hotkey when the user is typing in form field
    enableOnFormTags: true
  });

  const { t } = useTranslation();
  const { value } = useConfig("ai.assistant.enable", "boolean");

  const setDisplay = useCallback((displayMode: DisplayMode): void => {
    let shouldChange = false;
    if (displayMode === 'normal' && style !== 'assistant-panel-normal') {
      shouldChange = true;
      setTimeout(() => setStyle("assistant-panel-normal"), 10);
    } else if (displayMode === 'minimal' && style !== 'assistant-panel-minimal') {
      shouldChange = true;
      setTimeout(() => setStyle("assistant-panel-none"), 300);
    } else if (displayMode === 'maximal' && style !== 'assistant-panel-max') {
      shouldChange = true;
      setTimeout(() => setStyle("assistant-panel-max"), 10);
    }
    if (shouldChange) {
      setStyle("assistant-panel-invisible");
    }
  }, [setStyle, style]);

  const AssistantIconImageSrc = "/images/assistant-icon-purple.svg";

  return (
    <div className={`assistant-widget ${style === 'assistant-panel-max' ? 'assistant-widget-max' : ''}`}>
      <div className={style}>
        <Suspense fallback={<div></div>}>
          <AiAssistantComponent zIndex={zIndex} setDisplay={setDisplay} enable={value === true}/>
        </Suspense>
      </div>
      <Button
        shape="circle"
        icon={<Image src={AssistantIconImageSrc} preview={false}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                }}
        />}
        onClick={() => {
          setDisplay((style === 'assistant-panel-invisible' || style === 'assistant-panel-none') ? 'normal' : 'minimal');
        }}
        size="large"
        style={{
          zIndex: zIndex,
          fontSize: 40,
        }}
        title={t("assistant:Ai Assistant")}
      />
    </div>
  );
};

export default AiAssistantWidget;
