import React, { ReactElement, useEffect, useState } from "react";
import { Avatar, Image, Space } from "antd";
import { getAvatar } from "@utils/TokenUtils";
import { UserOutlined } from "@ant-design/icons";
import { getAvatarForUser } from "@utils/FetchUtils";
import { AvatarData } from "@utils/FetchUtilsProps";
import { isBlank } from "@utils/StringUtils";
import { AvatarSize } from "antd/lib/avatar/AvatarContext";

interface AvatarProps {
  userId: number;
  size?: AvatarSize;
  showText?: boolean;
}

const CustomAvatar = (props: AvatarProps): ReactElement => {
  const { userId, size, showText } = props;
  const [avatarData, setAvatarData] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const getAvatarDataOfUser = (userId: number): void => {
    (async () => {
      if (userId == null) {
        //get current user's avatar
        setAvatarData(getAvatar() ?? '');
      } else {
        const data: AvatarData = await getAvatarForUser(userId);
        setAvatarData(data.avatar);
        const lDisplayName = isBlank(data.name) ? data.username : (data.name ?? '');
        setDisplayName(lDisplayName);
      }
    })();
  };

  useEffect(() => {
    getAvatarDataOfUser(userId);
  }, [userId]);

  const avatar = (avatarData == null || avatarData === '') ? (<UserOutlined />) : (<Avatar
    src={<Image src={avatarData} preview={false} />}
    size={size ?? "small"}
    alt={displayName}
  />);

  return (
    <Space title={displayName}>
      {avatar}
      {showText && displayName}
    </Space>
  );
};

export default CustomAvatar;
