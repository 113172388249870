import React, { ReactElement, Suspense, useCallback, useEffect, useState } from "react";
import XMLViewer from 'react-xml-viewer';
import { useTranslation } from 'react-i18next';
import { LargeSpin, CloseIcon } from "../";
import { Modal, Result } from "antd";
import { ExtendUploadFile, HttpErrorProps, SaveRecordProps } from "@props/RecordProps";
import {
  ExcelFileExtensions,
  getBase64,
  getBlob,
  getFileType,
  ImageFileExtensions,
  isFileOfType,
  PdfFileExtensions,
  WordFileExtensions,
  HtmlFileExtensions,
  CsvFileExtensions,
  PowerpointFileExtensions, VideoFileExtensions, AudioFileExtensions, getErrorMsgKey,
  JsonFileExtensions, CodeFileExtensions, MarkdownFileExtensions, XmlFileExtensions
} from "./FileOperatorUtils";
import { RcFile } from "antd/es/upload";
import DOMPurify from "dompurify";
import { wrapAsHtml } from "@utils/ComponentUtils";
import JsonCell from "../../form/cells/JsonCell";
import { emptyMethod } from "@utils/Constants";
import CodeEditor from "../../form/fields/CodeEditor";
import ReactMarkdownWrap from "../wrap/ReactMarkdownWrap";

const PdfPreview = React.lazy(() => import("./PdfPreview"));
const CsvPreview = React.lazy(() => import("./CsvPreview"));
const CustomOutTable = React.lazy(() => import('../wrap/OutTableWrap'));

export interface FilePreviewComponentProps {
  file: ExtendUploadFile;
  onClose: () => void;
  zIndex: number;
}

const FilePreviewComponent = (props: FilePreviewComponentProps): ReactElement => {
  const { file, onClose, zIndex } = props;
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewBlob, setPreviewBlob] = useState<string>();
  const [previewFileType, setPreviewFileType] = useState<string>();
  const [previewLoading, setPreviewLoading] = useState<boolean>();
  const [modalWidth, setModalWidth] = useState<number | string>();
  const [previewTitle, setPreviewTitle] = useState<string>();
  const [cols, setCols] = useState<number>();
  const [rows, setRows] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { t } = useTranslation();

  const errorHandling = useCallback((file: ExtendUploadFile, e: HttpErrorProps): void => {
    const errorMsgKey: string = getErrorMsgKey(e);
    setErrorMessage(t(errorMsgKey, { error: JSON.stringify(e), fileName: file.name }));
  }, [t]);

  useEffect(() => {
    const previewMethods: Array<{
      applyTypes: Array<string>;
      prepare: (file: ExtendUploadFile) => void;
    }> = [
        {
          applyTypes: [...HtmlFileExtensions, ...CsvFileExtensions, ...JsonFileExtensions,
          ...CodeFileExtensions, ...MarkdownFileExtensions, ...XmlFileExtensions],
          prepare: (file: ExtendUploadFile): void => {
            getBlob(file as RcFile, true).then((blob: Blob | void) => {
              const b = blob as Blob;
              b?.text().then((content: string) => {
                if (!isFileOfType(file, XmlFileExtensions)) {
                  setPreviewBlob(DOMPurify.sanitize(content));
                } else {
                  setPreviewBlob(content);
                }
                setModalWidth("90%");
              });
            }).catch(e => errorHandling(file, e))
              .finally(() => setPreviewLoading(false));
          },
        },
        {
          applyTypes: [
            ...ImageFileExtensions, ...PdfFileExtensions,
            ...PowerpointFileExtensions, ...WordFileExtensions,
            ...VideoFileExtensions, ...AudioFileExtensions
          ],
          prepare: (file: ExtendUploadFile): void => {
            getBase64(file).then(content => {
              file.preview = (content as string);
              setPreviewBlob(content as string);
              setModalWidth(undefined);
            }).catch(e => errorHandling(file, e))
              .finally(() => setPreviewLoading(false));
          }
        },
        {
          applyTypes: ExcelFileExtensions,
          prepare: (file: ExtendUploadFile): void => {
            getBlob(file as RcFile, true).then(blob => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              import('../wrap/ExcelRendererWrap').then(excelRendererWrap => {
                excelRendererWrap.default(blob, (err: unknown, resp: { cols: number, rows: number }) => {
                  if (!err) {
                    setCols(resp.cols);
                    setRows(resp.rows);
                  } else {
                    console.error(`Failed to parse excel file: ${JSON.stringify(err)}`);
                  }
                });
                setModalWidth("90%");
              });
            }).catch(e => errorHandling(file, e))
              .finally(() => setPreviewLoading(false));
          }
        },
      ];
    setPreviewLoading(true);
    setPreviewTitle(file.name);
    setPreviewFileType(getFileType(file));
    setPreviewVisible(true);
    previewMethods.find(({ applyTypes }) => applyTypes.includes(getFileType(file)))?.prepare(file);
    // we only want to run this effect when file.id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.id, errorHandling]);

  const resetAllStatus = (): void => {
    setPreviewBlob(undefined);
    setPreviewVisible(false);
    setPreviewFileType(undefined);
    setRows(undefined);
    setCols(undefined);
    onClose();
  };

  const imagePreview: (false | JSX.Element) = (isFileOfType(file, ImageFileExtensions) && (previewBlob != null) &&
    <img alt={previewTitle}
      src={previewBlob}
      style={{ textAlign: "center", maxWidth: "100%", maxHeight: "100%" }}
    />);

  const excelPreview: (false | JSX.Element) = (isFileOfType(file, ExcelFileExtensions) && (rows != null && cols != null) &&
    <Suspense fallback={<div></div>}>
      <CustomOutTable
        data={rows}
        columns={cols}
        tableClassName="excel-table"
        tableHeaderRowClass="heading"
      /></Suspense>);

  const asPdfPreview: (false | JSX.Element) = (isFileOfType(file, [
    ...WordFileExtensions, ...PdfFileExtensions, ...PowerpointFileExtensions
  ]) && (previewBlob != null) && (<Suspense fallback={<div />}><PdfPreview previewBlob={previewBlob} /></Suspense>));

  const htmlPreview: (false | JSX.Element) = (isFileOfType(file, HtmlFileExtensions)) && (previewBlob != null) &&
    (<div dangerouslySetInnerHTML={{ __html: previewBlob }} />);

  const csvPreview: (false | JSX.Element) = (isFileOfType(file, CsvFileExtensions) && (previewBlob != null) &&
    (<Suspense fallback={<div />}><CsvPreview data={previewBlob} /></Suspense>));

  const videoPreview: (false | JSX.Element) = (isFileOfType(file, VideoFileExtensions) && (previewBlob != null) &&
    (<video controls>
      <source src={previewBlob} />
      Your browser does not support the <code>video</code> element.
    </video>));

  const audioPreview: (false | JSX.Element) = (isFileOfType(file, AudioFileExtensions) && (previewBlob != null) &&
    (<audio controls src={previewBlob}>
      Your browser does not support the
      <code>audio</code> element.
    </audio>));

  const jsonPreview: (false | JSX.Element) = (isFileOfType(file, JsonFileExtensions) && (previewBlob != null) &&
    (<JsonCell text={previewBlob} page="DISPLAY" />));

  const codePreview: (false | JSX.Element) = (isFileOfType(file, CodeFileExtensions) && (previewBlob != null) &&
    (<CodeEditor
      value={previewBlob ?? ""}
      onChange={emptyMethod}
      name={"code"}
      updatable={false}
      width="100%"
      mode={getFileType(file)?.toLowerCase() ?? "java"}
      zIndex={zIndex}
      record={{} as SaveRecordProps}
    />));

  const markdownPreview: (false | JSX.Element) = (isFileOfType(file, MarkdownFileExtensions) && (previewBlob != null) &&
    (<ReactMarkdownWrap linkTarget="_blank">{previewBlob}</ReactMarkdownWrap>));

  const xmlPreview: (false | JSX.Element) = (isFileOfType(file, XmlFileExtensions) && (previewBlob != null) &&
    (<XMLViewer xml={previewBlob} collapsible={true} />));

  return (<>
    <Modal
      zIndex={zIndex + 1}
      open={previewVisible || previewLoading}
      title={previewTitle}
      footer={null}
      onCancel={(): void => {
        resetAllStatus();
      }}
      className={`file-preview-modal ${previewFileType}-preview-modal`}
      width={modalWidth}
      closeIcon={<CloseIcon onClick={() => {
        resetAllStatus();
      }}
      />
      }
    >
      <div className="file-preview-container">
        {previewLoading && <LargeSpin />}
        {!previewLoading && (errorMessage != null) && (<Result
          status="error"
          title={t('Error')}
          subTitle={wrapAsHtml(errorMessage)}
        />)}
        {!previewLoading && (errorMessage == null) &&
          <>
            {jsonPreview}
            {imagePreview}
            {excelPreview}
            {asPdfPreview}
            {htmlPreview}
            {csvPreview}
            {videoPreview}
            {audioPreview}
            {codePreview}
            {markdownPreview}
            {xmlPreview}
          </>
        }
      </div>
    </Modal>
  </>
  );
};

export default FilePreviewComponent;
