import React from "react";
import { ErrorMsg } from "../components";
import { validConstrain } from "@utils/FetchUtils";
import {ConstraintsToRulesMapParams} from "@props/RecordProps";
import { humanReadableTitle, removePackagePart } from "@utils/StringUtils";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { TRIGGER_BACKGROUND_VALIDATION_MINIMAL_TIME_MS } from '@config/base';
import { Rule } from "antd/lib/form";
import i18n from "@config/i18n";
import { getColumnTransKey, shouldUseFrontendTrans } from "@utils/ColumnsUtils";

/**
 * 将后端的数据库元数据转换为前端 antd 的表单校验逻辑，并调用错误显示的控件显示错误信息，
 * unique 校验采用后台定期发送 ajax 请求的方式，
 * 发送的时间间隔在常量 TRIGGER_BACKGROUND_VALIDATION_MINIMAL_TIME_MS 中定义
 * 当前支持 nullable 和 unique 两种元数据校验
 * @param props
 */
export function constraintsToRulesMap(props: ConstraintsToRulesMapParams): Rule | undefined {
  const {
    constraintKey, constraintValue, domainName, column, record,
    callback, nullableCheckBy, form
  } = props;

  const displayDomainName = humanReadableTitle(removePackagePart(domainName) ?? "");
  const columnTitle = shouldUseFrontendTrans(column) ?
        i18n.t(getColumnTransKey(domainName, column.key)) : column.title;
  let latestUniqueValue = "";
  let uniqueTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
  let latestValidateValue = "";
  let validateTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
  switch (constraintKey) {
    case "email":
      return {
        required: (nullableCheckBy === constraintKey),
        type: "email",
        message: i18n.t("Please input a valid email address")
      };
    case "unique":
    case "validate":
      return {
        validator: (rule: RuleObject, value: StoreValue) => {
          const isUniqueConstraint = (constraintKey === "unique");
          if (isUniqueConstraint) {
            latestUniqueValue = value;
          } else {
            latestValidateValue = value;
          }
          //下面的这一段是用于控制在 1 秒（TRIGGER_BACKGROUND_VALIDATION_MINIMAL_TIME_MS）内
          //只发送一个唯一校验的请求到后台
          const x = (): Promise<string> => {
            return new Promise((resolve) => {
              if (isUniqueConstraint) {
                if (uniqueTimeout != null) {
                  clearTimeout(uniqueTimeout);
                }
                uniqueTimeout = setTimeout(() => resolve('done!'),
                  TRIGGER_BACKGROUND_VALIDATION_MINIMAL_TIME_MS);
              } else {
                if (validateTimeout != null) {
                  clearTimeout(validateTimeout);
                }
                validateTimeout = setTimeout(() => resolve('done!'),
                  TRIGGER_BACKGROUND_VALIDATION_MINIMAL_TIME_MS);
              }
            });
          };
          const shouldCheck = (isUniqueConstraint) ? (latestUniqueValue !== "" && latestUniqueValue != null) : true;
          if (shouldCheck) {
            //字段不为空时，调用后台的唯一校验接口
            //对于 validation,字段为空的时候, 也调用后台的校验逻辑
            return x().then(() => {
              return validConstrain({
                domainName,
                column: column,
                value: isUniqueConstraint ? latestUniqueValue : latestValidateValue,
                id: (record == null || record.id == null) ? undefined : record.id,
                record,
                formValues: form.getFieldsValue(),
                validationType: constraintKey
              }).catch((ex) => {
                const msg = i18n.t('ValidationFailed', { columnTitle, statusText: ex?.response?.statusText });
                return Promise.reject(msg);
              }).then((validResult => {
                const isValid = validResult.valid;
                callback?.(isValid);
                if (!isValid) {
                  const errorMsg = isUniqueConstraint ?
                    i18n.t("UniqueValidationFailed", { domainTitle: displayDomainName, columnTitle, value: latestUniqueValue }) :
                    validResult.message;
                  const errorComponent = <ErrorMsg errorMsg={i18n.t(errorMsg ?? "Invalid value")} />;
                  return Promise.reject(errorComponent);
                } else if (isValid && !isUniqueConstraint && validResult.message !== '' && validResult.message != null) {
                  //TODO 支持 valid 返回提示信息,即后台返回 valid: true 然后 message 不为空
                  //  使用 antd 表单的 warning 形式的校验, 待调研
                  //  https://github.com/ant-design/ant-design/issues/29312
                  //const errorMsg = validResult.message;
                  return Promise.resolve();
                } else {
                  return Promise.resolve();
                }
              }));
            });
          } else if (nullableCheckBy === constraintKey) {
            //字段有校验且不能为空的情况下，如果字段为空，直接返回为空的校验结果
            const errorComponent = <ErrorMsg errorMsg={i18n.t('ColumnIsRequired', { columnTitle })} />;
            callback?.(false);
            return Promise.reject(errorComponent);
          } else {
            //字段有校验且可以为空的情况下，如果字段为空，直接返校回通过
            callback?.(true);
            return Promise.resolve();
          }
        }
      };
    case "nullable":
      return {
        required: true,
        // Attention: Tricky logic here
        // If nullableCheckBy is unique, means nullable will be handle by unique constraint
        // So empty string is returned to avoid duplicate error message shown
        validator: (_: RuleObject, value: StoreValue) => {
          let invalid = false;
          let errorComp = null;
          if (["unique", "validate", "email"].includes(nullableCheckBy ?? "")) {
            invalid = false;
          }
          //1. unique and validate constraint will not handle empty string scenario, so it's handle here
          //2. if value is false --> false is a valid value for boolean controller
          if (("" === value || value == null) && value !== false) {
            invalid = true;
            errorComp = (<ErrorMsg errorMsg={i18n.t('ColumnIsRequired', { columnTitle })} />);
          }
          callback?.(!invalid);
          if (errorComp == null) {
            return Promise.resolve();
          }
          return Promise.reject(errorComp);
        }
      };
    case 'min':
    case 'max':
      return {
        validator: (_: RuleObject, value: StoreValue) => {
          let invalid = false;
          switch (constraintKey) {
            case 'max':
              invalid = (constraintValue != null && value > constraintValue);
              break;
            case 'min':
              invalid = (constraintValue != null && value < constraintValue);
              break;
          }
          callback?.(!invalid);
          if (invalid) {
            const errorMsg = i18n.t('NumberExceedValidationFailed', { key: constraintKey, value: constraintValue });
            const errorComponent = (
              <ErrorMsg errorMsg={errorMsg} />
            );
            return Promise.reject(errorComponent);
          }
          return Promise.resolve();
        }
      };
  }
}

