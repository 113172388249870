import { CellComponentDisplayPage } from '@props/RecordProps';
import { Drawer, Tag } from 'antd';
import React, { ReactElement, Suspense, useState } from 'react';
import { BugOutlined } from '@ant-design/icons';
import { emptyMethod } from '@utils/Constants';
import CodeEditor from '../fields/CodeEditor';
import { useTranslation } from 'react-i18next';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { isBlank } from '@utils/StringUtils';

export interface StacktraceCellProps {
  zIndex: number;
  value?: string;
  page?: CellComponentDisplayPage;
}

const StacktraceCell = (props: StacktraceCellProps): ReactElement => {
  const { zIndex, value } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  return (isBlank(value))? (<></>) : (
    <>
      <Tag
        onClick={() => setVisible(true)}
        icon={<BugOutlined style={{color: "red"}}/>}
        style={{cursor: "pointer", borderRadius: "4px"}}
      >
        {t('Exception')}
      </Tag>
      <Drawer
        title={undefined}
        width={1200}
        rootStyle={{ zIndex: zIndex + 2}}
        rootClassName="action-button-popover-container stacktrace-drawer"
        open={visible}
        closeIcon={undefined}
        headerStyle={{ display: "none" }}
        onClose={() => setVisible(false)}
      >
        <pre
          className="line-numbers code-cell"
          onClick={(event: React.MouseEvent<unknown>) => stopPropagationAndPreventDefault(event)}
        >
          <Suspense fallback={<div />}>
            <CodeEditor
              value={value ?? ""}
              onChange={emptyMethod}
              name={"code"}
              updatable={false}
              width="100%"
              mode={"json"}
              zIndex={zIndex}
            />
          </Suspense>
        </pre>
      </Drawer>
    </>
  );
};

export default StacktraceCell;
