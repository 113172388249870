import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataNode } from 'antd/lib/tree';
import { MasterDetailLayout } from '../../layout';
import {
  fetchCanCreate,
  fetchDomainMeta,
  fetchFormExtInfo,
  fetchFormIdAndExtInfoByType,
  fetchListOfDomainData,
  fetchSearchResult,
  fetchTree,
  fetchCurrentValueNoRelationshipColumns,
  fetchFormIdAndExtInfoByName
} from '@utils/FetchUtils';
import {
  DataApiResultProps,
  FormExtInfoProps,
  FormProps,
  RecordProps,
  TableMetaProps,
  ExtendDataNode,
} from '@props/RecordProps';
import TreeComponent from './TreeComponent';
import {
  PageHeader, FullTextSearchListComponent, UpdateComponent,
  SimpleListComponent
} from '../../form';
import {
  MasterCollapseWidth, MasterDetailExpandWidth,
  MasterDetailListMasterListWidth, MasterDetailListMasterTreeWidth
} from '@config/base';
import './App.less';
import { DetailCollapse, LargeSpin, getPagination } from '../../components';
import { Button, Empty, message, Pagination, Result, Space } from 'antd';
import { emptyMethod } from '@utils/Constants';
import { getSearchConditionsForDomain } from "@kernel/ServerSideSearcher";
import { initPagination, paginationReducer, dataReducer, initData } from '@utils/hooks';
import { hasChildrenField } from '@utils/ColumnsUtils';
import {
  buildAllNodesFlat, getSelfAndAllChildrenKeys, mergeChildrenIntoTree, recursionChangeNodeInfo
} from '@utils/TreeUtils';
export interface MasterDetailListProps {
  formId?: number;
  domainName: string;
  initShowCreateModal?: boolean;
  initDomainId?: number;
  initSelectedId?: number;
  zIndex: number;
  extInfo?: FormExtInfoProps;
}

export interface LoadLeftPanelDataProps {
  forceRefresh: boolean;
  isDnd?: boolean;
  pExtInfo: FormExtInfoProps;
  pUseTree?: boolean;
  detailForm: FormProps;
  masterForm?: FormProps;
  current?: number;
  pageSize?: number;
  showMessage?: boolean;
  nodeKey?: string | number;
  treeData?: ExtendDataNode[];
}

type onSelectType = ((selectedKeys: Array<number>, extInfo: FormExtInfoProps, detailForm: FormProps) => void);

const MasterDetailListComponent = (props: MasterDetailListProps): ReactElement => {
  const {
    zIndex, formId, domainName, initDomainId, initShowCreateModal, initSelectedId,
  } = props;
  const [prevDomainName, setPrevDomainName] = useState<string>();
  const [useTree, setUseTree] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [rightContent, setRightContent] = useState<ReactElement>(<></>);
  const [currentRecordIds, setCurrentRecordIds] = useState<Array<number>>(initSelectedId ? [initSelectedId] : []);
  const [masterWidth, setMasterWidth] = useState<number>(MasterDetailListMasterTreeWidth);
  const [layoutForm, setLayoutForm] = useState<FormProps>();
  const [detailColumn, setDetailColumn] = useState<TableMetaProps>();
  const [detailForm, setDetailForm] = useState<FormProps>({} as FormProps);
  const [masterForm, setMasterForm] = useState<FormProps>({} as FormProps);
  const [leftLoading, setLeftLoading] = useState<boolean>(true);
  const [hideListHeader, setHideListHeader] = useState<boolean>(false);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer, undefined, () => initPagination("simple-list"));
  const [dataState, dataDispatch] = useReducer(dataReducer, initData);
  /** 是否允许左边的 tree 控件多选 */
  const [multipleTree, setMultipleTree] = useState<boolean>(false);
  const detailColumnRef = useRef<TableMetaProps>();

  let onSelect: onSelectType = emptyMethod;

  const { t } = useTranslation();

  useEffect(() => {
    dataDispatch({ type: "set", payload: { treeData: [] } });
    fetchCanCreate(domainName).then(json => {
      setCanCreate(json.create);
    }).catch(e => console.error(`Failed to get canCreate of ${domainName}: ${e}`));
  }, [domainName]);

  // 当前展开的 panel
  const [expandCol, setExpandCol] = useState<"master" | "detail">("master");

  const getDetailDomainName = useCallback((col?: TableMetaProps) => {
    const detailDomainName: string | undefined = (col != null) ?
      ((col.type === 'array') ? col.elementType : col.type) : domainName;
    if (detailDomainName == null) {
      console.warn(`detailDomainName is null on ${JSON.stringify(col)} extInfo, will use ${domainName} by default, this might not the desire behaivor`);
    }
    return detailDomainName ?? domainName;
  }, [domainName]);

  const setFullTextSearchListRightPanel = useCallback((selectedKeys: Array<number>,
    pDetailForm: FormProps, pDetailColumn?: TableMetaProps): void => {
    // 如果后台没有传递 pDetailColumn 则默认的设置 detailDomainName 为当前显示的 domain
    const allSelectedKeys = (dataState.treeData ?? []).length == 0 ?
      selectedKeys : getSelfAndAllChildrenKeys(dataState.treeData ?? [], selectedKeys);
    const detailDomainName: string | undefined = getDetailDomainName(pDetailColumn ?? detailColumnRef.current);
    setRightContent(
      (detailDomainName != null) ?
        <FullTextSearchListComponent
          hideHeaderTitle={true}
          zIndex={zIndex}
          ownerClass={domainName}
          ownerIds={allSelectedKeys}
          domainName={detailDomainName}
          formId={pDetailForm.id}
          ownerClassColumnName={detailColumn?.backReferenceField}
          setOwnerIds={(ownerIds: Array<number>) => {
            const df = ((pDetailForm ?? detailForm) ?? {}) as FormProps;
            onSelect(ownerIds, {
              ...df.extInfo,
              detailFormType: 'INLINE_FULL_TEXT_SEARCH_LIST'
            }, df);
          }}
        /> : (<Result
          status="warning"
          subTitle={t(`${domainName} Form ${formId} configuration error.`)}
          extra={<Space>
            <Button type="primary" onClick={emptyMethod}>Report a Bug</Button>
          </Space>}
        />)
    );
  }, [
    detailColumn, domainName, formId, t, zIndex, getDetailDomainName, dataState.treeData, onSelect, detailForm
  ]);

  const setDefaultRightContent = useCallback((pExtInfo?: FormExtInfoProps,
    pDetailForm?: FormProps, pDetailColumn?: TableMetaProps) => {
    if (pExtInfo == null || pDetailForm == null) {
      return;
    }
    if (pExtInfo?.detailFormType === 'INLINE_FULL_TEXT_SEARCH_LIST') {
      setMultipleTree(true);
      setFullTextSearchListRightPanel([], pDetailForm, pDetailColumn);
    } else if (pExtInfo?.detailFormType === 'Update') {
      setRightContent(<Empty
        description={t('Please select a record on left panel')}
      />);
    }
  }, [t, setFullTextSearchListRightPanel]);

  const loadTreeData = useCallback(async (props: LoadLeftPanelDataProps): Promise<void> => {
    // eslint-disable-next-line react/prop-types
    const { forceRefresh, isDnd, nodeKey, treeData, pExtInfo } = props;
    // eslint-disable-next-line react/prop-types
    const { conditions } = pExtInfo ?? {};
    const searchConditions = (conditions == null) ? getSearchConditionsForDomain(domainName) :
      { ...getSearchConditionsForDomain(domainName), ...conditions };
    const tData = (treeData as ExtendDataNode[] ?? []);
    // Pass -1 to load root node of the tree
    return fetchTree(domainName, (nodeKey ?? -1), searchConditions, !forceRefresh)
      .then(json => {
        if (nodeKey == null) {
          dataDispatch({ type: "set", payload: { treeData: json } });
        } else {
          const newTreeData = mergeChildrenIntoTree([...tData], nodeKey, json as DataNode[]) as ExtendDataNode[];
          // 如果是刷新其中一个节点，那么同时去后台获取该节点的最新信息并刷新
          const id = Number.parseInt(nodeKey.toString());
          const flatData: Array<ExtendDataNode> = [];
          buildAllNodesFlat(newTreeData, flatData);
          return fetchCurrentValueNoRelationshipColumns(domainName, id, false).then(val => {
            const ct = flatData.find(t => t.id === val.id)?.title;
            const isNotCurrentNode = (ct !== val.title && ct !== val.label && ct !== val.name);
            const td = isNotCurrentNode ? recursionChangeNodeInfo([...newTreeData], id, val.title || val.name || val.label) : newTreeData;
            dataDispatch({ type: "set", payload: { treeData: td } });
          });
        }
        if (forceRefresh && !isDnd) {
          message.success(t('Data refreshed'));
        }
        return new Promise<void>(resolve => resolve());
      }).finally(() => setLeftLoading(false))
      .catch(e => console.error(`Failed to get tree for ${domainName}: ${e}`));
  }, [domainName, t]);

  const loadListData = useCallback((props: LoadLeftPanelDataProps): void => {
    const {
      // eslint-disable-next-line react/prop-types
      forceRefresh, pExtInfo, detailForm, current, pageSize, showMessage
    } = props;
    //如果已经有 listData, 且请求不是强制刷新, 则不再请求
    if ((dataState.data != null && dataState.data?.length > 0) && forceRefresh === false) {
      return;
    }
    const setState = (json: DataApiResultProps): void => {
      const { data, total } = json;
      paginationDispatch({ type: 'set', payload: { total } });
      setLeftLoading(false);
      dataDispatch({ type: "set", payload: { data } });
      if (data.length === 0) {
        return;
      }
      // 是否有需要特殊标记的条目
      let hasShouldMarkRow = false;
      data?.some(row => {
        const { id } = row;
        //默认设置为第一个需要特殊标记的条目，
        //如未读条目
        if (row.id === initSelectedId) {
          onSelect([id], pExtInfo, detailForm);
          hasShouldMarkRow = true;
          return true;
        } else if (row.isUnRead === true && initSelectedId == null) {
          onSelect([id], pExtInfo, detailForm);
          hasShouldMarkRow = true;
          return true;
        }
      });
      // 如果没有需要标记的行，默认显示第一行
      if (!hasShouldMarkRow && data.length > 0) {
        onSelect([data[0].id], pExtInfo, detailForm);
      }
      if (forceRefresh && showMessage !== false) {
        message.success(t('Data refreshed'));
      }
    };
    // eslint-disable-next-line react/prop-types
    const extInfoConditionsIsNull = (pExtInfo?.conditions == null);
    const searchConditions = extInfoConditionsIsNull ?
      // eslint-disable-next-line react/prop-types
      getSearchConditionsForDomain(domainName) : { ...getSearchConditionsForDomain(domainName), ...pExtInfo.conditions };
    if (searchConditions == null) {
      fetchListOfDomainData({
        domainName,
        current: current ?? paginationState.current,
        max: pageSize ?? paginationState.pageSize,
        useCache: !forceRefresh,
      }).then(json => {
        setState(json);
      }).catch(e => console.error(`Failed to get tree for ${domainName}: ${e}`));
    } else {
      fetchSearchResult({
        domainName,
        searchConditions,
        current: current ?? paginationState.current,
        max: pageSize ?? paginationState.pageSize,
      }).then(json => {
        setState(json);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState.data, t, domainName]);

  const extInfoProps = useMemo(() => {
    return layoutForm?.extInfo ?? {} as FormExtInfoProps;
  }, [layoutForm]);

  const treeComponent = useMemo(() => (<TreeComponent
    fetchDataCallback={(nodeKey?: string | number): Promise<void> => loadTreeData({
      forceRefresh: true,
      isDnd: true,
      pExtInfo: extInfoProps,
      detailForm,
      nodeKey,
      treeData: dataState.treeData,
    })}
    // 点击复选框触发
    onCheck={(checkedNodes: Array<DataNode>) => {
      const sd: Array<RecordProps> = checkedNodes.map(node => {
        return {
          id: parseInt(node.key.toString()),
          name: node.title
        };
      });
      dataDispatch({ type: "set", payload: { selectedData: sd } });
    }}
    dataDispatch={dataDispatch}
    columns={columns}
    domainName={domainName}
    data={dataState.treeData ?? []}
    // 点击节点标题触发
    onSelect={(selectedKeys: Array<number>) => {
      onSelect(selectedKeys, extInfoProps, detailForm);
    }}
    collapseMasterCallback={() => {
      if (masterWidth > MasterDetailListMasterTreeWidth) {
        setMasterWidth(masterWidth - MasterDetailExpandWidth);
      } else {
        setExpandCol('detail');
        setHideListHeader(true);
      }
    }}
    expandMasterCallback={
      () => {
        setMasterWidth(masterWidth + MasterDetailExpandWidth);
        if (hideListHeader) {
          setHideListHeader(false);
        }
      }
    }
    zIndex={zIndex}
    multiple={multipleTree}
    canCreate={canCreate}
    layoutForm={layoutForm}
  />), [
    canCreate, columns, detailForm, domainName, extInfoProps,
    hideListHeader, loadTreeData, masterWidth, layoutForm,
    multipleTree, dataState.treeData, zIndex, onSelect
  ]);

  const simpleListComponent = useMemo(() => (<>
    <SimpleListComponent
      onClick={(record: RecordProps) => onSelect([record.id], extInfoProps, detailForm)}
      onSelect={(records: Array<RecordProps>) => dataDispatch({ type: "set", payload: { selectedData: records } })}
      domainName={domainName}
      data={dataState.data ?? []}
      initSelectedId={currentRecordIds?.[0]}
      columns={columns}
      fetchDataCallback={() => {
        loadListData({ forceRefresh: true, pExtInfo: extInfoProps, detailForm });
      }}
      zIndex={zIndex}
    />
    <Pagination
      {...getPagination({
        ...paginationState,
        showLessItems: true,
        showQuickJumper: false,
        showSizeChanger: true,
      })}
      onChange={(current: number, pageSize?: number) => {
        paginationDispatch({ type: 'set', payload: { current, pageSize } });
        loadListData({
          forceRefresh: true, pExtInfo: extInfoProps, detailForm,
          current, pageSize, showMessage: false
        });
      }}
    />
  </>), [
    columns, currentRecordIds, detailForm, domainName, onSelect,
    extInfoProps, dataState.data, loadListData, paginationState, zIndex
  ]);

  const formTypeLeftPanelConfigMapping: {
    [propName: string]: {
      leftContent: ReactElement;
      className: string;
      loadDataCallback: (props: LoadLeftPanelDataProps) => void;
    }
  } = useMemo(() => {
    return {
      "TREE": {
        leftContent: treeComponent,
        className: "master-detail-tree-container",
        loadDataCallback: loadTreeData,
      },
      "INLINE_SIMPLE_LIST": {
        leftContent: simpleListComponent,
        className: 'master-detail-simple-container',
        loadDataCallback: loadListData,
      },
    };
  }, [
    loadListData, loadTreeData,
    simpleListComponent, treeComponent
  ]);

  const getLoadLeftDataCallback = useCallback((pUseTree: boolean, pFormProps?: FormProps):
    ((props: LoadLeftPanelDataProps) => void) => {
    const masterFormType = (pUseTree ?? useTree) ? "TREE" :
      ((pFormProps ?? layoutForm)?.extInfo?.masterFormType);
    return formTypeLeftPanelConfigMapping[masterFormType ?? ""]?.loadDataCallback ?? emptyMethod;
  }, [formTypeLeftPanelConfigMapping, useTree, layoutForm]);

  const getLeftContent = useCallback((): ReactElement => {
    const masterFormType = useTree ? "TREE" : (layoutForm?.extInfo?.masterFormType);
    return formTypeLeftPanelConfigMapping[masterFormType ?? ""]?.leftContent ?? (
      <span style={{ padding: "20px" }}>
        {t('Not supported left panel form type or user have no permission')}
      </span>
    );
  }, [formTypeLeftPanelConfigMapping, layoutForm, useTree, t]);

  const getLeftContentClassName = useCallback((): string => {
    const masterFormType = useTree ? "TREE" : (layoutForm?.extInfo?.masterFormType);
    return formTypeLeftPanelConfigMapping[masterFormType ?? ""]?.className ?? "";
  }, [formTypeLeftPanelConfigMapping, layoutForm, useTree]);

  onSelect = useCallback((selectedKeys: Array<number>,
    pExtInfo: FormExtInfoProps, pDetailForm: FormProps): void => {
    setCurrentRecordIds(selectedKeys);
    if (selectedKeys.length === 0) {
      setDefaultRightContent(pExtInfo, pDetailForm ?? detailForm, detailColumn);
      return;
    }
    // eslint-disable-next-line react/prop-types
    const { detailFormType, detailUpdatable } = pExtInfo;
    const isUsingUpdateComponent = ['Update', 'INLINE_DISPLAY'].includes(detailFormType ?? "");
    const isInlineFullTextSearchList = (detailFormType === 'INLINE_FULL_TEXT_SEARCH_LIST');
    if (selectedKeys.length > 0 && isUsingUpdateComponent) {
      setRightContent(
        <UpdateComponent
          formId={pDetailForm?.id}
          domainName={domainName}
          id={selectedKeys[0]}
          zIndex={zIndex}
          readonly={!(detailUpdatable ?? true)}
          showBottomSaveButton={detailUpdatable ?? true}
          hideHeaderTitle={true}
          triggerSave={false}
          callback={() => {
            getLoadLeftDataCallback(useTree)({
              forceRefresh: true, isDnd: false, pExtInfo: extInfoProps, detailForm, masterForm,
              treeData: dataState.treeData, nodeKey: selectedKeys[0]
            });
          }}
          hideHeader={false}
          page="master-detail"
        />
      );
    } else if (isInlineFullTextSearchList) {
      setFullTextSearchListRightPanel(selectedKeys, pDetailForm, detailColumnRef.current);
    }
  }, [
    detailColumn, domainName, setDefaultRightContent, zIndex, detailForm, masterForm,
    extInfoProps, setFullTextSearchListRightPanel, getLoadLeftDataCallback, useTree,
    dataState.treeData
  ]);

  //如果 form 的字段定义中有 children 字段，则默认使用 tree
  //如果 form 的字段定义中没有 children 字段，则默认使用 list
  useEffect(() => {
    // 如果已经从后台读取到了 columns，或者正在读取，且 domainName 没有变化，则直接返回
    if ((columns.length > 0 || leftLoading === true) && prevDomainName === domainName) {
      return;
    }
    setPrevDomainName(domainName);
    setLeftLoading(true);
    const fetchFormResult = formId ?
      fetchFormExtInfo(formId) : fetchFormIdAndExtInfoByType(domainName, "MASTER_DETAIL_LIST");
    fetchFormResult.then((formProps: FormProps) => {
      const { id, extInfo } = formProps;
      setLayoutForm(formProps);
      dataDispatch({ type: "set", payload: { refreshMode: formProps.extInfo?.dataRefreshMode } });
      fetchDomainMeta(domainName, id).then((meta: Array<TableMetaProps>) => {
        setColumns(meta);
        const hasChildren = hasChildrenField(meta);
        //如果有 key 为 children 的列，则默认显示为 tree 控件
        setUseTree(hasChildren);
        setMasterWidth(hasChildren ?
          MasterDetailListMasterTreeWidth : MasterDetailListMasterListWidth);
        // 根据 extInfo 中的 detailField 属性设置 detail column
        const dCandidate: TableMetaProps[] = meta.filter(c => c.key === extInfo?.detailField);
        const lDetailColumn = dCandidate[0];
        if (dCandidate.length > 0) {
          setDetailColumn(lDetailColumn);
          detailColumnRef.current = lDetailColumn;
        } else {
          console.info("No detail column by detailField: [",
            extInfo?.detailField,
            "] from columns: ", meta,
            " will render the master object [", domainName, "] directly",
            " please confirm server side form field or request map configuration",
            " domainName: ", domainName, ", formId: ", id);
        }
        const dc = (dCandidate.length > 0) ? lDetailColumn : undefined;
        // 如果 extInfo 中没有 detailField, 默认是显示当前 domain 的 form
        const detailDomainName = getDetailDomainName(dc);
        const initDetailFormAndLeftData = (lMasterForm: FormProps): void => {
          const getDetailFormInfoFunc = (extInfo?.detailFormName != null) ?
            fetchFormIdAndExtInfoByName : ((extInfo?.detailFormType != null) ? fetchFormIdAndExtInfoByType : null);
          const getDetailFormInfoParam = (extInfo?.detailFormName != null) ?
            extInfo?.detailFormName : ((extInfo?.detailFormType != null) ? extInfo?.detailFormType : null);
          if (getDetailFormInfoFunc != null && getDetailFormInfoParam != null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getDetailFormInfoFunc(detailDomainName, getDetailFormInfoParam).then(lDetailForm => {
              setDetailForm(lDetailForm);
              setDefaultRightContent(extInfo, lDetailForm, lDetailColumn);
              const param = {
                forceRefresh: false, isDnd: false, pExtInfo:
                  formProps.extInfo ?? {} as FormExtInfoProps,
                detailForm: lDetailForm,
                masterForm: lMasterForm,
                pUseTree: hasChildren
              } as LoadLeftPanelDataProps;
              getLoadLeftDataCallback(hasChildren, formProps)(param);
            }).catch(e => console.error(`Failed to get formId of ${detailDomainName} type ${extInfo?.detailFormType} : ${e}`));
          }
        };
        if (detailDomainName != null) {
          if (extInfo?.masterFormType != null) {
            fetchFormIdAndExtInfoByType(detailDomainName, extInfo?.masterFormType).then((lMasterForm: FormProps) => {
              setMasterForm(lMasterForm);
              initDetailFormAndLeftData(lMasterForm);
            });
          } else {
            initDetailFormAndLeftData({} as FormProps);
          }
        }
      }).catch(e => console.error(`Failed to get form ${JSON.stringify(formProps)} for domain ${domainName}: ${e}`))
        .finally(() => setLeftLoading(false));
    }).catch(e => console.error(`Failed to get master detail form for ${domainName}: ${e}`));
  }, [
    prevDomainName, domainName, layoutForm, setDefaultRightContent, leftLoading, columns,
    getLoadLeftDataCallback, formId, getDetailDomainName
  ]);

  const collapsedMasterPanel = (
    <DetailCollapse
      domainName={domainName ?? ""}
      label={t(`domainTitle:${domainName}`)}
    />);

  const flex = (expandCol === 'master') ?
    masterWidth : MasterCollapseWidth + 15;

  const className = `master-detail-container ${getLeftContentClassName()}`;
  const masterExpand = (expandCol === 'master');
  const detailFormType = layoutForm?.extInfo?.detailFormType?.toLocaleLowerCase();
  const leftContent = getLeftContent();
  const showPageHeader = (masterExpand &&
    (!['INLINE_FULL_TEXT_SEARCH_LIST', 'INLINE_SEARCH_CONDITIONS_LIST'].includes(layoutForm?.extInfo?.masterFormType ?? "")));
  const readOnlyClassName = extInfoProps?.detailUpdatable ? 'detail-updatable' : 'detail-readonly';
  return (
    <div className={`${className} ${readOnlyClassName}`}>
      {showPageHeader && <PageHeader
        hide={hideListHeader}
        displayLabel={false}
        initDomainId={initDomainId}
        initShowCreateModal={initShowCreateModal}
        listForm={layoutForm ?? {} as FormProps}
        tableMode={'simple-list'}
        domainName={domainName}
        data={[]}
        selectedData={dataState.selectedData ?? []}
        columns={columns}
        enumValues={{}}
        objectValues={{}}
        setData={emptyMethod}
        fetchDataCallback={() => {
          getLoadLeftDataCallback(useTree)({
            forceRefresh: true, isDnd: false, pExtInfo: extInfoProps, detailForm, masterForm,
            treeData: dataState.treeData
          } as LoadLeftPanelDataProps);
        }}
        initialCanCreate={canCreate}
        zIndex={zIndex + 1}
        dataState={dataState}
        dataDispatch={dataDispatch}
        createFormName={layoutForm?.extInfo?.createFormName}
      />
      }
      <MasterDetailLayout
        flex={`${flex}px`}
        leftContent={leftLoading ? <LargeSpin /> : ((expandCol === 'master') ?
          leftContent : collapsedMasterPanel)}
        leftClassName={`${detailFormType}-left-panel ${expandCol}`}
        rightClassName={`${detailFormType}-right-panel ${expandCol}`}
        rightContent={rightContent}
        leftOnClick={() => {
          setExpandCol("master");
          setHideListHeader(false);
        }}
        rightVisible={true}
      />
    </div>
  );
};

export default MasterDetailListComponent;
