import React, { Suspense, useEffect, useRef } from "react";
import { Divider, FormInstance, Input, List, Tooltip } from "antd";
import { fetchFunctions, FunctionInfo } from "./FunctionEditorUtils";
import { SaveRecordProps, Store, TableMetaProps } from "@props/RecordProps";
import { useResizeDetector } from "react-resize-detector";
import { CodeEditorRef } from "../../form/fields/CodeEditor";
import "./FunctionEditor.css";
import { SearchOutlined } from "@ant-design/icons";


const CodeEditor = React.lazy(() => import('../../form/fields/CodeEditor'));

export interface FunctionEditorProps {
  code: string;
  record?: SaveRecordProps | undefined;
  column: TableMetaProps;
  form?: FormInstance;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  zIndex: number;
}

export const FunctionEditor: React.FC<FunctionEditorProps> = (props) => {
  const {
    code, form, column, onValuesChange,
    record, zIndex
  } = props;
  const { key, extInfo } = column;
  const [functions, setFunctions] = React.useState<FunctionInfo[]>([]);
  const [filteredFunctions, setFilteredFunctions] = React.useState<FunctionInfo[]>([]);
  const { width, ref } = useResizeDetector();
  const [pattern, setPattern] = React.useState<string>('tech.muyan.function.DynamicFunctionService.invoke("FUNCTION_NAME", params)');
  const codeRef = useRef<CodeEditorRef>(null);

  const parentWidth = (width ?? 440);
  const listWidth = parentWidth / 5;
  const codeWidth = parentWidth - listWidth - 10;

  useEffect(() => {
    fetchFunctions().then((res) => {
        setFunctions(res.functions);
        setPattern(res.pattern);
        setFilteredFunctions(res.functions);
      }
    );
  }, []);

  return <div
    ref={ref}
    className='function-editor'
  >
    <div
      style={{
        width: listWidth,
      }}
    >
      <div className='function-editor-candidate'>
        <div style={{
          paddingLeft: 5,
          paddingRight: 5,
        }}>
          <Input
            prefix={<SearchOutlined/>}
            onChange={(e) => {
              const keyword = e.target.value.toLowerCase();
              setFilteredFunctions(functions.filter(f => f.name.toLowerCase().includes(keyword)));
            }}
          />
        </div>
        <Divider/>
        <List
          className="function-editor-candidate-list"
          dataSource={filteredFunctions}
          renderItem={(item) =>
            <List.Item>
              <Tooltip title={item.description}>
                <span
                  onClick={() => codeRef.current?.insertText(pattern.replace("FUNCTION_NAME", item.name))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {item.name}
                </span>
              </Tooltip>
            </List.Item>
          }
          style={{
            backgroundColor: '#ffffff',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        />
      </div>
    </div>
    <Suspense fallback={<div/>}>
      <div style={{
        paddingLeft: 10,
      }}>
        <CodeEditor
          value={code}
          onChange={(val) => {
            const changedValue = { [column.key]: val };
            const newValue = { ...record, ...changedValue };
            form?.setFieldsValue(changedValue);
            onValuesChange?.(changedValue, newValue);
          }}
          name={key}
          width="99%"
          updatable={true}
          style={{
            width: codeWidth,
            margin: "auto",
            paddingLeft: 10,
          }}
          mode={extInfo?.codeLanguage}
          zIndex={zIndex}
          record={record}
          ref={codeRef}
        />
      </div>
    </Suspense>
  </div>;
};