import React from "react";
import { DashboardWidgetType } from "@props/RecordProps";
import { PrimaryColor } from '@config/base';
import { ReactElement } from "react";
import { WidgetComponentRenderProps } from "./WidgetComponentsMapping";
import {
  AntdChartWidget, DataTableWidget, MarkdownWidget, StatisticWidget,
  CountdownWidget, HtmlWidget
} from "./widgets";

const Bar = React.lazy(() => import('../../components/wrap/BarWrap'));
const Area = React.lazy(() => import('../../components/wrap/AreaWrap'));
const Line = React.lazy(() => import('../../components/wrap/LineWrap'));
const Column = React.lazy(() => import('../../components/wrap/ColumnWrap'));
const Pie = React.lazy(() => import('../../components/wrap/PieWrap'));
const Gauge = React.lazy(() => import('../../components/wrap/GaugeWrap'));
const Liquid = React.lazy(() => import('../../components/wrap/LiquidWrap'));
const Bullet = React.lazy(() => import('../../components/wrap/BulletWrap'));
const Progress = React.lazy(() => import('../../components/wrap/ProgressWrap'));
const RingProgress = React.lazy(() => import('../../components/wrap/RingProgressWrap'));
const TinyArea = React.lazy(() => import('../../components/wrap/TinyAreaWrap'));
const TinyLine = React.lazy(() => import('../../components/wrap/TinyLineWrap'));
const TinyColumn = React.lazy(() => import('../../components/wrap/TinyColumnWrap'));
const BidirectionalBar = React.lazy(() => import('../../components/wrap/BidirectionalBarWrap'));
const Histogram = React.lazy(() => import('../../components/wrap/HistogramWrap'));

export interface WidgetConfigProps {
  type: DashboardWidgetType;
  component: (props: WidgetComponentRenderProps) => ReactElement;
}

// 图表中的默认填充色
const defaultFillColor = "#ECE3F6";
// 图表中的默认坐标轴颜色
const defaultStrokeColor = '#D0D0D0';
// 图表中的默认显示颜色
const defaultExtraProps = { color: PrimaryColor };

export const WidgetConfigures: Array<WidgetConfigProps> = [
  { type: "STATISTIC", component: StatisticWidget },
  { type: "HTML", component: HtmlWidget},
  { type: "MARKDOWN", component: MarkdownWidget },
  { type: "DATA_TABLE", component: DataTableWidget },
  { type: "COUNTDOWN", component: CountdownWidget },
  {
    type: "GAUGE_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: Gauge, extraProps: {
        range: { color: `l(0) 0:${defaultFillColor} 1:${PrimaryColor}` },
        indicator: {
          pointer: { style: { stroke: defaultStrokeColor } },
          pin: { style: { stroke: defaultStrokeColor } },
        },
        statistic: {
          content: {
            style: {
              fontSize: '18px',
              lineHeight: '18px',
            },
          },
        },
        style: {
          textContent: (target: number, total: number) => `${Math.round((target / total) * 10000) / 100}%`,
        },
      }
    })
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "LIQUID_CHART", component: AntdChartWidget({ Component: Liquid, extraProps: { color: "#B8E2FC" } }) },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "LINE_CHART", component: AntdChartWidget({ Component: Line, extraProps: defaultExtraProps }) },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "AREA_CHART", component: AntdChartWidget({ Component: Area, extraProps: defaultExtraProps }) },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "BAR_CHART", component: AntdChartWidget({ Component: Bar }) },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "COLUMN_CHART", component: AntdChartWidget({ Component: Column, extraProps: defaultExtraProps }) },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  { type: "PIE_CHART", component: AntdChartWidget({ Component: Pie }) },
  {
    type: "BULLET_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: Bullet, extraProps: {
        color: {
          range: '#f0efff',
          measure: PrimaryColor,
          target: '#3D76DD',
        },
      }
    })
  },
  {
    type: "PROGRESS_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: Progress, extraProps: {
        color: [PrimaryColor, '#E8EDF3'],
        style: { marginTop: "46%" }
      }
    })
  },
  {
    type: "RING_PROGRESS_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: RingProgress, extraProps: {
        color: [PrimaryColor, '#E8EDF3'],
        statistic: {
          title: {
            style: {
              color: PrimaryColor,
              fontSize: '12px',
              lineHeight: '14px',
            },
            formatter: function formatter() {
              return '进度';
            },
          },
        },
      }
    })
  },

  {
    type: "TINY_AREA_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: TinyArea, extraProps: {
        autoFit: true,
        smooth: true,
        areaStyle: { fill: defaultFillColor },
        line: { ...defaultExtraProps },
      }
    })
  },
  {
    type: "TINY_LINE_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: TinyLine, extraProps: {
        autoFit: true,
        smooth: true,
        ...defaultExtraProps
      }
    })
  },
  {
    type: "TINY_COLUMN_CHART", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Component: TinyColumn, extraProps: {
        autoFit: true,
        ...defaultExtraProps,
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
          customContent: function customContent(x: number, data: any) {
            return data?.[0]?.data?.y?.toFixed(2);
          }
        },
      }
    })
  },
  {
    type: "BI_DIRECTION_BAR", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      Component: BidirectionalBar, extraProps: {
        xAxis: { position: 'bottom' },
        interactions: [{ type: 'active-region' }],
        tooltip: {
          shared: true,
          showMarkers: true
        }
      }
    })
  },
  {
    type: "HISTOGRAM", component: AntdChartWidget({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      Component: Histogram, extraProps: {
        binField: 'value',
        binWidth: 2,
        tooltip: {
          showMarkers: true,
          position: 'top',
        },
        interactions: [{ type: 'element-highlight' }],
        color: PrimaryColor,
      }
    })
  }

];
