import React, { ReactElement } from 'react';
import { DoubleRightOutlined, ExperimentOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Popconfirm, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DataCollectForm } from '../';
import { emptyMethod } from '@utils/Constants';
import {
  ActionExecResult, ActionProps, MultipleExecResults, RecordProps, TableMetaProps
} from '@props/RecordProps';
import { wrapAsHtml } from '@utils/ComponentUtils';
import { ObjectCell } from "../../form/cells";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";

export type ActionComponentProps = {
  action: ActionProps;
  executeCallback: (isFinalRound: boolean) => void;
  element: ReactElement;
  zIndex: number;
  setVisiblePopoverCallback: (key?: string) => void;
  setResults: (results: MultipleExecResults | ActionExecResult) => void;
  parameters?: Array<TableMetaProps>;
  formValues?: RecordProps;
  setFormValues: (value: RecordProps) => void;
  selectedData?: Array<RecordProps>;
  domainName: string;
  labelField?: string;
  loadingParameters?: boolean;
};

const ActionComponent = (props: ActionComponentProps): ReactElement => {
  const {
    action, zIndex, executeCallback, setResults, parameters, domainName,
    formValues, setFormValues, setVisiblePopoverCallback, selectedData,
    loadingParameters, labelField
  } = props;
  const { t } = useTranslation();
  const { id, helpText, confirmMessage, confirmType, supportFineTuning } = action;
  const [form] = Form.useForm();

  const validAndExecuteAction = (isFinalRound: boolean, event?: React.MouseEvent<HTMLElement>): void => {
    event?.preventDefault();
    form.validateFields().then(() => {
      executeCallback(isFinalRound);
    });
  };

  const needParameter = ((parameters?.length ?? 0) > 0);
  const isNoConfirmAction = (['NO_CONFIRM', 'NO_POPUP_NO_CONFIRM'].includes(confirmType));
  const isDisplayConfirmAction = (confirmType === 'DISPLAY_CONFIRM');
  const hasConfirmMessage = (confirmMessage != null && confirmMessage !== '');
  const hasSelectedData = ((selectedData?.length ?? 0) > 0);
  const objectsDisplay = selectedData?.map(item => {
    let label = undefined;
    if (labelField) {
      label = item[labelField];
    }
    return <ObjectCell
      domainName={domainName}
      displayText={label}
      id={item.id}
      zIndex={zIndex}
      key={item.id}
    />;
  });
  // const dryRunTitle = action.supportFineTuning ? t('Dry run') : t('Run');
  // const dryRunIcon = action.supportFineTuning ? <ExperimentOutlined /> : <DoubleRightOutlined />;
  // const dryRunButton = (<Space direction="horizontal">
  //   {dryRunTitle}
  //   {dryRunIcon}
  // </Space>);
  return (
    <div className="dmac-container">
      {!needParameter &&
        <Alert
          message={t("No parameters needed to run this action")}
          type="info"
          showIcon
          className="action-message-info"
        />
      }
      {hasSelectedData && (
        <Alert
          message={t('Selected objects')}
          type="info"
          className="action-target-objects-info action-message-info"
          showIcon={true}
          description={objectsDisplay}
        />
      )}
      {
        hasConfirmMessage && <Alert
          message={wrapAsHtml(confirmMessage)}
          type="info"
          className="action-message-info"
        />
      }
      {loadingParameters && <Spin className="para-loading-spin"/>}
      {!loadingParameters && needParameter &&
        <DataCollectForm
          page="action"
          labelAlign="left"
          onChange={(params: RecordProps) => {
            setFormValues(params);
          }}
          onFinishFailed={emptyMethod}
          onFinish={emptyMethod}
          operation="create"
          columns={parameters ?? []}
          domainName={""}
          hideDetailPanel={true}
          groups={[]}
          form={form}
          readonly={false}
          hideFields={[]}
          record={formValues}
          zIndex={zIndex}
          formType={"Action"}
        />
      }
      <div
        className="action-with-msg-run-button-container"
        title={helpText}
      >
        <Space size="middle">
          {supportFineTuning &&
            <Button
              title={t("Final run the action with current parameters and selected dry run result")}
              onClick={(e) => validAndExecuteAction(false, e)}
              icon={<ExperimentOutlined />}
              type="primary"
              size="middle"
            >
              {t('Run')}
            </Button>}
          {!supportFineTuning && isDisplayConfirmAction && <Popconfirm
            title={t("Confirm to run this action")}
            okText={t("Confirm")}
            cancelText={t("Cancel")}
            placement="top"
            onConfirm={() => validAndExecuteAction(true, undefined)}
            overlayStyle={{ zIndex: zIndex + 1 }}
          >
            <Button
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                stopPropagationAndPreventDefault(e);
                //Hide other action popups
                setVisiblePopoverCallback(id.toString());
                // Reset result for other results
                setResults({});
              }}
              icon={<DoubleRightOutlined />}
              type="primary"
              size="middle"
            >
              {t("Run and save")}
            </Button>
          </Popconfirm>
          }
          {!supportFineTuning && isNoConfirmAction &&
            <Button
              onClick={(e) => validAndExecuteAction(true, e)}
              icon={<DoubleRightOutlined />}
              type="primary"
              size="middle"
            >
              {t("Run and save")}
            </Button>
          }
        </Space>
      </div>
    </div>
  );
};

export default ActionComponent;
