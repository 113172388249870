import React, { Dispatch, Key, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import {
  NodeCollapseOutlined, DoubleLeftOutlined, NodeExpandOutlined,
  DoubleRightOutlined, CheckCircleOutlined, MinusCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ExtendDataNode, TableMetaProps, RecordProps, FormProps } from '@props/RecordProps';
import { Space, Tree } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import TreeTitleRender, { TreeNodeOperation } from './TreeTitleRender';
import { PredefinedFilters } from "../../form";
import { emptyMethod } from '@utils/Constants';
import { saveDomain, fetchCurrentValueNoRelationshipColumns } from '@utils/FetchUtils';
import { SERVER_URL } from '@config/base';
import {
  buildAllNodesFlat, recursionRemoveNode, recursionAddNode, recursionChangeNodeInfo
} from '@utils/TreeUtils';
import { DataProps } from "@utils/hooks";
import { supportDnd } from '@utils/ComponentUtils';

export interface TreeProps {
  domainName: string;
  columns: Array<TableMetaProps>;
  data: Array<ExtendDataNode>;
  onSelect: (selectedKeys: Array<number>) => void;
  expandMasterCallback?: () => void;
  collapseMasterCallback?: () => void;
  onCheck: (checkedNodes: Array<DataNode>) => void;
  zIndex: number;
  fetchDataCallback: (nodeKey?: string | number) => Promise<void>;
  dataDispatch: Dispatch<{ type: "set"; payload: DataProps; }>;
  multiple?: boolean;
  canCreate: boolean;
  layoutForm?: FormProps;
}

// 树控件的默认高度是当前窗口的高度减去 120px,
// 这样正好可以在窗口中完整显示，下边预留一个细条
const FooterContentHeight = 120;

const bestTreeHeight = (window.innerHeight - FooterContentHeight);

/**
 * 展开模式， ea: expand all, ca: collapse all, uc: uncontrol
*/
type ExpandMode = "ea" | "ca" | "uc";

type callbackType = (node: ExtendDataNode, index: number, data: Array<ExtendDataNode>) => void;

const TreeComponent = (props: TreeProps): ReactElement => {
  const {
    domainName, zIndex, data, onSelect, collapseMasterCallback, onCheck,
    fetchDataCallback, expandMasterCallback, columns, multiple, canCreate,
    layoutForm
  } = props;
  const { t } = useTranslation();
  //ea: expand all, ca: collapse all, uc: uncontrol
  const [expandMode, setExpandMode] = useState<ExpandMode>("uc");
  const [flatNodes, setFlatNodes] = useState<Array<ExtendDataNode>>([]);
  const [selectIds, setSelectIds] = useState<Array<number>>([]);
  const pendingSaveNodes = useRef<Set<ExtendDataNode>>(new Set());
  const [dragging, setDragging] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<Array<string | number>>([]);
  const [loadedKeys, setLoadedKeys] = useState<Array<string | number>>([]);
  const [currentShowMenuOrModalNode, setCurrentShowMenuOrModalNode] = useState<ExtendDataNode>();
  // 是否可拖拽看是否有名称为 displaySequence 的列
  const draggable = supportDnd(columns);
  // 前端操作过后，前端的数据状态，可能和后台的数据状态不一致
  // 如删除和更新之后，前端会先更新前端的数据状态和 UI，然后再向后台发送请求
  const [dataState, setDataState] = useState<Array<ExtendDataNode>>([]);

  const loadData = ({ key, children, isLeaf }: EventDataNode<ExtendDataNode>): Promise<void> => {
    setLoading(true);
    return new Promise<void>(resolve => {
      if ((children?.length ?? 0) === 0 && (isLeaf === false)) {
        fetchDataCallback(key).then(() => {
          setLoadedKeys([...new Set([...loadedKeys, key])]);
          setLoading(false);
          resolve();
        });
      } else {
        setLoading(false);
        resolve();
      }
    });
  };

  // 是否正在从后台载入数据
  const [loading, setLoading] = useState<boolean>();

  /** 根据当前浏览器窗口大小设置高度 */
  const [height, setHeight] = useState<number>(bestTreeHeight);
  const updateDimensions = (): void => {
    setHeight(bestTreeHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const newFlatNodes: Array<ExtendDataNode> = [];
    buildAllNodesFlat(data, newFlatNodes);
    setFlatNodes(newFlatNodes);
    setLoading(false);
    setDataState(data);
  }, [data]);

  useEffect(() => {
    // 如果还在从后台载入某个节点的子节点数据，
    // 此时 data/flatNodes 数据不是最新的，
    // 此时设置 expandedKeys 会导致控件的数据和状态错误
    if (loading || flatNodes.length === 0) {
      return;
    }
    if (expandMode === 'uc') {
      return;
    }
    const isExpandAllMode = (expandMode === 'ea');
    const newExpandKeys: Array<string | number> = flatNodes
      //.filter(n => n.children.length > 0)
      .filter((n, idx, arr) => {
        // uc 模式下只自动展开之前展开的，有子节点的节点
        // ea 模式下，展开所有有子节点的节点
        // ca 模式下，关闭所有节点
        return (isExpandAllMode ? idx === arr.indexOf(n) : false);
      }).map(n => n.key);
    if (newExpandKeys.length === 0) {
      setLoadedKeys([]);
    }
    setExpandedKeys(newExpandKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, expandMode, flatNodes, loading]);

  const click = (expandMode: ExpandMode, event: React.MouseEvent<HTMLElement>): void => {
    stopPropagationAndPreventDefault(event);
    setExpandMode(expandMode);
    //收起树后，等待 200ms, 将 expandMode 设置为非受控的
    //这样用户再次点击某个节点，就可以正常展开其下级节点
    //不然第一次时，用户需要点击两次才会展开节点
    setTimeout(() => setExpandMode("uc"), 200);
  };

  const reorderCallback = (dataState: Array<ExtendDataNode>): void => {
    pendingSaveNodes.current.forEach((node: ExtendDataNode) => {
      const newParent = node.parent ? {
        key: node.parent,
        isLeaf: false
      } as ExtendDataNode : undefined;
      const oldNode = flatNodes.filter((n: ExtendDataNode) => n.id === node.id)[0];
      const removedOld = recursionRemoveNode([...dataState], oldNode);
      const addedNew = recursionAddNode([...removedOld], newParent, node);
      setDataState(addedNew);
    });
    pendingSaveNodes.current = new Set();
  };

  const onDrop = (info: {
    event: React.MouseEvent<unknown>;
    //拖拽落下的节点 id,
    //对于拖拽调整父节点的，本参数为父节点
    //对于拖拽调整节点顺序不变化父节点的，本参数为被拖拽节点落点的上一个节点
    node: EventDataNode<ExtendDataNode>;
    // 被拖拽的节点
    dragNode: EventDataNode<ExtendDataNode>;
    dragNodesKeys: Key[];
    dropPosition: number;
    dropToGap: boolean;
  }): void => {
    if (dragging) {
      return;
    }
    setDragging(true);
    // 如果拖动到另外一个节点的第一个子节点，则 node 参数传递的是父节点
    // 如果拖动到另外一个节点的最后一个子节点，则 node 参数传递的是上一个姐妹节点
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key; ////被拖拽的节点 id
    const dropPos = info.node.pos.split('-'); //拖拽落下的位置 最外层到最里层
    // info.dropPosition: 被拖拽的节点落下的位置
    // dropPos.length - 1: 拖拽落下的节点最内层的位置
    // 两者相减，得到被拖拽的节点相对于拖拽落下的节点的位置变化
    const positionChange = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // 递归找到某节点并执行 callback 方法
    const loop = (data: Array<ExtendDataNode>, key: string | number, callback: callbackType): void => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          callback(data[i], i, data);
        }
        // 这里判断 data[i] != null 是为了处理用户将最后一个节点拖动为其他节点的子节点的场景
        // 这种情况下，实际 data 的 length 就会减小 1, 那么循环到最后一个元素的时候，就会出现
        // 该 index 下的元素已经不存在了
        if (data[i] != null && data[i].children != null && (data[i].children?.length ?? -1) > 0) {
          loop((data[i].children ?? []) as Array<ExtendDataNode>, key, callback);
        }
      }
    };
    const lData = [...data]; //拷贝当前的节点列表用以操作

    // Find dragObject and remove it from the data list
    let dragObj: ExtendDataNode | undefined;
    loop(lData, dragKey, (item, index, arr) => {
      console.log(`Drag item(dragObj) ${JSON.stringify(item)}, index: ${index}, sisters: ${JSON.stringify(arr)}`);
      arr.splice(index, 1);
      dragObj = item;
    });

    const changedNodes: Set<ExtendDataNode> = new Set();
    if (dragObj != null) {
      changedNodes.add(dragObj);
    }

    // 如果被拖拽的节点的原父节点现在已经没有子节点(唯一的子节点已经被拖拽走了)，
    // 因此需要将其原父节点的 isLeaf 设置为 true, 隐藏节点前的小三角
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore dragObj has been assigned in loop callback
    if (dragObj != null && dragObj.parent != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore dragObj has been assigned in loop callback
      loop(lData, Number(dragObj.parent), (item) => {
        if (item != null && item.children?.length === 0 && item.isLeaf === false) {
          item.isLeaf = true;
          changedNodes.add(item);
        }
      });
    }

    const nodeProps: EventDataNode<{ children: Array<ExtendDataNode> }> = info.node; // 拖动的节点的 props
    const dropToEnd = (nodeProps.children || []).length > 0
      && nodeProps.expanded && positionChange === 1;

    const handleDropToBeginningAndEnd = (): void => {
      loop(lData, dropKey, (item) => {
        item.children = item.children || [];
        if (dragObj != null) {
          dragObj.parent = (typeof item.key === 'string') ? Number(item.key) : item.key;
          // 拖入后根据姐妹节点的 displaySequence 来设定拖入节点的
          // 如果拖入节点是第一个，则将第一个节点的 displaySequence － 10 得到拖入节点的 displaySequence
          // 如果拖入节点是最后一个，则将最后一个节点的 displaySequence + 10 得到拖入节点的 displaySequence
          if (item.children.length > 0) {
            if (dropToEnd) {
              dragObj.displaySequence = (item.children[item.children.length - 1] as ExtendDataNode).displaySequence + 10;
            } else {
              dragObj.displaySequence = (item.children[0] as ExtendDataNode).displaySequence - 10;
            }
          }
          item.children.unshift(dragObj);
        }
        // 如果本来是一个叶子节点，现在下面增加了子节点，
        // 那么将其 isLeaf 设置为 false,
        // 如果所有节点均被删除， 则将其 isLeaf 设置为 true,
        // 这里只影响显示，无需调用后台接口进行保存
        const originIsLeaf = (item.isLeaf === true);
        const nowHasChild = (item.children?.length > 0);
        if ((nowHasChild && originIsLeaf) || (!nowHasChild || originIsLeaf)) {
          item.isLeaf = !nowHasChild;
        }
      });
    };

    // 如果拖动到开始或者结尾
    if (!info.dropToGap || dropToEnd) {
      handleDropToBeginningAndEnd();
    } else { //在姐妹节点中间释放拖动
      let sisters: Array<ExtendDataNode> | undefined;
      let dropNodeIdx: number | undefined;
      loop(lData, dropKey, (item, index, arr) => {
        sisters = arr;
        dropNodeIdx = index;
      });
      if (dragObj != null && dropNodeIdx != null && sisters != null) {
        const idxToInsert = (positionChange === -1) ? dropNodeIdx : dropNodeIdx + 1;
        if (sisters.length > 0) {
          const { parent } = sisters[0];
          dragObj.parent = parent;
          // 将新的节点插入
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore sisters and i has been assigned in loop callback
          sisters?.splice(idxToInsert, 0, dragObj);
          // 如果是插入最后
          if ((idxToInsert + 1) === sisters.length) {
            // 因为新节点已经被加入 sisters 的最后一个元素, 因此下面的 index 是 sisters.length － 2
            const maxSequence = (sisters[sisters.length - 2] as ExtendDataNode).displaySequence;
            dragObj.displaySequence = maxSequence + 10;
          } else if (idxToInsert === 0) {
            // 因为新节点已经被加入到 sisters 的第一个节点，因此下面的 index 是 1
            const minSequence = sisters?.[1].displaySequence;
            dragObj.displaySequence = minSequence - 10;
          } else { //插入到中间
            //待插入元素前的部分
            const beforePart = sisters.slice(0, idxToInsert);
            dragObj.displaySequence = beforePart[beforePart.length - 1].displaySequence + 10;
            // 更新影响到的其他节点的 displaySequence
            sisters.forEach((elem, idx, arr) => {
              // 只处理在当前插入的节点之后的节点，要将后面部分的节点的 displaySequence 加上 10
              if (idx > 0 && idx > idxToInsert) {
                const previousNodeDisplaySequence = arr[idx - 1].displaySequence;
                // 如果当前节点的 displaySequence 比其之前一个要小，那么将当前节点的 displaySequence 加 10
                // 如果当前节点的 displaySequence 比其之前一个要大，则不修改，这是为了减小拖动时影响的树节点的修改集
                if (elem.displaySequence <= previousNodeDisplaySequence) {
                  // 如果当前的值不是前一个节点的 displaySequence + 10, 则修改，否则无需修改
                  const targetDisplaySequence = previousNodeDisplaySequence + 10;
                  if (elem.displaySequence !== targetDisplaySequence) {
                    elem.displaySequence = targetDisplaySequence;
                    changedNodes.add(elem);
                  }
                }
              }
            });
          }
        }
      }
    }
    pendingSaveNodes.current = changedNodes;
    // 调用后台 API, 批量保存所有更改的节点
    saveDomain({
      domainName, successCallback: () => reorderCallback(dataState),
      failCallback: emptyMethod,
      method: "PUT",
      url: `${SERVER_URL}/tree/${domainName}`,
      values: Array.from(pendingSaveNodes.current),
    }).finally(() => setDragging(false));
  };

  const onExpand = (newExpandedKeys: Key[], info: {
    node: EventDataNode<ExtendDataNode>;
    expanded: boolean;
    nativeEvent?: MouseEvent;
  }, forceRefresh?: boolean): void => {
    const { node, expanded } = info;
    const { key, isLeaf, loaded, children } = node;
    const ep = expanded ? [...new Set([...newExpandedKeys, key, ...expandedKeys])] :
      [...new Set(newExpandedKeys.filter(n => n !== key))];
    setExpandMode('uc');
    setExpandedKeys(ep);
    // 如果 node 非叶子节点，且当前显示的子节点的数量为 0, 或者调用方指定强制刷新，则刷新数据
    // 因为点击了刷新树的图标后，之前 expand 的节点不会再调用 loadData 方法去刷新数据，
    // 需要在这里刷新下数据
    const refresh = (!isLeaf && loaded && expanded && children?.length === 0) || forceRefresh;
    if (refresh) {
      setLoading(true);
      fetchDataCallback(key).then(() => {
        setLoadedKeys([...new Set([...loadedKeys, key])]);
      }).finally(() => setLoading(false));
    }
  };

  const deleteCallback = (node?: RecordProps): void => {
    if (node != null) {
      // 此时从 deleteCallback 回传的 node 只包含 id 了，
      const { id } = node;
      const newFlatNodes = [...flatNodes].filter((n: ExtendDataNode) => n.id !== id);
      setFlatNodes(newFlatNodes);
      const newSelectIds = [...selectIds].filter((sid: number) => sid !== id);
      setSelectIds(newSelectIds);
      const newExpandedKeys = [...expandedKeys ?? []]?.filter((ek: string | number) => ek !== id);
      setExpandedKeys(newExpandedKeys);
      const newLoadedKeys = [...loadedKeys].filter((lk: string | number) => lk !== id);
      setLoadedKeys(newLoadedKeys);
      setDataState(recursionRemoveNode([...dataState], node));
    } else {
      console.error("Delete node is null: ", node);
    }
  };

  const titleRender = (node: ExtendDataNode): ReactNode => {
    const selected = selectIds.indexOf(parseInt(node.key.toString())) > -1;
    return (<TreeTitleRender
      currentShowMenuOrModalNode={currentShowMenuOrModalNode}
      setCurrentShowMenuOrModalNode={setCurrentShowMenuOrModalNode}
      canCreate={canCreate}
      selected={selected}
      node={node}
      domainName={domainName}
      updateDataCallback={(node: DataNode, operation: TreeNodeOperation) => {
        // 如果是更新当前节点，则直接从后台获取最新的当前节点的数据
        if (operation === 'edit') {
          fetchCurrentValueNoRelationshipColumns(domainName, Number.parseInt(node.key.toString())).then(json => {
            flatNodes.forEach((n: ExtendDataNode) => {
              if (n.id === node.key) {
                n.title = json.label || json.name || json.title || n.title;
                n.icon = json.icon || n.icon;
                n.title && setDataState(recursionChangeNodeInfo([...dataState], n.id, n.title.toString(), n.icon));
              }
            });
          });
        } else if (operation === 'addChild') {
          // 如果是在当前节点下新增子节点，则调用 onExpand 展开当前节点
          onExpand(expandedKeys ?? [], {
            node: ({ //手动构造一个 onExpand 方法用到的属性列表
              key: node.key,
              isLeaf: false,
              loaded: true,
            } as EventDataNode<ExtendDataNode>),
            expanded: true,
          }, true);
        }
      }}
      deleteCallback={deleteCallback}
      zIndex={zIndex}
      updateFormName={layoutForm?.extInfo?.updateFormName}
    />);
  };

  return (
    <div>
      <div className="tree-menu-container">
        <span className='tree-operation-container'>
          <Space direction="horizontal" size={6}>
            {collapseMasterCallback &&
              <DoubleLeftOutlined
                title={t('Click to collapse panel')}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  stopPropagationAndPreventDefault(e);
                  collapseMasterCallback();
                }}
              />
            }
            <NodeExpandOutlined
              onClick={(e: React.MouseEvent<HTMLElement>) => click("ea", e)}
              title={t('Click to expand all child nodes')}
            />
            <NodeCollapseOutlined
              onClick={(e: React.MouseEvent<HTMLElement>) => click("ca", e)}
              title={t('Click to collapse all child nodes')}
            />
            {multiple && (<><CheckCircleOutlined
              title={t('Click to select all nodes')}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                stopPropagationAndPreventDefault(e);
                const selectedKeys = flatNodes.map(d => d.id);
                onSelect(selectedKeys);
                setSelectIds(selectedKeys);
                onCheck(flatNodes);
              }}
            />
              <MinusCircleOutlined
                title={t('Click to clear all nodes selection')}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  stopPropagationAndPreventDefault(e);
                  onSelect([]);
                  setSelectIds([]);
                  onCheck([]);
                }}
              /></>
            )}
            <span
              className="expand-tree-icon"
              onClick={() => expandMasterCallback?.()}
              title={t('Click to expand panel')}
            >
              <DoubleRightOutlined />
            </span>
          </Space>
        </span>
        <span className="tree-component-filters">
          <PredefinedFilters
            domainName={domainName}
            fetchDataCallback={fetchDataCallback}
            columns={columns}
            displayStyle='inline'
            zIndex={zIndex}
          />
        </span>
      </div>
      <Tree
        className={`${draggable ? 'tree-draggable' : 'tree-non-draggable'}`}
        checkedKeys={selectIds}
        titleRender={titleRender}
        onExpand={onExpand}
        treeData={dataState}
        checkable={true}
        multiple={multiple}
        height={height}
        selectedKeys={selectIds}
        expandedKeys={expandedKeys}
        onCheck={(checked: {
          checked: Key[];
          halfChecked: Key[];
        } | Key[], info: {
          event: 'check';
          node: EventDataNode<ExtendDataNode>;
          checked: boolean;
          nativeEvent: MouseEvent;
          checkedNodes: DataNode[];
          checkedNodesPositions?: {
            node: DataNode;
            pos: string;
          }[];
          halfCheckedKeys?: Key[];
        }) => {
          onCheck(info.checkedNodes);
          const newSelectIds: Array<number> = [...info.checkedNodes.map(n => parseInt(n.key.toString()))];
          setSelectIds(newSelectIds);
          onSelect([...newSelectIds]);
        }}
        onSelect={(selectedKeys: Key[]): void => {
          const ids: Array<number> = selectedKeys.map(sk => parseInt(sk.toString()));
          setSelectIds(ids);
          onSelect(ids);
          onCheck(flatNodes.filter((n: ExtendDataNode) => ids.indexOf(n.id) > -1));
        }}
        draggable={draggable}
        onDragEnter={emptyMethod}
        onDrop={onDrop}
        checkStrictly={true}
        loadedKeys={loadedKeys}
        loadData={loadData}
      />
    </div>
  );
};

export default TreeComponent;
