import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { DashboardWidgetProps, WidgetDataResponseProps } from "@props/RecordProps";
import { getDashboardWidgetData } from "@utils/FetchUtils";
import { getWidgetComponent } from "./WidgetComponentsMapping";
import {Card, Dropdown, Menu, Modal, Space, Spin, Tag} from "antd";
import {
  SettingOutlined, EditOutlined, CodeSandboxOutlined,
  ReloadOutlined, FullscreenOutlined
} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../components/';
import { DeleteComponent } from "../../form";
import { WidgetErrorBoundary } from "./WidgetErrorBoundary";
import RefreshIntervalSelect from "./RefreshIntervalSelect";
import { RedirectComponent } from "../../components/redirect";

interface DashboardWidgetComponentProps {
  meta: DashboardWidgetProps;
  refreshCallback: () => void;
  refreshInterval?: number;
}

const DashboardWidget = (props: DashboardWidgetComponentProps): ReactElement => {
  const { meta, refreshCallback, refreshInterval } = props;
  const { t } = useTranslation();

  const [dataAndConfigs, widgetDataAndConfigs] = useState<WidgetDataResponseProps>({});
  const [maxWidget, setMaxWidget] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showEditCoreLogicModal, setShowEditCoreLogicModal] = useState<boolean>(false);
  const [showMenuIcon, setShowMenuIcon] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval>>();
  const [refreshIntervalState, setRefreshIntervalState] = useState<number | undefined>(refreshInterval);
  const loadingRef = React.useRef(loading);

  useEffect(() => {
    setRefreshIntervalState(refreshInterval);
  }, [refreshInterval]);

  const refreshData = useCallback((): void => {
    console.log("*******************", loading);
    if (loadingRef.current === true) {
      return;
    }
    loadingRef.current = true;
    const getData = (): void => {
      getDashboardWidgetData(meta.id).then(data => {
        widgetDataAndConfigs(data);
      }).finally(() => {
        loadingRef.current = false;
        setLoading(false);
      });
    };
    if (intervalId != null) {
      clearInterval(intervalId);
    }
    getData();
    if (refreshIntervalState != null && refreshIntervalState > 0) {
      const newIntervalId = setInterval(getData, refreshIntervalState * 1000);
      setIntervalId(newIntervalId);
    } else {
      setIntervalId(undefined);
    }
  }, [refreshIntervalState, meta.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshData();
  }, [refreshData]);


  //Position template
  // {"config": {"width": 150, "height": 150}, "position": {"col": 4}}
  //FIXME 如果设定的 position:  col: 小于 4，有一定几率出现 widget 重叠的情况
  const WidgetComponent = getWidgetComponent(meta);
  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => {
          refreshData();
        }}><ReloadOutlined /> {t('Refresh data')}</span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={() => setMaxWidget(true)}>
          <FullscreenOutlined /> {t('Show in popup window')}
        </span>
      </Menu.Item>
      {
        meta.canUpdate && <Menu.Item>
          <span onClick={() => {
            setShowEditModal(true);
          }}>
            <EditOutlined /> {t('Edit widget settings')}
          </span>
        </Menu.Item>
      }
      {
        meta.canUpdate && <Menu.Item>
          <span onClick={() => {
            setShowEditCoreLogicModal(true);
          }}>
            <CodeSandboxOutlined /> {t('Edit widget core logic')}
          </span>
        </Menu.Item>
      }
      {meta.canDelete &&
        <Menu.Item>
          <DeleteComponent
            domainName="DynamicDashboardWidget"
            id={meta.id}
            callback={() => {
              refreshCallback();
            }}
            trigger="click"
            renderWithoutContainer={true}
            text={t("Delete widget")}
          />
        </Menu.Item>
      }
    </Menu>
  );
  const widgetLabel = meta.label ?? meta.name;

  const widgetComponent = (
    <WidgetErrorBoundary
      widget={meta}
      dataAndConfigs={dataAndConfigs}
    >
      <div className={`widget-container widget-${meta.type.toLowerCase()}`}>
        <WidgetComponent
          dataAndConfigs={dataAndConfigs}
          widget={meta}
        />
      </div>
    </WidgetErrorBoundary>
  );
  const maxModal = (
    <Modal
      title={widgetLabel}
      open={maxWidget}
      footer={null}
      closeIcon={<CloseIcon onClick={() => setMaxWidget(false)} />}
      className="max-widget-popup"
      width="90%"
    >
      {widgetComponent}
    </Modal>
  );
  return (
    <Card
      onMouseEnter={() => setShowMenuIcon(true)}
      onMouseLeave={() => setShowMenuIcon(false)}
      style={{
        flex: 1,
        transform: "translateZ(0)", // Apply the GPU acceleration hack
        transition: "height 0.3s ease, opacity 0.3s ease",
        //position: "relative"
      }}
      // title={widgetLabel}
      title={widgetLabel}
      extra={
        <>
          {!showMenuIcon && (<></>)}
          {showMenuIcon && (
            <div
              style={{
                position: "absolute",
                top: "14px",
                right: "24px",
              }}
            >
              <Space direction="horizontal" size={2}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Tag><SettingOutlined/>{t('Settings')}</Tag>
                </Dropdown>
                <RefreshIntervalSelect
                  refreshInterval={refreshIntervalState}
                  setRefreshIntervalCallback={(interval?: number) => {
                    console.log(interval);
                    setRefreshIntervalState(interval);
                  }}
                />
              </Space>
            </div>
          )}
        </>
      }
    >
      {((loading == null) || loading) && <Spin size="small" />}
      {(loading === false) && widgetComponent}
      {maxWidget && maxModal}
      {showEditModal &&
        <RedirectComponent
          forMultiple={false}
          fetchDataCallback={() => {
            refreshCallback();
            setShowEditModal(false);
          }}
          redirect={`/DynamicDashboardWidget/${meta.id}/update`}
          //Use 7 to make sure it appears on top of dashboard widgets
          zIndex={7}
          showText={false}
          hasRelateObjectField={false}
        />
      }
      {showEditCoreLogicModal &&
        <RedirectComponent
          forMultiple={false}
          fetchDataCallback={() => {
            refreshCallback();
            setShowEditCoreLogicModal(false);
          }}
          redirect={`/DynamicLogic/${meta.coreLogic.id}/update`}
          //Use 7 to make sure it appears on top of dashboard widgets
          zIndex={7}
          showText={false}
          hasRelateObjectField={false}
        />
      }
    </Card>
  );
};

export default DashboardWidget;
