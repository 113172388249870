import React, { ReactElement, useState } from 'react';
import { ExtendUploadFile, StorageFileValue } from '@props/RecordProps';
import FilePreview from '../../components/fileOperator/FilePreview';
import { EyeOutlined } from '@ant-design/icons'; // Import an icon from Ant Design
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

export interface PreviewActionProps {
  file: StorageFileValue;
  zIndex: number;
  displayTextAndIcon?: boolean;
}

const PreviewAction = (props: PreviewActionProps): ReactElement => {
  const { file, zIndex, displayTextAndIcon } = props;

  const [display, setDisplay] = useState<boolean>(false);

  const { t } = useTranslation();

  const toggleDisplay = (): void => {
    setDisplay(!display);
  };

  return (
    <>
      {displayTextAndIcon &&
        <Space onClick={toggleDisplay} style={{ paddingLeft: "24px", cursor: "pointer" }}>
          <EyeOutlined />
          {t('Preview and print')}
        </Space>
      }

      {(display || !displayTextAndIcon) && (
        <FilePreview
          file={{
            id: file.id,
            uid: file.id,
            name: file.name,
            data: file,
          } as unknown as ExtendUploadFile}
          onClose={() => {
            setDisplay(false);
          }}
          zIndex={zIndex}
        />
      )}
    </>
  );
};

export default PreviewAction;
