import type { Identifier, XYCoord } from 'dnd-core';
import { FormInstance, List } from 'antd';
import React, { ReactElement, useRef, useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import GroupedGrandChildGroupExtra from './GroupedGrandChildGroupExtra';
import GroupedGrandChildGroupTitle from './GroupedGrandChildGroupTitle';
import {
  CellComponentDisplayPage,
  RecordProps,
  TableMetaProps
} from '@props/RecordProps';
import { DndItemTypes } from "@config/base";
import { DragItem } from "./GroupedGrandChildDetailGroup";
import { useTranslation } from 'react-i18next';

export interface GroupedGrandChildGroupDetailItemProps {
  key: number;
  data: Array<RecordProps>;
  record: RecordProps;
  columns: Array<TableMetaProps>;
  summaryGroupTitleColumns: Array<TableMetaProps>;
  summaryGroupDescriptionColumns: Array<TableMetaProps>;
  summaryGroupExtraColumns: Array<TableMetaProps>;
  reload: () => void;
  parentDomainId: number;
  parentDomainName: string;
  domainName: string;
  page: CellComponentDisplayPage,
  zIndex: number;
  canDnd: boolean;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void
  onDrop: (item: DragItem,) => void
}

const GroupedGrandChildGroupDetailItem = (props: GroupedGrandChildGroupDetailItemProps): ReactElement => {
  const {
    data, record, columns, summaryGroupTitleColumns, parentDomainId, domainName, parentDomainName, reload,
    //summaryGroupDescriptionColumns,
    page, zIndex, canDnd, moveCard, onDrop, summaryGroupExtraColumns, index
  } = props;

  const [showOperations, setShowOperations] = useState<boolean>(false);

  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DndItemTypes.List,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      //dragIndex: 移动的节点的index
      //hoverIndex: 移动之后，最后一次经过的节点的index
      //如果移动过程中，经过了多个节点，那么每经过一个节点，本方法会被调用一次
      if (!ref.current) {
        return;
      }
      if (!canDnd) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // not exist in this data group, ignored
      if (!data.find((d) => d.id === item.id)) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex);
      // 如何判断是否是在同一个组内拖拽
      // 如果在同一个组内，拖拽，直接更新该组内的顺序
      // 如果不在同一个组内的拖拽，直接从原来的组中删除，然后添加到新的组中，添加的时候 displaySequence 比其前面的大 5, 比其后面的小 5
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      // 要把结果回写到父组件，然后回写到后端
      // console.log(item.index, ' <--- item.index value', hoverIndex, dragIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean | null }
  >({
    type: DndItemTypes.List,
    item: () => {
      return { id: record.id, index, type: DndItemTypes.List };
    },
    end: onDrop,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const title = <GroupedGrandChildGroupTitle
    record={record}
    columns={columns}
    displayColumns={summaryGroupTitleColumns}
    domainName={domainName}
    page={page} zIndex={zIndex}
    form={{} as FormInstance}
    canDnd={canDnd}
  />;
  const extra = <GroupedGrandChildGroupExtra
    record={record}
    reload={reload}
    operationsProps={{
      ownerId: parentDomainId,
      ownerClass: parentDomainName,
    }}
    columns={columns}
    displayColumns={summaryGroupExtraColumns}
    domainName={domainName}
    page={page}
    zIndex={zIndex}
    form={{} as FormInstance}
    showOperations={showOperations}
  />;
  // const description = <GroupedGrandChildGroupTitle
  //   record={record}
  //   columns={columns}
  //   displayColumns={summaryGroupDescriptionColumns}
  //   domainName={domainName}
  //   page={page}
  //   zIndex={zIndex}
  //   form={{} as FormInstance}
  // />;
  return (<List.Item
    extra={extra}
    key={record.id}
    ref={ref}
    data-handler-id={handlerId}
    style={{ opacity }}
    title={ t('Click and drag to sort') }
    onMouseEnter={() => setShowOperations(true)}
    onMouseLeave={() => setShowOperations(false)}
  >
    <List.Item.Meta
      title={title}
      //FIXME add description back
      description={undefined}
    />
  </List.Item>);
};

export default GroupedGrandChildGroupDetailItem;
