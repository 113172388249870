import { RecordProps, Store, TableMetaProps } from "@props/RecordProps";
import { isObjectType } from "@utils/ColumnsUtils";
import { transformRecord } from "@kernel/DisplayComponentsMapping";


export enum ArrayColumnOperationsTypeEnum {
  ADD = "ADD",
  REMOVE = "REMOVE",
  EDIT = "EDIT",
  NOT_CHANGE = "NOT_CHANGE",
}

export interface ArrayColumnOption {
  operations: Record<ArrayColumnOperationsTypeEnum, number[]>;
  ownerColumnName?: string;
}

export const convertValueToForm = (record: RecordProps, columnsRecord: Record<string, TableMetaProps>): Store => {
  const val: Store = {};
  if (!columnsRecord) {
    return val;
  }
  Object.keys(columnsRecord).forEach((key) => {
    const column = columnsRecord[key];
    if (column && isObjectType(column.type)) {
      val[key] = record[key]?.id;
    } else {
      val[key] = record[key];
    }
  });
  return val;
};

export const mergeChangedValues = (record: RecordProps, changedValues: Store): RecordProps => {
  const newRecord: RecordProps = { ...record };
  Object.keys(changedValues).forEach(key => {
    newRecord[key] = changedValues[key];
  });
  return newRecord;
};

export const convertValueFromForm = (prev: RecordProps, store: Store, columnsRecord: Record<string, TableMetaProps>): RecordProps => {
  const val: RecordProps = { ...prev };
  if (!columnsRecord) {
    return val;
  }
  Object.keys(columnsRecord).forEach((key) => {
    const column = columnsRecord[key];
    if (column && column.display) {
      if (isObjectType(column.type)) {
        if (store[key]) {
          val[key] = {
            id: store[key],
          };
        } else {
          val[key] = undefined;
        }
      } else {
        val[key] = store[key];
      }
    }
  });
  return val;
};

export const convertRecordToStore = (record: RecordProps, columnsRecord: Record<string, TableMetaProps>): RecordProps => {
  const convertedData = transformRecord(Object.values(columnsRecord), record);
  const res: RecordProps = { } as RecordProps;
  // filter out the columns that are not in the columnsRecord
  Object.keys(columnsRecord).forEach((key) => {
    res[key] = convertedData[key];
  });
  return res;
};

export const markRecordEdited = (arrayColumnOption: ArrayColumnOption, record: RecordProps): void => {
  // added row
  if (record.id < 0) {
    return;
  }
  arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE] = arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE].filter(value => value !== record.id);
  arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.EDIT] = arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.EDIT].filter(value => value !== record.id);
};

export const markRecordRemoved = (arrayColumnOption: ArrayColumnOption, record: RecordProps): void => {
  // remove row
  if (record.id < 0) {
    arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE] = arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE].filter(value => value !== record.id);
    return;
  }
  arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE] = arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.NOT_CHANGE].filter(value => value !== record.id);
  arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.EDIT] = arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.EDIT].filter(value => value !== record.id);
  if (!arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.REMOVE].find(value => value === record.id)) {
    arrayColumnOption.operations[ArrayColumnOperationsTypeEnum.REMOVE].push(record.id);
  }
};
