import { SpecialFrontendToBackTypeMapping } from "@config/base";
import { ExecResultProps, RecordProps, SaveRecordProps } from "@props/RecordProps";
import _ from "lodash";

/**
 * 如果后台在元数据中没有返回 labelField 属性，
 * 那么前台会按照本数组中的元素，依次寻找对象中的属性
 * 以最先找到的属性为显示的 Label
 */
const DefaultLabelField = ['label', 'name', 'title', 'authority', 'username', 'serialNumber'];

/**
 * 找到对于某个对象，应该显示的 label
 * 如果 labelField 为空，则会依次使用 DefaultLabelField 中的属性
 * 以最先找到的属性为显示的 Label
 * @param record 对象信息
 * @param labelField 后台的 domain 中配置的 labelField 属性
 */
export function getLabelToDisplay(record: RecordProps | SaveRecordProps, labelField?: string): string {
  if (record == null || !isObject(record)) {
    return "";
  }
  if (labelField) {
    return record[labelField];
  }
  for (const lf of DefaultLabelField) {
    if (lf in record && record[lf] != null) {
      return record[lf];
    }
  }
  return `${record.id}`;
}

/**
 * 判断一个对象是否包含至少一个 key
 * @param obj 待判断的对象
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const pureObjectIsEmpty = (obj: any): boolean => {
  return (obj == null) ||
    (obj && obj.constructor === Object && Object.keys(obj).length === 0);
};

/**
 * 判断一个变量是不是 Javascript 的 Object
 * @param obj 待判断的 Object
 */
export const isObject = (obj: unknown): boolean => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const getNumberOfLineBreaks = (
  result: ExecResultProps, fineTuningResult?: string, noAdditionalLines?: boolean): number => {
  if (result == null) {
    return 1;
  }
  const { execLog, execResult } = result;
  const v1 = execLog?.split("\n")?.length ?? 1;
  const v2 = execResult?.split("\n")?.length ?? 1;
  const v3 = fineTuningResult?.split("\n")?.length ?? 1;
  const otherLines = (noAdditionalLines) ? 0 : 8;
  const value = otherLines + v1 + v2 + v3;
  return (isNaN(value) || value == null) ? 1 : value;
};

export const stopPropagationAndPreventDefault = (event: React.MouseEvent<unknown>): void => {
  event.stopPropagation();
  if (event.nativeEvent) {
    event.nativeEvent.stopImmediatePropagation();
  }
  event.preventDefault();
};

export const isArrayEqual = (x: Array<unknown>, y: Array<unknown>): boolean => {
  return _.isEqual(x, y);
};

export const isSetEqual = (x: Set<unknown>, y: Set<unknown>): boolean => {
  return _.isEqual(x, y);
};

export const isObjectEqual = (x: object, y: object): boolean => {
  return _.isEqual(x, y);
};

export const getRawDomainName = (domainName: string): string => {
  return SpecialFrontendToBackTypeMapping[domainName] || domainName;
};

export const errorToObject = (error: Error): { message: string, name: string, stack: string | undefined } => {
  return {
    message: error.message,
    name: error.name,
    stack: error.stack
  };
};

const labelCache: Record<string, Record<number, string>> = {};

export const getObjectLabelFromCache = (domainName: string, id: number): string | undefined => {
  if (!id) {
    return undefined;
  }
  return labelCache[domainName]?.[id];
};

export const setObjectLabelToCache = (domainName: string, id: number, label: string): void => {
  if (!id) {
    return;
  }
  if (!labelCache[domainName]) {
    labelCache[domainName] = {};
  }
  labelCache[domainName][id] = label;
};

export const deleteLabelFromCache = (domainName: string, id: number): void => {
  if (labelCache[domainName]) {
    delete labelCache[domainName][id];
  }
};

window.__labelCache = labelCache;