import React, { ReactElement } from "react";
import { ObjectValues, TableMetaProps } from "@props/RecordProps";
import { Popover } from "antd";
import { TableOutlined } from "@ant-design/icons";
import SubTableForm from "./SubTableForm";

const SubTableFormDisplayComponentCell = (props: {
  zIndex: number,
  owner?: ObjectValues;
  column: TableMetaProps;
  ownerClass: string;
}): ReactElement => {
  const { zIndex, owner, column, ownerClass } = props;
  return <Popover
    trigger="click"
    placement="bottom"
    content={
      <div>
        <SubTableForm
          column={column}
          owner={owner}
          editMode={false}
          ownerClass={ownerClass}
          zIndex={zIndex}
        />
      </div>
    }
    overlayStyle={{
      zIndex: zIndex + 2,
      maxWidth: 800,
    }}
  >
    <TableOutlined/>
  </Popover>;
};

export default SubTableFormDisplayComponentCell;
