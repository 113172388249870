import React, { ReactElement } from 'react';
import {
  FileWordOutlined, FileImageOutlined,
  FilePdfOutlined, FileExcelOutlined, FileMarkdownOutlined, FileZipOutlined, FilePptOutlined,
  FileTextOutlined, BookOutlined, SoundOutlined, YoutubeOutlined, MailOutlined
} from '@ant-design/icons';

export const FileTypeIconMapping: {
  [propName: string]: ReactElement
} = {
  "text/markdown": <FileMarkdownOutlined />,
  "text/plain": <FileTextOutlined />,
  "text/csv": <FileExcelOutlined />,
  "image/png": <FileImageOutlined />,
  "image/jpeg": <FileImageOutlined />,
  "image/gif": <FileImageOutlined />,
  "application/pdf": <FilePdfOutlined />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FileWordOutlined />,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FileExcelOutlined />,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": <FilePptOutlined />,
  "application/msword": <FileWordOutlined />,
  "application/vnd.ms-excel": <FileExcelOutlined />,
  "application/vnd.ms-powerpoint": <FilePptOutlined />,
  "application/epub+zip": <BookOutlined />,
  "audio/mpeg": <SoundOutlined />,
  "audio/mp3": <SoundOutlined />,
  "video/mp4": <YoutubeOutlined />,
  "application/x-rar-compressed": <FileZipOutlined />,
  "application/x-rar": <FileZipOutlined />,
  "application/x-7z-compressed": <FileZipOutlined />,
  "application/zip": <FileZipOutlined />,
  "message/rfc822": <MailOutlined />,
};

export const TableModeToClassNameMap: {[propName: string]: 'fix-table' | 'flow-table'} = {
  "table-list": 'fix-table',
  "detail": 'fix-table',
  "finder": 'flow-table',
  "detail-drawer": "fix-table",
  "simple-list": 'fix-table',
};

export const DataCollectFormHideFieldClassName = "form-item-hide-by-display";
