import React, { ReactElement } from 'react';
import { Collapse, Space, Tooltip } from 'antd';
import { DynamicIcon } from '../components';
import { GroupedFormFields, GroupMetaProps } from "@props/RecordProps";
import { QuestionCircleOutlined, CaretRightOutlined } from '@ant-design/icons';
import i18n from "@config/i18n";
import { stripPackagePart } from '@utils/StringUtils';
import { DataCollectFormHideFieldClassName } from '@config/ui';

const { Panel } = Collapse;

export const isFieldNotReadonlyBasedOnGroupMeta = (
  groupId: number | undefined, groups: GroupMetaProps[]
): boolean => {
  if (groupId == null) { return true; }
  const groupObj = groups.filter(g => g.id === groupId)?.[0];
  return (groupObj?.displayMode !== "readonly");
};


export const getTranslatedFormGroupLabel = (domainName: string, group: GroupMetaProps): React.ReactNode => {
  const transKey = `formGroup:${stripPackagePart(domainName)}.${group.label}`;
  return (i18n.exists(transKey))? i18n.t(transKey) : group.label;
};

export const panel = (
  domainName: string,
  group: GroupMetaProps,
  fields: Array<ReactElement>,
  callback: () => void,
  nowDisplay: boolean
): ReactElement => {

  const allFieldsHidden = fields.every(field =>
    field.props?.className?.includes(DataCollectFormHideFieldClassName)
  );

  const panelClassName = allFieldsHidden ?
    `${DataCollectFormHideFieldClassName} site-collapse-custom-panel` : 'site-collapse-custom-panel';

  const header = (group.icon != null) ? (
    <span
      onClick={callback}
    >
      <CaretRightOutlined
        rotate={nowDisplay ? 90 : 0}
        style={{ marginRight: "1em" }}
      />
    <Space
      size="small"
    >
      <DynamicIcon type={group.icon} /> {getTranslatedFormGroupLabel(domainName, group)}
    </Space>
    </span>
  ) : group.label;
  return (
    <Panel
      header={header}
      extra={
        <Tooltip title={group.helpText}><QuestionCircleOutlined /> </Tooltip>
      }
      key={group.id}
      className={panelClassName}
    >
      {fields}
    </Panel>
  );
};

export const panels = (
  domainName: string,
  fields: GroupedFormFields,
  groups: Array<GroupMetaProps>,
  activeGroups: Array<GroupMetaProps>,
  setActiveGroups: (value:  Array<GroupMetaProps>) => void
): Array<React.ReactElement> => {
  const result = [] as Array<ReactElement>;
  for (const [key, value] of Object.entries(fields)) {
    const group = groups.find(g => g.id.toString() === key.toString());
    if (group != null && group.displayMode !== 'hide') {
      const nowDisplay = (activeGroups.find(g => g.id === group.id) != null);
      const callback = (): void => {
        const nowDisplay = (activeGroups.find(g => g.id === group.id) != null);
        if (nowDisplay) {
          setActiveGroups(activeGroups.filter(g => g.id !== group.id));
        } else {
          setActiveGroups([...activeGroups, group]);
        }
      };
      const groupIdx = groups.findIndex(g => g.id === group.id);
      result[groupIdx]  = panel(domainName, group, value, callback, nowDisplay);
    }
  }
  return result;
};
