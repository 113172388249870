import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Popconfirm, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { DeletionProps } from '@props/RecordProps';
import { deleteRecord } from "@utils/FetchUtils";
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { fullDomainNameToHumanReadable } from "@utils/StringUtils";

//FIXME change to default export
export const DeleteComponent: React.FC<DeletionProps> = (props: DeletionProps) => {
  const {
    domainName, errorMsg, text, renderWithoutContainer, zIndex, displayElement
  } = props;
  const { t } = useTranslation();
  const displayLabel = (text ?? t("Delete"));

  if (errorMsg == null) {
    const content = displayElement ?? ((renderWithoutContainer) ? (
      <span
        onClick={(event: React.MouseEvent<unknown>) => stopPropagationAndPreventDefault(event)}
      > <DeleteOutlined /> {displayLabel} </span>
    ) : (
      <a
        href="/#"
        title={t('Delete current object')}
        onClick={(event: React.MouseEvent<unknown>) => stopPropagationAndPreventDefault(event)}
      >
        <Space size={8}>
          <DeleteOutlined />
          {t('Delete current object')}
        </Space>
      </a>
    ));
    return (<Popconfirm
      title={t('Are you sure to delete this', { domainTitle: fullDomainNameToHumanReadable(domainName) })}
      onConfirm={(): void => {
        //Callback is called inside deleteRecord method
        deleteRecord(props);
      }}
      okText={t("Confirm")}
      cancelText={t("Cancel")}
      // 这是为了避免在某些情况下， 删除的弹出确认框会被遮挡
      zIndex={(zIndex ?? 3) + 1050}
    >
      {content}
    </Popconfirm>
    );
  } else {
    return (<Alert message="Error" description={errorMsg} type="error" showIcon />);
  }
};
