import { CreateAction } from '../../form';
import React, { ReactElement, useEffect, useState } from 'react';
import { FeedbackType } from '.';
import { FeedbackDomainName } from '@config/domain';
import { getOrganization, getUserId, getUsername } from '@utils/TokenUtils';
import { Spin } from 'antd';

export interface FeedbackFormProps {
  type: FeedbackType;
  zIndex: number;
  onCancelCallback: () => void;
  ipAddress: string;
}

const FeedbackForm = (props: FeedbackFormProps): ReactElement => {
  const { type, zIndex, onCancelCallback, ipAddress } = props;
  const [context, setContext] = useState<Record<string, unknown>>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (ipAddress === '') {
      return;
    }
    const context = {
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      language: window.navigator.language,
      platform: window.navigator.platform,
      userContext: {
        id: getUserId(),
        username: getUsername(),
        organization: getOrganization(),
      },
      ipAddress,
    };
    setContext(context);
    setLoading(false);
  }, [ipAddress]);

  console.log('context', context);

  return loading ? (<Spin />) : (
    <CreateAction
      domainName={FeedbackDomainName}
      zIndex={zIndex + 1}
      initShowCreateModal={true}
      queryParameters={{
        type,
        context: JSON.stringify(context, null, 2),
      }}
      onCancelCallback={onCancelCallback}
    />
  );
};

export default FeedbackForm;
