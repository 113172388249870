import { RecordProps } from "@props/RecordProps";
import React, { MutableRefObject, useEffect, useMemo } from "react";
import { Empty, Form, FormInstance } from "antd";
import { EditableContext } from "./SubTableForm";
import { useCustomHookForm } from "@utils/FormUtils";
import { ArrayColumnOption, markRecordEdited } from "./SubTableUtils";

export interface SubTableRowProps {
  index: number;
  record: RecordProps;
  'form-record': Record<number, FormInstance>
  clicked?: number;
  'set-data-and-sort-ref': MutableRefObject<(updateFunc: (prevState: RecordProps[]) => RecordProps[]) => void>;
  'populate-data-ref': MutableRefObject<(d: RecordProps[]) => Promise<RecordProps[]>>;
  'data-row-key'?: string;
  'number-of-columns': number;
  'array-column-option': ArrayColumnOption;
}

export const SubTableRow: React.FC<SubTableRowProps> = (props) => {
  if (props['data-row-key'] === 'empty-row') {
    return (
      <tr {...props}>
        <td colSpan={props['number-of-columns']}> {/* or however many columns you have */}
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        </td>
      </tr>
    );
  }
  return <InnerSubTableRow
    key={`sub-table-form-${props?.record?.id}`}
    {...props}
  />;
};

const InnerSubTableRow: React.FC<SubTableRowProps> = ({
                                                        record,
                                                        'form-record': formRecord,
                                                        'array-column-option': arrayColumnOption,
                                                        ...props
                                                      }) => {
  const form = useCustomHookForm();

  const initialValues = useMemo(() => record, [record]);

  useEffect(() => {
    formRecord[record.id] = form;
    form.setFieldsValue(record);
    markRecordEdited(arrayColumnOption, record);
  }, [formRecord, form, record, arrayColumnOption]);
  return (
    <Form
      form={form}
      initialValues={initialValues}
      component={false}
    >
      <EditableContext.Provider value={form}>
        <tr
          key={record.id}
          {...props}
        />
      </EditableContext.Provider>
    </Form>
  );
};
