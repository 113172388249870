import { Drawer } from 'antd';
import React, { ReactElement, useState } from 'react';
import {
  FileMarkdownOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { CellComponentDisplayPage, TableMode } from "@props/RecordProps";
import { CloseIcon } from '../../components';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import ReactMarkdownWrap from "../../components/wrap/ReactMarkdownWrap";

const MarkdownCell = (props: {
  page: CellComponentDisplayPage,
  text: string;
  zIndex: number;
  tableMode?: TableMode;
}): ReactElement => {
  const { page, zIndex, text, tableMode } = props;
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  return (page === 'DISPLAY' && tableMode != 'detail-drawer') ?
    (<ReactMarkdownWrap
      linkTarget="_blank"
      className="markdown-display"
    >{text}</ReactMarkdownWrap>) :
    (<>
      {<FileMarkdownOutlined onClick={() => setVisible(!visible)} />}
      <Drawer
        width="600px"
        title={t("Message content")}
        placement="right"
        closable={true}
        zIndex={zIndex + 2}
        open={visible}
        onClose={() => setVisible(false)}
        closeIcon={<CloseIcon onClick={setVisible} />}
      >
        <span
          onClick={(e) => stopPropagationAndPreventDefault(e)}
        >
          <ReactMarkdownWrap linkTarget="_blank">{text}</ReactMarkdownWrap>
        </span>
      </Drawer>
    </>);
};

export default MarkdownCell;
