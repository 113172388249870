import React, { ReactElement } from "react";
import { ObjectValues, SaveOptionProps, Store, TableMetaProps } from "@props/RecordProps";
import EntityAttributesTable from "./EntityAttributesTable";
import { FormInstance, Popover } from "antd";
import { TableOutlined } from "@ant-design/icons";
import { EntityAttributeValues } from "./EntityAttributesUtils";
import './entityAttributes.less';

const EntityAttributesComponentCell = (props: {
  value?: EntityAttributeValues;
  zIndex: number,
  owner?: ObjectValues;
  column: TableMetaProps;
  ownerClass: string;
  editable: boolean;
  form?: FormInstance;
  saveOptions?: SaveOptionProps;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  path?: string;
}): ReactElement => {
  const {
    value, zIndex, owner, column, onValuesChange,
    ownerClass, editable, form, saveOptions, path,
  } = props;
  return <Popover
    trigger="click"
    placement="bottom"
    content={
      <div className='popover-content'>
        <EntityAttributesTable
          value={value}
          column={column}
          owner={owner}
          editMode={editable}
          ownerClass={ownerClass}
          zIndex={zIndex + 1}
          onValuesChange={onValuesChange}
          form={form}
          saveOptions={saveOptions}
          path={path}
        />
      </div>
    }
    overlayStyle={{
      zIndex: zIndex + 2,
      maxWidth: 800,
    }}
  >
    <TableOutlined />
  </Popover>;
};

export default EntityAttributesComponentCell;
