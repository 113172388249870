import { Spin } from "antd";
import React, { CSSProperties, ReactElement } from 'react';

const LargeSpin = (props: {
  message?: string;
  containerCss?: CSSProperties
}): ReactElement =>
(<div style={props.containerCss ?? {}} className='large-spin'>
  <Spin size="large" />
  <span style={{ paddingLeft: "1rem" }}>
    {props.message == null ? "" : props.message}
  </span>
</div>);

export default LargeSpin;
