import React, { ReactElement } from "react";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PrimaryColor } from "@config/base";

interface BooleanCellRenderProps {
  value: boolean;
}

const BooleanCell = (props: BooleanCellRenderProps): ReactElement => {
  const { value } = props;
  const { t } = useTranslation();

  return (value === true) ?
    <CheckCircleOutlined style={{ color: "green" }} title={t("true")} /> :
    (value === false) ?
      <CloseCircleOutlined style={{ color: PrimaryColor }} title={t("false")} /> :
      <div />
    ;
};

export default BooleanCell;
