import React, { CSSProperties, ReactElement, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getReadOnlyClass } from '@utils/ComponentUtils';
import { SuffixIcon } from '../../components';

const { Option } = Select;

interface ValueType {
  value: string | number;
  label: string;
}

export interface ValueSelectProps {
  placeholder: string;
  value?: string | number;
  style?: CSSProperties;
  notFoundContent: string;
  onSelect?: (val: string | number | undefined) => void;
  updatable?: boolean;
  options?: string[] | number[];
}

const ValueSelect = (props: ValueSelectProps): ReactElement => {
  const {
    placeholder, value, style, notFoundContent,
    updatable, options, onSelect,
  } = props;

  const { t } = useTranslation();

  const disabled = (updatable == null) ? false : !updatable;
  const [data, record] = useMemo(() => {
    const data: ValueType[] = [];
    const record: Record<string, string | number> = {};
    options?.forEach(o => {
      const str = o.toString();
      record[str] = o;
      data.push({
        value: o,
        label: str,
      });
    });
    return [data, record];
  }, [options]);

  // **** Attention ****
  // zIndex of the select dropdown should higher than the zIndex of modal(by
  // default 1051) because the dropdown might be displayed on a modal
  // https://stackoverflow.com/questions/53926911/antd-select-not-working-inside-a-full-screen-dialog
  // Set in App.less by .ant-select-dropdown
  return (
    <Select
      showSearch
      style={style}
      disabled={disabled}
      value={value}
      placeholder={t(placeholder)}
      defaultActiveFirstOption={true}
      showArrow={true}
      dropdownMatchSelectWidth={true}
      optionFilterProp="label"
      notFoundContent={notFoundContent}
      dropdownStyle={{ zIndex: 2000 }}
      onClear={() => onSelect?.(undefined)}
      onSelect={(val) => onSelect?.(record[val])}
      filterOption={(input, option) => {
        if (option == null || option.value == null || input == null) {
          return false;
        }
        const il = input.toLowerCase();
        const str = option.value.toString().toLowerCase();
        return str.includes(il);
      }}
      className={`valueSelect ${getReadOnlyClass(updatable)}`}
      suffixIcon={<SuffixIcon updatable={updatable}/>}
      allowClear={true}
    >
      {data?.map(d => <Option key={d.value} value={d.value}>{d.label}</Option>)}
    </Select>
  );
};

export default ValueSelect;
