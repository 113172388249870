import React, { ReactElement } from 'react';
import { Steps, Tooltip } from 'antd';
import { PauseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { WizardMetaProps } from '@props/RecordProps';
import { DynamicIcon } from "../../components";

const { Step } = Steps;

const StepsComponent = (props: {
  wizardDirection: 'vertical' | 'horizontal';
  wizardMeta?: WizardMetaProps;
  current: number;
  disabledSteps?: Array<string>;
}): ReactElement => {
  const { wizardMeta, current, disabledSteps, wizardDirection } = props;
  const { t } = useTranslation();

  const trimDescription = (desc?: string): string => {
    if (desc == null) {
      return "";
    }
    if (desc.length > 45) {
      return desc.substr(0, 45) + "...";
    }
    return desc;
  };

  function getDisabledToolTipElem(title: string, showIcon: boolean): React.ReactNode {
    return (
      <Tooltip title={t("This step is disabled based on input data and server side logic")}>
        {title} {showIcon ? <PauseCircleOutlined /> : ""}
      </Tooltip>
    );
  }

  const stepElems = wizardMeta?.steps.map((step, idx) => {
    const { id, name, label, description, icon } = step;
    const status = (idx > current) ? "wait" : (idx === current ? "process" : "finish");
    const stepIcon = (icon == null) ? undefined : <DynamicIcon type={icon} />;
    const isDisabledStep = disabledSteps?.includes(name);
    const displayLabel = label ?? name;
    const trimmedDesc = trimDescription(description);
    const title = isDisabledStep ? getDisabledToolTipElem(displayLabel, true) : displayLabel;
    return (<Step
      status={status}
      title={title}
      icon={stepIcon}
      description={trimmedDesc}
      key={id}
      disabled={isDisabledStep}
    />);
  });

  const stepsElem = (
    <div className={`wizard-steps-${wizardDirection}`}>
      <Steps
        direction={wizardDirection === "vertical" ? "horizontal" : "vertical"}
        current={current}
      >
        {stepElems}
      </Steps>
    </div>
  );
  return stepsElem;
};

export default StepsComponent;
