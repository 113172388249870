import React, { ReactElement, useEffect, useState } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import { fetchCurrentValue } from "@utils/FetchUtils";
import { LargeSpin } from '../../components';
import ImportRecordComponent, { ImportRecordProps } from './ImportRecord';

const UpdatedIdsDetail = (props: DetailPanelProps): ReactElement => {
  const { record, zIndex } = props;
  const [domainName, setDomainName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { domainClass: domainClassId } = record;
  const id = typeof domainClassId === "number" ? domainClassId : domainClassId?.id;
  useEffect(() => {
    fetchCurrentValue("DomainClass", id)
      .then(json => {
        setDomainName(json.shortName);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const importProps = {
    insertedIds: record?.updatedIds?.insertedIds,
    updatedIds: record?.updatedIds?.updatedIds,
    deletedIds: record?.updatedIds?.deletedIds,
    noUpdateIds: record?.updatedIds?.noUpdateIds,
    conflictIds: record?.updatedIds?.conflictIds,
    dbUpdatedCsvNoUpdateIds: record?.updatedIds?.dbUpdatedCsvNoUpdateIds,
    failedLines: record?.failedLines,
    skippedLines: record?.skippedLines,
    conflictLines: record?.conflictLines,
    dbUpdatedCsvNoUpdateLines: record?.dbUpdatedCsvNoUpdateLines,
    logs: record?.logs,
    status: record?.status,
    insertedRecord: record?.insertedRecord,
    failedRecord: record?.failedRecord,
    noUpdateRecord: record?.noUpdateRecord,
    skippedRecord: record?.skippedRecord,
    updatedRecord: record?.updatedRecord,
    deletedRecord: record?.deletedRecord,
    conflictRecord: record?.conflictRecord,
    dbUpdatedCsvNoUpdateRecord: record?.dbUpdatedCsvNoUpdateRecord,
  } as ImportRecordProps;
  return loading ? <LargeSpin /> : (
    <ImportRecordComponent
      domainName={domainName}
      importRecord={importProps}
      uploaded={true}
      defaultMaxWidth="100%"
      zIndex={zIndex}
    />
  );
};

export default UpdatedIdsDetail;
