import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Divider, Dropdown, Menu } from "antd";
import { SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { fetchFormIdAndExtInfoByName, fetchFormIdAndExtInfoByType } from "@utils/FetchUtils";
import { OperationsProps } from "@props/RecordProps";
import { DeleteComponent, ActionButtons, UpdateAction } from "../";
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { useDomainPermit } from '@utils/DomainPermitUtils';

const Operations = (props: OperationsProps): ReactElement => {
  const {
    domainName, id, updateCallback, deleteCallback, ownerClass, fetchType,
    ownerId, page, zIndex, showActions, changeModalVisibleCallback,
    updateFormName, columnNameInOwnerClass,
  } = props;
  const { t } = useTranslation();
  const [updateFormId, setUpdateFormId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [visiblePopover, setVisiblePopover] = useState<string>();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const domainPermit = useDomainPermit(domainName, id);

  /** 查看详情的 Modal 的只读状态，在查看详情的 Modal 中，可以点击编辑按钮，将其状态修改为编辑状态 */
  const [detailModalReadonly, setDetailModalReadonly] = useState<boolean>(true);

  useEffect(() => {
    if (menuVisible === false || updateFormId != null) {
      return;
    }
    if (updateFormName != null && updateFormName !== '') {
      fetchFormIdAndExtInfoByName(domainName, updateFormName).then((json) => {
        setUpdateFormId(json?.id);
      }).catch(e => {
        console.error(`Failed to get Update formId of ${domainName} with name ${updateFormName}: ${e}`);
      }).finally(() => {
        setLoading(false);
      });
      return;
    } else {
      fetchFormIdAndExtInfoByType(domainName, 'Update').then((json) => {
        setUpdateFormId(json?.id);
      }).catch(e => {
        console.error(`Failed to get Update formId of ${domainName}: ${e}`);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [domainName, menuVisible, updateFormId, updateFormName]);

  const setVisibleAndCallback = useCallback((visible: boolean): void => {
    setMenuVisible(visible);
    changeModalVisibleCallback?.(visible);
  }, [changeModalVisibleCallback]);

  const detailAction = (
    <Menu.Item
      key="detail"
      title={t('Detail')}
      onClick={() => setVisibleAndCallback(false)}
    >
      <UpdateAction
        id={id}
        domainName={domainName}
        ownerClass={ownerClass}
        columnNameInOwnerClass={columnNameInOwnerClass}
        callback={updateCallback}
        ownerId={ownerId}
        errorMsg={domainPermit.canUpdateErrorMsg}
        formId={updateFormId}
        zIndex={zIndex}
        showIconAndText={true}
        readonly={detailModalReadonly}
        switchEditable={() => {
          if (detailModalReadonly) {
            domainPermit.canUpdate && setDetailModalReadonly(false);
          } else {
            setDetailModalReadonly(true);
          }
        }}
        fetchType={fetchType}
      />
    </Menu.Item>
  );

  const editAction = (<Menu.Item
    key="update"
    title={t('Update')}
    onClick={() => setVisibleAndCallback(false)}
  >
    <UpdateAction
      id={id}
      domainName={domainName}
      ownerClass={ownerClass}
      columnNameInOwnerClass={columnNameInOwnerClass}
      callback={updateCallback}
      ownerId={ownerId}
      errorMsg={domainPermit.canUpdateErrorMsg}
      formId={updateFormId}
      zIndex={zIndex}
      showIconAndText={true}
      switchEditable={() => {
        if (detailModalReadonly) {
          domainPermit.canUpdate && setDetailModalReadonly(false);
        } else {
          setDetailModalReadonly(true);
        }
      }}
      readonly={!domainPermit.canUpdate || !detailModalReadonly}
      fetchType={fetchType}
    />
  </Menu.Item>);

  const deleteAction = (<Menu.Item
    key="delete"
    title={t('Delete')}
    onClick={() => setVisibleAndCallback(false)}
  >
    <DeleteComponent
      id={id}
      domainName={domainName}
      callback={deleteCallback}
      errorMsg={domainPermit.canDeleteErrorMsg}
      zIndex={zIndex}
    />
  </Menu.Item>);

  const actions = showActions ? (<ActionButtons
    domainName={domainName}
    fetchDataCallback={updateCallback}
    visiblePopover={visiblePopover}
    setVisiblePopoverCallback={(key?: string) => setVisiblePopover(key)}
    selectedData={[{ id }]}
    mode="single"
    zIndex={zIndex}
    direction="vertical"
  />) : (<></>);

  const isEditOrDetailPage = ["edit", "detail"].includes(page);

  const menus = (
    <Menu>
      <Divider>{t('Basic operations')}</Divider>
      {loading && <LoadingOutlined
        style={{
          textAlign: "center",
          margin: "auto",
          width: "100%",
          display: "inline-block",
        }} />}
      {!loading && !isEditOrDetailPage && detailAction}
      {!loading && domainPermit.canUpdate && !isEditOrDetailPage && editAction}
      {!loading && domainPermit.canDelete && deleteAction}
      {!loading && showActions && <Divider>{t('Actions')}</Divider>}
      {!loading && showActions && actions}
    </Menu>
  );

  return (
    <span
      className="operation-container"
      onClick={(e) => stopPropagationAndPreventDefault(e)}
    >
      <Dropdown
        overlay={menus}
        trigger={['click']}
        open={menuVisible}
        onOpenChange={setVisibleAndCallback}
        placement={"bottomRight"}
        overlayClassName="operation-dropdown"
      >
        <SettingOutlined
          className={`show-operate-icon ${(page === 'list') ? '' : 'link-icon'}`}
          title={t("Click to see available operations for this object")}
        />
      </Dropdown>
    </span>
  );
};

export default Operations;
