import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { isDevelopmentEnv } from '@config/base';
import { Space, Tag } from 'antd';
import { getEmailSuffix } from '../security';

type QuickLoginPanelProps = {
  setLoginInfo: (email: string, autoLogin?: boolean) => void;
};

const QuickLoginPanel = (props: QuickLoginPanelProps): ReactElement => {
  const { setLoginInfo } = props;

  const { t } = useTranslation();
  const displayLoginAccountStyle = { display: isDevelopmentEnv() ? 'flex' : 'none' };
  const quickLoginPanel = <div style={{ ...displayLoginAccountStyle, margin: 'auto' }}>
    <Space direction="vertical">
      <div>
        {t("login:NoPermissionUser")}
        <Tag onClick={() => setLoginInfo(`me@${getEmailSuffix()}`, true)}>Empty user</Tag>
      </div>
      <div>
        {t("login:BusinessUser")}
        <Tag onClick={() => setLoginInfo(`biz_user@${getEmailSuffix()}`, true)}>Biz user</Tag>
      </div>
      <div>
        {t(`login:BusinessAdmin`)}
        <Tag onClick={() => setLoginInfo(`biz_admin@${getEmailSuffix()}`, true)}>Biz admin</Tag>
      </div>
      <div>
        {t(`login:OrganizationAdmin`)}
        <Tag onClick={() => setLoginInfo(`admin@${getEmailSuffix()}`, true)}>System admin</Tag>
      </div>
      <div>
        {t(`login:OrganizationDeveloper`)}
        <Tag onClick={() => setLoginInfo(`developer@${getEmailSuffix()}`, true)}>Developer</Tag>
      </div>
    </Space>
  </div>;
  return quickLoginPanel;
};

export default QuickLoginPanel;
