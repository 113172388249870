import React, { ReactElement } from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

export const parseValue = (value: string | undefined): string => {
  if (value == null) {
    return "";
  }
  return value.replace(/\$\s?|(,*)/g, '');
};

const Currency = (props: InputNumberProps): ReactElement => {
  return (
    <InputNumber
      {...props}
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={parseValue}
    />
  );
};

export default Currency;
