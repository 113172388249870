import React, { ReactElement } from "react";
import { Table } from "antd";
import { TableMetaProps } from "@props/RecordProps";

export interface TableChartComponentProps {
  data?: string;
  column: TableMetaProps;
}

export const TableChart: React.FC<TableChartComponentProps> = (props: TableChartComponentProps): ReactElement => {
  const { data, column } = props;

  const { columns } = column.extInfo?.chartInfo ?? {};

  return <Table
    pagination={false}
    columns={columns}
    // bordered
    dataSource={JSON.parse(data ?? '[]')}
  />;
};
