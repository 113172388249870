import React, { ReactElement } from "react";
import { InputNumber, Timeline } from "antd";

interface SeriesInputProps {
  labels: string[];
  values: number[] | string[];
  onChange: (index: string, value: number) => void;
  labelSuffix?: string;
  valueSuffix?: string;
}

const SeriesInput = (props: SeriesInputProps): ReactElement => {
  const parseFunc = (value: string | undefined): number => {
    const strVal: string = (value == null) ?
      '' : value.replace(props.labelSuffix || '', '');
    return parseFloat(strVal);
  };

  return (
    <Timeline mode={"left"}>
      {props.labels.map((label, index) => (
        <Timeline.Item label={label + props.labelSuffix || ''}
          key={label}>
          <InputNumber
            value={Number(props.values[index])}
            size={"small"}
            min={0}
            max={100}
            formatter={value => `${value}${props.valueSuffix || ''}`}
            parser={parseFunc}
            onInput={value => {
              let numVal = NaN;
              if (typeof value === "string") {
                try {
                  numVal = Number.parseFloat(value);
                } catch (err) {
                  console.error(`Failed to convert ${value} to a number`);
                  return;
                }
              }
              if (!isNaN(numVal)) {
                props.onChange(props.labels[index], numVal || 0);
              }
            }}
          />
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default SeriesInput;
