import React, { ReactElement, useState } from "react";
import { Menu, Space, Dropdown, Button } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';

type SaveButtonGroupProps = {
  saveAndCloseButton: ReactElement | null;
  saveAndEditButton: ReactElement | null;
  saveAndContinueButton: ReactElement | null;
  switchModeButton: ReactElement | null;
};

const SaveButtonGroup: React.FC<SaveButtonGroupProps> = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownVisibleChange = (visible: boolean): void => {
    setDropdownVisible(visible);
  };

  const handleMenuClick = (): void => {
    setDropdownVisible(true);
  };

  const dropdownContent: ReactElement = (
    <Menu onClick={handleMenuClick}>
      {props.saveAndEditButton && <Menu.Item key="saveEdit">{props.saveAndEditButton}</Menu.Item>}
      {props.saveAndContinueButton && <Menu.Item key="saveContinue">{props.saveAndContinueButton}</Menu.Item>}
      {props.switchModeButton && <Menu.Item key="switchMode">{props.switchModeButton}</Menu.Item>}
    </Menu>
  );

  const hasButtons = props.saveAndCloseButton || props.saveAndEditButton || props.saveAndContinueButton;

  if (!hasButtons) {
    return null;
  }

  return (
    <Space size={2}>
      {props.saveAndCloseButton}
      {(props.saveAndEditButton || props.saveAndContinueButton) && (
        <Dropdown
          overlay={dropdownContent}
          trigger={['click']}
          overlayClassName={"save-button-group-overlay"}
          visible={dropdownVisible}
          onVisibleChange={handleDropdownVisibleChange}
        >
          <Button size={"small"} title={"Advanced operations"}>
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      )}
    </Space>
  );
};

export default SaveButtonGroup;
