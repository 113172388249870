import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { startsWithPropKey } from "@utils/StringUtils";
import { DisplayMode } from '@props/RecordProps';
import { getOriginalClassName } from "./alias";

type Command = {
  [propName: string]: (parameters: Array<string>) => void;
};

type UseSlashCommandReturnType = {
  processSlashCommand: (content: string) => void;
  isSlashCommand: (content: string) => boolean;
};

export const useSlashCommand = (
  handleSetConversationBarrier: () => void,
  setDisplay: (display: DisplayMode) => void,
): UseSlashCommandReturnType => {
  const navigate = useNavigate();

  const createSlashCmd = useCallback((parameters: Array<string>): void => {
    const domainName = getOriginalClassName(parameters[0]);
    navigate(`/${domainName}/create`);
  }, [navigate]);

  const commands: Command = useMemo(() => ({
    '/reset': handleSetConversationBarrier,
    '/bye': () => setDisplay("minimal"),
    '/expand': () => setDisplay("maximal"),
    '/collapse': () => setDisplay("normal"),
    // Follow slash commands are in internal alpha testing phase
    '/list': (parameters: Array<string>) => {
      const domainName = getOriginalClassName(parameters[0]);
      navigate(`/${domainName}/list`);
    },
    '/create': createSlashCmd,
    '/new': createSlashCmd,
    '/inbox': () => {
      navigate(`/inbox`);
    },
    '/dashboard': () => {
      navigate(`/`);
    },
  }), [setDisplay, navigate, handleSetConversationBarrier, createSlashCmd]);

  const isSlashCommand = useCallback((content: string) => {
    return startsWithPropKey(content, commands);
  }, [commands]);

  const processSlashCommand = useCallback((content: string) => {
    if (isSlashCommand(content)) {
      if (commands[content]) {
        commands[content]([]);
      } else if (content.includes(" ")) {
        const action = content.split(" ")[0];
        const parameters = content.split(" ").slice(1);
        commands[action]?.(parameters);
        setDisplay("normal");
      }
    }
  }, [commands, setDisplay, isSlashCommand]);

  return { processSlashCommand, isSlashCommand };
};

export default useSlashCommand;
