import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Form, Space } from "antd";
import { SearchOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { fetchFormIdAndExtInfoByType, fetchDomainMeta } from "@utils/FetchUtils";
import { SearchValue, SearchPanelProps, TableMetaProps } from "@props/RecordProps";
import { getEditableController } from "@kernel/EditableComponentsMapping";
import { getFieldTitle } from "@utils/ComponentUtils";
import { getFinderConditions, setFinderCondition } from "@kernel/ServerSideFinder";

export const FinderFormDisplayPanel = (props: SearchPanelProps): ReactElement => {

  const formName = "finder-form";

  const { finderInitiated, setFinderInitiated, domainName,
    columns, fetchDataCallback, tableMode, zIndex } = props;
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [searching, setSearching] = useState<boolean>(false);
  const [domainMeta, setDomainMeta] = useState<Array<TableMetaProps>>([]);

  const search = useCallback((): void  => {
    setSearching(true);
    setFinderCondition(domainName, form.getFieldsValue(), columns);
    fetchDataCallback();
    setSearching(false);
  }, [columns, form, domainName, fetchDataCallback]);

  useEffect(() => {
    if (!finderInitiated) {
      search();
      setFinderInitiated(true);
    }
  }, [setFinderInitiated, finderInitiated, search]);

  useEffect(() => {
    fetchFormIdAndExtInfoByType(domainName, "Finder").then(json => {
      fetchDomainMeta(domainName, json.id).then((domainMeta) => {
        setDomainMeta(domainMeta);
      });
    });
  }, [domainName]);

  const conditions = getFinderConditions(domainName);
  const initialValues: { [propName: string]: SearchValue } = {};
  for (const property in conditions) {
    const condition = conditions[property];
    if (null != condition && null != condition.value) {
      initialValues[condition.columnKey] = condition.value;
    }
  }

  const hideColumnTypes = ['array', 'subTable', 'file', 'image'];

  const fields = domainMeta
    .filter(column => !hideColumnTypes.includes(column.type) && column.display !== false)
    .map(column => {
      const { type, helpText, key, unique, email, title } = column;
      const fieldTitle = getFieldTitle({ domainName, helpText, title, key, unique, email });
      const isEnum = type.indexOf("_enums_") > -1;
      const gc = getEditableController;
      let renderFunc;
      if (type === 'id') {
        renderFunc = gc("long");
      } else if (gc(type) == null) {
        if (isEnum) {
          renderFunc = gc("enum");
        } else {
          renderFunc = gc("object");
        }
      } else {
        renderFunc = gc(type, tableMode);
      }
      const options = undefined;
      const controller = (renderFunc == null) ? undefined : renderFunc({
        updatable: true, type, fieldTitle, form, key, options,
        domainName, fieldValue: initialValues[key], record: undefined, zIndex, multiple: false,
        column
      });
      return controller == null ? undefined : (<Form.Item
        label={fieldTitle}
        name={column.key}
        key={column.key}
      >{controller}</Form.Item>
      );
    });

  const searchButton = (
    <div
      style={{ textAlign: "center", margin: "auto" }}
    >
      <Space
        direction="horizontal"
        size="middle"
      >
        <Button
          title={t("Search")}
          type="primary"
          htmlType="submit"
          onClick={search}
          form={formName}
          disabled={searching}
          loading={searching}
        >
          <span><SearchOutlined /> {t('Search')}</span>
        </Button>
      </Space>
    </div>
  );

  return (
    <div className="finder-form">
      <Form
        layout="inline"
        id={formName}
        form={form}
        colon={false}
        initialValues={initialValues}
      >
        {fields}
      </Form>
      { searchButton}
    </div>
  );

};

export default FinderFormDisplayPanel;
