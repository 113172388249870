import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Input, Space, Tooltip } from 'antd';
import { SERVER_URL } from '@config/base';
import { CheckCircleOutlined, } from '@ant-design/icons';

function setBackendAddr(backendUrl: string): void {
  localStorage.setItem('backendUrl', backendUrl);
  window.location.assign("/login");
}

const padding = <span style={{ paddingRight: "0.5rem" }} />;
const separate = <span className="footer-separator"> | </span>;
const div = <>{padding}{separate} {padding}</>;
const msg = "直接输入后端地址";
const singleSelector = (protocol: string, host: string, port: number, tenant: string, text: string): ReactElement => {
  const linkUrl = `${protocol}://${tenant}.${host}:${port}`;
  const hint = `设置后端为 ${linkUrl}`;
  return (<Tooltip
    title={hint}
    placement="bottom"
  >
    <span
      title={hint}
      style={{ cursor: "pointer" }}
      onClick={(): void => {
        setBackendAddr(linkUrl);
      }} >
      {text}
    </span>
    {padding}
  </Tooltip>);
};

const selector = (protocol: string, host: string, port: number, last?: boolean): ReactElement => {
  return (<>
    {singleSelector(protocol, host, port, "muyan-server", host.toUpperCase())}
    {(last !== true) && div}
  </>);
};

// Define the types for the props
interface SetBackendComponentProps {
  onSetCallback: () => void;
}

const SetBackendComponent: React.FC<SetBackendComponentProps> = ({ onSetCallback }): ReactElement => {
  const [value, setValue] = useState(SERVER_URL);

  const onChange = (appSlug: ChangeEvent<HTMLInputElement>): void => {
    setValue(appSlug.target.value);
  };

  const onClick = (): void => {
    setBackendAddr(value);
    // Call the onSetCallback when the backend URL is set
    onSetCallback();
  };

  return (
    <div className="set-front">
      {padding}
      <Tooltip title={msg}>
        <Input
          style={{ width: '350px', marginRight: "0.5rem" }}
          value={value}
          onChange={onChange}
        />
      </Tooltip>
      {padding}
      <Tooltip
        title={`设置后端为 ${value}`}
      >
        <CheckCircleOutlined onClick={onClick} />
      </Tooltip>
      <br />
      <Space
        size={4}
        style={{
          paddingTop: '10px',
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {selector("https", "lowcode.ltd", 443, false)}
        {selector("https", "muyan.io", 443, false)}
        {selector("https", "muyan.app", 443, true)}
      </Space>
    </div>
  );
};


export default SetBackendComponent;
