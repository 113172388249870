import React, { ReactElement } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import { FunctionEditor } from "./FunctionEditor";
import { CodeEditorDetail } from "../../form/fields";

export const FunctionEditorDetail = (props: DetailPanelProps): ReactElement => {
  const { column, readonly, form, fieldValue, zIndex, onValuesChange } = props;
  if (readonly) {
    return <CodeEditorDetail
      {...props}
    />;
  }
  return <FunctionEditor
    column={column}
    code={fieldValue}
    form={form}
    onValuesChange={onValuesChange}
    zIndex={zIndex}
  />;
};