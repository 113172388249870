import { DashboardProps, DashboardWidgetProps } from '@props/RecordProps';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDashboardWidgets } from "@utils/FetchUtils";
import DashboardWidget from "./DashboardWidget";
import { LargeSpin } from "../../components";
import { Button, Col, Result, Row } from 'antd';
import "./css/dashboard.less";
import { useMediaQuery } from '@utils/hooks';

interface DashboardComponentProps {
  meta: DashboardProps;
  display: boolean;
  triggerRefresh?: boolean | number;
  addWidgetCallback: () => void;
  refreshInterval?: number;
}

const Dashboard = (props: DashboardComponentProps): ReactElement => {
  const { meta, display, triggerRefresh, addWidgetCallback, refreshInterval } = props;
  const { t } = useTranslation();
  const { canCreateWidget } = meta;
  const [groupedWidgets, setGroupedWidgets] = useState<Array<Array<DashboardWidgetProps>>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isMobile = useMediaQuery('(max-width: 480px)');
  const isTabletOrSmallLaptop = useMediaQuery('(min-width: 481px) and (max-width: 1024px)');
  const isLargeScreen = useMediaQuery('(min-width: 1025px) and (max-width: 1200px)');
  const isExtraLarge = useMediaQuery('(min-width: 1201px)');

  const getWidgetCol = (widget: DashboardWidgetProps): number => {
    // Fetch user's config from options
    const optionJson = widget.options;
    const option = JSON.parse(optionJson);
    const userConfigCol = option?.position?.col;

    // If it's a laptop/desktop, use the configured column span or default
    if (isExtraLarge) {
      return userConfigCol ?? 8;  // 24 / 3 = 8 (for 3 per row)
    }

    if (isLargeScreen) {
      return 12; // 24 / 2 = 12 (for 2 per row)
    }

    // If it's a tablet or small laptop, 2 per row
    if (isTabletOrSmallLaptop || isMobile) {
      return 24;  // 24 / 2 = 12 (for 2 per row)
    }

    return 24;  // Default case (unlikely, but as a fallback)
  };


  const loadDashboardWidgetsMeta = useCallback(() => {
    setLoading(true);
    getDashboardWidgets(meta.id).then(widgets => {
      const newGroupedWidgets = [] as Array<Array<DashboardWidgetProps>>;
      let currentNumOfCols = 0;
      let currentIdx = 0;
      widgets.forEach(widget => {
        //Default display as 12 cols if not set in options
        const myCol = getWidgetCol(widget);

        if (newGroupedWidgets.length === 0) {
          newGroupedWidgets[0] = [widget];
          currentNumOfCols += myCol;
        } else {
          if ((currentNumOfCols + myCol) > 24) {
            newGroupedWidgets.push([widget]);
            currentIdx++;
            currentNumOfCols = myCol;
          } else {
            newGroupedWidgets[currentIdx].push(widget);
            currentNumOfCols += myCol;
          }
        }
      });
      setGroupedWidgets(newGroupedWidgets);
    }).finally(() => setLoading(false));
    // include triggerRefresh to refresh the widget list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.id, triggerRefresh]);

  useEffect(() => {
    loadDashboardWidgetsMeta();
  }, [loadDashboardWidgetsMeta]);

  const emptyDashboardMessage = <>{groupedWidgets.length === 0 && <Result
    title={t("No widget defined(or you don't have access to any)")}
    extra={
      canCreateWidget && (
        <Button
          type="primary"
          onClick={() => addWidgetCallback()}
        >
          {t('Create new dashboard widget')}
        </Button>
      )}
  />}</>;

  return (display && !loading) ? (
    <div>
      {emptyDashboardMessage}
      {groupedWidgets.length > 0 && groupedWidgets.map((row: Array<DashboardWidgetProps>, idx: number) => {
        return (
          <Row key={idx}>
            {row.map((widget: DashboardWidgetProps, widgetIdx: number) => {
              return (
                <Col
                  span={getWidgetCol(widget)}
                  key={widget.id}
                  className={`dashboard-col ${(widgetIdx === row.length - 1) ? 'dashboard-col-last' : ''}`}
                  //Follow style is to make widget in one row all same height
                  //https://github.com/ant-design/ant-design/issues/9154
                  style={{ display: 'inline-flex', alignSelf: "stretch" }}
                >
                  <DashboardWidget
                    meta={widget}
                    key={widget.id}
                    refreshCallback={() => loadDashboardWidgetsMeta()}
                    refreshInterval={(widget.refreshInterval == 0 || widget.refreshInterval == null) ?
                      refreshInterval : widget.refreshInterval}
                  />
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  ) : <LargeSpin />;
};

export default Dashboard;

