import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Input, Table } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SorterResult } from 'antd/lib/table/interface';
import { v4 as uuid } from "uuid";

import './css/App.css';

import {
  DataApiResultProps,
  DynamicSelectValues,
  EnumValues,
  fetchDataCallbackProps,
  FetchListOfDomainDataProps,
  FetchSearchResultProps,
  FormProps,
  ListProps,
  ObjectColumns,
  ObjectValues,
  RecordProps, SearchConditions,
  TableMetaProps,
  TableMode,
} from '@props/RecordProps';
import {
  fetchCanCreate,
  fetchDomainMeta,
  fetchFormExtInfo, fetchFormIdAndExtInfoByName,
  fetchFormIdAndExtInfoByType,
  fetchListOfDomainData,
  fetchListOfRelateDomainData,
  fetchSearchResult,
  getHasDefaultFilters,
} from '@utils/FetchUtils';
import {
  isActionColumn,
  tableColumnMaxWidth,
  calcPossibleObjectColumnIds,
  DataAndPossibleObjectIdsProps,
  refreshMetas
} from '@utils/ColumnsUtils';
import { calculateTableAndColumnWidth } from '@utils/ComponentUtils';
import { LargeSpin, getPagination as getTablePagination, AccessDeniedComponent } from "../../components";
import { isOwnerColumn } from "@kernel/DisplayComponentsMapping";
import {
  isSetEqual,
  pureObjectIsEmpty,
  stopPropagationAndPreventDefault
} from "@utils/ObjectUtils";
import { isSorterChange, wrapColumnSorter } from "@kernel/ColumnSorters";
import operationColumn from "./OperationColumnMeta";
import { PageHeader } from "../";
import {
  getAppliedSearchConditionsForDomain,
  getSearchConditionsForDomain,
  setSearchProps
} from "@kernel/ServerSideSearcher";
import SearchPanel from "./SearchPanel";
import { TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { getFinderConditions } from '@kernel/ServerSideFinder';
import { initPagination, paginationReducer, dataReducer, initData } from '@utils/hooks';
import { TableModeToClassNameMap } from '@config/ui';
import { FetchRelateDomainProps } from '@utils/FetchUtilsProps';
import CardList from '../cardList/CardList';
import { emptyMethod } from "@utils/Constants";

const DefaultListForm = { id: Infinity } as FormProps;

const ListComponent: React.FC<ListProps> = (props: ListProps) => {
  const {
    domainName, ownerClass, ownerId, columnNameInOwnerClass, tableMode,
    onSelectRow, readonly, initShowCreateModal, fieldType,
    initDomainId, initDomainPage, zIndex, isCurrentActiveTab, formId,
    multiple, displayForm, defaultSearchConditions,
  } = props;

  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [tableModeState, setTableModeState] = useState<TableMode>(tableMode);
  const isFinderMode = (tableModeState === 'finder');
  const [finderInitiated, setFinderInitiated] = useState<boolean>(false);
  const isDetailDrawerMode = (tableModeState === "detail-drawer");
  const isDetailMode = (tableModeState === "detail" || isDetailDrawerMode);
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as Array<TableMetaProps>);
  const [columnLength, setColumnLength] = useState<number>(0);
  const [listForm, setListForm] = useState<FormProps>(DefaultListForm);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [searchInputs, setSearchInputs] = useState<{ [propName: string]: typeof Input }>({});
  const [ownerColumn, setOwnerColumn] = useState<TableMetaProps>();
  const [clickTimeout, setClickTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [currentSelectedRow, setCurrentSelectedRow] = useState<number>();
  const [currentSorter, setCurrentSorter] = useState<SorterResult<RecordProps>>();
  const [paginationState, paginationDispatch] = useReducer(paginationReducer, undefined, () => initPagination(tableModeState));
  const [dataState, dataDispatch] = useReducer(dataReducer, initData);
  const [updatedRows, setUpdatedRows] = useState<Array<number>>([]);
  const [calculatedColumns, setCalculatedColumns] = useState<Array<TableMetaProps>>([]);
  const fetchDataCallbackRef = useRef<(props: fetchDataCallbackProps) => void>(emptyMethod);
  const setRenderTrigger = useState(uuid())[1];

  useEffect(() => {
    if (updatedRows.length === 0) {
      return;
    }
    const timer = setTimeout(() => {
      setUpdatedRows([]);
    }, 3000);

    return () => clearTimeout(timer);
  }, [updatedRows]);

  useEffect(() => {
    const newUpdatedRows: Array<number> = [];
    if (dataState.refreshMode != null && ['realtime', 'auto'].includes(dataState.refreshMode)) {
      dataState.data?.forEach(record => {
        if (dataState.insertLines?.map(r => r?.id).includes(record.id)) {
          newUpdatedRows.push(record.id);
        }
        if (dataState.deleteLines?.map(r => r?.id).includes(record.id)) {
          newUpdatedRows.push(record.id);
        }
        if (dataState.updateLines?.map(r => r?.id).includes(record.id)) {
          newUpdatedRows.push(record.id);
        }
      });
    }
    if (newUpdatedRows.length > 0) {
      setUpdatedRows(newUpdatedRows);
    }
  }, [
    dataState.updateLines, dataState.deleteLines, dataState.insertLines, dataState.refreshMode,
    dataState.data
  ]);

  useEffect(() => {
    if (listForm?.extInfo?.defaultTableMode != null) {
      setTableModeState(listForm.extInfo?.defaultTableMode);
    }
  }, [listForm, domainName]);

  useEffect(() => {
    setTableModeState(tableMode);
  }, [tableMode, domainName]);

  useEffect(() => {
    setHasPermission(true);
    setListForm(DefaultListForm);
    dataDispatch({ type: 'set', payload: initData });
    fetchCanCreate(domainName)
      .then(json => setCanCreate(json.create))
      .catch(e => {
        console.error(`Failed to get canCreate of domain ${domainName}: ${e}`);
      });
  }, [domainName]);

  const setState = useCallback((records: DataApiResultProps,
    newCurrent: number, refreshListFormId?: number): void => {
    dataDispatch({ type: 'set', payload: { data: records.data } });
    refreshSelectedData(records.data, dataState.selectedData ?? []);
    const dataAndPossibleObjectIds = calcPossibleObjectColumnIds(records.data);
    const fetchDataWrap = (): void => fetchDataCallbackRef.current({
      newCurrent: paginationState.current,
      pageSizeNum: paginationState.pageSize,
      refreshListFormId: listForm.id,
      isInit: false,
      ownerColumn,
      useCache: true,
    });
    if (refreshListFormId != null || listForm.id !== Infinity) {
      refreshMetas(dataAndPossibleObjectIds, refreshListFormId ?? listForm.id,
        domainName, dataDispatch, setLoading, setColumns,
        tableModeState, fetchDataWrap, isFinderMode, zIndex, ownerId, ownerClass);
    }
    paginationDispatch({
      type: 'set', payload: { 'current': newCurrent, 'total': records.total }
    });
    setRenderTrigger(uuid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    domainName, isFinderMode, listForm.id, ownerClass, ownerColumn, ownerId, paginationState, t, tableModeState,
    zIndex, dataState.data
  ]);

  /**
   * refresh selected data and remove those which is not in backend return list
   * @param dataArray data array to be refreshed and filtered
   * @param prevSelectedData previously selected data
   */
  const refreshSelectedData = useCallback((dataArray: Array<RecordProps>,
    prevSelectedData: Array<RecordProps>): Array<RecordProps> => {
    if (dataArray?.length === 0 && dataState.selectedData?.length != 0) {
      dataDispatch({ type: 'set', payload: { selectedData: [] } });
      return dataArray;
    }
    const prevSelectedIds = new Set(prevSelectedData.map(d => d.id));
    const newSelectedData: Array<RecordProps> = [];
    const newSelectedIds = new Set();
    dataArray.forEach((row: RecordProps) => {
      if (prevSelectedIds.has(row.id)) {
        newSelectedData.push(row);
        newSelectedIds.add(row.id);
      }
    });
    if (!isSetEqual(prevSelectedIds, newSelectedIds)) {
      dataDispatch({ type: 'set', payload: { selectedData: newSelectedData } });
    }
    return dataArray;
  }, [dataState.selectedData, dataDispatch]);

  fetchDataCallbackRef.current = (props: fetchDataCallbackProps) => {
    const {
      newCurrent,
      pageSizeNum,
      refreshListFormId,
      isInit,
      ownerColumn,
      useCache,
      listFormParam,
      showLoadingIndicator,
    } = props;
    const showLoading = (showLoadingIndicator == null) || (showLoadingIndicator === true);
    if (showLoading) {
      setLoading(true);
    }
    let searchConditions: SearchConditions | undefined;
    if (defaultSearchConditions) {
      searchConditions = defaultSearchConditions;
    } else {
      const appliedSearchConditions = getAppliedSearchConditionsForDomain(domainName);
      // 获取搜索条件，默认 apply local storage 中保存的搜索条件
      searchConditions = isFinderMode ?
        getFinderConditions(domainName) : appliedSearchConditions;
    }
    const ic = (listFormParam ?? listForm)?.extInfo?.conditions;
    const withImplicitlySearchConditions = (ic != null) ?
      { ...ic, ...searchConditions } : searchConditions;

    const fetchDataInnerWrap = (): void => fetchDataCallbackRef.current({
      newCurrent: paginationState.current,
      pageSizeNum: paginationState.pageSize,
      refreshListFormId: listForm.id,
      isInit: false,
      ownerColumn,
      useCache: useCache ?? true,
    });

    const max = pageSizeNum;
    let retrieveDataMethod: (
      undefined | typeof fetchSearchResult | typeof fetchListOfRelateDomainData | typeof fetchListOfDomainData
      ) = undefined;
    let retrieveDataParameters = {} as (
      FetchListOfDomainDataProps | FetchSearchResultProps | FetchRelateDomainProps
      );
    // 如果缓存中保存了搜索条件， 使用搜索条件到后台去搜索
    if (isFinderMode && isInit) {
      //If is a finder panel and is init, don't retrieve data by default
      refreshMetas({
          data: [] as Array<RecordProps>,
          possibleObjectIds: {} as ObjectColumns
        }, refreshListFormId, domainName, dataDispatch, setLoading, setColumns,
        tableModeState, fetchDataInnerWrap, isFinderMode, zIndex, ownerId, ownerClass);
    } else {
      // 如果是瞬态字段，也使用搜索 API 来获取数据
      const isTransientField: boolean = (fieldType === 'TRANSIENT_FIELD');
      // 如果搜索条件不为空，或者在 finder 模式下，或查是瞬态字段，使用 search API 来获取数据
      if (!pureObjectIsEmpty(withImplicitlySearchConditions) || isFinderMode || isTransientField) {
        // Finder 模式下，传递当前搜索的对象在主对象中的字段名，
        // 以便于按照这个上下文进行逻辑客制化
        // Search 模式下，传递主对象在当前搜索对象中的字段明
        // 以便于限制只获取与主对象关联的对象
        const occn = (isFinderMode) ? columnNameInOwnerClass : ownerColumn?.key;
        retrieveDataMethod = fetchSearchResult;
        retrieveDataParameters = {
          domainName, ownerClass, ownerIds: [ownerId],
          searchConditions: withImplicitlySearchConditions,
          ownerClassColumnName: occn,
          max, current: newCurrent,
        } as FetchSearchResultProps;
      } else {
        const hasOwnerInfo = (ownerId != null &&
          columnNameInOwnerClass != null &&
          ownerClass != null);
        // 如果是在关联对象页面，则只获取与当前主对象关联的对象
        if (hasOwnerInfo && isDetailMode) {
          retrieveDataMethod = fetchListOfRelateDomainData;
          retrieveDataParameters = {
            domainName: ownerClass, current: newCurrent,
            columnName: columnNameInOwnerClass, ownerId, max,
            ownerClass, ownerClassColumnName: ownerColumn?.key,
            useCache,
          } as FetchRelateDomainProps;
        } else { // 不带搜索条件获取数据
          retrieveDataMethod = fetchListOfDomainData;
          retrieveDataParameters = {
            formId, domainName, current: newCurrent, max, useCache
          } as FetchListOfDomainDataProps;
        }
      }
      if (null != retrieveDataMethod) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        retrieveDataMethod(retrieveDataParameters)
          .then((records: DataApiResultProps) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const hasPermissionBasedOnStatusCode = (records?.status !== 403);
            setHasPermission(hasPermissionBasedOnStatusCode);
            if (!hasPermissionBasedOnStatusCode) {
              setLoading(false);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              throw new Error(records.message);
            } else {
              setState(records, newCurrent, refreshListFormId);
              return records.data;
            }
          })
          .then((data: Array<RecordProps>) => refreshSelectedData(data, (dataState.selectedData ?? [])))
          .then((data: Array<RecordProps>) => calcPossibleObjectColumnIds(data))
          .then((props: DataAndPossibleObjectIdsProps) => refreshMetas(props, refreshListFormId, domainName,
            dataDispatch, setLoading, setColumns,
            tableModeState, fetchDataInnerWrap, isFinderMode, zIndex, ownerId, ownerClass))
          .catch((error: Error) => console.error(`Error getting domain ${domainName} data: ` + error));
      }
    }
  };

  const fetchDataWrap = useCallback((useCache?: boolean, showLoadingIndicator?: boolean): void => {
    fetchDataCallbackRef.current({
      newCurrent: paginationState.current,
      pageSizeNum: paginationState.pageSize,
      refreshListFormId: listForm.id,
      isInit: false,
      ownerColumn,
      useCache: useCache ?? true,
      showLoadingIndicator,
    });
  }, [listForm.id, ownerColumn, paginationState]);

  // 根据最新的数据刷新 action 列的定义，
  // 主要是更新其中的相关回调函数的参数为最新的数据
  useEffect(() => {
    if (dataState.data == null || dataState.data.length === 0) {
      return;
    }
    const columnRefreshBasedOnLatestData = columns.map(c => {
      if (isActionColumn(c.key)) {
        return operationColumn({
          domainName,
          ownerClass,
          data: dataState.data ?? [],
          ownerId,
          tableMode: tableModeState,
          zIndex,
          fetchDataCallback: fetchDataWrap,
          deleteCallback: (id: number) => {
            const newData = (dataState.data ?? []).filter(d => d.id !== id);
            dataDispatch({ type: 'set', payload: { data: newData } });
          },
          updateFormName: listForm.extInfo?.updateFormName,
        });
      }
      return c;
    });
    setColumns(columnRefreshBasedOnLatestData);
    // Ignore columns, current, fetchDataCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState.data, domainName, ownerClass, ownerId, paginationState.pageSize, tableModeState]);

  useEffect(() => {
    const formType = isFinderMode ? "Finder" : "List";
    setLoading(true);
    const metaPostProcess = (json: FormProps): void => {
      setListForm(json);
      const refreshMode = json.extInfo?.dataRefreshMode;
      dataDispatch({ type: "set", payload: { refreshMode } });
      fetchDomainMeta(domainName, json.id).then(domainMeta => {
        const owner = domainMeta.find(m => isOwnerColumn(ownerClass, columnNameInOwnerClass, ownerId, m));
        //Set current list page's owner column to ownerColumn
        //Example: When update a contract, and contract line is displayed on a list
        //Then set ownerColumn of this contract line display table to column meta of
        // "contract" column inside contract line domain
        setOwnerColumn(owner);
        // Get current saved search conditions
        const filterConditions = getSearchConditionsForDomain(domainName);
        if (filterConditions == null) {
          // 如果当前的列表没有默认的搜索条件，则看一下是否有默认的搜索条件
          // 如果有默认搜索条件，则在这里不去后端获取数据，因为在 PredefinedFilter 中会获取数据
          getHasDefaultFilters(domainName).then(hasDefaultFilter => {
            if (!hasDefaultFilter.result) {
              fetchDataCallbackRef.current({
                newCurrent: 1,
                pageSizeNum: paginationState.pageSize,
                refreshListFormId: json.id,
                isInit: true,
                ownerColumn: owner,
                listFormParam: json,
              });
            } else {
              setLoading(false);
            }
          }).catch(e => console.error(`Failed to get default filters for ${domainName}/${json.id}: ${e}`));
        } else {
          fetchDataCallbackRef.current({
            newCurrent: 1,
            pageSizeNum: paginationState.pageSize,
            refreshListFormId: json.id,
            isInit: true,
            ownerColumn: owner,
            listFormParam: json,
          });
        }
      }).catch(e => console.error(`Failed to get domain meta for ${domainName}/${json.id}: ${e}`));
    };
    if (formId != null) {
      fetchFormExtInfo(formId).then(json => {
        metaPostProcess(json);
      }).catch(e => {
        console.error(`Failed to get form ${formId} of domain ${domainName}: ${e}`);
        setHasPermission(false);
        setLoading(false);
      });
    } else if (displayForm) {
      fetchFormIdAndExtInfoByName(domainName, displayForm).then(json => {
        metaPostProcess(json);
      }).catch(e => {
        console.error(`Failed to get form ${formType} of domain ${domainName}: ${e}`);
        setHasPermission(false);
        setLoading(false);
      });
    } else {
      fetchFormIdAndExtInfoByType(domainName, formType).then(json => {
        metaPostProcess(json);
      }).catch(e => {
        console.error(`Failed to get form ${formType} of domain ${domainName}: ${e}`);
        setHasPermission(false);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName, isFinderMode, displayForm, ownerClass, ownerId, formId, columnNameInOwnerClass]);

  useEffect(() => {
    wrapColumnSorter(columns);
    setColumns(columns);
  }, [columns, domainName]);

  const isRowUpdated = useCallback(
    (row: RecordProps) => updatedRows.includes(row.id),
    [updatedRows]
  );

  if (!isFinderMode) {
    setSearchProps({
      domainName, columns, setSearchInputs,
      searchInputs, enumValues: (dataState.enumValues ?? {} as EnumValues),
      current: paginationState.current,
      refreshSearchResult: setState,
      ownerClass, ownerIds: (ownerId == null ? [] : [ownerId]), zIndex,
      ownerClassColumnName: ownerColumn?.key,
      max: paginationState.pageSize,
      dynamicSelectValues: dataState.dynamicSelectValues ?? {} as DynamicSelectValues,
    });
  }

  useEffect(() => {
    const res = calculateTableAndColumnWidth(domainName, columns);
    setCalculatedColumns(res.columns);
    setColumnLength(res.columnLength);
  }, [columns, domainName]);

  const onSelectData = (selectedRows: Array<RecordProps>): void => {
    dataDispatch({ type: 'set', payload: { selectedData: selectedRows } });
    if (null != onSelectRow) {
      onSelectRow(selectedRows);
    }
  };

  const rowSelection: TableRowSelection<RecordProps> = {
    // 选中行时候的回调函数，记录选中的行
    onChange: (selectedRowKeys: React.Key[], selectedRows: Array<RecordProps>) => onSelectData(selectedRows),
    // 每行前面的选择框的属性
    getCheckboxProps: (record: RecordProps) => ({
      disabled: false,
      name: record.name,
    }),
    type: (isFinderMode) ? (multiple ? "checkbox" : "radio") : "checkbox",
    columnTitle: (isFinderMode) ? (multiple ? undefined : (<SelectOutlined title={t("Select record")} />)) : undefined,
  };

  const xScroll = (columnLength > tableColumnMaxWidth) ? columnLength : true;
  const noData = (dataState.data?.length === 0);
  // 对应到 css 中，是设定了 .ant-table-body 的 max-height 属性
  // FIXME 将这里的 css 设定从 ts 文件迁移到 css 中
  const heightPercent = isDetailDrawerMode ? '80vh' : '100vh';
  const yScroll = noData ? `calc(${heightPercent} - 495px)` : `calc(${heightPercent} - 251px)`;
  const isEmptyClassName = (noData) ? 'ant-table-no-data' : 'ant-table-has-data';

  const cardFetchDataParams = {
    newCurrent: paginationState.current,
    pageSizeNum: paginationState.pageSize,
    refreshListFormId: listForm.id,
    isInit: false,
    ownerColumn: ownerColumn,
    useCache: false,
    listFormParam: listForm
  };

  const cardList = (
    <CardList
      dataState={dataState}
      dataDispatch={dataDispatch}
      listForm={listForm}
      domainName={domainName}
      page={'INLINE_DISPLAY'}
      zIndex={zIndex}
      columns={columns}
      deleteCallback={() => fetchDataCallbackRef.current(cardFetchDataParams)}
      fetchDataCallback={fetchDataCallbackRef.current}
      enumValues={dataState.enumValues}
      objectValues={dataState.objectValues}
      ownerId={ownerId}
      ownerClass={ownerClass}
      paginationState={paginationState}
      paginationDispatch={paginationDispatch}
    />
  );

  const tableList = (<Table
    className={`${TableModeToClassNameMap[tableModeState]} ${isEmptyClassName}`}
    columns={calculatedColumns}
    scroll={{ x: xScroll, y: yScroll }}
    dataSource={dataState.data?.filter((d: RecordProps) => (d.clientSideShow === undefined || d.clientSideShow === true))}
    rowKey={(r): number => r.id}
    onChange={(pagination: TablePaginationConfig, filters, sorter: SorterResult<RecordProps> | SorterResult<RecordProps>[]): void => {
      const pageSize = (pagination.pageSize == null) ? paginationState.pageSize : pagination.pageSize;
      const simpleSorter = sorter as SorterResult<RecordProps>;
      paginationDispatch({ type: 'set', payload: { 'pageSize': pageSize } });
      const current = (pagination.current == null) ? 1 : pagination.current;
      if (!isSorterChange(simpleSorter, currentSorter)) {
        fetchDataCallbackRef.current({
          newCurrent: current,
          pageSizeNum: pageSize,
          refreshListFormId: listForm.id,
          isInit: false,
          ownerColumn
        });
      } else {
        // ##### Attention #####
        // As of 2020-11-19, client side sort is supported, so should not go
        // to server to retrieve data If only sorter changed
        setCurrentSorter(simpleSorter);
      }
    }}
    rowClassName={(record: RecordProps, index: number) => {
      if (isRowUpdated(record)) {
        return 'websocket-insert-row';
      }
      return (index % 2 === 1) ? 'odd' : 'even';
    }}
    pagination={getTablePagination(paginationState)}
    rowSelection={{
      selectedRowKeys: dataState.selectedRowKeys,
      ...rowSelection,
    }}
    onRow={(record: RecordProps) => {
      return {
        onClick: (event: React.MouseEvent<unknown>) => {
          stopPropagationAndPreventDefault(event);
          if (isFinderMode) {
            const alreadySelected = (dataState.selectedRowKeys ?? []).filter((k: number) => k === record.id).length > 0;
            // 单选, 如果当前单击行是非选中状态，则设置其为选中状态, 如果当前单击行是选中状态，则取消其选中状态
            // 多选, 如果已经选择，则取消选择, 如果没有选择，则选择
            const newSelectedData = (multiple !== true) ? (alreadySelected ? [] : [record]) : (alreadySelected ?
              (dataState.selectedData ?? []).filter((k: RecordProps) => k.id !== record.id) : [...(dataState.selectedData ?? []), record]);
            dataDispatch({ type: 'set', payload: { selectedData: newSelectedData } });
            if (onSelectRow != null) {
              onSelectRow(newSelectedData);
            }
            return;
          }
          // 以下是处理在非 search 的列表页面，双击选中当前行的逻辑
          const clearTimer = (): void => {
            clearTimeout((clickTimeout as unknown) as number);
            setClickTimeout(undefined);
          };
          const firstClickHandle = (): void => {
            setCurrentSelectedRow(record.id);
            const v = setTimeout(() => {
              clearTimer();
            }, 500);
            setClickTimeout(v);
          };
          if (clickTimeout != null) {
            const firstClickRow = currentSelectedRow;
            const secondClickRow = record.id;
            if (firstClickRow === secondClickRow) {
              const alreadySelected = (dataState.selectedRowKeys ?? []).filter((k: number) => k === record.id).length > 0;
              dataDispatch({
                type: 'set', payload: {
                  selectedData: (alreadySelected ?
                    (dataState.selectedData ?? []).filter((d: RecordProps) => d.id !== record.id) :
                    [...(dataState.selectedData ?? []), record])
                }
              });
              clearTimer();
            } else {
              firstClickHandle();
            }
          } else {
            firstClickHandle();
          }
        }
      };
    }} />);

  const mainContent = (tableModeState === 'card-list') ? cardList : tableList;

  const searchPanel = useMemo(() => {
    return (
      <SearchPanel
        finderInitiated={finderInitiated}
        setFinderInitiated={setFinderInitiated}
        numOfRow={dataState.data?.length ?? 0}
        domainName={domainName}
        columns={calculatedColumns}
        refresh={Math.random()}
        enumValues={dataState.enumValues ?? {} as EnumValues}
        objectValues={dataState.objectValues ?? {} as ObjectValues}
        fetchDataCallback={fetchDataWrap}
        tableMode={tableModeState}
        zIndex={zIndex}
      />
    );
  }, [
    dataState.data, domainName, calculatedColumns, dataState.enumValues,
    dataState.objectValues, fetchDataWrap, tableModeState, zIndex, finderInitiated
  ]);


  const setDataWrapWithUseCallback = useCallback((data: Array<RecordProps>) =>
    dataDispatch({ type: 'set', payload: { data } }), [dataDispatch]);

  const fetchDataCallbackWrapWithUseCallback = useCallback((showLoading?: boolean) =>
    fetchDataWrap(false, showLoading), [fetchDataWrap]);

  const pageHeader = useMemo(() => {
    return (calculatedColumns.length > 0 && hasPermission) &&
      <PageHeader
        isCurrentActiveTab={isCurrentActiveTab}
        initDomainId={initDomainId}
        initDomainPage={initDomainPage}
        initShowCreateModal={initShowCreateModal}
        listForm={listForm}
        tableMode={tableModeState}
        domainName={domainName}
        data={dataState.data ?? []}
        selectedData={dataState.selectedData ?? []}
        columns={calculatedColumns}
        enumValues={dataState.enumValues ?? {} as EnumValues}
        objectValues={dataState.objectValues ?? {} as ObjectValues}
        setData={setDataWrapWithUseCallback}
        fetchDataCallback={fetchDataCallbackWrapWithUseCallback}
        initialCanCreate={canCreate}
        ownerId={ownerId}
        ownerClass={ownerClass}
        columnNameInOwnerClass={columnNameInOwnerClass}
        ownerColumn={ownerColumn?.key}
        readonly={readonly}
        zIndex={zIndex + 1}
        dataState={dataState}
        dataDispatch={dataDispatch}
        setTableMode={setTableModeState}
        createFormName={listForm.extInfo?.createFormName}
      />;
  }, [
    calculatedColumns, hasPermission, isCurrentActiveTab, initDomainId, initDomainPage, initShowCreateModal, listForm,
    tableModeState, domainName, dataState, dataDispatch, setDataWrapWithUseCallback,
    fetchDataCallbackWrapWithUseCallback, canCreate, ownerId, ownerClass, columnNameInOwnerClass,
    ownerColumn, readonly, zIndex, setTableModeState
  ]);

  const cssStyle = `list-table-container ${tableModeState}-table-container list-${domainName}-${tableModeState}-table-container`;

  const displayDataElement = (<div key={`list-form-${formId}-${domainName}`}>
    {pageHeader}
    {loading ? <LargeSpin /> : <div className={cssStyle}>{searchPanel}{mainContent}</div>}
  </div>);
  const accessDeninedComponent = (<AccessDeniedComponent
    showHomepageButton={!isFinderMode && !isDetailMode && !isDetailDrawerMode}
  />);
  return hasPermission ? displayDataElement : accessDeninedComponent;
};

export default ListComponent;
