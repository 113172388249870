//TODO 把所有的 domainName 的常量都放到这个配置文件里面来

export const UserDomainName = "tech_muyan_security_User";
export const FormTypeDomainName = "tech_muyan_enums_FormType";
export const RoleDomainName = "tech_muyan_security_Role";
export const MessageDomainName = "Message";

/** 文件导入作业的状态字段 */
export const FileImportStatusEnumType = "tech_muyan_enums_FileImportStatus";
export const DynamicFormDomainName = "tech_muyan_dynamic_form_DynamicForm";
export const DynamicMenuDomainName = 'tech_muyan_dynamic_form_DynamicMenu';

/** Action 执行结果字段的类型 */
export const ExecuteStatusEnumType = 'tech_muyan_enums_ExecuteStatus';

/** Message Severity 的类型 */
export const MessageSeverityEnumType = "tech_muyan_enums_MessageSeverity";

/** Task 的运行类型 */
export const TaskTriggerMethodEnumType = "tech_muyan_enums_TaskTriggerMethod";

/** 附件字段的 Domain 类型 */
export const StorageFieldValueDomainName = "tech_muyan_storage_StorageFieldValue";

/** 设备状态 */
export const EquipmentStatusEnumType = "tech_muyan_mes_enums_EquipmentStatus";

/** 排产状态 */
export const TaskScheduleTypeEnumType = "tech_muyan_mes_enums_TaskScheduleType";

/** Comment 评论的状态 */
export const CommentStatusEnumType = "tech_muyan_enums_CommentStatus";

export const EquipmentStateEnumType = "tech_muyan_mes_enums_EquipmentState";

/** Feedback Domain 的名称 */
export const FeedbackDomainName = "tech_muyan_feedback_Feedback";

/** Dynamic Filter 的名称 */
export const DynamicFilterDomainName = "tech_muyan_dynamic_form_DynamicFilter";
