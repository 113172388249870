import { RecordProps, SaveRecordProps } from "@props/RecordProps";
import { isNumeric } from "@utils/DecimalUtils";
import { isObject } from "@utils/ObjectUtils";
import dayjs from "dayjs";

window.__moment = dayjs;

/**
 * 枚举字段的转换函数
 * @param record 记录
 * @param value 要转换的枚举值，从后台获取，格式为 {id: xxx},
 * 或者直接是 id 形式 "xxx" 或者 xxx
 */
export const transferObjectOrObjects = (
  record: RecordProps, value: { id: number } | Array<number>
): number | Array<number> | { id: number } | null => {
  if (Array.isArray(value)) {
    return value;
  }
  if (isObject(value) && "id" in value) {
    return value;
  }
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string' && isNumeric(value)) {
    return parseInt(value);
  }
  return null;
};

/**
 * 日期字段值的转换函数
 * @param record 记录
 * @param value 要转换的日期值，从后台返回前台的日期值是字符串，
 * 在将值传递给 antd 的 DatePicker 控件进行显示之前，需要先转换为 Moment 对象
 */
export const transferStringToMomentObj = (
  record: RecordProps, value: string
): dayjs.Dayjs | undefined => {
  return value == null ? undefined : dayjs(value);
};

export const transferStringToTagList = (record: RecordProps, value: string): string[] | number[] | undefined => {
  if (!value) {
    return undefined;
  }
  return JSON.parse(value);
};

export const transferZonedDatetimeToMomentObj = (
  record: RecordProps, value: string
): dayjs.Dayjs| undefined => {
  return value == null ? undefined : dayjs(value.includes('[') ? value.split('[')[0] : value);
};

export const transferAndFormatJson = (
  record: RecordProps, value: string
): string | undefined => {
  try {
    return (value == null) ? undefined : JSON.stringify(JSON.parse(value), null, 2);
  } catch (e) {
    return value;
  }
};

export const transferObjectIds = (
  record: RecordProps, value: string
): Array<string> | undefined => {
  return value == null ? undefined : value.split(",");
};

export const transferSubTable = (
  record: RecordProps, value: RecordProps[]
): RecordProps[] => {
  // console.log("transferSubTable", value);
  return value;
};

export const transferMomentObjToLocalDateString = (record: SaveRecordProps, date: dayjs.Dayjs): string => {
  return date?.format("YYYY-MM-DD");
};

export const transferMomentObjToLocalDateTimeString = (record: SaveRecordProps, date: dayjs.Dayjs): string => {
  return date?.format("YYYY-MM-DDTHH:mm:ss");
};

export const transferMomentObjToString = (record: SaveRecordProps, date: dayjs.Dayjs): string => {
  return date?.toISOString();
};

export const transferTagListToString = (record: SaveRecordProps, value?: string[]): string | undefined => {
  if (!value) {
    return undefined;
  }
  return JSON.stringify(value);
};
