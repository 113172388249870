import React, { useEffect, useState } from 'react';
import { Modal, Space } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import SetBackendComponent from "./SetBackend";
import { useTranslation } from 'react-i18next';

interface SetBackendModalProps {
  visibleProp: boolean;
}

const SetBackendModal: React.FC<SetBackendModalProps> = ({ visibleProp }) => {
  const [visible, setVisible] = useState(visibleProp);
  const { t } = useTranslation();

  useEffect(() => {
    setVisible(visibleProp);
  }, [visibleProp]);

  const showModal = (): void => {
    setVisible(true);
  };

  const handleOk = (): void => {
    setVisible(false);
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  return (
    <>
      <Space direction="horizontal" onClick={showModal} >
        <ApiOutlined />
        {t('Set backend')}
      </Space>
      <Modal
        title={undefined}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={"footer-content"}
        closeIcon={null}
        footer={null}
        closable={false}
        width={450}
      >
        <SetBackendComponent onSetCallback={() => setVisible(false)} />
      </Modal>
    </>
  );
};

export default SetBackendModal;
