import React, { ReactElement } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import { TableChart } from "./TableChart";

export const TableChartDetailPanel = (props: DetailPanelProps): ReactElement => {
  const { fieldValue, record, column } = props;
  const { key } = column;

  return <TableChart
    data={fieldValue ?? record?.[key]}
    column={column}
  />;
};
