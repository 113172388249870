import { Form, FormInstance } from "antd";
import { GetColumnValueCallback, SaveRecordProps } from "@props/RecordProps";
import { GetColumnValueKey, OnSaveCallbackKey } from "@utils/Constants";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";

export type OnSavedCallback = (res: {
  data?: SaveRecordProps;
  status: "fail" | "success";
}) => void;

export const useCustomHookForm = (): FormInstance => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue(GetColumnValueKey, {});
  }, [form]);

  return form;
};

export const getFormFieldsValue = (form: FormInstance): Record<string, unknown> => {
  const values = form.getFieldsValue(true);
  const getColumnValueRecord: Record<string, GetColumnValueCallback> = form.getFieldValue(GetColumnValueKey);
  if (getColumnValueRecord) {
    Object.keys(getColumnValueRecord).forEach((key) => {
      values[key] = getColumnValueRecord[key]();
    });
  }
  return values;
};

export const getCustomValueCallback = (form: FormInstance): Record<string, GetColumnValueCallback> => {
  return form.getFieldValue(GetColumnValueKey);
};

export const getAllOnSavedCallback = (form: FormInstance): OnSavedCallback[] => {
  const callbackRecords: Record<string, OnSavedCallback> = form.getFieldValue(OnSaveCallbackKey) ?? {};
  return Object.values(callbackRecords);
};

export const registerOnSavedCallback = (form: FormInstance, callback: OnSavedCallback): () => void => {
  const callbackRecords: Record<string, OnSavedCallback> = form.getFieldValue(OnSaveCallbackKey) ?? {};
  form.setFieldValue(OnSaveCallbackKey, callbackRecords);
  const key = uuid();
  callbackRecords[key] = callback;
  return () => delete callbackRecords[key];
};