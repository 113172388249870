import React, { ReactElement } from 'react';
import {
  CellComponentDisplayPage,
  RecordProps,
  TableMetaProps,
  TableMode
} from "@props/RecordProps";
import { FormInstance, Space } from "antd";
import { getDisplayRenderFunction } from "@kernel/DisplayComponentsMapping";
import { HolderOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface GroupedGrandChildGroupTitleProps {
  domainName: string;
  zIndex: number;
  page: CellComponentDisplayPage,
  tableMode?: TableMode,
  record: RecordProps;
  columns: Array<TableMetaProps>;
  displayColumns: Array<TableMetaProps>;
  form: FormInstance,
  switchTabCallback?: (key: string) => void,
  isHighlightField?: boolean,
  canDnd?: boolean,
}

const GroupedGrandChildGroupTitle = (props: GroupedGrandChildGroupTitleProps): ReactElement => {
  const {
    record, displayColumns, domainName, tableMode, zIndex, page,
    form, switchTabCallback, isHighlightField, canDnd
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {canDnd && <HolderOutlined title={t('Click and drag to sort')}/>}
      <Space style={{ zIndex }} direction={"horizontal"}>
        {displayColumns.filter(c => c.key !== 'id').map((column: TableMetaProps) => {
          const renderFunction = getDisplayRenderFunction({
            column,
            enumValues: {},
            objectValues: {},
            domainName,
            page,
            zIndex,
            form,
            switchTabCallback,
            isHighlightField,
            tableMode,
          });
          return renderFunction?.(record[column.key], record) ?? (<></>);
        })}
      </Space>
    </>
  );
};

export default GroupedGrandChildGroupTitle;
