import { ActionExtInfoProp, ActionMode, ActionProps, RecordProps, TableMetaProps } from '@props/RecordProps';
import { fetchDynamicActionParameters } from '@utils/FetchUtils';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { Space } from 'antd';
import { ActionIcon } from '../../components/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerActionButton from './DrawerActionButton';
import PopoverActionButton from './PopoverActionButton';

export interface ActionButtonProps {
  action: ActionProps;
  fetchDataCallback: () => void;
  domainName: string;
  zIndex: number;
  selectedData?: Array<RecordProps>;
  displayLabel: boolean;
  mode: ActionMode;
  visiblePopover: string | undefined;
  setVisiblePopoverCallback: (key?: string) => void;
  ownerClass?: string;
  ownerId?: number;
  columnNameInOwnerClass?: string;
  labelField?: string;
}

export interface SpecActionButtonProps extends ActionButtonProps {
  closeCallback: () => void;
  parameters?: Array<TableMetaProps>;
  loadingParameters?: boolean;
  actionElem?: ReactElement;
  open?: boolean;
}

const DefaultActionExtInfo: ActionExtInfoProp = { displayLabel: true, refreshPage: true };

const ActionButton = (props: ActionButtonProps): ReactElement => {

  const {
    action, displayLabel, mode, visiblePopover, setVisiblePopoverCallback,
    labelField,
  } = props;

  const { id, label, icon, name, helpText, confirmType, extInfo, group } = action;

  const { displayLabel: displayLabelFromExtInfo } = extInfo ?? DefaultActionExtInfo;

  const [loadingParameters, setLoadingParameters] = useState<boolean>();

  const [display, setDisplay] = useState<boolean>(false);

  const { t } = useTranslation();

  //执行参数
  const [parameters, setParameters] = useState<Array<TableMetaProps>>();

  useEffect(() => {
    if (parameters == null) {
      setLoadingParameters(true);
      fetchDynamicActionParameters({ actionId: id }).then(parameters => {
        setParameters(parameters);
        setLoadingParameters(false);
      }).catch(e => console.error(`Failed to get execute parameters for action ${id}: ${e} `));
    }
  }, [
    id, parameters
  ]);

  if (loadingParameters) {
    return <></>;
  }
  const visible = (visiblePopover === id.toString());
  const classNameBasedOnActive = `${display && visible ? "action-button-active" : "action-button-inactive"}`;
  const className = `link-icon-container ${classNameBasedOnActive}`;
  // 标识是否是只显示图标，不显示 Popup 的简易模式
  const isSimpleAction = (confirmType === 'NO_POPUP_NO_CONFIRM');
  const isSingleDisplayMode = (mode === 'single');
  const spaceGap = isSingleDisplayMode ? 8 : 2;
  // 对于 Simple Action 类型的 action, 默认不显示 Label,
  // 除非 extInfo 中的 displayLabel 为 true, 或者父组件传递的 displayLabel 为 true
  const displayLabelForSimpleAction = (isSimpleAction && (displayLabelFromExtInfo === true)) || (displayLabel === true);
  const calcDisplayLabel = (!isSimpleAction || displayLabelForSimpleAction) && (displayLabel);
  const actionLabelWithTrans = calcDisplayLabel ? t(`dynamicAction:${label ?? name}`) : "";

  const actionElem = (<span
    className={className}
    title={helpText}
    onClick={(e: React.MouseEvent<HTMLElement>) => {
      if (isSimpleAction) {
        // 如果是 Simple Action, 什么也不用做, callback 在 "simple-action-icon" 中调用
      } else {
        stopPropagationAndPreventDefault(e);
        if (display === false) {
          setVisiblePopoverCallback(id.toString());
        } else if (display === true) {
          setVisiblePopoverCallback(undefined);
        }
        setDisplay(!display);
      }
    }}
  >
    <Space direction="horizontal" size={spaceGap}>
      {<ActionIcon icon={icon} />}
      <span className="action-label">
        {actionLabelWithTrans}
      </span>
    </Space>
  </span>);

  // If action
  // 1. has parameter,
  // 2. or is single mode,
  // 3. or is grouped,
  // use Drawer mode
  const displayUseDrawer = ((parameters?.length ?? 0) > 0) || isSingleDisplayMode || (group != null);

  return displayUseDrawer ? (
    <DrawerActionButton
      {...props}
      parameters={parameters}
      loadingParameters={loadingParameters}
      actionElem={actionElem}
      open={display && visible}
      closeCallback={() => {
        setDisplay(false);
        setVisiblePopoverCallback(undefined);
      }}
      labelField={labelField}
    />
  ) :
    (<PopoverActionButton
      {...props}
      parameters={parameters}
      loadingParameters={loadingParameters}
      actionElem={actionElem}
      open={display && visible}
      closeCallback={() => {
        setDisplay(false);
        setVisiblePopoverCallback(undefined);
      }}
      labelField={labelField}
    />);
};

export default ActionButton;
