import React, { ReactElement } from "react";
import { ListComponent } from "../../form";
import { Empty } from "antd";
import { DetailPanelProps } from "@props/RecordProps";

const RelateObjects = (props: DetailPanelProps): ReactElement => {
  const { ownerClass, record, column, zIndex, isCurrentActiveTab } = props;
  const { fieldType, elementType, key, extInfo } = column;

  const elementDomain = column.elementDomain ?? elementType;
  if (!elementDomain) {
    const description = `Error to get ${JSON.stringify(column)}`;
    return (<Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={description}
    />);
  } else {
    return (
      <ListComponent
        isCurrentActiveTab={isCurrentActiveTab}
        domainName={elementDomain}
        columnNameInOwnerClass={key}
        ownerClass={ownerClass}
        ownerId={record?.id}
        tableMode="detail"
        inline={false}
        zIndex={zIndex + 1}
        fieldType={fieldType}
        displayForm={extInfo?.displayForm}
      />);
  }
};

export default RelateObjects;
