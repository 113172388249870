import React, { ReactElement } from 'react';
import {
  RightSquareOutlined
} from '@ant-design/icons';
import { DynamicIcon } from '.';

export const ActionIcon = (props: { icon?: string }): ReactElement => {
  const { icon } = props;

  return icon ?
    <DynamicIcon
      type={icon}
    /> : <RightSquareOutlined />;
};

export default ActionIcon;
