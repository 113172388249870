import React, { ReactElement, ReactNode, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Input, Checkbox, Button, Popover, Form, Alert, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { getOrganization, getUserId } from '@utils/TokenUtils';
import {
  FilterFilled, SaveOutlined, CloseCircleOutlined, SettingOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import './filter.less';
import { getSearchConditionsForDomain } from '@kernel/ServerSideSearcher';
import { DynamicFilterResponseProps, FetchSearchResultProps, InternalServerErrorResp, SearchConditions } from '@props/RecordProps';
import { CreateFilterProps, saveFilter, UpdateFilterProps } from './FilterFetchUtils';
import { useClickToggleVisible } from '@utils/hooks';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { TooltipPlacement } from 'antd/es/tooltip';
import { DeleteComponent } from '../../form';
import { DynamicFilterDomainName } from '@config/domain';
import { openInfoNotification } from '@utils/NotificationUtils';

interface SaveDynamicFilterPopoverProps {
  domainName: string;
  zIndex: number;
  filter?: DynamicFilterResponseProps;
  displayElement?: ReactNode;
  updateCallback: () => void;
  fullTextSearchConditions?: FetchSearchResultProps;
}

const SaveDynamicFilterPopover = (props: SaveDynamicFilterPopoverProps): ReactElement => {
  const { domainName, zIndex, filter, updateCallback, displayElement, fullTextSearchConditions } = props;
  const [label, setLabel] = useState(filter?.label ?? '');
  const [description, setDescription] = useState(filter?.description ?? '');
  const [isDefault, setIsDefault] = useState(filter?.isDefault ?? false);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const [visible, setVisible] = useClickToggleVisible();
  const mode = (filter == null) ? 'create' : 'edit';

  const conditionsObj: SearchConditions | FetchSearchResultProps | undefined =
    fullTextSearchConditions ?? getSearchConditionsForDomain(domainName);
  const conditionsStr = JSON.stringify(conditionsObj, (key, value) => {
    if (value === undefined) {
      return value;
    }
    return value;
  });
  const handleSave = (): void => {
    if (label.trim() === '') {
      setErrorMessage(t('Label field is required'));
      return;
    }

    if ((conditionsObj == null || Object.keys(conditionsObj).length === 0) && fullTextSearchConditions == null) {
      console.error('No conditions to save');
      return;
    }
    //Display sequence is set to current max display sequence + 10 on backend
    const value = (mode === 'edit' && filter != null) ? ({
      id: filter.id,
      label: label,
      isDefault: isDefault ?? filter.isDefault,
      description,
      conditions: conditionsStr,
    } as UpdateFilterProps) : ({
      //Append uuid to avoid name conflict
      name: `${label}_${uuid()}`,
      label: label,
      isSystem: false,
      isDefault,
      description,
      organization: { id: getOrganization().id },
      owner: { id: getUserId() },
      enableRoles: "",
      conditions: conditionsStr,
      icon: "FilterOutlined"
    } as CreateFilterProps);
    setSaving(true);
    saveFilter({
      domainName,
      value,
      successCallback: () => {
        setErrorMessage('');
        openInfoNotification(t('Save dynamic filter success'));
        updateCallback?.();
      },
      failCallback: (error: unknown) => {
        if ("message" in (error as object)) {
          setErrorMessage((error as InternalServerErrorResp).message);
        } else {
          setErrorMessage(JSON.stringify(error));
        }
      },
      finallyCallback: () => {
        setSaving(false);
        setVisible(false);
      }
    });
  };

  const errorMessageAlert = (errorMessage == null || errorMessage === '') ?
    <></> : (<Alert
      message={t('Save dynamic filter failed')}
      type="error"
      description={errorMessage}
      showIcon
    />);

  const instrument = (filter == null) ? <></> : (
    <Alert
      message={t('Will replace exist condition with current applies one')}
      type="info"
      showIcon
    />
  );

  const content = (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: "94%", margin: "auto", display: "block" }}
      onClick={(e) => {
        stopPropagationAndPreventDefault(e);
      }}
    >
      {instrument}
      {errorMessageAlert}
      <Form layout="horizontal">
        <Form.Item>
          <Input
            id="labelField"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            placeholder={t('Dynamic filter name')}
          />
        </Form.Item>
        <Form.Item>
          <Input.TextArea
            id="descriptionField"
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('Dynamic filter description')}
          >
            {description}
          </Input.TextArea>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isDefault}
            onChange={(e) => setIsDefault(e.target.checked)}
          >
            <span onClick={() => setIsDefault(!isDefault)}>
              {t('Is Default(Apply automatically when showing the table)')}
            </span>
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Space size={12}>
            <Button
              type="primary"
              onClick={handleSave}
              size="small"
              icon={<SaveOutlined />}
              loading={saving}
            >
              {t('Save')}
            </Button>
            <Button onClick={() => {
              setVisible(false);
            }}
              size="small"
              icon={<CloseCircleOutlined />}
            >
              {t('Cancel')}
            </Button>
            {filter != null && <DeleteComponent
              id={filter?.id}
              domainName={DynamicFilterDomainName}
              callback={() => {
                updateCallback?.();
                setVisible(false);
              }}
              displayElement={<Button
                type="primary"
                danger
                size="small"
                icon={<DeleteOutlined />}
              >
                {t('Delete')}
              </Button>}
            />}
          </Space>
        </Form.Item>
      </Form>
    </Space>
  );

  const goldenRatio = 0.618;
  const width = 300;
  const height = width * goldenRatio;

  const uiConfig = {
    "edit": {
      displayElement: (<SettingOutlined      
        title={t("Edit this filter")}
      />),
      displayPlacement: "bottomRight" as TooltipPlacement
    },
    "create": {
      displayElement: (<Button
        icon={<FilterFilled title={t("FilterPanelLocked")} />}
        title={t('Save search condition as dynamic filter')}
        className="save-dynamic-filter-button"
        size={"small"}
      >
        {t('Create dynamic filter')}
      </Button>),
      displayPlacement: "bottomLeft" as TooltipPlacement
    }
  };

  return (
    <Popover
      content={content}
      trigger="click"
      overlayStyle={{
        width: `${width}px`,
        height: `${height}px`,
        paddingTop: 0,
      }}
      open={visible}
      showArrow={false}
      placement={uiConfig[mode].displayPlacement}
      zIndex={zIndex + 2}
      overlayClassName={`save-dynamic-filter-popover save-dynamic-filter-popover-${mode}`}
    >
      <span onClick={(e) => {
        stopPropagationAndPreventDefault(e);
        setVisible(!visible);
      }}>
        {displayElement ?? uiConfig[mode].displayElement}
      </span>
    </Popover>
  );
};

export default SaveDynamicFilterPopover;
