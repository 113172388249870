import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Card, Checkbox, Dropdown, FormInstance, Menu, Modal, Space } from 'antd';
import {
  DoubleRightOutlined,
  EditOutlined,
  FullscreenOutlined,
  DownOutlined,
  RightOutlined,
  SettingOutlined,
  InfoCircleFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { fetchCurrentValue, fetchFormIdAndExtInfoByType } from '@utils/FetchUtils';
import { FormProps, RecordProps, TableMetaProps } from '@props/RecordProps';
import { getLabelToDisplay, stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { DeleteComponent, UpdateComponent, ActionButtons } from '../../form';
import { CloseIcon, DocumentDetail } from '../../components';
import Highlighter from "react-highlight-words";
import { emptyMethod } from "@utils/Constants";
import { stripAbstract } from '@utils/StringUtils';
import { wrapAsHtml } from '@utils/ComponentUtils';
import { useDomainPermit } from '@utils/DomainPermitUtils';
import { RedirectComponent } from "../../components/redirect";

interface CardSearchResultCellProps {
  record: RecordProps;
  domainName: string;
  contentDisplayColumns: Array<TableMetaProps>;
  columns: Array<TableMetaProps>;
  updateFormId: number;
  changeCallback: (record: RecordProps, selected: boolean) => void;
  searchKeyword?: string;
  zIndex: number;
  layout?: "vertical" | "horizontal";
  onlyShowAbstract?: boolean;
  panelDisplayColumns?: Array<TableMetaProps>;
}

const CardSearchResultCell = (props: CardSearchResultCellProps): ReactElement => {
  const {
    record, domainName, contentDisplayColumns, changeCallback,
    searchKeyword, zIndex, layout = 'vertical', onlyShowAbstract = true,
    panelDisplayColumns
  } = props;
  const { id } = record;
  const { t } = useTranslation();
  const isHorizontalLayout = (layout === 'horizontal');
  const [menuIconVisible, setMenuIconVisible] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(isHorizontalLayout);
  const [abstractContent, setAbstractContent] = useState<ReactElement>(<></>);
  const [inlineDisplayFormId, setInlineDisplayFormId] = useState<number>();
  const [actionPopoverVisible, setActionPopoverVisible] = useState<string>();
  const [accessCounted, setAccessCounted] = useState<boolean>(false);
  const domainPermit = useDomainPermit(domainName, id);


  useEffect(() => {
    fetchFormIdAndExtInfoByType(domainName, "INLINE_DISPLAY").then((form: FormProps) => {
      setInlineDisplayFormId(form.id);
    }).catch(e => console.error(`Failed to get ${domainName} INLINE_DISPLAY form : ${JSON.stringify(e)}`));
  }, [domainName]);

  useEffect(() => {
    if (modalVisible && !accessCounted) {
      fetchCurrentValue(domainName, id).then(() => {
        setAccessCounted(true);
        // This API call is just to track a visit to the record.
        // Nothing needs to be done with the result.
      });
    }
  }, [modalVisible, accessCounted, domainName, id]);

  //TODO #834 和 DynamicWidget 界面的整合成一个组件
  const menu = (
    <Menu>
      {
        <Menu.Item key="update" style={{padding: "15px"}}>
          {
            !domainPermit.canUpdateErrorMsg && (
              <span onClick={() => setShowEditModal(true)}>
                <EditOutlined /> {t('Edit')}
              </span>)
          }
          {domainPermit.canUpdateErrorMsg && <span>{domainPermit.canUpdateErrorMsg}</span>}
        </Menu.Item>
      }
      {domainPermit.canDelete &&
        <Menu.Item key="delete" style={{padding: "15px"}}>
          <DeleteComponent
            domainName={domainName}
            id={record.id}
            callback={() => {
              //Call parent to refresh data
            }}
            trigger="click"
            renderWithoutContainer={true}
            text={t('Delete')}
            errorMsg={domainPermit.canDeleteErrorMsg}
          />
        </Menu.Item>
      }
    </Menu>
  );

  const label = (
    <a
      title={getLabelToDisplay(record, undefined)}
      href="/#"
      onClick={(e) => {
        stopPropagationAndPreventDefault(e);
        setModalVisible(true);
      }}>
      <Space size="middle">
        <Highlighter
          highlightClassName="highlight-word"
          searchWords={[searchKeyword ?? '']}
          autoEscape
          textToHighlight={getLabelToDisplay(record, undefined)}
        />
      </Space>
    </a>
  );

  useEffect(() => {
    setAbstractContent(<>
      {contentDisplayColumns.map(c => {
        // Replace &nbsp; with whitespace
        // https://stackoverflow.com/questions/1495822/replacing-nbsp-from-javascript-dom-text-node
        // Remove all tags
        // https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
        const content = record[c.key]
          .replace(/<[^>]*>?/gm, '')
          .replace(/\u00a0/g, " ")
          .replace(/&nbsp;/g, " ");
        const absStr = onlyShowAbstract? stripAbstract(content, searchKeyword ?? '') : content;
        const withHighlight = (searchKeyword != null && searchKeyword !== '') ? (<span key={c.key}>
          <Highlighter
            highlightClassName="highlight-word"
            searchWords={[searchKeyword ?? '']}
            autoEscape
            textToHighlight={absStr}
          />
          </span>) : wrapAsHtml(absStr, record.id);
        return withHighlight;
      })}
    </>
    );
  }, [contentDisplayColumns, record, searchKeyword, menuIconVisible, t, onlyShowAbstract]);

  const title = isHorizontalLayout? undefined : (<Space direction="horizontal">
    <Checkbox
      checked={selected}
      onChange={(e) => {
        changeCallback(record, e.target.checked);
        setSelected(e.target.checked);
      }
      }
    />
    {label}
  </Space>);

  const panelContent = ((panelDisplayColumns?.length ?? 0) > 0 && panelDisplayColumns?.[0] != null) ? (
    <Alert
      className="panel-message-info"
      message={undefined}
      type="info"
      showIcon={true}
      icon={<InfoCircleFilled />}
      description={record[panelDisplayColumns?.[0].key]}
    />
  ) : (<></>);

  const extra = isHorizontalLayout? undefined : (menuIconVisible && (
          <Space size="small">
            <ActionButtons
              visiblePopover={actionPopoverVisible}
              setVisiblePopoverCallback={(key?: string) => {
                setActionPopoverVisible(key);
              }}
              domainName={domainName}
              fetchDataCallback={emptyMethod}
              mode="single"
              direction="horizontal"
              selectedData={[record]}
              zIndex={zIndex}
              displayLabel={false}
            />
            <FullscreenOutlined
              onClick={() => setModalVisible(true)}
            />
            <Dropdown overlay={menu}>
              <SettingOutlined
                title={t('Edit current object')}
                style={{ cursor: 'pointer' }}
                onClick={emptyMethod}
              />
            </Dropdown>
            <DoubleRightOutlined
              style={{ cursor: "pointer" }}
              //FIX this, should open the detail page in modal, not in a new page
              onClick={() => setShowDetailModal(true)}
              title={t('Show details')}
            />
          </Space>
  ));

  const inlineDisplayForm = (inlineDisplayFormId == null)? (<></>) : (<UpdateComponent
        zIndex={zIndex}
        domainName={domainName}
        id={record.id}
        formId={inlineDisplayFormId}
        hideDetailPanel={true}
        hideHeader={true}
        hideHeaderTitle={true}
        readonly={true}
        formType="INLINE_DISPLAY"
    />);

  const editForward = (<RedirectComponent
          forMultiple={false}
          fetchDataCallback={() => {
            setShowEditModal(false);
          }}
          redirect={`/${domainName}/${id}/update`}
          //Use 7 to make sure it appears on top of dashboard widgets
          zIndex={7}
          showText={false}
          hasRelateObjectField={true}
    />);

  const showForward = (<RedirectComponent
    forMultiple={false}
    fetchDataCallback={() => {
      setShowDetailModal(false);
    }}
    redirect={`/${domainName}/${id}/show`}
    //Use 7 to make sure it appears on top of dashboard widgets
    zIndex={7}
    showText={false}
    hasRelateObjectField={true}
  />);

  const dumpRecord = { ...record };
  const contentColumn = contentDisplayColumns[0];
  const content: string = dumpRecord[contentColumn.key];
  dumpRecord[contentColumn.key] = (searchKeyword != null && searchKeyword !== '') ? content.replaceAll(
    searchKeyword, '<span class="highlight-word">' + searchKeyword + '</span>') : content;

  const allContent = isHorizontalLayout? (<>
    <span style={{width: "70%", minHeight: "160px", float: "left"}}>{abstractContent}</span>
    <span style={{
      minHeight: "160px", float: "right", paddingLeft: "10px"
    }} className="card-search-result-cell-horizontal-layout">{inlineDisplayForm}</span>
    </>) : (
    <Space direction={layout}>
      {panelContent}
      {abstractContent}
      <div className={'show-details-button'}
        title={t('Click to view detail')}
      >
        {detailVisible ? <DownOutlined /> : <RightOutlined />}
        <span>
          <a href="/#" onClick={(e) => {
            stopPropagationAndPreventDefault(e);
            setDetailVisible(!detailVisible);
          }}>
            {t('Show details')}
          </a>
        </span>
      </div>
      {detailVisible && inlineDisplayForm}
      {showEditModal && editForward}
      {showDetailModal && showForward}
    </Space>
  );
  return (
    <Card
      key={record.id}
      title={title}
      className={detailVisible ? 'display-detail' : ''}
      extra={extra}
      onMouseEnter={() => setMenuIconVisible(true)}
      onMouseLeave={() => setMenuIconVisible(false)}
      >
      {allContent}
      <Modal
        width="90%"
        open={modalVisible}
        closeIcon={<CloseIcon onClick={() => setModalVisible(false)} />}
        footer={null}
        title={label}
        className="search-result-modal"
        maskClosable={true}
        onCancel={() => setModalVisible(false)}
      >
        <DocumentDetail
          record={dumpRecord}
          column={contentColumn}
          ownerClass={domainName}
          zIndex={zIndex + 1}
          isCurrentActiveTab={false}
          form={{} as FormInstance}
          readonly={true}
        />
      </Modal>

    </Card>
  );
};

export default CardSearchResultCell;
