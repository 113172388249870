import { ExcludeArrayColumnsFetchType, LogApiResponseToConsole, OnlyLabelFieldFetchType, SERVER_URL } from '@config/base';
import i18n from '@config/i18n';
import {
  AttachmentProps,
  ActionProps,
  DashboardProps,
  DashboardWidgetProps,
  DataApiResultProps,
  DeleteRecordResponseProps,
  DeletionProps,
  DynamicFilterResponseProps,
  EnumMetaProps,
  ExecResultsProps,
  ExtendDataNode,
  FetchListOfDomainDataProps,
  FetchSearchResultProps,
  FetchType,
  FormProps,
  FormType,
  GroupMetaProps,
  MessageSummaryResponseProps,
  ObjectMetaProps,
  RecordProps,
  SaveRecordProps,
  SaveRecordResponseProps,
  SearchConditions,
  TableMetaProps,
  ThemeInfoProps,
  UploadCsvResponseProps,
  ErrorResponseProps,
  WidgetDataResponseProps,
  WizardMetaProps,
  WizardStepResultProps,
  BaseResponseProps,
  RevisionCompareProps,
  RevisionCompareResult,
  ActionMode
} from '@props/RecordProps';

import {
  openErrorNotification,
  openInfoNotification,
  openWarningNotification,
  openWarningNotificationWithDescription
} from '@utils/NotificationUtils';
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { getAccessToken, getRefreshToken, saveTokens } from "@utils/TokenUtils";
import { configureRefreshFetch, fetchJSON } from "refresh-fetch";
import merge from 'lodash/merge';
import { cachedFetch } from './CachedFetch';
import { capitalizeFirstLetter, typeWithPackageToSimpleType } from '@utils/StringUtils';
import { deleteLabelFromCache, getRawDomainName, pureObjectIsEmpty } from "@utils/ObjectUtils";
import {
  AvatarData, CreateActionProps, CronInformationProps, PopulateDomainDataProps,
  FetchRelateDomainProps, LoginResultProps, RefreshColumnMetaResultProps, RefreshDynamicColumnMetaResultProps,
  SaveDomainProps
} from "./FetchUtilsProps";
import { DynamicMenuProps } from '@kernel/RoutersConfig';

// TODO define a base interface for error type with 'errorType' field.
export interface FetchError {
  errorType: 'FetchError';
  code: number;
  message: string;
}


export const buildAuthorizationHeader = (): {
  [propName: string]: string;
} => {
  const accessToken = getAccessToken();
  return {
    'Authorization': "Bearer " + (accessToken == null ? "" : accessToken),
    'Content-Type': 'application/json'
  };
};

export async function requestUrlAndGetPromise(url: string, parameters: RequestInit, options?: {
  useCache?: boolean;
  parseResponse?: boolean;
  isRetry?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
}): Promise<any> {
  const params = { headers: buildAuthorizationHeader(), ...parameters };
  const {
    useCache, parseResponse, isRetry
  } = options || {
    useCache: true, parseResponse: true, isRetry: false
  };
  const isGetMethod = (parameters.method == null || parameters.method === 'GET');
  const cacheUsed = (isGetMethod && useCache !== false);
  const response = (cacheUsed) ? await
    cachedFetch(url, params) : await _fetch(url, params);
  if (parseResponse !== false) {
    return await response.text().then((text: string) => {
      if (LogApiResponseToConsole) {
        console.debug(`Response from ${url}: \n${text}`);
      }
      try {
        return JSON.parse(text);
      } catch (error) {
        // 仅在重试访问后台报错后，才打印错误日志
        if (isRetry) {
          console.error(`Failed to parse [${text}] from [${url}] with parameters [${JSON.stringify(params)}] to json: ${error}`);
        }
        // 如果使用 cache 的 response 出错，修改请求 options, 跳过 cache 往后台请求
        if (cacheUsed) {
          const skipCacheOptions = { ...options };
          skipCacheOptions.useCache = false;
          skipCacheOptions.isRetry = true;
          return requestUrlAndGetPromise(url, parameters, skipCacheOptions);
        }
      }
    });
  }
  return response;
}

export async function requestUrlAndGetPromiseThrowError<T>(url: string, parameters: RequestInit, options?: {
  useCache?: boolean;
  parseResponse?: boolean;
  isRetry?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
}): Promise<T> {
  const params = { headers: buildAuthorizationHeader(), ...parameters };
  const {
    useCache, parseResponse, isRetry
  } = options || {
    useCache: true, parseResponse: true, isRetry: false
  };
  const isGetMethod = (parameters.method == null || parameters.method === 'GET');
  const cacheUsed = (isGetMethod && useCache !== false);
  const response = (cacheUsed) ? await
    cachedFetch(url, params) : await _fetch(url, params);
  if (parseResponse !== false) {
    const resp = response as Response;
    return await resp.text().then((text: string) => {
      if (LogApiResponseToConsole) {
        console.debug(`Response from ${url}: \n${text}`);
      }
      let body = null;
      try {
        body = JSON.parse(text);
      } catch (error) {
        // 仅在重试访问后台报错后，才打印错误日志
        if (isRetry) {
          console.error(`Failed to parse [${text}] from [${url}] with parameters [${JSON.stringify(params)}] to json: ${error}`);
        }
        // 如果使用 cache 的 response 出错，修改请求 options, 跳过 cache 往后台请求
        if (cacheUsed) {
          const skipCacheOptions = { ...options };
          skipCacheOptions.useCache = false;
          skipCacheOptions.isRetry = true;
          return requestUrlAndGetPromise(url, parameters, skipCacheOptions);
        }
      }
      if (resp.ok) {
        return body;
      } else {
        throw {
          errorType: 'FetchError',
          code: resp.status,
          message: body?.message
        } as FetchError;
      }
    });
  }
  return response;
}

export async function fetchCronInformation(value: string): Promise<CronInformationProps> {
  const headers = buildAuthorizationHeader();
  const response = await _fetch(`${SERVER_URL}/cron/parse?q=${value}`, { headers });
  return await response.json();
}

export async function fetchTopMenus(): Promise<Array<DynamicMenuProps>> {
  const headers = buildAuthorizationHeader();
  const response = await _fetch(`${SERVER_URL}/menu/top`, { headers });
  if (response.ok) {
    return await response.json();
  } else {
    console.warn("Error getting list of top menus: ", response);
    return await response.json();
  }
}

export async function fetchEnumOptions(enumType: string): Promise<Array<EnumMetaProps>> {
  return await requestUrlAndGetPromise(`${SERVER_URL}/enum/${enumType}`, {});
}

export async function fetchSelectOptions(dynamicFieldKey: string): Promise<Array<string>> {
  return await requestUrlAndGetPromise(`${SERVER_URL}/options/${dynamicFieldKey}`, {});
}

export async function searchObjectByKeyword(ownerClass: string, fieldName: string, type: string, keyword: string): Promise<Array<ObjectMetaProps>> {
  if (ownerClass == null) {
    return await requestUrlAndGetPromise(`${SERVER_URL}/search/${fieldName}/${type}?q=${keyword}`, {});
  }
  return await requestUrlAndGetPromise(`${SERVER_URL}/search/${ownerClass}/${fieldName}/${type}?q=${keyword}`, {});
}

export async function fetchDomainMeta(domainName: string, formId: number | undefined): Promise<Array<TableMetaProps>> {
  return await requestUrlAndGetPromise(`${SERVER_URL}/domain/${getRawDomainName(domainName)}/formFields/${formId}`, {});
}

export async function fetchCurrentValue(domainName: string, id: number, fetchType?: FetchType | null, formId?: number | null): Promise<RecordProps> {

  let url = `${SERVER_URL}/${getRawDomainName(domainName)}/${id}`;

  // Add fetchType and formId to the URL only if they are defined
  const queryParams = [];
  if (fetchType != null) {
    queryParams.push(`fetchType=${fetchType}`);
  }
  if (formId != null) {
    queryParams.push(`formId=${formId}`);
  }

  // Join the query parameters together and add to the URL
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  return requestUrlAndGetPromise(url, {});
}

export async function fetchCurrentValueNoRelationshipColumns(domainName: string, id: number, useCache?: boolean): Promise<RecordProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/${getRawDomainName(domainName)}/${id}?fetchType=${OnlyLabelFieldFetchType}`, { },
                                 { useCache: (useCache === false)? false : true });
}

export async function fetchCurrentValues(domainName: string, ids: Array<number>, fetchType?: FetchType, formId?: number): Promise<Array<RecordProps>> {
  const noIdsSupplied = (ids.length === 0 || (ids.length === 1 && ids[0] == null));
  const ft: FetchType = (fetchType == null) ? OnlyLabelFieldFetchType : ExcludeArrayColumnsFetchType;
  return noIdsSupplied ? await new Promise<Array<RecordProps>>((resolve) => resolve([])) :
    await requestUrlAndGetPromise(`${SERVER_URL}/multiple/${getRawDomainName(domainName)}/${ids}?fetchType=${ft}&formId=${formId}`, {});
}

export async function fetchListOfDomainData(props: FetchListOfDomainDataProps): Promise<DataApiResultProps> {
  const { formId, domainName, current, max, useCache } = props;
  const offset = max * (current - 1);
  return await requestUrlAndGetPromise(`${SERVER_URL}/${getRawDomainName(domainName)}?max=${max}&offset=${offset}&formId=${formId}`, {}, { useCache });
}

export async function fetchListOfRelateDomainData(props: FetchRelateDomainProps): Promise<DataApiResultProps> {
  const { formId, domainName, columnName, ownerId, current, max, useCache } = props;
  const offset = max * (current - 1);
  return await requestUrlAndGetPromise(`${SERVER_URL}/${getRawDomainName(domainName)}/${ownerId}/${columnName}?max=${max}&offset=${offset}&formId=${formId}`,
    {}, { useCache });
}

export async function populateDomainData<T extends SaveRecordProps>(props: PopulateDomainDataProps<T>): Promise<T[]> {
  const { formId, domainName, objects } = props;
  return requestUrlAndGetPromise(`${SERVER_URL}/batch/populate/${formId}/${getRawDomainName(domainName)}`, {
    method: 'POST',
    headers: buildAuthorizationHeader(),
    body: JSON.stringify(objects)
  }, { useCache: false });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function saveDomain(props: SaveDomainProps): Promise<any> {
  const {
    domainName, values, url, method, successCallback, failCallback,
    skipInfoNotification
  } = props;
  const headers = buildAuthorizationHeader();
  const dn = i18n.t(`domainTitle:${capitalizeFirstLetter(typeWithPackageToSimpleType(domainName))}`);
  const parameters = {
    method, headers,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    body: JSON.stringify(values, (k: string, v: any) => {
      return v === undefined ? null : v;
    })
  };
  return await _fetch(url, parameters).then(response => {
    if (!response.ok) {
      response.json().then((errorInfo) => {
        const errorMsg = ("message" in errorInfo) ? errorInfo.message : JSON.stringify(errorInfo);
        failCallback(errorInfo);
        openErrorNotification(i18n.t('Save failed', { domainTitle: dn, msg: errorMsg }));
        return errorInfo;
      });
    } else {
      return response.json().then((r: SaveRecordResponseProps) => {
        if (Array.isArray(r.data)) {
          r.data.forEach((d) => deleteLabelFromCache(domainName, d.id));
        } else {
          const id = r?.data?.id;
          if (id) {
            deleteLabelFromCache(domainName, id);
          }
        }
        const msg = r.message;
        if (r.status === "success") {
          successCallback(r.data);
          if (!(skipInfoNotification === true)) {
            openInfoNotification(i18n.t('Save success', { domainTitle: dn }));
          }
        } else if (r.status === "warning") {
          successCallback(r.data);
          openWarningNotificationWithDescription(
            i18n.t('Save success with warnings', { domainTitle: dn, msg: '' }), msg
          );
        } else if (r.status === "error") {
          failCallback(r.data);
          openErrorNotification(
            msg, i18n.t('Save failed', { domainTitle: dn, msg: '' })
          );
        }
        return r.data;
      });
    }
  }).catch(error => {
    const msg = (error.status === 422) ?
      (error?.body?._embedded?.errors?.map((e: { message: string }) => e?.message)?.join("<br/>")) :
      (error.message == null || error.message === '') ? error?.body?.message : error?.message;
    console.warn(`Error update ${JSON.stringify(domainName)} with value ${JSON.stringify(values)} to server: ${JSON.stringify(error)}`);
    failCallback(error);
    openErrorNotification(msg, i18n.t('Save failed', { domainTitle: dn, msg: '' }));
  });
}

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function onFinishFailed(errorInfo: ValidateErrorEntity): void {
  openErrorNotification(i18n.t('Save failed validation error'));
}

/**
 * 调用后台的唯一校验接口，校验用户输入的字段
 * 如果 id 参数为空，则表示是新建的校验，这种情况下，传递到后台的 id 会是 -1
 * @param props 校验的相关参数，包含 domain 名称，id，列名和用户输入的值
 */
export async function validConstrain(props: {
  domainName: string; id: number | undefined;
  column: TableMetaProps;
  value: string | number | RecordProps | null;
  record: SaveRecordProps | undefined;
  validationType: "validate" | "unique";
  formValues: SaveRecordProps;
}): Promise<{ valid: boolean; message?: string }> {
  const { domainName, id, column, value, record, validationType, formValues } = props;
  const { key: columnName } = column;
  const headers = buildAuthorizationHeader();
  const escapedValue = (value == null) ? '' : encodeURIComponent(typeof value === 'string' || typeof value === 'number' ? value : value.id);
  const url = `${SERVER_URL}/${validationType}/${getRawDomainName(domainName)}/${columnName}?create=${id == null}&id=${id == null ? -1 : id}&v=${escapedValue}`;
  return await _fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      record: record,
      unique: column.unique,
      formValues: formValues
    }),
  }).then(response => {
    if (!response.ok) {
      openErrorNotification(i18n.t('Valid unique constraint failed for field', { columnName }));
      throw Error(response.statusText);
    } else {
      return response;
    }
  }).then((response) => response.json());
}

export async function refreshColumnMeta(props: {
  domainName: string;
  currentObjValue: SaveRecordProps | undefined;
  sourceFieldName: string;
  destFieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceFieldValue: string | number | Array<any> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destFieldValue: string | number | Array<any> | undefined;
}): Promise<RefreshColumnMetaResultProps> {
  const {
    domainName, currentObjValue, sourceFieldValue, destFieldName,
    destFieldValue, sourceFieldName
  } = props;
  const url = `${SERVER_URL}/column/refresh/${getRawDomainName(domainName)}/${sourceFieldName}/${destFieldName}`;
  const headers = buildAuthorizationHeader();
  const parameters = {
    method: 'POST',
    body: JSON.stringify({
      object: currentObjValue,
      [sourceFieldName]: sourceFieldValue,
      [destFieldName]: destFieldValue,
    }),
    headers,
  };
  return await _fetch(url, parameters).then(response => {
    return response.json();
  }).then(json => {
    return json;
  });
}

export async function refreshDynamicColumnMeta(props: {
  domainName: string;
  currentObjValue: SaveRecordProps | undefined;
  sourceFieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceFieldValue?: string | number | Array<any>;
}): Promise<RefreshDynamicColumnMetaResultProps> {
  const {
    domainName, currentObjValue, sourceFieldName
  } = props;
  const url = `${SERVER_URL}/column/refreshAll/${getRawDomainName(domainName)}/${sourceFieldName}`;
  const headers = buildAuthorizationHeader();
  const parameters = {
    method: 'POST',
    body: JSON.stringify(currentObjValue),
    headers,
  };
  return await _fetch(url, parameters).then(response => {
    return response.json();
  }).then(json => {
    return json;
  });
}

export async function deleteRecord(props: DeletionProps): Promise<void> {
  //FIXME add status and error message as a parameter to callback and move
  //display of notification to DeleteComponent
  const { domainName, id, callback } = props;
  const headers = buildAuthorizationHeader();
  const dn = i18n.t(`domainTitle:${capitalizeFirstLetter(typeWithPackageToSimpleType(domainName))}`);
  return await _fetch(`${SERVER_URL}/${getRawDomainName(domainName)}/${id}`, {
    method: 'DELETE', headers
  }).then(r => {
    return r.json();
  }).then((r: DeleteRecordResponseProps) => {
    if (r.status === "success") {
      const title = i18n.t('Delete success', { domainTitle: dn });
      callback({ id });
      openInfoNotification(title);
    } else if (r.status === "warning") {
      const title = i18n.t('Delete success with warnings', { domainTitle: dn });
      callback({ id });
      openWarningNotification(`${title}: ${r.message}`);
    } else if (r.status === "error") {
      const title = i18n.t("Delete failed", { domainTitle: dn });
      openErrorNotification(`${title}: ${r.message}`);
    }
  }).catch(error => {
    console.error(`Error delete domain ${getRawDomainName(domainName)} with id ${id}: ${error.message}: ${error.stack}`);
    const errorMessage = i18n.t("Delete failed with id", {
      domainTitle: dn,
      msg: error.message,
      id: id
    });
    openErrorNotification(errorMessage);
  });
}

export async function executeDynamicAction(
  props: {
    domainName: string;
    actionId: number;
    formValues: RecordProps;
    ids?: Array<number>;
    ownerClass?: string;
    ownerId?: number;
    columnNameInOwnerClass?: string;
    isFinalRound?: boolean;
    fineTuningResult?: string;
  }
): Promise<ExecResultsProps> {
  const {
    domainName, actionId, formValues, ids, fineTuningResult,
    ownerClass, ownerId, columnNameInOwnerClass, isFinalRound,
  } = props;
  const url = `${SERVER_URL}/execute/${actionId}/${getRawDomainName(domainName)}`;
  const isFinalRoundCnst = isFinalRound ?? true;
  const hasOwnerInfo = (columnNameInOwnerClass != null && ownerClass != null && ownerId != null);
  const body = hasOwnerInfo?
    { ids, formValues, ownerId, ownerClass, columnNameInOwnerClass, isFinalRound: isFinalRoundCnst, fineTuningResult } :
    { ids, formValues, isFinalRound: isFinalRoundCnst, fineTuningResult };
  return await requestUrlAndGetPromise(url, {
    method: 'POST', body: JSON.stringify(body)
  });
}

export async function fetchEnabledActionsForClass(
  props: {
    domainName: string;
    type: ActionMode;
    ids?: Array<number>;
    ownerClass?: string;
    ownerId?: number;
    columnNameInOwnerClass?: string;
  }
): Promise<Array<ActionProps>> {
  const {
    domainName, ids, type,
    ownerClass, ownerId, columnNameInOwnerClass
  } = props;
  return await requestUrlAndGetPromise(
    `${SERVER_URL}/actions/${getRawDomainName(domainName)}/${type}`, {
      method: 'POST', body: JSON.stringify({ ids, ownerClass, ownerId, columnNameInOwnerClass })
  });
}

//export async function executeAction(domainName: string, actionName, { parameters });
//export async function fetchActionByName(domainName: string, actionName);

export async function fetchCanUpdateDeleteObjects(
  domainName: string, ids?: Array<number>
): Promise<Record<number, Array<ActionProps>>> {
  return await requestUrlAndGetPromise(`${SERVER_URL}/actions/${getRawDomainName(domainName)}`, {
    method: 'POST', body: JSON.stringify(ids),
  });
}

export async function fetchCanCreate(domainName: string): Promise<CreateActionProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/actions/${getRawDomainName(domainName)}/canCreate`, {});
}

export async function fetchFormIdAndExtInfoByType(domainName: string, formType: FormType): Promise<FormProps> {
  const url = `${SERVER_URL}/form/${getRawDomainName(domainName)}/type/${formType}`;
  return requestUrlAndGetPromise(url, {});
}

export async function fetchFormIdAndExtInfoByName(domainName: string, formName: string): Promise<FormProps> {
  const url = `${SERVER_URL}/form/${getRawDomainName(domainName)}/name/${formName}`;
  return requestUrlAndGetPromise(url, {});
}

export async function fetchFormExtInfo(formId: number): Promise<FormProps> {
  const url = `${SERVER_URL}/form/${formId}`;
  return requestUrlAndGetPromise(url, {});
}

export async function fetchFormFieldGroups(
  domainName: string, formId: number, objectId?: number
): Promise<Array<GroupMetaProps>> {
  const baseUrl = `${SERVER_URL}/domain/${getRawDomainName(domainName)}/formGroups/${formId}`;
  const url = (objectId != null) ? `${baseUrl}/${objectId}` : baseUrl;
  return requestUrlAndGetPromise(url, {});
}

export async function validLogin(): Promise<LoginResultProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/api/validate`, { method: 'POST' });
}

export async function getMenu(parentMenuId: number): Promise<Array<DynamicMenuProps>> {
  return requestUrlAndGetPromise(`${SERVER_URL}/menu/sub/${parentMenuId}`, {});
}

const SEARCH_RESULT_CACHE = new Map<string, Promise<DataApiResultProps>>();

export async function fetchSearchResult(props: FetchSearchResultProps): Promise<DataApiResultProps> {
  const {
    domainName,
    ownerIds,
    ownerClass,
    ownerClassColumnName,
    searchConditions,
    current,
    max,
    fetchType,
    isFullTextSearch,
    sortDirection,
    sortField,
    fullTextConditions,
    columnNameInOwnerClass,
    queryJson,
    useCache,
  } = props;
  const endPoint = (isFullTextSearch) ? "fullTextSearch" : "search";
  const offset = current <= 0 ? 0 : max * (current - 1);
  const hasSortInfo = (sortField != null && sortDirection != null);
  const baseUrl = `${SERVER_URL}/${endPoint}/${getRawDomainName(domainName)}?max=${max}&offset=${offset}`;
  const u1 = (ownerClass != null) ? `${baseUrl}&ownerClass=${ownerClass}` : baseUrl;
  const u2 = (ownerIds != null && ownerIds.length > 0) ? `${u1}&ownerId=${ownerIds?.join(",")}` : u1;
  const u3 = (columnNameInOwnerClass != null) ? `${u2}&columnNameInOwnerClass=${columnNameInOwnerClass}` : u2;
  const u4 = (ownerClassColumnName != null) ? `${u3}&ownerClassColumnName=${ownerClassColumnName}` : u3;
  const u5 = (hasSortInfo) ? `${u4}&sortField=${sortField}&sortDirection=${sortDirection}` : u4;
  const url = (fetchType != null) ? `${u5}&fetchType=${fetchType}` : u5;
  if (useCache) {
    const key = `${url}:${JSON.stringify(searchConditions)}:${JSON.stringify(fullTextConditions)}:${JSON.stringify(queryJson)}`;
    const cached = SEARCH_RESULT_CACHE.get(key);
    if (cached) {
      return cached;
    }
    const promise = requestUrlAndGetPromise(url, {
      method: "POST",
      body: JSON.stringify({ searchConditions, fullTextConditions, queryJson }),
    });
    promise.then(() => setTimeout(() => SEARCH_RESULT_CACHE.delete(key), 1000));
    SEARCH_RESULT_CACHE.set(key, promise);
    return promise;
  }
  return await requestUrlAndGetPromise(url, {
    method: "POST",
    body: JSON.stringify({ searchConditions, fullTextConditions, queryJson }),
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchJSONWithToken = async <ResponseBody,>(url: string, options = {}): Promise<Response> => {
  const token = getAccessToken();
  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  const response = await fetchJSON(url, optionsWithToken);
  return response.response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error.status === 401;

export const refreshToken = async (): Promise<void> => {
  const refreshToken = getRefreshToken();
  if (refreshToken == null) {
    return;
  }
  const url = new URL(`${SERVER_URL}/oauth/access_token`);
  const searchParams = new URLSearchParams();
  searchParams.append('grant_type', 'refresh_token');
  searchParams.append('refresh_token', refreshToken);
  url.search = searchParams.toString();
  const response = await fetchJSONWithToken(url.toString(), {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
  if (response.ok) {
    saveTokens(await response.json());
  } else {
    console.error("Refresh token failed, response: ", response);
    throw new Error("Refresh token failed");
  }
};

const _fetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken: shouldRefreshToken,
  refreshToken: refreshToken
});

export async function downloadAttachment(id: number, preview?: boolean): Promise<Blob> {
  const endpoint = preview ? "preview" : "attachment";
  const url = `${SERVER_URL}/${endpoint}/${id}`;
  return requestUrlAndGetPromise(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/octet-stream'
    }
  }, { useCache: false, parseResponse: false })
    .then(res => res.blob()) as Promise<Blob>;
}

export async function uploadCsvs(props: {
  data: FormData, domainName: string
}): Promise<UploadCsvResponseProps | ErrorResponseProps> {
  const { data, domainName } = props;
  const url = `${SERVER_URL}/import/${getRawDomainName(domainName)}/csv`;
  return uploadFiles({ data, url });
}

export async function uploadFiles(props: {
  data: FormData; url: string
}): Promise<UploadCsvResponseProps> {
  const { url, data } = props;
  const headers = buildAuthorizationHeader();
  delete headers['Content-Type'];
  const response = await fetch(
    url,
    {
      headers,
      method: "POST",
      body: data
    }
  );
  if (response.ok) {
    return await response.json();
  } else {
    console.warn("Error uploading file: ", response);
    return await response.json();
  }
}

export function getWizardMeta(wizardId: number): Promise<WizardMetaProps> {
  const url = `${SERVER_URL}/wizard/${wizardId}`;
  return requestUrlAndGetPromise(url, {});
}

export function getWizardStepMeta(stepId: number): Promise<Array<TableMetaProps>> {
  const url = `${SERVER_URL}/wizard/step/${stepId}`;
  return requestUrlAndGetPromise(url, {});
}

export async function postWizardStep(props: {
  stepId: number;
  formValues: SaveRecordProps;
  isLastStep: boolean
}): Promise<WizardStepResultProps> {
  const { stepId, formValues, isLastStep } = props;
  const url = `${SERVER_URL}/wizard/step/${stepId}`;
  return await _fetch(url, {
    headers: buildAuthorizationHeader(),
    method: 'POST',
    body: JSON.stringify({ formValues, isLastStep }),
  }).then(response => {
    if (!response.ok) {
      openErrorNotification(i18n.t('Failed to handle wizard step', { stepId }));
      throw Error(response.statusText);
    } else {
      return response;
    }
  }).then((response) => response.json());
}

export async function getDashboards(): Promise<Array<DashboardProps>> {
  return requestUrlAndGetPromise(`${SERVER_URL}/dashboard/list`, {}, { useCache: false });
}

export async function getDashboardWidgets(dashboardId: number): Promise<Array<DashboardWidgetProps>> {
  return requestUrlAndGetPromise(`${SERVER_URL}/dashboard/meta/${dashboardId}`, {}, { useCache: false });
}

export async function getDashboardWidgetData(widgetId: number): Promise<WidgetDataResponseProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/dashboard/widget/data/${widgetId}`, {}, { useCache: false });
}

export async function getDynamicFilters(domainName: string, displayDefault: boolean): Promise<Array<DynamicFilterResponseProps>> {
  return requestUrlAndGetPromise(`${SERVER_URL}/filter/${getRawDomainName(domainName)}?displayDefault=${displayDefault}`, {}, { useCache: false });
}

export async function getHasDefaultFilters(domainName: string): Promise<{ result: boolean }> {
  return requestUrlAndGetPromise(`${SERVER_URL}/filter/hasDefault/${getRawDomainName(domainName)}`, {}, { useCache: false });
}

export async function fetchMessageSummary(username: string): Promise<MessageSummaryResponseProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/${username}/messageSummary`, {}, { useCache: false });
}

export async function fetchDynamicActionParameters(props: { actionId: number; }): Promise<Array<TableMetaProps>> {
  const { actionId } = props;
  return requestUrlAndGetPromise(`${SERVER_URL}/action/${actionId}/parameters`, {}, { useCache: false });
}

export async function fetchTree(
  domainName: string, key: string | number,
  searchConditions?: SearchConditions, useCache?: boolean
): Promise<Array<ExtendDataNode>> {
  // 如果搜索条件为空, 则调用 GET 请求(可以缓存), 否则调用 POST 请求
  if (searchConditions == null || pureObjectIsEmpty(searchConditions)) {
    return requestUrlAndGetPromise(`${SERVER_URL}/tree/${getRawDomainName(domainName)}/${key}`, {}, { useCache: useCache ?? true });
  } else {
    return requestUrlAndGetPromise(`${SERVER_URL}/tree/${getRawDomainName(domainName)}/${key}`, {
      method: 'POST',
      headers: buildAuthorizationHeader(),
      body: JSON.stringify({ searchConditions })
    });
  }
}

export async function fetchDynamicThemeInfo(): Promise<ThemeInfoProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/theme/info`, {}, { useCache: false });
}

export async function updateAttachmentsDisplaySequence(ids: Array<number>): Promise<BaseResponseProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/attachment/sort`, {
    method: 'POST',
    headers: buildAuthorizationHeader(),
    body: JSON.stringify(ids)
  }, { useCache: false });
}

export async function updateAttachmentDisplayName(id: number, displayName: string): Promise<BaseResponseProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/attachment/rename`, {
    method: 'POST',
    headers: buildAuthorizationHeader(),
    body: JSON.stringify({id, displayName})
  }, { useCache: false });
}

export async function deleteAttachment(meta: AttachmentProps): Promise<BaseResponseProps> {
  return requestUrlAndGetPromise(`${SERVER_URL}/attachment/delete`, {
    method: 'POST',
    headers: buildAuthorizationHeader(),
    body: JSON.stringify(meta)
  }, { useCache: false });
}

export async function fetchRevisionCompareResult(request: RevisionCompareProps): Promise<RevisionCompareResult> {
  const { columnName, domainName, sourceId, targetId } = request;
  const prefix = `${SERVER_URL}/diff/${getRawDomainName(domainName)}/`;
  const url = (sourceId == null)?
    `${prefix}${targetId}/${columnName}` : `${prefix}${sourceId}/${targetId}/${columnName}`;
  return requestUrlAndGetPromise(url,{
    method: 'GET',
    headers: buildAuthorizationHeader()
  },{ useCache: true });
}

export async function fetchDomainLabels(domainFullName: string, domainIds: string[]): Promise<Map<string, string>> {
  const idsStr = domainIds.join(',');
  const result = await requestUrlAndGetPromise(`${SERVER_URL}/multiple/${domainFullName}/${idsStr}?fetchType=only_label_field`, {
    headers: buildAuthorizationHeader()
  }) as { label: string, id: string; }[];
  const domainMap = new Map<string, string>();
  result.forEach(domain => {
      domainMap.set(domain.id, domain.label);
    }
  );
  return domainMap;
}

export async function createRelatedDomainObject(props: {
  mainDomainName: string;
  mainDomainId: number;
  columnName: string;
  relatedDomainName: string;
  object: SaveRecordProps;
}): Promise<BaseResponseProps> {
  const { mainDomainName, mainDomainId, columnName, relatedDomainName, object } = props;
  return requestUrlAndGetPromise(`${SERVER_URL}/updateRelated/${mainDomainName}/${mainDomainId}/${columnName}/${relatedDomainName}`, {
    method: 'POST',
    headers: buildAuthorizationHeader(),
    body: JSON.stringify(object)
  }, { useCache: false });
}

export const getAvatarForUser = async (userId: number): Promise<AvatarData> => {
  return await requestUrlAndGetPromise(`${SERVER_URL}/attachment/avatar/${userId}`, {});
};
