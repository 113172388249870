import { RecordProps, TableMode } from '@props/RecordProps';
import { isNumeric } from '@utils/DecimalUtils';
import { fetchCurrentValue } from '@utils/FetchUtils';
import { isObject } from '@utils/ObjectUtils';
import { Space, Spin, Tag } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import MultipleObjectsCell from '../cells/MultipleObjectsCell';
import ObjectCell from '../cells/ObjectCell';

export interface ObjectIdsDisplayComponentProps {
  text: Array<string> | string | undefined;
  record: RecordProps;
  columnKey: string;
  zIndex: number;
  labelField?: string;
  tableMode?: TableMode;
  flatDisplay?: boolean;
}

const ObjectIdsDisplayComponent = (props: ObjectIdsDisplayComponentProps): ReactElement => {
  const { text, record, labelField, columnKey, tableMode, zIndex, flatDisplay } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [domainName, setDomainName] = useState<string>();
  const [prevObjectType, setPrevObjectType] = useState<number | { label: string }>();
  const { objectType } = record;
  const val = record[columnKey];
  const ids: Array<number> = ((typeof text === 'string')?
    text?.split(",")?.map(t => parseInt(t)) : text?.map(t => parseInt(t)).filter(t => !isNaN(t))) ?? [];

  const fetchDomainName = (objectId: number): void => {
    fetchCurrentValue("DomainClass", objectId).then((json: RecordProps) => {
        setDomainName(json.shortName);
        setLoading(false);
      }).catch(e => console.error(`Failed to get domainClass(${objectId}) information: ${JSON.stringify(e)}`));
  };

  useEffect(() => {
    // 如果 objectType 没有变化，不需要重新获取 domainName 信息
    if (objectType === prevObjectType) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setPrevObjectType(objectType);
    // 如果 objectType 是对象且包含 label 属性，则将 Label 属性中的空格删除，得到 domainName
    // 如果 objectType 是 number, 则请求后台获取其 domainClass 的属性
    // 如果 objectType 是 string, 则直接设置 domainName 为其值
    // 如果 objectType 是对象且不包含 label, 则请求后台获取其 domainClass 的属性
    if (isObject(objectType) && "label" in objectType) {
      const lDomainName = (
        objectType?.label as string
      )?.replaceAll(" ", "");
      setDomainName(lDomainName);
    } else if (isNumeric(objectType)) {
      fetchDomainName(objectType);
    } else if (typeof objectType === "string") {
      setDomainName(objectType);
      setLoading(false);
    } else if (isObject(objectType) && !("label" in objectType)) {
      fetchDomainName(objectType.id);
    }
  }, [record, prevObjectType, objectType]);

  const idsLength = ids?.length;
  if (ids == null || idsLength === 0) {
    return (<></>);
  }

  if (loading) {
    return <Spin size="small" />;
  }
  if (domainName != null) {
    return (idsLength === 1) ? (<Space size={2}><ObjectCell
      domainName={domainName}
      id={(isObject(val) && ('id' in val)) ? val?.id : val}
      labelField={labelField}
      zIndex={zIndex + 1}
    /></Space>) : (
      flatDisplay ? <Space size={2}>{ids.map(id => {
        return (<ObjectCell
          key={id}
          domainName={domainName}
          id={id}
          labelField={labelField}
          zIndex={zIndex + 1}
        />);
      })}</Space> : <MultipleObjectsCell
        domainName={domainName}
        labelField={labelField}
        zIndex={zIndex}
        displayText={""}
        tableMode={tableMode}
        ids={ids}
      />);
  } else {
    return (<>{ids.map(id => <Tag key={id}>{id}</Tag>)}</>);
  }
};

export default ObjectIdsDisplayComponent;
