import { fetchCanUpdateDeleteObjects } from "@utils/FetchUtils";
import React, { useEffect, useMemo } from "react";

export type DomainPermitState = {
  canUpdate: boolean,
  canUpdateErrorMsg: string | undefined,
  canDelete: boolean,
  canDeleteErrorMsg: string | undefined
};

const defaultPermission: DomainPermitState = {
  canUpdateErrorMsg: undefined,
  canUpdate: false,
  canDeleteErrorMsg: undefined,
  canDelete: false,
};

export const useDomainPermit = (domainName?: string, id?: number | string): DomainPermitState => {
  const idNumber = useMemo(() => {
    if (!id) {
      return undefined;
    }
    if (typeof id === 'number') {
      return id;
    }
    return parseInt(id);
  }, [id]);
  const ids = useMemo(() => idNumber ? [idNumber] : [], [idNumber]);
  const record = useDomainPermits(domainName, ids);
  return idNumber ? record[idNumber] ?? defaultPermission : defaultPermission;
};

export const useDomainPermits = (domainName: string | undefined, ids: number[]): Record<number, DomainPermitState> => {
  const [stateRecord, setStateRecord] = React.useState<Record<number, DomainPermitState>>(() => {
    const newState: Record<number, DomainPermitState> = {};
    ids.forEach(id => newState[id] = defaultPermission);
    return newState;
  });

  useEffect(() => {
    if (!domainName) {
      return;
    }
    fetchDomainPermitStates(domainName, ids)
      .then(setStateRecord)
      .catch(e => console.error(`Error fetching permissions for [${domainName}], ids [${ids}]: `, JSON.stringify(e)));
  }, [domainName, ids]);

  return stateRecord;
};

const cache: Map<string, Promise<Record<number, DomainPermitState>>> = new Map();

export const fetchDomainPermitStates = async (domainName: string, ids: number[]): Promise<Record<number, DomainPermitState>> => {
  if (ids.length === 0) {
    return {};
  }
  const cacheKey = `${domainName}:${ids.join(',')}`;
  const cached = cache.get(cacheKey);
  if (cached) {
    return cached;
  }
  const promise = fetchCanUpdateDeleteObjects(domainName, ids).then(actionRecord => {
    const stateRecord: Record<number, DomainPermitState> = {};
    for (const id of ids) {
      const actions = actionRecord[id];
      const state: DomainPermitState = { ...defaultPermission };
      stateRecord[id] = state;
      actions?.forEach(action => {
        if (action.name === 'update') {
          if (action.message != null && action.message !== '') {
            state.canUpdateErrorMsg = action.message;
            state.canUpdate = false;
          } else {
            state.canUpdate = action.enable;
          }
        } else if (action.name === 'delete') {
          if (action.message != null && action.message !== '') {
            state.canDeleteErrorMsg = action.message;
            state.canDelete = false;
          } else {
            state.canDelete = action.enable;
          }
        }
      });
    }
    setTimeout(() => cache.delete(cacheKey), 1000);
    return stateRecord;
  });
  cache.set(cacheKey, promise);
  return promise;
};
