import React, { CSSProperties, ReactElement } from "react";
import { Select } from "antd";
import { getReadOnlyClass } from "@utils/ComponentUtils";
import { HttpMethods } from '@config/base';
import { SuffixIcon } from '../../components';
import { EnumMetaProps } from "@props/RecordProps";

interface HttpMethodSelectProps {
  value?: string | undefined | { enumType: string; name: string };
  style: CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: any;
  updatable?: boolean;
  className?: string;
  options?: Array<EnumMetaProps> | undefined;
}

/**
 * Http Method 的选择控件，受控控件
 */
const HttpMethodSelect = (props: HttpMethodSelectProps): ReactElement => {
  const { style, value, updatable, options } = props;
  const name: string = (value == null) ? "" : (typeof value === "string") ? value : value?.name;

  const removeUpdatableAttribute = (): HttpMethodSelectProps => {
    const propsToSelect: HttpMethodSelectProps = {} as HttpMethodSelectProps;
    Object.assign(propsToSelect, props);
    delete propsToSelect.updatable;
    propsToSelect.value = name;
    return propsToSelect;
  };

  const optionsToShow = options ? (options.map(opt => opt.value)) : HttpMethods;

  const propsToSelect = removeUpdatableAttribute();

  return (
    <Select
      {...propsToSelect}
      style={style}
      showSearch
      defaultValue={name}
      className={`httpMethod ${getReadOnlyClass(updatable)}`}
      suffixIcon={<SuffixIcon updatable={updatable} />}
      disabled={!updatable}
      dropdownStyle={{ zIndex: 2000 }}
    >
      {optionsToShow.map(opt => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)}
    </Select>
  );
};

export default HttpMethodSelect;
