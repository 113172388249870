import React, {ReactElement, Suspense} from 'react';
import "swagger-ui-react/swagger-ui.css";
import { SERVER_URL } from '@config/base';
import { getAccessToken } from '@utils/TokenUtils';
import './SwaggerApp.css';

interface Request {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

const SwaggerUI = React.lazy(() => import('swagger-ui-react'));

const SwaggerApp = (): ReactElement => (
  <div className="swagger-container">
    <Suspense fallback={<div></div>}>
      <SwaggerUI
    docExpansion="none"
    deepLinking={false}
    url={`${SERVER_URL}/swagger/api`}
    requestInterceptor={(request: Request): Request => {
      //Support with context-path added by nginx reverse proxy
      if (!request.url.endsWith('/swagger/api')) {
        request.url = `${SERVER_URL}${new URL(request.url).pathname}`;
      }
      const token = getAccessToken();
      if (token != null) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    }}
    />
    </Suspense>
  </div>
);
export default SwaggerApp;
