import { TableModeDefaultPageSizeMapping } from "@config/base";
import { PaginationProps, TableMode } from "@props/RecordProps";

export const initPagination = (tableMode: TableMode): PaginationProps => {
  return {
    pageSize: TableModeDefaultPageSizeMapping[tableMode],
    current: 1,
    total: 0
  };
};

export const paginationReducer = (state: PaginationProps, action: {
  type: 'init' | 'set';
  payload: {
    tableMode?: TableMode,
    current?: number;
    total?: number;
    pageSize?: number;
  };
}): PaginationProps => {
  switch (action.type) {
    case 'init':
      return initPagination(action.payload.tableMode ?? "table-list");
    case 'set':
      return {
        total: action.payload.total ?? state.total,
        current: action.payload.current ?? state.current,
        pageSize: action.payload.pageSize ?? state.pageSize,
      };
    default:
      throw new Error("Not support action ", action.type);
  }
};
