import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import useState from 'react-usestateref';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Progress, Space, Spin, Tooltip, Upload } from 'antd';
import update from 'immutability-helper';
import { LoadingOutlined, CloudUploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import {
  ExcludeArrayColumnsFetchType,
  FileFieldType,
  MaxTotalFileSizeInMB,
  MaxUploadFileCount,
  MaxUploadFileSizeInMB,
  WebSocketDummyMessage,
  WEBSOCKET_SERVER_URL
} from '@config/base';
import ReconnectableSocket, { SocketInterface } from '@utils/WebsocketUtils';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { BaseResponseProps, DataApiResultProps, ExtendUploadFile, FileOperatorProps, } from "@props/RecordProps";
import {
  fetchCurrentValue,
  fetchCurrentValues,
  fetchListOfRelateDomainData,
  updateAttachmentsDisplaySequence,
  deleteAttachment
} from "@utils/FetchUtils";
import './App.less';
import {
  isSuccess,
  isUploadOperation, onDownload,
} from "./FileOperatorUtils";
import FileItemRender from "./FileItemRender";
import { openErrorNotification, openInfoNotification } from "@utils/NotificationUtils";
import { getOperatingSystem } from '@utils/BrowserUtils';
import { isDynamicField } from '@utils/ColumnsUtils';
import FilePreview from "./FilePreview";
import { wrapAsHtml } from '@utils/ComponentUtils';

const buttonIconStyle = { marginRight: "8px" };

const FileOperator = (props: FileOperatorProps): ReactElement => {
  const { t } = useTranslation();
  const { updatable, domainName, multiple, columnKey, form, record, column, initExtInfo, zIndex } = props;
  const { id: ownerId } = record || {};
  const extInfo = column.extInfo || initExtInfo;
  const { maxCount, accept, maxSizeMB, totalMaxSizeMB } = extInfo || {};
  //https://github.com/Aminadav/react-useStateRef
  //解决在 Websocket.on 的 callback 中,拿到的 fileList 不是最新的 fileList 的问题
  const [fileList, setFileList, fileListRef] = useState<Array<ExtendUploadFile>>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [websocket, setWebsocket] = useState<SocketInterface>();
  const [allFilesUploaded, setAllFilesUploaded] = useState<boolean>(true);
  const [numberDisplay, setNumberDisplay] = useState<string>("");
  const [totalSize, setTotalSize] = useState<number>(0);
  const bDynamicField = isDynamicField(columnKey);
  const isSingleStaticMode = (!multiple && !bDynamicField);
  const [previewFile, setPreviewFile] = useState<ExtendUploadFile>();
  const isArrayType = (column.type === 'array' || column.serverSideType === 'FILE_LIST');
  const [loading, setLoading] = useState<boolean>(true);

  const updateStatistics = useCallback((): void => {
    const numOfSuccess = fileListRef.current.filter(f => isSuccess(f))?.length;
    const tl = fileListRef.current.length;
    setPercent((numOfSuccess / tl) * 100);
    const nd = `(${numOfSuccess}/${tl})`;
    setNumberDisplay(nd);
  }, [fileListRef]);

  useEffect(() => updateStatistics(), [fileList, updateStatistics]);
  // Clear the file list if switch to another owner object
  useEffect(() => setFileList([]), [record?.id, setFileList]);

  const deleteOneFile = useCallback((file: UploadFile, showMessage?: boolean): void => {
    const sm = showMessage || true;
    const extendFile = fileListRef.current.find(f => f.uid === file.uid);
    if (extendFile != null) {
      const index = fileListRef.current.indexOf(file);
      const newFileList = fileListRef.current.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      if (multiple) {
        form?.setFieldsValue({
          [columnKey]: newFileList
        });
      } else {
        form?.setFieldsValue({ [columnKey]: undefined });
      }
      if (sm) {
        openInfoNotification(t('File deleted', { fileName: file.name }));
      }
    }
  }, [t, columnKey, fileListRef, form, setFileList, multiple]);

  const setDefaultFileList = useCallback((result: DataApiResultProps): void => {
    if (result.data.length === 0) {
      setFileList([]);
    }
    const fileList: Array<ExtendUploadFile> = [];
    result.data.sort((r1, r2) => r1.displaySequence - r2.displaySequence);
    result.data.forEach((file => {
      fileList.push({
        id: file.id,
        data: {
          id: file.id,
          key: file.key,
          name: file.fileName,
          description: file.description,
          storageEngine: file.storageEngine,
          displaySequence: file.displaySequence,
        },
        fileType: file.mimeType,
        message: "",
        uid: file.id.toString(),
        name: file.fileName,
        status: 'done'
      });
    }));
    setFileList(fileList);
    // 从后台获取了当前的字段值之后，设置 form 中的 field value
    // 不然再次点击主对象的保存按钮，之前主对象中的附件信息会丢失
    // 如果是多文件字段，则设置其为 id 的列表
    // 如果是单文件字段，则设置其为 id 列表的的第一个元素
    const formFieldValue = multiple ? fileList.map(f => f.id) : fileList.map(f => f.id)[0];
    form?.setFieldsValue({ [columnKey]: formFieldValue });
  }, [setFileList, columnKey, form, multiple]);

  useEffect(() => {
    if (websocket != null) {
      return;
    }
    const onMessageCallback = (serverMsg: string): void => {
      if (serverMsg === WebSocketDummyMessage) {
        return;
      }
      const messageProps: ExtendUploadFile = JSON.parse(serverMsg);
      if (messageProps.status === 'error' && messageProps.operation === 'upload') {
        openErrorNotification(t('File upload failed', {
          message: messageProps.message, fileName: messageProps.name
        }));
        setUploading(false);
        return;
      }
      const newFileList = [...fileListRef.current];
      const find: ExtendUploadFile | undefined = newFileList
        .find(f => f.uid === messageProps.uid);
      if (find == null) {
        console.error("Upload data returned from server not found on frontend: ", messageProps, fileList);
        return;
      }
      const { operation, status, message, data } = messageProps;
      if (data != null && isUploadOperation(operation)) {
        const { id } = data;
        find.id = id;
        find.status = status;
        find.response = message;
        const currValue = form?.getFieldValue(columnKey);
        // 如果现在 fieldValue 中的值不为空则 append 到当前列表
        // 如果现在 fieldValue 中的值为空, 则直接设置
        const newValue = (multiple) ? (currValue ? [...currValue, id] : [id]) : id;
        form?.setFieldsValue({ [columnKey]: newValue });
        // https://github.com/ant-design/ant-design/issues/23156
        // Follow 2 lines is to trigger onValuesChange
        // Otherwise value of attachment field can not be passed to backend on action parameter form
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formDispatch = (form as any).getInternalHooks('RC_FORM_INTERNAL_HOOKS').dispatch;
        formDispatch({ type: 'updateValue', namePath: columnKey, value: newValue });
        updateStatistics();
        openInfoNotification(t('File uploaded', { fileName: find.name }));
      }
      const numOfNonSuccessFiles = newFileList.filter(f => !isSuccess(f))?.length;
      if (numOfNonSuccessFiles === 0) {
        setUploading(false);
      }
    };
    const socketInterface = ReconnectableSocket(`${WEBSOCKET_SERVER_URL}/websocket/fileUpload`);
    socketInterface.on((message: string) => onMessageCallback(message));
    setWebsocket(socketInterface);
    //组件 unload 的时候关闭 websocket 连接
    return function cleanup() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      websocket?.close();
    };
  }, [
    columnKey, domainName, ownerId, deleteOneFile, websocket, fileList,
    fileListRef, form, setFileList, updateStatistics, multiple, record,
    setDefaultFileList, maxCount, t, bDynamicField, isSingleStaticMode
  ]);

  const columnValue = record?.[columnKey];
  useEffect(() => {
    const value = columnValue;
    setLoading(true);
    // 如果是动态字段，且 value 为空，表示该字段的值为空，直接返回
    if (bDynamicField && value == null) {
      setLoading(false);
      return;
    }
    //获取当前后端保存的文件列表并写入状态变量
    //注意: 对于 wizard 中的动态字段， ownerId 会是空的
    //注意: 对于一对多的 storage field value，在列表页面，API 默认返回的 value 为空,
    //需要在这里主动去后端查询一下关联对象的列表
    if (value != null || isArrayType) {
      // 如果是动态字段，那么 record 中应该包含其 id 信息了
      if (bDynamicField) {
        const ids = multiple ? value : ((typeof value === 'object' && 'id' in value) ? [value.id] : [value]);
        fetchCurrentValues(FileFieldType, ids, ExcludeArrayColumnsFetchType).then(json => {
          const apiResultWrap = {
            total: json.length,
            data: json
          };
          setDefaultFileList(apiResultWrap);
        }).finally(() => setLoading(false));
      } else if (ownerId != null) {
        //如果是更新或者显示现有对象,
        if (multiple) {
          // 如果是静态字段，且支持 multiple,
          // 表示是一个从 owner 到 storage file value 的一对多关联
          // 而对于 array 类型的字段， show 接口是不会获取 array 字段的值
          // 因此就算 value 为空，也需要在这里获取关联字段的值
          fetchListOfRelateDomainData({
            domainName,
            ownerId,
            columnName: columnKey,
            current: 1,
            max: maxCount ?? MaxUploadFileCount,
            useCache: false
          }).then(result => {
            setDefaultFileList(result);
          }).finally(() => setLoading(false));
        } else {
          // 如果是静态字段，不支持 multiple 的情况，直接使用 id 获取 StorageFileValue
          (columnValue?.id != null) && fetchCurrentValue(FileFieldType, columnValue?.id).then(json => {
            const apiResultWrap = {
              total: 1,
              data: [json]
            };
            setDefaultFileList(apiResultWrap);
          }).finally(() => setLoading(false));
          (columnValue?.id == null) && setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [
    ownerId, multiple, domainName, columnKey, bDynamicField, maxCount,
    columnValue, setDefaultFileList, isArrayType
  ]);

  const noNeedUpdateStatuses = ['done', 'success', 'removed'];

  const handleUpload = (e?: React.MouseEvent<unknown>, newFileList?: Array<ExtendUploadFile>): void => {
    if (e != null) {
      stopPropagationAndPreventDefault(e);
    }
    const latestFileList = newFileList ?? fileList;
    setUploading(true);
    // Upload file
    for (const file of latestFileList.reverse()) {
      // 使用 reverse 翻转之后再处理的原因:
      // 对于一对一的情况, 如果是用新文件替换旧文件，
      // 应该先上传再删除, 不然非空校验会出问题
      const { id: attachmentId, uid, status } = file;
      const statusStr: string = (status ?? '');
      // 上传待上传的文件
      if (!noNeedUpdateStatuses.includes(statusStr)) {
        websocket?.sendFile({
          id: attachmentId ?? undefined,
          uid,
          ownerId,
          ownerClass: domainName,
          columnNameInOwnerClass: columnKey,
          multiple,
        }, (file as unknown) as File);
      }
    }
  };

  // 在 FileItemRender 中重命名后，刷新文件显示列表
  const onRename = (file: ExtendUploadFile, filename: string): void => {
    const extendFile = fileList.find(f => f.uid === file.uid);
    if (extendFile != null) {
      // 如果是已经上传到服务器的文件，标记状态为 removed
      // 用户后续点击保存文件更新按钮的时候往服务器发请求
      extendFile.name = filename;
      setFileList(fileList);
    }
    updateStatistics();
  };

  const onRemove = (file: UploadFile): void => {
    const newFileList = [...fileList];
    const extendFile = newFileList.find(f => f.uid === file.uid);
    const fileOnServer = (extendFile?.id != null);
    if (extendFile != null && fileOnServer) {
      deleteAttachment({
        id: extendFile.id,
        uid: extendFile.uid,
        ownerId,
        ownerClass: domainName,
        columnNameInOwnerClass: columnKey,
        multiple
      }).then((json: BaseResponseProps) => {
        if (json.success === true) {
          // 如果是已经上传到服务器的文件，直接从文件列表中删除
          const index = newFileList.indexOf(file);
          const updatedList = newFileList.slice();
          updatedList.splice(index, 1);
          setFileList(updatedList);
          openInfoNotification(t('File deleted', {fileName: file.name}));
        } else {
          console.error(`Failed to delete file ${JSON.stringify(file)}`, json.message);
          openErrorNotification(json.message, wrapAsHtml(t('Delete attachment failed', {fileName: file.name})));
        }
      }).catch((e: unknown) => {
        console.error(`Failed to delete file ${JSON.stringify(file)}`, e);
        openErrorNotification(e, t('Delete attachment failed', {fileName: file.name}));
      });
    } else {
      // 如果是还没有上传到服务器的文件，直接删除
      const index = fileListRef.current.indexOf(file);
      const newFileList = fileListRef.current.slice();
      newFileList.splice(index, 1);
      // 如果是单文件模式，且已经存在上传过服务器的文件
      // 则删除了没有上传服务器的替换文件后
      // 要将标记为已删除的文件标记回来
      if (!multiple && newFileList.length > 0) {
        newFileList[0].status = 'done';
      }
      setFileList(newFileList);
    }
    updateStatistics();
  };

  const validNumberOfFiles = (file: UploadFile): boolean => {
    //进行文件个数限制的判断, 如果 multiple 是 false, 直接设置为 1
    const actualMaxCount = (multiple) ? (maxCount ?? MaxUploadFileCount) : 1;
    const nonRemoveFiles = fileListRef.current.filter(f => f.status !== 'removed');
    const numOfFilesExceedLimit = (nonRemoveFiles.length >= actualMaxCount && multiple);
    if (numOfFilesExceedLimit) {
      openErrorNotification(
        t("Max files added to attachment", { maxCount: actualMaxCount }),
        t("File didn't add to attachment list", { fileName: file.name })
      );
      deleteOneFile(file);
    }
    return !numOfFilesExceedLimit;
  };

  const validFileSize = (file: UploadFile): boolean => {
    //进行单个文件大小的限制判断
    const actualMaxSize = (maxSizeMB ?? MaxUploadFileSizeInMB);
    const fileSizeInMB = ((file.size ?? 0) / 1024 / 1024);
    const isSizeLargeThanMax = fileSizeInMB > actualMaxSize;
    if (isSizeLargeThanMax) {
      openErrorNotification(
        t("File size exceed limitation", { maxSizeMB: actualMaxSize }),
        t("File didn't add to attachment list", { fileName: file.name })
      );
      deleteOneFile(file);
    }
    return !isSizeLargeThanMax;
  };

  const validTotalSize = (file: UploadFile): boolean => {
    //进行总的文件大小的限制判断
    const actualTotalMaxSizeMB = (totalMaxSizeMB ?? MaxTotalFileSizeInMB);
    const fileSizeInMB = ((file.size ?? 0) / 1024 / 1024);
    const newTotalMaxSizeMB = (multiple) ? totalSize + fileSizeInMB : totalSize;
    const totalSizeExceedLimit = (newTotalMaxSizeMB > actualTotalMaxSizeMB);
    if (totalSizeExceedLimit) {
      openErrorNotification(
        t("File total size exceed limitation", { maxSizeMB: actualTotalMaxSizeMB }),
        t("File didn't add to attachment list", { fileName: file.name })
      );
      deleteOneFile(file);
    }
    setTotalSize(newTotalMaxSizeMB);
    return !totalSizeExceedLimit;
  };

  const beforeUpload = (file: UploadFile): boolean => {
    const numOfFileValid = validNumberOfFiles(file);
    if (!numOfFileValid) { return false; }

    const fileSizeValid = validFileSize(file);
    if (!fileSizeValid) { return false; }

    const totalSizeValid = validTotalSize(file);
    if (!totalSizeValid) { return false; }

    if (!multiple) {
      // 如果是单文件模式，直接将新文件设置为当前的 fileList 并上传
      // 后台 API 会处理现有数据的删除
      setFileList([file]);
      handleUpload(undefined, [file]);
    } else {
      // 因为用户没选择一个文件，都会触发一次 beforeUpload,
      // 所以每次只要上传本次触发方法的文件即可
      handleUpload(undefined, [file]);
      // 如果是多文件模式，直接将本次选择的文件添加到文件列表
      setFileList([...fileListRef.current, file]);
    }
    return false;
  };

  const onPreview = async (file: ExtendUploadFile): Promise<void> => {
    setPreviewFile(file);
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      const newFileList: Array<ExtendUploadFile> = update(fileList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const ids: Array<number> = newFileList.filter(f => f?.id != null).map(f => f.id);
      ids.length > 0 && updateAttachmentsDisplaySequence(ids).then((json: BaseResponseProps) => {
        if (json.success === true) {
          setFileList(newFileList);
          openInfoNotification(t('Change attachment display sequence success'));
        } else {
          openErrorNotification(json.message, t('Failed to change attachment display sequence'));
        }
      });
    }, [fileList, setFileList, t],
  );

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const renderProps: UploadProps<unknown> = {
    accept,
    maxCount,
    onRemove,
    //用户选择文件后, 不直接上传, 先加入到 fileList 中,用户保存上传按钮后再上传
    beforeUpload,
    fileList,
    multiple,
    onDownload,
    onPreview,
    onChange,
    itemRender: (originNode: React.ReactElement, file: UploadFile, fileList: Array<UploadFile<unknown>>, actions: {
      download: () => void;
      preview: () => void;
      remove: () => void;
    }): React.ReactNode => {
      return (
        <FileItemRender
        actions={actions}
        file={file}
        fileList={fileList}
        originNode={originNode}
        updatable={updatable}
        moveRow={moveRow}
        rename={onRename}
        remove={onRemove}
      />);
    }
  };

  useEffect(() => {
    const allUploaded = fileList.every(file => {
      const { status } = file;
      return status === 'done';
    });
    setAllFilesUploaded(allUploaded);
  }, [fileList]);

  const uploadButtonDisable = (fileList.length === 0 || uploading || allFilesUploaded);
  const mKey = (getOperatingSystem() === 'MacOS') ? "Cmd" : "Ctrl";
  const selectFileButtonIcon = (<PaperClipOutlined style={buttonIconStyle} />);

  const getButton = (message: ReactNode): ReactElement => (
    <Button
      title={t('Click to select file(s)')}
      size="large"
      icon={selectFileButtonIcon}
    > {message} </Button>
  );

  const selectFileButton = multiple ?
    getButton(<Trans>Select <kbd>{{ mKey }}</kbd> files</Trans>) :
    ((fileListRef.current.length === 1) ?
      (<Tooltip title={t('Select a new file will replace current one')}>
        {getButton(<Trans>Select file</Trans>)}
      </Tooltip>
      ) : getButton(<Trans>Select file</Trans>));

  const uploadButton = (
    <Button
      size="large"
      onClick={handleUpload}
      disabled={uploadButtonDisable}
      loading={uploading}
      icon={uploading ?
        <LoadingOutlined style={buttonIconStyle} /> : <CloudUploadOutlined style={buttonIconStyle} />
      }
      title={t(uploadButtonDisable ? 'No file pending for upload' : 'File(s) list')}
    >
      <Space>
        {t(uploading ? 'Uploading' :
          ((fileList.length > 0) ? 'Upload file(s)' :
            (multiple ? 'Please select one or more files first' : 'Please select a file first')))}
        {fileList.length > 0 &&
          <>
            {numberDisplay}
            <Progress
              type="line"
              size={fileList.length > 5 ? "small" : undefined}
              percent={Number.parseInt(percent.toFixed(0))}
              className={Number.parseInt(percent.toFixed(0)) === 100 ? "success" : ""}
              steps={fileListRef.current.filter(f => f.status !== 'removed').length}
            />
          </>
        }
      </Space>
    </Button>
  );

  const dragArea = (
    <div className="drag-instruction">
      {!multiple && <Trans>Drag file from disk to here <br /> and click upload button</Trans>}
      {multiple && <Trans>Drag files from disk to here <br /> and click upload button</Trans>}
    </div>
  );
  const displayStyle = (updatable ? undefined : 'none');
  const className = (updatable ? "file-upload-updatable" : "file-upload-readonly");
  if (loading) {
    return <Spin />;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Upload {...renderProps} className={`file-upload-component ${className}`}>
        <Space direction={"vertical"} style={{ display: displayStyle }}>
          {selectFileButton}
          {dragArea}
          {uploadButton}
        </Space>
      </Upload>
      {previewFile != null && <FilePreview
                                file={previewFile} onClose={() => setPreviewFile(undefined)}
                                zIndex={zIndex}
      />}
    </DndProvider>
  );
};

export default FileOperator;
