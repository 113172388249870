import { DetailPanelProps, TableMetaProps } from "@props/RecordProps";
import { DetailComponentRenderFunction, FieldConfigures } from "./ComponentsConfig";
import React, { ReactElement, useState } from "react";
import { ColumnsUtils } from "@utils/index";
import { useResizeDetector } from "react-resize-detector";
import { stopPropagationAndPreventDefault } from "@utils/ObjectUtils";
import {
  MonitorOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";

/**
 * 获取某种列类型的 detail 模式（在编辑或者察看详情时候，右边部分的显示）
 */
export const getDetailRenderFunction = (
  column: TableMetaProps
): DetailComponentRenderFunction | undefined => {
  const { type } = column;
  const detailComponent =
    column?.extInfo?.summaryField ?
      wrapperRender(FieldConfigures.find(fc => fc.type === type)?.detailComponent) :
      FieldConfigures.find(fc => fc.type === type)?.detailComponent;
  return detailComponent;
};

const wrapperRender = (render: DetailComponentRenderFunction | undefined): DetailComponentRenderFunction | undefined => {
  if (!render) {
    return undefined;
  }
  return (props: DetailPanelProps): ReactElement => {
    const { column, record, zIndex } = props;
    const summaryField = ColumnsUtils.summaryField(column);
    if (summaryField && record?.[summaryField]) {
      const [showSummary, setShowSummary] = useState<boolean>(true);
      const summaryRecord = {
        ...record,
        [column.key]: record[summaryField],
      };
      const { width, height, ref } = useResizeDetector();
      const summaryProps: DetailPanelProps = {
        ...props,
        record: showSummary ? summaryRecord : record,
      };
      // const [originalElement, summaryElement] = useState(() => [render(props), render(summaryProps)]);
      // FIXME: Fix translation
      return <div>
        {showSummary ? (<div>
          <div
            style={{
              position: "absolute",
              width,
              height,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: zIndex + 1,
            }}
            onMouseEnter={(e) => stopPropagationAndPreventDefault(e)}
            onMouseLeave={(e) => stopPropagationAndPreventDefault(e)}
            onClick={(e) => stopPropagationAndPreventDefault(e)}
          >
            <Tooltip title="点击显示详情">
              <Button
                size={"large"}
                shape="circle"
                onClick={() => setShowSummary(false)}
              >
                <MonitorOutlined
                  style={{
                    fontSize: 24,
                  }}
                />
              </Button>
            </Tooltip>
          </div>
          <div
            ref={ref}
            style={{
              filter: showSummary ? "blur(1px) brightness(0.95)" : undefined,
            }}
          >
            {render(summaryProps)}
          </div>
        </div>) : (
          <div>
            {render(props)}
          </div>)}
      </div>;
    }
    return render(props);
  };
};
