import React, { ErrorInfo, ReactNode } from "react";
import { DashboardWidgetProps, WidgetDataResponseProps } from "@props/RecordProps";
import { Alert } from "antd";

export class WidgetErrorBoundary extends React.Component<{
  widget: DashboardWidgetProps;
  dataAndConfigs: WidgetDataResponseProps;
}, { hasError: boolean }> {
  constructor(props: Readonly<{ widget: DashboardWidgetProps; dataAndConfigs: WidgetDataResponseProps; }>) {
    super(props);
    this.state = { hasError: false };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError = (error: unknown): { hasError: boolean } => {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  };

  componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
    console.error("error: ", error);
    console.error("errorInfo: ", errorInfo);
  };

  render(): ReactNode {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      const message = `Failed to render widget[${JSON.stringify(this.props.widget)}] with dataAndConfig [${JSON.stringify(this.props.dataAndConfigs)}]`;
      return (<Alert
        message="Failed to render widget"
        description={message}
        type="error"
        className="light-bg-alert"
        showIcon
      />);
    }

    return this.props.children;
  }
}
