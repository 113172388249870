import { SaveRecordProps, TableMetaProps } from "@props/RecordProps";
import React from "react";
import { Form } from "antd";
import { getValuePropName } from "@kernel/EditableComponentsMapping";
import { DisplayRenderProps, EditableRenderProps } from "./EntityAttributesTable";

export interface EntityAttributesCellProps {
  column: TableMetaProps;
  record: SaveRecordProps;
  editMode: boolean;
  DisplayRender: React.FC<DisplayRenderProps>;
  EditableRender?: React.FC<EditableRenderProps>;
  path?: string;
}

export const EntityAttributesCell: React.FC<EntityAttributesCellProps> = (props: EntityAttributesCellProps) => {
  const {
    column, record, editMode, path,
    DisplayRender, EditableRender,
  } = props;
  const { key, type } = column;

  // console.log('EntityAttributesCell re-rendered', column.key);

  return (<td key={`entity-attribute-cell-${path ?? column.key}`} className={`entity-attribute-cell-td-${column.type} entity-attribute-cell-td-${column.key}`}>
    <Form.Item
      name={key}
      key={key}
      valuePropName={getValuePropName(type)}
      style={{
        marginBottom: 0,
      }}
    >
      {editMode && column.editable && EditableRender
        // ? a
        ? EditableRender({ record })
        : <DisplayRender key={`entity-attribute-cell-readonly-${column.key}`} record={record}/>}
    </Form.Item>
  </td>);
};
