import React, { CSSProperties, ReactElement, useState } from 'react';
import { Card, Space } from 'antd';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import CustomAvatar from '../../components/avatar/CustomAvatar';
import { MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DomainComment } from './CommentProps';
import { RecordProps, TableMetaProps } from '@props/RecordProps';
import CommentForm from './CommentForm';
import Operations from '../../form/list/Operations';
import { CommentDomainName } from '.';
import { CheckCircleOutlined } from '@ant-design/icons';

dayjs.extend(relativeTime);

interface CommentProps {
  owner: RecordProps;
  comment: DomainComment;
  updateCallback: (data: DomainComment) => void;
  fetchDataCallback: () => void;
  column: TableMetaProps;
  mainDomainName: string;
  zIndex: number;
  className?: string;
  objectTypeId: number;
  highlight?: boolean;
}

const Comment = (props: CommentProps): ReactElement => {
  const {
    comment, updateCallback, owner, zIndex, column, mainDomainName,
    fetchDataCallback, className, objectTypeId, highlight
  } = props;
  const { id, dateCreated, createdBy, replies } = comment;
  const [showOperations, setShowOperations] = useState<boolean>(false);
  const [showReplyBox, setShowReplyBox] = useState<boolean>(false);
  const { t } = useTranslation();

  const extra = (<Space>
    {showOperations && <span>
      {comment.replyTo == null &&
        <a
          href="/#"
          title={t('Reply')}
          onClick={(e) => {
            e.preventDefault();
            setShowReplyBox(!showReplyBox);
          }}
        >
          <MessageOutlined className="small-clickable-icon" />
        </a>}
    </span>
    }
    {showOperations && <span>
      <Operations
        domainName={CommentDomainName}
        updateCallback={() => fetchDataCallback()}
        ownerId={owner.id}
        ownerClass={mainDomainName}
        page="detail-drawer"
        zIndex={zIndex}
        showActions={true}
        id={id}
        deleteCallback={() => {
          fetchDataCallback();
        }}
      />
    </span>
    }
    <span
      title={dayjs(dateCreated).format("dddd, MMMM Do YYYY, h:mm:ss a")}>
      {dayjs(dateCreated).fromNow()}
    </span>
  </Space>);

  const contentStyle: CSSProperties = highlight? {float: 'left'} : {} as CSSProperties;

  return (
    <div
      className={`comment-item ${className}`}
      onMouseEnter={() => setShowOperations(true)}
      onMouseLeave={() => setShowOperations(false)}
      key={id}>
      <Card title={<CustomAvatar userId={createdBy} showText={true} />}
        bordered={true}
        size="default"
        extra={extra}>
        <Space direction="vertical" className="comment" style={{ width: "100%" }}>
          <div className={highlight? 'highlight-comment-content' : 'comment-content'}>
            <pre style={contentStyle}>
              {highlight &&
                <CheckCircleOutlined />
              }
              {comment.content}
            </pre>
          </div>
          {showReplyBox &&
            <div className="reply-box-container">
              <CommentForm
                owner={owner}
                zIndex={zIndex}
                mainDomainName={mainDomainName}
                column={column}
                replyTo={comment}
                fetchDataCallback={fetchDataCallback}
                objectTypeId={objectTypeId}
              />
            </div>
          }
          {replies && replies.length > 0 &&
            <div className="replies-container">
              {replies.map((reply: DomainComment) => {
                return (
                  <Comment
                    key={reply.id}
                    comment={reply}
                    updateCallback={updateCallback}
                    fetchDataCallback={fetchDataCallback}
                    owner={owner}
                    zIndex={zIndex}
                    column={column}
                    mainDomainName={mainDomainName}
                    objectTypeId={objectTypeId}
                    highlight={false}
                  />
                );
              })}
            </div>
          }
        </Space>
      </Card>
    </div>);
};

export default Comment;
