/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactElement } from 'react';
import {
  ActionProps, ExecResultProps, ActionExecResult, ActionMode, MiddleStepExecContextProps, ExtendUploadFile
} from "@props/RecordProps";
import { Space, Timeline } from "antd";
import { random } from 'lodash';
import {
  FileDoneOutlined, CloseCircleOutlined, CheckCircleOutlined,
  ClockCircleOutlined, IssuesCloseOutlined, LinkOutlined,
  ExceptionOutlined, PaperClipOutlined, DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExpandableContentComponent } from '../../components';
import { ActionExecuteResultPanelMaxWidth, CustomIcon } from '@config/base';
import { formatTime } from '@utils/DateUtils';
import { getNumberOfLineBreaks } from '@utils/ObjectUtils';
import { displaySingleResult, wrapAsHtml } from '@utils/ComponentUtils';
import { StacktraceCell } from '../../form/cells';
import { RedirectComponent } from '../../components/redirect';
import { onDownload } from '../../components/fileOperator';
import PreviewAction from './PreviewAction';

export interface ActionResultDisplayProps {
  action: ActionProps;
  result: ExecResultProps | ActionExecResult;
  initDisplay: boolean;
  toggleDisplayCallback: (visible: boolean) => void;
  titleRightExtraRenderFunc?: (objectIds?: Array<number>) => ReactElement;
  mode: ActionMode;
  zIndex: number
  fetchDataCallback?: () => void;
  isMiddleStepResult?: boolean;
  round?: number;
  middleStepExecContext?: MiddleStepExecContextProps;
  fineTuningResult?: string;
}

const ActionResultDisplay = (props: ActionResultDisplayProps): ReactElement => {
  const {
    result, action, fetchDataCallback, zIndex, isMiddleStepResult, round, fineTuningResult
  } = props;

  const { t, i18n } = useTranslation();
  const renderSingleResult = (
    props: ActionResultDisplayProps, result: ExecResultProps,
    forMultiple: boolean
  ): ReactElement => {
    const {
      action, initDisplay, toggleDisplayCallback, titleRightExtraRenderFunc,
    } = props;
    const { name } = action;
    const {
      status, execLog, execResult, submitUser, submitTime,
      finishTime, startTime, redirect, stackTrace, objectId,
      download
    } = result;

    const fail = (status === 'FAILED');
    const successWithWarning = (status === 'SUCCESS_WITH_WARNING');
    const color = (fail) ? "red" : (successWithWarning) ? "#FFA500" : "green";
    const style = successWithWarning ? { color: "#FFA500" } : undefined;
    const statusIcon = (fail) ? <CloseCircleOutlined /> : (successWithWarning) ?
      <IssuesCloseOutlined style={style} /> : <CheckCircleOutlined />;
    const stackTraceNotEmpty = (stackTrace != null && stackTrace !== '');
    const execResultNotEmpty = (execResult != null && execResult !== '');
    const execLogNotEmpty = (execLog != null && execLog !== '');
    const startTimeNotEmpty = (startTime != null && startTime !== '');
    const finishTimeNotEmpty = (finishTime != null && finishTime !== '');
    const actionTitleTrans = t(`dynamicAction:${action.label ?? action.name}`);
    const roundTitle = (round != null) ? t("Round") + " " + round : '';
    const title = `${actionTitleTrans} ${objectId ?? ""} ${roundTitle}`;
    const redirectNotEmpty = (redirect != null && redirect !== '');
    const downloadNotEmpty = (download != null);
    const key = (objectId ?? name).toLocaleString();
    const execLogDisplay = (i18n.exists(execLog)) ? t(execLog) : execLog;
    const idParam = (Array.isArray(objectId)) ?
      objectId : [objectId as number];
    const extraRight = (titleRightExtraRenderFunc && objectId) ?
      titleRightExtraRenderFunc(idParam) : undefined;
    const modal = (
      <ExpandableContentComponent
        titleRightExtra={extraRight}
        toggleDisplayCallback={(visible) => {
          toggleDisplayCallback(visible);
        }}
        initDisplay={initDisplay ? random(0, 1) : false}
        initNumOfLineBreaks={getNumberOfLineBreaks(result, fineTuningResult, isMiddleStepResult)}
        className={`action-execute-result ${status?.toLowerCase()}`}
        key={key}
        visible={result != null}
        icon={statusIcon}
        content={(
          <div className="timeline-container">
            <Timeline mode="left">
              {!isMiddleStepResult && <Timeline.Item
                color="blue"
                label={formatTime(submitTime)}
                dot={<ClockCircleOutlined />}
              > {t('Submitted by', { submitUser })} </Timeline.Item>
              }
              {(startTimeNotEmpty && !isMiddleStepResult) &&
                <Timeline.Item
                  label={startTime != null ? formatTime(startTime) : "未开始"}
                  color="blue"
                  dot={<ClockCircleOutlined />}
                > {t('Started')} </Timeline.Item>
              }
              {(finishTimeNotEmpty && !isMiddleStepResult) &&
                <Timeline.Item
                  color={color}
                  label={formatTime(finishTime)}
                  dot={statusIcon}
                > {t('Finished', { status })} </Timeline.Item>
              }
              {execLogNotEmpty &&
                <Timeline.Item
                  color="blue"
                  label={t("Log")}
                  dot={<CustomIcon type="icon-icon_log" />}
                > {wrapAsHtml(execLogDisplay)} </Timeline.Item>}
              {execResultNotEmpty &&
                <Timeline.Item
                  color="blue"
                  label={t("Result")}
                  dot={<FileDoneOutlined />}
                > {wrapAsHtml(execResult)} </Timeline.Item>}
              {(fineTuningResult != null) &&
                <Timeline.Item
                  color="blue"
                  label={t("Fine Tuning Result")}
                  dot={<FileDoneOutlined />}
                > {wrapAsHtml(fineTuningResult)} </Timeline.Item>}
              {redirectNotEmpty && !isMiddleStepResult &&
                <Timeline.Item
                  color="blue"
                  label={t("Redirect")}
                  dot={<LinkOutlined />}
                >
                  <RedirectComponent
                    forMultiple={forMultiple}
                    fetchDataCallback={fetchDataCallback}
                    redirect={redirect}
                    zIndex={zIndex + 1}
                  />
                </Timeline.Item>}
              {downloadNotEmpty && !isMiddleStepResult &&
                <Timeline.Item
                  color="blue"
                  label={t("Result file download")}
                  dot={<DownloadOutlined />}
                >
                  <Space
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onDownload({
                        id: download.id,
                        name: download.name,
                        data: download
                      } as ExtendUploadFile);
                    }}
                  >
                    <PaperClipOutlined />
                    {t("Download")}
                  </Space>
                  {<PreviewAction
                     file={download}
                     zIndex={zIndex + 1}
                     displayTextAndIcon={true}
                  />}
                </Timeline.Item>}
              {stackTraceNotEmpty &&
                <Timeline.Item
                  color={"blue"}
                  label={t("Exception Stacktrace")}
                  dot={<ExceptionOutlined />}
                >
                  <StacktraceCell
                    zIndex={zIndex + 1}
                    value={stackTrace ?? ""}
                    page="ACTION_RESULT"
                  />
                </Timeline.Item>}
            </Timeline>
          </div >
        )}
        showDownload={false}
        title={< span title={title} className="title-label" > {title} </span >}
        customStyle={{
          width: "100%",
          maxWidth: ActionExecuteResultPanelMaxWidth,
        }} />
    );

    return (<div key={key}> {modal} </div>);
  };

  const renderMultipleResult = (
    props: ActionResultDisplayProps, result: ActionExecResult
  ): ReactElement => {
    const elements: Array<ReactElement> = [];
    let idx = 0;
    for (const objId in result) {
      if (!Object.prototype.hasOwnProperty.call(result, objId)) {
        continue;
      }
      const resultForObj = result[objId];
      elements.push(renderSingleResult(props, resultForObj, idx !== 0));
      idx += 1;
    }
    const renderElement = (<span>{elements.map(e => e)}</span>);
    return renderElement;
  };

  return displaySingleResult(action.mode) ?
    //@ts-ignore
    renderSingleResult(props, result) : renderMultipleResult(props, result);

};

export default ActionResultDisplay;
