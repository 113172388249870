import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Menu, Badge, Dropdown, Image, Space } from 'antd';
import { Link, } from 'react-router-dom';
import {
  UserOutlined, LogoutOutlined, CaretDownOutlined,
  EditOutlined, BellOutlined, GlobalOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getAvatar, getUsername } from "@utils/TokenUtils";
import { WEBSOCKET_SERVER_URL } from '@config/base';
import { loggedIn, logout } from "@utils/LoginUtils";

import { Message, MessageSummaryResponseProps, Store } from '@props/RecordProps';
import ReconnectableSocket, { SocketInterface } from '@utils/WebsocketUtils';
import {
  openErrorNotification, openImportantNotification, openInfoNotificationWithDuration,
  openWarningNotificationWithDescription
} from '@utils/NotificationUtils';
import ReactMarkdownWrap from '../components/wrap/ReactMarkdownWrap';
import Modal from 'antd/lib/modal/Modal';
import UserProfileUpdateComponent from './UserProfileUpdateComponent';
import './App.css';
import { refreshToken } from '@utils/FetchUtils';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ActionRequiredNotification } from '../components/notification';
import { SwitchLanguage } from "../development";

const SeverityPriority = {
  "INFO": 0,
  "WARNING": 1,
  "ERROR": 2,
  "IMPORTANT": 3,
  "ACTION_REQUIRED": 4,
};

interface UserProfileProps {
  collapsed: boolean;
}

const UserProfile = (props: UserProfileProps): ReactElement => {
  const { collapsed } = props;
  const { t } = useTranslation();
  const [messageSummary, setMessageSummary] = useState<MessageSummaryResponseProps>();
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState<boolean>(false);
  const [avatarData, setAvatarData] = useState<string | null>(getAvatar());
  const isRefreshingToken = useRef<boolean>(false);
  const [actionMessages, setActionMessages] = useState<Array<Message>>([]);
  const [rawMessages, setRawMessages] = useState<string | null>(null);

  useEffect(() => {
    let websocket: (SocketInterface | undefined) = undefined;
    if (loggedIn()) {
      websocket = ReconnectableSocket(`${WEBSOCKET_SERVER_URL}/websocket/messageSummary`);
      websocket?.on((message: string) => {
        setRawMessages(message); // Set the raw messages to state
      });
    }
    return function cleanup() {
      websocket?.close();
    };
  }, []);

  useEffect(() => {
    if (rawMessages) {
      const mp: MessageSummaryResponseProps = JSON.parse(rawMessages);
      setMessageSummary(mp);
      let highestSeverity = 0;
      const actionMessages = mp?.messages.filter((m) => m.severity === "ACTION_REQUIRED");
      setActionMessages(actionMessages);
      // 获取最高的消息级别，并将最高级别的消息放到 elems 变量中
      const elems: Array<ReactElement> = mp?.messages
        ?.filter(((m) => m.severity !== "ACTION_REQUIRED"))
        ?.map((m, idx) => {
          const newSeverity = SeverityPriority[m.severity];
          if (newSeverity > highestSeverity) {
            highestSeverity = newSeverity;
          }
          return (<ReactMarkdownWrap
            linkTarget="_blank"
            className="markdown-display"
            key={idx}
          >{m.content}</ReactMarkdownWrap>);
        });
      const reactContent = (<>{elems}</>);
      if (elems?.length > 0) {
        if (highestSeverity === 0) {
          openInfoNotificationWithDuration(t("Info push message"), reactContent, 0);
        } else if (highestSeverity === 1) {
          openWarningNotificationWithDescription(t("Warning push message"), reactContent);
        } else if (highestSeverity === 2) {
          openErrorNotification(reactContent);
        } else if (highestSeverity >= 3) {
          // Need a special style for important message
          openImportantNotification(t("Important push message"), reactContent);
        }
      }
    }
  }, [t, rawMessages]);

  const closeModal = useCallback((): void => {
    setShowUpdateProfileModal(false);
  }, []);

  const openModal = useCallback((): void => {
    setShowUpdateProfileModal(true);
  }, []);

  const refreshAvatar = useCallback((): void => {
    isRefreshingToken.current = true;
    refreshToken();
    setTimeout(() => {
      setAvatarData(getAvatar());
      isRefreshingToken.current = false;
    }, 5000);
  }, []);

  const refreshProfile = useCallback((): void => {
    refreshAvatar();
    closeModal();
  }, [refreshAvatar, closeModal]);

  const updateProfileModal = (
    <Modal
      open={showUpdateProfileModal}
      onCancel={closeModal}
      footer={null}
      className="update-user-profile-modal"
    >
      <UserProfileUpdateComponent
        onFinish={(): void => {
          refreshProfile();
        }}
        onFinishFailed={(values: Store): void => {
          console.error(`Failed to save ${JSON.stringify(values)}`);
        }}
        zIndex={3}
      />
    </Modal>
  );

  const isAvatarEmpty = (avatarData == null || avatarData === '');
  const src = isAvatarEmpty ? <UserOutlined /> : <Image src={avatarData ?? undefined} preview={false} />;
  const className = isAvatarEmpty ? "user-profile-avatar-empty" : "user-profile-avatar-image";
  const avatar = (<Avatar src={src} size={collapsed ? "large" : "small"} className={className} />);

  const overlay = (
    <Menu>
      <Menu.Item key={"username"} icon={<UserOutlined />}>
        {getUsername()}
      </Menu.Item>
      <Menu.Item
        key={"update-profile"}
        icon={<EditOutlined />}
        onClick={openModal}
      >
        {t('Update user profile')}
      </Menu.Item>
      <Menu.Item
        key={"change-language"}
        icon={<GlobalOutlined />}
      >
        <Space size={"small"}>
          <span>{t('Language')}</span>
          <SwitchLanguage />
        </Space>
      </Menu.Item>
      <Menu.Item
        key={"logout"}
        onClick={(info: MenuInfo) => {
          stopPropagationAndPreventDefault(info.domEvent as React.MouseEvent<unknown>);
          logout();
        }}
        icon={<LogoutOutlined />}
      >
        {t('Logout')}
      </Menu.Item>
    </Menu>
  );

  const title = (
    t('Unread message tooltip', { num: messageSummary?.numberOfUnread ?? "" })
    + " | " + t('Followup message tooltip', { num: messageSummary?.numberOfFollowup ?? "" })
  );

  const getIconWithBadge = (icon: ReactElement, link: string): ReactElement => {
    const iconWithLink = (<Link to={link}>{icon}</Link>);
    return (
      <>
        {hasUnreadMessage &&
          <Badge
            count={messageSummary?.numberOfUnread}
            size="small"
            title={title}
          >
            {iconWithLink}
          </Badge>}
        {!hasUnreadMessage && iconWithLink}
        <ActionRequiredNotification
          messages={actionMessages}
          zIndex={0}
        />
      </>);
  };

  const hasUnreadMessage = ((messageSummary?.numberOfUnread ?? 0) > 0);
  const profileIcon = <Dropdown
    overlay={overlay}
    trigger={["click"]}
    arrow={false}
    placement="bottom"
  >
    {avatar}
  </Dropdown>;
  if (collapsed) {
    return getIconWithBadge(avatar, "/inbox");
  }
  return (
    <>
      <Space className="current-login-user" size={4}>
        <Space size={1}>
          {profileIcon}
          <CaretDownOutlined className="user-profile-dropdown-icon" />
        </Space>
        {getIconWithBadge(<BellOutlined />, "/inbox")}
      </Space>
      {updateProfileModal}
    </>
  );
};

export default UserProfile;
