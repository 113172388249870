import { saveDomain, } from './FetchUtils';
import { SERVER_URL } from '@config/base';
import {
  ColumnType,
  ContinueOperate,
  RecordProps,
  RecordValueType,
  SaveRecordProps,
  TableMetaProps
} from "@props/RecordProps";
import { FieldConfigures, getDestTypeForComponentLookup } from "@kernel/ComponentsConfig";

let lastTrigger: number | undefined;

export const getLastTrigger = (): number | undefined => {
  return lastTrigger;
};

export const setLastTrigger = (trigger: number): void => {
  lastTrigger = trigger;
};

// FIXME Change the type of props to a specific type and remove the eslint-disable-next-line
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export async function callSaveDomainWithCallback(props: any): Promise<any> {
  const {
    values, domainName, callback, continueOperate, columns, options
  } = props;
  const isCreate = (values.id == null);
  const params = new URLSearchParams();
  if (options) {
    params.append('options', JSON.stringify(options));
  }
  const url = `${isCreate ? `${SERVER_URL}/${domainName}` : `${SERVER_URL}/${domainName}/${values.id}`}?${params.toString()}`;
  const method = isCreate ? 'POST' : 'PUT';
  const convertedValue: SaveRecordProps = { ...values };
  columns?.forEach((column: TableMetaProps): void => {
    // 不能直接通过 save 对象的接口传递到后端的所有列类型列表
    // 这些字段是通过别的方式更新，不能直接在接口中传递 null 值到后端
    const shouldNotPassValueTypes: ColumnType[] = ['array', 'comments'];
    if (shouldNotPassValueTypes.includes(column.type)) {
      // Remove value of this column from values
      // 或者将这部分逻辑放到 callSaveDomainWithCallback 中
      return;
    }
    convertedValue[column.key] = convertValueForSave(column, values);
  });
  return saveDomain({
    domainName, values: convertedValue, url, method,
    successCallback: (data: SaveRecordProps) => {
      callback?.({ data, continueOperate, status: 'success' });
    }, failCallback: () => {
      callback?.({ continueOperate, status: 'fail' });
    }
  });
}

export interface SaveDomainCallbackProps {
  data?: SaveRecordProps;
  continueOperate?: ContinueOperate;
  status: 'success' | 'fail';
}

export type SaveDomainCallback = (props: SaveDomainCallbackProps) => void;

export interface BatchSaveDomainWithCallbackProps {
  type: 'CREATE' | 'UPDATE';
  values: SaveRecordProps[];
  domainName: string;
  callback: SaveDomainCallback;
  continueOperate?: ContinueOperate;
  columns: TableMetaProps[];
  options?: Record<string, unknown>;
}

export async function callBatchSaveDomainWithCallback(props: BatchSaveDomainWithCallbackProps): Promise<RecordProps[]> {
  const {
    type, values, domainName, callback,
    continueOperate, columns, options
  } = props;
  const params = new URLSearchParams();
  if (options) {
    params.append('options', JSON.stringify(options));
  }
  const url = `${SERVER_URL}/${domainName}/batch?${params.toString()}`;
  const method = type === 'CREATE' ? 'POST' : 'PUT';
  const convertedValues = values.map((value: SaveRecordProps) => {
    const convertedValue: SaveRecordProps = {};
    columns?.forEach((column: TableMetaProps): void => {
      // 不能直接通过 save 对象的接口传递到后端的所有列类型列表
      // 这些字段是通过别的方式更新，不能直接在接口中传递 null 值到后端
      const shouldNotPassValueTypes: ColumnType[] = ['array', 'comments'];
      if (shouldNotPassValueTypes.includes(column.type)) {
        // Remove value of this column from values
        // 或者将这部分逻辑放到 callSaveDomainWithCallback 中
        return;
      }
      convertedValue[column.key] = convertValueForSave(column, value);
    });
    return convertedValue;
  });
  return saveDomain({
    domainName, values: convertedValues, url, method,
    successCallback: (data: SaveRecordProps) => {
      callback?.({ data, continueOperate, status: 'success' });
    }, failCallback: () => {
      callback?.({ continueOperate, status: 'fail' });
    }
  });
}

export const convertValueForSave = (column: TableMetaProps, values: SaveRecordProps): RecordValueType => {
  const destType = getDestTypeForComponentLookup(column.type);
  const frontendValueConverter = FieldConfigures.find(fc => fc.type === destType)?.frontendValueConverter;
  return frontendValueConverter ? frontendValueConverter(values, values[column.key]) : values[column.key];
};