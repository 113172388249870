import { ExtendDataNode, RecordProps } from "@props/RecordProps";
import { DataNode } from "antd/lib/tree";

/**
 * Build a flat view contains all nodes for a tree
 * @param rootNodes: root nodes of the tree
 * @param current: current result (for recur call)
 */
export const buildAllNodesFlat = (rootNodes: Array<ExtendDataNode>, current: Array<ExtendDataNode>): void => {
  rootNodes.forEach(node => {
    if (node != null) {
      current.push(node);
      if (node.children != null && node.children?.length > 0) {
        buildAllNodesFlat(node.children as Array<ExtendDataNode>, current);
      }
    }
  });
};

/**
 * Remove a node from a multiple level tree
 * @param treeData: root nodes of the tree
 * @param nodeToRemove: the node to remove from the tree
 */
export const recursionRemoveNode = (treeData: Array<ExtendDataNode>, nodeToRemove: RecordProps): Array<ExtendDataNode> => {
  const nodeData: Array<ExtendDataNode> = [];
  treeData.forEach(item => {
    if(item.children){
      item.children = recursionRemoveNode(item.children, nodeToRemove);
    }
    if (item.id !== nodeToRemove.id) {
      nodeData.push(item);
    }
  });
  return nodeData;
};


/**
 * Change node title
 * @param treeData: root nodes of the tree
 * @param id: id of the node to change title
 * @param newTitle: new title of the node
 * @param newIcon: new icon of the node
*/
export const recursionChangeNodeInfo = (treeData: Array<ExtendDataNode>,
                                        id: string | number,
                                        newTitle: string,
                                        newIcon?: string): Array<ExtendDataNode> => {
  const nodeData: Array<ExtendDataNode> = [];
  treeData.forEach(item => {
    if (item.id === id) {
      if (item.title !== newTitle) {
        item.title = newTitle;
      }
      if (item.icon !== newIcon) {
        item.icon = newIcon;
      }
    }
    nodeData.push(item);
    if(item.children){
      item.children = recursionChangeNodeInfo(item.children, id, newTitle);
    }
  });
  return nodeData;
};

export const recursionAddNode = (treeData: Array<ExtendDataNode>, parent: ExtendDataNode | undefined, nodeToAdd: ExtendDataNode): Array<ExtendDataNode> => {
  if (!parent) {
    // 根据 displaySequence 找到待插入的 index, 如果所有现有节点 displaySequence 均小于插入节点的，则插入到最后
    const idxToInsert = treeData.findIndex((c: ExtendDataNode) => (c.displaySequence > nodeToAdd.displaySequence));
    if (idxToInsert === -1) {
      return [...treeData, nodeToAdd];
    } else {
      const res = [...treeData];
      res.splice(idxToInsert, 0, nodeToAdd);
      return res;
    }
  }
  const nodeData: Array<ExtendDataNode> = [];
  treeData.forEach((item: ExtendDataNode) => {
    if (item.key === parent.key) {
      if (item.children && item.children.length > 0) {
        // 如果节点有 displaySequence 属性标识要考虑其显示先后顺序
        if ('displaySequence' in nodeToAdd) {
          // 根据 displaySequence 找到待插入的 index, 如果所有现有节点 displaySequence 均小于插入节点的，则插入到最后
          const idxToInsert = item.children.findIndex((c: ExtendDataNode) => (c.displaySequence > nodeToAdd.displaySequence));
          if (idxToInsert === -1) {
            item.children.push(nodeToAdd);
          } else {
            item.children.splice(idxToInsert, 0, nodeToAdd);
          }
        } else {
          // 如果没有 displaySequence 属性直接加到最后
          item.children.push(nodeToAdd);
        }
      } else {
        // 如果之前父节点没有子节点，则新创建一个数组存放新的子节点
        item.children = [nodeToAdd];
      }
    } else if(item.children){
      // 如果不是目标父节点，则在其 children 上递归调用，寻找待插入的父节点并处理
      item.children = recursionAddNode(item.children, parent, nodeToAdd);
    }
    nodeData.push(item);
  });
  return nodeData;
};

/**
 * Get a subtree from a tree strucutre
 * @param rootNodes root nodes of the tree
 * @param keyToFind key of the subtree root
 * @return the root of subtree with corresponding key or undefined if not found
 */
export const getSubTreeRootNodeWithKey = (rootNodes: Array<ExtendDataNode>, keyToFind: number):
  ExtendDataNode | undefined => {
  const flatNodes: Array<ExtendDataNode> = [];
  buildAllNodesFlat(rootNodes, flatNodes);
  return flatNodes?.find(node => {
    if (node.key === keyToFind) {
      return node;
    }
  });
};

/**
 * Get key of self and all children nodes of a list of keys
 * @param treeData The tree to get data
 * @param parents list of all node keys, which to get self and children nodes
 */
export const getSelfAndAllChildrenKeys = (treeData: Array<ExtendDataNode>,
  parents: Array<number>): Array<number> => {
  if (parents.length === 0) {
    return [];
  }
  const current: Array<ExtendDataNode> = [];
  parents.forEach(p => {
    const node = getSubTreeRootNodeWithKey(treeData, p);
    if (node != null) {
      buildAllNodesFlat([node], current);
    }
  });
  return (current.length > 0) ?
    current.map(c => Number.parseInt(c.key.toString())) : [] as Array<number>;
};

/**
 * Update list of extend data node and add children to a specific children, then return the new list
 * @param list list to update
 * @param key key to add children
 * @param children children to add to key
 */
export const mergeChildrenIntoTree = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] => {
  return list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: mergeChildrenIntoTree(node.children, key, children),
      };
    }
    return node;
  });
};
