import { notification, message as antdMessage } from 'antd';
import { wrapAsHtml } from "@utils/ComponentUtils";
import React, { CSSProperties, ReactNode } from 'react';
import i18n from "@config/i18n";
import {
  IssuesCloseOutlined, InfoCircleOutlined, AlertOutlined
} from "@ant-design/icons";
import { PrimaryColor } from '@config/base';
import { CloseIcon } from '../components';
import { emptyMethod } from './Constants';

const NotificationDefaultStyle = { bottom: "24px" } as CSSProperties;

export function openErrorNotification(
  description: string | undefined | null | ReactNode,
  message?: string | undefined | null | ReactNode,
): void {
  notification.error({
    message: message ?? i18n.t("Error message"),
    duration: 0, //Never auto close error notification
    description: (typeof description === 'string') ? wrapAsHtml(description) : description,
    style: NotificationDefaultStyle,
    closeIcon: (<CloseIcon onClick={emptyMethod} />),
    placement: "bottomRight"
  });
}

export function openInfoNotification(
  message: string | undefined | null | ReactNode,
): void {
  openSuccessMessage(message);
}

export function openImportantNotification(
  message: string | undefined | null | ReactNode,
  description: string | undefined | null | ReactNode,
): void {
  notification.info({
    message: (typeof message === 'string') ? wrapAsHtml(message) : message,
    description: (typeof description === 'string') ? wrapAsHtml(description) : description,
    style: NotificationDefaultStyle,
    duration: 0, //Never auto close important notification
    icon: (<AlertOutlined style={{ color: "red" }} />),
    closeIcon: (<CloseIcon onClick={emptyMethod} />),
    placement: "bottomRight"
  });
}

export function openInfoNotificationWithDuration(
  message: string | undefined | null | ReactNode,
  description: string | undefined | null | ReactNode,
  duration: number,
): void {
  notification.info({
    message: (typeof message === 'string') ? wrapAsHtml(message) : message,
    description: (typeof description === 'string') ? wrapAsHtml(description) : description,
    style: NotificationDefaultStyle,
    duration,
    icon: (<InfoCircleOutlined style={{ color: "green" }} />),
    closeIcon: (<CloseIcon onClick={emptyMethod} />),
    placement: "bottomRight"
  });
}

export function openWarningNotification(
  message: string | undefined | null | ReactNode,
): void {
  openWarningNotificationWithDescription(message, undefined);
}

export function openWarningNotificationWithDescription(
  message: string | undefined | null | ReactNode,
  description: string | undefined | null | ReactNode,
): void {
  notification.warning({
    message: (typeof message === 'string') ? wrapAsHtml(message) : message,
    description: (typeof description === 'string') ? wrapAsHtml(description) : description,
    style: NotificationDefaultStyle,
    duration: 0, //Never auto close warning notification
    icon: (<IssuesCloseOutlined style={{ color: PrimaryColor }} />),
    closeIcon: (<CloseIcon onClick={emptyMethod} />),
    placement: "bottomRight"
  });
}

export function openSuccessMessage(message: string | ReactNode): void {
  antdMessage.success(message).then(() => {
    // Auto close message after 1 seconds
    setTimeout(() => {
      antdMessage.destroy();
    }, 1000);
  });
}
