import React, { ReactElement } from "react";

import SearchConditionDisplayPanel from "./SearchConditionDisplayPanel";
import FinderFormDisplayPanel from "./FinderFormDisplayPanel";
import { SearchPanelProps, TableMode } from "@props/RecordProps";

const SearchPanel = (props: SearchPanelProps): ReactElement => {
  const { tableMode } = props;
  const searchConditionDisplayPanel = (<SearchConditionDisplayPanel {...props} />);
  const finderSearchForm = (<FinderFormDisplayPanel {...props} />);

  const tableModeToSearchPanelMapping = {
    "detail"        : searchConditionDisplayPanel,
    "detail-drawer" : searchConditionDisplayPanel,
    "table-list"    : searchConditionDisplayPanel,
    "card-list"     : searchConditionDisplayPanel,
    "finder"        : finderSearchForm,
  } as {
    [mode in TableMode]: ReactElement;
  };

  return tableModeToSearchPanelMapping[tableMode];
};

export default SearchPanel;
