import { Statistic } from "antd";
import React, { ReactElement } from "react";
import { WidgetComponentRenderProps } from "../WidgetComponentsMapping";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { PrimaryColor } from '@config/base';

const StatisticWidget = (props: WidgetComponentRenderProps): ReactElement => {
  const { dataAndConfigs, widget } = props;
  const config = JSON.parse(widget.options)?.config ?? {};
  const mergedConfigs = { ...config, ...dataAndConfigs };

  const directionPrefixMapping: { [propName: string]: ReactElement } = {
    "up": <ArrowUpOutlined />,
    "down": <ArrowDownOutlined />
  };

  const directionColorMapping: { [propName: string]: string } = {
    "up": "#3f8600",
    "down": "#cf1322"
  };

  const prefix = (mergedConfigs?.direction != null) ?
    directionPrefixMapping[mergedConfigs?.direction] : undefined;
  const color = (mergedConfigs?.direction != null) ?
    directionColorMapping[mergedConfigs?.direction] : PrimaryColor;

  return (
    <Statistic
      valueStyle={{ color }}
      prefix={prefix}
      precision={2}
      {...mergedConfigs}
    />
  );
};

export default StatisticWidget;
