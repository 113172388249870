import React, { ReactElement, useEffect, useState } from "react";
import { Tag } from "antd";
import { capitalizeFirstLetter } from "@utils/StringUtils";
import { fetchCurrentValues } from "@utils/FetchUtils";

const RolesCell = (props: { value: string }): ReactElement => {
  const { value } = props;
  const [displayVals, setDisplayVals] = useState<Array<string>>([]);
  useEffect(() => {
    if (value != null && Array.isArray(value)) {
      if (value.length > 0) {
        fetchCurrentValues('roles', value).then(roles => {
          setDisplayVals(roles.map(role => role.authority));
        });
      }
    } else {
      setDisplayVals(value?.split(","));
    }
  }, [value]);

  const tags = (value == null || value.length === 0) ? (<span />) :
    displayVals?.map((t: string) => {
      const val = capitalizeFirstLetter(t.replace("ROLE_", "").toLowerCase());
      return (
        <Tag className="role-tag" key={t}>{val}</Tag>);
    });
  return (<span>{tags}</span>);
};

export default RolesCell;
