import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RevisionCompareProps, RevisionCompareResult } from '@props/RecordProps';
import { wrapAsHtml } from '@utils/ComponentUtils';
import { fetchRevisionCompareResult } from '@utils/FetchUtils';
import { openErrorNotification } from '@utils/NotificationUtils';
import { LargeSpin } from "../../components/icons";
import { Collapse } from 'antd';
const { Panel } = Collapse;
import "./compare.less";

const RevisionCompare = (props: RevisionCompareProps): ReactElement => {
  const { columnName, domainName, sourceId, targetId } = props;
  console.log(columnName, domainName, sourceId, targetId);
  const [result, setResult] = useState<RevisionCompareResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const replaceSpecialChar = (str: string): string => {
    return str.replaceAll('&para;', '');
  };

  const compareWithLatest = (sourceId == null);
  const h1 = compareWithLatest? t('Latest changes') : t('Target changes based on source');
  const h2 = compareWithLatest? t('Revert changes'): t('Source changes based on target');
  const h3 = compareWithLatest? t('History revision'): t('Source column value');
  const h4 = compareWithLatest? t('Current revision') : t('Target column value');

  useEffect(() => {
    setLoading(true);
    fetchRevisionCompareResult(props).then((json: RevisionCompareResult) => {
      setResult(json);
    }).catch((e) => {
      console.error("Failed to get revision compare result", props, e);
      openErrorNotification(t("Failed to get revision compare result", { error: e }));
    }).finally(() => setLoading(false));
  }, [t, props]);
  return loading ? (<LargeSpin />) : (
    <div style={{ paddingBottom: "24px" }}>
      <Collapse
        defaultActiveKey={['target-to-source']}
      >
      <Panel header={h1} key="target-to-source">
          <pre className="diff-panel">
            {wrapAsHtml(replaceSpecialChar(result?.diffSourceToTargetHtml ?? ""))}
          </pre>
        </Panel>
        <Panel header={h2} key="source-to-target">
          <pre className="diff-panel">
            {wrapAsHtml(replaceSpecialChar(result?.diffTargetToSourceHtml ?? ""))}
          </pre>
        </Panel>
        <Panel header={h3} key="source">
          <pre className="diff-panel">
            {wrapAsHtml(result?.source)}
          </pre>
        </Panel>
        <Panel header={h4} key="target">
          <pre className="diff-panel">
            {wrapAsHtml(result?.target)}
          </pre>
        </Panel>
      </Collapse>
    </div>
  );
};

export default RevisionCompare;
