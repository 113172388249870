import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import {
  CellComponentDisplayPage,
  FieldExtInfo,
  RecordProps,
  SortedRecordProps,
  TableMetaProps
} from "@props/RecordProps";
import update from 'immutability-helper';
import {
  fetchDomainMeta,
  fetchFormIdAndExtInfoByName,
  fetchListOfRelateDomainData,
  saveDomain
} from "@utils/FetchUtils";
import GroupedGrandChildGroupDetailItem from './GroupedGrandChildGroupDetailItem';
import { supportDnd } from '@utils/ComponentUtils';
import { SERVER_URL } from "@config/base";
import { updateDisplaySequence } from "./updateDisplaySequence";
import { emptyMethod } from "@utils/Constants";
import { openInfoNotification } from '@utils/NotificationUtils';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, typeWithPackageToSimpleType } from '@utils/StringUtils';
import { Button, Empty, Spin } from 'antd';
import { CreateAction } from '../../form';

export interface GroupedGrandChildDetailProps {
  domainName: string;
  index: number;
  sortedData: SortedRecordProps[][];
  setSortedData: Dispatch<SetStateAction<SortedRecordProps[][]>>;
  record: RecordProps;
  displayColumn: TableMetaProps;
  page: CellComponentDisplayPage;
  zIndex: number;
  updatedGroups: Array<number>;
  setUpdatedGroups: Dispatch<SetStateAction<Array<number>>>;
}

export interface DragItem {
  index: number
  id: number
  type: string
}

// 第二层分组
const GroupedGrandChildDetailGroup = (props: GroupedGrandChildDetailProps): ReactElement => {
  const {
    domainName, index, sortedData, setSortedData, record, displayColumn, zIndex, page, updatedGroups, setUpdatedGroups
  } = props;
  const { t } = useTranslation();
  const data = sortedData[index];
  const { displayForm } = displayColumn.extInfo ?? {} as FieldExtInfo;
  const { key, elementType, type } = displayColumn;
  const [loading, setLoading] = useState<boolean>(true);
  const [formId, setFormId] = useState<number>();
  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
  const [summaryGroupTitleColumns, setSummaryGroupTitleColumnsColumns] = useState<Array<TableMetaProps>>([]);
  const [summaryGroupDescriptionColumns, setSummaryGroupDescriptionColumns] = useState<Array<TableMetaProps>>([]);
  const [summaryGroupExtraColumns, setSummaryGroupExtraColumns] = useState<Array<TableMetaProps>>([]);
  const [canDnd, setCanDnd] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [showAddChildModal, setShowAddChildModal] = useState<boolean>(false);

  useEffect(() => {
    if (displayForm != null) {
      setLoading(true);
      const domainName = elementType ?? type;
      fetchFormIdAndExtInfoByName(domainName, displayForm).then(json => {
        setFormId(json.id);
        fetchDomainMeta(domainName, json.id).then((meta: Array<TableMetaProps>) => {
          setColumns(meta);
          setSummaryGroupTitleColumnsColumns(meta.filter(c => c.groupName?.startsWith('summaryGroupTitle')));
          setSummaryGroupDescriptionColumns(meta.filter(c => c.groupName?.startsWith('summaryGroupDescription')));
          setSummaryGroupExtraColumns(meta.filter(c => c.groupName?.startsWith('summaryGroupExtra')));
          return meta;
        })
          .finally(() => setLoading(false))
          .catch(e => console.error(`Failed to get form ${formId} for domain ${domainName}: ${e}`));
      });
    }
  }, [displayForm, elementType, formId, type]);

  const reload = useCallback(() => {
    if (fetching) {
      return;
    }
    setFetching(true);
    setLoading(true);
    if (updatedGroups.find(i => i === index)) {
      setUpdatedGroups(prevState => prevState.filter(i => i !== index));
    }
    fetchListOfRelateDomainData({
      domainName,
      columnName: key,
      ownerId: record.id,
      current: 1,
      max: 9999,
      useCache: false
    }).then(json => {
      if (data.length === 0 && json.data.length === 0) {
        return;
      }
      const canDnd = supportDnd(columns);
      setCanDnd(canDnd);
      const sortedData = canDnd ?
        json.data.sort((a, b) => a.displaySequence - b.displaySequence) : json.data;
      setSortedData((prevState) =>
        update(prevState, {
          [index]: { $set: sortedData as SortedRecordProps[] },
        }));
    }).finally(() => {
      setFetching(false);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, data.length, domainName, index, key, record.id]);

  useEffect(() => {
    if ((data.length === 0 && columns.length > 0) || updatedGroups.find(i => i === index)) {
      reload();
    }
  }, [columns, data?.length, domainName, key, record.id, reload, updatedGroups, index]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSortedData((prevData: SortedRecordProps[][]) =>
      update(prevData, {
        [index]: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevData[index][dragIndex] as SortedRecordProps],
          ],
        },
      })
    );
  }, [index, setSortedData]);

  const onDrop = (item: DragItem): void => {
    const [newData, changedElements] = updateDisplaySequence(data, item.index);
    if (changedElements.length == 0) {
      return;
    }
    setSortedData((prevState) =>
      update(prevState, {
        [index]: { $set: newData as SortedRecordProps[] },
      }));
    let failed = false;
    if (displayColumn.elementType == null) {
      console.error("Display column ", displayColumn, " elementType is null, indicates component is not supported on this column");
      console.info("GroupedGrandChild component is only supported on one-to-many object colum as of now");
      console.info("Please check related form settings on server side");
    } else {
      Promise.all(changedElements.map((element) => {
        return saveDomain({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          domainName: displayColumn.elementType!,
          successCallback: emptyMethod,
          failCallback: () => {
            failed = true;
          },
          method: "PUT",
          url: `${SERVER_URL}/${displayColumn.elementType}/${element.id}`,
          values: {
            displaySequence: element.displaySequence,
          },
          skipInfoNotification: true,
        });
      })).finally(() => {
        if (failed) {
          reload();
        } else {
          const dn = t(`domainTitle:${capitalizeFirstLetter(typeWithPackageToSimpleType(domainName))}`);
          openInfoNotification(t('Save success', { domainTitle: dn }));
        }
      });
    }
  };

  const childDomainName = elementType ?? type;

  const createButton = (<Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    imageStyle={{
      height: 60,
    }}
  >
    <Button
      type="default"
      onClick={() => setShowAddChildModal(true)}
    >
      <PlusCircleOutlined /> {t('Create')}
    </Button>
    {showAddChildModal &&
      <CreateAction
        domainName={childDomainName}
        ownerId={record.id}
        ownerClass={domainName}
        columnNameInOwnerClass={displayColumn.dataIndex}
        callback={() => {
          reload();
          setShowAddChildModal(false);
        }}
        initialCanCreate={true}
        initShowCreateModal={true}
        zIndex={zIndex + 2}
        showIconAndText={false}
        onCancelCallback={() => {
          setShowAddChildModal(false);
        }}
      />
    }
  </Empty>);

  return loading ? (<Spin />) : (
    <div>
      {(data.length == 0) && createButton}
      {(data.length > 0) && data.map((item, i) => {
        return (<GroupedGrandChildGroupDetailItem
          key={item.id}
          data={data}
          record={item}
          columns={columns}
          reload={reload}
          parentDomainId={record.id}
          parentDomainName={domainName}
          domainName={childDomainName}
          page={page}
          zIndex={zIndex}
          canDnd={canDnd}
          //index={canDnd ? item.displaySequence : index}
          index={i}
          summaryGroupTitleColumns={summaryGroupTitleColumns}
          summaryGroupDescriptionColumns={summaryGroupDescriptionColumns}
          summaryGroupExtraColumns={summaryGroupExtraColumns}
          moveCard={moveCard}
          onDrop={onDrop}
        />);
      })}
    </div>
  );
};

export default GroupedGrandChildDetailGroup;
