import {
  GetRequestBundleWaitingSeconds, GetRequestCacheExpirySeconds, PendingRequestsCacheKey
} from '@config/base';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const cachedFetch = async (url: string, options: any): Promise<any> => {
  let expiry = GetRequestCacheExpirySeconds;
  const cacheKey = url;
  if (typeof options === 'number') {
    expiry = options;
    options = undefined;
  } else if (typeof options === 'object') {
    expiry = options.seconds || expiry;
  }

  const resp = getCachedResponse(cacheKey, expiry);
  if (resp != null) {
    return await resp;
  }
  const pendingRequests: Set<string> = getPendingRequests();
  if (pendingRequests.has(url)) {
    //If currently there is a same request on going, just wait for a while
    await new Promise(resolve => setTimeout(resolve, GetRequestBundleWaitingSeconds * 1000));
    const resp = getCachedResponse(cacheKey, expiry);
    if (resp != null) {
      return await resp;
    }
  } else {
    updatePendingRequestList(url, "add");
  }

  return fetch(url, options).then(response => {
    // let's only store in cache if the content-type is JSON or something
    // non-binary
    if (response.status === 200) {
      const ct = response.headers.get('Content-Type');
      if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
        // There is a .json() instead of .text() but we're going to store it in
        // sessionStorage as string anyway. If we don't clone the response, it
        // will be consumed by the time it's returned. This way we're being
        // un-intrusive.
        response.clone().text().then(content => {
          localStorage.setItem(cacheKey, content);
          localStorage.setItem(getTsCacheKey(cacheKey), `${Date.now()}`);
          updatePendingRequestList(url, "delete");
          // Auto delete the cache after GetRequestCacheExpirySeconds + 1 seconds
          setTimeout(() => {
            localStorage.removeItem(cacheKey);
            localStorage.removeItem(getTsCacheKey(cacheKey));
          }, (GetRequestCacheExpirySeconds + 3) * 1000);
        });
      }
    }
    return response;
  });
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const getCachedResponse = (cacheKey: string, expiry: number): Promise<any> | undefined => {
  const cached = localStorage.getItem(cacheKey);
  const tsCacheKey = getTsCacheKey(cacheKey);
  const whenCached = parseInt(localStorage.getItem(tsCacheKey) ?? "0");
  if (cached !== null && whenCached !== null) {
    // it was in sessionStorage! Yay!
    // Even though 'whenCached' is a string, this operation
    // works because the minus sign tries to convert the
    // string to an integer and it will work.
    const age = (Date.now() - whenCached) / 1000;
    if (age < expiry) {
      const response = new Response(new Blob([cached]));
      return Promise.resolve(response);
    } else {
      // We need to clean up this old key
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(tsCacheKey);
    }
  }
};

const updatePendingRequestList = (url: string, operation: "delete" | "add"): void => {
  const newPendingRequests = getPendingRequests();
  (operation === "delete") ? newPendingRequests.delete(url) : newPendingRequests.add(url);
  localStorage.setItem(PendingRequestsCacheKey, JSON.stringify(Array.from(newPendingRequests)));
};

const getTsCacheKey = (cacheKey: string): string => {
  return cacheKey + ":ts";
};

const getPendingRequests = (): Set<string> => {
  const pendingRequests: Set<string> = new Set(
    JSON.parse(localStorage.getItem(PendingRequestsCacheKey) ?? '[]'));
  return pendingRequests;
};
