import { useState, useEffect } from 'react';

const useClickToggleVisible = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [visible, setVisible] = useState(false);

  const handleClickOutside = (event: MouseEvent): void => {
    const triggerNode = event.target as HTMLElement;

    if (!triggerNode.closest('.ant-popover')) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return [visible, setVisible];
};

export default useClickToggleVisible;
