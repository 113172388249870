import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ContinueOperate, FormExtInfoProps, SaveRecordProps, TableMetaProps, UpdateProps } from '@props/RecordProps';
import { Alert, Modal, Popover, Space } from "antd";
import UpdateComponent from "./App";
import { fetchDomainMeta, fetchFormExtInfo, fetchFormIdAndExtInfoByType } from "@utils/FetchUtils";
import { modalPropsBuilder, SaveLoadingButtonType } from '../';
import { LargeSpin } from "../../components";
import { hasDetailPanel } from '@utils/ColumnsUtils';
import { useDomainPermit } from '@utils/DomainPermitUtils';
import { useHotkeys } from 'react-hotkeys-hook';
import { ModalLockContext } from '@utils/context';

export const UpdateAction: React.FC<UpdateProps> = (props: UpdateProps) => {
  const {
    domainName, id, ownerClass, ownerId, showIconAndText, columnNameInOwnerClass,
    callback, errorMsg, formId, initShowEditModal, zIndex, onCancelCallback,
    queryParameters, refererUrl, readonly, switchEditable, fetchType,
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(
    initShowEditModal != null ? initShowEditModal : false
  );
  const [updateFormId, setUpdateFormId] = useState<number>();
  // 触发保存操作是通过每次将 triggerSave 这个 state 变量设置为不同的随机值
  // 进而触发 UpdateComponent 组件的刷新, 然后在 UpdateComponent 组件中，
  // 会判断 triggerSave 的值如果不是 false, 则表示需要触发保存操作
  const [triggerSave, setTriggerSave] = useState<false | number>(false);
  const [continueOperate, setContinueOperate] = useState<ContinueOperate>(false);
  const [columns, setColumns] = useState<Array<TableMetaProps>>();
  const [hasAnyDetailPanel, setHasAnyDetailPanel] = useState<boolean>(false);
  const [needToRefreshListPage, setNeedToRefreshListPage] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [saveLoadingButton, setSaveLoadingButton] = useState<SaveLoadingButtonType>(undefined);
  const location = useLocation();
  const currentUrl = refererUrl ?? location.pathname;
  const domainPermit = useDomainPermit(domainName, id);
  const [readonlyState, setReadonlyState] = useState<boolean>(readonly ?? false);
  const { acquireLock, releaseLock, hasLock } = useContext(ModalLockContext);
  const [lockId, setLockId] = useState(0);
  const [cancelConfirmMessage, setCancelConfirmMessage] = useState<string | undefined>();
  const [extInfo, setExtInfo] = useState<FormExtInfoProps>();

  const switchEditableWrap = (editable: boolean): void => {
    setReadonlyState(editable);
    switchEditable?.(editable);
  };

  useEffect(() => {
    console.log("Current lockId: ", lockId);
  }, [lockId]);

  useEffect(() => {
    if (showModal && lockId === 0) {
      setLockId(acquireLock());
    } else if (!showModal && lockId !== 0) {
      releaseLock(lockId);
      setLockId(0);
    }
  }, [showModal, lockId, acquireLock, releaseLock]);

  useEffect(() => {
    const setAllInfo = (fId: number): void => {
      setUpdateFormId(fId);
      fetchDomainMeta(domainName, fId).then((meta: Array<TableMetaProps>) => {
        setColumns(meta);
        setHasAnyDetailPanel(meta?.filter(c => hasDetailPanel(c)).length > 0);
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        setLoading(false);
      });
    };

    if (formId != null) {
      setAllInfo(formId);
      fetchFormExtInfo(formId).then(json => {
        setExtInfo(json.extInfo);
      }).catch(e => {
        console.error(`Failed to get form(${formId}) meta of domain ${domainName} form: ${e}`);
      });
    } else {
      fetchFormIdAndExtInfoByType(domainName, "Update")
        .then(json => {
          setAllInfo(json?.id);
          setExtInfo(json.extInfo);
        })
        .catch(e => {
          console.error(`Failed to get Update form id of ${domainName}: ${e}`);
        });
    }
  }, [domainName, formId, loading]);

  const onSaveAndClose = (): void => {
    setCancelConfirmMessage(undefined);
    setNeedToRefreshListPage(true);
    setContinueOperate(false);
    setTriggerSave(Math.random());
    setSaveLoadingButton("saveClose");
    if (hasLock(lockId)) {
      releaseLock(lockId);
    }
  };

  const onSaveAndContinue = (): void => {
    setCancelConfirmMessage(undefined);
    setNeedToRefreshListPage(true);
    setContinueOperate("edit");
    setTriggerSave(Math.random());
    setSaveLoadingButton("saveContinueEdit");
  };

  const useHotKeysConfig = {
    enableOnFormTags: true
  };
  useHotkeys('shift+ctrl+s, shift+cmd+s', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut shift+ctrl+s ignored due to global lock.');
    }
    onSaveAndContinue();
  }, useHotKeysConfig);
  useHotkeys('ctrl+enter, cmd+enter', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut ctrl+enter ignored due to global lock.');
    }
    onSaveAndClose();
  }, useHotKeysConfig);
  useHotkeys('shift+ctrl+e, shift+cmd+e', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut shift+ctrl+e ignored due to global lock.');
    }
    switchEditableWrap(!readonlyState);
  }, useHotKeysConfig);

  if (errorMsg != null) {
    return (
      <Popover
        trigger="click"
        title={<Alert message="Error" type="error" showIcon />}
        content={<pre>{errorMsg}</pre>}
      >
        <span><FormOutlined style={{ color: "red" }} /> Edit</span>
      </Popover>
    );
  } else {
    const modalParams = modalPropsBuilder({
      hasRelateObjectField: hasAnyDetailPanel,
      mode: "edit", t,
      open: showModal,
      isValid,
      domainName,
      onSaveAndClose,
      onSaveAndContinue,
      onDelete: () => {
        setCancelConfirmMessage(undefined);
        setShowModal(false);
        callback?.();
        if (hasLock(lockId)) {
          releaseLock(lockId);
        }
      },
      onCancel: () => {
        setCancelConfirmMessage(undefined);
        setShowModal(false);
        onCancelCallback?.();
        if (needToRefreshListPage) {
          callback?.();
        }
        if (hasLock(lockId)) {
          releaseLock(lockId);
        }
      },
      zIndex: zIndex + 1,
      ownerClass, ownerId, id, saveLoadingButton,
      columnNameInOwnerClass,
      refererUrl: currentUrl,
      readonly: readonlyState,
      updatable: domainPermit?.canUpdate,
      switchEditable: switchEditableWrap,
      cancelConfirmMessage,
      modalTitle: extInfo?.domainTitle,
    });
    const componentParams: UpdateProps = {
      formId: updateFormId,
      domainName,
      id,
      callback: (props: {
        continueOperate: ContinueOperate;
        data?: SaveRecordProps;
        status?: "fail" | "success";
      }) => {
        const { continueOperate, status } = props;
        const saveSuccess = (status !== 'fail');
        saveSuccess && setTriggerSave(false);
        setContinueOperate(continueOperate);
        //Only refresh after return to list panel to avoid duplicate API call
        //(continueOperate === false)
        if (continueOperate === false) {
          saveSuccess && callback?.();
          onCancelCallback?.();
        }
        setSaveLoadingButton(undefined);
      },
      columnNameInOwnerClass,
      ownerClass,
      ownerId,
      triggerSave,
      continueOperate,
      columns,
      validationCallback: (v: boolean) => {
        setIsValid(v);

        //如果 valid 是 true, 则重置 button 的 loading 状态的逻辑由 saveCallback 处理
        //此处只处理 valid 是 false 的状态
        if (!v) {
          setSaveLoadingButton(undefined);
        }

        setTimeout(() => {
          setIsValid(true);
        }, 2000);
      },
      zIndex: zIndex + 1,
      queryParameters,
      readonly: readonlyState,
      refererUrl: currentUrl,
      switchEditable: switchEditableWrap,
      fetchType,
      setCancelConfirmMessage,
    };
    const title = readonly ? t("Show details") : t("Edit current object");
    const icon = readonly ? <EyeOutlined /> : <FormOutlined />;

    const iconAndText = (<a
      title={title}
      href="/#"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setShowModal(true);
      }}>
      <Space size={8}>
        {icon}
        {title}
      </Space>
    </a>);
    const modal = (
      <Modal {...modalParams}>
        {loading &&
          <div style={{
            textAlign: "center",
            margin: "auto",
            width: "100%",
            padding: "1rem"
          }}>
            <LargeSpin />
          </div>
        }
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {!loading && (<UpdateComponent {...componentParams} />)}
      </Modal>
    );
    return (<> {showIconAndText ? iconAndText : (<></>)} {modal} </>);
  }
};

export default UpdateAction;
