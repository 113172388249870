import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  ContinueOperate, CreateProps, FormExtInfoProps, SaveOperation, SaveRecordProps, TableMetaProps,
  UpdateProps
} from '@props/RecordProps';
import { Modal, Space, Spin } from "antd";
import CreateComponent from "../create/App";
import { fullDomainNameToHumanReadable } from "@utils/StringUtils";
import {
  fetchCanCreate, fetchFormIdAndExtInfoByType, fetchDomainMeta,
  fetchFormExtInfo
} from "@utils/FetchUtils";
import { UpdateComponent, modalPropsBuilder, SaveLoadingButtonType } from "../";
import { emptyMethod } from '@utils/Constants';
import { shouldDisplayDetailPanel } from '@utils/ColumnsUtils';
import { useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { ModalLockContext } from '@utils/context';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { ModalPropsBuilderProps } from "../ModalPropsBuilder";

export type CallbackProps = {
  continueOperate: ContinueOperate;
  data?: SaveRecordProps;
  status: 'success' | 'fail';
};

export const CreateAction: React.FC<CreateProps> = (props: CreateProps) => {
  const {
    formId, domainName, ownerClass, ownerId,
    callback, columnNameInOwnerClass, initialCanCreate,
    initShowCreateModal, zIndex, showIconAndText, onCancelCallback,
    queryParameters, refererUrl, formName,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(
    initShowCreateModal == null ? false : initShowCreateModal
  );
  const [saveLoadingButton, setSaveLoadingButton] = useState<SaveLoadingButtonType>(undefined);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [continueOperate, setContinueOperate] = useState<ContinueOperate>(false);
  const refreshListPageRef = React.useRef<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [data, setData] = useState<SaveRecordProps>();
  const [columns, setColumns] = useState<Array<TableMetaProps>>();
  const [hasRelateObjectField, setHasRelateObjectField] = useState<boolean>(false);
  const [updateFormId, setUpdateFormId] = useState<number>();
  const [updateColumns, setUpdateColumns] = useState<Array<TableMetaProps>>();
  const [updateHasRelateObjectField, setUpdateHasRelateObjectField] = useState<boolean>(false);
  const [triggerSave, setTriggerSave] = useState<number | false>(false);
  const [mode, setMode] = useState<SaveOperation>('create');
  const { t } = useTranslation();
  const location = useLocation();
  const currentUrl = refererUrl ?? location.pathname;
  /** 编辑 Modal 的只读状态，在点击创建并编辑弹出的 Modal 中，可以点击编辑按钮，将其状态修改为查看状态 */
  const [detailModalReadonly, setDetailModalReadonly] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { acquireLock, releaseLock, hasLock } = useContext(ModalLockContext);
  const [lockId, setLockId] = useState(0);
  const [cancelConfirmMessage, setCancelConfirmMessage] = useState<string | undefined>();
  const [extInfo, setExtInfo] = useState<FormExtInfoProps>();

  const useHotKeysConfig = {
    enableOnFormTags: true
  };
  useHotkeys('shift+ctrl+s, shift+cmd+s', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut shift+ctrl+s ignored due to global lock.');
      return;
    }
    onSaveAndContinue(mode, mode as ContinueOperate);
  }, useHotKeysConfig);
  useHotkeys('ctrl+enter, cmd+enter', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut ctrl+enter ignored due to global lock.');
      return;
    }
    onSaveAndContinue(mode, false);
  }, useHotKeysConfig);
  useHotkeys('shift+ctrl+e, shift+cmd+e', () => {
    if (!hasLock(lockId)) {
      console.log('Shortcut shift+ctrl+e ignored due to global lock.');
    }
    onSaveAndContinue("create", "edit");
  }, useHotKeysConfig);

  useEffect(() => {
    //Update show modal state when domainName changes
    setShowModal(initShowCreateModal == null ? false : initShowCreateModal);
  }, [domainName, initShowCreateModal]);

  useEffect(() => {
    //Only get lock when modal is shown and current lockId is 0, which means
    //this modal is just shown for the first time. This is to avoid infinite
    //loop of acquiring lock when popup other modal on top of a create modal.
    if (showModal && lockId === 0) {
      const newLockId = acquireLock();
      setLockId(newLockId);
    }
  }, [showModal, acquireLock, hasLock, lockId]);

  useEffect(() => {
    const dataIsNull = (data == null);
    setMode(dataIsNull ? "create" : "edit");
  }, [data]);

  useEffect(() => {
    setColumns([]);
    setUpdateColumns([]);
  }, [domainName]);

  useEffect(() => {
    setLoading(true);
    if (updateFormId == null) {
      fetchFormIdAndExtInfoByType(domainName, 'Update').then(json => {
        fetchDomainMeta(domainName, json.id).then((domainMeta: Array<TableMetaProps>) => {
          setUpdateFormId(json.id);
          setUpdateColumns(domainMeta);
          setUpdateHasRelateObjectField(domainMeta.filter(c => shouldDisplayDetailPanel(mode, c)).length > 0);
        }).catch(e => {
          console.error(`Failed to get form(${json.id}) meta of domain ${domainName} form: ${e}`);
        });
      }).catch(e => {
        console.error(`Failed to get form Id of domain ${domainName} Update form: ${e}`);
      }).finally(() => {
        setLoading(false);
      });
    }
    if (columns == null) {
      if (formId != null) {
        fetchDomainMeta(domainName, formId).then((domainMeta: Array<TableMetaProps>) => {
          setColumns(domainMeta);
          setHasRelateObjectField(domainMeta.filter(c => shouldDisplayDetailPanel(mode, c)).length > 0);
        }).catch(e => {
          console.error(`Failed to get form(${formId}) meta of domain ${domainName} form: ${e}`);
        }).finally(() => {
          setLoading(false);
        });
        fetchFormExtInfo(formId).then(json => {
          setExtInfo(json.extInfo);
        }).catch(e => {
          console.error(`Failed to get form(${formId}) meta of domain ${domainName} form: ${e}`);
        });
      } else {
        fetchFormIdAndExtInfoByType(domainName, 'Create').then(json => {
          setExtInfo(json.extInfo);
          fetchDomainMeta(domainName, json.id).then((domainMeta: Array<TableMetaProps>) => {
            setColumns(domainMeta);
            setHasRelateObjectField(domainMeta.filter(c => shouldDisplayDetailPanel(mode, c)).length > 0);
          }).catch(e => {
            console.error(`Failed to get form(${json.id}) meta of domain ${domainName} form: ${e}`);
          });
        }).catch(e => {
          console.error(`Failed to get form Id of domain ${domainName} Update form: ${e}`);
        }).finally(() => {
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  }, [columns, domainName, formId, mode, updateFormId]);

  useEffect(() => {
    if (initialCanCreate != null) {
      setCanCreate(initialCanCreate);
    } else {
      fetchCanCreate(domainName).then(json => {
        setCanCreate(json.create);
      }).catch(e => {
        console.error(`Failed to get canCreate for ${domainName}: ${JSON.stringify(e)}`);
      });
    }
  }, [domainName, initialCanCreate]);

  const onSaveCallback = (props: CallbackProps, operation: SaveOperation): void => {
    const { continueOperate, data, status } = props;
    if (status === 'success') {
      setShowModal(continueOperate !== false);
      setContinueOperate(continueOperate);
      setData((continueOperate === 'edit' ? data : undefined));
      // 如果用户点击了保存并编辑按钮后，将 mode 设置为 edit,
      // 这样就会显示编辑的 Modal, 隐藏创建的 Modal
      if (continueOperate === 'edit' && mode === 'create') {
        setMode('edit');
      }
      //Only refresh after return to list panel to avoid duplicate API call
      if (continueOperate === false) {
        callback?.(props);
        onCancelCallback?.();
        releaseLock(lockId);
      }
    } else {
      setShowModal(true);
      if (!['wizard', 'edit-related', 'create-related']
        .includes(operation)) {
        setContinueOperate(operation as ContinueOperate);
      }
    }
    setTriggerSave(false);
    setSaveLoadingButton(undefined);
  };

  const onCancel = useCallback(() => {
    setCancelConfirmMessage(undefined);
    setShowModal(false);
    setTriggerSave(false);
    onCancelCallback?.();
    // Only trigger list page refresh when return to list page
    if (refreshListPageRef.current) {
      refreshListPageRef.current = false;
      callback?.({ data, continueOperate: false, status: 'success' });
      setData(undefined);
    }
    if (lockId != null) {
      releaseLock(lockId);
    }
  }, [callback, data, onCancelCallback, lockId, releaseLock]);

  const onSaveAndContinue = (operation: SaveOperation, nextAction: ContinueOperate): void => {
    setCancelConfirmMessage(undefined);
    if (nextAction === false) {
      setSaveLoadingButton("saveClose");
    } else if (operation === 'create' && nextAction === 'edit') {
      //创建时点击保存并编辑的处理
      setSaveLoadingButton("saveEdit");
    } else if (operation === nextAction) {
      setSaveLoadingButton("saveContinueCreate");
    }
    refreshListPageRef.current = true;
    setContinueOperate(nextAction);
    const myTriggerSave = (operation === mode) ? Math.random() : false;
    setTriggerSave(myTriggerSave);
  };

  const switchEditable = (editable: boolean): void => {
    setDetailModalReadonly(!editable);
  };

  const isEditMode = (mode === 'edit');

  const modalParams = {
    isValid, mode, open: showModal, domainName,
    hasRelateObjectField: isEditMode ? updateHasRelateObjectField : hasRelateObjectField,
    // 保存并关闭的回调
    onSaveAndClose: () => onSaveAndContinue(mode, false),
    // 保存并继续当前操作的回调(如在创建时，保存并继续创建，在编辑时保存并继续编辑)
    onSaveAndContinue: () => onSaveAndContinue(mode, mode as ContinueOperate),
    // 创建时，保存并跳转到编辑页面的回调
    onSaveAndContinueEdit: () => onSaveAndContinue("create", "edit"),
    onCancel, id: data?.id, onDelete: emptyMethod, saveLoadingButton,
    zIndex: zIndex + 1, t, refererUrl: currentUrl,
    switchEditable,
    readonly: detailModalReadonly,
    key: `create_${domainName}_${mode}_modal_editable`,
    cancelConfirmMessage,
    modalTitle: extInfo?.domainTitle,
  } as ModalPropsBuilderProps;
  const componentParams: CreateProps | UpdateProps = {
    formId: isEditMode ? updateFormId : formId,
    domainName, showContinueOperate: true,
    ownerClass, ownerId, columnNameInOwnerClass, continueOperate,
    triggerSave, zIndex, queryParameters,
    validationCallback: (v: boolean) => {
      setIsValid(v);

      //如果 valid 是 true, 则重置 button 的 loading 状态的逻辑由 saveCallback 处理
      //此处只处理 valid 是 false 的状态
      if (!v) {
        setSaveLoadingButton(undefined);
      }

      setTimeout(() => {
        setIsValid(true);
      }, 2000);
    },
    callback: (props: CallbackProps) => onSaveCallback(props, mode),
    columns: isEditMode ? updateColumns : columns,
    switchEditable,
    readonly: detailModalReadonly,
    formName,
    setCancelConfirmMessage,
  };

  const linkAndText = (
    <a
      href="/#"
      onClick={(event: React.MouseEvent<unknown>) => {
        if (!loading) {
          stopPropagationAndPreventDefault(event);
          setShowModal(true);
        }
      }}
      title={t("Create new domain", {
        domainTitle: fullDomainNameToHumanReadable(domainName),
      })}
      style={{ cursor: loading ? "not-allowed" : "pointer" }}
    >
      {loading ? (
        <Spin />
      ) : canCreate ? (<Space className="link-icon link-icon-with-label" size={2}>
        <PlusOutlined />
        {t('Create')}
      </Space>) : null}
    </a>
  );

  return (columns == null) ? (<Spin />) : (
    <span className="create-action">
      {showIconAndText ? linkAndText : (<></>)}
      {mode === "create" && (<Modal
        {...modalPropsBuilder(modalParams)}
        destroyOnClose={true}
        style={{ minWidth: "410px", width: "410px" }}
      >
        <CreateComponent
          {...componentParams}
        />
      </Modal>
      )}
      {mode === "edit" && data?.id != null && (
        <Modal {...modalPropsBuilder(modalParams)}>
          <UpdateComponent
            {...componentParams}
            id={data.id}
          />
        </Modal>
      )}
    </span>
  );
};

export default CreateAction;
