import React, { ReactElement } from "react";
import { WarningOutlined } from '@ant-design/icons';
import { Space } from "antd";
import { wrapAsHtml } from "@utils/ComponentUtils";
/**
 * 在界面上显示某个字段的校验错误逻辑的控件
 * @param props 要显示的错误信息
 * @constructor
 */
const ErrorMsg = (props: { errorMsg: string }): ReactElement => {
  const {errorMsg} = props;
  return (<div>
    <Space>
      <span><WarningOutlined /></span>
      <span className="errorMsg">{wrapAsHtml(errorMsg)}</span>
    </Space>
  </div>);
};

export default ErrorMsg;
