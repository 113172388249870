import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { InputNumber } from "antd";
import SeriesInput from "./SeriesInput";

interface RangedSeriesInputProps {
  value: string
  style?: CSSProperties;
  defaultLabels?: string[]
  defaultValues?: number[]
  max: number
  labelSuffix?: string
  valueSuffix?: string
  onChange: (value: string) => void
}

const RangedSeriesInput = (props: RangedSeriesInputProps): ReactElement => {
  const [data, setData] = useState<Map<string, string>>(new Map<string, string>());
  const [maxValidLabel, setMaxValidLabel] = useState<number>(0.1);

  useEffect(() => {
    if (props.value === undefined) {
      setData(new Map(Object.entries({ '0': '0', '0.1': '0' })));
      return;
    }
    setData(new Map(Object.entries(JSON.parse(props.value))));
    const _maxValidLabel = ((new Map(Object.entries(JSON.parse(props.value)))).size - 1) / 10;
    setMaxValidLabel(_maxValidLabel);
  }, [props.value]);

  const onMaxChange = (max: string | number | null | undefined): void => {
    if (max === undefined) {
      return;
    }
    setMaxValidLabel(Number(max));
    for (let i = 0; i <= Number(max); i = (i * 10 + 1) / 10) {
      if (data.has(String(i))) {
        continue;
      }
      data.set(String(i), '0');
    }
    props.onChange(JSON.stringify(Object.fromEntries(getActiveData(data, Number(max)))));
    setData(data);
  };

  const parseFunc = (value: string | undefined): number => {
    const strVal: string = (value == null) ?
          '' : value.replace(props.labelSuffix || '', '');
    return parseFloat(strVal);
  };

  const getActiveData = (data: Map<string, string>, max: number): Map<string, string> => {
    return new Map([...data].filter(item => Number(item[0]) <= max));
  };

  return (
    <div style={props.style}>
      <InputNumber max={props.max}
        min={0.1}
        step={0.1}
        precision={1}
        formatter={value => `${value}${props.labelSuffix || ''}`}
        parser={parseFunc}
        style={{ marginBottom: 30 }}
        onChange={onMaxChange}
        defaultValue={0.1}
        value={maxValidLabel}
      />
      <SeriesInput labels={Array.from(data.keys()).filter(key => Number(key) <= maxValidLabel)}
        values={Array.from(data.values())}
        onChange={(label, value) => {
          data.set(String(label), String(value));
          props.onChange(JSON.stringify(Object.fromEntries(getActiveData(data, maxValidLabel))));
          setData(new Map(data));
        }}
        labelSuffix={props.labelSuffix}
        valueSuffix={props.valueSuffix}
      />
    </div>
  );
};

export default RangedSeriesInput;
