import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef, useState } from "react";
import AceEditor from "react-ace";
import 'ace-builds/src-noconflict/mode-markdown';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-groovy";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-kuroir";
import 'ace-builds/src-noconflict/ext-language_tools';
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import { useTranslation } from "react-i18next";
import { SaveRecordProps } from "@props/RecordProps";
import { Alert, Button, Space, Tag } from "antd";
import { debounce } from "lodash";
import { formatJson } from "@utils/StringUtils";
import { LockOutlined } from "@ant-design/icons";

export type CodeEditorProps = {
  value: string;
  onChange: (value: string) => void;
  name: string;
  width?: string;
  height?: string;
  updatable: boolean;
  mode?: string;
  style?: React.CSSProperties;
  record?: SaveRecordProps | undefined;
  zIndex: number;
};

export interface CodeEditorRef {
  insertText: (text: string) => void;
}

const CodeEditor = forwardRef((props: CodeEditorProps, forwardedRef: React.ForwardedRef<CodeEditorRef>): ReactElement => {
  const {
    mode, value, name, onChange, width, height, updatable, style, zIndex, record
  } = props;

  const isJsonMode = (mode === 'json');
  const isJsonAndValueNotEmpty = (isJsonMode && value !== '' && value != null);
  const defaultValue = isJsonAndValueNotEmpty ? formatJson(value) : value;
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorPosition, setErrorPosition] = useState<number>();
  const minWidth = "440px";
  const compWidth = width ?? minWidth;
  const compHeight = height ?? `${currentValue?.split(/\r\n|\r|\n/).length * 20 + 20}px`;
  const editorRef = useRef<AceEditor>(null);
  const prevIdValue = useRef<number>();
  const prevDefaultValue = useRef<string>();

  const { t } = useTranslation();

  const goToErrorPosition = (): void => {
    if (editorRef.current && errorPosition) {
      const lines = currentValue.slice(0, errorPosition).split("\n");
      editorRef.current.editor.gotoLine(lines.length, lines[lines.length - 1].length, true);
    }
  };

  useEffect(() => {
    const isJsonAndValueNotEmpty = (isJsonMode && value !== '');
    const defaultValue = isJsonAndValueNotEmpty ? formatJson(value) : value;
    if (record?.id !== prevIdValue.current) {
      setCurrentValue(defaultValue);
      prevDefaultValue.current = defaultValue;
    } else {
      if ((defaultValue !== currentValue) && (currentValue === prevDefaultValue.current)) {
        setCurrentValue(defaultValue);
        prevDefaultValue.current = defaultValue;
      }
    }
    prevIdValue.current = record?.id;
  }, [record?.id, value, isJsonMode, currentValue]);

  useImperativeHandle(forwardedRef, () => {
    const insertText = (text: string): void => {
      if (editorRef.current) {
        editorRef.current.editor.insert(text);
      }
    };
    return {
      insertText,
    };
  }, []);

  const validateAndChange = (val: string): void => {
    onChange(val);
    setCurrentValue(val);
    if (isJsonMode && val === '') {
      setErrorMessage('');
      setErrorPosition(undefined);
    }
    if (isJsonMode && val !== '') {
      try {
        JSON.parse(val);
        setErrorMessage('');
      } catch (error) {
        setErrorMessage(new String(error).toString());
        if (error instanceof SyntaxError) {
          const errorPosition = error.message.match(/position ([0-9]+)/i);
          if (errorPosition) {
            const errorLocation = Number(errorPosition[1]);
            console.error(`JSON syntax error at position: ${errorLocation}`);
            setErrorPosition(errorLocation);
          } else {
            console.error(`JSON syntax error. ${error.message}`);
            setErrorPosition(undefined);
          }
        }
      }
    }
  };

  const debouncedValidateAndChange = isJsonMode ?
    debounce(validateAndChange, 100) : validateAndChange;

  // Ctrl + Shift + B to reformat code
  return (
    <span className="code-editor-wrap">
      {errorMessage && (
        <Alert
          message={<Space direction="vertical">
            {errorMessage}
            {errorPosition && <Button onClick={goToErrorPosition} type="link" size="small">
              {t('Go to first error position')}
            </Button>}
          </Space>
          }
          type="error"
          showIcon={true}
          style={{
            position: 'absolute',
            top: "32px",
            right: "64px",
            zIndex: zIndex + 1,
            backgroundColor: "rgb(255 204 199 / 0.3)",
          }}
        />
      )}
      {!updatable && (
        <Tag
          icon={<LockOutlined/>}
          style={{
            zIndex: zIndex + 1,
          }}>
          {t('Read only')}
        </Tag>
      )}
      <AceEditor
        onLoad={editor => {
          // Disable the replaymacro command to relese the Ctrl + Shift + E shortcut
          // We use this shortcut to switch detail modal between edit and display mode
          editor.commands.removeCommand('replaymacro');
        }}
        ref={editorRef}
        mode={mode ?? "javascript"}
        readOnly={!updatable}
        theme="kuroir"
        name={name}
        onChange={debouncedValidateAndChange}
        defaultValue={defaultValue}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={currentValue ?? ""}
        height={compHeight}
        commands={Beautify.commands}
        style={{
          width: compWidth,
          height: compHeight,
          minWidth,
          border: updatable ? "1px solid #DDDDDD" : undefined,
          zIndex,
          opacity: updatable ? 1 : 0.85,
          // Use #000 to make it readable in readonly mode, as there's a opacity applied
          color: updatable ? undefined : "#000",
          ...style
        }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          hScrollBarAlwaysVisible: false,
          vScrollBarAlwaysVisible: false,
          // wrap: true,
          highlightSelectedWord: true,
        }}
      />
    </span>
  );
});

export default CodeEditor;
