import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Alert, Button, FormInstance, Result, Space } from 'antd';
import {
  PlusCircleOutlined, CloseCircleOutlined, RightCircleOutlined, HomeOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import {
  MapOfEnumMetaProps,
  RecordProps,
  SaveRecordProps,
  Store,
  TableMetaProps,
  WizardMetaStepProps
} from "@props/RecordProps";
import { fetchCanCreate, getWizardStepMeta } from '@utils/FetchUtils';
import { LargeSpin } from '../../components';
import { DataCollectForm } from '../../form';
import { wrapAsHtml } from '@utils/ComponentUtils';
import { emptyMethod } from '@utils/Constants';
import { RedirectComponent } from "../../components/redirect";
import { transformRecord } from "@kernel/DisplayComponentsMapping";

export interface WizardStepComponentProps {
  wizardId: number;
  direction: 'vertical' | 'horizontal';
  wizardStep: WizardMetaStepProps;
  form: FormInstance;
  errorMessage?: string;
  warningMessage?: string;
  infoMessage?: string;
  finalSubmitted: boolean;
  redirect?: string
  initialValues?: Store;
  onClose?: () => void;
  onWizardRerun?: () => void;
  zIndex: number;
  options?: Array<MapOfEnumMetaProps>;
  dynamicMetas?: Array<TableMetaProps>;
}

export default function WizardStepComponent(
  props: WizardStepComponentProps
): ReactElement {
  const {
    wizardStep, form, errorMessage, warningMessage, infoMessage,
    finalSubmitted, redirect, initialValues, zIndex, onClose, onWizardRerun,
    options, dynamicMetas, direction
  } = props;
  const { t } = useTranslation();
  const { id: stepId, description } = wizardStep;

  const [loading, setLoading] = useState<boolean>(true);
  const [fieldsMeta, setFieldsMeta] = useState<Array<TableMetaProps>>([]);
  const [hasField, setHasField] = useState<boolean>();
  const [canCreateField, setCanCreateField] = useState<boolean>();
  const [convertedInitialValues, setConvertedInitialValues] = useState<SaveRecordProps | undefined>(initialValues);

  const isHorizontal = direction === 'horizontal';

  const refreshStepMeta = useCallback(() => {
    setLoading(true);
    if (stepId != null) {
      getWizardStepMeta(stepId)
        .then((meta) => {
          const lcFieldsMeta = (meta == null) ? [] : meta;
          if (dynamicMetas) {
            lcFieldsMeta.push(...dynamicMetas);
          }
          console.log("getWizardStepMeta: ", lcFieldsMeta, dynamicMetas);
          setFieldsMeta(lcFieldsMeta);
          const lcHasField = lcFieldsMeta.length > 0;
          setHasField(lcHasField);
          if (lcHasField === false) {
            fetchCanCreate("DynamicFieldInstance")
              .then(json => setCanCreateField(json.create))
              .catch(e => {
                console.error(`Failed to get canCreate of domain DynamicFieldInstance: ${e}`);
              });
          }
          if (initialValues) {
            const transformedRecord = transformRecord(lcFieldsMeta, initialValues as RecordProps);
            form.setFieldsValue(transformedRecord);
            setConvertedInitialValues(transformedRecord);
          }
        }).finally(() => setLoading(false));
    } else {
      setFieldsMeta([]);
      setHasField(false);
      setLoading(false);
    }
  }, [dynamicMetas, form, initialValues, stepId]);

  useEffect(() => {
    refreshStepMeta();
  }, [refreshStepMeta]);

  const redirectIsEmpty = (redirect == null || redirect === '');
  const needDisplayRedirect = !redirectIsEmpty && finalSubmitted;

  const showMsg = (
    msg: string | undefined, level: "info" | "error" | "warning"
  ): ReactElement => {
    const msgIsEmpty = (msg == null || msg === '');
    return msgIsEmpty ?
      (<></>) : <Alert
        message={<>{wrapAsHtml(msg)}</>}
        type={level}
        className={`wizard-message wizard-message-${level}`}
        showIcon
      />;
  };

  return loading ? (<LargeSpin />) : ((hasField === false) ? (<>
    {canCreateField && <Result
      extra={canCreateField &&
        <Space>
          <Button
            type="primary"
          >
            <a href="/DynamicFieldInstance/create" target="_blank">
              <PlusCircleOutlined /> {t('Add new step field')}
            </a>
          </Button>
          <Button
            type="primary"
            onClick={() => refreshStepMeta()}
          >
            <ReloadOutlined /> {t('Refresh wizard step')}
          </Button>
        </Space>
      }
      title={t("No step field defined")}
    />}
  </>) : (
      <div>
        {isHorizontal && <Alert message={description} type="info" showIcon className="wizard-light-info" />}
        <div>
          {showMsg(errorMessage, "error")}
          {showMsg(warningMessage, "warning")}
          {showMsg(infoMessage, "info")}
          <div style={direction === 'horizontal' ? { marginTop: "24px" } : undefined}>
            {!finalSubmitted &&
              <DataCollectForm
                key={wizardStep.id}
                onChange={emptyMethod}
                onFinishFailed={emptyMethod}
                onFinish={emptyMethod}
                operation="create"
                columns={fieldsMeta}
                domainName={""}
                hideDetailPanel={isHorizontal}
                groups={[]}
                form={form}
                readonly={false}
                hideFields={[]}
                record={convertedInitialValues == null ? undefined : convertedInitialValues}
                zIndex={zIndex}
                formType={isHorizontal ? "Wizard" : "Create"}
                defaultColumnOptions={options}
                page={isHorizontal ? "wizard" : "create"}
              />
            }
            {finalSubmitted && <div className="wizard-final-step-actions">
              {needDisplayRedirect && (
                <div style={{ padding: "30px" }}>
                  <RedirectComponent
                    forMultiple={false}
                    fetchDataCallback={emptyMethod}
                    redirect={redirect}
                    zIndex={zIndex + 1}
                  />
                </div>
              )}
              <br />
              <Space size="large">
                <span onClick={() => {
                  window.location.assign("/");
                  onClose?.();
                }} className="link-span">
                  <HomeOutlined /> {t("Return to homepage")}
                </span>
                <span onClick={onClose} className="link-span">
                  <CloseCircleOutlined /> {t("Close window")}
                </span>
                <span onClick={onWizardRerun} className="link-span">
                  <RightCircleOutlined /> {t("Rerun wizard")}
                </span>
              </Space>
            </div>}
          </div>
        </div>
      </div>
    ));
}

