import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { EnumMetaProps } from "@props/RecordProps";
import { getReadOnlyClass } from '@utils/ComponentUtils';
import { SuffixIcon, ErrorBoundary } from '../../components';
import { getEnumLabelFromCache } from "@utils/EnumUtils";

const { Option } = Select;

export interface EnumSelectProps {
  type: string;
  mode?: "tags" | "multiple";
  placeholder: string;
  notFoundContent: string;
  value?: string | { enumType: string; name: string };
  style?: CSSProperties | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: any;
  updatable?: boolean;
  onChange?: (e: string) => void;
  options?: Array<EnumMetaProps>;
}

const EnumSelect = (props: EnumSelectProps): ReactElement => {
  const {
    type, mode, placeholder, value,
    notFoundContent, updatable, options
  } = props;

  const { t } = useTranslation();

  const [data, setData] = useState<Array<EnumMetaProps>>([] as Array<EnumMetaProps>);
  const [fetching, setFetching] = useState<boolean>(false);

  const disabled = (updatable == null) ? false : !updatable;
  const enumName = (typeof value === 'string') ? value : value?.name;

  function removeUpdatableAttribute(): EnumSelectProps {
    const propsToSelect = {} as EnumSelectProps;
    Object.assign(propsToSelect, props);
    delete propsToSelect.updatable;
    return propsToSelect;
  }

  const propsToSelect = removeUpdatableAttribute();

  useEffect(() => {
    setFetching(true);
    if (options != null) {
      setData(options);
    } else if (data?.length === 0) {
      getEnumLabelFromCache(type).then(setData);
    }
  }, [type, options, data]);

  // **** Attention ****
  // zIndex of the select dropdown should higher than the zIndex of modal(by
  // default 1051) because the dropdown might be displayed on a modal
  // https://stackoverflow.com/questions/53926911/antd-select-not-working-inside-a-full-screen-dialog
  // Set in App.less by .ant-select-dropdown
  return (
    <ErrorBoundary>
      <Select
        {...propsToSelect}
        mode={mode}
        showSearch
        disabled={disabled}
        value={enumName}
        placeholder={t(placeholder)}
        defaultActiveFirstOption={true}
        showArrow={true}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundContent}
        dropdownMatchSelectWidth={true}
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (option == null || option.value == null || input == null) {
            return false;
          }
          const il = input.toLowerCase();
          const validOption = ('children' in option) ?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (option.children.toLowerCase().indexOf(il) >= 0 || option.label?.toLowerCase()?.indexOf(il) >= 0) :
            (option.value.toLowerCase().indexOf(il) >= 0 || option.label?.toLowerCase()?.indexOf(il) >= 0);
          return validOption;
        }}
        className={`enum ${getReadOnlyClass(updatable)}`}
        suffixIcon={<SuffixIcon updatable={updatable} />}
        allowClear={true}
      >
        {data?.map(d => <Option key={d.value} value={d.value}>{d.label}</Option>)}
      </Select>
    </ErrorBoundary>
  );
};

export default EnumSelect;
