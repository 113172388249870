import { isEnumColumn, isObjectType } from "@utils/ColumnsUtils";
import { EnumValues, ObjectValues, RecordProps, TableMetaProps } from "../props/RecordProps";
import React, { ReactElement } from "react";
import Highlighter from "react-highlight-words";
import { FilterMinimalKeywordLength, TRIGGER_CLIENT_SIDE_FILTER_MINIMAL_TIME_MS } from '@config/base';

let latestValue: string;
let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

export async function filterDataAsync(
  data: Array<RecordProps>,
  columns: Array<TableMetaProps>,
  enumValues: EnumValues,
  objectValues: ObjectValues,
  keyword: string
): Promise<{ data: Array<RecordProps>; total: number }> {
  latestValue = keyword;

  //控制在 TRIGGER_CLIENT_SIDE_FILTER_MINIMAL_TIME_MS 内只搜索一次
  const x = (): Promise<string> => {
    return new Promise((resolve) => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => resolve('done!'),
        TRIGGER_CLIENT_SIDE_FILTER_MINIMAL_TIME_MS);
    });
  };

  return x().then(() => {
    return new Promise((resolve) => {
      const result = filterData(data, columns, enumValues, objectValues, latestValue);
      resolve(result);
    });
  });
}

/**
 * 在客户端过滤表格中的内容，使用大小写不敏感的匹配方式，使用界面上用户看到的所有信息进行匹配
 * @param data 表格中的数据内容
 * @param columns 表格的列的元数据
 * @param enumValues 表格中包含的枚举值列表
 * @param objectValues 表格中包含的对象值列表
 * @param keyword 过滤的关键字
 */
export function filterData(
  data: Array<RecordProps>,
  columns: Array<TableMetaProps>,
  enumValues: EnumValues,
  objectValues: ObjectValues,
  keyword: string): {
    data: Array<RecordProps>;
    total: number;
  } {
  //Reset all render to clear last round serach keyword highlight
  columns.forEach(column => {
    column.render = column.backupRender;
  });

  // If keyword is empty, display all data
  if (keyword == null || keyword === '') {
    return {
      data: data.map(row => {
        row.clientSideShow = true;
        return row;
      }),
      total: data.length
    };
  } if (keyword != null && keyword !== ''
    && keyword.length < FilterMinimalKeywordLength) {
    return {
      data, total: data.length
    };
  } else {
    //Loop over every line of data
    const upperCaseKeyword: string = keyword.toUpperCase();
    const columnMetaByKey: { [propName: string]: TableMetaProps } = {};
    columns.forEach(c => {
      columnMetaByKey[c.key] = c;
    });
    const isEnumColumnCache: { [propName: string]: boolean } = {};
    const isObjectColumnCache: { [propName: string]: boolean } = {};

    const flatEnumValuesCache: { [propName: string]: string } = {};
    for (const evName in enumValues) {
      const evValueLabelPairs = enumValues[evName];
      if (!Object.prototype.hasOwnProperty.call(enumValues, evName)) {
        continue;
      }
      for (let i = 0; i < evValueLabelPairs.length; i++) {
        const vlPair = evValueLabelPairs[i];
        flatEnumValuesCache[`${evName}_${vlPair.value}`] = vlPair.label;
      }
    }

    const flatObjectValuesCache: { [propName: string]: string } = {};
    for (const objName in objectValues) {
      const idLabelPairs = objectValues[objName];
      if (!Object.prototype.hasOwnProperty.call(objectValues, objName)) {
        continue;
      }
      for (let i = 0; i < idLabelPairs.length; i++) {
        const vlPair = idLabelPairs[i];
        flatObjectValuesCache[`${objName}_${vlPair.value}`] = vlPair.label;
      }
    }
    let matchedNumber = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filteredData = data.map((row, idx) => {
      let match = false;
      //Loop over every column of each line of data to match
      for (const property in row) {
        if (!Object.prototype.hasOwnProperty.call(row, property)) {
          continue;
        }
        const columnMeta = columnMetaByKey[property];
        if (columnMeta == null) {
          continue;
        }
        const rowValue = row[property];
        if (rowValue == null || rowValue === '') {
          continue;
        }
        const { type } = columnMeta;
        const isEnumFromCache = isEnumColumnCache[type];
        const isEnum = (isEnumFromCache != null) ? isEnumFromCache : isEnumColumn(type);
        isEnumColumnCache[type] = isEnum;

        const isObjectFromCache = isObjectColumnCache[type];
        const isObject = (isObjectFromCache != null) ? isObjectFromCache : isObjectType(type);
        isObjectColumnCache[type] = isObject;

        if (isEnum) {
          const enumValue = enumValues[property];
          if (null != enumValue && enumValue.length > 0) {
            const enumCacheKey = `${property}_${rowValue}`;
            const matchLabel = flatEnumValuesCache[enumCacheKey];
            if (matchLabel.toUpperCase()?.includes(upperCaseKeyword)) {
              match = true;
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              columnMeta.render = (text: string): ReactElement => {
                return (
                  <Highlighter
                    highlightClassName="highlight-word"
                    searchWords={[keyword]}
                    autoEscape
                    textToHighlight={matchLabel ? matchLabel : ''}
                  />
                );
              };
            }
          }
        } else if (isObject) {
          const objectCacheKey = `${type}_${rowValue.id}`;
          const matchLabel = flatObjectValuesCache[objectCacheKey];
          if (matchLabel?.toUpperCase().includes(upperCaseKeyword)) {
            match = true;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            columnMeta.render = (text: string): ReactElement => {
              return (
                <Highlighter
                  highlightClassName="highlight-word"
                  searchWords={[keyword]}
                  autoEscape
                  textToHighlight={matchLabel ? matchLabel : ''}
                />
              );
            };
          }
        } else {
          if ((rowValue != null && rowValue.toString().toUpperCase().includes(upperCaseKeyword))) {
            match = true;
            columnMeta.render = (text: string): ReactElement => {
              return (
                <Highlighter
                  highlightClassName="highlight-word"
                  searchWords={[keyword]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ''}
                />
              );
            };
          }
        }
      }
      row.clientSideShow = match;
      if (match) {
        matchedNumber += 1;
      }
      return row;
    });

    return {
      data: filteredData,
      total: matchedNumber
    };
  }
}
