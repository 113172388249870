import React, { ReactElement } from 'react';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { openInfoNotification } from '@utils/NotificationUtils';
import { stopPropagationAndPreventDefault } from "../utils/ObjectUtils";
import { LanguageLocalStorageKey } from '@config/i18n';

const SwitchLanguage = (): ReactElement => {
  const { t } = useTranslation();
  const initLang = localStorage.getItem(LanguageLocalStorageKey) || 'zh';
  const [lang, setLang] = React.useState<string>(initLang);
  const changeLanguage = (e: React.MouseEvent<unknown>, langCode: string, langName: string): void => {
    stopPropagationAndPreventDefault(e);
    dayjs.locale(langCode);
    i18next.changeLanguage(langCode);
    setLang(langCode);
    localStorage.setItem(LanguageLocalStorageKey, langCode);
    openInfoNotification(t("Language switch notification", { lang: langName }));
  };
  const supportLangs: Array<{
    code: string;
    name: string;
    flag: string;
  }> = [
      { code: 'zh', name: '简体中文', flag: '中' },
      { code: 'en', name: 'English', flag: 'EN' },
    ];
  return (
    <div style={{ cursor: "pointer" }}>
      <Space>
        {supportLangs.map((l) => {
          return (
            <span
              key={l.code}
              onClick={(e) => changeLanguage(e, l.code, l.name)}
              className={lang === l.code ? "language-selected" : "language-unselected"}
            >
              {l.flag}
            </span>
          );
        })}
      </Space>
    </div>
  );
};

export default SwitchLanguage;
