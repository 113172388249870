

interface ChangeEvent {
  path: string;
  payload: unknown;
}

export type ChangeEventCallback = (event: ChangeEvent) => void;

export type UnregisterCallback = () => void;

export class EventBus {

  private readonly listenersMap = new Map<string, ChangeEventCallback[]>();

  public on = (path: string, callback: ChangeEventCallback): UnregisterCallback => {
    this.listenersMap.set(path, [...(this.listenersMap.get(path) || []), callback]);
    return () => {
      const listeners = this.listenersMap.get(path);
      if (listeners) {
        this.listenersMap.set(path, listeners.filter((cb) => cb !== callback));
      }
    };
  };

  public emit = (path: string, payload: unknown): void => {
    console.log("Emitting event", path, payload);
    try {
      const parts = path.split("/");
      let subPath = "";
      parts.forEach((part) => {
        subPath += part;
        const listeners = this.listenersMap.get(subPath);
        if (listeners) {
          listeners.forEach((cb) => {
            console.log("Calling callback", cb, path, payload);
            cb({ path, payload });
          });
        }
        subPath += '/';
      });
    } catch (e) {
      console.error("Failed to emit event", e);
    }
  };

}

export const resolvePath = (path: string, pathExpression: string): string => {
  const pathParts = path.split("/");
  const expressionParts = pathExpression.split("/");
  const resolvedPaths = [...pathParts];
  for (let i = 0; i < expressionParts.length; i++) {
    const expressionPart = expressionParts[i];
    if (expressionPart === ".") {
      continue;
    }
    if (expressionPart === "..") {
      resolvedPaths.splice(resolvedPaths.length - 1, 1);
      continue;
    }
    resolvedPaths.push(expressionPart);
  }
  return resolvedPaths.join("/");
};

export const buildPath = (parentPath: string, key: string): string => {
  return `${parentPath}/${key}`;
};

export const EVENT_BUS = new EventBus();