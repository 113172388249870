import { LoginResultProps } from "@utils/FetchUtilsProps";
import { OrganizationInfo } from "@props/RecordProps";

/**
 * localStorage 中存放的 tokens 的 keys
 */
export const AccessTokenLocalStorageKey = 'tech.muyan.access_token';
export const RefreshTokenLocalStorageKey = 'tech.muyan.refresh_token';
export const UsernameLocalStorageKey = 'tech.muyan.username';
export const NameLocalStorageKey = 'tech.muyan.name';
export const OrganizationLocalStorageKey = 'tech.muyan.organization';
export const UserAvatarLocalStorageKey = 'tech.muyan.avatar';
export const UserIdLocalStorageKey = 'tech.muyan.user_id';
export const DefaultPageLocalStorageKey = 'tech.muyan.defaultPage';

const getAccessToken = (): string | null => localStorage.getItem(AccessTokenLocalStorageKey);
const getRefreshToken = (): string | null => localStorage.getItem(RefreshTokenLocalStorageKey);
const getUsername = (): string | null => localStorage.getItem(UsernameLocalStorageKey);
const getName = (): string | null => localStorage.getItem(NameLocalStorageKey);
const getAvatar = (): string | null => localStorage.getItem(UserAvatarLocalStorageKey);
const getOrganization = (): OrganizationInfo => JSON.parse(localStorage.getItem(OrganizationLocalStorageKey) ?? "{}");
const getUserId = (): string | null => localStorage.getItem(UserIdLocalStorageKey);
const getDefaultPage = (): string | null => localStorage.getItem(DefaultPageLocalStorageKey);

const saveTokens = (jwt: LoginResultProps): void => {
    localStorage.setItem(AccessTokenLocalStorageKey, jwt.access_token);
    localStorage.setItem(RefreshTokenLocalStorageKey, jwt.refresh_token);
    localStorage.setItem(UsernameLocalStorageKey, jwt.username);
    localStorage.setItem(NameLocalStorageKey, jwt.name ?? '');
    localStorage.setItem(OrganizationLocalStorageKey, JSON.stringify(jwt.organization));
    localStorage.setItem(UserAvatarLocalStorageKey, jwt.avatar ?? '');
    localStorage.setItem(UserIdLocalStorageKey, jwt.id.toString());
    localStorage.setItem(DefaultPageLocalStorageKey, jwt.defaultPage ?? '');
};

const clearTokens = (): void => {
    localStorage.removeItem(AccessTokenLocalStorageKey);
    localStorage.removeItem(RefreshTokenLocalStorageKey);
    localStorage.removeItem(UsernameLocalStorageKey);
    localStorage.removeItem(NameLocalStorageKey);
    localStorage.removeItem(OrganizationLocalStorageKey);
    localStorage.removeItem(UserAvatarLocalStorageKey);
    localStorage.removeItem(UserIdLocalStorageKey);
    localStorage.removeItem(DefaultPageLocalStorageKey);
};

export {
    saveTokens,
    getAccessToken,
    getRefreshToken,
    clearTokens,
    getUsername,
    getName,
    getOrganization,
    getAvatar,
    getUserId,
    getDefaultPage,
};
