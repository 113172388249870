import { DetailPanelProps, RecordProps } from '@props/RecordProps';
import React, { ReactElement } from 'react';
import GroupedGrandChildComponent from './GroupedGrandChildComponent';

const GroupedGrandChildDetailPanel = (props: DetailPanelProps): ReactElement => {
  const {
    record, column, zIndex, domainName, form
  } = props;

  return (record == null || domainName == null) ? (<></>) : (
    <GroupedGrandChildComponent
      record={record as RecordProps}
      column={column}
      page={"DISPLAY"}
      zIndex={zIndex}
      domainName={domainName}
      form={form}
    />
  );
};

export default GroupedGrandChildDetailPanel;
