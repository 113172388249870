import React, { ReactElement } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Routes,
} from 'react-router-dom';

import RoutersConfig from '../kernel/RoutersConfig';

const RoutersNav = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <Routes>
      {RoutersConfig({type: "private", navigate})}
    </Routes>
  );
};

export default RoutersNav;
