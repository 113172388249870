import React, { ReactElement } from "react";
import { DateFormat, DateTimeFormat } from '@config/base';
import dayjs from "dayjs";
interface DateTimeCellRenderProps {
  value: string;
  type: string;
}

/**
 * 日期格式标签
 * value： 日期值。
 * type： 日期格式化。例如：YYYY-MM-DD
 * @param props
 * @constructor
 */
const DateTimeCell = (props: DateTimeCellRenderProps): ReactElement => {
  const { value, type } = props;

  // console.log("DateTimeCell", a, value, type);
  const classNameValue = type + "-value";
  const emptyDiv = (<div className={classNameValue} />);
  if (value === null || value === undefined || value === "undefined") {
    return emptyDiv;
  }
  const format = (type === "date") ? DateFormat : DateTimeFormat;
  const datetimeValue = dayjs(value).format(format);

  return (datetimeValue != null) ?
    (<div className={classNameValue}>{datetimeValue}</div>) : emptyDiv;
};

export default DateTimeCell;
