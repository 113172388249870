import { requestUrlAndGetPromiseThrowError } from "@utils/FetchUtils";
import { SERVER_URL } from "@config/base";

export interface FunctionInfo {
  id: number;
  name: string;
  description: string;
}

export interface FetchFunctionInfoResponse {
  pattern: string;
  functions: FunctionInfo[];
}

export const fetchFunctions = async (): Promise<FetchFunctionInfoResponse> => {
  return await requestUrlAndGetPromiseThrowError(`${SERVER_URL}/function`, {}, {
    useCache: true,
  });
};