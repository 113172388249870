import React, { ReactElement, useEffect, useState } from 'react';
import { Message } from '@props/RecordProps';
import { Button, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './App.less';
import { saveDomain } from '@utils/FetchUtils';
import { emptyMethod } from '@utils/Constants';
import { SaveDomainProps } from '@utils/FetchUtilsProps';
import { SERVER_URL } from '@config/base';
import { RedirectComponent } from '../redirect';

export interface ActionRequiredNotificationProps {
  messages: Array<Message>;
  zIndex: number;
}

const ActionRequiredNotification = (props: ActionRequiredNotificationProps): ReactElement => {
  const { messages, zIndex } = props;

  const [dismissedMessages, setDismissedMessages] = useState<number[]>([]);
  const [displayActionUrlMessageId, setDisplayActionUrlMessageId] = useState<number>();
  const [displayModal, setDisplayModal] = useState<boolean>(true);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
  const domainName = 'Message';
  const message = messages[currentMessageIndex];

  useEffect(() => {
    setDisplayModal(messages.length > 0);
  }, [messages]);

  const { t } = useTranslation();
  if (messages.length === 0) {
    return (<></>);
  }

  const handleDismiss = (id: number): void => {
    markMessageAsRead(id).then(() => {
      setDismissedMessages([...dismissedMessages, id]);
      if (currentMessageIndex < messages.length - 1) {
        const nextIdx = currentMessageIndex + 1;
        setCurrentMessageIndex(nextIdx);
      } else {
        setDisplayModal(false);
      }
    }).catch((e: unknown) => console.error(`Failed to mark message ${id} as read: ${e}`));
  };

  const onHandle = (id: number): React.MouseEventHandler<HTMLElement> | undefined => {
    return () => {
      markMessageAsRead(id).then(() => {
        setDisplayActionUrlMessageId(id);
        if (currentMessageIndex < messages.length - 1) {
          const nextIdx = currentMessageIndex + 1;
          setCurrentMessageIndex(nextIdx);
        } else {
          setTimeout(() => {
            setDisplayModal(false);
          }, 1000);
        }
      }).catch((e: unknown) => console.error(`Failed to mark message ${message} as read: ${e}`));
    };
  };

  const renderMessage = (message: Message): ReactElement => {
    return (
      <>
        <ReactMarkdown
          linkTarget="_blank"
        >
          {message.content}
        </ReactMarkdown>
        {displayActionUrlMessageId === message.id && <RedirectComponent
          redirect={message.actionUrl ?? '/inbox'}
          forMultiple={false}
          fetchDataCallback={() => setDisplayActionUrlMessageId(undefined)}
          zIndex={zIndex + 3}
          showText={false}
        />
        }
      </>
    );
  };

  const nextMessage = (): void => {
    if (currentMessageIndex < messages.length - 1) {
      const nextIdx = currentMessageIndex + 1;
      setCurrentMessageIndex(nextIdx);
    }
  };

  const prevMessage = (): void => {
    if (currentMessageIndex > 0) {
      const nextIdx = currentMessageIndex - 1;
      setCurrentMessageIndex(nextIdx);
    }
  };

  const markMessageAsRead = (id: number): Promise<unknown> => {
    const data: SaveDomainProps = {
      values: {
        id: id,
        isUnRead: false,
      },
      domainName,
      method: "PUT",
      successCallback: emptyMethod,
      failCallback: (e: unknown) => console.log(`Failed to mark ${message} as readed: ${e}`),
      skipInfoNotification: true,
      url: `${SERVER_URL}/${domainName}/${message.id}`
    };
    return saveDomain(data);
  };

  const modalContent = (
    <>
      {renderMessage(message)}
    </>
  );

  const title = <>
    <Space style={{ fontSize: "120%" }}>
      <ExclamationCircleOutlined
        style={{
          color: "orange",
          fontSize: "150%",
        }} />
      {t('Immediately action required messages')}
    </Space>
    <Space className="handle-buttons">
      <Button
        onClick={onHandle(message.id)}
        type="primary"
      >
        {t('Handle immediately')}
      </Button>
      <Button
        onClick={() => handleDismiss(message.id)}
      >
        {t('Handle later')}
      </Button>
    </Space>
  </>;

  const footer = <Space>
    <Button
      onClick={prevMessage}
      disabled={currentMessageIndex === 0}
    >
      {t('Previous')}
    </Button>
    <Button
      onClick={nextMessage}
      disabled={currentMessageIndex === messages.length - 1}
    >
      {t('Next')}
    </Button>
  </Space>;
  return (
    <Modal
      className="action-required-notification-modal"
      open={displayModal}
      closeIcon={<></>}
      footer={footer}
      zIndex={zIndex + 3}
      width={800}
      title={title}
    >
      {modalContent}
    </Modal>
  );
};

export default ActionRequiredNotification;
