import { ReactElement } from "react";
import { TableMode } from "@props/RecordProps";
import { EditableControllerProps, FieldConfigures } from "./ComponentsConfig";
import { isEnumColumn, isObjectType } from "@utils/ColumnsUtils";

/**
 * 不同的数据类型，使用不同的编辑控件，
 * 本方法负责根据 type 信息从组件的配置中查找对应的编辑控件
 */
export const getEditableController = (
  type: string,
  mode?: TableMode
): (((props: EditableControllerProps) => ReactElement) | undefined) => {
  const fieldConfig = FieldConfigures.filter(f => {
    const destType = (type !== 'roles' && isObjectType(type)) ? "object" : (isEnumColumn(type) ? "enum" : type);
    return f.type === destType;
  });
  const numOfController = fieldConfig.length;
  if (numOfController === 1) {
    return fieldConfig[0].editableComponent?.(mode);
  } else if (numOfController > 1 || numOfController === 0) {
    const message = `${numOfController === 0 ? "No" : "Multiple"} editable component render defined for ${type} with tableMode ${mode}, please check ComponentsConfig.tsx, constant FieldConfigures`;
    console.error(message);
  }
  return undefined;
};

/**
 * 在 antd 的控件中，使用哪个属性来将值传递到表单中，针对不同的控件类型，antd 使用了不同的属性
 * 具体文档可参考 https://ant.design/components/form-cn/ 关于 valuePropName 的相关说明
 * 对于 integer, decimal, string, long, datetime, date, text, boolean, password
 * 在领域模型中直接定义的字段与动态字段，使用相同的type
 * 对于单选及多选类型的字段，直接在领域模型中定义的字段，type为 tags, multiple 或者 single,
 * 对于动态字段的选择类型的字段，则使用 df_tags, df_multiple, df_single 作为字段的类型
 */
export const getValuePropName = (type: string): string => {
  return FieldConfigures.filter(f => f.type === type)[0]?.valuePropName ?? "value";
};
