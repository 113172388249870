import React, { ReactElement } from 'react';
import { TableMode } from '@props/RecordProps';
import { Popover, Tag } from 'antd';
import ObjectCell from './ObjectCell';

interface MultipleObjectsCellRenderProps {
  domainName: string;
  labelField?: string;
  displayText?: string;
  tableMode?: TableMode;
  ids?: Array<number>;
  zIndex: number;
}

const MultipleObjectsCell = (props: MultipleObjectsCellRenderProps): ReactElement => {
  const {
    domainName, labelField, tableMode, zIndex, ids,
  } = props;

  if (ids == null || ids.length === 0) {
    return (<></>);
  } else if (Array.isArray(ids)) {
    const elems = ids?.map((id, idx) => {
      return (<ObjectCell
        domainName={domainName}
        id={id}
        zIndex={zIndex}
        key={`${id}_${idx}`}
        labelField={labelField}
        tableMode={tableMode}
      />);
    });
    const result = (
      <Popover
        trigger="click"
        placement="right"
        overlayClassName="tag-popover"
        content={
          <div className="objects"> {elems} </div>
        }
        overlayStyle={{ zIndex: zIndex + 1 }}
      >
        <Tag className="object-id-tag">
          &raquo; &raquo; &raquo;
        </Tag>
      </Popover>
    );
    return result;
  } else {
    return (<></>);
  }
};

export default MultipleObjectsCell;
