import React, { ReactElement } from "react";
import { Alert, Popover, Tag } from "antd";
import { useTranslation } from 'react-i18next';
import { MaxTextCellDirectDisplayCharLength } from '@config/base';
import { CopyOutlined } from '@ant-design/icons';
import { wrapAsHtml } from "@utils/ComponentUtils";
import { copyToClipBoard } from "@utils/StringUtils";
import { openSuccessMessage } from '@utils/NotificationUtils';
import {stopPropagationAndPreventDefault} from "@utils/ObjectUtils";

interface TextCellRenderProps {
  value: string;
  length?: number;
  placeholder?: string;
  displayMode?: "exception";
}

const TextCell = (props: TextCellRenderProps): ReactElement => {
  const { value, length, placeholder, displayMode } = props;
  const maxDisplay = (length != null) ?
    length : MaxTextCellDirectDisplayCharLength;
  const style = (maxDisplay === 999) ? "detail-string" : "";
  const { t } = useTranslation();
  if (value == null) {
    return <div className="string-cell" />;
  }
  if (value === "null") {
    return <Popover
      title={undefined}
      trigger="hover"
      placement="left"
      content={
        <Alert
          message="本次执行返回值为 null（空对象）"
          description="根据不同业务场景，前台可能隐藏该对象、显示空字符串，或报错"
          className="light-bg-alert"
          type="info"
          showIcon={true}
        />
      }
    >
      <Tag className="string-cell-placeholder">{placeholder ?? 'NULL'}</Tag>
    </Popover>;
  }
  const className = (displayMode === 'exception')
    ? "string-popover-stacktrace" : "string-popover";
  const classNames = `${className} ${style}`;
  const toHtmlValue = /<\/?[a-z][\s\S]*>/i.test(value) ? wrapAsHtml(value) : value;
  const content = (value.length > maxDisplay) ?
    (<div><Popover
      title={undefined}
      trigger={["hover", "click"]}
      placement="left"
      content={
        <>
        <CopyOutlined
        style={{
          position: "absolute",
          right: "20px",
          top: "36px",
          fontSize: "150%"
        }}
        onClick={(e: React.MouseEvent<unknown>) => {
          e.stopPropagation();
          copyToClipBoard(value).then(() => {
            openSuccessMessage(t('Success copy to clipboard'));
          });
        }}
        title={t('Copy to clipboard')}
        />
        <pre
          onClick={(e: React.MouseEvent<unknown>) => {
              stopPropagationAndPreventDefault(e);
          }}
          className={classNames}
        >
          {toHtmlValue}
        </pre>
        </>
      }
    >
      <Tag className="string-cell-placeholder">{placeholder ?? '...'}</Tag>
    </Popover></div>) : <span style={{ whiteSpace: "pre-line" }}>{toHtmlValue}</span>;
  return (<div  key={Math.random().toString()} className={`string-cell ${style}`}>{content}</div>);
};

export default TextCell;
