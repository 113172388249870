import { CompareFn } from "antd/es/table/interface";
import { RecordProps } from "@props/RecordProps";
import { isEnumColumn, isObjectType } from "@utils/ColumnsUtils";
import { SorterResult } from 'antd/lib/table/interface';
import { TableMetaProps } from "@props/RecordProps";

const DefaultSortTypes = [
  "decimal",
  "string",
  "id",
  "integer",
  "text",
  "percentage",
  "currency",
  "httpMethod",
  "date",
  "datetime",
  "boolean",
  "df_single",
  "int",
];

function compareIfNull(valOne: unknown, valTwo: unknown): number {
  if (valOne == null && valTwo == null) {
    return 0;
  }
  if (valOne == null && valTwo != null) {
    return -1;
  }
  if (valOne != null && valTwo == null) {
    return 1;
  }
  return NaN;
}

export type Sorter = boolean | CompareFn<RecordProps> | { compare: CompareFn<RecordProps>; multiple: number; } | undefined;

export const getSorter = (type: string, key: string): Sorter => {
  if (DefaultSortTypes.includes(type) || isEnumColumn(type)) {
    return (a: RecordProps, b: RecordProps) => {
      const valOne = a[key];
      const valTwo = b[key];
      const c = compareIfNull(valOne, valTwo);
      if (!isNaN(c)) {
        return c;
      }
      if (valOne > valTwo) {
        return 1;
      } else if (valOne < valTwo) {
        return -1;
      } else {
        return 0;
      }
    };
  } else if (isObjectType(type)) {
    return (a: RecordProps, b: RecordProps) => {
      const valOne = a[key];
      const valTwo = b[key];
      const c = compareIfNull(valOne, valTwo);
      if (!isNaN(c)) {
        return c;
      }
      if (valTwo != null && valOne.id > valTwo.id) {
        return 1;
      } else if (valTwo != null && valOne.id < valTwo.id) {
        return -1;
      } else {
        return 0;
      }
    };
  }
  return false;
};

export const isSorterChange = (newSorter: SorterResult<RecordProps>, currentSorter?: SorterResult<RecordProps>): boolean => {
  const newSortIsEmpty = (Object.entries(newSorter).length === 0 || newSorter == null);
  if ((currentSorter == null && !newSortIsEmpty) || (currentSorter != null && newSortIsEmpty)) {
    return true;
  } else if (currentSorter == null && newSortIsEmpty) {
    return false;
  } else {
    return !(
      currentSorter?.column === newSorter?.column &&
        currentSorter?.columnKey === newSorter?.columnKey &&
        currentSorter?.field === newSorter?.field &&
        currentSorter?.order === newSorter?.order
    );
  }
};

export const wrapColumnSorter =(columns: Array<TableMetaProps>): void => {
  columns.forEach(column => {
    const { key, type } = column;
    const sorter = getSorter(type, key);
    if (sorter != null) {
      column["sorter"] = sorter;
    }
  });
};
