import { Row, Col } from 'antd';
import React, { ReactElement } from 'react';

export interface MasterDetailLayoutProps {
  flex: string;
  leftOnClick: () => void;
  leftContent: ReactElement;
  rightVisible: boolean;
  rightContent: ReactElement;
  leftClassName?: string;
  rightClassName?: string;
}

const MasterDetailLayout = (props: MasterDetailLayoutProps): ReactElement => {
  const {
    flex, leftContent, leftOnClick, rightContent, rightVisible,
    leftClassName, rightClassName,
  } = props;

  return (
    <Row
      justify="end"
    >
      <Col
        flex={flex}
        onClick={leftOnClick}
        className={`md-layout-left ${leftClassName ?? ""}`}
        span={24}
      >
        {leftContent}
      </Col>
      {rightVisible &&
        <Col
          flex="auto"
          style={{ width: "1px" }}
          className={`md-layout-right ${rightClassName ?? ""}`}
          span={24}
        >
          {rightContent}
        </Col>}
    </Row>
  );
};

export default MasterDetailLayout;
