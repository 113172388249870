import React, { ReactElement, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { FileTypeIconMapping } from "@config/ui";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  PaperClipOutlined,
  FormOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import { Badge, Popconfirm, Space, Tooltip, Popover, Input, Button } from "antd";
import { useDrag, useDrop } from 'react-dnd';
import { isFail, isSuccess, isRemoved, isPreviewable } from "./FileOperatorUtils";
import type { UploadFile } from 'antd/es/upload/interface';
import { ExtendUploadFile, BaseResponseProps } from "@props/RecordProps";
import { openErrorNotification, openInfoNotification } from "@utils/NotificationUtils";
import { updateAttachmentDisplayName } from '@utils/FetchUtils';
import { DndItemTypes } from "@config/base";
import { useConfig } from "@utils/hooks";

interface FileItemRenderProps {
  updatable: boolean;
  file: ExtendUploadFile;
  originNode: React.ReactElement;
  fileList: Array<UploadFile<unknown>>;
  actions: {
    download: () => void;
    preview: () => void;
    remove: () => void;
  };
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  rename: (file: ExtendUploadFile, filename: string) => void;
  remove: (file: ExtendUploadFile) => void;
}

const DefaultSpaceSize = 2;

const FileItemRender = (props: FileItemRenderProps): ReactElement => {
  const { file, actions, updatable, fileList, moveRow, rename, remove } = props;
  const { t } = useTranslation();
  const { download, preview } = actions;
  const [showIcons, setShowIcons] = useState(false);
  const [newFileName, setNewFileName] = useState<string>();
  const { fileType, response, name, status, data } = file;
  const [renaming, setRenaming] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [renamePopoverVisible, setRenamePopoverVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const { value: controlDownloadPermission } = useConfig("attachment.align_download_permission_with_update", "boolean");

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: DndItemTypes.FileItem,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: DndItemTypes.FileItem,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const icon = fileType ?
    (FileTypeIconMapping[fileType] ?? <PaperClipOutlined />) : (<PaperClipOutlined />);
  const fileStatusNull = (status == null);
  const isToRemoveFile = isRemoved(file);
  const fileNameStyle = { cursor: "move", textDecoration: `${isToRemoveFile ? 'line-through' : 'none'}` };

  const fileName = (
    <span className="upload-filename">
      {updatable && fileStatusNull &&
        <Tooltip title={t('File has not been uploaded')}>
          <Badge dot className="dark-orange-dot" />
        </Tooltip>}
      {updatable && isFail(file) &&
        <Tooltip title={t("File upload failed", { message: response, fileName: file.fileName })}>
          <Badge dot />
        </Tooltip>
      }
      {updatable && isSuccess(file) && <Badge dot className="green-dot" title={t("File upload success")} />}
      {updatable && isToRemoveFile &&
        <Tooltip title={t("To remove file")}>
          <Badge dot className="grey-dot" />
        </Tooltip>
      }
      <span
        title={name}
        style={fileNameStyle}
      >
        {name}
      </span>
    </span>
  );
  const fileOnServer = (!fileStatusNull || data?.id != null);
  const downloadIcon = (<CloudDownloadOutlined
    onClick={() => {
      if (fileOnServer) {
        download();
      }
    }}
    title={t('Download attachment')}
    style={{
      color: (fileOnServer ? undefined : 'darkgray'),
      cursor: (fileOnServer ? undefined : 'default')
    }}
  />);
  const previewIcon = isPreviewable(file) && (<EyeOutlined
    onClick={preview}
    title={t('Preview attachment')}
  />);
  const removeIcon = (
    <Popconfirm
      visible={deleteConfirmVisible}
      onVisibleChange={setDeleteConfirmVisible}
      onConfirm={() => {
        remove(file);
        setDeleteConfirmVisible(false);
      }}
      disabled={isToRemoveFile}
      title={t(isSuccess(file) ?
        'This file has been uploaded to server, are you sure to delete it?' :
        'Are you sure to remove this file from upload list?'
      )}
    >
      <DeleteOutlined
        title={isToRemoveFile ?
          t('Attachment has been marked as to remove') : t('Delete attachment')
        }
        style={{
          color: (isToRemoveFile ? 'darkgray' : undefined),
          cursor: (isToRemoveFile ? 'default' : undefined)
        }}
      />
    </Popconfirm>
  );
  const renameForm = (
    <Space
      direction="vertical"
      align="center"
      size="middle"
    >
      <Input
        defaultValue={file.name}
        style={{ width: "400px" }}
        onChange={(e) => setNewFileName(e.target.value)}
      />
      <Button icon={
        <CheckCircleOutlined
          style={{ color: "green", cursor: "pointer" }}
          title={t('Save')}
        />
      }
        loading={renaming}
        onClick={() => {
          if (newFileName != null && newFileName !== '') {
            if (file.id != null) {
              updateAttachmentDisplayName(file.id, newFileName).then((json: BaseResponseProps) => {
                //Sync the change to parent component to refresh display
                if (json.success === true) {
                  openInfoNotification(t('Change file display name success', { fileName: file.name, newFileName }));
                  rename(file, newFileName);
                } else {
                  openErrorNotification(t('Change file display name failed', { fileName: file.name, newFileName, error: json.message }));
                }
              }).catch(e => {
                console.error("Failed to rename file ", file, ": ", e);
                openErrorNotification(t('Change file display name failed', { fileName: file.name, newFileName, error: e }));
              }).finally(() => setRenaming(false));
            } else {
              file.name = newFileName;
              setRenaming(false);
              rename(file, newFileName);
            }
          } else {
            openErrorNotification(t('New file name can not be blank'));
          }
        }}
      >
        {t('Save')}
      </Button>
    </Space>
  );
  const renameIcon = (
    <Popover
      visible={renamePopoverVisible}
      onVisibleChange={setRenamePopoverVisible}
      placement="top"
      title={t('Rename file display name')}
      content={renameForm}
      trigger="click"
    >
      <FormOutlined
        style={{ cursor: "pointer" }}
        title={t('Rename file display name')}
      />
    </Popover>
  );
  const containerClassName = updatable ? "upload-operation-container" : "upload-operation-container-readonly";
  const canDownload = (updatable && controlDownloadPermission)
    || !controlDownloadPermission;
  const operationIcons = (
    <Space
      direction={"horizontal"}
      size="small"
      className={containerClassName}
    >
      {canDownload && downloadIcon}
      {previewIcon}
      {updatable && renameIcon}
      {updatable && removeIcon}
    </Space>
  );
  return (
    <div
      ref={ref}
      onMouseEnter={() => {
        if (!showIcons && !renamePopoverVisible && !deleteConfirmVisible) {
          setShowIcons(true);
        }
      }}
      onMouseLeave={() => {
        if (showIcons && !renamePopoverVisible && !deleteConfirmVisible) {
          setShowIcons(false);
        }
      }}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
    >
      <Space direction="horizontal" size={DefaultSpaceSize}>
        <Space
          className="upload-filename-container"
          direction={"horizontal"}
          size={DefaultSpaceSize}
        >
          {icon}
          {fileName}
        </Space>
        {showIcons && operationIcons}
      </Space>
    </div>
  );
};

export default FileItemRender;
