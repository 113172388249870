import React, { HTMLAttributeAnchorTarget, ReactElement, useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import {
  QuestionCircleOutlined, CustomerServiceOutlined, ReadOutlined,
  DeploymentUnitOutlined, PhoneOutlined, BulbOutlined, BugOutlined,
  CodeSandboxOutlined, UserOutlined, LinkOutlined
} from
  '@ant-design/icons';
import "./SupportWidget.less";

import { useTranslation } from 'react-i18next';
import FeedbackForm from './FeedbackForm';
import { ExtendedMenuProps, FeedbackType, SupportWidgetProps } from '.';
import { useConfig } from '@utils/hooks';
import SetBackendModal from "../../development/SetBackendModal";
import { SERVER_URL } from "@config/base";
import { loggedIn } from '@utils/LoginUtils';
import { fetchConfig } from '@utils/hooks/useConfig';

export const SupportWidget = (props: SupportWidgetProps): ReactElement => {

  const { zIndex, authorized } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showFeedbackType, setShowFeedbackType] = useState<FeedbackType>();
  const [ipAddress, setIpAddress] = useState('');
  const [value, setValue] = useState<string>();

  const { t } = useTranslation();


  const { value: userManualLink } = useConfig("feedback.user_manual_link");
  const { value: developerManualLink } = useConfig("feedback.developer_manual_link");

  useEffect(() => {
    if (loggedIn()) {
      fetchConfig("feedback.enable_types").then(r => {
        if (r) {
          setValue(r.value as string);
        }
      });
    }
    const fetchIpAddress = async (): Promise<void> => {
      const cacheKey = `${SERVER_URL}_ipAddress`;
      try {
        // Check if the ipAddress is stored in the local storage
        const cachedIpAddress = localStorage.getItem(cacheKey);
        if (cachedIpAddress) {
          setIpAddress(cachedIpAddress);
        } else {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          setIpAddress(data.ip);
          localStorage.setItem(cacheKey, data.ip);
        }
      } catch (error) {
        console.error(error);
        setIpAddress('');
      }
    };

    fetchIpAddress().then(r => r);
  }, []);


  interface MenuItemLabelProps {
    icon: ReactElement;
    text: string;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
  }

  const MenuItemLabel: React.FC<MenuItemLabelProps> = ({ icon, text, href, target }: MenuItemLabelProps) => (
    <Space direction="horizontal">
      {icon}
      <a rel="noopener noreferrer" href={href ?? "#/"} target={target}>
        {text}
      </a>
    </Space>
  );

  const items: ExtendedMenuProps['items'] = [
    {
      key: 'QUESTION',
      authorized: true,
      label: (
        <MenuItemLabel
          icon={<CustomerServiceOutlined />}
          text={t('Function consultation')}
        />
      ),
    },
    {
      key: 'BUG',
      authorized: true,
      label: (
        <MenuItemLabel
          icon={<BugOutlined />}
          text={t('Report bug')}
        />
      ),
    },
    {
      key: 'SUGGESTION',
      authorized: true,
      label: (
        <MenuItemLabel
          icon={<BulbOutlined />}
          text={t('Feature request')}
        />
      ),
    },
    {
      key: 'PURCHASE_CONSULT',
      authorized: true,
      label: (
        <MenuItemLabel
          icon={<UserOutlined />}
          text={t('Purchase consultation')}
        />
      ),
    },
    {
      key: 'BUSINESS_COOPERATION',
      authorized: true,
      label: (
        <MenuItemLabel
          icon={<PhoneOutlined />}
          text={t('Business cooperation')}
        />
      ),
    },
  ];

  const alwaysShowItems = [
    {
      key: '210',
      authorized: false,
      label: (
        <MenuItemLabel
          icon={<ReadOutlined />}
          text={t('User manual')}
          href={(userManualLink as string) ?? 'https://docs.muyan.io'}
          target="_blank"
        />
      ),
    },
    {
      key: '220',
      authorized: false,
      label: (
        <MenuItemLabel
          icon={<CodeSandboxOutlined />}
          text={t('Developer manual')}
          href={(developerManualLink as string) ?? 'https://docs.muyan.io'}
          target="_blank"
        />
      ),
    },
    {
      key: '230',
      authorized: false,
      label: <SetBackendModal visibleProp={false} />,
    },
    {
      key: '240',
      authorized: false,
      label: (
        <Space>
          <DeploymentUnitOutlined />版本: {process.env.REACT_APP_VERSION}
        </Space>
      ),
    },
    // Add link to https://next.muyan.io, our next generation UI
    {
      key: '250',
      authorized: false,
      label: (
        <MenuItemLabel
          icon={<LinkOutlined />}
          text={t('Next generation UI')}
          href="https://next.muyan.io"
          target="_blank"
        />
      ),
    },
  ];

  const enableTypes = (value as string)?.split(',') || [];
  const filteredItems = items.filter(item => enableTypes.includes(item.key as string) &&
    (item.authorized === authorized || item.authorized === false));
  // 如果显示的反馈类型不为空，则添加一个分割线
  if (filteredItems.length > 0) {
    filteredItems.push({
      key: '200',
      authorized: false,
      label: (<span style={{ cursor: 'arrow' }} ><hr /></span>),
    });
  }
  const displayMenus = [...filteredItems, ...alwaysShowItems];
  // 从数组中过滤掉不需要显示的属性(authorized)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const trimed = displayMenus.map(({ authorized, ..._ }) => _);

  return (
    <div className="affix-footer" >
      <Dropdown
        menu={{
          items: trimed,
          onClick: ({ key }) => {
            if (enableTypes.includes(key as string)) {
              setShowFeedbackType(key as FeedbackType);
            }
          },
        }}
        placement="top"
        arrow={false}
        trigger={['click']}
        overlayStyle={{
          zIndex: zIndex + 1,
        }}
      >
        <QuestionCircleOutlined
          onClick={() => setShowMenu(!showMenu)}
        />
      </Dropdown>
      {showFeedbackType && (<FeedbackForm
        zIndex={zIndex + 1}
        type={showFeedbackType}
        onCancelCallback={() => setShowFeedbackType(undefined)}
        ipAddress={ipAddress}
      />)}
    </div>
  );
};
