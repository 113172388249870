import React, { ReactElement } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import LineChart from "./LineChart";


export const LineChartDetailPanel = (props: DetailPanelProps): ReactElement => {
  const { fieldValue, record, column } = props;
  const { key } = column;

  return <LineChart
    value={fieldValue ?? record?.[key]}
    column={column}
  />;
};

export default LineChartDetailPanel;
