import React, { ReactElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';
import i18n from "i18next";
import Comment from "./Comment";
import { DomainComment } from "./CommentProps";
import { RecordProps, TableMetaProps } from "@props/RecordProps";
import { Empty } from "antd";

interface CommentsListProps {
  owner: RecordProps;
  comments: DomainComment[];
  updateCallback: (data: DomainComment) => void;
  fetchDataCallback: () => void;
  mainDomainName: string;
  column: TableMetaProps;
  zIndex: number;
  objectTypeId: number;
  highlightCommentId?: number;
}

const CommentsList = (props: CommentsListProps): ReactElement => {
  const {
    owner, comments, updateCallback, fetchDataCallback, mainDomainName,
    column, zIndex, objectTypeId, highlightCommentId
  } = props;

  const locale: string = (i18n.language === "zh") ? "zh-cn" : i18n.language;
  dayjs.locale(locale);
  const [rootComments, setRootComments] = useState<DomainComment[]>([]);

  useEffect(() => {
    // Group the comments and put those has empty replyTo to a list
    const groupedComments: DomainComment[] = [];
    comments.forEach((comment: DomainComment) => {
      if (comment.replyTo) {
        const parent: DomainComment | undefined = comments.find((c: DomainComment) => c.id === comment.replyTo?.id);
        if (parent) {
          if (!parent.replies) {
            parent.replies = [];
          }
          parent.replies.push(comment);
        }
      } else {
        groupedComments.push(comment);
      }
    });
    // Sort the comments and put the one with id equals to highlightCommentId to the top
    if (highlightCommentId) {
      groupedComments.sort((a: DomainComment, b: DomainComment) => {
        if (a.id === highlightCommentId) {
          return -1;
        }
        if (b.id === highlightCommentId) {
          return 1;
        }
        return 0;
      });
    }
    setRootComments(groupedComments);
  }, [comments, highlightCommentId]);

  const shouldHighlight = (id: number): boolean => (highlightCommentId === id);
  const stripedClassName = (idx: number): string => (idx % 2 === 0) ? "comment-list-item-even" : "comment-list-item-odd";
  const highlightClassName = (id: number): string => (shouldHighlight(id)) ? "highlight-comment" : "";
  return (rootComments.length === 0) ? (<Empty />) : (
    <div className="comment-list">{
      rootComments.map((comment: DomainComment, idx: number) => (
        <Comment
          className={`${stripedClassName(idx)} ${highlightClassName(comment.id)}`}
          owner={owner}
          comment={comment}
          key={comment.id}
          updateCallback={updateCallback}
          fetchDataCallback={fetchDataCallback}
          mainDomainName={mainDomainName}
          column={column}
          zIndex={zIndex}
          objectTypeId={objectTypeId}
          highlight={shouldHighlight(comment.id)}
        />
      ))
    }
    </div>
  );
};

export default CommentsList;
