import { getUserId } from "@utils/TokenUtils";
import { Button, Input } from "antd";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringUtils } from "@utils/index";
import { openErrorNotification, openInfoNotification } from "@utils/NotificationUtils";
import { createRelatedDomainObject } from "@utils/FetchUtils";
import CustomAvatar from "../avatar/CustomAvatar";
import { RecordProps, TableMetaProps } from "@props/RecordProps";
import { DomainComment } from "./CommentProps";
import { SendOutlined } from '@ant-design/icons';

export interface CommentFormProps {
  zIndex: number;
  mainDomainName: string;
  owner: RecordProps;
  objectTypeId: number;
  column: TableMetaProps;
  fetchDataCallback?: () => void;
  replyTo?: DomainComment;
}

const CommentForm = (props: CommentFormProps): ReactElement => {
  const {
    zIndex, mainDomainName, column, fetchDataCallback, owner, replyTo,
    objectTypeId
  } = props;
  const { t } = useTranslation();
  const [content, setContent] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const placeholder = t("Please input comments here, press Ctrl + Enter to save it");

  const submitMessage = useCallback((): void => {
    if (!StringUtils.isBlank(content)) {
      if (saving) {
        return;
      }
      setSaving(true);
      createRelatedDomainObject({
        columnName: column.key,
        object: {
          content,
          status: "NEW",
          replyTo: replyTo?.id ?? undefined,
          objectId: owner.id,
          objectType: objectTypeId,
        },
        mainDomainName: mainDomainName,
        mainDomainId: owner.id,
        relatedDomainName: "tech_muyan_comment_DomainComment",
      }).then(() => {
        setContent("");
        openInfoNotification(t("Comment saved"));
        fetchDataCallback?.();
      }).catch((e) => {
        openErrorNotification(t("Error"), e.message);
      }).finally(() => setSaving(false));
    }
  }, [
    column.key, content, mainDomainName, fetchDataCallback,
    owner.id, saving, t, replyTo, objectTypeId
  ]);

  const keydownHandler = useCallback((e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && e.ctrlKey && !StringUtils.isBlank(content)) {
      submitMessage();
    }
  }, [submitMessage, content]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    document.addEventListener('keydown', keydownHandler);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [keydownHandler]);

  const avatarSize = (replyTo != null) ? undefined : "large";

  return (
    <div style={{ zIndex }} className="comment-form-container">
      <CustomAvatar
        userId={Number.parseInt(getUserId() ?? "")}
        size={avatarSize}
      />
      <div className="comment-input-container">
        <Input.TextArea
          placeholder={placeholder}
          className="text"
          autoSize={{ minRows: 3 }}
          onChange={(e) => setContent(e.target.value)}
          value={content}
          autoFocus={true}
        />
        <Button
          value="Submit"
          size="small"
          onClick={submitMessage}
          loading={saving}
          icon={<SendOutlined />}
        >
          {t('Submit')}
        </Button>
      </div>
    </div>
  );
};

export default CommentForm;
