import { Popover, Space, TablePaginationConfig } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { RECORD_PER_PAGE_OPTIONS } from '@config/base';
import { PaginationProps } from '@props/RecordProps';

export const getTablePagination = (props: PaginationProps): TablePaginationConfig => {
  const { current, total, pageSize, showLessItems, showQuickJumper, showSizeChanger, onChange, instruction } = props;
  return {
    defaultCurrent: 1,
    position: ["bottomCenter"],
    size: "small",
    current,
    total,
    pageSize,
    showLessItems: showLessItems ?? false,
    showSizeChanger: showSizeChanger ?? true,
    showQuickJumper: showQuickJumper ?? true,
    showTotal: (total: number, range: Array<number>) => {
      return (<span className="list-paginiation-count" >
        <Popover
          title={undefined}
          content={<Space direction="vertical" style={{ minWidth: '400px' }}>
            <Trans
              i18nKey="PaginationMsg"
              values={{ start: range[0], end: range[1], total }}
            >
            </Trans>
            <>{instruction}</>
          </Space>
          }>
          <InfoCircleOutlined />
        </Popover>
      </span>);
    },
    responsive: true,
    pageSizeOptions: RECORD_PER_PAGE_OPTIONS,
    onChange
  };
};

export const getPagination = getTablePagination;
