import React, { ReactElement, useEffect, useState } from "react";
import { DetailPanelProps } from "@props/RecordProps";
import SubTableForm from "./SubTableForm";
import { registerOnSavedCallback } from "@utils/FormUtils";


export const SubTableDetailPanel = (props: DetailPanelProps): ReactElement => {
  const {
    column, readonly, form, domainName, record,
    zIndex, saveOptions, isCurrentActiveTab, onValuesChange, path,
  } = props;
  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    return registerOnSavedCallback(form, (res) => {
      if (res.status === 'success') {
        setKey(Date.now());
      }
    });
  }, [form]);
  return (
    <SubTableForm
      key={key}
      column={column}
      owner={record}
      editMode={!readonly}
      ownerClass={domainName}
      zIndex={zIndex}
      form={form}
      saveOptions={saveOptions}
      isCurrentActiveTab={isCurrentActiveTab}
      onValuesChange={onValuesChange}
      path={path}
    />
  );
};