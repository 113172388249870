import { SERVER_URL } from '@config/base';
import { BaseApiResponse, DynamicFilterResponseProps, InternalServerErrorResp } from '@props/RecordProps';
import { requestUrlAndGetPromise } from '@utils/FetchUtils';

interface Organization {
  id: number;
}

interface Owner {
  id: string | null;
}

export interface SaveFilterProps {
  id?: number;
  label: string;
  isDefault: boolean;
  conditions: string;
}

export interface CreateFilterProps extends SaveFilterProps {
  name: string;
  label: string;
  isSystem: boolean;
  organization: Organization;
  owner: Owner;
  enableRoles: string;
}

export interface UpdateFilterProps extends SaveFilterProps {
  id: number;
  label: string;
  isDefault: boolean;
}

export const saveFilter = async (saveFilterProps: {
  value: SaveFilterProps;
  domainName: string;
  successCallback: () => void;
  failCallback: (error: unknown) => void;
  finallyCallback: () => void;
}): Promise<void> => {
  const {
    value, domainName, failCallback, successCallback, finallyCallback
  } = saveFilterProps;
  const mode = (value.id === undefined) ? "create" : "edit";
  const isCreate = (mode === "create");
  const baseUrl = `${SERVER_URL}/filter`;
  const url = isCreate ? `${baseUrl}/${domainName}` : `${baseUrl}/${value.id}`;
  const method = isCreate ? "POST" : "PUT";

  try {
    const response: BaseApiResponse<DynamicFilterResponseProps> = await requestUrlAndGetPromise(url, {
      method,
      body: JSON.stringify(value),
    });

    if (!response.success) {
      throw new Error(response.message);
    }

    console.log('Filter saved successfully:', response.data);
    successCallback();
  } catch (error: unknown) {
    console.error('Error while saving filter:', error);
    failCallback(error as InternalServerErrorResp);
  } finally {
    finallyCallback();
  }
};

