import React, { ReactElement } from 'react';
import {
    Route,
    useParams,
    NavigateFunction
} from 'react-router-dom';

import FirstPage from "../FirstPage";
import { LoginPage } from "../security";
import { loggedIn } from "@utils/LoginUtils";
import SwaggerApp from '../development/SwaggerApp';

import {
    ListComponent, EmptyPage,
    MasterDetailListComponent, FullTextSearchListComponent, WizardAction
} from '../form';
import RevisionCompare from "../components/revisionCompare/RevisionCompare";
import { getParamsFromUrl } from "@utils/StringUtils";
import { AccountPage } from "../security";
import { MessageDomainName } from '@config/domain';
import { GanttComponentWrap } from "../form/gantt";
import { CardListComponent } from '../form/cardList';

export type MenuFormType = "CREATE" | "DISPLAY" | "UPDATE" | "LIST" | "WIZARD"
  | "DELETE" | "MASTER_DETAIL_LIST" | "FULL_TEXT_SEARCH_LIST"
  | "DISPLAY" | "DELETE" | "CARD_LIST" | "GANTT";

export interface MenuFormProp {
  id: number;
  domainName: string;
  type: MenuFormType;
}

export interface DynamicMenuProps {
  id: number;
  label: string;
  form?: MenuFormProp;
  icon: string;
  type: "MENU_GROUP" | "EXTERNAL_LINK" | "FORM" | "INTERNAL_LINK";
  link?: string;
  children?: Array<DynamicMenuProps>;
}

function DisplayWizard(): ReactElement {
    const { wizardId, stepId } = useParams<{
        wizardId: string;
        stepId?: string;
    }>();
    const currentUrl = window.location.href;
    const queryParameters = getParamsFromUrl(currentUrl);
    return (wizardId == null) ? (<></>) : (<WizardAction
        wizardId={parseInt(wizardId)}
        //FIXME fix the default stepId to -1 issue
        stepId={parseInt(stepId ?? "-1")}
        visibleSeed={Math.random()}
        zIndex={1}
        queryParameters={queryParameters}
    />);
}

function CreateChild(): ReactElement {
    const { domainName } = useParams<{
        domainName: string;
        formId?: string;
    }>();
    return (domainName == null) ? (<></>) : (<div>
        <ListComponent
            domainName={domainName}
            tableMode="table-list"
            inline={false}
            initShowCreateModal={true}
            zIndex={0}
        />
    </div>);
}

function ListChild(): ReactElement {
    const { domainName, formId } = useParams<{
        domainName: string;
        formId: string;
    }>();
    return (domainName == null) ? (<></>) : (<div>
        <ListComponent
            key={`${domainName}_${formId}`}
            domainName={domainName}
            tableMode="table-list"
            inline={false}
            zIndex={0}
            formId={formId ? parseInt(formId) : undefined}
        />
    </div>);
}

function MasterDetailListChild(): ReactElement {
    const { domainName, formId } = useParams<{
        domainName: string;
        formId: string;
    }>();
    return (domainName == null || formId == null) ? (<>formId or domainName is null</>) : (<MasterDetailListComponent
        formId={parseInt(formId)}
        domainName={domainName}
        zIndex={0}
    />);
}

function FullTextSearchListChild(): ReactElement {
    const { domainName, formId } = useParams<{
        domainName: string;
        formId: string;
    }>();
  //FIXME fix the default setOwnerIds to emptyMethod issue
  return (domainName == null || formId == null) ? (<>formId or domainName is null</>) : (<FullTextSearchListComponent
    formId={parseInt(formId)}
    domainName={domainName}
    zIndex={0}
  />);
}

function UpdateChild(): ReactElement {
    const { domainName, id } = useParams<{
        domainName: string;
        id: string;
        formId?: string;
    }>();
    return (domainName == null || id == null) ? (<>id or domainName is null</>) : (<div>
      <ListComponent
        domainName={domainName}
        tableMode="table-list"
        inline={false}
        initDomainId={parseInt(id)}
        initDomainPage="edit"
        zIndex={0}
      />
    </div>);
}

function DisplayChild(): ReactElement {
    const { domainName, id, formId } = useParams<{
        domainName: string;
        id?: string;
        formId?: string;
    }>();
    return (id == null || domainName == null) ? (<>id is null</>) : (
      <div>
        <ListComponent
          formId={formId == null ? undefined : parseInt(formId)}
          domainName={domainName}
          initDomainId={parseInt(id)}
          initDomainPage="detail"
          readonly={true}
          zIndex={0}
          tableMode="table-list"
          inline={false}
        />
      </div>
    );
}

function DiffChild(): ReactElement {
    const { domainName, sourceId, targetId, columnName } = useParams<{
        domainName?: string;
        sourceId?: string;
        targetId?: string;
        columnName?: string;
    }>();
    const s: number = parseInt(sourceId ?? "-1");
    const t: number = parseInt(targetId ?? "-1");
    return (<RevisionCompare
        domainName={domainName ?? ""}
        sourceId={(s === -1) ? undefined : s}
        targetId={t}
        columnName={columnName ?? ""}
    />);
}

function MessageDetail(): ReactElement {
    const { id } = useParams<{
        id: string;
    }>();
    return (id == null) ? (<>id is null</>) : (
      <MasterDetailListComponent
        domainName={MessageDomainName}
        zIndex={0}
        initSelectedId={parseInt(id)}
      />
    );
}

function GanttChild(): ReactElement {
    const { formId, tableId } = useParams<{
        domainName?: string;
        formId?: string;
        tableId?: string;
    }>();
    return <GanttComponentWrap
      formId={parseInt(formId ?? "-1")}
      tableId={parseInt(tableId ?? "-1")}
      zIndex={0}
    />;
}

const CardListChild = (): ReactElement => {
  return (
    <CardListComponent
      domainName={MessageDomainName}
      zIndex={0}
      inline={false}
      tableMode={'card-list'}
    />
  );
};

const keyPrefix = new Date().getTime().toString();

export type RouterConfig = {
  path: string;
  key: string;
  element: ReactElement;
  formType?: MenuFormType;
  getMenuLink?: (form: MenuFormProp) => string;
};

export const formRouterConfig: Array<RouterConfig> = [
  {
    path: `/:formId/:domainName/create`,
    key: `${keyPrefix}_create`,
    element: <CreateChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/create`,
    formType: "CREATE"
  },
  {
    path: `/:formId/:domainName/:id/update`,
    key: `${keyPrefix}_update`,
    element: <UpdateChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/${form.id}/update`,
    formType: "UPDATE"
  },
  {
    path: `/:formId/:domainName/list`,
    key: `${keyPrefix}_${Math.random().toString()}_list`,
    element: <ListChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/list`,
    formType: "LIST"
  },
  {
    path: `/:formId/:domainName/mdList`,
    key: `${keyPrefix}__mdList`,
    element: <MasterDetailListChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/mdList`,
    formType: "MASTER_DETAIL_LIST"
  },
  {
    path: `/:formId/:domainName/wsList`,
    key: `${keyPrefix}__wsList`,
    element: <FullTextSearchListChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/wsList`,
    formType: "FULL_TEXT_SEARCH_LIST"
  },
  {
    path: `/:formId/:domainName/cardList`,
    key: `${keyPrefix}__cardList`,
    element: <CardListChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/cardList`,
    formType: "CARD_LIST"
  },
  {
    path: `/wizard/:wizardId`,
    key: `${keyPrefix}_wizard`,
    element: <DisplayWizard />,
    getMenuLink: (form: MenuFormProp) => `/wizard/${form.id}`,
    formType: "WIZARD"
  },
  {
    path: `/:formId/:domainName/gantt`,
    key: `${keyPrefix}_${Math.random().toString()}_gantt`,
    element: <GanttChild />,
    getMenuLink: (form: MenuFormProp) => `/${form.id}/${form.domainName}/gantt`,
    formType: "GANTT"
  },
];

const RoutersConfig = (props: { type: "private" | "public", navigate: NavigateFunction }): Array<ReactElement> => {
  const { type, navigate } = props;

  const loginRouterConfig = { path: "/login", key: "login", element: <LoginPage next="/" /> };
  const startRouterConfig = { path: "/start", key: "start", element: <LoginPage next="/" firstTime={true} /> };
  const accountRouterConfig = { path: "/account", key: "account", element: <AccountPage /> };

  const publicRouters: Array<RouterConfig> = [loginRouterConfig, startRouterConfig, accountRouterConfig];
  const publicPath = publicRouters.map((r) => r.path);
  const mainPage = (<>
    {loggedIn() && <FirstPage />}
    {!loggedIn() && !publicPath.find(p => p === window.location.pathname) && navigate("/login")}
  </>);
  // https://stackoverflow.com/questions/52916289/react-does-not-recognize-the-computedmatch-prop-on-a-dom-element
  // ATTENTION: router definition for router should before
  // Routers defined using DisplayChild, otherwise this one
  // will be matched instead of the router one.
  const privateRouters: Array<RouterConfig> = [
    loginRouterConfig,
    startRouterConfig,
    accountRouterConfig,
    ...formRouterConfig,
    { path: "/swagger/api", key: `${keyPrefix}_swagger`, element: <SwaggerApp /> },
    { path: `/wizard/:wizardId/:stepId`, key: `${keyPrefix}_wizard`, element: <DisplayWizard /> },
    { path: "/", key: "main", element: mainPage },
    { path: "/logout", key: "logout", element: <LoginPage next="/logout" /> },
    { path: `/:formId/:domainName/index`, key: `${keyPrefix}_${Math.random().toString()}_index`, element: <ListChild /> },
    { path: `/:domainName/list`, key: `${keyPrefix}_${Math.random().toString()}_list`, element: <ListChild /> },
    { path: `/:formId/:domainName/:id/show`, key: `${keyPrefix}_update`, element: <DisplayChild /> },
    { path: `/:domainName/create`, key: `${keyPrefix}_create`, element: <CreateChild /> },
    { path: `/:domainName/:id/update`, key: `${keyPrefix}_update`, element: <UpdateChild /> },
    { path: `/:domainName/:id/show`, key: `${keyPrefix}_display`, element: <DisplayChild /> },
    { path: `/:domainName/:id/:formId`, key: `${keyPrefix}_display`, element: <DisplayChild /> },
    { path: `/:domainName/:id`, key: `${keyPrefix}_display`, element: <DisplayChild /> },
    { path: "/inbox", key: `${keyPrefix}_inbox`, element: <MasterDetailListComponent domainName={MessageDomainName} zIndex={0} /> },
    { path: "/inbox/:id", key: `${keyPrefix}_inbox_detail`, element: <MessageDetail /> },
    { path: "/emptyPage", key: `${keyPrefix}_emptyPage`, element: <EmptyPage /> },
    { path: "/diff/:domainName/:sourceId/:targetId/:columnName", key: `${keyPrefix}_diff`, element: <DiffChild /> },
    { path: "/diff/:domainName/:targetId/:columnName", key: `${keyPrefix}_diff`, element: <DiffChild /> },
  ];
  return (type == "private" ? privateRouters : publicRouters).map((r): ReactElement =>
    <Route
      path={r.path}
      key={r.key}
      element={r.element}
    />
  );
};

export default RoutersConfig;
