import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Badge, Card, Col, Row, Space, Statistic } from 'antd';
import {
  MinusSquareOutlined, PlusSquareOutlined, CheckSquareOutlined, AppstoreOutlined,
  WarningOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CsvOperateComponentPanelMaxWidth, CustomIcon } from '@config/base';
import { fetchFormIdAndExtInfoByType } from '@utils/FetchUtils';
import { random } from 'lodash';
import { ExpandableContentComponent } from '../../components';
import ObjectPopup from './ObjectPopup';

export type ImportRecordProps = {
  insertedIds: string;
  updatedIds: string;
  deletedIds: string;
  noUpdateIds: string;
  conflictIds: string;
  dbUpdatedCsvNoUpdateIds: string;
  failedLines: string;
  skippedLines: string;
  conflictLines: string;
  logs: string;
  status: "SUCCESS" | "PARTIALLY_FAIL" | "FAILED" | "RUNNING" | "NO_DATA_IMPORTED" | "NOT_START";
  insertedRecord: number;
  failedRecord: number;
  noUpdateRecord: number;
  skippedRecord: number;
  updatedRecord: number;
  deletedRecord: number;
  conflictRecord: number;
  dbUpdatedCsvNoUpdateRecord: number;
};

const statusMapping = [
  { value: "SUCCESS", label: "has succeed" },
  { value: "PARTIALLY_FAIL", label: "has partially failed" },
  { value: "FAILED", label: "has failed" },
  { value: "RUNNING", label: "is still running" },
  { value: "NO_DATA_IMPORTED", label: " imported nothing" },
  { value: "NOT_START", label: "was not started" },
];

const statusToAlertTypeMapping = {
  SUCCESS: "success",
  PARTIALLY_FAIL: "warning",
  FAILED: "error",
  RUNNING: "info",
  NO_DATA_IMPORTED: "warning",
  NOT_START: "error"
} as { [id: string]: string };

export type AlertStatus = "success" | "warning" | "error" | "info" | "default" | undefined;

const colElem = (title: string, value: number, status: AlertStatus, col?: number): ReactElement => (
  <Col span={col ?? 6}>
    <Card className="import-record-card">
      <Statistic
        title={title}
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        prefix={<Badge status={status} />}
      />
    </Card>
  </Col>
);

const ImportRecordComponent = (props: {
  domainName: string;
  importRecord: ImportRecordProps;
  uploaded: boolean;
  defaultMaxWidth?: string;
  zIndex: number;
}): ReactElement => {
  const { importRecord, uploaded, domainName, defaultMaxWidth, zIndex } = props;
  const { t } = useTranslation();

  const statusObj = statusMapping.filter(s => s.value === importRecord?.status);
  const displayMsg = (statusObj.length > 0) ? statusObj[0].label : "";
  const type = ((statusObj.length > 0) ?
    statusToAlertTypeMapping[statusObj[0].value] : "warning") as AlertStatus;
  const [formId, setFormId] = useState<number>(-1);

  useEffect(() => {
    fetchFormIdAndExtInfoByType(domainName, "Update").then((json) => {
      setFormId(json.id);
    }).catch(e => {
      console.error(`Failed to get Update form of domain ${domainName}: ${e}`);
    });
  }, [domainName, formId]);

  const expandableElem = (
    title: string, content: string,
    showDownload: boolean,
    showAsTag: boolean,
    icon?: ReactElement
  ): ReactElement | undefined => {
    return (content != null && content.length > 0) ?
      (<ExpandableContentComponent
        content={showAsTag ? (
          <div>
            {content.split(',').map(id => (
              <ObjectPopup
                domainName={domainName}
                id={id}
                formId={formId}
                key={id}
                zIndex={zIndex + 1}
              />
            ))}
          </div>
        ) : content}
        // Use random to generate a random number,
        // This is to auto open the import log section if it has content
        // everytime when a new upload finishes
        initDisplay={uploaded ? random(0, 1) : -1}
        title={title}
        icon={icon}
        breakLine={!showDownload}
        showDownload={showDownload}
      />) : undefined;
  };

  const maxWidth = defaultMaxWidth ?? CsvOperateComponentPanelMaxWidth;

  const {
    deletedIds, updatedIds, failedLines, insertedIds, conflictIds, logs,
    dbUpdatedCsvNoUpdateIds
  } = importRecord;

  return (
    <div style={{
      margin: "auto",
      width: "100%",
      maxWidth
    }}>
      <Space size="small"
        direction="vertical"
        className="expandable-content-space"
      >
        <ExpandableContentComponent
          customStyle={{ marginTop: "8px" }}
          fixHeight={242}
          // Use random to generate a random number,
          // This is to auto open the import summary
          //everytime when a new upload finishes
          initDisplay={uploaded ? random(0, 1) : -1}
          icon={<AppstoreOutlined />}
          content={
            <div style={{ marginTop: "10px" }}>
              <Alert
                message={
                  t('ImportSummary', {
                    domainTitle: t(`status:${type}` ?? ""),
                    displayMsg: t(`status:${displayMsg}`)
                  })}
                showIcon={true}
                type={(type === 'default') ? 'info' : type}
                closable={false}
                className="expandable-content-alert"
              />
              <Row gutter={16}>
                {colElem(t("Inserted"), importRecord?.insertedRecord, "success")}
                {colElem(t("Updated"), importRecord?.updatedRecord, "success")}
                {colElem(t("Deleted"), importRecord?.deletedRecord, "warning")}
                {colElem(t("No changes"), importRecord?.noUpdateRecord, "default")}
              </Row>
              <Row gutter={16}>
                {colElem(t("Conflicted"), importRecord?.conflictRecord, "error")}
                {colElem(t("Failed"), importRecord?.failedRecord, "error")}
                {colElem(t("Db updated csv no update"), importRecord?.dbUpdatedCsvNoUpdateRecord, "default")}
                {colElem(t("Skipped"), importRecord?.skippedRecord, "error")}
              </Row>
            </div>
          }
          title={t("Import summary")}
          breakLine={true}
          showDownload={false}
        />
        {conflictIds && expandableElem(t("Conflicted records"),
          conflictIds, false, true, <ExclamationCircleOutlined />)}
        {deletedIds && expandableElem(t("Deleted records"),
          deletedIds, false, true, <MinusSquareOutlined />)}
        {updatedIds && expandableElem(t("Updated records"),
          updatedIds, false, true, <CheckSquareOutlined />)}
        {insertedIds && expandableElem(t("Inserted records"),
          insertedIds, false, true, <PlusSquareOutlined />)}
        {dbUpdatedCsvNoUpdateIds && expandableElem(t("Db updated csv no update records"),
          dbUpdatedCsvNoUpdateIds, false, true, <CheckSquareOutlined />)}
        {failedLines && expandableElem(t("Import failed lines"),
          failedLines, true, false, <WarningOutlined />)}
        {logs && expandableElem(t("Import error logs"),
            logs, true, false, <CustomIcon type="icon-icon_log" />)}
        </Space>
    </div >
  );
};

export default ImportRecordComponent;
