import React, { ReactElement } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CloseIcon = (props: {
  onClick: (visible: boolean) => void;
  className?: string;
  noLinkWrap?: boolean
}): ReactElement => {
  const { onClick, className, noLinkWrap } = props;
  const { t } = useTranslation();
  return (
    <span
      title={t("Close this panel")}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onClick(false);
      }}
      className={className}
    >
      {noLinkWrap &&
        <CloseCircleOutlined
          className="close-icon"
        />
      }
      {!noLinkWrap && <a href="#">
        <CloseCircleOutlined
          className="close-icon"
        />
      </a>
      }
    </span>
  );
};

export default CloseIcon;
