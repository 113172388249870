import { RecordProps, TableMetaProps } from "@props/RecordProps";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { Button, ButtonProps, Form, Popconfirm, Space } from "antd";
import { DeleteOutlined, DownOutlined, FormOutlined, SaveOutlined, UpOutlined } from "@ant-design/icons";
import { getValuePropName } from "@kernel/EditableComponentsMapping";
import { DisplayRenderProps, EditableContext, EditableRenderProps } from "./SubTableForm";
import { useTranslation } from "react-i18next";
import { ArrayColumnOption, markRecordEdited, markRecordRemoved } from "./SubTableUtils";
import { DomainPermitState } from "@utils/DomainPermitUtils";

export interface SubTableCellProps {
  column?: TableMetaProps;
  record: RecordProps;
  editMode: boolean;
  // form: FormInstance;
  clicked?: number;
  setClickRef: React.MutableRefObject<(clicking: (number | undefined)) => void>;
  setData: Dispatch<SetStateAction<RecordProps[]>>;
  arrayColumnOption: ArrayColumnOption;
  updatable?: boolean;
  deletable?: boolean;
  sortKey?: string;
  domainPermit?: DomainPermitState;
  DisplayRender: React.FC<DisplayRenderProps>;
  EditableRender?: React.FC<EditableRenderProps>;
  sortable: boolean;
}

export const SubTableCell: React.FC<SubTableCellProps> = (props: SubTableCellProps) => {
  const {
    column, record, editMode, setData,
    clicked, setClickRef, sortKey,
    arrayColumnOption, updatable, deletable, domainPermit,
    DisplayRender, EditableRender, sortable,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = useContext(EditableContext)!;

  const { t } = useTranslation();

  // console.log(`Cell ${record?.id}-${column?.key} refreshed`, sortable);

  if (!record) {
    return <></>;
  }

  const rowInEditing = clicked === record.id;

  const commonAttrs = {
    type: "default",
    shape: "circle",
    size: "small",
  } as ButtonProps;

  if (!column) {
    const canSort = sortable && domainPermit?.canUpdate && editMode;
    // If user set deletable to true specific, then it can be deleted, because delete also use to remove it from the table
    // And whether to delete it from DB is decided by backend logic
    const canDelete = (domainPermit?.canDelete && deletable) || ((domainPermit?.canDelete === false) && (deletable === true));
    const canUpdate = editMode && domainPermit?.canUpdate && updatable;
    return (
      <td
        className="sub-table-operation-cell"
      >
        <Space direction="horizontal">
          {canUpdate && <Button
            title={rowInEditing ? t("Click to save current line") : t("Click to edit current line")}
            disabled={!editMode}
            {...commonAttrs}
            icon={rowInEditing ? <SaveOutlined/> : <FormOutlined/>}
            onClick={() => setClickRef.current(rowInEditing ? undefined : record.id)}
          />}
          {canDelete &&
            <Popconfirm
              title={t("Delete current object")}
              onConfirm={() => {
                setData((prevState) => {
                  const newData = [...prevState];
                  const index = newData.findIndex((item) => record.id === item.id);
                  markRecordRemoved(arrayColumnOption, record);
                  newData.splice(index, 1);
                  return newData;
                  });
                }}
              onCancel={() => {
                // Do nothing on cancel
              }}
              okText={t("Confirm")}
              cancelText={t("Cancel")}
            >
              <Button
                disabled={!editMode}
                {...commonAttrs}
                icon={<DeleteOutlined/>}
                title={t("Delete current object")}
              />
            </Popconfirm>
          }
          {canSort && <Button
            {...commonAttrs}
            icon={<UpOutlined />}
            title={t("Click to move this line up")}
            onClick={() => setData((prevState) => {
              markRecordEdited(arrayColumnOption, record);
              const index = prevState.findIndex((item) => record.id === item.id);
              if (index === 0) {
                return prevState;
              }
              const newData = [...prevState];
              const r = newData[index];
              newData[index] = newData[index - 1];
              newData[index - 1] = r;
              if (sortKey) {
                const s = newData[index][sortKey];
                newData[index][sortKey] = newData[index - 1][sortKey];
                newData[index - 1][sortKey] = s;
              }
              return newData;
            })}
          />}
          {canSort && <Button
            {...commonAttrs}
            icon={<DownOutlined />}
            title={t("Click to move this line down")}
            onClick={() => setData((prevState) => {
              markRecordEdited(arrayColumnOption, record);
              const index = prevState.findIndex((item) => record.id === item.id);
              if (index === prevState.length - 1) {
                return prevState;
              }
              const newData = [...prevState];
              const r = newData[index];
              newData[index] = newData[index + 1];
              newData[index + 1] = r;
              if (sortKey) {
                const s = newData[index][sortKey];
                newData[index][sortKey] = newData[index + 1][sortKey];
                newData[index + 1][sortKey] = s;
              }
              return newData;
            })}
          />}
        </Space>
      </td>);
  }
  const { key, type } = column;

  // console.log(`Cell ${record.id}-${column?.key} refreshed`, record[key]);

  return (<td className={`sub-table-td-${column.type} sub-table-td-${column.key}`}>
    <Form.Item
      // label={title}
      name={key}
      key={key}
      // rules={rules}
      valuePropName={getValuePropName(type)}
      // className={`form-field-type-${type.toLowerCase()} ${isHighlightField ? "highlight-field" : `form-item-${key}`}`}
      style={{
        marginBottom: 0,
      }}
    >
      {rowInEditing && (column.updatable || record.id < 0) && EditableRender
        ? EditableRender({ form, record })
        : <DisplayRender record={record} />}
    </Form.Item>
  </td>);
};
