import React, { ReactElement, Suspense } from "react";
import { DetailPanelProps } from "@props/RecordProps";

const CodeEditor = React.lazy(() => import('./CodeEditor'));

const CodeEditorDetail = (props: DetailPanelProps): ReactElement => {
  const { column, readonly, record, form, zIndex, onValuesChange, fieldValue } = props;
  const { key, updatable, extInfo } = column;
  const value = fieldValue ?? record?.[key];
  const combUpdatable = ((updatable !== false && record?.id != null) && !readonly) || (record?.id == null);
  return (<Suspense fallback={<div />}>
    <CodeEditor
      value={value}
      onChange={(val) => {
        const changedValue = {[key]: val};
        const newValue = {...record, ...changedValue};
        form.setFieldsValue(changedValue);
        onValuesChange?.(changedValue, newValue);
      }}
      name={key}
      updatable={combUpdatable}
      style={{ width: "99%", margin: "auto" }}
      mode={extInfo?.codeLanguage}
      zIndex={zIndex}
      record={record}
    />
  </Suspense>
  );
};

export default CodeEditorDetail;
