import React, { ReactElement, useEffect, useState } from "react";
import { ProfileOutlined } from '@ant-design/icons';
import { DetailPanelProps, SaveRecordProps } from "@props/RecordProps";
import { ListComponent, ObjectsDetailList } from "../../form";
import { Drawer } from "antd";
import {
  fetchFormIdAndExtInfoByType,
  fetchFormIdAndExtInfoByName
} from "@utils/FetchUtils";
import { CloseIcon, LargeSpin } from '../../components';
import { getLabelToDisplay } from "@utils/ObjectUtils";
import { capitalizeFirstLetter, typeWithPackageToSimpleType } from "@utils/StringUtils";
import { useTranslation } from 'react-i18next';

const RelatedObjectsCell = (props: DetailPanelProps): ReactElement => {
  const { ownerClass, column, record, zIndex } = props;
  const { elementType, extInfo, type, fieldType } = column;
  const [formId, setFormId] = useState<number>();
  const [visible, setVisible] = useState<boolean>();
  const { t } = useTranslation();
  useEffect(() => {
    // visible === true 才请求后台是避免在不必要的情况下(本控件还没显示就请求了)
    // formId == null 才请求是为了避免重复请求
    if (elementType != null && formId == null && visible === true) {
      if (extInfo?.displayForm != null) {
        fetchFormIdAndExtInfoByName(elementType, extInfo?.displayForm).then(json => setFormId(json.id));
      } else {
        fetchFormIdAndExtInfoByType(elementType, "List").then(json => setFormId(json.id));
      }
    }
  }, [formId, elementType, extInfo, visible]);

  if (elementType == null || record == null) {
    return (<div title='related-objects-empty-element-type' />);
  }

  const simpleOwnerClass: string = capitalizeFirstLetter(typeWithPackageToSimpleType(ownerClass));
  const ownerClassTitle = t(`domainTitle:${simpleOwnerClass}`);
  const columnTitle = t(`field:${simpleOwnerClass}.${column.key}`);
  const objLabel = getLabelToDisplay(record ?? {} as SaveRecordProps, undefined);
  const drawerTitle = `${ownerClassTitle} [${objLabel}(${record?.id})] ${columnTitle}`;
  const detailComponent = (type === 'arrayDetail') ? (
    <ObjectsDetailList
      domainName={elementType}
      zIndex={zIndex}
      record={record}
      column={column}
      ownerClass={ownerClass}
    />
  ) : (
    <ListComponent
      domainName={elementType}
      columnNameInOwnerClass={column.key}
      ownerClass={ownerClass}
      ownerId={record?.id}
      tableMode="detail-drawer"
      inline={true}
      zIndex={zIndex + 1}
      formId={formId}
      fieldType={fieldType}
    />);
  const position = (type === 'arrayDetail') ? 'right' : 'bottom';
  const width = (type === 'arrayDetail') ? '75%' : '100%';
  return (<>
    <ProfileOutlined onClick={() => setVisible(!visible)} />
    <div onClick={(e) => e.stopPropagation()} title='related-objects'>
      <Drawer
        destroyOnClose={true}
        rootClassName={`related-objects-cell related-objects-cell-${type}`}
        placement={position}
        title={drawerTitle}
        open={visible}
        closable={true}
        onClose={() => setVisible(false)}
        closeIcon={<CloseIcon onClick={setVisible} />}
        height="80%"
        width={width}
        zIndex={zIndex + 2}
        push={false}
      >
        {formId ? detailComponent : <LargeSpin />}
      </Drawer>
    </div>
  </>);
};

export default RelatedObjectsCell;
