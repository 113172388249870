import { SERVER_URL } from '@config/base';
import { requestUrlAndGetPromise } from '@utils/FetchUtils';
import { useEffect, useState } from 'react';

type ConfigValueTypes = string | number | boolean;

export interface ConfigResponseProps {
  key: string;
  value: ConfigValueTypes;
  type: string;
}

export type ConfigKey = keyof typeof configKeyToTypeMap;
export type ConfigType = typeof configKeyToTypeMap[ConfigKey];

const configKeyToTypeMap = {
  'register.self_register': 'boolean',
  'register.reset_password': 'boolean',
  'feedback.enable_types': 'string',
  "feedback.user_manual_link": 'string',
  "feedback.developer_manual_link": 'string',
  "ai.assistant.enable": 'boolean',
  "websocket.heartbeatInterval": 'int',
  "attachment.align_download_permission_with_update": 'boolean',
  "debugDynamicLogic.enableNewDomainChangePushService": 'string',
};

/**
 * Fetches the config from the server
 * @param configKey config key
 * @param configType data type of the config, like boolean, integer, string etc
 */
export async function fetchConfig(configKey: ConfigKey, configType?: ConfigType): Promise<ConfigResponseProps> {
  // q:how to get the name of a type in typescript?
  // a: https://stackoverflow.com/questions/39645871/how-to-get-the-name-of-a-type-in-typescript
  const url = `${SERVER_URL}/config/${configKey}/${configType ?? 'string'}`;
  return requestUrlAndGetPromise(url, {});
}

// Define useConfig hook
export const useConfig = (configKey: ConfigKey, configType?: ConfigType): {
  value: ConfigValueTypes | null;
  error: Error | null;
  loading: boolean;
} => {
  const [value, setValue] = useState<ConfigValueTypes | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchConfig(configKey, configType ?? configKeyToTypeMap[configKey])
      .then((config) => {
        setValue(config.value);
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Failed to fetch config for ${configKey}: ${error}`);
        setError(error);
        setLoading(false);
      });
  }, [configKey, configType]);

  return { value, error, loading };
};
