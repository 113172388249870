import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fscreen from 'fscreen';

const useFullScreen = (): {
  fullScreen: boolean;
  toggleFullScreen: () => void;
} => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', () => setFullScreen(!(fscreen.fullscreenElement == null)));

    return () => {
      fscreen.removeEventListener('fullscreenchange', () => setFullScreen(!(fscreen.fullscreenElement == null)));
    };
  }, []);

  const toggleFullScreen = (): void => {
    if (fullScreen === false) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
    setFullScreen(!fullScreen);
  };

  return { fullScreen, toggleFullScreen };
};

export default useFullScreen;
