import React, { CSSProperties, ReactElement, useState } from 'react';
import { Drawer, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { UpdateComponent, ListOperations } from '../../form';
import { removePackagePart } from '@utils/StringUtils';
import { fetchFormIdAndExtInfoByType } from '@utils/FetchUtils';
import { LargeSpin, CloseIcon } from '../../components';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { emptyMethod } from '@utils/Constants';

const ObjectPopup = (props: {
  domainName: string;
  id: string | number;
  style?: CSSProperties;
  formId?: number;
  display?: string | number;
  zIndex: number
}): ReactElement => {
  const {
    domainName, formId: stateFormId, id, style, display, zIndex
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [formId, setFormId] = useState<number | undefined>(stateFormId);
  const [visible, setVisible] = useState<boolean>(false);
  const [drawerTitle, setDrawerTitle] = useState<string>(domainName);

  const title = (<div
    className="object-popover"
  >
    <Space>
      <span>{t(`domainTitle:${removePackagePart(drawerTitle)}`)}({id})</span>
      <span>
        <ListOperations
          page="detail-drawer"
          domainName={domainName}
          id={(typeof id == 'string') ? parseInt(id) : id}
          ownerClass={undefined}
          ownerId={undefined}
          deleteCallback={emptyMethod}
          updateCallback={emptyMethod}
          zIndex={zIndex + 1}
          showActions={true}
        />
      </span>
    </Space>
  </div>);

  const onClose = (): void => {
    setVisible(false);
  };

  const displayTitle = (display == null || display === '') ? id : display;
  const innerElem = (
    <a
      href="/#"
      onClick={(e: React.MouseEvent<unknown>) => {
        e.preventDefault();
        setLoading(true);
        fetchFormIdAndExtInfoByType(domainName, "Update").then((json) => {
          setFormId(json.id);
          // if json.extInfo.domainTitle is not null, set drawer title
          if (json.extInfo?.domainTitle != null) {
            setDrawerTitle(json.extInfo?.domainTitle);
          }
        }).catch(e => console.error(`Failed to get update form id for domain ${domainName}: ${JSON.stringify(e)}`))
          .finally(() => setLoading(false));
      }}
    >
      <Tag
        key={`${id}`}
        className="object-id-tag"
        style={style}
        onClick={() => setVisible(true)}
      >
        {displayTitle}
      </Tag>
    </a>
  );
  return (
    (<span onClick={(event: React.MouseEvent<unknown>) => {
      stopPropagationAndPreventDefault(event);
    }}>
      <Drawer
        width="660px"
        destroyOnClose={true}
        onClose={() => onClose()}
        zIndex={zIndex + 1}
        open={visible}
        title={title}
        closeIcon={<CloseIcon
          onClick={
            //Set to emptyMethod to avoid onClose been called multiple times
            emptyMethod
          }
          className="detail-drawer-close-icon-container"
        />}
      >
        {
          loading ? <LargeSpin /> :
            <div
              style={{ width: "615px", overflow: "auto" }}
            >
              <div style={{ width: "100%", textAlign: "right", margin: "auto" }}>
                <Space size="small" style={{ alignItems: "flex-start" }}>
                </Space>
              </div>
              <UpdateComponent
                formId={formId == null ? -1 : formId}
                domainName={domainName}
                id={typeof id === 'string' ? parseInt(id) : id}
                readonly={true}
                hideDetailPanel={true}
                zIndex={zIndex}
                displayMode="detail-drawer"
                page="detail-drawer"
              />
            </div>
        }
      </Drawer>
      <>{innerElem}</>
    </span>)
  );
};

export default ObjectPopup;
