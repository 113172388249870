import { Button, Result, Space } from 'antd';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const EmptyPage = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title={'警告'}
      subTitle={"未连接 MQTT 物联网端口，无法获取生产实况"}
      extra={
        <Space>
          <Button type="primary" onClick={() => navigate("/")}>主页</Button>
        </Space>
      }
    />
  );
};

export default EmptyPage;
