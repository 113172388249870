import React, { ReactElement, useEffect, useState } from "react";
import { Tag, Tooltip } from "antd";
import { useTranslation } from 'react-i18next';
import { ExclamationOutlined } from '@ant-design/icons';
import { fetchCurrentValue } from "@utils/FetchUtils";
import { getObjectLabelFromCache, getLabelToDisplay, setObjectLabelToCache } from "@utils/ObjectUtils";
import ObjectPopup from "./ObjectPopup";
import { TableMode } from "@props/RecordProps";
import { emptyMethod } from "@utils/Constants";

interface ObjectCellRenderProps {
  domainName: string;
  id: number;
  labelField?: string;
  displayText?: string;
  tableMode?: TableMode;
  zIndex: number;
}

const DefaultDisplayVal = '';

const ObjectCell = (props: ObjectCellRenderProps): ReactElement => {
  const {
    domainName: propDomainName, id: propId, labelField, displayText,
    zIndex,
  } = props;
  const { t } = useTranslation();
  const [displayVal, setDisplayVal] = useState<string | number>(DefaultDisplayVal);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setDisplayVal(DefaultDisplayVal);
    setHasError(false);
  }, [propDomainName, propId]);

  useEffect(() => {
    if (displayText == null && propId != null) {
      const cache = getObjectLabelFromCache(propDomainName, propId);
      if (cache) {
        setHasError(false);
        setDisplayVal(cache);
      } else {
        fetchCurrentValue(propDomainName, propId).then((value) => {
          const label = getLabelToDisplay(value, labelField);
          setHasError(false);
          setDisplayVal(label);
          setObjectLabelToCache(propDomainName, propId, label);
        }).catch(exception => {
          if (exception?.status === 403 || exception?.status === 404
            || exception?.error === 404 || exception?.error === 403) {
            console.warn(`Failed to get object ${propDomainName} with id ${propId}: ${exception?.message}`);
            setHasError(true);
            if (exception.status === 403 || exception?.error === 403) {
              setErrorMessage('You do not have permission to view this object');
            }
            else if (exception.status === 404 || exception?.error === 404) {
              setErrorMessage('This object does not exist');
            }
          }
        }).finally(() => emptyMethod());
      }
    } else if (displayText != null) {
      setObjectLabelToCache(propDomainName, propId, displayText);
      setDisplayVal(displayText);
    }
  }, [displayVal, propId, propDomainName, labelField, displayText]);

  if (propId == null) {
    return (<></>);
  }

  return (
    <div className="object">
      {hasError ? (
      <Tooltip
        title={t(`Failed to load object`, {
          domainName: propDomainName, id: propId, errorMessage: t(errorMessage ?? '')
        })}>
        <Tag
          key={`${propId}`}
          className="object-id-tag"
          color="red"
          icon={<ExclamationOutlined />}
        >{propId}</Tag>
      </Tooltip>
      ) :
      <ObjectPopup
        domainName={propDomainName}
        id={propId}
        display={displayVal}
        zIndex={zIndex + 1}
      />}
    </div>
  );
};

export default ObjectCell;
