import { Alert, Button, Modal } from "antd";
import React, { FunctionComponent, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SaveOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { MiddleStepExecContextProps } from "@props/RecordProps";
import CodeEditor from "../fields/CodeEditor";

interface MiddleStepExecInstructionProps {
  setCurrentTab: (tab: string) => void;
  executeCallback: (isFinalRound: boolean) => void;
  middleStepExecContext: MiddleStepExecContextProps;
  setFineTuningResult: (result: string) => void;
  zIndex: number;
}

const MiddleStepExecInstruction: FunctionComponent<MiddleStepExecInstructionProps> = ({
  setCurrentTab,
  executeCallback,
  middleStepExecContext,
  setFineTuningResult,
  zIndex
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fineTuningResult, setFineTuningResultState] = useState(
    middleStepExecContext?.result?.execResult ?? "");

  const handleOk = (): void => {
    setFineTuningResult(fineTuningResult);
    setIsModalVisible(false);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  return (
    (<Alert
      message={t('alertMessage')}
      showIcon={true}
      type="info"
      className="middle-step-exec-instruction action-message-info"
      description={
        <>
          <Trans i18nKey="alertDescriptionPart1" />
          <span style={{ color: "red", fontWeight: 900 }}>
            <Trans i18nKey="alertDescriptionPart2" />
          </span>
          <ul>
            <li>{t('alertDescriptionSatisfaction')}</li>
            <li>
              {t('alertDescriptionTuning')}
              <Button
                size="small"
                type="default"
                icon={<EditOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                {t('editResult')}
              </Button>
              {t('alertDescriptionSystemSave')}
            </li>
            <li>
              {t('alertDescriptionModify')}
              <Button
                size="small"
                type="default"
                onClick={() => setCurrentTab("ptab")}
                icon={<FormOutlined />}
              >
                {t('resetParameters')}
              </Button>
              {t('alertDescriptionRunAgain')}
            </li>
          </ul>
          <div style={{ textAlign: "center", width: "100%", margin: "auto" }}>
            <Button
              size="middle"
              type="primary"
              onClick={() => executeCallback(true)}
              icon={<SaveOutlined />}
            >
              {t("Save")}
            </Button>
          </div>
          <Modal
            title={t("fineTuningResult")}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            zIndex={zIndex + 1}
            width="62vw"
            className="fine-tuning-result-modal"
          >
            <CodeEditor
              value={fineTuningResult}
              onChange={(val: string) => setFineTuningResultState(val)}
              name="finalResultEditor"
              updatable={true}
              zIndex={1}
              width="100%"
              height={"200"}
            />
          </Modal>
        </>
      }
    ></Alert>)
  );

};


export default MiddleStepExecInstruction;
