import { ColumnType, MatchMode, TableMetaProps } from "@props/RecordProps";
import {
  FieldConfigures, getDestTypeForComponentLookup, SearchConditionInputComponentRenderFunction
} from "./ComponentsConfig";
import { getStringSearchInput } from "@kernel/SearchInputComponents";

const shouldAppendMultipleToTypeKey = (multiple: boolean | undefined, type: ColumnType) : boolean => {
  const knownMultipleType = ['df_tags', 'df_multiple', 'tags'];
  return multiple === true &&
    !knownMultipleType.includes(type) &&
    !type.endsWith("_multiple");
};

/**
 * 获取表格中的某一列可用的搜索匹配规则，
 * 比如：
 * 对于字符，可用的匹配为：包含、等于、开始于、结束于
 * 对于数字，可用的匹配为：等于、大于、小于、大于等于、小于等于、不等于
 * @param column 该列的列元数据，其中包含该列的类型等信息
 */
export const getAvailableMatchers = (column: TableMetaProps): Array<MatchMode> => {
  const { type, multiple } = column;
  const destType = shouldAppendMultipleToTypeKey(multiple, type) ?
    getDestTypeForComponentLookup(type) + "_multiple" : getDestTypeForComponentLookup(type);
  return FieldConfigures.find(fc => fc.type === destType)?.searchMatchers ?? [];
};

/**
 * 针对不同的列类型，获取默认的匹配规则
 * 比如对于 string 类型，默认的匹配规则为 contains
 * 对于 id 类型，默认的匹配规则为 =
 * @param column 该列的列元数据，其中包含该列的类型等信息
 */
export const getDefaultMatcher = (column: TableMetaProps): (MatchMode | undefined) => {
  const { type, multiple } = column;
  const destType = shouldAppendMultipleToTypeKey(multiple, type) ?
    getDestTypeForComponentLookup(type) + "_multiple" : getDestTypeForComponentLookup(type);
  const matched = FieldConfigures.filter(fc => fc.type === destType)?.[0];
  return matched?.searchMatchers?.[0];
};

/**
 * 针对不同的列类型, 获取该字段的搜索条件输入的控件
 * @param column 该列的列元数据, 其中包含该列的类型等信息
 */
export const getSearchInputComponent = (column: TableMetaProps): SearchConditionInputComponentRenderFunction => {
  const { type, multiple } = column;
  const destType = shouldAppendMultipleToTypeKey(multiple, type) ?
    getDestTypeForComponentLookup(type) + "_multiple" : getDestTypeForComponentLookup(type);
  const matched = FieldConfigures.filter(fc => fc.type === destType)?.[0];
  return matched?.searchConditionInputComponent ?? getStringSearchInput;
};
